import React, { useState } from "react";

import { getCurrentTimeHomePage } from "../components/common/Helper";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import SideTabs from "../components/systemadministration/Tabs";
import PermissionRoles from "../components/systemadministration/PermissionManagement/Roles";
import PermissionUsers from "../components/systemadministration/PermissionManagement/Users";
import RolesEdit from "../components/systemadministration/PermissionManagement/RolesEdit";
import NewRolesEdit from "../components/systemadministration/PermissionManagement/NewRolesEdit";
import AuditTrail from "../components/systemadministration/PermissionManagement/AuditTrail";
import { Link, useHistory, useParams } from "react-router-dom";
import RoleAuditTrail from "../components/systemadministration/PermissionManagement/RoleAuditTrail";
import UserAssigned from "../components/systemadministration/PermissionManagement/UserAssigned";
import { GlobalIconNames } from "../utils/GlobalIconNames";

const PermissionManagement = () => {
  const { tab, type, roleName, id } = useParams();
  const history = useHistory();

  const [isRole, setIsRole] = useState(0);
  // const [roleName, setRoleName] = useState("");

  // console.log("isRole ---> ", isRole);
  // console.log("url ---> ", window.location.href);

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap permission-mgt-sec">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <SideTabs chooseRole={setIsRole} /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-info-list Tickets-main-wrap">
                  <div className={`my-tickets-heading-blk flex-direction mb-2`}>
                    <div className="d-flex align-items-center ">
                      <div className="name-w-head d-flex align-items-center ">
                        <h4 className="text-left-align landing-heading heading_color_dynamic">
                          {!type ? (
                            <>
                              Permission <span>Management</span>
                            </>
                          ) : (
                            <>
                              Role <span>{roleName}</span>
                            </>
                          )}
                          {/* {type === "php" && } */}
                        </h4>
                      </div>
                        <div className="text-md-right action2-blk">
                          <div className="ticket-view-btn d-flex align-items-center">
                            <div className="add-new-ticket-bx">
                              <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                <i className="fal fa-angle-left"></i>Back
                              </button>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  {!type && (
                    <div className="tabs-wrap">
                      <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                        <li className="nav-item" onClick={() => history.push("/systemAdministration/permissionManagement/table")}>
                          <Link
                            className={`nav-link ${tab === "table" && "active"}`}
                            id="pills-tab1"
                            data-toggle="pill"
                            to={"/systemAdministration/permissionManagement/table"}
                            role="tab"
                            aria-controls="pills-tab11"
                            aria-selected="true"
                          >
                            {GlobalIconNames("Permissions").html}
                          </Link>
                        </li>
                        <li className="nav-item" onClick={() => history.push("/systemAdministration/permissionManagement/auditTrail")}>
                          <Link
                            className={`nav-link ${tab === "auditTrail" && "active"}`}
                            id="pills-tab2"
                            data-toggle="pill"
                            to={"/systemAdministration/permissionManagement/auditTrail"}
                            role="tab"
                            aria-controls="pills-tab12"
                            aria-selected="true"
                          >
                            {GlobalIconNames("auditTrail").html}
                          </Link>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                          {tab === "table" && <PermissionRoles openRoles={setIsRole} />}
                        </div>
                        <div className="tab-pane fade active show" id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
                          {tab === "auditTrail" && <AuditTrail />}
                        </div>
                      </div>
                    </div>
                  )}
                  {tab === "open" && (
                    <div className="tabs-wrap">
                      <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                        {/* <li className="nav-item" onClick={() => history.push(`/systemAdministration/permissionManagement/open/php/${roleName}/${id}`)}>
                          <Link
                            className={`nav-link ${type === "php" && "active"}`}
                            id="pills-tab2"
                            data-toggle="pill"
                            to={`/systemAdministration/permissionManagement/open/php/${roleName}/${id}`}
                            role="tab"
                            aria-controls="pills-tab2"
                            aria-selected="false"
                          >
                            {GlobalIconNames("Php Portal", "fa-code", "Php Portal").html}
                          </Link>
                        </li> */}
                        <li className="nav-item" onClick={() => history.push(`/systemAdministration/permissionManagement/open/new/${roleName}/${id}`)}>
                          <Link
                            className={`nav-link ${type === "new" && "active"}`}
                            id="pills-tab1"
                            data-toggle="pill"
                            to={`/systemAdministration/permissionManagement/open/new/${roleName}/${id}`}
                            role="tab"
                            aria-controls="pills-tab1"
                            aria-selected="true"
                          >
                            {GlobalIconNames("AMI", "fa-exclamation-triangle", "AMI").html}
                          </Link>
                        </li>
                        <li className="nav-item" onClick={() => history.push(`/systemAdministration/permissionManagement/open/userInRole/${roleName}/${id}`)}>
                          <Link
                            className={`nav-link ${type === "userInRole" && "active"}`}
                            id="pills-tab1"
                            data-toggle="pill"
                            to={`/systemAdministration/permissionManagement/open/userInRole/${roleName}/${id}`}
                            role="tab"
                            aria-controls="pills-tab1"
                            aria-selected="true"
                          >
                            {GlobalIconNames("Assigned User", "fa-users", "Assigned User").html}
                          </Link>
                        </li>
                        <li className="nav-item" onClick={() => history.push(`/systemAdministration/permissionManagement/open/auditTrail/${roleName}/${id}`)}>
                          <Link
                            className={`nav-link ${type === "auditTrail" && "active"}`}
                            id="pills-tab3"
                            data-toggle="pill"
                            to={`/systemAdministration/permissionManagement/open/auditTrail/${roleName}/${id}`}
                            role="tab"
                            aria-controls="pills-tab3"
                            aria-selected="true"
                          >
                            {GlobalIconNames("auditTrail").html}
                          </Link>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade active show" id="pills-tab22" role="tabpanel" aria-labelledby="pills-tab2">
                          {type === "php" && <RolesEdit data={id} roleName={roleName} isNewPortal={1} />}
                        </div>
                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                          {type === "new" && <NewRolesEdit data={id} roleName={roleName} prevUrl={window.location.href} isNewPortal={0} />}
                        </div>
                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                          {type === "userInRole" && <UserAssigned data={id} roleName={roleName} prevUrl={window.location.href} isNewPortal={0} />}
                        </div>
                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                          {type === "auditTrail" && <RoleAuditTrail data={id} roleName={roleName} prevUrl={window.location.href} isNewPortal={0} />}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionManagement;
