import React, { useEffect, useState, useMemo} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import {
  AddRoomToCampus,
  DeleteRoomOfCampus,
  GetCampusRoomsList,
  GetCampusRoomsListCancelToken,
} from "../../../services/CampusMgtService";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { TrimText } from "../../common/TrimText";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const RoomList = () => {
  const [dataArr, setDataArr] = useState([]);
  const search = useLocation().search;
  const [classId, setClassId] = useState(new URLSearchParams(search).get("id"));
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [updateData, setUpdateData] = useState(false)

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  // useEffect(() => {
  //   if (classId !== null) {
  //     getRoomList();
  //   }
  // }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const data = await GetCampusRoomsListCancelToken(classId, dropdownSource.token);
        if (data?.status == 200) {
          setDataArr(
            data && data.data && data.data.data_list ? data.data.data_list : []
          );
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
    if (classId !== null) {
    fetchData();
    }
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, [updateData]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };


  function dataToRender() {
    let updatedData = [];
    let allData = dataArr;
    if (searchValue.length) {
      let tempUser = allData.filter((item) => {
        let includes = item.room_name
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempRoomNo = allData.filter((item) => {
        let includes = item.room_no
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempRoomDescription = allData.filter((item) => {
        let includes = item.room_description
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempUser,...tempRoomNo,...tempRoomDescription];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    return updatedData;
  }

  function getRoomList() {
    GetCampusRoomsList(classId)
      .then((data) => {
        setDataArr(
          data && data.data && data.data.data_list ? data.data.data_list : []
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log("error> >> > >", error);
      });
  }
  const sortRoomFunction = (rowA, rowB) => {
    const name1 = rowA.room_name;
    const name2 = rowB.room_name;
    return name1.localeCompare(name2);
  };
  const sortDescriptionFunction = (rowA, rowB) => {
    const name1 = rowA.room_description;
    const name2 = rowB.room_description;
    return name1.localeCompare(name2);
  };

  const columns = useMemo(() => [
    {
      name: "Room Name",
      selector: "room_name",
      sortFunction:sortRoomFunction,
      sortable: true,
      cell: (row) => row.room_name ? <span className="feature-name" title={row.room_name}><span className="textLimit100">{row.room_name}</span></span> : "-"
    },
    {
      name: "#Room No.",
      selector: "room_no",
      sortable: true,
      cell: (row) => <p title={row.room_no}>{row.room_no ? TrimText(row.room_no,20) : "-"}</p>,
    },
    {
      name: "Description",
      selector: "room_description",
      sortFunction:sortDescriptionFunction,
      sortable: true,
      cell: (row) => (
        <span className="feature-name" title={row.room_description}>
          <span className="textLimit100">{row.room_description ? row.room_description : "-"}</span>
        </span>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button
              className="btn btn-danger rounded-circle"
              onClick={() => {
                deleteRoom(row.id);
              }}
              title="Delete"
            >
              <i className="fal fa-trash-alt"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);
  const roomFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      room_name: "",
      room_no: "",
      room_description: "",
    },
    validationSchema: Yup.object({
      room_name: Yup.string().required("Name is required").max(255, "Name should not be greater than 255 Character"),
      room_no: Yup.string().required("Number is required").max(255, "Number should not be greater than 255 Character"),
      room_description: Yup.string().required("Description is required"),
    }),
    onSubmit: (values,resetForm) => {
      let formData = new FormData();
      formData.append("room_name", values.room_name);
      formData.append("room_no", values.room_no);
      formData.append("room_description", values.room_description);
      formData.append("campus_id", classId);
      AddRoomToCampus(formData)
        .then((data) => {
          Swal.fire("Added!", "Your record has been Created.", "success");
          $("#roomCampusModal").modal("hide");
          // getRoomList();
          setUpdateData(!updateData)
          roomFormik.resetForm()

        })
        .catch((error) => {
          console.log("error -=-=-=-> ", error);
        });
    },
  });

  function deleteRoom(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let formData = new FormData();
        formData.append("id", id);
        DeleteRoomOfCampus(formData)
          .then((data) => {
            Swal.fire("Deleted!", "Record deleted successfully.", "success");
            $("#roomCampusModal").modal("hide");
            // getRoomList();
            setUpdateData(!updateData)
          })
          .catch((error) => {
            console.log("error -=-=-=-> ", error);
          });
      }
    });
  }

  const modifiedData = () => {
    let data = dataToRender();

    data = data?.map((row, ind) => ({
      ...row,
      "Room Name": row.room_name ? row.room_name : "-",
      "#Room No.": row.room_no ? row.room_no : "-",
      Description: row.room_description ? row.room_description : "-",
    }));

    return data;
  };

  const exportData = (fileType, fileName) => {
    let data = modifiedData();

    const header = ["Room Name", "#Room No.", "Description"];

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row["Room Name"], row["#Room No."], row["Description"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const resetFilter = () => {
    setSearchValue("");
  };
  return (
    <>
      <div className="card card-profile-info-card">
        <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="l-o-c-t custom-table-div filter-search-icon card-table-custom">
            <div className="search-filter-div">
                <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                >
                    <div
                        id="assessment-table-main_filter"
                        className="dataTables_filter"
                    >
                        <label>
                        <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={handleSearchFilter}
                            value={searchValue}
                        />
                        </label>
                        <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                        </button>
                        </div>
                    </div>
                </div>
                </div>
                <div className="filter-button-group">
                <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                    <button
                        className="btn btn-primary"
                        onClick={resetFilter}
                        title="Reset"
                    >
                        <i className="fal fa-redo"></i>Reset
                    </button>
                    </div>

                    <div className="files-export-group">
                    <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                        exportData("xlsx", "room_audit_trail");
                        }}
                        title="Export spreadsheet"
                    >
                        <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                        exportData("csv", "room_audit_trail");
                        }}
                        title="Export CSV"
                    >
                        <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                        exportData("pdf", "room_audit_trail");
                        }}
                        title="Export PDF"
                    >
                        <i className="fal fa-file-pdf icon"></i>
                    </button>
                    </div>
                </div>
                </div>
                </div>
                </div>
                <div className="search-filter-div-right">
                <div className=" filter-search-bar-blk">
                    <div className="add-ticket-blk button-reset dropdown-comman text-right">
                        <button
                            className="btn btn-primary"
                            title="Create New"
                            data-toggle="modal"
                            data-target="#roomCampusModal"
                        >
                            <i className="fal fa-plus"></i>Create New
                        </button>
                    </div>
                </div>
                </div>
            </div>
            {loading ? (
                <SkeletonTicketList />
            ) : (
                <DataTable
                data={dataToRender()}
                columns={columns}
                pagination={true}
                defaultSortField="room_name"
                defaultSortAsc={false}
                paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                />
            )}
            </div>
        </div>
        

        <div
          className="topic-add-modal modal fade"
          id="roomCampusModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content modal-border-update">
              <form onSubmit={roomFormik.handleSubmit}>
              <div className="modal-body p-0">
                    <div className="modal-header modal-header-custom">
                      <h5 class="modal-title">
                      <i className="fal fa-plus"></i> Add Room to Campus
                      </h5>
                      <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                      </button>
                      
                    </div>
                    <div className="t p-30px">
                      <div className="topic-add-modal-content">
                        <div className="row center-label">
                          <div className="col-md-12">
                            <div className="form-group-blk mb-3">
                              <label>Room Name *</label>
                              <input
                                className={
                                  "form-control" +
                                  (roomFormik.errors.room_name &&
                                  roomFormik.touched.room_name
                                    ? " is-invalid"
                                    : "")
                                }
                                value={roomFormik.values.room_name}
                                placeholder="Room Name"
                                onChange={roomFormik.handleChange}
                                onBlur={roomFormik.handleBlur}
                                name="room_name"
                                title="Room Name *"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group-blk mb-3">
                              <label>Room No *</label>
                              <input
                                className={
                                  "form-control" +
                                  (roomFormik.errors.room_no &&
                                  roomFormik.touched.room_no
                                    ? " is-invalid"
                                    : "")
                                }
                                type="text"
                                value={roomFormik.values.room_no}
                                placeholder="Room No"
                                onChange={roomFormik.handleChange}
                                onBlur={roomFormik.handleBlur}
                                name="room_no"
                                title="Room No *"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group-blk mb-3">
                              <label>Description *</label>
                              <textarea
                                className={
                                  "form-control " +
                                  (roomFormik.errors.room_description &&
                                  roomFormik.touched.room_description
                                    ? " is-invalid"
                                    : "")
                                }
                                rows="5"
                                placeholder="Description..."
                                value={roomFormik.values.room_description}
                                name="room_description"
                                onChange={roomFormik.handleChange}
                                onBlur={roomFormik.handleBlur}
                                title="Description *"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>


                        <div className="form-group form-group-save-cancel mt-3">
                          <button type="submit" className="btn btn-primary" title="Save">
                            <i class="fal fa-save"></i>
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                            data-dismiss="modal"
                            onClick={()=>roomFormik.resetForm()}
                            title="Close"
                          >
                            <i className="fal fa-times"></i>
                            Close
                          </button>
                          {Object.keys(roomFormik.values).map((key) => {
                        if (roomFormik.touched[key] && roomFormik.errors[key]) {
                          return (
                            <div className="invalid-feedback d-block">
                              {roomFormik.errors[key]}
                            </div>
                          );
                        }
                      })}
                        </div>
                    </div>
              </div>
              </form>
                
              </div>
              </div>
            </div>
      </div>
    </>
  );
};

export default RoomList;
