import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ProcessDetail from "./ProcessDetail";
import AllCopiedIntakes from "./AllCopiedIntakes";
import AllCopiedQualifications from "./AllCopiedQualifications";
import AllCopiedNotifications from "./AllCopiedNotifications";
import AllCopiedRecordings from "./AllCopiedRecordings";
import AllCopiedAssesmblies from "./AllCopiedAssesmblies";
import AllCopiedAssignments from "./AllCopiedAssignments";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";

function ListCopiedLogs() {
    const history = useHistory();
    const givenPermsisions = useSelector((state) => state.givenPermission);
    const { tab } = useParams();
    const [showProcessDetail, setShowProcessDetail] = useState(false)
    const [showAllCopiedIntakes, setShowAllCopiedIntakes] = useState(false)
    const [showAllCopiedQualifications, setShowAllCopiedQualifications] = useState(false)
    const [showAllCopiedNotifications, setShowAllCopiedNotifications] = useState(false)
    const [showAllCopiedRecordings, setShowAllCopiedRecordings] = useState(false)
    const [showAllCopiedAssesmblies, setShowAllCopiedAssesmblies] = useState(false)
    const [showAllCopiedAssignments, setShowAllCopiedAssignments] = useState(false)

    useEffect(() => {
        let response = hasPermission({
            scopes: ["intakeCopyView"],
            permissions: givenPermsisions,
        });
        if (!response) {
            history.push("/noaccess");
        }
    }, []);

    return <>
        <div
            className={`admin-home-acc accordion ac_over_none ${!showProcessDetail ? "d-none" : ""}`}
            id="accordionExample"
        >
            <div className="card-main">
                <div className="card-top">
                    <div className="card-header1" id={`processDetailheading${tab}`}>
                        <h2 className="mb-0">
                            <button
                                className="btn btn-link collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target={`#processDetail${tab}`}
                                // aria-expanded={item?.id ? "true" : "false"}
                                aria-expanded={"false"}
                                aria-controls={`#processDetail${tab}`}
                            >
                                <div
                                    className={
                                        `title-border-box ` +
                                        "intake-copy-side-border-grade"
                                    }
                                >
                                    <span>
                                        <p
                                            style={{ color: "#000", marginBottom: 0 }}
                                            title={"Process Details"}
                                        >
                                            {"Process Details"}
                                        </p>
                                    </span>
                                </div>{" "}
                                <div className="header-status">
                                    <div className="status-gol card-blue-1">
                                        <i className="fal fa-chevron-down rotate-icon"></i>
                                    </div>
                                </div>
                            </button>
                        </h2>
                    </div>
                    <div
                        id={`processDetail${tab}`}
                        className={`collapse ${false ? "show" : ""}  `}
                        aria-labelledby={`processDetailheading${tab}`}
                        data-parent="#accordionExample"
                    >
                        <div className="card card-body" style={{ border: "0px" }}>
                            {<ProcessDetail id={tab} setShowProcessDetail={setShowProcessDetail} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            className={`admin-home-acc accordion ac_over_none ${!showAllCopiedIntakes ? "d-none" : ""}`}
            id="accordionExample"
        >
            <div className="card-main">
                <div className="card-top">
                    <div className="card-header1" id={`allCopiedIntakesheading${tab}`}>
                        <h2 className="mb-0">
                            <button
                                className="btn btn-link collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target={`#allCopiedIntakes${tab}`}
                                // aria-expanded={item?.id ? "true" : "false"}
                                aria-expanded={"false"}
                                aria-controls={`#allCopiedIntakes${tab}`}
                            >
                                <div
                                    className={
                                        `title-border-box ` +
                                        "intake-copy-side-border-grade"
                                    }
                                >
                                    <span className="curser">
                                        <p
                                            style={{ color: "#000", marginBottom: 0 }}
                                            title={"All Copied Intakes"}
                                        >
                                            {"All Copied Intakes"}
                                        </p>
                                    </span>
                                </div>{" "}
                                <div className="header-status">
                                    <div className="status-gol card-blue-1">
                                        <i className="fal fa-chevron-down rotate-icon"></i>
                                    </div>
                                </div>
                            </button>
                        </h2>
                    </div>
                    <div
                        id={`allCopiedIntakes${tab}`}
                        className={`collapse ${false ? "show" : ""}  `}
                        aria-labelledby={`allCopiedIntakesheading${tab}`}
                        data-parent="#accordionExample"
                    >
                        <div className="card card-body" style={{ border: "0px" }}>
                            {<AllCopiedIntakes id={tab} setShowAllCopiedIntakes={setShowAllCopiedIntakes} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            className={`admin-home-acc accordion ac_over_none ${!showAllCopiedQualifications ? "d-none" : ""}`}
            id="accordionExample"
        >
            <div className="card-main">
                <div className="card-top">
                    <div className="card-header1" id={`allCopiedQualificationsheading${tab}`}>
                        <h2 className="mb-0">
                            <button
                                className="btn btn-link collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target={`#allCopiedQualifications${tab}`}
                                // aria-expanded={item?.id ? "true" : "false"}
                                aria-expanded={"false"}
                                aria-controls={`#allCopiedQualifications${tab}`}
                            >
                                <div
                                    className={
                                        `title-border-box ` +
                                        "intake-copy-side-border-grade"
                                    }
                                >
                                    <span className="curser">
                                        <p
                                            style={{ color: "#000", marginBottom: 0 }}
                                            title={"All Copied Qualifications"}
                                        >
                                            {"All Copied Programmes"}
                                        </p>
                                    </span>
                                </div>{" "}
                                <div className="header-status">
                                    <div className="status-gol card-blue-1">
                                        <i className="fal fa-chevron-down rotate-icon"></i>
                                    </div>
                                </div>
                            </button>
                        </h2>
                    </div>
                    <div
                        id={`allCopiedQualifications${tab}`}
                        className={`collapse ${false ? "show" : ""}  `}
                        aria-labelledby={`allCopiedQualificationsheading${tab}`}
                        data-parent="#accordionExample"
                    >
                        <div className="card card-body" style={{ border: "0px" }}>
                            {<AllCopiedQualifications id={tab} setShowAllCopiedQualifications={setShowAllCopiedQualifications} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            className={`admin-home-acc accordion ac_over_none ${!showAllCopiedNotifications ? "d-none" : ""}`}
            id="accordionExample"
        >
            <div className="card-main">
                <div className="card-top">
                    <div className="card-header1" id={`allCopiedNotificationsheading${tab}`}>
                        <h2 className="mb-0">
                            <button
                                className="btn btn-link collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target={`#allCopiedNotifications${tab}`}
                                // aria-expanded={item?.id ? "true" : "false"}
                                aria-expanded={"false"}
                                aria-controls={`#allCopiedNotifications${tab}`}
                            >
                                <div
                                    className={
                                        `title-border-box ` +
                                        "intake-copy-side-border-grade"
                                    }
                                >
                                    <span className="curser">
                                        <p
                                            style={{ color: "#000", marginBottom: 0 }}
                                            title={"All Copied Notifications"}
                                        >
                                            {"All Copied Notifications"}
                                        </p>
                                    </span>
                                </div>{" "}
                                <div className="header-status">
                                    <div className="status-gol card-blue-1">
                                        <i className="fal fa-chevron-down rotate-icon"></i>
                                    </div>
                                </div>
                            </button>
                        </h2>
                    </div>
                    <div
                        id={`allCopiedNotifications${tab}`}
                        className={`collapse ${false ? "show" : ""}  `}
                        aria-labelledby={`allCopiedNotificationsheading${tab}`}
                        data-parent="#accordionExample"
                    >
                        <div className="card card-body" style={{ border: "0px" }}>
                            {<AllCopiedNotifications id={tab} setShowAllCopiedNotifications={setShowAllCopiedNotifications} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            className={`admin-home-acc accordion ac_over_none ${!showAllCopiedRecordings ? "d-none" : ""}`}
            id="accordionExample"
        >
            <div className="card-main">
                <div className="card-top">
                    <div className="card-header1" id={`allCopiedRecordingsheading${tab}`}>
                        <h2 className="mb-0">
                            <button
                                className="btn btn-link collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target={`#allCopiedRecordings${tab}`}
                                // aria-expanded={item?.id ? "true" : "false"}
                                aria-expanded={"false"}
                                aria-controls={`#allCopiedRecordings${tab}`}
                            >
                                <div
                                    className={
                                        `title-border-box ` +
                                        "intake-copy-side-border-grade"
                                    }
                                >
                                    <span className="curser">
                                        <p
                                            style={{ color: "#000", marginBottom: 0 }}
                                            title={"All Copied Recordings"}
                                        >
                                            {"All Copied Recordings"}
                                        </p>
                                    </span>
                                </div>{" "}
                                <div className="header-status">
                                    <div className="status-gol card-blue-1">
                                        <i className="fal fa-chevron-down rotate-icon"></i>
                                    </div>
                                </div>
                            </button>
                        </h2>
                    </div>
                    <div
                        id={`allCopiedRecordings${tab}`}
                        className={`collapse ${false ? "show" : ""}  `}
                        aria-labelledby={`allCopiedRecordingsheading${tab}`}
                        data-parent="#accordionExample"
                    >
                        <div className="card card-body" style={{ border: "0px" }}>
                            {<AllCopiedRecordings id={tab} setShowAllCopiedRecordings={setShowAllCopiedRecordings} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            className={`admin-home-acc accordion ac_over_none ${!showAllCopiedAssesmblies ? "d-none" : ""}`}
            id="accordionExample"
        >
            <div className="card-main">
                <div className="card-top">
                    <div className="card-header1" id={`allCopiedAssesmbliesheading${tab}`}>
                        <h2 className="mb-0">
                            <button
                                className="btn btn-link collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target={`#allCopiedAssesmblies${tab}`}
                                // aria-expanded={item?.id ? "true" : "false"}
                                aria-expanded={"false"}
                                aria-controls={`#allCopiedAssesmblies${tab}`}
                            >
                                <div
                                    className={
                                        `title-border-box ` +
                                        "intake-copy-side-border-grade"
                                    }
                                >
                                    <span className="curser">
                                        <p
                                            style={{ color: "#000", marginBottom: 0 }}
                                            title={"All Copied Assesmblies"}
                                        >
                                            {"All Copied Assesmblies"}
                                        </p>
                                    </span>
                                </div>{" "}
                                <div className="header-status">
                                    <div className="status-gol card-blue-1">
                                        <i className="fal fa-chevron-down rotate-icon"></i>
                                    </div>
                                </div>
                            </button>
                        </h2>
                    </div>
                    <div
                        id={`allCopiedAssesmblies${tab}`}
                        className={`collapse ${false ? "show" : ""}  `}
                        aria-labelledby={`allCopiedAssesmbliesheading${tab}`}
                        data-parent="#accordionExample"
                    >
                        <div className="card card-body" style={{ border: "0px" }}>
                            {<AllCopiedAssesmblies id={tab} setShowAllCopiedAssesmblies={setShowAllCopiedAssesmblies} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            className={`admin-home-acc accordion ac_over_none ${!showAllCopiedAssignments ? "d-none" : ""}`}
            id="accordionExample"
        >
            <div className="card-main">
                <div className="card-top">
                    <div className="card-header1" id={`allCopiedAssignmentsheading${tab}`}>
                        <h2 className="mb-0">
                            <button
                                className="btn btn-link collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target={`#allCopiedAssignments${tab}`}
                                // aria-expanded={item?.id ? "true" : "false"}
                                aria-expanded={"false"}
                                aria-controls={`#allCopiedAssignments${tab}`}
                            >
                                <div
                                    className={
                                        `title-border-box ` +
                                        "intake-copy-side-border-grade"
                                    }
                                >
                                    <span className="curser">
                                        <p
                                            style={{ color: "#000", marginBottom: 0 }}
                                            title={"All Copied Assignments"}
                                        >
                                            {"All Copied Assessments"}
                                        </p>
                                    </span>
                                </div>{" "}
                                <div className="header-status">
                                    <div className="status-gol card-blue-1">
                                        <i className="fal fa-chevron-down rotate-icon"></i>
                                    </div>
                                </div>
                            </button>
                        </h2>
                    </div>
                    <div
                        id={`allCopiedAssignments${tab}`}
                        className={`collapse ${false ? "show" : ""}  `}
                        aria-labelledby={`allCopiedAssignmentsheading${tab}`}
                        data-parent="#accordionExample"
                    >
                        <div className="card card-body" style={{ border: "0px" }}>
                            {<AllCopiedAssignments id={tab} setShowAllCopiedAssignments={setShowAllCopiedAssignments} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

}

export default ListCopiedLogs;
