import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { GetProgramsNotificationDetailsCancelToken, PreviewStudentNotification, UpdateStudentNotification } from "../../../../../services/StudentsSettingServices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HtmlInputEditor from "../../../../common/HtmlInputEditor";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import { IMAGE_URL, FORM_DATE_FORMAT } from "../../../../../utils/Constants";
import PermissionsGate from "../../../../../utils/permissionGate";
import hasPermission from "../../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import moment from "moment";
import { formatQuestion } from "../../../../../utils/FormatQuestion";
import axios from "axios";

function NotificationForm({ studentName }) {
  const { id, subType, subId } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [attachmentName, setAttachmentName] = useState();
  const [attachmentFilePreview, setAttachmentFilePreview] = useState("");
  const [editorState, setEditorState] = React.useState("");
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [editNotificationData, setEditNotificationData] = useState({});
  const [gearLoading, setGearLoading] = useState("")

  useEffect(() => {
    let scopes = subType == "editNotification" ? "snedit" : "snadd";
    let response = hasPermission({ scopes: [scopes], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
    if (subType == "editNotification") {
      const cancelTokenSources = [];

    const getProgramsList = async () => {
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetProgramsNotificationDetailsCancelToken(subId, source.token);
        if(res.status==200 && res.data.notifications){
          setEditorState(res.data.notifications.content);
          setEditNotificationData(res.data.notifications);
          formik.setFieldValue("IncludeAccountPayer", res.data.notifications.isPayerActive);
          formik.setFieldValue("EmailNotification", res.data.notifications.isEmailActive);
          formik.setFieldValue("PushNotification", res.data.notifications.isPushnotificationActive);
          formik.setFieldValue("Title", res.data.notifications.subject);
          formik.setFieldValue("SendWhen", res.data.notifications.publish == 0 ? true : false);
          formik.setFieldValue("SendWhenDate",res.data.notifications.publish_date ?new Date(moment.unix(res.data.notifications.publish_date)):"");
          formik.setFieldValue("FileAttachment", res.data.notifications.attachment ? res.data.notifications.attachment : "");
          formik.setFieldValue("sentGroups",{ EmailNotification: res.data.notifications.isEmailActive , PushNotification: res.data.notifications.isPushnotificationActive} );
          setAttachmentFilePreview(res.data.notifications.attachment!=null && res.data.notifications.attachment.replace("public/",""));
          formik.setFieldValue("Important", res.data.notifications.ish_importanceActive);
          setAttachmentName(res.data.notifications.attachment!=null && res.data.notifications.attachment.replace("public/",""));
        }else{
          Swal.fire({
            icon: "error",
            title: "Error",
            text: res.data.message,
          }).then(() => { history.goBack() });
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          }).then(() => { history.goBack() });
        }
      }
    };
  
    getProgramsList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
    }
  }, []);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Name: studentName ? studentName : "",
      IncludeAccountPayer: editNotificationData.isPayerActive ? editNotificationData.isPayerActive : "",
      Important: editNotificationData.ish_importanceActive ? editNotificationData.ish_importanceActive : "",
      sentGroups: { EmailNotification: editNotificationData.isEmailActive ? editNotificationData.isEmailActive : false, PushNotification: editNotificationData.isPushnotificationActive ? editNotificationData.isPushnotificationActive : false },
      SendWhen: Object.keys(editNotificationData).length ? editNotificationData.publish == 0 ? true : false : true,
      SendWhenDate: editNotificationData.publish_date ? new Date(moment.unix(editNotificationData.publish_date)) : "",
      IsDraft: "",
      Title: editNotificationData.subject ? editNotificationData.subject : "",
      Content: "",
      FileAttachment: "",
    },
    validationSchema: Yup.object({
      // Name: Yup.string().required("Programme Name is required"),
      SendWhen: Yup.boolean().required("Send When is required"),
      Title: Yup.string().required("Title is required").trim(),
      // Content: Yup.string().required("Content is required"),
      // SendWhen: Yup.number(),
      SendWhenDate: Yup.string()
        // .email()
        .when("SendWhen", {
          is: false,
          then: Yup.string().required("Date And Time is required"),
        }),
      sentGroups: Yup.object().shape(
        {
          PushNotification: Yup.bool().when("EmailNotification", {
            is: (EmailNotification) => !EmailNotification,
            then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification"),
          }),
          EmailNotification: Yup.bool().when("PushNotification", {
            is: (PushNotification) => !PushNotification,
            then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification"),
          }),
        },
        [
          ["PushNotification", "EmailNotification"],
          ["EmailNotification", "PushNotification"],
        ]
      ),
      Content: Yup.string()
        .test("required", "Content is required", (value) => {
          if ((formatQuestion(editorState).replaceAll("&nbsp;", " ")).trim() == "" && !editorState.includes("<img")) {
            return false
          }
          if (!editorState) {
            return false
          }
          return true;
        })
        .trim(),
    }),

    onSubmit: (values, { resetForm }) => {
      setDisabled(true);
      let formData = new FormData();
      let date = values?.SendWhen === false ? new Date(values.SendWhenDate).getTime()/1000 : "";
      // date= new Date(date).getTime()/1000;
      subType == "editNotification" && formData.append("id", subId);
      formData.append("notification_type", "student_notification");
      formData.append("title", values?.Title);
      formData.append("content", editorState);
      if(previewLoading == false){
        formData.append("publish_date", date);
        formData.append("publish", values?.SendWhen == false ? 0 : 1);
      }
      formData.append("student_id", id);
      formData.append("is_push_notification", values?.sentGroups.PushNotification ? 1 : 0);
      formData.append("is_email", values?.sentGroups.EmailNotification ? 1 : 0);
      formData.append("is_draft", values?.IsDraft);
      formData.append("attachment", values?.FileAttachment);
      formData.append("is_payer", values?.IncludeAccountPayer ? 1 : 0);
      formData.append("is_importance", values?.Important ? 1 : 0);
      formData.append("regarding", "student");
      formData.append("intake", id);
      // if (type == "open") {
      //   UpdateProgramme(formData)
      //     .then((res) => {
      //       Swal.fire({
      //         icon: "success",
      //         title: "Success",
      //         text: "Updated successfully",
      //       });
      //       setDisabled(false);
      //     })
      //     .catch((err) => console.log("error :", err));
      // } else {
      previewLoading ? handlePreview()
      : UpdateStudentNotification(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Added successfully",
          });
          setDisabled(false);
          history.push(`/studentAdministration/students/open/${id}/notifications`);
        })
        .catch((err) => {console.log("error :", err); setDisabled(false);});
      // }
    },
  });

  const handlePreview = () => {
    let date = formik.values.SendWhenDate;
    date = new Date(date).getTime()/1000;
    let formData = new FormData();
    formData.append("isPushnotificationActive", formik.values.sentGroups.PushNotification ? 1 : 0);
    formData.append("subject", formik.values.Title);
    formData.append("message", editorState);
    formData.append("attachment", formik.values.FileAttachment);
    formData.append("intake", id);
    formData.append("isEmailActive", formik.values.sentGroups.EmailNotification ? 1 : 0);
    formData.append("isPayerActive", formik.values?.IncludeAccountPayer ? 1 : 0);
    formData.append("ish_importanceActive", formik.values?.Important ? 1 : 0);
    formData.append("draft_notification", formik.values?.IsDraft ? 1 : 0);
    formData.append("publish", formik.values?.SendWhen);
    if (formik.values?.SendWhen == false) {
      formData.append("publish_date", date);
    }
    PreviewStudentNotification(formData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Preview Send successfully",
        });
        setDisabled(false);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data[0].message,
        });
        console.log("error :", err.response.data[0].message);
      });
  };

  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Name">Student Name *</label>
            <div className="form-icon-group mb-4" title="Student Name">
              <input
                disabled
                type="text"
                className={"form-control" + (formik.errors.Name && formik.touched.Name ? " is-invalid" : "")}
                name="Name"
                id="Name"
                placeholder="Student Name"
                title="Student Name"
                value={formik.values.Name}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Title">Title *</label>
            <div className="form-icon-group mb-4" title="Title">
              <input
                type="text"
                className={"form-control" + (formik.errors.Title && formik.touched.Title ? " is-invalid" : "")}
                name="Title"
                id="Title"
                placeholder="Title"
                title="Title"
                value={formik.values.Title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label></label>
              <div className="custom-check custom-control custom-checkbox mt-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="IncludeAccountPayer"
                  name="select"
                  checked={formik.values.IncludeAccountPayer}
                  onChange={(e) => formik.setFieldValue("IncludeAccountPayer", e.target.checked)}
                />
                <label className="custom-control-label" htmlFor="IncludeAccountPayer">
                  Include Account Payer
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label>&nbsp;</label>
            <div className="form-group-blk mb-3">
              <label></label>
              <div className="custom-check custom-control custom-checkbox custom-control-inline">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="Important"
                  name="select"
                  checked={formik.values.Important}
                  onChange={(e) => formik.setFieldValue("Important", e.target.checked)}
                />
                <label className="custom-control-label" htmlFor="Important">
                  Important
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label>Send Via *</label>
            <div className="form-group-blk mb-3">
              <label></label>
              <div className="custom-check custom-control custom-checkbox custom-control-inline">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="EmailNotification"
                  name="select"
                  checked={formik.values.sentGroups.EmailNotification}
                  onChange={(e) => {
                    formik.setFieldValue("sentGroups.EmailNotification", e.target.checked);
                  }}
                />
                <label className="custom-control-label" htmlFor="EmailNotification">
                  Email
                </label>
              </div>
              <div className="custom-check custom-control custom-checkbox custom-control-inline">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="PushNotification"
                  name="select"
                  checked={formik.values.sentGroups.PushNotification}
                  onChange={(e) => formik.setFieldValue("sentGroups.PushNotification", e.target.checked)}
                />
                <label className="custom-control-label" htmlFor="PushNotification">
                  Push Notification
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label title="SendWhen">Send When *</label>
            <div className="form-icon-group" title="SendWhen">
              <div className="">
                <div className="custom-control custom-radio custom-control-inline" title="Now">
                  <input
                    type="radio"
                    id="customRadioInline1"
                    name="customRadioInline"
                    className="custom-control-input"
                    checked={formik.values.SendWhen == true}
                    onChange={() => formik.setFieldValue("SendWhen", true)}
                    onBlur={formik.handleBlur}
                  />
                  <label className="custom-control-label" htmlFor="customRadioInline1">
                    Now
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline" title="Later">
                  <input
                    type="radio"
                    id="customRadioInline2"
                    name="customRadioInline"
                    className="custom-control-input"
                    title="SendWhen"
                    checked={formik.values.SendWhen == false}
                    onChange={() => formik.setFieldValue("SendWhen", false)}
                    onBlur={formik.handleBlur}
                  />
                  <label className="custom-control-label" htmlFor="customRadioInline2">
                    Later
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label>Upload Attachment</label>
              <div className="form-group atttach-file m-0">
                <label className={formik.errors.FileAttachment && formik.touched.FileAttachment && "file-req is-invalid"}>
                  <i className="fal fa-paperclip"></i>
                  <span>Add Attachment</span>
                  <input
                    type="file"
                    className="form-control  form-control-aatch-file"
                    onChange={(e) => {
                      if (e.target.files.length) {
                        setAttachmentFilePreview(e.target.files[0].name);
                        formik.setFieldValue("FileAttachment", e.target.files[0]);
                        setAttachmentName(URL.createObjectURL(e.target.files[0]));
                      }
                    }}
                  />
                </label>
              </div>
              {attachmentName && (
                <>
                  <div className="frm-group">
                    <ul className="list-unstyled attached-file-ul m-0">
                      <li>
                          <a
                            title={attachmentName}
                            href={attachmentName.includes("blob")
                            ? attachmentName
                            : `${IMAGE_URL}/${attachmentName}`.replace("home/myaie/public_html/", "").replace(
                                "public/",
                                ""
                              )} target="_blank">
                            <i className="fal fa-file"></i>
                            &nbsp;{attachmentFilePreview}
                          </a>
                        <button
                          title="Delete"
                          className="btn btn-danger"
                          onClick={() => {
                            formik.setFieldValue("FileAttachment", "");
                            setAttachmentFilePreview("");
                            setAttachmentName("");
                          }}
                        >
                          <i className="fal fa-trash-alt"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
          {!formik.values.SendWhen ? (
            <div className="col-md-6 col-lg-4">
              <label>Date *</label>
              <div className="form-group-blk mb-3">
                <DatePicker
                  selected={formik.values.SendWhenDate}
                  onChange={(date) => {
                    formik.setFieldValue("SendWhenDate", date);
                  }}
                  // showTimeSelect
                  filterTime={filterPassedTime}
                  dateFormat={FORM_DATE_FORMAT}
                  // className="form-control"
                  className={"form-control cursor-pointer" + (formik.errors.SendWhenDate && formik.touched.SendWhenDate ? " is-invalid" : "")}
                  title="Date"
                  onBlur={formik.handleBlur}
                  minDate={new Date()}
                  placeholderText="Enter Date"
                  onChangeRaw={(e) => e.preventDefault()}
                />
              </div>
              {formik.errors.SendWhenDate && formik.touched.SendWhenDate ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          ) : (
            ""
          )}
          <div className="col-md-12">
            <label>Content *</label>
            <div className="form-icon-group mb-4 d-block">
              {((editNotificationData && editorState) || subType=="addNotification") && <HtmlInputEditor
                editorState={editorState}
                setEditorState={setEditorState}
                hideSign={true}
                isCKEditor={true}
                isInvalid={
                  formik.touched.Content &&
                  formik.errors.Content
                } />}
            </div>
          </div>
        </div>

        <div className="form-group form-group-save-cancel">
          <PermissionsGate scopes={["snedit"]} errorProps={{ disabled: true }}>
            <button className="btn btn-save btn-success" type="submit" title="Send" disabled={disabled && gearLoading=="send" ? true : false} onClick={() => {setGearLoading("send"); setPreviewLoading(false); formik.setFieldValue("IsDraft", 0)}}>
              {gearLoading=="send" ? <i className="fas fa-cog fa-spin"></i> : <i className="fa fa-paper-plane"></i>}
              Send
            </button>
          </PermissionsGate>
          <PermissionsGate scopes={["snedit"]} errorProps={{ disabled: true }}>
            <button className="btn btn-save btn-success" type="submit" title="Save As Draft" disabled={disabled && gearLoading=="save" ? true : false} onClick={() => {setGearLoading("save"); setPreviewLoading(false); formik.setFieldValue("IsDraft", 1)}}>
              {disabled && gearLoading=="save" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-plus"></i>}
              Save As Draft
            </button>
          </PermissionsGate>
          <PermissionsGate scopes={["snedit"]} errorProps={{ disabled: true }}>
            <button className="btn btn-save btn-success" type="submit" title="Preview" disabled={disabled && previewLoading} onClick={() => {setPreviewLoading(true); formik.setFieldValue("IsDraft", 0)}}>
              {disabled && previewLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-file-alt"></i>}
              Preview
            </button>
          </PermissionsGate>
          <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()}>
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {formik.touched.Name && formik.errors.Name ? <div className="invalid-feedback d-block">{formik.errors.Name}</div> : null}
          {formik.touched.SendWhen && formik.errors.SendWhen ? <div className="invalid-feedback d-block">{formik.errors.SendWhen}</div> : null}
          {formik.touched.Title && formik.errors.Title ? <div className="invalid-feedback d-block">{formik.errors.Title}</div> : null}
          {formik.touched.SendWhenDate && formik.errors.SendWhenDate ? <div className="invalid-feedback d-block">{formik.errors.SendWhenDate}</div> : null}
          {formik.touched.Content && formik.errors.Content ? <div className="invalid-feedback d-block">{formik.errors.Content}</div> : null}
          {formik.touched.sentGroups?.EmailNotification && formik.errors.sentGroups?.EmailNotification ? (
            <div className="invalid-feedback d-block">{formik.errors.sentGroups?.EmailNotification}</div>
          ) : null}
        </div>
      </form>
    </div>
  );
}
export default NotificationForm;
