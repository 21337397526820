import React, { useState, useEffect, useMemo } from "react";
import { InitialRender } from "../../common/Helper";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import {
  IMAGE_URL,
  TABLE_DATE_FORMAT,
  TABLE_ROWS_PER_PAGE,
  TABLE_TIME_FORMAT,
} from "../../../utils/Constants";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import {
  DeleteStudentRegistration,
  GetAllDropdownValues,
  GetReRegStudentListFilters,
  ReRegisterStudent,
} from "../../../services/RegistrationService";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { RegStudentColorComb } from "../../systemadministration/usermanagement/CheckRole";
import { TrimText } from "../../common/TrimText";
import Hover from "../../common/Hover";
import Tablefilter from "../../common/Tablefilter";
import { RenderReason } from "../../../utils/CommonStatusItems";
import Swal from "sweetalert2";
import PermissionsGate from "../../../utils/permissionGate";
import {
  GetStudentListBasedOnArticulationCancelToken,
  GetStudentListBasedOnArticulationFiltersCancelToken,
  articulationContactedSubmitStatus,
  getStudentListBasedOnArticulation,
  getStudentListBasedOnArticulationFilters,
} from "../../../services/programArticulationService";
import $ from "jquery";
import Select from "react-select";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import DataTableComponent from "../../common/DataTableComponent";
import axios from "axios";

const StudentList = () => {
  const { id } = useParams();
  const history = useHistory();

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [studentListStatus, setStudentListStatus] = useState([]);
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [reason, setReason] = useState({ arr: [], checkObj: {} });
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [programme, setProgramme] = useState({ arr: [], checkObj: {} });
  const [showCkEditor, setShowCkEditor] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [studentID, setStudentID] = useState("");
  const [contactedNotes, setContactedNotes] = useState("");
  const [ContactLoading, setContactLoading] = useState(false)
  const [salesRep, setSalesRep] = useState({ arr: [], checkObj: {} });
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "submit_date",
    sortOrder: "DESC"
  })
  const [totalRows, setTotalRows] = useState(0);
  const [cancelationLoading, setCancelationLoading] = useState(false)

  const [filterData, setFilterData] = useState({
    intake: [],
    reason: [],
    status: [],
    programme: [],
    salesRep: [],
  })
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["cparasview"], permissions: givenPermsisions, });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateArticulationStudents"));
    if (storedFilters) {
      setProgramme(storedFilters.programme);
      setReason(storedFilters.reason);
      setStatus(storedFilters.status);
      setSalesRep(storedFilters.salesRep);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      programme,
      reason,
      status,
      salesRep
    };
    localStorage.setItem("filterStateArticulationStudents", JSON.stringify(filterState));
  }, [  programme, reason, status, salesRep]);

  useEffect(() => {
    const cancelTokenSources = [];
    const getListData = async () => {
      setLoading(true);

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      let apiData = {
        viaProgram: programme.arr,
        viaStatus: status.arr,
        viaReason: reason.arr,
        viaSalesRep: salesRep.arr,
        search,
        key: tableState.sortKey,
        page: tableState.page,
        limit: tableState.perPage,
        order: tableState.sortOrder,
        articulation_id: id
      }

      try {
        const res = await GetStudentListBasedOnArticulationCancelToken(apiData, source.token);
        setStudentListStatus(res.data?.studentsList);
        setTotalRows(res.data?.pagination.total)
        if (res.status === 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
    getListData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [tableState, search, salesRep, reason, status, programme, cancelationLoading]);

    useEffect(() => {
      const dropdownSource = axios.CancelToken.source();
    
      const fetchData = async () => {
        try {
          const res = await GetStudentListBasedOnArticulationFiltersCancelToken(id, dropdownSource.token);
          if (res.status === 200) {
            setFilterData({
              intake: res.data.intakeFilter,
              reason: res.data.reasonFilter,
              status: res.data.statusFilter,
              programme: res?.data?.programmeFilter,
              salesRep: res?.data.salesPersonFilter,
            });
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
        }
      };
    
      fetchData();
    
      return () => {
        dropdownSource.cancel('Component unmounted');
      };
  }, [cancelationLoading]);

  const handleConatactedStudent = () => {
    setContactLoading(true)
    let data = {
      articulation_id: id,
      status: studentID?.registration_status?.includes("Contacted")
        ? studentID?.registration_status
        : studentID?.registration_status + " - Contacted",
      articulation_notes: contactedNotes,
      student_id: studentID?.student,
      programme_id: studentID?.qualification_id
    };
    articulationContactedSubmitStatus(data)
      .then((res) => {
        setContactLoading(false)
        if (res.status === 200) {
          $("#feature-popup").modal("hide");
          setShowCkEditor(false);
          setStudentID("");
          setContactedNotes("");
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
            onClose: () => {
              setCancelationLoading(!cancelationLoading)
            }
          });
        }
      })
      .catch((err) => {
        setContactLoading(false)
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = studentListStatus;
    if (search.length) {
      let tempStdNo = allData.filter((item) => {
        let includes = item.student_number
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempFirstname = allData.filter((item) => {
        let includes =
          item.student_name &&
          item.student_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSalesRepFirstname = allData.filter((item) => {
        let includes =
          item.sale_person_first_name &&
          item.sale_person_first_name
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSalesRepLastname = allData.filter((item) => {
        let includes =
          item.sale_person_last_name &&
          item.sale_person_last_name
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempYear = allData.filter((item) => {
        let includes =
          item.year && item.year.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudentId = allData.filter((item) => {
        let includes =
          item.student_id &&
          item.student_id.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempProgramme = allData.filter((item) => {
        if (item.qualification_name && Array.isArray(item.qualification_name)) {
          let includes = item.qualification_name.some((qualification) =>
            qualification.course_name
              .toLowerCase()
              .includes(search.toLowerCase())
          );
          if (includes) {
            return true;
          }
        }
        return false;
      });
      let tempCreatedDate = allData.filter((item) => {
        let includes =
          item.created_date &&
          moment
            .unix(item.created_date)
            .format(TABLE_DATE_FORMAT)
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        ...tempStdNo,
        ...tempFirstname,
        ...tempYear,
        ...tempStudentId,
        ...tempProgramme,
        ...tempCreatedDate,
        ...tempSalesRepFirstname,
        ...tempSalesRepLastname,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (intake.arr.length) {
      let tempStatus = updatedData;
      let tempIntakeYear = tempStatus.filter((item) => {
        const startsIntakeYear = intake.arr.find(function (post) {
          if (post == (item.full_intake_value ? item.full_intake_value : ""))
            return item;
        });
        if (startsIntakeYear) {
          return startsIntakeYear;
        } else return null;
      });

      updatedData = tempIntakeYear;
    }

    if (status.arr.length) {
      let tempStatus = updatedData;
      let tempStatusData = tempStatus.filter((item) => {
        const findStatus = status.arr.find(function (post) {
          if (
            post == (item.registration_status ? item.registration_status : "")
          )
            return item;
        });
        if (findStatus) {
          return findStatus;
        } else return null;
      });

      updatedData = tempStatusData;
    }

    if (salesRep.arr.length) {
      let tempSalesRep = updatedData;
      let tempSalesRepData = tempSalesRep.filter((item) => {
        const findSalesRep = salesRep.arr.find(function (post) {
          if (post == (item.sale_person_id ? item.sale_person_id : ""))
            return item;
        });
        if (findSalesRep) {
          return findSalesRep;
        } else return null;
      });

      updatedData = tempSalesRepData;
    }


    if (reason.arr.length) {
      let tempReason = updatedData;
      let tempReasonData = tempReason.filter((item) => {
        const findReason = reason.arr.find(function (post) {
          if (post == (item.not_submit_reason ? item.not_submit_reason : ""))
            return item;
        });
        if (findReason) {
          return findReason;
        } else return null;
      });

      updatedData = tempReasonData;
    }

    if (programme.arr.length) {
      let tempStatus = updatedData;
      let tempStatusData = tempStatus.filter((item) => {
        const findStatus = programme.arr.find((post) => {
          if (Array.isArray(item.qualification_name)) {
            return item.qualification_name.some(
              (qualification) => qualification.course_name === post
            );
          } else {
            return (
              post === (item.qualification_name ? item.qualification_name : "")
            );
          }
        });
        if (findStatus) {
          return true;
        } else {
          return false;
        }
      });

      updatedData = tempStatusData;
    }

    if (
      search.length ||
      intake.arr.length ||
      status.arr.length ||
      reason.arr.length ||
      programme.arr.length ||
      salesRep.arr.length
    ) {
      return updatedData;
    } else {
      return studentListStatus;
    }
  };

  const columns = useMemo(() => [
    {
      name: "Student",
      selector: "student_name",
      sortField: "student_name",
      sortable: true,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon cat-dark-red "}>
              {row?.photo ? (
                <img
                  src={`${IMAGE_URL}/${row?.photo
                    .replaceAll("/home/myaie/public_html/", "")
                    .replace("public/", "")}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([
                  row.student_name.split(" ")[0],
                  row.student_name.split(" ").reverse()[0],
                ])
              )}
              <span
                className={`profile-box-2-status ${row.activity_status
                  ? row.activity_status.toLowerCase() == "online"
                    ? "Online"
                    : row.activity_status.toLowerCase() == "away"
                      ? "Away"
                      : "Offline"
                  : "Offline"
                  }`}
              >
                <i className="fas fa-circle"></i>
              </span>
              <Hover
                firstName={row.student_name.split(" ")[0]}
                lastName={row.student_name.split(" ").reverse()[0]}
                photo={row.photo}
                email={row.email}
                mobile={row.mobile}
                right={true}
                role="Sales"
                status={row.status}
                activity_status={row.activity_status}
                showNumber={true}
                number={row.student_crm_id}
              />
            </span>
            <Link
              to={`/studentAdministration/students/open/${row.student}/general`}
              className="as-text-blue curser"
            >
              <span className="textLimit100">{row.student_name}</span>
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Articulation Status",
      selector: "registration_status",
      sortField: "registration_status",
      sortable: true,
      cell: (row) =>
        row.registration_status ? (
          <span title={row.registration_status}>
            {RegStudentColorComb(row.registration_status.toLowerCase())}
            {row.articulation_notes ? (
              <p className="decline-reason-comment" title={row?.articulation_notes}>
                {row.articulation_notes}
              </p>
            ) : null}
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Decline Reason",
      selector: "not_submit_reason",
      sortField: "not_submit_reason",
      sortable: true,
      cell: (row) => {
        if (row.not_submit_reason && filterData.reason.length) {
          const rowReason = filterData.reason.find(
            (item) => item.value == row.not_submit_reason
          );
          return (
            <span title={row.not_submit_reason_description}>
              {RenderReason(rowReason.label, rowReason.color).html}
              {row.not_submit_reason_description ? (
                <p className="decline-reason-comment">
                  {row.not_submit_reason_description}
                </p>
              ) : null}
            </span>
          );
        } else {
          return "N/A";
        }
      },
    },
    {
      name: "Student No.",
      selector: "student_number",
      sortField: "student_number",
      sortable: true,
      cell: (row) =>
        row.student_number ? (
          <span className="feature-name">
            <span className="textLimit100">{row.student_number}</span>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Student ID",
      selector: "student_id",
      sortField: "student_id",
      sortable: false,
      cell: (row) =>
        row.student_id ? (
          <span className="feature-name">
            <span className="textLimit100">{row.student_id}</span>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Articulation Programmes",
      selector: "qualification_name",
      sortField: "qualification_name",
      sortable: false,
      cell: (row) => {
        const qualificationNames = row.getQualificationDetails || [];
        const showLimit = 2;
        const shouldDisplaySeeMore = qualificationNames.length > showLimit;

        const toggleExpanded = (rowId) => {
          if (expandedRows.includes(rowId)) {
            setExpandedRows(expandedRows.filter((id) => id !== rowId));
          } else {
            setExpandedRows([...expandedRows, rowId]);
          }
        };

        return (
          <>
            <>
              {(qualificationNames.length > 3
                ? qualificationNames.slice(0, showLimit)
                : qualificationNames
              ).map((qualification, index) => (
                <li key={index} className="as-text-blue cursor">
                  <Link
                    to={`/courseAdministration/Programmes/programme/open/${qualification?.programme_id}/details`}
                    className="as-text-blue cursor"
                    title={qualification.course}
                  >
                    {TrimText(qualification.course, 25)}
                  </Link>
                </li>
              ))}
              {qualificationNames.length > 3 &&
                !expandedRows.includes(row.id) && (
                  <span
                    className="see-more cursor-pointer as-text-blue"
                    onClick={() => toggleExpanded(row.id)}
                  >
                    See more...
                  </span>
                )}
              {expandedRows.includes(row.id) && (
                <>
                  {qualificationNames
                    .slice(showLimit)
                    .map((qualification, index) => (
                      <li key={index} className="as-text-blue cursor">
                        <Link
                          to={`/courseAdministration/Programmes/programme/open/${qualification?.programme_id}/details`}
                          className="as-text-blue cursor"
                          title={qualification.course}
                        >
                          {TrimText(qualification.course, 25)}
                        </Link>
                      </li>
                    ))}
                  <span
                    className="see-less cursor-pointer as-text-blue"
                    onClick={() => toggleExpanded(row.id)}
                  >
                    See less...
                  </span>
                </>
              )}
              {qualificationNames.length === 0 && (
                <span className="articulation-programme-status">N/A</span>
              )}
            </>
          </>
        );
      },
    },

    {
      name: "Intake",
      selector: "full_intake_value",
      sortField: "full_intake_value",
      sortable: true,
      cell: (row) => row.intake_year + "-" + row.intake_number,
    },
    {
      name: "Date",
      selector: "submit_date",
      sortField: "submit_date",
      sortable: true,
      cell: (row) => (
        <>
          {row.registration_status === "Interested" ? (
            <div className="dateTime">
              <p className="right-space">
                {moment
                  .unix(row.submit_date)
                  .format(TABLE_DATE_FORMAT)}
              </p>
              <p>
                {moment
                  .unix(row.submit_date)
                  .format(TABLE_TIME_FORMAT)}
              </p>
            </div>
          ) : row.submit_date ? (
            <div className="dateTime">
              <p className="right-space">
                {moment.unix(row.submit_date).format(TABLE_DATE_FORMAT)}
              </p>
              <p>{moment.unix(row.submit_date).format(TABLE_TIME_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) => {
        return row.status ? RegStudentColorComb(row.status.toLowerCase()) : "-";
      },
    },
    {
      name: "Sales Rep.",
      selector: "sale_person_first_name",
      sortField: "sale_person_first_name",
      sortable: true,
      // sortFunction: sortingSalesRep,
      cell: (row) =>
        row?.sale_person_first_name ? (
          <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span className={"assigned-title-blk  name-icon cat-dark-red "}>
                {row?.sale_person_photo ? (
                  <img
                    src={`${IMAGE_URL}/${row?.sale_person_photo
                      .replaceAll("/home/myaie/public_html/", "")
                      .replace("public/", "")}`}
                    alt="AIE"
                  />
                ) : (
                  InitialRender([
                    row.sale_person_first_name,
                    row.sale_person_last_name,
                  ])
                )}
                <span
                  className={`profile-box-2-status ${row.sale_person_activity_status
                    ? row.sale_person_activity_status.toLowerCase() ==
                      "online"
                      ? "Online"
                      : row.sale_person_activity_status.toLowerCase() ==
                        "away"
                        ? "Away"
                        : "Offline"
                    : "Offline"
                    }`}
                >
                  <i className="fas fa-circle"></i>
                </span>
                <Hover
                  firstName={row.sale_person_first_name}
                  lastName={row.sale_person_last_name}
                  photo={row.sale_person_photo}
                  email={row.sale_person_email}
                  mobile={row.sale_person_mobile}
                  // right={true}
                  role="Sales"
                  activity_status={row.sale_person_activity_status}
                  showNumber={true}
                />
              </span>
              <Link
                to={`/studentAdministration/students/open/${row.sale_person_id}/general`}
                className="as-text-blue curser"
              >
                <span className="textLimit100">
                  {row?.sale_person_first_name +
                    " " +
                    row?.sale_person_last_name}
                </span>
              </Link>
            </div>
          </div>
        ) : (
          "-"
        ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              className="btn btn-primary rounded-circle"
              to={`/studentAdministration/students/open/${row.student}/general`}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            <PermissionsGate scopes={["cparslmac"]} errorProps={{ disabled: true }}>
              <button
                className="btn btn-primary rounded-circle"
                title="Mark As Called"
                onClick={() => {
                  $("#feature-popup").modal("show");
                  setShowCkEditor(true);
                  setStudentID(row);
                }}
              >
                <i className="fal fa-phone"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let apiData = {
      viaProgram: programme.arr,
      viaStatus: status.arr,
      viaReason: reason.arr,
      viaSalesRep: salesRep.arr,
      search,
      key: tableState.sortKey,
      page: tableState.page,
      limit: tableState.perPage,
      order: tableState.sortOrder,
      articulation_id: id,
      exportStatus: "true"
    }
    getStudentListBasedOnArticulation(apiData).then(res => {
      let data = res.data?.studentsList

    let header = [
      "Student",
      "Re-registration Status",
      "Decline Reason",
      "Decline Reason Description",
      "Student No.",
      "Student Id",
      "Programme",
      "Intake",
      "Date",
      "Status",
    ];

    data = data?.map((row) => ({
      ...row,
      Student: row.student_name ? row.student_name : "-",
      "Re-registration Status": row?.registration_status
        ? filterData.status.find(
          (item) => item.value == row.registration_status
        ).label
        : "-",
      "Decline Reason": row?.not_submit_reason
        ? filterData.reason.find((item) => item.value == row.not_submit_reason)
          .label
        : "-",
      "Decline Reason Description": row?.not_submit_reason_description
        ? row?.not_submit_reason_description
        : "-",
      "Student No.": row.student_number ? row.student_number : "-",
      "Student Id": row.student_id ? row.student_id : "-",
      Programme: row.getQualificationDetails
        ? row.getQualificationDetails
          .map((qualification) => {
            return qualification?.course;
          })
          .toString()
        : "-",
      Intake: row.intake_year ? row.intake_year + "-" + row.intake_number : "-",
      Date:
        row.registration_status === "Interested"
          ? `${moment
            .unix(row.submit_date)
            .format(TABLE_DATE_FORMAT)},${moment
              .unix(row.submit_date)
              .format(TABLE_TIME_FORMAT)}`
          : row.submit_date
            ? `${moment.unix(row.submit_date).format(TABLE_DATE_FORMAT)},${moment
              .unix(row.submit_date)
              .format(TABLE_TIME_FORMAT)}`
            : "-",

      Status: row.status ? row.status : "-",
    }));


    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Student"],
          row["Re-registration Status"],
          row["Decline Reason"],
          row["Decline Reason Description"],
          row["Student No."],
          row["Student Id"],
          row["Programme"],
          row["Intake"],
          row["Date"],
          row["Status"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
    }).catch(err => console.error("error :", err))
  };

  return (
    <>
      <div className="tabs-wrap">
        <div>
          <div>
            <DataTableComponent
              data={studentListStatus}
              columns={columns}
              exportFunction={exportData}
              state={tableState}
              setState={setTableState}
              setSearch={setSearch}
              totalRows={totalRows}
              exportFileName="Articulation_Student_List"
              loading={loading}
              filters={[
                {
                  filterName: "Articulation Registration Status",
                  optionArr: filterData.status || [],
                  state: status,
                  setState: setStatus,
                  isOptionReversed: true,
                  stopOptionSorting:true,
                  uniqueId: "re-reg-status"
                },
                {
                  filterName: "Decline Reason",
                  uniqueId: "re-reg-reason",
                  optionArr: filterData.reason || [],
                  state: reason,
                  setState: setReason,
                  isOptionReversed:false,
                  stopOptionSorting:true,
                  renderLabelFunction: RenderReason,
                },
                {
                  filterName: "Articulations Programmes",
                  optionArr: filterData.programme || [],
                  state: programme,
                  setState: setProgramme,
                  isOptionReversed: true,
                  stopOptionSorting:true
                },
                {
                  filterName: "Sales Rep",
                  optionArr: filterData.salesRep || [],
                  state: salesRep,
                  setState: setSalesRep,
                  isOptionReversed: true,
                  stopOptionSorting:true
                }
              ]}
            />
            {/* <div className="my-tickets-info-list Tickets-main-wrap">
              <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
                <div className="search-filter-div">
                  <div className="search-filter-div-left">
                    <div className="system-administration-table table-responsive">
                      <div className="table-responsive-div">
                        <div
                          id="assessment-table-main_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <div
                            id="assessment-table-main_filter"
                            className="dataTables_filter"
                          >
                            <label>
                              <input
                                type="search"
                                className=""
                                placeholder="Search"
                                aria-controls="assessment-table-main"
                                onChange={(e) => handleFilters(e, "search")}
                                value={search}
                              />
                            </label>
                            <div className="filter-eff filter-data-btn">
                              <button className="filter-buttons">
                                <i className="fal fa-filter"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="filter-button-group">
                        <div className="filter-scroll">
                          <div
                            className={`filter-scroll-inner  filter-custom-new`}
                          >
                            <Tablefilter
                              filterName={"Articulation Registration Status"}
                              optionArr={filterData.status}
                              state={status}
                              setState={setStatus}
                              isOptionReversed={true}
                              uniqueId="re-reg-status"
                            />
                            <Tablefilter
                              filterName={"Decline Reason"}
                              uniqueId="re-reg-reason"
                              optionArr={filterData.reason}
                              state={reason}
                              setState={setReason}
                              isOptionReversed={true}
                              renderLabelFunction={RenderReason}
                            />
                            <Tablefilter
                              filterName={"Articulations Programmes"}
                              optionArr={filterData.programme}
                              state={programme}
                              setState={setProgramme}
                              isOptionReversed={true}
                            />
                            <Tablefilter
                              filterName={"Sales Rep"}
                              optionArr={filterData.salesRep}
                              state={salesRep}
                              setState={setSalesRep}
                              isOptionReversed={true}
                            />
                          </div>
                        </div>
                        <div className="reset-btn-group">
                          <div className="button-reset dropdown-comman">
                            <button
                              className="btn btn-primary"
                              title="Reset"
                              onClick={resetFilter}
                            >
                              <i className="fal fa-redo"></i>Reset
                            </button>
                          </div>
                          <div className="files-export-group">
                            <button
                              type="button"
                              className="btn btn-files"
                              onClick={() => {
                                exportData("xlsx", "Articulation List");
                              }}
                              title="Export spreadsheet"
                            >
                              <i className="fal fa-file-excel icon"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-files"
                              onClick={() => {
                                exportData("csv", "Articulation List");
                              }}
                              title="Export CSV"
                            >
                              <i className="fal fa-file-csv icon"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-files"
                              onClick={() => {
                                exportData("pdf", "Articulation List");
                              }}
                              title="Export PDF"
                            >
                              <i className="fal fa-file-pdf icon"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <SkeletonTicketList />
                ) : (
                  <DataTable
                    data={dataToRender()}
                    defaultSortField={"created_date"}
                    defaultSortAsc={false}
                    columns={columns}
                    pagination={true}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                  />
                )}
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {showCkEditor && (
        <div
          // ref={editorRef}
          className="topic-add-modal modal fade show"
          style={{ display: "block" }}
          id="feature-popup"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div className="modal-content modal-border-update">
              {/* <form onSubmit={formik.handleSubmit}> */}
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fal fa-phone"></i> Mark As Called
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      $("#feature-popup").modal("hide");
                      setShowCkEditor(false);
                      setStudentID("");
                      setContactedNotes("");
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="t p-30px">
                  <div className="topic-add-modal-content">
                    <div className="row center-label">
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Notes</label>
                          <textarea
                            className={
                              "form-control" + (false ? " is-invalid" : "")
                            }
                            placeholder="Add Notes"
                            title="Notes"
                            value={contactedNotes}
                            onChange={(e) => setContactedNotes(e.target.value)}
                            rows={3}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-group-save-cancel mt-3">
                    <button
                      className="btn btn-primary"
                      title="Save"
                      onClick={() => handleConatactedStudent()}
                      disabled={!contactedNotes}
                    >
                      {ContactLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                      Save
                    </button>
                    <button
                      className="btn btn-close btn-danger"
                      type="button"
                      title="Cancel"
                      onClick={() => {
                        $("#feature-popup").modal("hide");
                        setShowCkEditor(false);
                        setStudentID("");
                        setContactedNotes("");
                      }}
                    >
                      <i className="fal fa-times"></i>Cancel
                    </button>
                    {/* {Object.keys(formik.values).map((key) => {
                          if (formik.touched[key] && formik.errors[key]) {
                            return (
                              <div className="invalid-feedback d-block">
                                {formik.errors[key]}
                              </div>
                            );
                          }
                        })} */}
                  </div>
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentList;
