/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import 'owl.carousel/dist/assets/owl.theme.default.css';

// Import Swiper React components
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GetBanner } from "../../services/HomeService";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination]);
const HomeSlider = (props) => {
  const [banner, setBanner] = useState([]);
  const [bannerImg, setBannerImg] = useState([]);

  useEffect(() => {
    let domain;
    if (window.location.hostname.includes("myaie.ac") || window.location.host.includes("localhost:3000")) {
      domain = "aie";
    } else if (window.location.hostname.includes("mygdc.ac") || window.location.host.includes("localhost:3001")) {
      domain = "gdc";
    }
    let mounted = true;
    // GetBanner({domain:domain})
    //   .then((res) => {
    //     if (mounted) {
    //     setBanner(res.data);

    //       if (res.data.length > 0) {
    //         switch (res.data.length) {
    //           case 1:
    //             setBannerImg([100]);
    //             break;

    //           case 2:
    //             setBannerImg([50, 50]);
    //             break;

    //           case 3:
    //             setBannerImg([50, 25, 25]);
    //             break;

    //           case 4:
    //             setBannerImg([50, 25, 25, 50]);
    //             break;

    //           default:
    //             setBannerImg([100]);
    //             break;
    //         }
    //       }
    //     }
    //   })
    //   .catch(function (err) {
    //     return false;
    //   });
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const bannerLength = banner.length;
  return (
    <div className="home-slider-wraper">
      <Swiper
        spaceBetween={10}
        slidesPerView={"auto"}
        observer={"true"}
        watchOverflow={"true"}
        //onSwiper={(swiper) => ()}
        centeredSlides={false}
        className="swiper-home"
        pagination={{ clickable: true }}
      >
        {bannerLength == 1 && (
          <SwiperSlide
            className="swiper-slide w-100"
            style={{
              backgroundImage: `url('${banner[0].banner_img}')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "190px",
            }}
          >
            <div className="home-slider-inr">
              <div className="home-slider-content">
                <h4 className="heading">{banner[0].banner_heading}</h4>
                <p className="text-max-80">{banner[0].banner_subject}</p>
                {banner[0].banner_url != "" ? (
                  <a href={banner[0].banner_url} target="_blank" className="btn btn-white-bordered  btn-learn-more">
                    Learn More
                  </a>
                ) : null}
              </div>
            </div>
          </SwiperSlide>
        )}
        {bannerLength == 2 && (
          <React.Fragment>
            <SwiperSlide
              className="swiper-slide w-50"
              style={{
                backgroundImage: `url('${banner[0].banner_img}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "190px",
              }}
            >
              <div className="home-slider-inr">
                <div className="home-slider-content">
                  <h4 className="heading">{banner[0].banner_heading}</h4>
                  <p className="text-max-80">{banner[0].banner_subject}</p>
                  {banner[0].banner_url != "" ? (
                    <a href={banner[0].banner_url} target="_blank" className="btn btn-white-bordered  btn-learn-more">
                      Learn More
                    </a>
                  ) : null}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide w-50"
              style={{
                backgroundImage: `url('${banner[1].banner_img}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "190px",
              }}
            >
              <div className="home-slider-inr">
                <div className="home-slider-content">
                  <h4 className="heading">{banner[1].banner_heading}</h4>
                  <p>{banner[1].banner_subject}</p>
                  {banner[1].banner_url != "" ? (
                    <a href={banner[1].banner_url} target="_blank" className="btn btn-white-bordered  btn-learn-more">
                      Learn More
                    </a>
                  ) : null}
                </div>
              </div>
            </SwiperSlide>
          </React.Fragment>
        )}
        {bannerLength == 3 && (
          <React.Fragment>
            <SwiperSlide
              className="swiper-slide w-50"
              style={{
                backgroundImage: `url('${banner[0].banner_img}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "190px",
              }}
            >
              <div className="home-slider-inr">
                <div className="home-slider-content">
                  <h4 className="heading">{banner[0].banner_heading}</h4>
                  <p className="text-max-80">{banner[0].banner_subject}</p>
                  {banner[0].banner_url != "" ? (
                    <a href={banner[0].banner_url} target="_blank" className="btn btn-white-bordered  btn-learn-more">
                      Learn More
                    </a>
                  ) : null}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide w-25"
              style={{
                backgroundImage: `url('${banner[1].banner_img}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "190px",
              }}
            >
              <div className="home-slider-inr">
                <div className="home-slider-content">
                  <h4 className="heading">{banner[1].banner_heading}</h4>
                  <p>{banner[1].banner_subject}</p>
                  {banner[1].banner_url != "" ? (
                    <a href={banner[1].banner_url} target="_blank" className="btn btn-white-bordered  btn-learn-more">
                      Learn More
                    </a>
                  ) : null}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide w-25"
              style={{
                backgroundImage: `url('${banner[2].banner_img}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "190px",
              }}
            >
              <div className="home-slider-inr">
                <div className="home-slider-content">
                  <h4 className="heading">{banner[2].banner_heading}</h4>
                  <p>{banner[2].banner_subject}</p>
                  {banner[2].banner_url != "" ? (
                    <a href={banner[2].banner_url} target="_blank" className="btn btn-white-bordered  btn-learn-more">
                      Learn More
                    </a>
                  ) : null}
                </div>
              </div>
            </SwiperSlide>
          </React.Fragment>
        )}
        {bannerLength == 4 && (
          <React.Fragment>
            <SwiperSlide
              className="swiper-slide w-50"
              style={{
                backgroundImage: `url('${banner[0].banner_img}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "190px",
              }}
            >
              <div className="home-slider-inr">
                <div className="home-slider-content">
                  <h4 className="heading">{banner[0].banner_heading}</h4>
                  <p className="text-max-80">{banner[0].banner_subject}</p>
                  {banner[0].banner_url != "" ? (
                    <a href={banner[0].banner_url} target="_blank" className="btn btn-white-bordered  btn-learn-more">
                      Learn More
                    </a>
                  ) : null}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide w-25"
              style={{
                backgroundImage: `url('${banner[1].banner_img}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "190px",
              }}
            >
              <div className="home-slider-inr">
                <div className="home-slider-content">
                  <h4 className="heading">{banner[1].banner_heading}</h4>
                  <p>{banner[1].banner_subject}</p>
                  {banner[1].banner_url != "" ? (
                    <a href={banner[1].banner_url} target="_blank" className="btn btn-white-bordered  btn-learn-more">
                      Learn More
                    </a>
                  ) : null}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide w-25"
              style={{
                backgroundImage: `url('${banner[2].banner_img}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "190px",
              }}
            >
              <div className="home-slider-inr">
                <div className="home-slider-content">
                  <h4 className="heading">{banner[2].banner_heading}</h4>
                  <p>{banner[2].banner_subject}</p>
                  {banner[2].banner_url != "" ? (
                    <a href={banner[2].banner_url} target="_blank" className="btn btn-white-bordered  btn-learn-more">
                      Learn More
                    </a>
                  ) : null}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="swiper-slide w-50"
              style={{
                backgroundImage: `url('${banner[3].banner_img}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "190px",
              }}
            >
              <div className="home-slider-inr">
                <div className="home-slider-content">
                  <h4 className="heading">{banner[3].banner_heading}</h4>
                  <p className="text-max-80">{banner[3].banner_subject}</p>
                  {banner[3].banner_url != "" ? (
                    <a href={banner[3].banner_url} target="_blank" className="btn btn-white-bordered  btn-learn-more">
                      Learn More
                    </a>
                  ) : null}
                </div>
              </div>
            </SwiperSlide>
          </React.Fragment>
        )}
      </Swiper>
    </div>
  );
};

export default HomeSlider;
