import axios from "axios";
import { API_BASE } from "../utils/Constants";
import { getToken, removeUserSession } from "../utils/Auth";
export default (history = null) => {
  const baseURL = API_BASE;

  let headers = {};

  if (getToken()) {
    headers.Authorization = `Bearer ${getToken()}`;
  }

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      if (error.response.status === 401 && !error.response.data.message) {
        removeUserSession();

        if (history) {
          history.push("/");
        } else {
          window.location = "/";
        }
      } else {
        if (
          error.response.status === 401 &&
          error.response.data.code === "E_JWT_TOKEN_EXPIRED"
        ) {
          removeUserSession();
          if (history) {
            history.push("/");
          } else {
            window.location = "/";
          }
        }

        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  return axiosInstance;
};

