import { param } from "jquery";
import axiosInstance from "../utils/axios";

export const getMyExam = async (exam = 0) => {
  return await axiosInstance().get(`/getMyExam`, {
    params: { examType: "in_progress", exam: exam },
  });
};

export const getMyAllExam = async (exam = 0) => {
  return await axiosInstance().get(`/getMyAllExam`, {
    params: { examType: "in_progress", exam: exam },
  });
};

export const getMyAllExamCountService = async (exam = 0) => {
  return await axiosInstance().get(`/getMyAllExamCount`, {
    params: { examType: "in_progress", exam: exam },
  });
};

export const getMyCompletedExam = async () => {
  return await axiosInstance().get(`/getMyExam`, {
    params: { examType: "completed" },
  });
};

export const getMyExamRegistrationForm = async (exam) => {
  return await axiosInstance().get(`/getMyExamRegistrationForm`, {
    params: { exam: exam },
  });
};

export const getExamSubjectList = async (exam) => {
  return await axiosInstance().get(`/getExamSubjectList`, {
    params: { exam: exam },
  });
};

export const getExamCenterList = async (exam) => {
  return await axiosInstance().get(`/getExamCenterList`, {
    params: { exam: exam },
  });
};

export const getExamCenterDetails = async (center) => {
  return await axiosInstance().get(`/getExamCenterDetails`, {
    params: { center: center },
  });
};

export const submitExamRegistration = async (values) => {
  return await axiosInstance().post(`/submitExamRegistration`, values);
};

export const getSubmitedExamRegistration = async (examid) => {
  return await axiosInstance().get(`/getSubmitedExamRegistration`, {
    params: { examid: examid },
  });
};
