import React from 'react'
import { useParams } from 'react-router-dom'
import AuditTrail from './AuditTrail';
import CreateTab from './CreateTab';
import EmployeeAuditTrail from './EmployeeAuditTrail';
import RearrangeTab from './RearrangeTab';
import TabList from './TabList';

export default function StudentHubManagement() {
    const {page} = useParams();

    return (
        <>
        {page==="list" && <TabList />}
        {page==="student-audit-trail" && <AuditTrail /> }
        {page==="employee-audit-trail" && <EmployeeAuditTrail /> }
        {page==="rearrange" && <RearrangeTab /> }
        {page==="create" && <CreateTab /> }
        </>
        
    )
}
