import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import axios from "../../../../utils/axios";
import { FileUploadField, FormField } from "../../../common/FormFields";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addEventLandingPage,
  getEventLandingPageDetails,
  getUpcomingEventLandingDropDown,
  updateEventLandingPage,
} from "../../../../services/EventService";
import PermissionsGate from "../../../../utils/permissionGate";

const PageDetails = ({ setHeaderName }) => {
  const { subId, subTab } = useParams();
  const history = useHistory();
  const [statusArr, setStatusArr] = useState([]);
  const [headerTypeArr, setHeaderTypeArr] = useState([]);
  const [popupArr, setPopupArr] = useState([]);
  const [brandArr, setBrandArr] = useState([]);
  const [eventData, setEventData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getUpcomingEventLandingDropDown();
        setStatusArr(res.data.statusList || []);
        setBrandArr(res.data.brandTemplateList || []);
        setPopupArr(res.data.getPopupList || []);
        setHeaderTypeArr(res.data.headerList || []);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await getEventLandingPageDetails(subId);
        res.data.Data.brandTemplete = res.data.Data?.brandTemplete?.map(
          (el) => el.brandID
        );
        setEventData(res.data?.Data);
        setHeaderName(res.data?.Data?.PageTitle);
         localStorage.setItem("headerName", res.data?.Data?.PageTitle);
      } catch (error) {
        // if (!axios?.isCancel(error)) {
        console.error(error);
        // }
      }
    };
    if (subId) {
      fetchData();
    }
  }, [subId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      PageTitle: eventData?.PageTitle || "",
      Brand: eventData?.brandTemplete || [],
      IsPublished: eventData?.IsPublished,
      ShowTitleInBody: eventData?.ShowTitleInBody || 0,
      BodyContent: eventData?.BodyContent || "",
      SliderID: eventData?.SliderID || "",
      PopupID: eventData?.PopupID || "",
      FormDestinationEmail: eventData?.FormDestinationEmail || "",
      conversion_code: eventData?.conversion_code || "",
      MetaTitle: eventData?.MetaTitle || "",
      MetaDescription: eventData?.MetaDescription || "",
      MetaKeywords: eventData?.MetaKeywords || "",
      addCustomCss: eventData?.addCustomCss || "",
      customCss: eventData?.customCss || "",
    },
    validationSchema: Yup.object().shape({
      PageTitle: Yup.string().required("Page Title is required"),
      Brand: Yup.array().min(1, "At least one template is required"),
      BodyContent: Yup.string().required("Page Content is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (subTab == "create") {
          await addEventLandingPage(values);
          Swal.fire(
            "Success!",
            "Landing page created successfully!",
            "success"
          );
          history.push("/websiteManagement/events/landing_page/table");
        } else if (subTab == "open") {
          await updateEventLandingPage({ ...values, PageID: subId });
          Swal.fire(
            "Success!",
            "Landing page updated successfully!",
            "success"
          );
          history.push(`/websiteManagement/events/landing_page/page_details/open/${subId}`);
        }
      } catch (error) {
        Swal.fire(
          "Error!",
          `Failed to ${subTab == "create" ? "create" : "update"} Landing page`,
          "error"
        );
        console.log(error);
      }
      setSubmitting(false);
    },
  });
  const handleEditorChange = (key, value) => {
    formik.setFieldValue(key, value);
  };

  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">Page Details</h4>
            </div>
          </div>

          <FormField
            field={{ name: "PageTitle", label: "Page Title" }}
            formik={formik}
          />

          <div className="col-md-6 col-lg-3">
            <label htmlFor="Brand">
              Assigned Brand Templates * <i className="fal fa-info-circle"></i>
            </label>
            <div className="form-icon-group mb-4">
              <Select
                className={`form-control custom-select-box ${
                  formik.touched.Brand && formik.errors.Brand && "is-invalid"
                }`}
                name="Brand"
                value={brandArr?.filter((val) =>
                  formik.values.Brand?.includes(val.value)
                )}
                onChange={(value) =>
                  formik.setFieldValue(
                    "Brand",
                    value ? value.map((v) => v.value) : []
                  )
                }
                isClearable
                onBlur={formik.handleBlur}
                options={brandArr}
                maxMenuHeight={175}
                placeholder="Select"
                isMulti
              />
            </div>
          </div>

          <FormField
            field={{
              name: "IsPublished",
              label: "Status",
              info: true,
              required: false,
            }}
            formik={formik}
            selectOptions={statusArr}
          />
          <div className="col-md-6 col-lg-3 d-flex align-items-center">
            <label className="mb-0">
              Show Page Title <i className="fal fa-info-circle"></i>
            </label>
            <div className="toggle-switch ml-2">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={formik.values?.ShowTitleInBody === 1}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "ShowTitleInBody",
                      e.target.checked ? 1 : 0
                    )
                  }
                />
                <span className="slider slider-round"></span>
              </label>
            </div>
          </div>
          <div className="col-md-12">
            <label htmlFor={"disclaimer_tc"}>Page Content *</label>
            <div className="form-icon-group mb-6 laptop-pricing">
              <HtmlInputEditor
                editorState={formik.values?.BodyContent}
                setEditorState={(value) =>
                  handleEditorChange("BodyContent", value)
                }
                isInValid={formik.errors.BodyContent}
                isCKEditor={true}
                hideSign={true}
              />
            </div>
          </div>
          <div className="col-md-12">
            <hr
              className="border border-2"
              style={{ borderColor: "#E6EBF1" }}
            />
          </div>
          <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">Page Settings</h4>
            </div>
          </div>

          <FormField
            field={{ name: "SliderID", label: "Header", required: false }}
            formik={formik}
            selectOptions={headerTypeArr}
            col_md={6}
            col_lg={4}
          />

          <FormField
            field={{ name: "PopupID", label: "Popup", required: false }}
            formik={formik}
            selectOptions={popupArr}
            col_md={6}
            col_lg={4}
          />
          <FormField
            field={{
              name: "FormDestinationEmail",
              label: "Form Email",
              required: false,
            }}
            formik={formik}
            col_md={6}
            col_lg={4}
          />
          <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">
                <FontAwesomeIcon icon={faGear} color="var(--topbar-color)" />{" "}
                SEO Settings
              </h4>
            </div>
          </div>

          <FormField
            field={{
              name: "conversion_code",
              label: "Conversion Code",
              required: false,
            }}
            formik={formik}
          />

          <FormField
            field={{ name: "MetaTitle", label: "Meta Title", required: false }}
            formik={formik}
          />

          <FormField
            field={{
              name: "MetaDescription",
              label: "Meta Description",
              required: false,
            }}
            formik={formik}
          />

          <FormField
            field={{
              name: "MetaKeywords",
              label: "Meta Keywords",
              required: false,
            }}
            formik={formik}
          />
          {/* <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">
                <FontAwesomeIcon icon={faGear} color="var(--topbar-color)" />{" "}
                Style Settings
              </h4>
            </div>
          </div> */}
          {/* <div className="col-md-6 col-lg-3 d-flex align-items-center mb-3">
            <label className="mb-0">
              Add Custom CSS<i className="fal fa-info-circle"></i>
            </label>
            <div className="toggle-switch ml-2">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={formik.values?.addCustomCss === 1}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "addCustomCss",
                      e.target.checked ? 1 : 0
                    )
                  }
                />
                <span className="slider slider-round"></span>
              </label>
            </div>
          </div> */}
          {/* {formik.values?.addCustomCss ? (
            <div className="col-md-12">
              <div className="form-group-blk mb-3">
                <label>Customs CSS*</label>
                <HtmlInputEditor
                  editorState={formik.values?.customCss}
                  setEditorState={(value) =>
                    handleEditorChange("customCss", value)
                  }
                  isInValid={formik.errors.customCss}
                  isCKEditor={true}
                  hideSign={true}
                />
              </div>
            </div>
          ) : null} */}
        </div>
        <div className="form-group form-group-save-cancel mt-4">
          <PermissionsGate scopes={[subTab == "create" ? 'wbseventlpageadd' : 'wbseventlpageedit']} RenderError={() => (
            <button className="btn btn-save btn-success" disabled>
              <i className="fal fa-save"></i> Save
            </button>
          )}>

          <button
            className="btn btn-save btn-success"
            type="submit"
            title="Save"
            >
            <i className="fal fa-save"></i>
            Save
          </button>
            </PermissionsGate>
        </div>
        {Object.keys(formik.errors).map((key) => {
          if (formik.touched[key]) {
            return (
              <div className="invalid-feedback d-block" key={key}>
                {formik.errors[key]}
              </div>
            );
          }
          return null;
        })}
      </form>
    </div>
  );
};

export default PageDetails;
