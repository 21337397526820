import React, { useState } from "react";
import HeaderImage from "../assets/images/my-admin-portal-logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router";
import Swal from "sweetalert2";
import { ResetPasswordService } from "../services/LoginService";
import BgImage from "../assets/images/login-gradient-bg.png";
import logoImage from "../assets/images/admin-logo.png";
import { useSelector } from "react-redux";

const NewResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Password: "",
      Password_confirmation: "",
    },
    validationSchema: Yup.object({
      Password: Yup.string()
        .required("Password is required")
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"),
      Password_confirmation: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("Password"), null], "Passwords must match"),
    }),
    onSubmit: (values) => {
      values = { ...values, reset_password_token: id };
      ResetPasswordService(values)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
            timer: 3000,
            onOpen: function () {
              Swal.showLoading();
            },
          }).then(function (result) {
            if (result.dismiss === "timer") {
              setLoading(false);
              history.push("/home");
            }
          });
        })
        .catch(function (err) {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: err.response.data.message,
          });
          setLoading(false);
          return false;
        });
    },
  });

  const brandVisualConfig = useSelector(state => state.brandVisuals.config);


  return (
    <div className="new-custom-login portal-login portal-forgot-password d-flex align-items-center" style={{ backgroundColor: "#10284b" }}>
    <img src={brandVisualConfig.background_logo ? brandVisualConfig.background_logo : null}/>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card portal-login-card text-center">
              <div className="card-header">
                <div class="card-header-img card-header-text">
                  <img src={brandVisualConfig.login_header ? brandVisualConfig.login_header : null}/>
                  {/* <div class="header-text"><h4>AMI</h4><p>ADMINISTRATION &amp; MANAGEMENT INTRANET</p></div> */}
                </div>
                {/* <div className="card-header-img">
                  <img src={HeaderImage} />
                </div> */}
                
              </div>
              <div className="card-body">
                <div className="card-body-inr">
                  <div className="portal-login-info">
                    <h3>Reset Password</h3>
                  </div>
                  <div className="portal-login-form ">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="">
                            <i className="fal fa-user"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          className={"form-control" + (formik.errors.Password && formik.touched.Password ? " is-invalid" : "")}
                          name="Password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Password}
                          placeholder="Enter New Password"
                        />
                      </div>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="">
                            <i className="fal fa-user"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          className={"form-control" + (formik.errors.Password_confirmation && formik.touched.Password_confirmation ? " is-invalid" : "")}
                          name="Password_confirmation"
                          placeholder="Confirm Password"
                          value={formik.values.Password_confirmation}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      <button className="btn btn-white-bordered btn-submit-info btn-primary mt-3" disabled={loading} title="Submit">
                        {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paper-plane"></i>} Reset Password
                      </button>
                      {formik.touched.Password && formik.errors.Password ? <div className="invalid-feedback d-block">{formik.errors.Password}</div> : null}
                      {formik.touched.Password_confirmation && formik.errors.Password_confirmation ? <div className="invalid-feedback d-block">{formik.errors.Password_confirmation}</div> : null}
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewResetPassword;
