import React from "react";
import ListOfTemplate from "./ListOfTemplate";

function ListOfTemplateModal({setSelectedTemplate, selectedTemplate, setSelectedGradeMixTemplate }) {
  return (
    <>
      <div
        // ref={editorRef}
        className="topic-add-modal modal fade"
        id="letterTemplatemodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-plus"></i> Grade Letter Template
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ListOfTemplate setSelectedTemplate={setSelectedTemplate} selectedTemplate={selectedTemplate} setSelectedGradeMixTemplate={setSelectedGradeMixTemplate}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListOfTemplateModal;
