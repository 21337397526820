import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import hasPermission from "../../../utils/hasMultiplePermission";
import Tablefilter from "../../common/Tablefilter";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import AssignedProgrammePopupTable from "./AssignedProgrammePopupTable";
import { getAssignedProgrammesFiltersList, getAssignedProgrammesList, getAssignedProgrammesListCancelToken } from "../../../services/LayoutCategoryServices";
import { RenderLearningMethod, RenderProgrammeType } from "../../../utils/CommonGroupingItem";
import { UpcomingEventsStatus } from "../../../utils/CommonStatusItems";
import TableTypeFilter from "../../common/TableTypeFilter";

function AssignedProgramme({ subId, programmeData, setProgrammeData }) {
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [type, setType] = useState({ arr: [], checkObj: {} });
  const [loading, setloading] = useState(true);
  const [eventsData1, setEventsData1] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterData, setFilterData] = useState({
    status: [],
  });

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });
  useEffect(() => {
    const storedFilters = JSON.parse(
      localStorage.getItem("filterStateAssigneProgramme")
    );
    if (storedFilters) {
      setStatus(storedFilters.status);
      setFilterData(storedFilters.filterData);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      status,
      filterData,
    };
    localStorage.setItem(
      "filterStateAssigneProgramme",
      JSON.stringify(filterState)
    );
  }, [status, filterData]);
  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });

const addProgramme = (row, type) => {
  let assigntemp = [...programmeData];
  let availtemp = [...eventsData1];
  if (type === "add") {
    assigntemp.push(row);
    availtemp = availtemp.filter((data) => data.id !== row.id);
  } else if (type === "delete") {
    assigntemp = assigntemp.filter((data) => data.id !== row.id);
    availtemp.push(row);
  }
  setProgrammeData([...assigntemp]);
  setEventsData1([...availtemp]);
};

  useEffect(() => {
    const cancelTokenSources = [];
    const getUpcomingEventList = async () => {
      setloading(true);
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);

      const data = {
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        exportStatus: "true",
        viaStatus: status.arr,
        viaType: type.arr,
        categoryID: subId,
        assignedData: 1,
      };

      try {
        const res = await getAssignedProgrammesListCancelToken(
          data,
          source.token
        );
        const event = res.data;
        setProgrammeData(event);
        if (res.status == 200) {
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };

    getUpcomingEventList();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAssignedProgrammesFiltersList(subId, 1);
        setFilterData({
          ...res.data,
          status: res.data.statusListFilter ? res.data?.statusListFilter : [],
          type: res.data.type ? res.data?.type : [],
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, []);

 

 const handleFilters = (e, type = "") => {
   const value = e.target.value;
   setSearch(value);
 };


  const resetFilter = () => {
    setStatus({ arr: [], checkObj: {} });
    setType({ arr: [], checkObj: {} });
    setSearch("");
  };


 const dataToRender = () => {
   let updatedData = [];
   let allData = programmeData;
   if (search.length) {
     let tempFirstname = allData?.filter((item) => {
       let includes =
         item.name && item.name.toLowerCase().includes(search.toLowerCase());
       if (includes) {
         return includes;
       } else return null;
     });
     let data = [...tempFirstname];
     let unique = [...new Set(data)];

     updatedData = unique;
   } else {
     updatedData = allData;
   }

   if (status.arr.length) {
     let tempRole = updatedData;
     let tempSearch = tempRole?.filter((item) => {
       const startsWith = status.arr.find(function (user, index) {
         if (
           user.toLowerCase() === (item.status ? item.status.toLowerCase() : "")
         )
           return item;
       });
       if (startsWith) {
         return startsWith;
       } else return null;
     });
     updatedData = tempSearch;
   }

   if (type.arr.length) {
     updatedData = updatedData?.filter((item) => {
       return type.arr.includes(String(item?.qualification_type))
     });
   }
   return updatedData;
 };
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        <Link
          className="as-text-blue curser feature-name"
          //   to={handleRedirection(row.id)}
          title={row.name}
        >
          <span className="textLimit100">{row.name}</span>
        </Link>
      ),
    },

    {
      name: "Code",
      selector: "Code",
      sortField: "Code",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (row.Code ? row.Code : "-"),
    },

    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (row.status ? row.status : "-"),
    },
    {
      name: "Type",
      selector: "qualification_type",
      // maxWidth: "240px",
      sortable: true,
      cell: (row) => {
        const filteredType = filterData?.type?.find(
          (item) => item.value === Number(row.qualification_type)
        );
        if (filteredType) {
          return (
            <div>
              <span className={"cat " + filteredType.color}>
                {" "}
                {filteredType.label !== "-" && (
                  <i className="fas fa-circle mr-1"></i>
                )}
                {filteredType.label}
              </span>
            </div>
          );
        } else {
          return "-";
        }
      },
    },
    {
      name: "Learning Method",
      selector: "Delivery_Methods",
      sortable: true,
      sortField: "Delivery_Methods",
      cell: (row) =>
        row.Delivery_Methods
          ? RenderLearningMethod(row?.Delivery_Methods, "programme").html
          : "-",
    },
    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              className="btn btn-primary h-auto rounded-circle"
              to={`/websiteManagement/events/upcoming_events/event_details/update/${row.EventId}`}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>

            <button
              type="button"
              className="btn btn-danger rounded-circle"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    addProgramme(row, "delete");
                  }
                });
              }}
            >
              <i className="fal fa-eye-slash"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = [
      "Name",
      "Code",
      "Status",
      "Type",
      "Learning Method",
    ]
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const programmeData = {
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      exportStatus: "true",
      viaStatus: status.arr,
      viaType: type.arr,
      categoryID: subId,
      assignedData: 1,
    };
    
    getAssignedProgrammesList(programmeData)
      .then((res) => {
        data = res.data;
        data = data?.map((row) => ({
          ...row,
          Name: row?.name || "",
          Code: row?.Code || "",
          Status: row.status ? row.status : "-",
          Type: row.qualification_type
            ? RenderProgrammeType(row.qualification_type).text
            : "-",
          "Learning Method": row.Delivery_Methods
            ? RenderLearningMethod(row.Delivery_Methods).text
            : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row?.name,
              row.Code,
              row.status,
              RenderProgrammeType(row.qualification_type).text,
              RenderLearningMethod(row.Delivery_Methods).text,
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        Swal.close();
        console.log(err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [debouncedTerm]);

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={(e) => handleFilters(e, "search")}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner filter-custom-new`}>
                    <Tablefilter
                      filterName="Status"
                      optionArr={filterData?.status}
                      state={status}
                      setState={setStatus}
                      // renderLabelFunction={UpcomingEventsStatus}
                    />
                  </div>
                </div>
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner filter-custom-new`}>
                    <TableTypeFilter
                      filterName="Type"
                      optionArr={filterData?.type}
                      state={type}
                      setState={setType}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Assigned_event");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Assigned_event");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Assigned_event");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <button
                  type="button"
                  className="btn btn-primary"
                  title="Assign Programmes"
                  data-toggle="modal"
                  data-target="#assignprogrammes"
                >
                  <i className="fal fa-calendar-alt"></i>Assign Programmes
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading === true ? (
          <SkeletonTicketList />
        ) : (
          <DataTable
            data={dataToRender()}
            defaultSortField="name"
            defaultSortAsc={false}
            columns={columns}
            pagination={true}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        )}
      </div>
      <div
        className="topic-add-modal modal fade"
        id="assignprogrammes"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div class="modal-header modal-header-custom">
              <h5 class="modal-title">
                <i class="fal fa-plus"></i> Assign Programmes
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <AssignedProgrammePopupTable
              subId={subId}
              eventsData1={eventsData1}
              setEventsData1={setEventsData1}
              addProgramme={addProgramme}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignedProgramme;
