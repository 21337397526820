import axiosInstance from "../utils/axios";

export const GetEventLandingPageFiltersList = async () => {
  return await axiosInstance().get(`/getEventLandingPageFiltersList`);
};
export const GetEventLandingPageListcancelToken = async (
  values,
  cancelToken
) => {
  return await axiosInstance().post(`/getEventLandingPageList`, values, {
    cancelToken: cancelToken,
  });
};
export const GetEventLandingPageList = async (values) => {
  return await axiosInstance().post(`/getEventLandingPageList`,values);
};
export const DeleteEventLandingPage = async (values) => {
  return await axiosInstance().post(`/deleteEventLandingPage`, values);
};
export const getEventLandingPageDetails = async (id) => {
  return await axiosInstance().get(`/getEventLandingPageDetails?PageID=${id}`);
};
export const getUpcomingEventLandingDropDown = async () => {
  return await axiosInstance().get(`/getUpcomingEventLandingDropDown`);
};

export const addEventLandingPage = async (data) => {
  return await axiosInstance().post("/createEventLandingPage", data);
};
export const updateEventLandingPage = async (data) => {
  return await axiosInstance().post(`/updateEventLandingPage`, data);
};

export const getUpcomingEventsListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getUpcomingEventList`, values, {
    cancelToken: cancelToken,
  });
};
export const getUpcomingEventsList = async (values) => {
  return await axiosInstance().post(`/getUpcomingEventList`, values);
};
export const getUpcomingEventFiltersList = async () => {
  return await axiosInstance().get(`/getUpcomingEventFiltersList`);
};

export const getUpcomingEventDetails = async (id) => {
  return await axiosInstance().get(`/getUpcomingEventDetails?EventId=${id}`);
};
export const deleteUpcomingEvent = async (values) => {
  return await axiosInstance().post(`/deleteUpcomingEvent`, values);
};
export const addUpcomingEventPage = async (data) => {
  return await axiosInstance().post("/createUpcomigEvent", data);
};
export const updateUpcomingEventPage = async (data) => {
  return await axiosInstance().post(`/updateUpcomingEvent`, data);
};
export const getUpcomingEventListDropDown = async () => {
  return await axiosInstance().get(`/getUpcomingEventListDropDown`);
};
export const getInfoPacksListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getInfoPackEventsList`, values, {
    cancelToken: cancelToken,
  });
};
export const getInfoPacksList = async (values) => {
  return await axiosInstance().post(`/getInfoPackEventsList`, values);
};
export const getInfoPackFiltersList = async () => {
  return await axiosInstance().get(`/getInfoPackEventsListFilters`);
};
export const getInfoPackDetails = async (id) => {
  return await axiosInstance().get(`/getEventEmailDetails?id=${id}`);
};
export const deleteInfoPack = async (values) => {
  return await axiosInstance().post(`/deleteEventEmail`, values);
};
export const addInfoPackPage = async (data) => {
  return await axiosInstance().post("/createEventEmail", data);
};
export const updateInfoPackPage = async (data) => {
  return await axiosInstance().post(`/updateEventEmail`, data);
};

export const getAssigneEventsListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(
    `/getAssignedUpcomingEventEmailList`,
    values,
    {
      cancelToken: cancelToken,
    }
  );
};
export const getAssigneEventsList = async (values) => {
  return await axiosInstance().post(
    `/getAssignedUpcomingEventEmailList`,
    values
  );
};
export const getAssigneEventsFiltersList = async (emailID, isAssigned) => {
  return await axiosInstance().get(
    `/getAssignedUpcomingEventEmailFiltersList?emailID=${emailID}&isAssigned=${isAssigned}`
  );
};

export const getBookingsListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getUserBookingEventList`, values, {
    cancelToken: cancelToken,
  });
};
export const getBookingsList = async (values) => {
  return await axiosInstance().post(`/getUserBookingEventList`, values);
};
export const getBookingsFiltersList = async (event_id) => {
  return await axiosInstance().get(
    `/getUserBookingEventListFilters?event_id=${event_id}`
  );
};

export const getHeaderDropDownList = async () => {
  return await axiosInstance().get(`/getHeaderDropDownList`);
};

export const getAttachedPageList = async (id) => {
  return await axiosInstance().get(`/getHeaderAttachPagesList?EventId=${id}`);
};
export const getAttachedPageFilters = async () => {
  return await axiosInstance().get(`/getHeaderAttachedPagesFilters`);
};
