import React, {useMemo} from "react";
import { IMAGE_URL} from "../../../utils/Constants";
import { GetLayoutNavigationAuditTrailList, getLayoutNavigationAuditTrailListFilters } from "../../../services/WebsiteListServer";
import { Link } from "react-router-dom";
import AuditTrailsTable from "../../common/AuditTrailsTable";
import { useParams } from "react-router-dom";
import { RenderStudentResourceType } from "../../../utils/CommonGroupingItem";

function AuditTrail() {
  const { subId, subType, subTab, type , tab , id } = useParams();

  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]
  let apiParam = {
    // action_id: subId,
    // resource_type: ['Category']
  };
  if( tab == "categories" && subTab == "auditTrail" && type == "table"){
    apiParam.resource_type=['Category']
    apiParam.action_id= subId  
  }
  if( tab == "menus" && subTab == "auditTrail" && type == "table"){
    apiParam.resource_type=['Menu']
    apiParam.action_id= subId
   
  }
  if( tab == "header" && subTab == "auditTrail" && type == "table"){
    apiParam.resource_type=['Header']
    apiParam.action_id= subId
  }
  if( tab == "testimonials" && subTab == "auditTrail" && type == "table"){
    apiParam.resource_type=['Testimonial']
    apiParam.action_id= subId
   
  }
  

  return (
    <AuditTrailsTable 
    apiFunction={GetLayoutNavigationAuditTrailList}
    filterArrApi={getLayoutNavigationAuditTrailListFilters}
    columnsToShow={columnsToShow}
    exportFileName={"Layout_&_Navigation_auditTrail"}
    isResourceNameShown={true}
    apiParams={apiParam}
    actionId={subId || id}
    isShowingResourceTypeFilter
    renderResourceTypeFunction={RenderStudentResourceType}
    />
  );
}

export default AuditTrail;
