import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { GlobalIconNames } from '../../utils/GlobalIconNames';
import { capitalize } from '../../utils/commonFunction';
import { RenderRegistrationStatus } from '../../utils/CommonStatusItems';
import { RenderProgrammeType } from '../../utils/CommonGroupingItem';

function Tablefilter({
  filterName,
  optionArr = [],
  state,
  setState,
  renderLabelFunction,
  uniqueId = '',
  isOptionReversed = false,
  stopOptionSorting = false,
  isSearchFilter = false,
  isIntake,
  origin = true,
  singleSelect = false,
  isAllIntakeAllowed = true,
  isDisabled = false,
}) {

  let arr = state?.arr;
  let checkObj = state?.checkObj;
  let filterId = filterName.split(' ')[0].toLowerCase();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (typeof optionArr === 'undefined') {
      return;
    }
    if (
      !isIntake &&
      filterName == 'Intake' &&
      optionArr &&
      !isAllIntakeAllowed
    ) {
      let temparr = optionArr
        .filter((intake) => {
          if (
            parseInt(intake.value.split('/')[0]) &&
            parseInt(intake.value.split('/')[0]) < 2022
          ) {
            return intake;
          } else {
            return false;
          }
        })
        .map((item) => ({ ...item, label: item.label.replace(/ +/g, '') }));

      setOptions([...temparr]);
    } else {
      setOptions([...optionArr]);
    }
  }, [optionArr]);

  // const filterFunction = (e) => {
  //   const value = e.target.value;
  //   let id = e.target.id;
  //   let array = [...arr];
  //   if (singleSelect) {
  //     Object.keys(checkObj).map((item) => (checkObj[item] = false));
  //     setState({ arr: [value], checkObj: { ...checkObj, [id]: true } });
  //     return;
  //   }
  //   if (array?.includes(value)) {
  //     array.splice(array.indexOf(value), 1);
  //   } else {
  //     array.push(value);
  //   }
  //   setState({ arr: array, checkObj: { ...checkObj, [id]: !checkObj[id] } });
  // };
  const filterFunction = (e) => {
    const value = e.target.value;
    let id = e.target.id;
    let array = [...arr];  
  
    if (singleSelect) {
      const newCheckObj = Object.keys(checkObj).reduce((acc, item) => {
        acc[item] = false;
        return acc;
      }, {});
      setState({ arr: [value], checkObj: { ...newCheckObj, [id]: true } });
      return;
    }
  
    if (array.includes(value)) {
      array.splice(array.indexOf(value), 1);
    } else {
      array.push(value);
    }
    setState({ arr: array, checkObj: { ...checkObj, [id]: !checkObj[id] } });
  };
  
  const initializeCheckObj = (items) => {
    const obj = {};
    items.forEach(item => {
      obj[uniqueId + filterId + item.value] = false;
    });
    return obj;
  };

  const loadOptions = async (search, prevOptions) => {
    let options = optionArr;
    let filteredOptions;
    if (!search) {
      filteredOptions = options;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = options.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );
    return { options: slicedOptions, hasMore };
  };

  

  const handleFilterChange = (e) => {
    let array = [];
    Array.isArray(e) &&
      e.map((val) => {
        array.push(val.value);
      });
    setState({ arr: array, checkObj: e ? e : [] });
  };
  return (
    <div className="assessment-table-filter">
      <div
        className={`btn-type-filter dropdown-comman ${
          isSearchFilter ? 'assesment-dropdown' : ''
        }`}
      >
        <div className="dropdown multiselect">
          <button
            className={`btn btn-default dropdown-toggle ${
              arr?.length ? 'btn-selected' : ''
            }`}
            type="button"
            id={`dropdownMenu${filterId}`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            title={filterName}
            disabled={isDisabled}
          >
            <span>
              {filterName} {GlobalIconNames('downarrow')}
            </span>
          </button>
          <form className="dropdown-menu remove-list-style">
            {isSearchFilter && optionArr && optionArr.length ? (
              <ul aria-labelledby={`dropdownMenu${filterId}`}>
                <div className="dropdown-filter-pagination">
                  <AsyncPaginate
                    value={checkObj}
                    loadOptions={loadOptions}
                    onChange={handleFilterChange}
                    menuIsOpen={true}
                    isMulti
                  />
                </div>
              </ul>
            ) : (
              <ul aria-labelledby={`dropdownMenu${filterId}`}>
                {options &&
                  (stopOptionSorting
                    ? options
                    : options.sort((a, b) =>
                        (a && a.label
                          ? a.label.toString().trim().toLowerCase()
                          : '') >
                        (b && b.label
                          ? b.label.toString().trim().toLowerCase()
                          : '')
                          ? isOptionReversed
                            ? -1
                            : 1
                          : isOptionReversed
                          ? 1
                          : -1
                      )
                  ).map((item, index) => (
                    <li
                      title={item.label}
                      key={item.value + filterName + index}
                    >
                      <input
                        key={uniqueId + filterId + item.value}
                        type="checkbox"
                        id={uniqueId + filterId + item.value}
                        value={item.value}
                        onChange={filterFunction}
                        checked={checkObj[uniqueId + filterId + item.value] === true}
                      />
                      <label htmlFor={uniqueId + filterId + item.value}>
                        {renderLabelFunction &&
                        (uniqueId == 'brand' ||
                          uniqueId === 'ExamStatus' ||
                          uniqueId == 'topic' ||
                          uniqueId == 'assignTopic' ||
                          uniqueId == 'availTopic' ||
                          uniqueId == 're-reg-reason' ||
                          uniqueId == 'gradeMixAssType') ? (
                          renderLabelFunction(item.label, item.color).html
                        ) : uniqueId === 'brands' ? (
                          renderLabelFunction(item.label, item.topbar_color)
                            .html
                        ) : uniqueId === 'campus' ||
                          uniqueId === 'quizType' ||
                          uniqueId === 'availQuizType' ? (
                          renderLabelFunction(item).html
                        ) : uniqueId === 'published' ? (
                          renderLabelFunction(item.value)
                        ) : renderLabelFunction ? (
                          renderLabelFunction(item.value).html
                        ) : uniqueId === 're-reg-status' ? (
                          RenderRegistrationStatus(item.value).html
                        ) : (  
                          <span className="cat">{capitalize(item.label)}</span>
                        )}
                      </label>
                    </li>
                  ))}
              </ul>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Tablefilter);
