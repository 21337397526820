const initialState={
    exam_period_name : ""
};
const examPeriodReducer=(state=initialState,action)=>{
    switch(action.type){
        case "SET_EXAM_PERIOD_NAME" :
            state.exam_period_name = action.payload;
            return {exam_period_name : state.exam_period_name}
        default :
            return state
    }
}
export default examPeriodReducer;