import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { URL_REGEX } from "../../../utils/Constants";
import Select from "react-select";
import {
  GetBrandSettingCommonContent,
  AddBrandSettingAPI,
  GetNetsuiteERPBrandNameList,
  GetBrandSettingCommonContentCancelToken,
  GetNetsuiteERPBrandNameListCancelToken,
} from "../../../services/BrandServices";
import { GetCampusMgtList } from "../../../services/CampusMgtService";
import axios from "axios";

const AddBrandSetting = () => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [CRMBrandName, setCRMBrandName] = useState([]);
  const [ERPBrandName, setERPBrandName] = useState([]);
  const [previewImgHeader, setPreviewImgHeader] = useState({ image: false });
  const [previewImgFooter, setPreviewImgFooter] = useState({ image: false });

  const [letterheadFooter, setLetterheadFooter] = useState();
  const [letterheadHeader, setLetterheadHeader] = useState();
  const [letterheadHeaderName, setLetterheadHeaderName] = useState({
    name: "",
  });
  const [letterheadFooterName, setLetterheadFooterName] = useState({
    name: "",
  });
  const [image, setImage] = useState();
  const [imageName, setImageName] = useState({ name: "" });
  const [imagePreview, setImagePreview] = useState({ image: false });
  const [brandWelcomeLetter, setBrandWelcomeLetter] = useState([]);
  const [brandWelcomeLetterSelected, setBrandWelcomeLetterSelected] = useState();
  const [campusList, setCampusList] = useState([])

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const response = await GetBrandSettingCommonContentCancelToken(dropdownSource.token);
        let x = {
          value: "",
          label: "",
        };
        x = response?.data?.brand_reg_crm.map((data) => {
          return { ...x, value: `${data.value}`, label: `${data.title}` };
        });
        setCRMBrandName(x);
        let y = {
          value: "",
          label: "",
        };
        y = response?.data?.erp_brand_name.map((data) => {
          return { ...y, value: `${data.value}`, label: `${data.title}` };
        });
        setERPBrandName(y);
        let z = {
          value: "",
          label: "",
        };
        z = response?.data?.brand_welcome_letter.map((data) => {
          return { ...x, value: `${data.value}`, label: `${data.title}` };
        });
        setBrandWelcomeLetter(z);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const response = await GetNetsuiteERPBrandNameListCancelToken(dropdownSource.token);
        let x = {
          value: "",
          label: "",
        };
        x = response?.data?.data?.items.map((data) => {
          return { ...x, value: `${data.id}`, label: `${data.fullname}` };
        });
        setERPBrandName(x);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
  const dropdownSource = axios.CancelToken.source();

  const fetchData = async () => {
    try {
      const data = await GetCampusMgtList({},dropdownSource.token);
      let newArr = data.data.data_list;
      let newCampus = [];
      newArr.map((val) => {
        newCampus.push({ label: val.campus_name, value: val.id })
      })
      setCampusList(newCampus)
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
      }
    }
  };

  fetchData();

  return () => {
    dropdownSource.cancel('Component unmounted');
  };
  }, []);

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      BrandName: "",
      Mobile: "",
      Domain: "",
      ERPBrandName: "",
      CRMBrandName: "",
      ERPBrandNameID: "",
      CRMBrandNameID: "",
      LetterheadHeader: "",
      LetterheadFooter: "",
      EmailDomain: "",
      photo: "",
      PortalTitle: "",
      IOS_url: "",
      Android: "",
      StudentCardQR: "",
      BrandWelcomeLetter: "",
      campus_locations: [],
      hauwei:""
    },
    validationSchema: Yup.object({
      BrandName: Yup.string()
        .trim("The Brand name cannot include leading and trailing spaces")
        .required("Brand Name is required"),
      Mobile: Yup.string().required("Contact Number Is Required"),
      CRMBrandName: Yup.string().required("CRM Brand Name is required"),
      Domain: Yup.string()
        .url("Please Enter Domain URL")
        .matches(URL_REGEX, "Domain URL is not valid")
        .required("Domain Url Is Required"),
      EmailDomain: Yup.string()
        .required("Email Domain Is Required")
        .trim("Enter Valid Email Domain"),

      LetterheadHeader: Yup.mixed().required(
        "Letterhead Header image Required"
      ),
      LetterheadFooter: Yup.mixed().required(
        "Letterhead Footer image Required"
      ),
      photo: Yup.mixed().required("Student Card image Required"),
      PortalTitle: Yup.string().required("Portal Title is required").trim("Enter valid title"),
      IOS_url: Yup.string().url("Please Enter IOS URL").matches(URL_REGEX, "IOS URL is not valid"),
      Android: Yup.string().url("Please Enter Android URL").matches(URL_REGEX, "Android URL is not valid"),
      StudentCardQR: Yup.string().url("Please Enter Student Card QR URL").matches(URL_REGEX, "Student Card QR URL is not valid").required("Student Card QR Url Is Required"),
      BrandWelcomeLetter: Yup.mixed().required("Welcome Letter is required"),
      campus_locations: Yup.array().min(1, "Campus Location is required"),
      hauwei: Yup.string().url("Please Enter Hauwei URL").matches(URL_REGEX, "Hauwei URL is not valid"),
    }),
    onSubmit: (values, { resetForm }) => {

      setDisabled(true);
      let formData = new FormData();
      formData.append("brand_name", values?.BrandName);
      formData.append("contact", values?.Mobile);
      formData.append("erp_brand", values?.ERPBrandName);
      formData.append("erp_brand_id", values?.ERPBrandNameID);
      formData.append("brand_reg_crm", values?.CRMBrandNameID);
      formData.append("domain_url", values?.Domain);
      formData.append("microsoft_email", values?.EmailDomain);
      formData.append("title", values?.PortalTitle);
      formData.append("androidAppUrl", values?.Android);
      formData.append("iosAppUrl", values?.IOS_url);
      formData.append("studentCardVerifyUrl", values?.StudentCardQR);
      formData.append("brand_welcome_letter", brandWelcomeLetterSelected);
      formData.append("campus_location", values.campus_locations);
      formData.set("hauweiAppUrl", values?.hauwei);

      letterheadHeader && formData.append("letterheadHeader", letterheadHeader);
      letterheadFooter && formData.append("letterheadFooter", letterheadFooter);
      image && formData.append("studentCardBackground", image);
      AddBrandSettingAPI(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Added successfully",
          });
          setDisabled(false);
          // resetForm();
          // setImage(user);
          history.push(
            `/administration/brandSetting/editPortalSetting/visualsTab/${res?.data?.inserted_record}`
          );
        })
        .catch((err) => {
          console.log("error: ", err?.response?.data[0]);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data}`,
          });
          setDisabled(false);
          history.push("/administration/brandSetting/addNewBrand");
        });
    },
  });

  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="portalTitle">Portal Title *</label>
            <div className="form-icon-group mb-4" title="Title">
              <input
                type="text"
                id="portalTitle"
                className={"form-control" + (formik.errors.PortalTitle && formik.touched.PortalTitle ? " is-invalid" : "")}
                name="PortalTitle"
                placeholder="Portal Title"
                title="Portal Title"
                value={formik.values.PortalTitle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="IOS_url">IOS App URL</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="IOS_url"
                className={"form-control" + (formik.errors.IOS_url && formik.touched.IOS_url ? " is-invalid" : "")}
                name="IOS_url"
                title="IOS App URL"
                placeholder="Enter IOS URL"
                value={formik.values.IOS_url.replace(" ", "")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="android">Android App URL</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="android"
                className={"form-control" + (formik.errors.Android && formik.touched.Android ? " is-invalid" : "")}
                name="Android"
                title="Android App URL"
                placeholder="Enter Android URL"
                value={formik.values.Android.replace(" ", "")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="hauwei">Hauwei App URL</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="hauwei"
                className={"form-control" + (formik.errors.hauwei && formik.touched.hauwei ? " is-invalid" : "")}
                name="hauwei"
                title="Hauwei App URL"
                placeholder="Enter Hauwei URL"
                value={formik.values.hauwei.replace(" ", "")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="studentCard">Student Card QR *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="studentCard"
                className={"form-control" + (formik.errors.StudentCardQR && formik.touched.StudentCardQR ? " is-invalid" : "")}
                name="StudentCardQR"
                title="Student Card QR"
                placeholder="Enter Student Card QR URL"
                value={formik.values.StudentCardQR.replace(" ", "")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="BrandWelcomeLetter">Welcome Letter *</label>
            <div className="">
              <div className="form-icon-group mb-4 " title="Welcome Letter">
                <Select
                  className={"form-control custom-select-box" + (formik.errors.BrandWelcomeLetter && formik.touched.BrandWelcomeLetter ? " is-invalid" : "")}
                  name="BrandWelcomeLetter"
                  value={brandWelcomeLetter.filter((option) => {
                    return option.label == formik.values.BrandWelcomeLetter;
                  })}
                  onChange={(value) => {
                    if (value) {
                      setBrandWelcomeLetterSelected(value.value);
                      formik.setFieldValue("BrandWelcomeLetter", value.label);
                    } else {
                      setBrandWelcomeLetterSelected("");
                      formik.setFieldValue("BrandWelcomeLetter", "");
                    }
                  }}
                  // getOptionLabel={(option) => option.title}
                  // getOptionValue={(option) => option.value}
                  onBlur={formik.handleBlur}
                  options={brandWelcomeLetter}
                  maxMenuHeight={175}
                  placeholder={formik.values.BrandWelcomeLetter ? formik.values.BrandWelcomeLetter : "Select"}
                  isClearable
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="campus_locations">Campus Locations *</label>
            <div className="">
              <div className="form-icon-group mb-4 " title="Campus Locations">
                <Select
                  className={"form-control custom-select-box" + (formik.errors.campus_locations && formik.touched.campus_locations ? " is-invalid" : "")}
                  name="campus_locations"
                  value={campusList.filter(function (option) {
                    return formik.values.campus_locations.includes(option.value);
                  })}
                  onChange={(value) => {
                    let tempArr = []
                    if (value) {
                      value.map(item => tempArr.push(item.value))
                    }
                    formik.setFieldValue("campus_locations", tempArr);
                  }}
                  onBlur={formik.handleBlur}
                  options={campusList}
                  maxMenuHeight={175}
                  isMulti={true}
                  placeholder={formik.values.campusList ? formik.values.campusList : "Select"}
                  isClearable
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="BrandName">Brand Name *</label>
            <div className="form-icon-group mb-4" title="Brand Name">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.BrandName && formik.touched.BrandName
                    ? " is-invalid"
                    : "")
                }
                name="BrandName"
                id="BrandName"
                placeholder="Brand Name"
                title="Brand Name"
                value={formik.values.BrandName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Contact">Contact Number *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.Mobile && formik.touched.Mobile
                    ? " is-invalid"
                    : "")
                }
                name="Mobile"
                title="Contact number"
                id="Contact"
                placeholder="Enter Contact Number"
                value={formik.values.Mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <label htmlFor="ERPBrandName">Netsuite Brand Name</label>
            <div className="">
              <div className="form-icon-group mb-4" title="Netsuite Brand Name">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.ERPBrandName && formik.touched.ERPBrandName
                      ? " is-invalid"
                      : "")
                  }
                  name="ERPBrandName"
                  // value={formik.values.ERPBrandName || "ERP Brand Name"}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("ERPBrandName", value.label);
                      formik.setFieldValue("ERPBrandNameID", value.value);
                    } else {
                      formik.setFieldValue("ERPBrandName", "");
                      formik.setFieldValue("ERPBrandNameID", "");
                    }
                  }}
                  value={ERPBrandName.filter((val) => {
                    return val.label === formik.values.ERPBrandName
                  })}
                  // getOptionLabel={(option) => option.title}
                  // getOptionValue={(option) => option.value}
                  onBlur={formik.handleBlur}
                  options={ERPBrandName}
                  maxMenuHeight={175}
                  placeholder={"Select"}
                  isClearable
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="CRMBrandName">CRM Brand Name *</label>
            <div className="">
              <div className="form-icon-group mb-4 " title="CRM Brand Name">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.CRMBrandName && formik.touched.CRMBrandName
                      ? " is-invalid"
                      : "")
                  }
                  name="CRMBrandName"
                  // value={formik.values.CRMBrandName || "CRM Brand Name"}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("CRMBrandName", value.label);
                      formik.setFieldValue("CRMBrandNameID", value.value);
                    } else {
                      formik.setFieldValue("CRMBrandName", "");
                      formik.setFieldValue("CRMBrandNameID", "");
                    }
                  }}
                  value={CRMBrandName.filter((val) => {
                    return val.label === formik.values.CRMBrandName
                  })}
                  // getOptionLabel={(option) => option.title}
                  // getOptionValue={(option) => option.value}
                  onBlur={formik.handleBlur}
                  options={CRMBrandName}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.CRMBrandName
                      ? formik.values.CRMBrandName
                      : "Select"
                  }
                  isClearable
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="DomainURL">Domain URL *</label>
            <div className="form-icon-group mb-4">
              {/** TODO Valid URL */}
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.Domain && formik.touched.Domain
                    ? " is-invalid"
                    : "")
                }
                name="Domain"
                id="DomainURL"
                placeholder="Domain URL"
                value={formik.values.Domain}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Domain URL"
              />
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <label htmlFor="EmailDomain">Email Domain *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.EmailDomain && formik.touched.EmailDomain
                    ? " is-invalid"
                    : "")
                }
                name="EmailDomain"
                id="EmailDomain"
                placeholder="Email Domain"
                value={formik.values.EmailDomain}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Email Domain"
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <label htmlFor="IndexLogo">Letterhead Header *</label>
            <div className="form-group atttach-file">
              <label>
                <i className="fal fa-paperclip"></i>
                <span>Add Attachment</span>
                <input
                  type="file"
                  id="input"
                  className="form-control  form-control-aatch-file"
                  accept={SUPPORTED_FORMATS}
                  name="file[]"
                  onChange={(e) => {
                    if (e.target.files.length) {
                      let reader = new FileReader();
                      reader.onload = (e) => {
                        setPreviewImgHeader({ image: e.target.result });
                      };
                      reader.readAsDataURL(e.target.files[0]);
                      formik.setFieldValue(
                        "LetterheadHeader",
                        e.target.files[0]
                      );
                      setLetterheadHeader(e.target.files[0]);
                      setLetterheadHeaderName({ name: e.target.files[0].name });
                    } else {
                      setPreviewImgHeader({ image: false });
                    }
                  }}
                />
              </label>
            </div>
            {letterheadHeaderName.name && (
              <>
                <div className="form-icon-group" title="letterhead Header">
                  <figure className="upload-img-size" title="Letterhead Header">
                    <img
                      className=""
                      src={previewImgHeader?.image}
                      alt={"Mobile Login Screen Header"}
                    />
                  </figure>
                </div>
                <div className="frm-group">
                  <ul className="list-unstyled attached-file-ul m-0">
                    <li>
                      <a href={previewImgHeader?.image} target="_blank">
                        <i className="fal fa-file"></i>
                        &nbsp;{letterheadHeaderName.name}
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <label htmlFor="IndexLogo">Letterhead Footer *</label>
            <div className="form-group atttach-file">
              <label>
                <i className="fal fa-paperclip"></i>
                <span>Add Attachment</span>
                <input
                  type="file"
                  id="input"
                  className="form-control  form-control-aatch-file"
                  name="file[]"
                  accept={SUPPORTED_FORMATS}
                  onChange={(e) => {
                    if (e.target.files.length) {
                      let reader = new FileReader();
                      reader.onload = (e) => {
                        setPreviewImgFooter({ image: e.target.result });
                      };
                      reader.readAsDataURL(e.target.files[0]);
                      formik.setFieldValue(
                        "LetterheadFooter",
                        e.target.files[0]
                      );
                      setLetterheadFooter(e.target.files[0]);
                      setLetterheadFooterName({ name: e.target.files[0].name });
                    } else {
                      setPreviewImgFooter({ image: false });
                    }
                  }}
                />
              </label>
            </div>
            {letterheadFooterName.name && (
              <>
                <div className="form-icon-group" title="Letterhead Footer">
                  <figure className="upload-img-size" title="Letterhead Footer">
                    <img
                      className=""
                      src={previewImgFooter?.image}
                      alt={"Mobile Login Screen Header"}
                    />
                  </figure>
                </div>
                <div className="frm-group">
                  <ul className="list-unstyled attached-file-ul m-0">
                    <li>
                      <a href={previewImgFooter?.image} target="_blank">
                        <i className="fal fa-file"></i>
                        &nbsp;{letterheadFooterName.name}
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <label htmlFor="IndexLogo">Student Card Background *</label>
            <div className="form-group atttach-file">
              <label>
                <i className="fal fa-paperclip"></i>
                <span>Add Attachment</span>
                <input
                  type="file"
                  id="input"
                  className="form-control  form-control-aatch-file"
                  name="file[]"
                  accept={SUPPORTED_FORMATS}
                  onChange={(e) => {
                    if (e.target.files.length) {
                      let reader = new FileReader();
                      reader.onload = (e) => {
                        setImagePreview({ image: e.target.result });
                      };
                      reader.readAsDataURL(e.target.files[0]);
                      formik.setFieldValue("photo", e.target.files[0]);
                      setImage(e.target.files[0]);
                      setImageName({ name: e.target.files[0].name });
                    } else {
                      setImagePreview({ image: false });
                    }
                  }}
                />
              </label>
            </div>
            {imagePreview.image && (
              <>
                <div className="form-icon-group" title="Title">
                  <figure className="upload-img-size" title="Title">
                    <img
                      className=""
                      src={imagePreview?.image}
                      alt={"Mobile Login Screen Header"}
                    />
                  </figure>
                </div>
                <div className="frm-group">
                  <ul className="list-unstyled attached-file-ul m-0">
                    <li>
                      <a href={imagePreview?.image} target="_blank">
                        <i className="fal fa-file"></i>
                        &nbsp;{imageName.name}
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="form-group form-group-save-cancel mt-3">
          <button
            className="btn btn-save btn-success"
            type="submit"
            title="Save"
            disabled={disabled}
          >
            {disabled ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fal fa-save"></i>
            )}
            {/* <i className="fal fa-save"></i> */}
            Save
          </button>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => history.goBack()}
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {Object.keys(formik.values).map(key => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block">
                  {formik.errors[key]}
                </div>
              )
            }
          })}
        </div>
      </form>
    </div>
  );
};

export default AddBrandSetting;
