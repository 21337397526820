import React, { useMemo, useState, useEffect } from 'react';
import PermissionsGate from '../../../../utils/permissionGate';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { GetCertificateTemplates, GetCertificateTemplatesFilters, GetCertificatesDetails } from '../../../../services/CertificateServices';
import { updateIntakeCertTemplateById } from '../../../../services/CertificateServices';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import hasPermission from '../../../../utils/hasMultiplePermission';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { TrimText } from '../../../common/TrimText';
import PopupComponent from '../../../common/PopupComponent';
import { RenderQuizTopic } from '../../../../utils/CommonStatusItems';
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { RenderBrands } from '../../../../utils/CommonGroupingItem';

const CertificateLists = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [topics, setTopics] = useState({ arr: [], checkObj: {} });
  const [brands, setBrands] = useState({ arr: [], checkObj: {} });
  const [size, setSize] = useState({ arr: [], checkObj: {} });
  const history = useHistory();
  const { id } = useParams()
  const [filterData, setFilterData] = useState({ brands: [], topics: [], SizeFilter: [] });
  const [isSaving, setIsSaving] = useState(false);
  const [certificateDetails, setCertificateDetails] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "Name",
    sortOrder: "asc"
  })

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ['ccerteficateview'],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push('/noaccess');
    }
  }, []);

  useEffect(() => {
    GetCertificatesDetails({id})
      .then((res) => {
        setCertificateDetails(res.data.data)
      })
      .catch((error) => console.error("error :", error));
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true); // Setting loading to true while fetching data
        const data = {
          search: search,
          viaBrand: brands.arr,
          viaSize: size.arr,
          viaTopics: topics.arr,
          exportStatus: false,
          page: tableState.page,
          limit: tableState.perPage,
          key: tableState.sortKey,
          sort: tableState.sortOrder,
        }
        const response = await GetCertificateTemplates(data);

        setData(response.data.data); // Store the data in the state
        setTotalRows(response.data.total)
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }

    // Trigger fetchData immediately when search term changes
    fetchData();
  }, [search, topics, brands, size, tableState]);

  useEffect(() => {
    GetCertificateTemplatesFilters()
      .then((res) => {
        setFilterData({ ...res.data, });
      })
      .catch((err) => console.error(err));
  }, []);

  const columns = useMemo(() => [
    {
      name: 'Name',
      selector: 'Name',
      sortable: true,
      sortField: 'Name',
      minWidth: '190px',
      cell: (row) => (
        <PermissionsGate scopes={['ccertview']} RenderError={() => (
          <span className="feature-name">
            <span className="textLimit100">{row.Name ? row.Name : ''}</span>
          </span>
        )}>
          <span
            title={row.Name}
            className="as-text-blue curser feature-name"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              formik.setFieldValue("selectedTemplate", { id: row.id, name: row.Name })
            }}
          >
            <span className="textLimit100">{row.Name ? row.Name : ''} </span>
          </span>
        </PermissionsGate>
      ),
    },
    {
      name: 'Brand',
      selector: 'brand_name',
      sortable: true,
      sortField: 'brand_name',
      cell: (row) => <p>{row.brand_name ? RenderBrands(row.brand_name, row.topbar_color).html : '-'}</p>,
    },
    {
      name: 'Topics',
      selector: 'certificateTopics',
      sortable: false,
      sortField: 'certificateTopics',
      cell: (row) => {
        if (row.certificateTopics.length) {
          return row.certificateTopics.map((topic, index) => RenderQuizTopic(topic.name, topic.color).html)
        } else {
          return <p>-</p>; // Handle the case where "Topics" is not provided
        }
      },
    },
    {
      name: 'Keywords',
      selector: 'Keywords',
      sortable: false,
      sortField: 'Keywords',
      cell: (row) => {
        if (row.Keywords) {
          const keywordsArray = row.Keywords.split(',');
          const keywordsList = keywordsArray.map((keyword, index) => (
            <span
              key={index}
              style={{
                backgroundColor: '#E2E2E2',
                color: '#252423',
                margin: '4px',
                padding: '3px',
              }}
            >
              {keyword}
            </span>
          ));

          return <div>{keywordsList}</div>;
        } else {
          return <p>-</p>; // Handle the case where "Topics" is not provided
        }
      },
    },
    {
      name: 'Size',
      selector: 'Size',
      sortable: false,
      sortField: 'Size',
      cell: (row) => (row.Size ? row.Size : '-'),
    },
    {
      name: 'Action',
      selector: '',
      maxWidth: '50px',
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              title="Add"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                formik.setFieldValue("selectedTemplate", { id: row.id, name: row.Name })
              }}
            >
              <i className="fal fa-plus"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      generatedCertificateName: certificateDetails && certificateDetails.generatedCertificateName ? certificateDetails.generatedCertificateName : "",
      certificateCourseName: certificateDetails && certificateDetails.certificateCourseName ? certificateDetails.certificateCourseName : "",
      selectedTemplate: certificateDetails && (certificateDetails.cert_template_id || certificateDetails.cert_template_name) ? { id: certificateDetails.cert_template_id, name: certificateDetails.cert_template_name } : {}
    },
    validationSchema: Yup.object({
      generatedCertificateName: Yup.string().required("Generated Certificate Name is required"),
      selectedTemplate: Yup.string().required("Certificate Template is required"),
    }),
    onSubmit: async (values) => {
      try {
        setIsSaving(true);
        const data = {
          id,
          cert_template_id: values.selectedTemplate.id ?? "",
          certificate_name: values.generatedCertificateName,
          certificate_course_name: values.certificateCourseName,
        }

        await updateIntakeCertTemplateById(data);

        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Certificate Template attached successfully.',
        });
      } catch (error) {
        console.error('Error updating certificate template:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
        });
      } finally {
        setIsSaving(false);
      }
    }
  })

  const exportData = (fileType, fileName) => {
    const header = ["Name", "Brand", "Topics", "Keywords", "Size"];
    const data = {
      search: search,
      viaBrand: brands.arr,
      viaSize: size.arr,
      viaTopics: topics.arr,
      exportStatus: true,
      page: tableState.page,
      limit: tableState.perPage,
      key: tableState.sortKey,
      sort: tableState.sortOrder,
    }
    GetCertificateTemplates(data).then(res => {
        let data = res.data;
        data = data?.map((row) => {
            let obj = {
                Name: row.Name ? row.Name : "-",
                "Brand": row.brand_name ? RenderBrands(row.brand_name, row.topbar_color).text : "-",
                "Topics": row?.certificateTopics ? row.certificateTopics.map(item => item.name).join(",") : "-",
                "Keywords": row?.Keywords ? row?.Keywords : "-",
                "Size": row.Size ? row.Size : "-"
            }
            return obj
        });

        if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            // Create new tag for download file
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
            anchor.click();

            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                URL.revokeObjectURL(blobURL);
            }, 1000);
            Swal.close();

        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.map((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);
            Swal.close();
            return false;
        }
        if (fileType === "pdf") {
            const compatibleData = data.map(item => Object.values(item));
            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                    minCellHeight: 10,
                    minCellWidth: 5,
                    halign: "left",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);
            Swal.close();
            return false;
        }
    }).catch((err) => { 
      console.error(err);
      Swal.close();
    })
  };

  return (
    <div className="card card-profile-info-card">
      <div className="card-body-inr card-body-info">
        <form onSubmit={formik.handleSubmit}>
          <div className="edit-icon new-card-header justify-content-between">
            <div className="card-header">
              Certificate Information
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-lg-3">
              <div className="form-group-blk mb-3">
                <label htmlFor="generatedCertificateName">Generated Certificate Name *</label>
                <input
                  type="text"
                  title="Assessment Name"
                  className={
                    "form-control" +
                    (formik.errors.generatedCertificateName &&
                      formik.touched.generatedCertificateName
                      ? " is-invalid"
                      : "")
                  }
                  placeholder="Enter Generated Certificate Name"
                  name="generatedCertificateName"
                  id="generatedCertificateName"
                  value={formik.values.generatedCertificateName || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-3 col-lg-3">
              <div className="form-group-blk mb-3">
                <label htmlFor="certificateCourseName">Certificate Course Name</label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.certificateCourseName && formik.touched.certificateCourseName
                      ? " is-invalid"
                      : "")
                  }
                  placeholder="Enter Certificate Course Name"
                  name="certificateCourseName"
                  title="certificateCourseName"
                  id="certificateCourseName"
                  value={formik.values.certificateCourseName || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-3 col-lg-3">
              <div className="form-group-blk mb-3">
                <label htmlFor="selectedTemplate">Certificate Templates</label>
                <input
                  disabled
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.selectedTemplate && formik.touched.selectedTemplate
                      ? " is-invalid"
                      : "")
                  }
                  placeholder="Select Certificate Templates"
                  name="selectedTemplate"
                  title="selectedTemplate"
                  id="selectedTemplate"
                  value={formik.values.selectedTemplate?.name || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-3 col-lg-3">
              <div className="search-filter-div-right mb-4">
                <label>&nbsp;</label>
                <div className="filter-search-bar-blk">
                  <div className="add-ticket-blk dropdown-comman">
                    <button
                      data-toggle="modal"
                      data-target="#availableTemplate"
                      className="btn btn-save btn-success"
                      type="button"
                      title="Select Template"
                    >
                      <i className="fal fa-file-alt"></i>Select Template
                    </button>
                    {formik.values.selectedTemplate?.name ? <div className="d-flex select-delete">
                      <Link
                        className="as-text-blue curser"
                        title={formik.values.selectedTemplate?.name}
                        to={`/courseAdministration/certificates/details/${formik.values.selectedTemplate?.id}`}
                        target="_blank"
                      >
                        <span className="textLimit100">
                          {TrimText(formik.values.selectedTemplate?.name, 20)}
                        </span>
                      </Link>
                      <button
                        className="btn btn-danger rounded-circle btn-dropdown-item"
                        title="Delete"
                        type='button'
                        onClick={() => formik.setFieldValue("selectedTemplate", {})}
                      >
                        <i className="fal fa-trash-alt"></i>
                      </button>
                    </div>
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group form-group-save-cancel mt-3 mb-0">
            <div>
              <PermissionsGate scopes={['ccerteficateedit']} errorProps={{disabled: true}}>
              <button
                className="btn btn-save btn-success"
                onClick={formik.handleSubmit}
                type="submit"
                title="Save"
                disabled={isSaving}
              >
                {isSaving ? (
                  <i className="fas fa-cog fa-spin"></i>
                ) : (
                  <i className="fal fa-save"></i>
                )}
                Save
              </button>
              </PermissionsGate>
              <button
                className="btn btn-close btn-danger"
                type="button"
                title="Cancel"
                onClick={() => {
                  if (!formik.dirty) {
                    history.goBack();
                  } else {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "Your work will not be saved",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, Go Back!",
                    })
                      .then((result) => {
                        if (result.isConfirmed) {
                          history.goBack();
                        }
                      })
                      .catch((error) => {
                        console.error("error: ", error);
                      });
                  }
                }}
              >
                <i className="fal fa-times"></i>Cancel
              </button>
            </div>
            {Object.keys(formik.values).map((key) => {
              if (formik.touched[key] && formik.errors[key]) {
                return (
                  <div className="invalid-feedback d-block">
                    {formik.errors[key]}
                  </div>
                );
              }
            })}
          </div>
        </form>
      </div>
      <PopupComponent
        id="availableTemplate"
        type="backendPagingationTable"
        header={{
          iconName: "fa-plus",
          heading: "Select Template"
        }}
        tableData={{
          columns: columns,
          loading: loading,
          setSearch: setSearch,
          data: data,
          state: tableState,
          setState: setTableState,
          totalRows,
          filters: [
            {
              filterName: 'Brand',
              optionArr: filterData.brands,
              state: brands,
              setState: setBrands,
              renderLabelFunction: RenderBrands,
              uniqueId: 'brands'
            },
            {
              filterName: 'Topics',
              optionArr: filterData.topics,
              state: topics,
              setState: setTopics,
              renderLabelFunction: RenderQuizTopic,
              uniqueId: "topic"
            },
            {
              filterName: 'Size',
              optionArr: filterData.SizeFilter,
              state: size,
              setState: setSize,
            },
          ],
          exportFunction: exportData,
          exportFileName: "Available_Templates",
          search: search,
        }}
      />
    </div>
  );
};

export default CertificateLists;
