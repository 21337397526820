import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { GetProgramsNotificationDetails, PreviewStudentNotification, UpdateStudentNotification } from "../../../../../services/StudentsSettingServices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HtmlInputEditor from "../../../../common/HtmlInputEditor";
import { EditorState, convertToRaw, RichUtils, CompositeDecorator, ContentState, convertFromHTML, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import { FORM_DATE_FORMAT, IMAGE_URL } from "../../../../../utils/Constants";
import PermissionsGate from "../../../../../utils/permissionGate";
import hasPermission from "../../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import moment from "moment";
import { getExamsNotification, SendDraftExamNotification } from "../../../../../services/ExternalExamServices";
import draftToHtml from "draftjs-to-html";
import { formatQuestion } from "../../../../../utils/FormatQuestion";
import axios from "axios";

function NotificationForm({ studentName }) {
  const { id, type, subType, subId, tab } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentName, setAttachmentName] = useState(false);
  const [attachmentFilePreview, setAttachmentFilePreview] = useState(false);
  const [editorState, setEditorState] = React.useState("");
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let scopes = subType == "open" ? "snedit" : "snadd";
    let response = hasPermission({ scopes: [scopes], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
    if (subType == "open") {
      const source = axios.CancelToken.source();
      const getDetails = async () =>{
        try {
          const res = await getExamsNotification(subId,id, source.token);

          if(res.status==200 && res.data.data){
            formik.setFieldValue("IncludeAccountPayer", res.data.data.isPayerActive);
            formik.setFieldValue("EmailNotification", res.data.data.isEmailActive);
            formik.setFieldValue("PushNotification", res.data.data.isPushnotificationActive);
            formik.setFieldValue("Title", res.data.data.subject);
            formik.setFieldValue("SendWhen", res.data.data.publish==0 ? 0:2);
            formik.setFieldValue("SendWhenDate",new Date(moment.unix(res.data.data.start)));
            formik.setFieldValue("FileAttachment", res.data.data.attachment ? res.data.data.attachment : "");
            formik.setFieldValue("sentGroups",{ EmailNotification: res.data.data.isEmailActive , PushNotification: res.data.data.isPushnotificationActive} );
             
            const questionBlock =res?.data.data.content;
            setEditorState(questionBlock);
            formik.setFieldValue("Important", res.data.data.ish_importanceActive);
            setAttachmentName(res?.data.data.attachment);
            setAttachmentFilePreview(res?.data.data.attachment.split("/")[res?.data.data.attachment.split("/").length - 1]);
          }else{
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Record not Available",
            }).then(() => { history.goBack() });
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Record not Available",
            }).then(() => { history.goBack() });
          }
        }
      }
      
      getDetails();
      return () =>{
        source.cancel('Component unmounted');
      }
    }
  }, []);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Name: studentName ? studentName : "",
      IncludeAccountPayer: "",
      Important: "",
      sentGroups: { EmailNotification: false, PushNotification: false },
      SendWhen: 0,
      SendWhenDate: "",
      IsDraft: "",
      Title: "",
      Content: "",
      FileAttachment: "",
    },
    validationSchema: Yup.object({
      // Name: Yup.string().required("Programme Name is required"),
      SendWhen: Yup.string().required("Send When is required"),
      Title: Yup.string().required("Title is required").trim(),
      // Content: Yup.string().required("Content is required"),
      Content: Yup.string().test(
        "required",
        "Content is required",
        (value) => {
          let feature = formatQuestion(editorState);
          let isInvalid = feature == "-";
          return !isInvalid;
        }
      ),
      SendWhen: Yup.number(),
      SendWhenDate: Yup.string()
        // .email()
        .when("SendWhen", {
          is: 2,
          then: Yup.string().required("Date is required"),
        }),
      sentGroups: Yup.object().shape(
        {
          PushNotification: Yup.bool().when("EmailNotification", {
            is: (EmailNotification) => !EmailNotification,
            then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification"),
          }),
          EmailNotification: Yup.bool().when("PushNotification", {
            is: (PushNotification) => !PushNotification,
            then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification"),
          }),
        },
        [
          ["PushNotification", "EmailNotification"],
          ["EmailNotification", "PushNotification"],
        ]
      ),
    }),

    onSubmit: (values, { resetForm }) => {
      let formData = new FormData();
      let date=values.SendWhenDate;
      date= new Date(date).getTime()/1000;
      subType == "open" && formData.append("id", subId);
      // formData.append("notification_type", "student_notification");
      formData.append("subject", values?.Title);
      formData.append("content", editorState);
      formData.append("publish_date", date);
      formData.append("publish", values?.SendWhen == false ? 1 : 0);
      formData.append("isPushnotificationActive", values?.sentGroups.PushNotification ? 1 : 0);
      formData.append("isEmailActive", values?.sentGroups.EmailNotification ? 1 : 0);
      formData.append("draft_notification", values?.IsDraft);
      formData.append("attachment", values?.FileAttachment);
      formData.append("isPayerActive", values?.IncludeAccountPayer ? 1 : 0);
      formData.append("ish_importanceActive", values?.Important ? 1 : 0);
      formData.append("intake", id);
      formData.append("exam", id);

      // if (type == "open") {
      //   UpdateProgramme(formData)
      //     .then((res) => {
      //       Swal.fire({
      //         icon: "success",
      //         title: "Success",
      //         text: "Updated successfully",
      //       });
      //       setDisabled(false);
      //     })
      //     .catch((err) => console.log("error :", err));
      // } else {
        SendDraftExamNotification(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
          });
          setDisabled(false);
          history.push(`/studentAdministration/externalExam/details/open/notification/${id}`);
        })
        .catch((err) => console.log("error :", err));
      // }
    },
  });

  const handlePreview = () => {
    const overViewValue = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    const formData = new FormData();
    formData.append("title", formik.values.Title);
    formData.append("content", overViewValue);
    formData.append("attachment", "formik.values.FileAttachment");
    PreviewStudentNotification(formData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Preview Send successfully",
        });
        setDisabled(false);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data[0].message,
        });
        console.log("error :", err.response.data[0].message);
      });
  };

  return (
    <div className="card card-profile-info-card">
      <div className="card-body-inr">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="Title">Title *</label>
              <div className="form-icon-group mb-4" title="Title">
                <input
                  type="text"
                  className={"form-control" + (formik.errors.Title && formik.touched.Title ? " is-invalid" : "")}
                  name="Title"
                  id="Title"
                  placeholder="Title"
                  title="Title"
                  value={formik.values.Title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group-blk mb-3">
                <label></label>
                <div className="custom-check custom-control custom-checkbox mt-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="IncludeAccountPayer"
                    name="select"
                    checked={formik.values.IncludeAccountPayer}
                    onChange={(e) => formik.setFieldValue("IncludeAccountPayer", e.target.checked)}
                  />
                  <label className="custom-control-label" htmlFor="IncludeAccountPayer">
                    Include Account Payer
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <label>&nbsp;</label>
              <div className="form-group-blk mb-3">
                <label></label>
                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="Important"
                    name="select"
                    checked={formik.values.Important}
                    onChange={(e) => formik.setFieldValue("Important", e.target.checked)}
                  />
                  <label className="custom-control-label" htmlFor="Important">
                    Important
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <label>Send Via *</label>
              <div className="form-group-blk mb-3">
                <label></label>
                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="EmailNotification"
                    name="select"
                    checked={formik.values.sentGroups.EmailNotification}
                    onChange={(e) => {
                      console.log("formik.values.EmailNotification", formik.values.sentGroups.EmailNotification);
                      formik.setFieldValue("sentGroups.EmailNotification", e.target.checked);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="EmailNotification">
                    Email
                  </label>
                </div>
                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="PushNotification"
                    name="select"
                    checked={formik.values.sentGroups.PushNotification}
                    onChange={(e) => formik.setFieldValue("sentGroups.PushNotification", e.target.checked)}
                  />
                  <label className="custom-control-label" htmlFor="PushNotification">
                    Push Notification
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <label title="SendWhen">Send When *</label>
              <div className="form-icon-group" title="SendWhen">
                <div className="">
                  <div className="custom-control custom-radio custom-control-inline" title="Now">
                    <input
                      type="radio"
                      id="customRadioInline1"
                      name="customRadioInline"
                      className="custom-control-input"
                      checked={formik.values.SendWhen == 0}
                      onChange={() => formik.setFieldValue("SendWhen", 0)}
                      onBlur={formik.handleBlur}
                    />
                    <label className="custom-control-label" for="customRadioInline1">
                      Now
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline" title="Later">
                    <input
                      type="radio"
                      id="customRadioInline2"
                      name="customRadioInline"
                      className="custom-control-input"
                      title="SendWhen"
                      checked={!formik.values.SendWhen == 0}
                      onChange={() => formik.setFieldValue("SendWhen", 2)}
                      onBlur={formik.handleBlur}
                    />
                    <label className="custom-control-label" for="customRadioInline2">
                      Later
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group-blk mb-3">
                <label>Upload Attachment</label>
                <div className="form-group atttach-file m-0">
                  <label className={formik.errors.FileAttachment && formik.touched.FileAttachment && "file-req is-invalid"}>
                    <i className="fal fa-paperclip"></i>
                    <span>Add Attachment</span>
                    <input
                      type="file"
                      className="form-control  form-control-aatch-file"
                      onChange={(e) => {
                        if (e.target.files.length) {
                            setAttachmentFilePreview(e.target.files[0].name);
                            formik.setFieldValue("FileAttachment", e.target.files[0]);
                            setAttachmentName(URL.createObjectURL(e.target.files[0]));
                        }
                      }}
                    />
                  </label>
                </div>
                {}
                {attachmentName && (
                  <>
                    <div className="frm-group">
                      <ul className="list-unstyled attached-file-ul m-0">
                      <li>
                            <a
                              title={attachmentName}
                              href={attachmentName.includes("blob")
                              ? attachmentName
                              : `${IMAGE_URL}/${attachmentName}`.replaceAll("home/myaie/public_html/", "")} target="_blank">
                              <i className="fal fa-file"></i>
                              &nbsp;{attachmentFilePreview}
                            </a>
                          <button
                            title="Delete"
                            className="btn btn-danger"
                            onClick={() => {
                              formik.setFieldValue("FileAttachment", "");
                              setAttachmentFilePreview("");
                              setAttachmentName("");
                            }}
                          >
                            <i className="fal fa-trash-alt"></i>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
            {!formik.values.SendWhen == 0 ? (
              <div className="col-md-4">
                <label>Date *</label>
                <div className="form-group-blk mb-3">
                  <DatePicker
                    selected={formik.values.SendWhenDate}
                    onChange={(date) => {
                      formik.setFieldValue("SendWhenDate", date);
                    }}
                    // showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat={FORM_DATE_FORMAT}
                    className={"form-control cursor-pointer" + (formik.errors.SendWhenDate && formik.touched.SendWhenDate ? " is-invalid" : "")}
                    title="Date"
                    onBlur={formik.handleBlur}
                    minDate={new Date()}
                    placeholderText="Enter Date"
                    showYearDropdown
                    scrollableYearDropdown
                    dateFormatCalendar="MMMM"
                    onChangeRaw={(e) => e.preventDefault()}
                  />
                </div>
                {formik.errors.SendWhenDate && formik.touched.SendWhenDate ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            ) : (
              ""
            )}
            <div className="col-md-12">
              <label>Content *</label>
              <div className="form-icon-group mb-4 d-block">
                <HtmlInputEditor
                  editorState={editorState} 
                  setEditorState={setEditorState} 
                  isInvalid={
                    formik.touched.Content &&
                    formik.errors.Content
                  }
                  isCKEditor={true}
                  hideSign={true} />
              </div>
            </div>
          </div>

          <div className="form-group form-group-save-cancel">
            <PermissionsGate scopes={subType=="add" ? ["eenadd"]: ["eenedit"]}>
              <button className="btn btn-save btn-success" type="submit" title="Send" disabled={isLoading} onClick={() => formik.setFieldValue("IsDraft", 0)}>
                {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fa fa-paper-plane"></i>}
                Send
              </button>
            </PermissionsGate>
            <PermissionsGate scopes={subType=="add" ? ["eenadd"]: ["eenedit"]}>
              <button className="btn btn-save btn-success" type="submit" title="Save As Draft" disabled={isLoading} onClick={() => formik.setFieldValue("IsDraft", 1)}>
                {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-plus"></i>}
                Save As Draft
              </button>
            </PermissionsGate>
            {/* <PermissionsGate scopes={["snedit"]} errorProps={{ disabled: true }}>
              <button className="btn btn-save btn-success" type="button" title="Preview" disabled={isLoading || !formik.dirty} onClick={handlePreview}>
                {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-file-alt"></i>}
                Preview
              </button>
            </PermissionsGate> */}
            <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()}>
              <i className="fal fa-times"></i>
              Cancel
            </button>

            {formik.touched.Name && formik.errors.Name ? <div className="invalid-feedback d-block">{formik.errors.Name}</div> : null}
            {formik.touched.SendWhen && formik.errors.SendWhen ? <div className="invalid-feedback d-block">{formik.errors.SendWhen}</div> : null}
            {formik.touched.Title && formik.errors.Title ? <div className="invalid-feedback d-block">{formik.errors.Title}</div> : null}
            {formik.touched.SendWhenDate && formik.errors.SendWhenDate ? <div className="invalid-feedback d-block">{formik.errors.SendWhenDate}</div> : null}
            {formik.touched.Content && formik.errors.Content ? <div className="invalid-feedback d-block">{formik.errors.Content}</div> : null}
            {formik.touched?.sentGroups?.EmailNotification && formik.errors?.sentGroups?.EmailNotification ? (
              <div className="invalid-feedback d-block">{formik.errors?.sentGroups?.EmailNotification}</div>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
}
export default NotificationForm;
