import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StartsToday from "./Assessments/StartsToday";
import OpenAssessments from "./Assessments/OpenAssessments";
import Upcoming from "./Assessments/Upcoming";
import MarkingDueToday from "./Assessments/MarkingDueToday";
import MarkingOverDue from "./Assessments/MarkingOverDue";
import UpcomingMarking from "./Assessments/UpcomingMarking";
import Closed from "./Assessments/Closed";
import Marking_pending from "./Assessments/MarkingPending";

const ListOfAssessments = ({globalReset,filterIntake,filterLearningMethod,filterSchool,filterLecturer}) => {

  const { type } = useParams();

  const componentMap = {
    Today: <StartsToday filterIntake={filterIntake} globalReset={globalReset} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer} filterSchool={filterSchool}/>,
    Open: <OpenAssessments filterIntake={filterIntake} globalReset={globalReset} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer} filterSchool={filterSchool}/>,
    upcoming: <Upcoming filterIntake={filterIntake} globalReset={globalReset} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer} filterSchool={filterSchool}/>,
    Making_due_today: <MarkingDueToday filterIntake={filterIntake} globalReset={globalReset} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer} filterSchool={filterSchool}/>,
    Making_over_due: <MarkingOverDue filterIntake={filterIntake} globalReset={globalReset} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer} filterSchool={filterSchool}/>,
    Upcoming_marking: <UpcomingMarking filterIntake={filterIntake} globalReset={globalReset} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer} filterSchool={filterSchool}/>,
    Closed: <Closed filterIntake={filterIntake} globalReset={globalReset} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer} filterSchool={filterSchool}/>,
    Marking_pending: <Marking_pending filterIntake={filterIntake} globalReset={globalReset} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer} filterSchool={filterSchool}/>
  };

  return componentMap[type]
}
export default ListOfAssessments;
