import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import Select from "react-select";
// import { Day, Month, Year, Location, Company, Title, Active } from "../DataReactSelect";
import {
  AddStudentTabListData,
  GetListOfStudentHubBrandList,
  GetListOfStudentHubBrandListCancelToken,
  GetStudentHubTabDetail,
  GetStudentHubTabDetailCancelToken
} from "../../../services/StudentService";
import { useLocation } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import { getCurrentTimeHomePage } from "../../common/Helper";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import axios from "axios";

let Id = "";
let Action = "";
const CreateTab = () => {
  const history = useHistory();
  const location = useLocation();  
  const [tabData, setTabData] = useState({});  
  const [linkedBrandList, setLinkedBrandList] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [selectedDomainIds, setSelectedDomainIds] = useState([]);
  const {id} = useParams();
  
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["shadd"],
      permissions: givenPermsisions,
    });
    if(location.pathname!="/support/student-hub-library/student-hub/create"){
      response = hasPermission({
        scopes: ["shedit"],
        permissions: givenPermsisions,
      });
    }
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetListOfStudentHubBrandListCancelToken(dropdownSource.token);
        let x = {
          value: "",
          label: "",
        };
        // console.log("data", x);
        x = res.data?.brand_setting_list.map((data) => {
          return {
            ...x,
            value: `${data.id}`,
            label: `${data.brand_name}`,
            // id: `${data.id}`
          };
        });
        //   setUserListData(x);
        setLinkedBrandList(x);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetStudentHubTabDetailCancelToken(dropdownSource.token);
        if(res.status===200){
          
          const resp = res.data.tabData;
          setTabData({
            ...tabData,
            tab_name:resp.tab_name,
            icon:resp.icon,
            visible:resp.visible===1 ? true : false
          })
          setSelectedDomain(resp.domain.split("___"))
          setSelectedDomainIds(resp.domainId.split("___"))
        };
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    if(location.pathname!="/support/student-hub-library/student-hub/create"){
    fetchData();
    }
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  // useEffect(() => {    
  //   GetListOfStudentHubBrandList().then((res) => {
  //     let x = {
  //       value: "",
  //       label: "",
  //     };
  //     // console.log("data", x);
  //     x = res.data?.brand_setting_list.map((data) => {
  //       return {
  //         ...x,
  //         value: `${data.id}`,
  //         label: `${data.brand_name}`,
  //         // id: `${data.id}`
  //       };
  //     });
  //     //   setUserListData(x);
  //     setLinkedBrandList(x);
  //   });   
  //   if(location.pathname!="/support/student-hub-library/student-hub/create"){
  //     GetStudentHubTabDetail({tab_id:id}).then((res)=>{
  //       if(res.status===200){
          
  //         const resp = res.data.tabData;
  //         setTabData({
  //           ...tabData,
  //           tab_name:resp.tab_name,
  //           icon:resp.icon,
  //           visible:resp.visible===1 ? true : false
  //         })
  //         setSelectedDomain(resp.domain.split("___"))
  //         setSelectedDomainIds(resp.domainId.split("___"))
  //       }
  //     })
  //   } 
  // }, []);


  const formik = useFormik({
    enableReinitialize: location.pathname!="/support/student-hub-library/student-hub/create" ? true : false,
    initialValues: {
      tab_name: tabData?.tab_name || "",
      visible:  tabData?.visible || false,
      icon: tabData?.icon || "",
      domain: selectedDomain,
      domainId: selectedDomainIds,
    },
    validationSchema: Yup.object({
      tab_name: Yup.string().required("Tab Name is required"),
      visible: Yup.string().required("Visible is required"),
      icon: Yup.string().required("Icon is required"),
      domain: Yup.array().min(1, "At least one brand is required").required(),
    }),
    onSubmit: (values) => {
      
      values.visible = values.visible == true ? 1 : 0;
      
      values = { ...values }
      
      AddStudentTabListData(values)
        .then((res) => {  

          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
            onClose: goToListing
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.data}`,
          });
        });      
    },
  });

  const goToListing = () => {
    // if(location.pathname=="/support/student-hub-library/student-hub/create"){
    //   history.push(`/support/student-hub-library/student-hub/open/${Id}`, { id: Id, action: "open" })
    // }else{
    // }
    history.push(`/support/student-hub-library/student-hub/list`)
  }

  const HandleChangeDomain = (data) => {
    const domainSelected = data ? data?.map((obj) => {
      return obj.label
    }) : [];
    const domainSelectedIds = data ? data?.map((obj) => {
      return obj.value
    }) : [];
    setSelectedDomain(domainSelected);
    setSelectedDomainIds(domainSelectedIds);
    formik.setFieldValue("domain", domainSelected);
    formik.setFieldValue("domainId", domainSelectedIds);
  };

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div class="d-flex align-items-center ">
                    <div class="name-w-head d-flex align-items-center ">
                      {location.pathname==="/support/student-hub-library/student-hub/create" ?
                      <h4 class="text-left-align landing-heading heading_color_dynamic">Create <span>Student Hub</span></h4>
                      :<h4 class="text-left-align landing-heading heading_color_dynamic">Tab: <span>{tabData?.tab_name}</span></h4>
                      }
                    </div>
                    <div class="text-md-right action2-blk">
                      <div class="ticket-view-btn d-flex align-items-center">
                        <div class="add-new-ticket-bx">
                          <button onClick={() => history.goBack()} title="Back" class="btn btn-white-bordered">
                            <i class="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <h4 className="text-left-align landing-heading heading_color_dynamic">
                    Student <span>{tablistData?.tab_name}</span>
                  </h4> */}
                </div>
                {/*  */}

                <div className="tabs-wrap">
                  {/* <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                        <i className="fal fa-users"></i>Tab Details
                      </a>
                    </li>
                    <li className="nav-item" onClick={() => history.push(`/support/student-hub-library/student-hub/audit-trail/tabs/${Id}`) }>
                      <a className="nav-link " id="pills-tab2" data-toggle="pill" href="#pills-tab12" role="tab" aria-controls="pills-tab12" aria-selected="true">
                        <i className="fal fa-history"></i>Audit trail
                      </a>
                    </li>
                  </ul> */}
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      <div className="card card-profile-info-card">
                          <div className="card-body-inr card-body-info">
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row">
                                <div className="col-md-6 col-lg-3">
                                  <div className="form-group" >
                                    <label>Tab Name*</label>
                                    <div className="form-icon-group mb-4">
                                      <input type="text" className={"form-control" + (formik.errors.tab_name && formik.touched.tab_name ? " is-invalid" : "")} name="tab_name" placeholder="Tab Name" title="Tab Name" value={formik.values.tab_name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                      {formik.errors.tab_name && formik.touched.tab_name ? (
                                        <span className="exclamation">
                                          <i className="fal fa-exclamation-circle"></i>
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                  <div className="form-group" >
                                    <label>Brand Name*</label>
                                    <div className="form-icon-group mb-4" title="Brand Name">
                                      <div className="form-control custom-select-box">
                                        <Select className={"form-control custom-select-box" + (formik.errors.domain && formik.touched.domain ? " is-invalid" : "")} name="domain" value={linkedBrandList.filter((obj) => selectedDomainIds?.includes(obj.value))} onChange={(value) => { HandleChangeDomain(value); }} onBlur={formik.handleBlur} options={linkedBrandList} maxMenuHeight={175} isMulti={true} />
                                        {formik.errors.domain && formik.touched.domain ? (
                                          <span className="exclamation">
                                            <i className="fal fa-exclamation-circle"></i>
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                  <div className="form-group" >
                                    <label>Icon*</label>
                                    <div className="exclamation-tooltip-blk form-icon-group mb-4">
                                      <input type="text" className={"form-control" + (formik.errors.icon && formik.touched.icon ? " is-invalid" : "")} name="icon" placeholder="eg: fal fa-cog" title="Icon" value={formik.values.icon} onChange={formik.handleChange} onBlur={formik.handleBlur} />

                                      <div className="exclamation-tooltip">
                                        <i class="fal fa-info-circle"></i>
                                        <div className="exclamation-tooltip-content">
                                          To see available options, please go to <a href="https://fontawesome.com/icons/" target="_blank">https://fontawesome.com/icons/</a> and copy code from there
                                        </div>
                                      </div>
                                      {formik.errors.icon && formik.touched.icon ? (
                                        <span className="exclamation">
                                          <i className="fal fa-exclamation-circle"></i>
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                  <div className="form-group">
                                    <label></label>
                                    <div className="custom-control custom-checkbox text-left mt-3">
                                      <input type="checkbox" className="custom-control-input" id="visible" name="visible" checked={formik.values.visible} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                      <label className="custom-control-label" htmlFor="visible">
                                        Published
                                      </label>
                                    </div>
                                    {formik.errors.visible && formik.touched.visible ? (
                                      <span className="exclamation">
                                        <i className="fal fa-exclamation-circle"></i>
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="form-group-save-cancel">
                                <PermissionsGate errorProps={{ disabled: true }} scopes={["shedit"]}>
                                  <button className="btn btn-save btn-success" type="submit" title="Save" >
                                    <i className="fal fa-save"></i>
                                    Save
                                  </button>
                                </PermissionsGate>
                                <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()} >
                                  <i className="fal fa-times"></i>
                                  Cancel
                                </button>
                              </div>
                              <div className="form-group form-group-save-cancel">
                                {formik.touched.tab_name && formik.errors.tab_name ? (
                                  <div className="invalid-feedback d-block">
                                    {formik.errors.tab_name}
                                  </div>
                                ) : null}
                                {formik.touched.visible && formik.errors.visible ? (
                                  <div className="invalid-feedback d-block">
                                    {formik.errors.visible}
                                  </div>
                                ) : null}
                                {formik.touched.icon && formik.errors.icon ? (
                                  <div className="invalid-feedback d-block">
                                    {formik.errors.icon}
                                  </div>
                                ) : null}
                                {formik.touched.domain && formik.errors.domain ? (
                                  <div className="invalid-feedback d-block">
                                    {formik.errors.domain}
                                  </div>
                                ) : null}
                              </div>
                            </form>
                          </div>
                        {/*<div className="card-body">
                           <div className="card-header">Tab Data</div> 

                          {/* {location.pathname != "/support/student-hub-library/student-hub/create" ?
                            <>
                              <div className="card-header">Categories</div>
                              <div className="custom-table-div filter-search-icon card card-table-custom new-datatable-blk1 ">
                                <div className=" filter-search-bar-blk">
                                  <div className="filter-button-group">
                                    <div className="filter-eff filter-data-btn">
                                      <button className="filter-buttons">
                                        <i className="fal fa-filter"></i>
                                      </button>
                                    </div>
                                    <div className="button-reset dropdown-comman">
                                      <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                                        <i className="fal fa-redo"></i>Reset
                                      </button>
                                    </div>
                                    <div className="files-export-group">
                                      <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Categories"); }} title="Export spreadsheet" >
                                        <i className="fal fa-file-excel icon"></i>
                                      </button>
                                      <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Categories"); }} title="Export CSV" >
                                        <i className="fal fa-file-csv icon"></i>
                                      </button>
                                      <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Categories"); }} title="Export PDF" >
                                        <i className="fal fa-file-pdf icon"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div className="add-ticket-blk button-reset dropdown-comman">
                                    <Link to={`/support/student-hub-library/student-hub/${Id}/category/create`}>
                                      <PermissionsGate errorProps={{ disabled: true }} scopes={["shedit"]}>
                                        <button className="btn btn-primary" title="Create New">
                                          <i className="fal fa-plus"></i>Create New
                                        </button>
                                      </PermissionsGate>
                                    </Link>
                                  </div>
                                </div>
                                <div className="system-administration-table table-responsive">
                                  <div className="table-responsive-div">
                                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer" >
                                      <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer" >
                                        <div id="assessment-table-main_filter" className="dataTables_filter" >
                                          <label>
                                            <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {isLoaded && <SkeletonTicketList />}
                                <DataTable data={dataToRender()} defaultSortField="categoryName" defaultSortAsc={true} columns={columns} pagination={true} noDataComponent={Str.noRecord} />
                              </div>
                            </> : null
                          } 
                        </div>*/}
                      </div>
                    </div>
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTab;
