import React from "react";
import { useParams } from "react-router-dom";
import AddBulkNotification from "./AddBulkNotification";
import EditBulkNotification from "./EditBulkNotification";

import ListOfBulkNotification from "./ListOfBulkNotification";

function BulkTab() {
  const { tab, type, id } = useParams();
  return (
    <div>
      {type === "table" && !id && <ListOfBulkNotification />}
      {type === "add" && !id && <AddBulkNotification />}
      {type === "edit" && id && <EditBulkNotification bulk_id={id} />}
    </div>
  );
}

export default BulkTab;
