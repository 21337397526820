import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { RenderLearningMethod } from '../../../utils/CommonGroupingItem';
import DataTableComponentFrontPagination from '../../common/DataTableComponentFrontPagination';
import { RenderCourseAssignedlistStatus } from '../../../utils/CommonStatusItems';
import SkeletonTicketList from '../../../loaders/SkeletonTicketList';

function EmailAvailableProgrames({AvailablefilterData , availableRec , availableLoading , handleAdd }) {
    const [searchAssigned, setSearchAssigned] = useState("");
    const [availableStudyType, setAvailableStudyType] = useState({ arr: [], checkObj: {} })
    const [availableIntake, setAvailableIntake] = useState({ arr: [], checkObj: {} })

    const availableColumns = useMemo(() => [
        {
            name: "Name",
            selector: "name",
            sortField: "name",
            sortable: true,
            cell: (row) => (
                <Link
                    className="as-text-blue curser feature-name"
                    to={`/program-management-center/programmes/programme/open/${row.id}`}
                    title={row.name}
                >
                    <span className="textLimit100">{row.name}</span>
                </Link>
            ),
        },
        {
            name: "Code",
            selector: "Code",
            sortField: "Code",
            sortable: true,
            cell: (row) => (row.Code ? row.Code : "-"),
        },
        {
            name: "Status",
            selector: "status",
            sortField: "status",
            sortable: true,
            cell: (row) => (row.status ? RenderCourseAssignedlistStatus(row.status).html : "-"),
        },
        {
            name: "Type",
            selector: "qualification_type",
            sortable: true,
            cell: (row) => row?.qualification_type ? (
                <div title={row?.qualification_type}>
                    <span className={`cat ${row?.qualification_color}`}>
                        <i className="fas fa-circle mr-1"></i>
                        {row?.qualification_type}
                    </span>
                </div>) : "-"
        },
        {
            name: "Learning Method",
            selector: "learning_Methods",
            sortable: true,
            sortField: "learning_Methods",
            cell: (row) =>
                row?.learning_Methods
                    ? RenderLearningMethod(row?.learning_Methods, "programme").html
                    : "-",
        },
        {
            name: "Actions",
            selector: "",
            className: "permission-tabe-last-cell",
            cell: (row) => (
                <div className="assessment-08">
                    <div className="as-buttons">
                        <Link
                        className="btn btn-primary rounded-circle"
                        to={`/program-management-center/programmes/programme/open/${row.id}`}
                        title="Open"
                        >
                        <i className="fal fa-folder-open"></i>
                        </Link>
                        <button
                            type="button"
                            className="btn btn-sm btn-primary rounded-circle"
                            onClick={() => handleAdd(row , "add")}
                            title="Add"
                        >
                            <i className="fal fa-plus"></i>
                        </button>
                    </div>
                </div>
            ),
        },
    ]);

    const dataToRenderAvailable = () => {
        let updatedData = [];
        let allData = availableRec;

        if (searchAssigned.length) {
            let name = allData?.filter((item) => {
                let includes = item?.name?.toString()
                    .toLowerCase()
                    .includes(searchAssigned.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });

            let data = [...name];
            let unique = [...new Set(data)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (availableStudyType.arr.length) {
            let tempAssignStudy = updatedData;
            let tempIntakeHash = tempAssignStudy.filter((item) => {
                const startsMarking = availableStudyType.arr.find((it) => it == item.type)
                if (startsMarking) {
                    return startsMarking;
                } else return null;
            });

            updatedData = tempIntakeHash;
        } else if (availableIntake.arr.length) {
            let tempIntake = updatedData;
            let tempIntakeHash = tempIntake.filter((item) => {
                const startsMarking = availableIntake.arr.find((it) => it == item.status)
                if (startsMarking) {
                    return startsMarking;
                } else return null;
            });

            updatedData = tempIntakeHash;
        }

        if (
            searchAssigned.length ||
            availableIntake.arr.length ||
            availableStudyType.arr.length
        ) {
            return updatedData;
        } else {
            return availableRec;
        }
    };

    const exportAvailableData = () => {
        let data = dataToRenderAvailable();
        const header = [
            "Name",
            "Code",
            "Status",
            "Type",
            "Learning Methods"
        ];
        data = data.map((row) => ({
            "Name": row.name ? row.name : "-",
            "Code": row.Code ? row.Code : "-",
            "Status": row.status,
            "Type": row?.qualification_type,
            "Learning Methods": row?.learning_Methods,
        }));
        
        const pdfData = data.map((row) => {
            return [
                row["Name"],
                row["Code"],
                row["Status"],
                row["Type"],
                row["Learning Methods"]
            ];
        });
        return {
            header,
            data,
            pdfData,
            columnStyles: {}
        }
    };

    return (
        <>
            <div className="modal-content">
                <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Assign Programme</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
                <div className="modal-body">
                    <DataTableComponentFrontPagination
                        data={dataToRenderAvailable()}
                        columns={availableColumns}
                        loading={availableLoading}
                        exportData={exportAvailableData}
                        exportFileName={"Assigned_shortCourse"}
                        defaultSort={{ defaultSortColumn: "name", defaultSortAsc: false }}
                        custumcss="border-0"
                        search={searchAssigned}
                        progressComponent={<SkeletonTicketList />}
                        setSearch={setSearchAssigned}
                        filters={[
                            {
                                filterName: "Status",
                                optionArr: AvailablefilterData.intake,
                                state: availableIntake,
                                setState: setAvailableIntake,
                                uniqueId: "availableIntake",
                                isOptionReversed: true,
                                renderLabelFunction: RenderCourseAssignedlistStatus,
                            },
                            {
                                filterName: "Type",
                                optionArr: AvailablefilterData.learning,
                                state: availableStudyType,
                                setState: setAvailableStudyType,
                                uniqueId: "availableStudyType",
                                renderLabelFunction: RenderLearningMethod,
                            }
                        ]}
                    />
                </div>
            </div>
        </>
    )
}

export default EmailAvailableProgrames