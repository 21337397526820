import React from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { GlobalIconNames } from "../../../utils/GlobalIconNames";
import PermissionsGate from "../../../utils/permissionGate";
import AuditTrail from "./AuditTrail";
import RegistrationTable from "./RegistrationTable";
import StudentDetails from "./StudentDetails";
import StudentTable from "./StudentTable";

function MergedDataTab() {
  const { tab, type, id, subTab } = useParams();
  const history = useHistory();
  return (
    // <div>
    //   {type && type == "table" && !id && <StudentTable />}
    //   {type && type == "open" && <StudentDetails />}
    // </div>

    <div className="tabs-wrap">
      <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
        <PermissionsGate scopes={["nmsmsview"]}>
        <li className="nav-item" onClick={() => history.push("/administration/netsuite/mergedData/table/student")} >
          <Link className={`nav-link ${id === "student" ? "active" : ""}`} id="pills-tab1" data-toggle="pill" to={"/administration/netsuite/mergedData/table/student"} href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true" >
            <i className="fal fa-users"></i>
            Students
          </Link>
        </li>
        </PermissionsGate>
        <PermissionsGate scopes={["nmsmrview"]}>
        <li className="nav-item" onClick={() => history.push("/administration/netsuite/mergedData/table/registration")} >
          <Link className={`nav-link ${((id === "registration") || tab === "mergedDataRegistration")  ? "active" : ""}`} id="pills-tab2" data-toggle="pill1" to={"/administration/netsuite/mergedData/table/registration"} href="#pills-tab12" role="tab" aria-controls="pills-tab12" aria-selected="false" >
            <i className="fal fa-users"></i>
            Registrations
          </Link>
        </li>
        </PermissionsGate>
        <li className="nav-item" onClick={() => history.push("/administration/netsuite/mergedData/table/auditTrail")} >
          <Link className={`nav-link ${id === "auditTrail" ? "active" : ""}`} id="pills-tab2" data-toggle="pill3" to={"/administration/netsuite/mergedData/table/auditTrail"} href="#pills-tab13" role="tab" aria-controls="pills-tab13" aria-selected="false" >
            {GlobalIconNames("audittrail").html}
          </Link>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
          {(type && type == "table" && !id) || (id === "student" && <StudentTable />)}
          {(type && type == "table" && !id) || (id === "registration" && <RegistrationTable />)}
          {(type && type == "table" && !id) || (id === "auditTrail" && <AuditTrail />)}
          {type && type == "open" && <StudentDetails />}
        </div>
      </div>
    </div>
  );
}

export default MergedDataTab;
