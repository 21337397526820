import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { GetExamSettingsAuditFilters, GetExamSettingsAuditTrails } from "../../../../services/ExternalExamServices";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import AuditTrailsTable from "../../../common/AuditTrailsTable";

function ExamSettingAuditTrail() {
  const history = useHistory();
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"];

  useEffect(() => {
    let response = hasPermission({ scopes: ["eeesview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, []);

  return (
    <AuditTrailsTable 
      apiFunction={GetExamSettingsAuditTrails}
      filterArrApi={GetExamSettingsAuditFilters}
      columnsToShow={columnsToShow}
      exportFileName={"Exam_Settings_Audit_Trail"}
      isResourceNameShown={true}
      isShowingResourceTypeFilter
      // apiParams={{action_id: subTab}}
      // actionId={subTab}
    />
  );
}

export default ExamSettingAuditTrail;
