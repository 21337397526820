import React, { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as Yup from "yup";
import {
  GetFilters,
  updateCertificateTemplateById,
  GetAssignedCertificateCourses,
  GetAssignedCertificateCoursesFilters,
  UnAssginedCertificate,
  AssginedCertificate,
} from '../../../services/CertificateServices';
import Swal from 'sweetalert2';
import { IMAGE_URL, TABLE_DATE_FORMAT } from '../../../utils/Constants';
import { convertBase64ToBlob } from '../../common/ImageConversions';
import DataTableComponent from '../../common/DataTableComponent';
import PermissionsGate from '../../../utils/permissionGate';
import { RenderCourseType, RenderLearningMethod } from '../../../utils/CommonGroupingItem';
import moment from 'moment';
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import PopupComponent from '../../common/PopupComponent';
import { useFormik } from 'formik';
import $ from "jquery";
import HtmlParser from 'react-html-parser';

const DetailsPage = ({ response }) => {
  const [certificateDetails, setCertificateDetails] = useState({})
  const [brands, setBrands] = useState([]);
  const [size, setSize] = useState([]);
  const [topics, setTopics] = useState([]);
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (response) {
      setCertificateDetails(response)
    }
  }, [response]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await GetFilters();
        const { brands, topics, sizeOptions } = response.data;
        setBrands(brands);
        setSize(sizeOptions);
        let tempArr = topics?.map(item=> ({ 
          value: item?.value, 
          label: HtmlParser(`<span style="color:#fff;background:${item?.color};" title=${item?.options}>${item?.options}</span>`)
        }));
        setTopics(tempArr);
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };

    fetchFilters();
  }, []);

  // Only Images are allowed for uploading in template
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: certificateDetails && certificateDetails.Name ? certificateDetails.Name : "",
      brand: certificateDetails && certificateDetails.Brand_id ? certificateDetails.Brand_id : "",
      size: certificateDetails && certificateDetails.Size ? certificateDetails.Size : "",
      keywords: certificateDetails && certificateDetails.Keywords ? certificateDetails.Keywords?.split(',') : [],
      topics: certificateDetails && certificateDetails.certificateTopics ? certificateDetails.certificateTopics.map(topic => ({ value: topic.id, label: topic.name })) : [],
      showStudentName: certificateDetails && certificateDetails.ShowStudentName ? Boolean(certificateDetails.ShowStudentName) : "",
      showCourseName: certificateDetails && certificateDetails.ShowCourseName ? Boolean(certificateDetails.ShowCourseName) : "",
      showDate: certificateDetails && certificateDetails.ShowDate ? Boolean(certificateDetails.ShowDate) : "",
      backgroundImage: certificateDetails && certificateDetails.BackgroundImage ? certificateDetails.BackgroundImage : "",
      backgroundImageName: certificateDetails && certificateDetails.BackgroundImage ? certificateDetails.BackgroundImage.split('/').pop() : ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is Required"),
      brand: Yup.string().required("Brand is Required"),
      size: Yup.string().required("Size is Required"),
      keywords: Yup.array().min(1, "Atleast one keyword is required").required(),
      topics: Yup.array().min(1, "Atleast one topic is required").required(),
      backgroundImage: Yup.string().required("Background Image is Required"),
    }),
    // this function is responsible for creating a new certificate template
    // Function calls the CreateCertificateTemplate API endpoint
    onSubmit: async ({
      name,
      brand,
      size,
      topics,
      showStudentName,
      showCourseName,
      showDate,
      backgroundImage,
      keywords
    }) => {
      setSaveLoading(true)
      try {
        // Gathering data from the form
        const formData = new FormData();
        if (isImageChanged) {
          const blobImage = convertBase64ToBlob(backgroundImage);
          const file_ = new File([blobImage], 'image.png', { type: 'image/png' });
          formData.append('BackgroundImage', file_);
        } else {
          formData.append('BackgroundImage', backgroundImage);
        }

        formData.append('id', id);
        formData.append('Name', name);
        formData.append('Brand_id', brand);
        formData.append('Size', size);
        formData.append('Keywords', keywords.join(', '));
        topics.map((item, index) => {
          formData.append(`Topics[${index}]`, item.value);
        })
        formData.append('ShowStudentName', showStudentName ? 1 : 0);
        formData.append('ShowCourseName', showCourseName ? 1 : 0);
        formData.append('ShowDate', showDate ? 1 : 0);

        //POST request
        await updateCertificateTemplateById(formData);
        setSaveLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Certificate template Updated successfully.',
        });
      } catch (error) {
        console.error('Error updating certificate template:', error);
        setSaveLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
        });
      }
    }
  })

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      formik.setFieldValue("backgroundImageName", file.name);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        formik.setFieldValue("backgroundImage", reader.result)
      };
      setIsImageChanged(true);
    }
  };

  const handleDeleteFile = () => {
    document.getElementById("input").value = ''
    formik.setFieldValue("backgroundImage", "")
    formik.setFieldValue("backgroundImageName", "")
  }

  const handleKeyDown = (event) => {
    let keyword = [...formik.values.keywords];
    if (event.key === "Enter") {
      if (event.target.value.trim()) {
        keyword.push(event.target.value.trim());
        event.target.value = ""
        formik.setFieldValue("keywords", [...keyword]);
      }

    }
  };

  const removeKeyword = (index) => {
    const newKeywords = [...formik.values.keywords];
    newKeywords.splice(index, 1);
    formik.setFieldValue("keywords", [...newKeywords]);
  };

  const sizeOptions = [
    {
      value: 'A4 Landscape',
      label: 'A4 (Landscape) - 297 x 210mm',
    },
    {
      value: 'A4 Portrait',
      label: 'A4 (Portrait) - 210 x 297 mm',
    },
    { value: 'Custom', label: 'Custom' },
  ]

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="row">
        <div className="col-lg-9 col-12 card-with-small-second-card">
          <div
            className="card card-profile-info-card course-details-tab-sec"
            id="parentElem"
          >
            <div className="course-info-sec">
              <div className="edit-icon new-card-header">
                <div className="card-header">
                  Certificate Information
                </div>
              </div>
              <div className="card-body-inr card-body-info">
                <div className="row">
                  <div className="col-md-4 col-lg-4">
                    <label htmlFor="name">Name *</label>
                    <div className="form-icon-group mb-4" title="Name">
                      <input
                        type="text"
                        className={
                          "form-control"
                          + (formik.errors.name && formik.touched.name
                            ? " is-invalid"
                            : "")
                        }
                        name="name"
                        id="name"
                        placeholder="Name"
                        title="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 ">
                    <div className="form-group-blk mb-3">
                      <label>Brand *</label>
                      <Select
                        className={
                          'form-control custom-select-box'
                          + (formik.errors.brand &&
                            formik.touched.brand
                            ? ' is-invalid'
                            : '')
                        }
                        name="brand"
                        value={brands.filter(brand => brand.value === formik.values.brand)}
                        options={brands}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            formik.setFieldValue('brand', selectedOption.value);
                          } else {
                            formik.setFieldValue('brand', "");
                          }
                        }}
                        isClearable
                        onBlur={formik.handleBlur}
                        maxMenuHeight={175}
                        placeholder={formik.values.brand || 'Brand'}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 ">
                    <div className="form-group-blk mb-3">
                      <label>Size *</label>
                      <Select
                        className={
                          'form-control custom-select-box'
                          + (formik.errors.size &&
                            formik.touched.size
                            ? ' is-invalid'
                            : '')
                        }
                        name="size"
                        value={size.filter(size => size.value === formik.values.size)}
                        options={size}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            formik.setFieldValue('size', selectedOption.value);
                          } else {
                            formik.setFieldValue('size', "");
                          }
                        }}
                        isClearable
                        onBlur={formik.handleBlur}
                        maxMenuHeight={175}
                        placeholder={formik.values.size || 'Size'}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <label>Keywords *</label>
                    <div className="form-icon-group">
                      <div className="form-control custom-select-box">
                        <input
                          type="text"
                          className={
                            "form-control "
                            + (formik.errors.keywords && formik.touched.keywords
                              ? " is-invalid"
                              : "")
                          }
                          name="keywords"
                          placeholder="Keywords"
                          title="keywords"
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                    {formik.values.keywords.length > 0 && (
                      <ul className="tag-list">
                        {formik.values.keywords?.map((ele, index) => (
                          <li key={index}>
                            {ele}
                            <span
                              onClick={() => removeKeyword(index)}
                            >
                              <i className="fal fa-times"></i>
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="form-group-blk mb-3">
                      <label>Topics *</label>
                      <Select
                        className={
                          'form-control color-drop custom-select-box is-multi '
                          + (formik.errors.topics &&
                            formik.touched.topics
                            ? ' is-invalid'
                            : '')
                        }
                        name="topics"
                        value={formik.values.topics}
                        options={topics || []}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            formik.setFieldValue('topics', selectedOption);
                          } else {
                            formik.setFieldValue('topics', []);
                          }
                        }}
                        isClearable
                        isMulti
                        onBlur={formik.handleBlur}
                        maxMenuHeight={175}
                        placeholder={formik.values.topics.length ? formik.values.topics : 'Topics'}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-2 mb-2">
                  <div className='mr-3'>
                    <label>Show Student Name</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={formik.values.showStudentName}
                        onChange={formik.handleChange}
                        name='showStudentName'
                        id='showStudentName'
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className='mr-3'>
                    <label>Show Course Name</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={formik.values.showCourseName}
                        onChange={formik.handleChange}
                        name='showCourseName'
                        id='showCourseName'
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className='mr-3'>
                    <label>Show Date</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={formik.values.showDate}
                        onChange={formik.handleChange}
                        name='showDate'
                        id='showDate'
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group form-group-save-cancel">
              <PermissionsGate
                scopes={['ccertedit']}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-save btn-success"
                  type="button"
                  title="Save"
                  onClick={formik.handleSubmit}
                  disabled={saveLoading}
                >
                  {saveLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                  Save
                </button>
              </PermissionsGate>
              <PermissionsGate
                scopes={['ccertedit']}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => history.push(`/courseAdministration/certificates/repositionElements/${id}`)}
                  type='button'
                  title="Reposition Elements"
                >
                  <i className="fal fa-arrows"></i> Reposition Elements
                </button>
              </PermissionsGate>
              <button
                className="btn btn-close btn-danger"
                type="button"
                title="Cancel"
                onClick={formik.resetForm}
              >
                <i className="fal fa-times"></i>Cancel
              </button>

              {Object.keys(formik.values).map((key) => {
                if (formik.touched[key] && formik.errors[key]) {
                  return (
                    <div className="invalid-feedback d-block" key={key}>
                      {formik.errors[key]}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className="col-lg-3 right-side-cols">
          <div
            className="card card-profile-info-card course-details-tab-sec"
            id="parentElem"
          >
            <div className="course-sec-gap course-setting-sec">
              {/* <div className="edit-icon new-card-header">
                <div className="card-header">Course Settings</div>
              </div> */}
              <div className='row'>
                <div className="col-md-12 col-lg-12">
                  <label htmlFor="IndexLogo">Background Image *</label>
                  <div className="form-group atttach-file mb-2 file-delete-btn">
                    <label>
                      <i className="fal fa-paperclip"></i>
                      <span>Add Attachment</span>
                      <input
                        // title={
                        //   letterheadHeaderName.name
                        //     ? letterheadHeaderName.name
                        //     : "No File Uploaded"
                        // }
                        type="file"
                        id="input"
                        className="form-control  form-control-aatch-file"
                        name="file[]"
                        accept={SUPPORTED_FORMATS}
                        onChange={handleUploadImage}
                      />
                    </label>
                  </div>
                  {formik.values.backgroundImage && (
                    <>
                      <span className='file-delete-btn'>
                        {isImageChanged ? (formik.values.backgroundImageName || "") : <a href={IMAGE_URL + "/" + (formik.values.backgroundImage.replace("home/myaie/public_html/", "").replace("public/", ""))} target='_blank'>{formik.values.backgroundImageName}</a>}
                        <button className="btn btn-danger rounded-circle" title="Delete" type="button"
                          onClick={handleDeleteFile}
                        >
                          <i className="fal fa-trash-alt"></i>
                        </button>
                      </span>
                      <div className="form-icon-group mt-2" title={formik.values.backgroundImageName || "Certificate Template"}>
                        <figure className="upload-img-size">
                          <img
                            className=""
                            src={isImageChanged ? formik.values.backgroundImage : IMAGE_URL + "/" + (formik.values.backgroundImage.replace("home/myaie/public_html/", "").replace("public/", ""))}
                            alt={formik.values.backgroundImageName || "Certificate Template"}
                          />
                        </figure>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};

function Courses() {

  // Assigned Courses Table states
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} })
  const [intake, setIntake] = useState({ arr: [], checkObj: {} })
  const [intakeType, setIntakeType] = useState({ arr: [], checkObj: {} })
  const [intakeArr, setIntakeArr] = useState([])
  const [learningMethodArr, setLearningMethodArr] = useState([]);
  const [intakeTypeArr, setIntakeTypeArr] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [tableState, setTableState] = useState({
    perPage: 10,
    page: 1,
    sortOrder: "ASC",
    sortKey: "type"
  })

  // Available courses Table States
  const [availableList, setAvailableList] = useState([])
  const [availableLoading, setAvailableLoading] = useState(false);
  const [availableSearch, setAvailableSearch] = useState("");
  const [availableTotalRows, setAvailableTotalRows] = useState(0);
  const [availableLearningMethod, setAvailableLearningMethod] = useState({ arr: [], checkObj: {} })
  const [availableIntake, setAvailableIntake] = useState({ arr: [], checkObj: {} })
  const [availableIntakeType, setAvailableIntakeType] = useState({ arr: [], checkObj: {} })
  const [availableIntakeArr, setAvailableIntakeArr] = useState([])
  const [availableLearningMethodArr, setAvailableLearningMethodArr] = useState([]);
  const [availableIntakeTypeArr, setAvailableIntakeTypeArr] = useState([]);
  const [availableRefresh, setAvailableRefresh] = useState(false);
  const [availableTableState, setAvailableTableState] = useState({
    perPage: 10,
    page: 1,
    sortOrder: "ASC",
    sortKey: "type"
  })

  const { id } = useParams();

  useEffect(() => {
    setLoading(true)
    const data = {
      key: tableState.sortKey,
      sort: tableState.sortOrder,
      page: tableState.page,
      limit: tableState.perPage,
      id,
      search,
      intake: intake.arr,
      learning_method: learningMethod.arr,
      intake_type: intakeType.arr,
      isAssigned: 1
    }
    GetAssignedCertificateCourses(data).then(res => {
      setList(res.data.data.data)
      setTotalRows(res.data.data.total)
      setLoading(false)
    }).catch(err => {
      console.error("error :", err)
      setLoading(false)
    })
  }, [learningMethod, tableState, id, search, refresh, intake, intakeType])

  useEffect(() => {
    GetAssignedCertificateCoursesFilters(id, 1).then(res => {
      setIntakeArr(res.data.intake_year_filters)
      setLearningMethodArr(res.data.learning_method_filters)
      setIntakeTypeArr(res.data.subject_type_filters)
    }).catch(err => {
      console.error("error:", err)
    })
  }, [])

  useEffect(() => {
    setAvailableLoading(true)
    const data = {
      key: availableTableState.sortKey,
      sort: availableTableState.sortOrder,
      page: availableTableState.page,
      limit: availableTableState.perPage,
      id,
      search: availableSearch,
      intake: availableIntake.arr,
      learning_method: availableLearningMethod.arr,
      intake_type: availableIntakeType.arr,
      isAssigned: "0"
    }
    GetAssignedCertificateCourses(data).then(res => {
      setAvailableList(res.data.data.data)
      setAvailableTotalRows(res.data.data.total)
      setAvailableLoading(false)
    }).catch(err => {
      console.error("error :", err)
      setAvailableLoading(false)
    })
  }, [availableLearningMethod, availableTableState, id, availableSearch, availableRefresh, availableIntake, availableIntakeType])

  useEffect(() => {
    GetAssignedCertificateCoursesFilters(id, 0).then(res => {
      setAvailableIntakeArr(res.data.intake_year_filters)
      setAvailableLearningMethodArr(res.data.learning_method_filters)
      setAvailableIntakeTypeArr(res.data.subject_type_filters)
    }).catch(err => {
      console.error("error:", err)
    })
  }, [])


  const columns = useMemo(() => [
    {
      name: "Course Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => {
        return row.name ? (
          <Link
            title={row.name}
            className="as-text-blue curser feature-name"
            to={`/courseAdministration/coursesdetails/${row.id}/dashboard/show`}
          >
            <span title={row.name} className="textLimit100">{row.name}</span>
          </Link>
        ) : (
          "-"
        );
      },
    },
    {
      name: "Learning Method",
      selector: "type",
      sortField: "type",
      sortable: true,
      cell: (row) => (
        <p>{row.type ? RenderLearningMethod(row.type).html : "-"}</p>
      ),
    },
    {
      name: "Start",
      selector: "start",
      sortField: "start",
      sortable: true,
      cell: (row) => (
        row.start ? (
          <div className="dateTime">
            <p className="right-space">
              {row.start.toString().length == 10
                ? moment.unix(row.start).format(TABLE_DATE_FORMAT)
                : moment(parseInt(`${row.start}`)).format(TABLE_DATE_FORMAT)}
            </p>
          </div>
        ) : (
          "-"
        )
      ),
    },
    {
      name: "End",
      selector: "end",
      sortField: "end",
      sortable: true,
      cell: (row) => (
        row.end ? (
          <div className="dateTime">
            <span className="right-space">
              {row.end.toString().length == 10
                ? moment.unix(row.end).format(TABLE_DATE_FORMAT)
                : moment(parseInt(`${row.end}`)).format(TABLE_DATE_FORMAT)}
            </span>
          </div>
        ) : (
          "-"
        )
      ),
    },
    {
      name: "Intake",
      selector: "year",
      sortField: "year",
      sortable: true,
      cell: (row) => <p>{row.year ? row.year + "-" + row.number : "-"}</p>,
    },
    {
      name: "Course Type",
      selector: "subject_type",
      sortField: "subject_type",
      sortable: true,
      cell: (row) => (
        <p>
          {row.subject_type ? RenderCourseType(row.subject_type).html : "-"}
        </p>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["cview"]}
              RenderError={() => (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link
                className="btn btn-primary rounded-circle"
                title="Open"
                to={`/courseAdministration/coursesdetails/${row.id}/dashboard/show`}
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            <PermissionsGate
              scopes={["pcdelete"]}
              errorProps={{ disabled: true }}
            >
              <button
                className="btn btn-danger rounded-circle"
                title="Remove"
                onClick={() => handleDelete(row.id)}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const availableColumns = useMemo(() => [
    {
      name: "Course Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => {
        return row.name ? (
          <Link
            title={row.name}
            className="as-text-blue curser feature-name"
            to={`/courseAdministration/coursesdetails/${row.id}/dashboard/show`}
            onClick={() => $("#assignCourses").modal("hide")}
          >
            <span title={row.name} className="textLimit100">{row.name}</span>
          </Link>
        ) : (
          "-"
        );
      },
    },
    {
      name: "Learning Method",
      selector: "type",
      sortField: "type",
      sortable: true,
      cell: (row) => (
        <p>{row.type ? RenderLearningMethod(row.type).html : "-"}</p>
      ),
    },
    {
      name: "Start",
      selector: "start",
      sortField: "start",
      sortable: true,
      cell: (row) => (
        row.start ? (
          <div className="dateTime">
            <p className="right-space">
              {row.start.toString().length == 10
                ? moment.unix(row.start).format(TABLE_DATE_FORMAT)
                : moment(parseInt(`${row.start}`)).format(TABLE_DATE_FORMAT)}
            </p>
          </div>
        ) : (
          "-"
        )
      ),
    },
    {
      name: "End",
      selector: "end",
      sortField: "end",
      sortable: true,
      cell: (row) => (
        row.end ? (
          <div className="dateTime">
            <span className="right-space">
              {row.end.toString().length == 10
                ? moment.unix(row.end).format(TABLE_DATE_FORMAT)
                : moment(parseInt(`${row.end}`)).format(TABLE_DATE_FORMAT)}
            </span>
          </div>
        ) : (
          "-"
        )
      ),
    },
    {
      name: "Intake",
      selector: "year",
      sortField: "year",
      sortable: true,
      cell: (row) => <p>{row.year ? row.year + "-" + row.number : "-"}</p>,
    },
    {
      name: "Course Type",
      selector: "subject_type",
      sortField: "subject_type",
      sortable: true,
      cell: (row) => (
        <p>
          {row.subject_type ? RenderCourseType(row.subject_type).html : "-"}
        </p>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["cview"]}
              RenderError={() => (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link
                className="btn btn-primary rounded-circle"
                title="Open"
                to={`/courseAdministration/coursesdetails/${row.id}/dashboard/show`}
                onClick={() => $("#assignCourses").modal("hide")}
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            <PermissionsGate
              scopes={["pcdelete"]}
              errorProps={{ disabled: true }}
            >
              <button
                className="btn btn-primary rounded-circle"
                title="Assign"
                onClick={() => handleAssign(row.id, row.name)}
              >
                <i className="fal fa-plus"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (courseId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        UnAssginedCertificate(courseId)
          .then((res) => {
            Swal.fire("Removed!", "Course has been removed.", "success");
            setRefresh(!refresh)
          })
          .catch((err) => console.log("error :", err));
      }
    });
  }

  const handleAssign = (course_id, certificate_name) => {
    const data = {
      course_id,
      certificate_name,
      cert_template_id: id
    }
    AssginedCertificate(data)
      .then((res) => {
        Swal.fire("Assigned!", "Course has been assigned.", "success");
        setRefresh(!refresh)
      })
      .catch((err) => console.log("error :", err));
  }

  const exportData = async (fileType, fileName) => {
    const res = await GetAssignedCertificateCourses({
      key: tableState.sortKey,
      sort: tableState.sortOrder,
      page: tableState.page,
      limit: tableState.perPage,
      id,
      search,
      intake: intake.arr,
      learning_method: learningMethod.arr,
      intake_type: intakeType.arr,
      exportStatus: "true",
      isAssigned: 1
    })
    let data = res.data.data;
    const header = [
      "Course Name",
      "Learning Method",
      "Start",
      "End",
      "Intake",
      "Course Type",
    ];
    data = data?.map((row) => ({
      ...row,
      "Course Name": row?.name ? row?.name : "-",
      "Course Type": row?.type ? RenderCourseType(row.subject_type).text : "-",
      "Start": row?.start
        ? row.start.toString().length == 10
          ? moment.unix(row.start).format(TABLE_DATE_FORMAT)
          : moment(parseInt(`${row.start}`)).format(TABLE_DATE_FORMAT)
        : "-",
      "End": row?.end
        ? row.end.toString().length == 10
          ? moment.unix(row.end).format(TABLE_DATE_FORMAT)
          : moment(parseInt(`${row.end}`)).format(TABLE_DATE_FORMAT)
        : "-",
      Intake: row.number ? row.year + "-" + row.number : "-",
      "Learning Method": row?.subject_type
        ? RenderLearningMethod(row.type).text
        : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Course Name"],
          row["Learning Method"],
          row["Start"],
          row["End"],
          row["Intake"],
          row["Course Type"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        columnStyles: {
          0: {
            columnWidth: 45,
          },
          1: {
            columnWidth: 30,
          },
          2: {
            columnWidth: 20,
          },
          3: {
            columnWidth: 20,
          },
          4: {
            columnWidth: 20,
          },
          5: {
            columnWidth: 30,
          },
        },
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };
  const availableExportData = async (fileType, fileName) => {
    const res = await GetAssignedCertificateCourses({
      key: availableTableState.sortKey,
      sort: availableTableState.sortOrder,
      page: availableTableState.page,
      limit: availableTableState.perPage,
      id,
      search: availableSearch,
      intake: availableIntake.arr,
      learning_method: availableLearningMethod.arr,
      intake_type: availableIntakeType.arr,
      isAssigned: "0",
      exportStatus: "true"
    })
    let data = res.data.data;
    const header = [
      "Course Name",
      "Learning Method",
      "Start",
      "End",
      "Intake",
      "Course Type",
    ];
    data = data?.map((row) => ({
      ...row,
      "Course Name": row?.name ? row?.name : "-",
      "Course Type": row?.type ? RenderCourseType(row.subject_type).text : "-",
      "Start": row?.start
        ? row.start.toString().length == 10
          ? moment.unix(row.start).format(TABLE_DATE_FORMAT)
          : moment(parseInt(`${row.start}`)).format(TABLE_DATE_FORMAT)
        : "-",
      "End": row?.end
        ? row.end.toString().length == 10
          ? moment.unix(row.end).format(TABLE_DATE_FORMAT)
          : moment(parseInt(`${row.end}`)).format(TABLE_DATE_FORMAT)
        : "-",
      Intake: row.number ? row.year + "-" + row.number : "-",
      "Learning Method": row?.subject_type
        ? RenderLearningMethod(row.type).text
        : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Course Name"],
          row["Learning Method"],
          row["Start"],
          row["End"],
          row["Intake"],
          row["Course Type"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        columnStyles: {
          0: {
            columnWidth: 45,
          },
          1: {
            columnWidth: 30,
          },
          2: {
            columnWidth: 20,
          },
          3: {
            columnWidth: 20,
          },
          4: {
            columnWidth: 20,
          },
          5: {
            columnWidth: 30,
          },
        },
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };


  return (
    <>
      <DataTableComponent
        data={list}
        columns={columns}
        loading={loading}
        setSearch={setSearch}
        state={tableState}
        setState={setTableState}
        totalRows={totalRows}
        exportFunction={exportData}
        exportFileName={"Assigned_courses"}
        filters={[
          {
            filterName: "Learning Method",
            optionArr: learningMethodArr,
            state: learningMethod,
            setState: setLearningMethod,
            renderLabelFunction: RenderLearningMethod
          },
          {
            filterName: "Intake",
            optionArr: intakeArr,
            state: intake,
            setState: setIntake,
            isOptionReversed: true
          },
          {
            filterName: "Course Type",
            optionArr: intakeTypeArr,
            state: intakeType,
            setState: setIntakeType,
            renderLabelFunction: RenderCourseType
          }
        ]}
        tableButton={[
          <button className="btn btn-primary" title="Assign Courses" data-toggle="modal" data-target="#assignCourses">
            <i className="fal fa-plus"></i>Assign Courses
          </button>
        ]}
      />
      <PopupComponent
        id='assignCourses'
        type='backendPagingationTable'
        header={{
          iconName: "fa-info-circle",
          heading: "Assign Courses"
        }}
        tableData={{
          data: availableList,
          columns: availableColumns,
          loading: availableLoading,
          setSearch: setAvailableSearch,
          state: availableTableState,
          setState: setAvailableTableState,
          totalRows: availableTotalRows,
          exportFunction: availableExportData,
          exportFileName: "Available_courses",
          filters: [
            {
              filterName: "Learning Method",
              optionArr: availableLearningMethodArr,
              state: availableLearningMethod,
              setState: setAvailableLearningMethod,
              renderLabelFunction: RenderLearningMethod,
              uniqueId: "available"
            },
            {
              filterName: "Intake",
              optionArr: availableIntakeArr,
              state: availableIntake,
              setState: setAvailableIntake,
              uniqueId: "available",
              isOptionReversed: true
            },
            {
              filterName: "Course Type",
              optionArr: availableIntakeTypeArr,
              state: availableIntakeType,
              setState: setAvailableIntakeType,
              renderLabelFunction: RenderCourseType,
              uniqueId: "available"
            }
          ]
        }}
      />
    </>
  )
}

function ViewCertificateTemplate() {

  return (
    "This Component is not in use But DetailsPage and Courses component is still in use"
  )
}

export default ViewCertificateTemplate
export { Courses, DetailsPage }