import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import { DeleteProgramme, ExportGrade, GetCommonContentReReg, GetProgramsList, GetProgramsListCancelToken, GetProgramsListFilter, GetProgramsListFilterCancelToken } from "../../../services/ProgrammeServices";
import moment from "moment";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { STUDENT_PORTAL_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { RenderLearningMethod, RenderProgrammeType, RenderSchool } from "../../../utils/CommonGroupingItem";
import Tablefilter from "../../common/Tablefilter";
import axios from "axios";
import $ from "jquery";
import { handleTableScroll } from "../../../utils/commonFunction";
import TableTypeFilter from "../../common/TableTypeFilter";

const ListOfPrograms = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [school, setSchool] = useState({ arr: [], checkObj: {} });
  const [type, setType] = useState({ arr: [], checkObj: {} });
  const [typeOptions, setTypeOptions] = useState({});
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
  const [deleterow, setDeleteRow] = useState(false);
  const [programmeList, setProgrammeList] = useState([]);
  const [loading, setloading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [key, setKey] = useState("startDate");
  const [sort, setSort] = useState("DESC");
  const [totalRows, setTotalRows] = useState(0);
  const [filterData, setFilterData] = useState({learning:[], intake:[], type:[], school:[]})
  const [debouncedTerm, setDebouncedTerm] = useState("")
  // const [cancelTokenSources, setCancelTokenSources] = useState([]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])
  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    let response = hasPermission({ scopes: ["capview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateProgrammes"));
    if (storedFilters) {
      setSchool(storedFilters.school);
      setType(storedFilters.type);
      setIntake(storedFilters.intake);
      setLearningMethod(storedFilters.learningMethod);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      school,
      type,
      intake,
      learningMethod
    };
    localStorage.setItem("filterStateProgrammes", JSON.stringify(filterState));
  }, [school, type, intake, learningMethod]);

  useEffect(() => {
    const cancelTokenSources = [];

    const getProgramsList = async () => {
      setloading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const apiData = {
        limit,
        page,
        key,
        sort,
        viaSearch: search,
        exportStatus: "false",
        viaSchool: school.arr,
        viaType: type.arr,
        intake: intake.arr,
        viaLearning: learningMethod.arr
      };
  
      try {
        const res = await GetProgramsListCancelToken(apiData, source.token);
        setProgrammeList(res.data.programs.data);
        setTotalRows(res.data.programs.total);
        if (res.status === 200) {
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };
  
    getProgramsList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [deleterow, limit, page, key, sort, search, school, type, intake, learningMethod]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetProgramsListFilterCancelToken(dropdownSource.token);
        setFilterData({
          ...res.data,
          learning: res.data.intakeTypeFilter,
          type: res.data.ProgrammeFilter,
          intake: res.data.yearsAndIntaksFilter,
          school: res.data.schoolFilter
        });

        
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSchool({ arr: [], checkObj: {} })
    setType({ arr: [], checkObj: {} })
    setIntake({ arr: [], checkObj: {} })
    setLearningMethod({ arr: [], checkObj: {} })
    setSearch("")
    setDebouncedTerm("")
  };

  const handleExportGrade = (ID) => {
    ExportGrade(ID)
      .then((res) => {
        if (res.data.information_fetch_successfully.length) {
          const header = ["Name", "Email", "Student Status", "Subject Name", "Assessment Name", "Assessment Type", "Assessment Mark"];
          let data = res.data.information_fetch_successfully?.map((row) => ({
            ...row,
            Name: row?.first_name ? row.first_name + " " + row.second_name + " " + row.last_name : "-",
            Email: row?.Email ? row.Email : "-",
            "Student Status": row?.Student_Status ? row.Student_Status : "-",
            "Subject Name": row.Subject_Name ? row.Subject_Name : "-",
            "Assessment Name": row.Assessment_Name ? row.Assessment_Name : "-",
            "Assessment Type": row.Assessment_Type ? row.Assessment_Type : "-",
            "Assessment Mark": row.Assessment_Mark.toString() ? row.Assessment_Mark.toString() : "-",
          }));
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${res.data.filename}.xlsx`);

          return false;
        } else {
          Swal.fire("", "There are no record to export", "warning")
        }
      })
      .catch(err => console.log("error :", err))
  }

  const sortingDate = (a, b) => {
    let date1 = a.start.toString().length == 10 ? moment.unix(a.start) : moment(a.start)
    let date2 = b.start.toString().length == 10 ? moment.unix(b.start) : moment(b.start)
    if (date2 > date1) {
      // console.log(`${date2} is greater than ${date1}`)
      return -1;
    } else if (date2 < date1) {
      // console.log(`${date1} is greater than ${date2}`)
      return 1;
    } else {
      // console.log(`Both dates are equal`)
      return 0;
    }
  }

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "course",
      sortable: true,
      sortField: "name",
      // minWidth: "190px",
      cell: (row) => (
        <PermissionsGate scopes={["capview"]} errorProps={{ disabled: true }}>
          <Link title={row.course} className="as-text-blue curser feature-name" to={`/courseAdministration/Programmes/programme/open/${row.id}/details`}>
            <span className="textLimit100">{row.course ? row.course : ""} </span>
          </Link>
        </PermissionsGate>
      ),
    },
    {
      name: "Start", selector: "start", sortable: true,sortField:"startDate",
      // sortFunction: sortingDate,
      cell: (row) => (
        <p>{row.start ?
          <div className="dateTime">
            <p className="right-space">{row.start.toString().length == 10 ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : moment(row.start).format(TABLE_DATE_FORMAT)}</p>
            
          </div>
          : "-"
        }</p>
      )
    },
    {
      name: "End",
      selector: "end",
      sortable: true,
      sortField: "endDate",
      cell: (row) => (
        <>{row.end ?
          <div className="dateTime">
            <p className="right-space">{row.end.toString().length == 10 ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : moment(row.end).format(TABLE_DATE_FORMAT)}</p>
          </div>
          : "-"
        }</>
      )
    },
    {
      name: "Learning Method",
      selector: "type",
      sortable: true,
      sortField: "learningMethod",
      cell: (row) => row.type ? RenderLearningMethod(row.type, "programme").html : "-",
    },
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      sortField: "intake",
      cell: (row) => (row.number && row.year ? row.year + "-" + row.number : "-")
    },
    {
      name: "Type",
      selector: "program_type",
      // maxWidth: "240px",
      sortable: true,
      cell: (row) => {
        const filteredType = filterData.type.find(item => item.value === Number(row.program_type));
        if (filteredType) {
          return (
            <div>
              <span className = {"cat " + filteredType.color}> {filteredType.label !== "-" && <i className="fas fa-circle mr-1"></i>}{filteredType.label}</span>
            </div>
          );
        } else {
          return "-";
        }
      }
    },  
    {
      name: "Students",
      selector: "total_student_quali",
      sortable: true,
      sortField: "student",
      cell: (row) => (row.total_student_quali)
    },
    {
      name: "School",
      selector: "school_type",
      sortable: true,
      sortField: "school",
      cell: (row) => {
        return row.school_type ? (RenderSchool(row.school_type, "programme").html) : "-"
      },
    },

    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <Link className="btn btn-primary rounded-circle" title="Open" to={`/courseAdministration/Programmes/programme/open/${row.id}/details`}>
              <i className="fal fa-folder-open"></i>
            </Link>
            <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <PermissionsGate scopes={["capview"]} errorProps={{ disabled: true }}>
                  <a className="btn btn-primary rounded-circle" title="Programme View" href={`${STUDENT_PORTAL_URL}/courseinfo/${row.id}/aa*S4571`} target="_blank">
                    <i className="fal fa-eye"></i>
                  </a></PermissionsGate>
                <PermissionsGate scopes={["capadd"]} RenderError={() => (<button className="btn btn-primary rounded-circle" title="Duplicate" disabled>
                  <i className="fal fa-copy"></i>
                </button>)}>
                  <Link className="btn btn-primary rounded-circle" title="Duplicate" to={`/courseAdministration/Programmes/programme/duplicate/${row.id}/details`}>
                    <i className="fal fa-copy"></i>
                  </Link></PermissionsGate>
                <PermissionsGate scopes={["capview"]} errorProps={{ disabled: true }}>
                  <button className="btn btn-primary rounded-circle" title="Export Grade" onClick={() => handleExportGrade(row.id)}>
                    <i className="fal fa-file-excel icon"></i>
                  </button>
                </PermissionsGate>
                <PermissionsGate scopes={["capdelete"]} errorProps={{ disabled: true }}>
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Delete"
                    data-toggle="modal"
                    data-target="#notifications"
                    onClick={() => handleDelete(row.id)}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    // console.log(ID);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteProgramme(ID).then((res) => setDeleteRow(!deleterow));

        Swal.fire("Deleted!", "Your Programme has been deleted.", "success");
      }
    });
  };

  const exportData = (fileType, fileName) => {
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    const apiData = {
      limit, page, key, sort,
      viaSearch: search,
      exportStatus: "true",
      viaSchool: school.arr,
      viaType: type.arr,
      intake: intake.arr,
      viaLearning: learningMethod.arr
    }
    GetProgramsList(apiData).then(res => {
      let data = res.data.programs;
      const header = ["Name", "Start", "End", "Learning Method", "Intake", "Type", "Students", "School"];

      data = data.map((row) => ({
        ...row,
        // ID: row?.id,
        Name: row?.course,
        "Start": row?.start ? row.start.toString().length == 10 ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : moment(row.start).format(TABLE_DATE_FORMAT) : "-",
        "End": row?.end ? row.end.toString().length == 10 ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : moment(row.end).format(TABLE_DATE_FORMAT) : "-",
        "Learning Method": row.type ? RenderLearningMethod(row.type).text : "-",
        Intake: row.number ? row.year + "-" + row.number : "-",
        Type: row.program_type ? RenderProgrammeType(row.program_type).text : "-",
        Students: row.total_student_quali !== null && row.total_student_quali.toString() && row.total_student_quali.toString ? row.total_student_quali : "-",
        School: row.school_type ? RenderSchool(row.school_type).text : "-",
      }));
      // console.log(typeof data);
      // console.log(typeof result);

      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
        anchor.click();

        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();

        // Returning false as downloading of file is already taken care of
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [row.Name, row["Start"], row["End"], row["Learning Method"], row.Intake, row.Type, row.Students, row.School];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          columnStyles: {
            0: {
              columnWidth: 30,
            },
            1: {
              columnWidth: 20,
            },
            2: {
              columnWidth: 20,
            },
            3: {
              columnWidth: 20,
            },
            4: {
              columnWidth: 15,
            },
            5: {
              columnWidth: 30,
            },
            6: {
              columnWidth: 10,
            },
            7: {
              columnWidth: 30,
            },
          },
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            // valign: "center",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();

        return false;
      }
    }).catch(err => console.error("error:", err))
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setKey(column.sortField);
    setSort(sortDirection === "asc" ? "ASC" : "DESC");
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap filter-scrolable">
      <div className="custom-table-div filter-search-icon card card-table-custom appeals-tables">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main"  onChange={e => setDebouncedTerm(e.target.value)} value={debouncedTerm} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Learning Method"
                      optionArr={filterData.learning}
                      state={learningMethod}
                      setState={setLearningMethod}
                      renderLabelFunction={RenderLearningMethod}
                    />
                    <Tablefilter
                      filterName={"Intake"}
                      optionArr={filterData.intake}
                      state={intake}
                      setState={setIntake}
                      isOptionReversed={true}
                    />
                    {/* <Tablefilter
                      filterName="Type"
                      optionArr={filterData.type}
                      state={type}
                      setState={setType}
                      renderLabelFunction={RenderProgrammeType}
                    /> */}
                    <TableTypeFilter
                      filterName={"Type"}
                      optionArr={filterData.type}
                      state={type}
                      setState={setType}
                    />
                    <Tablefilter
                      filterName="School"
                      optionArr={filterData.school}
                      state={school}
                      setState={setSchool}
                      renderLabelFunction={RenderSchool}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Programmes");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Programmes");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Programmes");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate RenderError={() => (
                  <button className="btn btn-primary" title="Create New" disabled>
                    <i className="fal fa-plus"></i>Create New
                  </button>
                )} scopes={["capadd"]}>
                  <Link to={`/courseAdministration/Programmes/programme/add`}>
                    <button className="btn btn-primary" title="Create New">
                      <i className="fal fa-plus"></i>Create New
                    </button>
                  </Link>
                </PermissionsGate>
              </div>
            </div>

          </div>

        </div>

        <DataTable
          data={programmeList}
          defaultSortField="start"
          defaultSortAsc={false}
          columns={columns}
          pagination={true}
          noDataComponent={Str.noRecord}
          onSort={handleSort}
          paginationServer
          sortServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
    </div>
  );
};

export default ListOfPrograms;
