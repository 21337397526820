import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import { getLibraryBorrowersList, GetLibraryBorrowersListFilters } from "../../../services/StudentService";
import moment from "moment";
import { RenderCampusDropdown, RenderResourceCampus } from "../../../utils/CommonGroupingItem";
import Tablefilter from "../../common/Tablefilter";
import { ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import { InitialRender } from "../../common/Helper";
import Hover from "../../common/Hover";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const LibraryList = () => {

    const [search, setSearch] = useState("");
    const [ListData, setListData] = useState([]);
    const [campus, setCampus] = useState({ arr: [], checkObj: {} });
    const [searchStudentStatusCheck, setStudentSearchStatusCheck] = useState({});
    const [isLoaded, setIsLoaded] = useState(true);
    const [sortkey, setSortKey] = useState("student");
    const [sortOrder, setSortOrder] = useState("ASC");
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [filterData, setFilterData] = useState([])
    const [debouncedTerm, setDebouncedTerm] = useState("")
  
    useEffect(() => {
      const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
      return () => {
        clearTimeout(timer);
      }
    }, [debouncedTerm])

    useEffect(() => {
        handleTableScroll()
      }, [isLoaded])

    useEffect(() => {
        const filterSource = axios.CancelToken.source();
        const getFilters = async () =>{
            try {
                const response = await GetLibraryBorrowersListFilters(filterSource.token);
                if(response.status==200){
                    setFilterData(response.data.result);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        }
        
        getFilters();

        return () => {
            filterSource.cancel('Component unmounted');      
        }
    }, [])
    

    useEffect(() => {
        const cancelTokenSources = [];

        const getLibraryListData = async () => {
            setIsLoaded(true);
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });
        
            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);

            const data = {
                page: page,
                limit: perPage,
                viaSearch: search ? search : "",
                exportStatus: false,
                key: sortkey,
                sort: sortOrder,
                viaCampus: campus.arr.length > 0 ? campus.arr : "",
            };

            try {
                const res = await getLibraryBorrowersList(data, source.token);
                if(res.status==200){
                    setListData(res.data.result.data);
                    setTotalRows(res?.data?.result?.total)
                    setIsLoaded(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    setIsLoaded(false);
                }
            }

        }
        getLibraryListData()
        
        return () => {
            cancelTokenSources.forEach(source => {
              source.cancel('Component unmounted');
            });
        };
          
    }, [search, page, perPage, sortkey, sortOrder, campus, searchStudentStatusCheck]);

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setSearch(value);
    };


    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setSortKey(column.selector);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };

    const resetFilter = () => {
        setSearch("");
        setDebouncedTerm("")
        setCampus({ arr: [], checkObj: {} })
        setStudentSearchStatusCheck({})
    };

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "student",
            sortable: true,
            cell: (row) => (
                <div className="assigned-title-block-new" title={row.student_name}>
                    <div className="assigned-title-itm">
                        <span
                        className={
                            "assigned-title-blk  name-icon cat-dark-red " +
                            ColorRender(row.role_name)
                        }
                        >
                        {row.picture_me ? (
                            <img
                            src={`${IMAGE_URL}/${row.picture_me.replaceAll(
                                "/home/myaie/public_html/",
                                ""
                            ).replace("public/","")}`}
                            alt="AIE"
                            />
                        ) : (
                            InitialRender(row.student_name.split(" "))
                        )}
                        <span className={`profile-box-2-status ${row.activity_status ? row.activity_status.toLowerCase()=="online" ? "Online" :row.activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                            <i className="fas fa-circle"></i>
                        </span>
                        <Hover
                            firstName={row.student_name.split(" ")[0]}
                            lastName={row.student_name.split(" ")[1]}
                            photo={row.picture_me}
                            email={row.email}
                            mobile={row.mobile}
                            status={row.studentStatus}
                            activity_status={row.activity_status}
                            right={true}
                            showNumber={true} 
                            number={row.student_crm_id}
                        />
                        </span>
                        <PermissionsGate scopes={["umedit"]} RenderError={() => <p>{row.title}</p>} >
                            <Link to={`/support/student-hub-library/library-borrowers/detail/${row.issu_to}`} className="as-text-blue curser  textLimit100" >{row.student_name}</Link>
                        </PermissionsGate>
                    </div>
                </div>
            ),
        },
        {
            name: "Student No",
            selector: "student_num",
            sortable: true,
            cell: (row) => (
                <span title={row.student_crm_id}>
                    {row.student_crm_id}
                </span>
            )
        },
        {
            name: "Title",
            selector: "title",
            sortable: true,
            cell: (row) => (
                <span className="textLimit100" title={row.title}>
                    <Link className="as-text-blue curser " to={row.type.toString() === "Book" ? `/support/student-hub-library/resource-management/edit/${row.resource_id}/dashboard/physical` : `/support/student-hub-library/resource-management/edit/${row.resource_id}/details/digital`} title={row.title}>
                    {row.title}
                    </Link>
                </span>
            )
        },
        {
            name: "Barcode",
            selector: "barcode",
            // sortable: true,
            cell: (row) => (
                <span className="textLimit100" title={row.barcode}>
                    {row.barcode ? row.barcode : "NA"}
                </span>
            )
        },
        {
            name: "Issue Date",
            selector: "issued_date",
            sortable: true,
            cell: (row) => (
                <div className="dateTime textLimit100" title={moment.unix(Number(row?.issued_date)).format(TABLE_DATE_FORMAT)}>
                    {Number(row?.issued_date) ? moment.unix(Number(row?.issued_date)).format(TABLE_DATE_FORMAT) : "NA"}
                </div>
            )
        },
        {
            name: "Due Date",
            selector: "due_date",
            sortable: true,
            cell: (row) => (
                row.status === "reserved" ?
                    <div className="dateTime textLimit100" title={moment.unix(Number(row?.reserved_due_date)).format(TABLE_DATE_FORMAT)}>
                        {Number(row?.reserved_due_date) ? moment.unix(Number(row?.reserved_due_date)).format(TABLE_DATE_FORMAT) : "NA"}
                    </div>
                    : <div className="dateTime textLimit100" title={moment.unix(Number(row?.due_date)).format(TABLE_DATE_FORMAT)}>
                        {Number(row?.due_date) ? moment.unix(Number(row?.due_date)).format(TABLE_DATE_FORMAT) : "NA"}
                    </div>

            )
        },


        {
            name: "Campus",
            selector: "campus",
            sortable: true,
            cell: (row) => (row.campus ?
                <div className="textLimit100 item-text-green" title={row.campus}>
                    {(row.campus && row.campus!="" && filterData.length) ? RenderCampusDropdown(filterData.filter(item => item.value == row.campus)[0]).html : "NA"}
                </div>
                : "NA"),
        },
        {
            name: "Days Overdue",
            selector: "due_days",
            sortable: true,
            cell: (row) => row.due_days
        },
        {
            name: "Actions",
            selector: "",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons">
                        <PermissionsGate scopes={["umedit"]}>
                            <Link to={`/support/student-hub-library/library-borrowers/detail/${row.issu_to}`}
                                className="btn btn-primary rounded-circle"
                                title="Open" >
                                <i className="fal fa-folder-open"></i>
                            </Link>
                        </PermissionsGate>
                    </div>
                </div>
            ),
        },
    ]);

    const exportData = async (fileType, fileName) => {
        const param = {
            page: page,
            limit: perPage,
            viaSearch: search ? search : "",
            exportStatus: 'true',
            key: sortkey,
            sort: sortOrder,
            viaCampus: campus.arr.length > 0 ? campus.arr : "",
        }
        const resp = await getLibraryBorrowersList(param);
        let data = resp.data.result;
        const header = ["Name", "Student No", "Title", "Barcode", "Issue Date", "Due Date", "Campus", "Days Overdue"];
        data = data.map((row) => ({
            Name: row?.student_name,
            "Student No": row.student_crm_id ? row.student_crm_id : "N/A",
            Title: row.title ? row.title : "N/A",
            Barcode: row.barcode ? row.barcode : "N/A",
            "Issue Date": row?.issued_date ? moment.unix(Number(row?.issued_date)).format(TABLE_DATE_FORMAT) : "N/A",
            "Due Date": row?.due_date ? moment.unix(Number(row?.due_date)).format(TABLE_DATE_FORMAT) : "N/A",
            Campus: row?.campus ? filterData.filter(val => val.value == row.campus)[0].label : "N/A",
            ["Days Overdue"]: row?.due_days ? row?.due_days : "N/A",
        }));

        if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });

            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = [
                "text/csv",
                anchor.download,
                anchor.href,
            ].join(":");
            anchor.click();

            setTimeout(() => {
                URL.revokeObjectURL(blobURL);
            }, 1000);
            Swal.close();
        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.map((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);
            Swal.close();
            return false;
        }
        if (fileType === "pdf") {
            const compatibleData = data.map((row) => {
                return [
                    row["Name"],
                    row["Student No"],
                    row["Title"],
                    row["Barcode"],
                    row["Issue Date"],
                    row["Due Date"],
                    row["Campus"],
                    row["Days Overdue"],
                ];
            });
            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
            });
            doc.save(`${fileName}.pdf`);
            return false;
        }

    };

    return (
        <>
            <div className="my-tickets-info-list Tickets-main-wrap">
                <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
                    <div className="search-filter-div">
                        <div className="search-filter-div-left">
                            <div className="system-administration-table table-responsive">
                                <div className="table-responsive-div">
                                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                        <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                            <div id="assessment-table-main_filter" className="dataTables_filter">
                                                <label>
                                                    <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={e => setDebouncedTerm(e.target.value)} value={debouncedTerm} />
                                                </label>
                                                <div className="filter-eff filter-data-btn">
                                                    <button className="filter-buttons">
                                                        <i className="fal fa-filter"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-button-group">

                                    <Tablefilter
                                        filterName="Campus"
                                        optionArr={filterData}
                                        state={campus}
                                        setState={setCampus}
                                        uniqueId="campus"
                                        renderLabelFunction={RenderCampusDropdown}
                                    />
                                    <div className="button-reset dropdown-comman" title="Reset">
                                        <button className="btn btn-primary" onClick={resetFilter} >
                                            <i className="fal fa-redo"></i>Reset
                                        </button>
                                    </div>

                                    <div className="files-export-group">
                                        <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Library Borrowers"); }} title="Export spreadsheet">
                                            <i className="fal fa-file-excel icon"></i>
                                        </button>
                                        <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Library Borrowers"); }} title="Export CSV">
                                            <i className="fal fa-file-csv icon"></i>
                                        </button>
                                        <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Library Borrowers"); }} title="Export PDF">
                                            <i className="fal fa-file-pdf icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {isLoaded && <SkeletonTicketList />} */}

                    <DataTable
                        data={ListData}
                        defaultSortField="student"
                        defaultSortAsc={true}
                        columns={columns}
                        pagination={true}
                        noDataComponent={Str.noRecord}
                        paginationDefaultPage={page}
                        progressPending={isLoaded}
                        progressComponent={<SkeletonTicketList />}
                        onSort={handleSort}
                        paginationServer
                        sortServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        highlightOnHover={false}
                        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                    />
                </div>
            </div>

        </>
    );
};

export default LibraryList;
