import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { GetModuleDetailCancelToken } from "../../services/ProgramService";
import { GlobalIconNames } from "../../utils/GlobalIconNames";
import Header from "../common/Header";
import SideBar from "../common/SideBar";
import { TrimText } from "../common/TrimText";
import AssignedTable from "./modules/AssignedTable";
import AuditTrail from "./modules/AuditTrail";
import ModuleDetail from "./modules/ModuleDetail";
import Modules from "./modules/Modules";
import Outcomes from "./modules/outcomes";
import Resources from "./modules/Resources";
import Topics from "./modules/Topics";
import axios from "axios";
import LinkedCourses from "./modules/LinkedCourses";
import Swal from "sweetalert2";

function Module() {
  const { tab, type, id } = useParams();
  const history = useHistory();
  const [moduleName, setModuleName] = useState("")
  const [detail, setDetail] = useState();
  const [description, setDescription] = useState("")
  const [prescribedTextBooks, setPrescribedTextBooks] = useState("");
  const [isEditorStateReady, setIsEditorStateReady] = useState(false);
  const [data, setData] = useState([])
  useEffect(() => {
    if (type !== "add" && type !== "table") {
      const cancelTokenSources = [];

    const getModuleDetails = async () => {
      setIsEditorStateReady(false)
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetModuleDetailCancelToken({ MId: id }, source.token);
        let apiValue = res.data?.data[0];
        if (res.status === 200) {
          res.data.data[0].ProgrammeId && setData(res.data.data);
          setModuleName(apiValue.Portal)
          setDetail({ ...apiValue, pre_requisites: apiValue.pre_requisites ? JSON.parse(apiValue.pre_requisites) : [], co_requisites: apiValue.co_requisites ? JSON.parse(apiValue.co_requisites) : [] });
          setDescription(apiValue.Description);
          setPrescribedTextBooks(apiValue.prescribed_text);
          setIsEditorStateReady(true)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsEditorStateReady(true)
          
          if(error?.response?.data?.message == "No data found"){
            Swal.fire({
              icon: "Info",
              title: "Info",
              text: "No record found with this id",
            })
            history.push(`/program-management-center/modules/module/table`)
          }
        }
      }
    };
  
    getModuleDetails();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
    }
  }, [type, tab])

  return (
    <>
      <Header></Header>
      <div
        className="content-wrapper d-flex flex-wrap new-standard-box"
        style={{ fontWeight: "0px" }}
      >
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        {tab == "module" && type == "add" ? "Add" : type !== "table" ? "Module:" : "Modules"} <span title={moduleName}>{type == "add" ? "Module" : type !== "table" ? TrimText(moduleName,60) : ""}</span>
                      </h4>
                    </div>
                      <div className="text-md-right action2-blk">
                        <div className="ticket-view-btn d-flex align-items-center">
                          <div className="add-new-ticket-bx">
                            <button
                              onClick={() => history.goBack()}
                              title="Back"
                              className="btn btn-white-bordered"
                            >
                              <i className="fal fa-angle-left"></i>Back
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  {type !== "add" && type !== "duplicate" && <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          type == "table"
                            ? "/program-management-center/modules/module/table"
                            : `/program-management-center/modules/module/open/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "module" && (type == "table" || type == "open") ? "active" : ""
                          }`}
                        id="pills-tab1"
                        data-toggle="pill"
                        to="/program-management-center/modules/module/table"
                        href="#pills-tab11"
                        role="tab"
                        aria-controls="pills-tab11"
                        aria-selected="true"
                      >
                        {(tab == "module" || tab == "auditTrail") && type == "table" ? GlobalIconNames("Modules").html : GlobalIconNames("details").html}
                      </Link>
                    </li>
                    {type !== "table" && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/modules/module/topics/${id}`)
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "module" && type == "topics" ? "active" : ""
                          }`}
                        id="pills-tab2"
                        data-toggle="pill"
                        to={`/program-management-center/modules/module/topics/${id}`}
                        href="#pills-tab22"
                        role="tab"
                        aria-controls="pills-tab22"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Topics", "fa-books", "Topics").html}
                      </Link>
                    </li>}
                    {type !== "table" && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/modules/module/outcomes/${id}`)
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "module" && type == "outcomes" ? "active" : ""
                          }`}
                        id="pills-tab2"
                        data-toggle="pill"
                        to={`/program-management-center/modules/module/outcomes/${id}`}
                        href="#pills-tab22"
                        role="tab"
                        aria-controls="pills-tab22"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Outcomes", "fa-chart-pie-alt", "Outcomes").html}
                      </Link>
                    </li>}
                    {type !== "table" && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/modules/module/assignedProgramme/${id}`)
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "module" && type == "assignedProgramme" ? "active" : ""
                          }`}
                        id="pills-tab2"
                        data-toggle="pill"
                        to={`/program-management-center/modules/module/assignedProgramme/${id}`}
                        href="#pills-tab22"
                        role="tab"
                        aria-controls="pills-tab22"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Assigned To", "fa-laptop", "Assigned To").html}
                      </Link>
                    </li>}
                    {type !== "table" && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/modules/module/resources/${id}`)
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "module" && type == "resources" ? "active" : ""
                          }`}
                        id="pills-tab2"
                        data-toggle="pill"
                        to={`/program-management-center/modules/module/resources/${id}`}
                        href="#pills-tab22"
                        role="tab"
                        aria-controls="pills-tab22"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Resources", "fa-user", "Resources").html}
                      </Link>
                    </li>}
                    {type !== "table" && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/modules/module/linked_courses/${id}`)
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "module" && type == "linked_courses" ? "active" : ""
                          }`}
                        id="pills-tab2"
                        data-toggle="pill"
                        to={`/program-management-center/modules/module/linked_courses/${id}`}
                        href="#pills-tab22"
                        role="tab"
                        aria-controls="pills-tab22"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Linked Courses", "fa-link", "Linked Courses").html}
                      </Link>
                    </li>}
                    <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          type == "table"
                            ? "/program-management-center/modules/auditTrail/table"
                            : `/program-management-center/modules/auditTrail/open/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "auditTrail" ? "active" : ""
                          }`}
                        id="pills-tab2"
                        data-toggle="pill"
                        to="/program-management-center/modules/module/auditTrail"
                        href="#pills-tab12"
                        role="tab"
                        aria-controls="pills-tab12"
                        aria-selected="true"
                      >
                        {GlobalIconNames("audittrail").html}
                      </Link>
                    </li>
                  </ul>}
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab11"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      {tab == "module" && type == "table" && <Modules />}

                      {tab == "module" &&
                        (type == "open" ||
                          type == "add" ||
                          type == "duplicate") && <ModuleDetail detail={type == "add" ? null : detail} description={description} setDescription={setDescription} prescribedTextBooks={prescribedTextBooks} setPrescribedTextBooks={setPrescribedTextBooks} isEditorStateReady={isEditorStateReady} />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab14"
                      role="tabpanel"
                      aria-labelledby="pills-tab4"
                    >
                      {tab == "module" && type == "assignedProgramme" && <AssignedTable data={data} loading={!isEditorStateReady} />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab14"
                      role="tabpanel"
                      aria-labelledby="pills-tab4"
                    >
                      {tab == "module" && type == "resources" && <Resources />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab14"
                      role="tabpanel"
                      aria-labelledby="pills-tab4"
                    >
                      {tab == "module" && type == "linked_courses" && <LinkedCourses detail={detail}/>}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab14"
                      role="tabpanel"
                      aria-labelledby="pills-tab4"
                    >
                      {tab == "module" && type == "outcomes" && <Outcomes />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab13"
                      role="tabpanel"
                      aria-labelledby="pills-tab3"
                    >
                      {tab == "module" && type == "topics" && <Topics />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab12"
                      role="tabpanel"
                      aria-labelledby="pills-tab2"
                    >
                      {tab == "auditTrail" && <AuditTrail />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Module;
