import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GlobalIconNames } from "../../../utils/GlobalIconNames";
import AuditTrails from "./AuditTrails";
import ListofRegisteredStudents from "./ListofRegisteredStudents";
import ListofNewRegistrations from "./ListofNewRegistrations";
import ListofReRegistrations from "./ListofReRegistrations";
import ListofSLPRegistrations from "./ListofSLPRegistrations";

const RegistrationPage = (props) => {

    const { tab, page } = useParams();
    const history = useHistory();

    useEffect(() => {
        // if(tab === "audittrails") {
        //     props.changeName("Audit Trail");
        // } else {
        //     props.changeName("Registrations");
        // }
    }, [])

    return (
        <>
            <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
            <li className="nav-item" onClick={() => { history.push(`/studentAdministration/registration/studentlist`); props.changeName("All Registrations"); }} >
                    <a className={"nav-link" + (page === "studentlist" && !tab ? " active" : "")} id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true" >
                        {GlobalIconNames("registration").html}
                    </a>
                </li>
                <li className="nav-item" onClick={() => { history.push(`/studentAdministration/registration/studentlist/registrationsonly`); props.changeName("Registrations"); }} >
                    <a className={"nav-link" + (page === "studentlist" && tab == 'registrationsonly' ? " active" : "")} id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true" >
                        {GlobalIconNames("registrationsonly").html}
                    </a>
                </li>
                <li className="nav-item" onClick={() => { history.push(`/studentAdministration/registration/studentlist/reregistrationsonly`); props.changeName("Re-Registrations"); }} >
                    <a className={"nav-link" + (page === "studentlist" && tab == 'reregistrationsonly' ? " active" : "")} id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true" >
                        {GlobalIconNames("reregistrationsonly").html}
                    </a>
                </li>
                <li className="nav-item" onClick={() => { history.push(`/studentAdministration/registration/studentlist/slpregistrationsonly`); props.changeName("SLP Registrations"); }} >
                    <a className={"nav-link" + (page === "studentlist" && tab == 'slpregistrationsonly' ? " active" : "")} id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true" >
                        {GlobalIconNames("slpregistrationsonly").html}
                    </a>
                </li>
                <li className="nav-item" onClick={() => { history.push(`/studentAdministration/registration/studentlist/audittrails`); props.changeName("Audit Trail");}} >
                    <a href="/studentAdministration/registration/studentlist/audittrails" className={"nav-link" + (page === "studentlist" && tab === "audittrails" ? " active" : "")} id="pills-tab2" data-toggle="pill"  role="tab" aria-controls="pills-tab12" aria-selected="true" >
                    {GlobalIconNames("auditTrail").html}
                    </a>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                {page === "studentlist" && !tab && <div className={"tab-pane fade" + (page === "studentlist" && tab !='registrationsonly' && tab != "slpregistrationsonly"  && tab != 'reregistrationsonly' ? " active show" : "")} id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                    <ListofRegisteredStudents />
                </div>}
                {page === "studentlist" && tab === "registrationsonly" && <div className={"tab-pane fade" + (page === "studentlist" && tab == "registrationsonly" ? " active show" : "")} id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                    <ListofNewRegistrations />
                </div>}
                {page === "studentlist" && tab === "reregistrationsonly" && <div className={"tab-pane fade" + (page === "studentlist" && tab == "reregistrationsonly" ? " active show" : "")} id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                    <ListofReRegistrations />
                </div>}
                {page === "studentlist" && tab === "slpregistrationsonly" && <div className={"tab-pane fade" + (page === "studentlist" && tab == "slpregistrationsonly" ? " active show" : "")} id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                    <ListofSLPRegistrations />
                </div>}
               {page === "studentlist" && tab === "audittrails" && <div className={"tab-pane fade" + (page === "studentlist" && tab === "audittrails" ? " active show" : "")} id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
                    <AuditTrails />
                </div>}

            </div>
        </>
    )

}

export default RegistrationPage;