import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router";
import Swal from "sweetalert2";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { InvoiceStatus } from "../../../DataSelect";
import {
  ExamRegistrationInvoice,
  Exams_invoices_edit,
  PrintInvoiceData,
  SendInvoiceNotification,
} from "../../../../../services/ExternalExamServices";
import moment from "moment";
import { getDate } from "date-fns";
import { FORM_DATE_FORMAT } from "../../../../../utils/Constants";
import InvoicePdf from "./InvoicePdf";
import {
  pdf,
} from "@react-pdf/renderer";

import { saveAs } from "file-saver";

const RegistrationInvoice = () => {
  const { id, subId } = useParams();
  const [invoiceTabData, setInvoiceTabData] = useState([]);
  const [iconLoading, setIconLoading] = useState(true);
  const [notificationLoading, setNotificationLoading] = useState(false)
  const [gearLoading, setGearLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setIconLoading(true);
    getExamVoice();
  }, []);

  const getExamVoice=()=>{
    ExamRegistrationInvoice(subId,id)
    .then((res) => {
      setInvoiceTabData(res.data.data);
      // getInvoiceCreateDate(res.data.data.invoice_create_date);
      // getInvoiceDueDate(res.data.data.invoice_due_date);
      setIconLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setIconLoading(false);
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: invoiceTabData.invoice_status
        ? invoiceTabData.invoice_status
        : "",
      to: invoiceTabData.name ? invoiceTabData.name : "",
      invoice_number: invoiceTabData.invoice_number
        ? invoiceTabData.invoice_number
        : "",
      invoice_date: invoiceTabData.invoice_create_date && invoiceTabData.invoice_create_date!=undefined  ? new Date(moment.unix(invoiceTabData.invoice_create_date)) :"",
      invoice_dueDate: invoiceTabData.invoice_due_date && invoiceTabData.invoice_due_date!=undefined  ? new Date(moment.unix(invoiceTabData.invoice_due_date)) :"",
      sub_total: invoiceTabData.sub_total ? invoiceTabData.sub_total : "",
      total: invoiceTabData.total ? invoiceTabData.total : "",
      vat: invoiceTabData.vat ? invoiceTabData.vat : "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Status is required "),
      to: Yup.string().required("To is required "),
      // invoice_number: Yup.string().required("Invoice Number is required "),
      // invoice_date: Yup.string().required("Invoice Date is required "),
      // invoice_dueDate: Yup.string().required("Invoice Due Date is required "),
    }),
    onSubmit: (values, { resetForm }) => {
      // console.log("first", values);
      setGearLoading(true)
      let formData = new FormData();
      formData.set("id", id);
      formData.set("invoice_status", values.status);
      formData.set("to_invoice", values.to);
      formData.set("type", "save");

      Exams_invoices_edit(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Added successfully",
          });
          resetForm();
          getExamVoice();
          setGearLoading(false)
          // history.push("/systemAdministration/userManagement/table");
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.data}`,
          });
          setGearLoading(false)
        });
    },
  });

  const handleNotification = () => {
    setNotificationLoading(true)
    let formData = new FormData();
    formData.set("userid", id);
    formData.set("to_invoice", formik.values.to);
    formData.set("type", "email");
    SendInvoiceNotification(formData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Notification send successfully",
        });
        setNotificationLoading(false)
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
        setNotificationLoading(false)
      });
  };

  const handlePrintInvoice = () => {
    setDownloading(true);
    PrintInvoiceData(id,subId,"D").then(async(res)=>{
      const respData = res.data;
      const pdfData = {
        invoice_number:respData?.invoice_no,
        invoice_due_date:moment(respData?.invoice_due).format("DD-MM-YYYY"),
        invoice_date:moment(respData?.invoice_date).format("DD-MM-YYYY"),
        sub_total:respData?.subTotal,
        total:respData?.total,
        vat:respData?.vat,
        subjects:respData?.subjects,
        exam_subject_cost:respData?.exam_subject_cost
      };
      setIconLoading(false);
      const blob = await pdf(
        <InvoicePdf data={pdfData} />
      ).toBlob();
      saveAs(blob, "invoice.pdf")
    }).catch(error=>{
      console.log(error);
      setIconLoading(false);
    }).finally(()=>{
      setDownloading(false);
    });
  }

  return (
    <div className="card card-profile-info-card mb-30 course-details-tab-sec">
      <div className="card-body">
        <form onSubmit={formik.handleSubmit}>
          <div className="course-info-sec">
            <div className="edit-icon new-card-header">
              <div className="card-header">
                {iconLoading && <i className="fas fa-cog fa-spin ml-1"></i>}
              </div>
            </div>
            <div className="card-body-inr card-body-info">
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="to">To*</label>
                  <div className="form-icon-group mb-4">
                    <input
                      type="text"
                      className={
                        "form-control" +
                        (formik.errors.to && formik.touched.to
                          ? " is-invalid"
                          : "")
                      }
                      name="to"
                      placeholder="To"
                      title="To"
                      value={formik.values.to}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.to && formik.touched.to ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="to">Invoice</label>
                  <div className="form-icon-group mb-4">
                    <input
                      disabled
                      type="text"
                      className={
                        "form-control" +
                        (formik.errors.invoice_number &&
                        formik.touched.invoice_number
                          ? " is-invalid"
                          : "")
                      }
                      name="invoice_number"
                      placeholder="Invoice Number"
                      title="Invoice Number"
                      value={formik.values.invoice_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.invoice_number &&
                    formik.touched.invoice_number ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="status">Status*</label>
                  <div className="form-icon-group mb-4" title="Status">
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.status && formik.touched.status
                          ? " is-invalid"
                          : "")
                      }
                      name="status"
                      value={InvoiceStatus.filter((value) => {
                        return value.value == formik.values.status;
                      })}
                      onChange={(value) =>
                        formik.setFieldValue("status", value.value)
                      }
                      onBlur={formik.handleBlur}
                      options={InvoiceStatus}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.status ? formik.values.status : "Status  "
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <label>Invoice Date</label>
                  <div className="form-group-blk mb-3" title="Invoice Date">
                    <DatePicker
                      disabled
                      className={
                        "form-control cursor-pointer" +
                        (formik.errors.invoice_date &&
                        formik.touched.invoice_date
                          ? " is-invalid"
                          : "")
                      }
                      selected={formik.values.invoice_date}
                      dateFormat={FORM_DATE_FORMAT}
                      onChange={(date) =>
                        formik.setFieldValue("invoice_date", date)
                      }
                      title="Invoice Date"
                      placeholderText="Select invoice date"
                      onChangeRaw={(e) => {
                        e.preventDefault();
                      }}
                      dateFormatCalendar="MMMM"
                      showYearDropdown
                      scrollableYearDropdown
                      // onChange={(e) => onHandleStartDate(e)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <label>Invoice Due Date</label>
                  <div className="form-group-blk mb-3" title="Invoice Due Date">
                    <DatePicker
                    disabled
                      className={
                        "form-control cursor-pointer" +
                        (formik.errors.invoice_dueDate &&
                        formik.touched.invoice_dueDate
                          ? " is-invalid"
                          : "")
                      }
                      selected={formik.values.invoice_dueDate}
                      dateFormat={FORM_DATE_FORMAT}
                      onChange={(date) =>
                        formik.setFieldValue("invoice_dueDate", date)
                      }
                      title="Invoice Due Date"
                      placeholderText="Select invoice due date"
                      onChangeRaw={(e) => {
                        e.preventDefault();
                      }}
                      dateFormatCalendar="MMMM"
                      showYearDropdown
                      scrollableYearDropdown
                      // onChange={(e) => onHandleStartDate(e)}
                    />
                  </div>
                </div>
                <div className="col-md-2 col-lg-3 mt-4">
                  <button
                    className="btn btn-save btn-success mr-2"
                    type="submit"
                    title="Send Notification"
                    onClick={() => handleNotification()}
                    disabled={true}
                  >
                    {notificationLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                    
                    Send Notification
                  </button>
                </div>
                <div className="col-md-2 col-lg-3 mt-4">
                  <button
                    className="btn btn-save btn-success"
                    type="button"
                    title="Print Invoice"
                    onClick={handlePrintInvoice}
                  >
                    {downloading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                    Print Invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="course-info-sec">
            <div className="edit-icon new-card-header">
              <div className="card-header">
                Line Items
                {iconLoading && <i className="fas fa-cog fa-spin ml-1"></i>}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="to">Sub total</label>
                <div className="form-icon-group mb-4">
                  <input
                    disabled
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.sub_total && formik.touched.sub_total
                        ? " is-invalid"
                        : "")
                    }
                    name="sub_total"
                    placeholder="Enter sub total"
                    title="Sub Total"
                    value={formik.values.sub_total}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <label htmlFor="to">Total</label>
                <div className="form-icon-group mb-4">
                  <input
                    disabled
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.total && formik.touched.total
                        ? " is-invalid"
                        : "")
                    }
                    name="total"
                    placeholder="Enter total"
                    title="Total"
                    value={formik.values.total}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <label htmlFor="to">VAT</label>
                <div className="form-icon-group mb-4">
                  <input
                    disabled
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.vat && formik.touched.vat
                        ? " is-invalid"
                        : "")
                    }
                    name="vat"
                    placeholder="Enter VAT  "
                    title="VAT"
                    value={formik.values.vat}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group form-group-save-cancel">
            <button
              className="btn btn-save btn-success"
              type="submit"
              title="Save"
            >
              {gearLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
              Save
            </button>
            <button
              className="btn btn-close btn-danger"
              type="button"
              title="Cancel"
              onClick={() => formik.resetForm()}
            >
              <i className="fal fa-times"></i>
              Cancel
            </button>
            {formik.touched.to && formik.errors.to ? (
              <div className="invalid-feedback d-block">{formik.errors.to}</div>
            ) : null}
            {formik.touched.invoice_number && formik.errors.invoice_number ? (
              <div className="invalid-feedback d-block">
                {formik.errors.invoice_number}
              </div>
            ) : null}
            {formik.touched.status && formik.errors.status ? (
              <div className="invalid-feedback d-block">
                {formik.errors.status}
              </div>
            ) : null}
            {formik.touched.invoice_date && formik.errors.invoice_date ? (
              <div className="invalid-feedback d-block">
                {formik.errors.invoice_date}
              </div>
            ) : null}
            {formik.touched.invoice_dueDate && formik.errors.invoice_dueDate ? (
              <div className="invalid-feedback d-block">
                {formik.errors.invoice_dueDate}
              </div>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationInvoice;
