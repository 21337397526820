import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { checkCheckboxRejectDetails, netSuitCreateLead } from "../../../../services/RegistrationService";
import { Title } from "../../../systemadministration/DataReactSelect";
import RenderPreviousValues from "./RenderPreviousValues";

function StudentDetails(props) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const studentId = params.get("studentId");
  let dropdowns = useSelector((state) => state.registerDropdownValues)
  const [studentData, setStudentData] = useState();
  const [disablity, setDisablity] = useState([]);
  const [dropdownValues, setDropdownValues] = useState(useSelector((state) => state.registerDropdownValues));
  const history = useHistory()

  useEffect(() => {
    setStudentData(props.studentData);
    setDisablity([{value:"none", label:"No disibility specified"}, ...dropdownValues.disability])
  }, [props]);

  const formik = useFormikContext();
  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Student Details</div>
      </div>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Title">{formik.values.course_type === "shortcourse" ? "Title" : "Title *"}</label>
            <div className="form-icon-group mb-4" title="Title">
              <Select
                className={"form-control custom-select-box" + (formik.errors.Title && formik.touched.Title ? " is-invalid" : "")}
                name="Title"
                value={Title?.filter(val=>val.value === formik.values.Title)}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue("Title", value.value)
                  } else {
                    formik.setFieldValue("Title", "")
                  }
                }}
                onBlur={formik.handleBlur}
                options={Title}
                maxMenuHeight={175}
                placeholder={formik.values.Title ? formik.values.Title : "Select Title  "}
                isClearable
              />
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.title} previousValue={studentData?.RegStudentsPreviousDetails?.title} type="dropdown" typeSupportingProps={{filterArray : Title}} />
          </div>
          
          <div className="col-md-6 col-lg-4">
            <label htmlFor="FirstName">First Name *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="FirstName"
                className={"form-control" + (formik.errors.FirstName && formik.touched.FirstName ? " is-invalid" : "")}
                name="FirstName"
                placeholder="Enter First Name"
                value={formik.values.FirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="First Name"
              />
              {formik.errors.FirstName && formik.touched.FirstName ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.first_name} previousValue={studentData?.RegStudentsPreviousDetails?.first_name} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="LastName">Last Name *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="LastName"
                className={"form-control" + (formik.errors.LastName && formik.touched.LastName ? " is-invalid" : "")}
                name="LastName"
                placeholder="Enter Last Name"
                value={formik.values.LastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Last Name"
              />
              {formik.errors.LastName && formik.touched.LastName ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.last_name} previousValue={studentData?.RegStudentsPreviousDetails?.last_name} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Initials">Initials</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="Initials"
                className={"form-control" + (formik.errors.Initials && formik.touched.Initials ? " is-invalid" : "")}
                name="Initials"
                placeholder="Enter Initials"
                value={formik.values.Initials}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Initials"
              />
              {formik.errors.Initials && formik.touched.Initials ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.initials} previousValue={studentData?.RegStudentsPreviousDetails?.initials} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="SecondName">Second Name </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="SecondName"
                className={"form-control" + (formik.errors.SecondName && formik.touched.SecondName ? " is-invalid" : "")}
                name="SecondName"
                placeholder="Enter Second Name"
                value={formik.values.SecondName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Second Name"
              />
              {formik.errors.SecondName && formik.touched.SecondName ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.second_name} previousValue={studentData?.RegStudentsPreviousDetails?.second_name} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="ThirdName">Third Name </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="ThirdName"
                className={"form-control" + (formik.errors.ThirdName && formik.touched.ThirdName ? " is-invalid" : "")}
                name="ThirdName"
                placeholder="Enter Third Name"
                value={formik.values.ThirdName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Third Name"
              />
              {formik.errors.ThirdName && formik.touched.ThirdName ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.third_name} previousValue={studentData?.RegStudentsPreviousDetails?.third_name} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Email">Email *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="Email"
                className={"form-control" + (formik.errors.Email && formik.touched.Email ? " is-invalid" : "")}
                name="Email"
                placeholder="Enter Email"
                value={formik.values.Email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Email"
              />
              {formik.errors.Email && formik.touched.Email ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.email} previousValue={studentData?.RegStudentsPreviousDetails?.email} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="RSAID">{formik.values.course_type === "shortcourse" ? "Passport/RSA ID" : "Passport/RSA ID *"}</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="RSAID"
                className={"form-control" + (formik.errors.RSAID && formik.touched.RSAID ? " is-invalid" : "")}
                name="RSAID"
                placeholder="Enter Passport/RSA ID"
                value={formik.values.RSAID}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="RSA ID"
              />
              {formik.errors.RSAID && formik.touched.RSAID ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.id_number} previousValue={studentData?.RegStudentsPreviousDetails?.id_number} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Mobile">Mobile *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="Mobile"
                className={"form-control" + (formik.errors.Mobile && formik.touched.Mobile ? " is-invalid" : "")}
                name="Mobile"
                placeholder="Enter Mobile"
                value={formik.values.Mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Mobile"
              />
              {formik.errors.Mobile && formik.touched.Mobile ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.mobile} previousValue={studentData?.RegStudentsPreviousDetails?.mobile} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="MaidenName">Maiden Name</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="MaidenName"
                className={"form-control" + (formik.errors.MaidenName && formik.touched.MaidenName ? " is-invalid" : "")}
                name="MaidenName"
                placeholder="Enter Maiden Name"
                value={formik.values.MaidenName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Maiden Name"
              />
              {formik.errors.MaidenName && formik.touched.MaidenName ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.maiden_name} previousValue={studentData?.RegStudentsPreviousDetails?.maiden_name} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Disablity">{formik.values.course_type === "shortcourse" ? "Disability" : "Disability *"}</label>
            <div className="form-icon-group mb-4" title="Disablity">
              <Select
                className={"form-control custom-select-box " + (formik.errors.Disablity && formik.touched.Disablity ? " is-invalid" : "")}
                name="Disablity"
                value={disablity.filter((item) => {
                  return item.value == formik.values.DisablityId;
                })}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue("Disablity", value.label);
                    formik.setFieldValue("DisablityId", value.value);
                  } else {
                    formik.setFieldValue("Disablity", "");
                    formik.setFieldValue("DisablityId", "");
                  }
                }}
                onBlur={formik.handleBlur}
                options={disablity}
                maxMenuHeight={175}
                placeholder={formik.values.Disablity ? formik.values.Disablity : "Select"}
                isClearable
              />
              {formik.errors.Disablity && formik.touched.Disablity ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.disability} previousValue={studentData?.RegStudentsPreviousDetails?.disability} type="dropdown" typeSupportingProps={{filterArray : disablity}} />
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="AllowedExtraTime" title="Allowed Extra Time">
              Allowed Extra Time *
            </label>
            <div className="form-icon-group" title="Allowed Extra Time">
              <div className="custon_rd_new">
                <div className="custom-control custom-radio custom-control-inline" title="Yes">
                  <input
                    type="radio"
                    id="customRadioInline1"
                    name="customRadioInline"
                    className="custom-control-input"
                    checked={formik.values.AllowedExtraTime == "Yes"}
                    onChange={() => formik.setFieldValue("AllowedExtraTime", "Yes")}
                    onBlur={formik.handleBlur}
                  />
                  <label className="custom-control-label" htmlFor="customRadioInline1">
                    Yes
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline" title="No">
                  <input
                    type="radio"
                    id="customRadioInline2"
                    name="customRadioInline"
                    className="custom-control-input"
                    title="AllowedExtraTime"
                    checked={formik.values.AllowedExtraTime == "No"}
                    onChange={() => formik.setFieldValue("AllowedExtraTime", "No")}
                    onBlur={formik.handleBlur}
                  />
                  <label className="custom-control-label" htmlFor="customRadioInline2">
                    No
                  </label>
                </div>
              <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.extra_time} previousValue={studentData?.RegStudentsPreviousDetails?.extra_time} type="allowedTime" typeSupportingProps={{filterArray : disablity}} />
              </div>
            </div>
          </div>
        <div className="col-md-6 col-lg-4">
          <label>&nbsp;</label>
          <div className="form-group-blk mb-3" style={{ paddingTop: "11px" }}>
            <label></label>
            <div className="custom-check custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                id="RPL"
                name="select"
                checked={formik.values.RPL}
                onChange={e => {
                  let data = {
                    action: "recognition_learning",
                    id: studentId,
                    order: e.target.checked ? 1 : 0,
                  }
                  checkCheckboxRejectDetails(data).then(res => {
                    Swal.fire("Updated", res.data.message, "success")
                  }).catch(err => {
                    console.error("error: ", err)
                    Swal.fire("Not Updated", err.response.data.message, "error")
                  })
                  formik.setFieldValue("RPL", e.target.checked)
                }}
              />
              <label className="custom-control-label" htmlFor="RPL">RPL</label>
            </div>
          </div>
        </div>
        </div>
        {/* <div className="form-group form-group-save-cancel">
          <button className="btn btn-save btn-success" type="submit" title="Update" disabled>
            {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save
          </button>
          <button className="btn btn-save btn-success" type="submit" title="Update" disabled={disabled || !formik.dirty || isLoaded}>
            {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Post To Netsuite
          </button>
          <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()}>
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {formik.touched.Title && formik.errors.Title ? <div className="invalid-feedback d-block">{formik.errors.Title}</div> : null}
          {formik.touched.Initials && formik.errors.Initials ? <div className="invalid-feedback d-block">{formik.errors.Initials}</div> : null}
          {formik.touched.FirstName && formik.errors.FirstName ? <div className="invalid-feedback d-block">{formik.errors.FirstName}</div> : null}
          {formik.touched.LastName && formik.errors.LastName ? <div className="invalid-feedback d-block">{formik.errors.LastName}</div> : null}
          {formik.touched.MaidenName && formik.errors.MaidenName ? <div className="invalid-feedback d-block">{formik.errors.MaidenName}</div> : null}
          {formik.touched.RSAID && formik.errors.RSAID ? <div className="invalid-feedback d-block">{formik.errors.RSAID}</div> : null}
          {formik.touched.Email && formik.errors.Email ? <div className="invalid-feedback d-block">{formik.errors.Email}</div> : null}
          {formik.touched.Mobile && formik.errors.Mobile ? <div className="invalid-feedback d-block">{formik.errors.Mobile}</div> : null}
          {formik.touched.Disablity && formik.errors.Disablity ? <div className="invalid-feedback d-block">{formik.errors.Disablity}</div> : null}
          {formik.touched.AllowedExtraTime && formik.errors.AllowedExtraTime ? <div className="invalid-feedback d-block">{formik.errors.AllowedExtraTime}</div> : null}
        </div> */}
        <div className="row"></div>
      <hr />
    </div>
  );
}

export default StudentDetails;
