import React, { useEffect, useMemo, useState } from 'react'
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";
import { useParams, useHistory } from 'react-router-dom';
import { GetChatDocuments, GetChatDocumentsFilters } from '../../../services/PostsAndFeedService';
import { BASE_URL, STUDENT_FILE_DOWNLOAD, CHAT_FILES_DOWNLOAD, downloadURL, IMAGE_URL } from '../../../utils/Constants';
import { RenderFileType } from '../../../utils/CommonGroupingItem';
import moment from 'moment';
import { InitalRender } from '../../common/Helper';
import Hover from '../../common/Hover';
import DataTableComponent from '../../common/DataTableComponent';
import { download } from '../../../utils/FileDownload';


export default function SharedFileList(props) {
    const history = useHistory();
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fileType, setFileType] = useState({ arr: [], checkObj: {} })
    const [uploadedBy, setUploadedBy] = useState({ arr: [], checkObj: {} })
    const [data, setData] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [filterData, setFilterData] = useState({fileType:[], uploadBy:[]})
    const [tableState, setTableState] = useState({
        page: 1,
        perPage: 10,
        sortKey: "originalName",
        sortOrder: "DESC"
    });
    const [searchStudent, setSearchStudent] = useState([]);
    const [searchAdmin, setSearchAdmin] = useState([]);

    
    useEffect(()=>{
        const payload = {
            course_id:id,
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            search:search,
            page: tableState.page,
            limit: tableState.perPage,          
            extentsion : fileType.arr,
            exportStatus:"false",
            student : searchStudent,
            admin : searchAdmin,
        }
        setLoading(true);
        GetChatDocuments(payload).then((res)=>{
            if(res.status==200){
                const respData = res?.data?.data?.data;
                setData(respData);
                setTotalRows(res.data?.data.total);
            }
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{
            setLoading(false);
        })
    },[search, fileType, tableState, searchAdmin, searchStudent])

    useEffect(()=>{
        const payload = {
            course_id:id,
        }
        GetChatDocumentsFilters(payload).then((res)=>{
            if(res.status === 200){
                const respData = res.data;
                const filteredUserData = respData?.userFilters.map((item)=>({value:`${item.value}-${item.is_admin}`, label:item.label}))
                setFilterData({
                    ...filterData,
                    fileType:[...respData?.exntsionFilters],
                    uploadBy:[...filteredUserData]
                })
            }            
        }).catch((err)=>{
            console.log(err)
        })
        return (()=>{
            setFilterData({fileType:[], uploadBy:[]})
        })
    },[])

    const handleDownloadFiles = (row) =>{ 
        let fileURL = row?.attachment;
        if(!fileURL.includes("chat.myaie.ac")){
            fileURL = downloadURL + CHAT_FILES_DOWNLOAD + "/" + row.attachment.replace("/home/myaie/api/public_html/", "").replace("/home/myaie/public_html/", "").replace("public/","")        
        } 
        download(fileURL, row?.originalName)
    }

    const handleShowInFeed = (row) =>{
        props.setMessageId(row?.message_id)
        props.setCommentId(row?.comment_id)
        history.push(`/courseAdministration/coursesdetails/${id}/post-feed/show`)
    }

    const columns = useMemo(()=>[
        {
            name : "Name",
            selector:"originalName",
            sortField:"originalName",
            sortable:true,
            cell:(row) =>(row?.originalName ? 
                <div className='d-flex'>
                {RenderFileType(row?.extentsion).iconOnly}
                <span
                    className={"as-text-blue overflow-ellipsis curser ml-2"}
                    onClick={()=>{handleDownloadFiles(row)}}
                >
                    {row?.originalName} 
                </span>                
                </div>
            : "-")
        },
        {
            name : "Uploaded By",
            selector:"user_full_name",
            sortField:"user_full_name",
            sortable:true,
            cell:(row) =>(
                <>
                {row?.is_admin>0 ? <div className="user-profile assigned-title-blk">
                    <div className="assigned-title-itm">
                        <span
                        className="name-icon cat-dark-red bgBlue  user-drop-down-img">
                            {row?.admin_profile ? (
                                <img
                                    src={
                                    IMAGE_URL +
                                    row?.admin_profile.replace("/home/myaie/public_html/", "").replace("public/","")
                                    }                     
                                    onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.closest('.name-icon.cat-dark-red').append(InitalRender(`${row?.admin_first_name} ${row?.admin_last_name}`));
                                    currentTarget.remove();
                                    }}
                                />
                                
                            ) : (
                                InitalRender(`${row?.admin_first_name} ${row?.admin_last_name}`)
                            )} 
                            {row?.admin_activity_status.toLowerCase() == "offline" ? <span className="portal-status-icon offline-u"></span> : <span className="portal-status-icon online-u"></span>}                       
                        </span>                         
                        <span className="overflow-ellipsis as-text-blue">{`${row?.admin_first_name} ${row?.admin_last_name}`}</span>
                    </div>
                    <Hover 
                        fullName={row?.user_full_name}
                        firstName={row?.admin_first_name}
                        lastName={row?.admin_last_name}
                        photo={row?.photo}
                        email={row?.admin_email}
                        mobile={row?.admin_mobile}
                        status={row?.admin_activity_status}
                        activity_status={row?.student_activity_status}
                        right={false}
                        showNumber={row?.student_crm_id ? true : false} 
                        number={row?.student_crm_id}
                    />
                </div>
                :<div className="user-profile assigned-title-blk">
                    <div className="assigned-title-itm">
                        <span
                        className="name-icon cat-dark-red bgBlue  user-drop-down-img">
                        {row?.student_profile ? (
                            <img
                                src={
                                IMAGE_URL +
                                row?.student_profile.replace("/home/myaie/public_html/", "").replace("public/","")
                                }                     
                                onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.closest('.name-icon.cat-dark-red').append(InitalRender(`${row?.student_first_name} ${row?.student_last_name}`));
                                currentTarget.remove();
                                }}
                            />
                            
                        ) : (
                            InitalRender(`${row?.student_first_name} ${row?.student_last_name}`)
                        )}
                        {row?.student_activity_status.toLowerCase() == "offline" ? <span className="portal-status-icon offline-u"></span> : <span className="portal-status-icon online-u"></span>}
                        </span>
                        
                        <span className="overflow-ellipsis as-text-blue">{`${row?.student_first_name} ${row?.student_last_name}`}</span>
                    </div>
                    <Hover 
                        fullName={row?.user_full_name}
                        firstName={row?.student_first_name}
                        lastName={row?.student_last_name}
                        photo={row?.photo}
                        email={row?.student_email}
                        mobile={row?.student_mobile}
                        status={row?.admin_activity_status}
                        activity_status={row?.student_activity_status}
                        right={false}
                        showNumber={row?.student_crm_id ? true : false} 
                        number={row?.student_crm_id}
                    />
                </div>}
                </>
            )
        },
        {
            name : "Date",
            selector:"created_at",
            sortField:"created_at",
            sortable:true,
            cell:(row) =>(row?.created_at ? 
                <div className="dateTime"><p className="right-space">{moment(row.created_at).format("D MMM 'YY")}</p></div>                
            : "-")
        },
        {
            name : "Size",
            selector:"size_in_bytes",
            sortField:"size_in_bytes",
            sortable:true,
            cell:(row) =>(row?.size ? row?.size : "-")
        },
        {
            name: "Action",
            cell: (row) => (
              <div className="assessment-08 d-flex">
                <div className="as-buttons">
                    <button
                      title="Download"
                      className="btn btn-primary rounded-circle"
                      onClick={() =>{handleDownloadFiles(row)}}
                    >
                      <i className="fal fa-download"></i>
                    </button>                    
                </div>
                <div className="as-buttons">
                    <button
                      title="Show in Feed"
                      className="btn btn-primary rounded-circle"
                      onClick={()=>{handleShowInFeed(row)}}
                    >
                      <i className="fal fa-comment"></i>
                    </button>                
                </div>
              </div>
            ),
        },
    ]);
    
    const exportData = (exportFileType, fileName) => {
        let exportData = data;
        let header = [
            "Name",
            "Uploaded By",
            "Date",
            "Size"
        ];

        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });

        let payload = {
            course_id:id,
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            search:search,
            page: tableState.page,
            limit: tableState.perPage,          
            extentsion : fileType.arr,
            exportStatus:"true",
            student : searchStudent,
            admin : searchAdmin,
        };
        GetChatDocuments(payload)
            .then((res) => {
                const respData = res?.data?.data;
                exportData = respData?.map((row) => ({
                    Name: row.originalName ? row.originalName : "-",
                    "Uploaded By": row.user_full_name ? row.user_full_name : "-",
                    Date: row.created_at ? moment(row.created_at).format("D MMM 'YY") : "-",
                    Size: row.size ? row.size : "-",
                    
                }));

                if (exportFileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data:exportData });
                    const blob = new Blob([csvString], {
                        type: "text/csv;charset=utf-8,",
                    });

                    const blobURL = window.URL.createObjectURL(blob);

                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    setTimeout(() => {
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                    Swal.close();
                } else if (exportFileType === "xlsx") {
                    const compatibleData = exportData.map((row) => {
                        const obj = {};
                        header.forEach((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                    Swal.close();
                    return false;
                }
                if (exportFileType === "pdf") {
                    const compatibleData = exportData.map((row) => {
                        return [
                            row["Name"],
                            row["Uploaded By"],
                            row["Date"],
                            row["Size"]
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            fontSize: 8,
                            columnStyles: {
                                0: {
                                  columnWidth: 35,
                                },
                                1: {
                                  columnWidth: 25,
                                },
                                2: {
                                  columnWidth: 20,
                                },
                                3: {
                                  columnWidth: 10,
                                }                               
                            },
                        },
                    });
                    doc.save(`${fileName}.pdf`);
                    Swal.close();
                    return false;
                }
            })
            .catch((error) => {
                console.log("error >>>>>> ", error);
            });
    };

    const handleUploadedByFilter = (e) =>{
        console.log(e)
        setUploadedBy(e);

        const st_arr = [];
        const ad_arr = [];
        
        e.arr.map(st =>{
            if(st.split("-")[1] == 1){
                ad_arr.push(st.split("-")[0])
            }else{
                st_arr.push(st.split("-")[0])
            }
        })

        setSearchAdmin([...ad_arr])
        setSearchStudent([...st_arr])
    }

    return (
        <DataTableComponent
            state={tableState}
            setState={setTableState}
            columns={columns}
            data={data}
            loading={loading}
            search={search}
            setSearch={setSearch}
            totalRows={totalRows}
            exportFunction={exportData}
            exportFileName={"shared_File_List"}
            defaultSort={{ defaultSortColumn: "originalName", defaultSortAsc: false }}
            filters={[
                {
                    filterName: "File Type",
                    state: fileType,
                    setState: setFileType,
                    optionArr: filterData?.fileType,
                    renderLabelFunction:RenderFileType,                    
                },
                {
                    filterName: "Uploaded By",
                    state: uploadedBy,
                    setState: (e)=>{handleUploadedByFilter(e)},
                    optionArr: filterData?.uploadBy
                }
            ]}
            tableButton={[
                <button className="btn btn-primary" onClick={()=>{history.push(`/courseAdministration/coursesdetails/${id}/post-feed/show`)}} title="Back" type="button">
                    
                    <i className="fal fa-chevron-left"></i>Back
                </button>
            ]}
        />
    )
}