import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import * as Yup from "yup";
import Str from "../../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import {
  deleteExamsFinalAllFiles,
  ExamsFinalMarksImportList,
  ExamsFinalMarksImportListFilter,
  ExamsFinalUpdatedion,
  ExamsMarksUpdate,
  GetExamsFinalAllFiles,
  ImportExamFinalMarksFile,
  ImportSingleUpdate,
  UpdateResult,
} from "../../../../services/ExternalExamServices";
import Tablefilter from "../../../common/Tablefilter";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../../utils/permissionGate";
import axios from "axios";
import { BASE_URL, STUDENT_FILE_DOWNLOAD, downloadURL, IMAGE_URL, STUDENT_FILES_DOWNLOAD } from "../../../../utils/Constants";

const MarksList = () => {
  const history = useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [markList, setMarkList] = useState([]);
  const [flaggedData, setFlaggedData] = useState([]);
  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("flagged_type");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [flaggedFilter, setFlaggedFilter] = useState({arr : [], checkObj : {}});
  const [gearLoading, setGearLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [resultUpdated, setResultUpdated] = useState(false);
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [fileList, setFileList] = useState([]);
  const [refreshFile, setRefreshFile] = useState(false);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["eefmiview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])
  
  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  useEffect(() => {
    const filterSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await ExamsFinalMarksImportListFilter(id,filterSource.token);
        if(res.status==200){
          setFlaggedData(res.data.flaggedFilter);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    getFilters();
    return () => {
      filterSource.cancel('Component unmounted');    
    };
  }, [])
  
  useEffect(()=>{
    const fileSource = axios.CancelToken.source();
    const getFiles = async () =>{
      try {
        const payload = {exam:id};
        const res = await GetExamsFinalAllFiles(payload, fileSource.token);
        if(res.status === 200){
          // console.log(res.data);
          setFileList([...res.data.result]);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    getFiles();
    return () => {
      fileSource.cancel('Component unmounted');    
    };
  },[refreshFile])

  useEffect(() => {
    const cancelTokenSources = [];
    const getListData = async () =>{
      setloading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      
      cancelTokenSources.push(source);
      const mark = {
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        exam: id,
        viaFlagged: flaggedFilter.arr,
      };

      try {
        const res = await ExamsFinalMarksImportList(mark, source.token);
        if(res.status==200){
          setMarkList(res.data.data.data);
          setTotalRows(res.data?.data?.total);
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }

    }
    
    getListData();
    return () => {
      cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
      });
    }
  }, [
    page,
    perPage,
    sortOrder,
    sortkey,
    search,
    flaggedFilter,
    id,
    refresh
  ]);

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setFlaggedFilter({arr : [], checkObj : {}});
    setSearch("");
    setDebouncedTerm("")
  };

  const handleUpdateFlag = (item) =>{
    const body = {
      id : item.id
    }
    setUpdating(true);
    ImportSingleUpdate(body).then((res)=>{
      if(res.status==200){
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Records successfully updated",
        }).then(()=>{
          setRefresh(!refresh);
        });        
      }
    }).catch((err)=>{
      console.log(err);
    }).finally(()=>{
      setUpdating(false);
    })
  }

  const columns = useMemo(() => [
    {
      name: "RSA ID",
      selector: "rsa_id",
      sortField: "rsa_id",
      sortable: true,
      cell: (row) => (row.rsa_id ? row.rsa_id : "-"),
    },
    {
      name: "Exam ID",
      selector: "exam_id_num",
      sortField: "exam_id_num",
      sortable: true,
      cell: (row) => (row.exam_id_num ? row.exam_id_num : "-"),
    },

    // { name: "Email", selector: "Email", sortable: true, cell: (row) => TrimText(row.Email ? row.Email : "NA", 15) },

    {
      name: "Surname",
      selector: "last_name",
      sortField: "last_name",
      sortable: true,
      cell: (row) => (row.last_name ? row.last_name : "-"),
    },
    {
      name: "Course Code",
      selector: "code",
      sortField: "code",
      sortable: true,
      cell: (row) => (row.code ? row.code : "-"),
    },
    {
      name: "Course Name",
      selector: "subject_name",
      sortField: "subject_name",
      sortable: true,
      cell: (row) =>row.subject_name ? <Link title={row.subject_name} to={`/courseAdministration/coursesdetails/${row.subject}/detailCourses/show`} className="as-text-blue curser  feature-name"> <span className="textLimit100">{row.subject_name}</span></Link> :"-"
    },
    {
      name: "DHET Result",
      selector: "dhetresult",
      sortField: "dhetresult",
      sortable: true,
      cell: (row) => (row.dhetresult.toString() ? row.dhetresult : "-"),
    },
    {
      name: "Pass Indicator",
      selector: "pass_indi",
      sortField: "pass_indi",
      sortable: true,
      cell: (row) => (row.pass_indi ? row.pass_indi : "-"),
    },
    {
      name: "Suspended Indicator",
      selector: "suspend_indi",
      sortField: "suspend_indi",
      sortable: true,
      cell: (row) => (row.suspend_indi.toString() ? row.suspend_indi : "-"),
    },
    {
      name: "Flagged",
      selector: "flagged_type",
      sortField: "flagged_type",
      sortable: true,
      cell: (row) => (row.flagged_type ? row.flagged_type : "-"),
    },
    {
      name: "File Name",
      selector: "file_name",
      sortField: "file_name",
      sortable: true,
      cell: (row) => (row.file_name ? row.file_name : "-"),
    },
    {
      name: "Update",
      selector: "updated",
      sortField: "updated",
      sortable: false,
      cell: (row) => (
        (row.flagged==4 || row.flagged==5 || row.flagged==6) ? 
        (row.updated==0 ? 
          <div className="assessment-08">
            <button className="btn btn-primary" disabled={updating} title="Update" onClick={()=>{handleUpdateFlag(row)}}>
              Update
            </button>                             
          </div>
          :
          <span title={"UPDATED"} className={`as-orange-bg as-widget no-text-transformation mr-2`}>UPDATED</span>
        ) : "-"
      ),
    },
  ]);

  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = [
      "RSA ID",
      "Exam ID",
      "Surname",
      "Course Code",
      "Course Name",
      "DHET Result",
      "Pass Indicator",
      "Suspended Indicator",
      "Flagged",
      "File Name",
      "Update",
    ];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const mark = {
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      exam: id,
      viaFlagged: flaggedFilter.arr,
      exportStatus: "true",
    };
    ExamsFinalMarksImportList(mark)
      .then((res) => {
        data = res.data.data;
        data = data?.map((row) => ({
          ...row,
          "Exam ID": row?.exam_id_num ? row?.exam_id_num : "-",
          "RSA ID": row?.rsa_id ? row?.rsa_id : "-",
          Surname: row?.last_name ? row?.last_name : "-",
          "Course Code": row?.code ? row?.code : "-",
          "Course Name": row?.subject_name ? row?.subject_name : "-",
          "Pass Indicator": row?.pass_indi ? row?.pass_indi : "-",
          "Suspended Indicator": row?.suspend_indi.toString()
            ? row?.suspend_indi
            : "-",
          Flagged: row?.flagged_type ? row?.flagged_type : "-",
          "File Name": row?.file_name ? row?.file_name : "-",
          Update: row?.updated ? row?.updated : "-",
          "DHET Result": row?.dhetresult.toString() ? row?.dhetresult : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          // console.log(csvString);
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["RSA ID"],
              row["Exam ID"],
              row["Surname"],
              row["Course Code"],
              row["Course Name"],
              row["Pass Indicator"],
              row["Suspended Indicator"],
              row["Flagged"],
              row["File Name"],
              row["Update"],
              row["DHET Result"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = () => {
    setGearLoading(true);
    let formData = new FormData();
    formData.set("exam", id);
    ExamsMarksUpdate(formData)
      .then((res) => {
        if(res.status==200){
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Records successfully updated",
          }).then(()=>{
            setGearLoading(false);
            setRefresh(!refresh);
          });
        }
      })
      .catch((err) =>{ console.log(err);setGearLoading(false);});
  };

  
  const handleFileChange = (e) => {    
    if (e.target.files.length) {
      Swal.fire({
        icon: "info",
        title: 'Processing File...',
        text: 'Upload may take a bit longer than usual',
        showConfirmButton: false
      });
      
      const formData = new FormData();
      formData.append("exam", id);
      formData.append("attachment", e.target.files[0]);
      ImportExamFinalMarksFile(formData)
        .then((res) => {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "File Added successfully",
          });         
        })
        .catch((err) => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err?.response?.data?.message,
          });
        })
        .finally(()=>{
          setRefresh(!refresh);          
          setRefreshFile(!refreshFile);
        })
    }
  };

  const handleUpdateResult = () =>{
    const body = {
      exam:id
    }
    setResultUpdated(true);
    UpdateResult(body).then((res)=>{
      if(res.status==200){
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Records successfully updated",
        }).then(()=>{
          history.push(`/studentAdministration/externalExam/details/open/updatedResult/${id}`);
        })
      }
    }).catch((err)=>{
      console.log(err);
    }).finally(()=>{
      setResultUpdated(false);
    })
  }

  const handleDownloadFile = async (file) =>{
    const absoluteURL = (STUDENT_FILE_DOWNLOAD.includes("myaie") ? downloadURL : "") + STUDENT_FILE_DOWNLOAD + "/" + (file.file_url).replaceAll("/home/myaie/public_html/", "").replace("public/", "").replace(/(^\w+:|^)\/\//, '')
    Swal.close();
    Swal.fire({
    icon: "info",
    title: "Downloading...",
    timer: 2000,
    onOpen: function () {
      Swal.showLoading();
    },
    showConfirmButton: false
  })
  await axios({
    url: absoluteURL, 
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    if (response.status==200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file?.file_name); 
      document.body.appendChild(link);
      link.click();
      Swal.close();
      Swal.fire({
       icon: "success",
       title: "Downloading Finished",
       showConfirmButton: true
     })
      
    } else {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error while downloading file",
      }).then((result) => {});
    }
  }).catch((error) => { 
    Swal.close();  
    Swal.fire({
      icon: "error",
      title: "Error while downloading file",
    }).then((result) => {});
  })      
  }

  const handleDeleteFile = (file) =>{
    const payload = {
      exam:id,
      file_name: file?.file_name
    }

    deleteExamsFinalAllFiles(payload).then((res)=>{
      if(res.status===200){
        // console.log(res.data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        }).then(()=>{
          setRefresh(!refresh);
          setRefreshFile(!refreshFile);
        })
      }
    }).catch((err)=>{
      console.log(err)
    })

  }

  return (
    <div className="card card-profile-info-card mb-30 course-details-tab-sec">
      <div className="card-body">
        <div className="custom-table-div filter-search-icon card-table-custom">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={e => setDebouncedTerm(e.target.value)} 
                          value={debouncedTerm}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="filter-scroll">
                    <div className={`filter-scroll-inner  filter-custom-new`}>
                      <Tablefilter
                        filterName={"Flagged"}
                        optionArr={flaggedData}
                        state={flaggedFilter}
                        setState={setFlaggedFilter}
                      />
                    </div>
                  </div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        className="btn btn-primary"
                        onClick={resetFilter}
                        title="Reset"
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("xlsx", "Marks");
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", "Marks");
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", "Marks");
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <DataTable
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={<SkeletonTicketList />}
            data={markList}
            defaultSortField={sortkey}
            sortServer
            defaultSortAsc={false}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            onSort={handleSort}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
          />
        </div>
        <hr />
        <div className="row align-items-center m-0">
          <div className="ml-auto text-right">
          <PermissionsGate scopes={["eefmiedit"]} RenderError={()=>(
            <>
              <button className="btn btn-primary mr-2" title="Update for 4/5" disabled>
              <i className="fa fa-paper-plane"></i>  Update for 4/5
              </button>
              <button className="btn btn-primary" title="Update Result" disabled>Update Result</button>
            </>
          )}>
            <button
              className="btn btn-primary mr-2"
              title="Update for 4/5"
              onClick={() => handleUpdate()}
              disabled={gearLoading}
            >
             {gearLoading ? <i className="fas fa-cog fa-spin"></i> :  <i className="fa fa-paper-plane"></i>} Update for 4/5
            </button>
            <button disabled={resultUpdated} className="btn btn-primary" title="Update Result" onClick={()=>{handleUpdateResult()}}>
              Update Result
            </button>            
            </PermissionsGate>
          </div>
        </div>
        <hr /> 
        <div className="my-tickets-info-list Tickets-main-wrap">          
          <div className="edit-icon new-card-header">
            <div className="card-header">Upload result file for import</div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group-blk mb-3">
                <label>Upload File *</label>
                <div className="form-group atttach-file m-0">
                  <label>
                    <i className="fal fa-paperclip"></i>
                    <span>Add Attachment</span>
                    {/* <PermissionsGate
                      errorProps={{ disabled: true }}
                      scopes={["spiedit"]}
                    > */}
                      <input
                        type="file"
                        title="Add Attachment"
                        className="form-control  form-control-aatch-file"
                        //  disabled={disabled}
                        accept="text/plain"
                        onChange={(e) => {
                          // console.log("first")
                          handleFileChange(e);
                          e.target.value = ''
                        }}
                      />
                    {/* </PermissionsGate> */}
                  </label>
                </div>
              </div>
            </div>
          </div>     
          <hr />
          <div className="row">
            {fileList.map((file, key) =>(
              <div className="col-md-3">
                <div className="uploaded-file-container">
                  <div className="file-name as-text-blue curser" onClick={() =>{handleDownloadFile(file)}}>
                    {file?.file_name}
                  </div>
                  <div className="file-action">
                    <div className="as-button">
                      <button
                        title="Download"
                        className="btn btn-primary rounded-circle mr-1"
                        onClick={() => handleDownloadFile(file)}
                      >
                        <i className="fal fa-download"></i>
                      </button>
                      <PermissionsGate scopes={["efufiledelete"]} errorProps={{ disabled: true }}>
                        <button
                          title="Delete"
                          className="btn btn-danger rounded-circle"
                          onClick={() => handleDeleteFile(file)}
                        >
                          <i className="fal fa-trash-alt"></i>
                        </button>
                      </PermissionsGate>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>              
        </div>
        
      </div>
    </div>
  );
};

export default MarksList;
