import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { useFormik, FormikProvider, Form } from "formik";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Papa from "papaparse"
import {
  getBrandTemplate,
  updateLaptopPricing
} from "../../../../services/BrandTemplateServices";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FileUploadField, FormField } from "../../../common/FormFields";
import axios from "axios";
import { GetNetsuiteItemsCancelToken } from "../../../../services/CourseService";
import AssignPagePopup from "../../../layoutNavigation/layoutHeaders/AssignPagePopup";
import { TrimText } from "../../../common/TrimText";
import $ from "jquery"
import { id } from "date-fns/locale";
import DataTableComponent from "../../../common/DataTableComponent";
import { RenderPMCPageStatus, RenderPMCStatusCol } from "../../../../utils/CommonStatusItems";
import { GetPagesList, getPagesListForPmcFilters } from "../../../../services/ProgramService";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";

const LaptopPricing = () => {
  const { subId, subType } = useParams();
  const history = useHistory();
  const [brandData, setBrandData] = useState({});
  const [netsuiteList, setNetsuiteList] = useState([]);
  const [assignedPage, setAssignedPage] = useState({});
  const [assignedPopupPages,setAssignedPopupPages] = useState({});
  const [assignedArticulationOptionList, setAssignedArticulationOptionList] = useState([]);
  const assignedPageRef = useRef(assignedPopupPages)
  const [some,setSome] = useState([]);
  const [editorStates, setEditorStates] = useState({
    core_specifications: "",
    med_specifications: "",
    high_specifications: "",
    disclaimer_tc: ""
  });
  const [allpageListData, setAllPageListData] = useState([]);
  const [assignPageList, setAssignPageList] = useState([]);
  const [assignPageLoading, setAssignPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageStatus, setPageStatus] = useState({ arr: [], checkObj: {} });
  const [pageBrand, setPageBrand] = useState({ arr: [], checkObj: {} });
  const [PageFilterData, setPageFilterdata] = useState({
    status: [],
    BrandList: [],
  });
  const [assignPageTableState, setAssignPageTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "PageTitle",
    sortOrder: "asc",
  });
  const [assignPageSearch, setAssignPageSearch] = useState("");
  const [assignPageTotalRows, setAssignPageTotalRows] = useState(0);
  useEffect(() => {
    const cancelDropdownSource6 = [];
    const getPageFilters = async () => {
      cancelDropdownSource6.forEach((source) => {
        source.cancel("New request made");
      });
      const source3 = axios.CancelToken.source();
      cancelDropdownSource6.push(source3);
      
      try {
        const res = await getPagesListForPmcFilters(source3.token);
        if (res.status == 200) {
          setPageFilterdata({
            BrandList: res.data?.getBrandtemplate,
            status: res.data?.getStatus,
          });
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
      cancelDropdownSource6.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
    getPageFilters();
    
    
  },[])
  
  useEffect(() => {
    const cancelTokenSources = [];
    
    const getAssignPageListData = async () => {
      setAssignPageLoading(true);
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });
      
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      
      try {
        const payload = {
          page: assignPageTableState.page,
          perPage: assignPageTableState.perPage,
          search: assignPageSearch,
          key: assignPageTableState.sortKey,
          sort: assignPageTableState.sortOrder,
          exportStatus: false,
          setSearch: setAssignPageSearch,
          viaBrand: pageBrand.arr,
          viaStatus: pageStatus.arr,
        };
        const res = await GetPagesList(payload, source.token);
        if (res.status == 200) {
          setAssignPageTotalRows(res.data?.Data?.total);
          setAssignPageList(res.data?.Data?.data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      } finally {
        setAssignPageLoading(false);
      }
    };
    
    getAssignPageListData();
    
    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [assignPageTableState, assignPageSearch, pageStatus, pageBrand]);
  
  const assignPageColumn = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      sortField: "PageTitle",
      // wrap: true,
      cell: (row) => (
        <span
        className="as-text-blue curser feature-name"
        // to={{
        //     pathname: `/courseAdministration/Programmes/programme/open/${row.qualification}/details`,
        // }}
        onClick={() => {
          formik.setFieldValue("terms_conditions_page", row.PageID);
          setAssignedPage({ ...row });
          $("#assignPagePopup").modal("hide");
        }}
        title={row.title}
        >
        <span className="textLimit100">
        {row.PageTitle ? row.PageTitle : "-"}
        </span>
        </span>
      ),
    },
    {
      name: "Status",
      selector: "Status",
      sortField: "status",
      sortable: true,
      cell: (row) => (row.status ? RenderPMCStatusCol(row.status).html : "-"),
    },
    {
      name: "Brand Templates",
      selector: "Brand Templates",
      sortable: false,
      cell: (row) => (
        <p className="d-flex flex-wrap">
        {row?.brandTemplete?.length > 0
          ? row?.brandTemplete.map(
            (brand, index) =>
              brand?.templateName && (
              <div className="brandList-pmc" title={brand?.templateName}>
              {brand?.templateName}
              </div>
            )
          )
          : "-"}
          </p>
        ),
      },
      {
        name: "Action",
        selector: "",
        maxWidth: "50px",
        cell: (row) => (
          <div className="assessment-08">
          <div className="as-buttons">
          {formik.values.laptop_detail_page_id != row?.PageID ? (
            <button
            type="button"
            className="btn btn-primary rounded-circle"
            onClick={() => {
              formik.setFieldValue("laptop_detail_page_id", row?.PageID);
              setAssignedPage({ ...row });
              $("#assignPagePopup").modal("hide");
            }}
            title="Assign Page"
            >
            <i className="fal fa-plus"></i>
            </button>
          ) : (
            <button
            type="button"
            className="btn btn-danger rounded-circle"
            onClick={() => {
              formik.setFieldValue("laptop_detail_page_id", 0);
              setAssignedPage({});
            }}
            title="Assign Page"
            >
            <i className="fal fa-minus"></i>
            </button>
          )}
          </div>
          </div>
        ),
      },
    ]);
    
    useEffect(() => {
      setSome({...assignedPopupPages});
    }, [assignedPopupPages]);
    
    const exportAssignPageData = (fileType, fileName) => {
      let exportData = [...assignedArticulationOptionList];
      const header = ["Name", "Status", "brand templates"];
      
      Swal.fire({
        title: "File downloading",
        onOpen: function () {
          Swal.showLoading();
        },
      });
      
      const payload = {
        page: assignPageTableState.page,
        perPage: assignPageTableState.perPage,
        search: assignPageSearch,
        key: assignPageTableState.sortKey,
        sort: assignPageTableState.sortOrder,
        exportStatus: true,
        viaBrand: pageBrand.arr,
        viaStatus: pageStatus.arr,
      };
      GetPagesList(payload)
      .then((res) => {
        exportData = res.data.Data;
        exportData = exportData?.map((row) => ({
          ...row,
          Name: row?.PageTitle ? row?.PageTitle : "-",
          Status: row?.status ? row.status : "-",
          "brand templates":
          row?.brandTemplete?.length > 0
          ? row.brandTemplete.map((item) => item?.templateName).join(", ")
          : "-",
        }));
        
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, exportData });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });
          
          const blobURL = window.URL.createObjectURL(blob);
          
          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();
          
          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = exportData.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });
          
          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = exportData.map((row) => {
            return [row["Name"], row["Status"], row["brand templates"]];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    };
    
    useEffect(() => {
      const cancelTokenSources = [];
      const getData = async () => {
        cancelTokenSources.forEach((source) => {
          source.cancel('New request made');
        });
        
        const source = axios.CancelToken?.source();
        cancelTokenSources.push(source);
        try {
          const data = await GetNetsuiteItemsCancelToken('InvtPart',source.token);
          if (data.status === 200) {
            setNetsuiteList(
              data?.data?.data?.items
              ? data?.data?.data?.items?.map((el) => { return { "label": el?.displayname, "value": el?.id } })
              : []
            );
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.log('error :', error);
          }
        }
      };
      
      getData();
      
      return () => {
        cancelTokenSources.forEach((source) => {
          source.cancel('Component unmounted');
        });
      };
    }, []);
    
    useEffect(() => {
      formik.setFieldValue("laptop_detail_page_id",assignedPopupPages?.PageID || 0)
    },[assignedPopupPages])
    
    useEffect(
      () => {
        // formik.setFieldValue("laptop_detail_page_id",assignedPopupPages.pa);
        setAssignedPopupPages(brandData?.assignedPage)
      },[brandData]
    )
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          const res = await getBrandTemplate(subId);
          const data = res.data?.getLaptopPricingData;
          setBrandData(data);
          setEditorStates({
            core_specifications: data?.core_specifications || "",
            med_specifications: data?.med_specifications || "",
            high_specifications: data?.high_specifications || "",
            disclaimer_tc: data?.disclaimer_tc || ""
          });
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };
      if (subId) {
        fetchData();
      }
    }, [subId]);
    
    const generateInitialValues = (data) => ({
      core_laptop_model: data?.core_laptop_model || "",
      core_title: data?.core_title || "",
      core_fee: data?.core_fee || 0,
      core_dep: data?.core_dep || 0,
      core_short_dec: data?.core_short_dec || "",
      core_specifications: data?.core_specifications || "",
      core_image: data?.core_image || "",
      med_laptop_model: data?.med_laptop_model || "",
      med_title: data?.med_title || "",
      med_fee: data?.med_fee || 0,
      med_dep: data?.med_dep || 0,
      med_short_dec: data?.med_short_dec || "",
      med_specifications: data?.med_specifications || "",
      med_image: data?.med_image || "",
      high_laptop_model: data?.high_laptop_model || "",
      high_title: data?.high_title || "",
      high_fee: data?.high_fee || 0,
      high_dep: data?.high_dep || 0,
      high_short_dec: data?.high_short_dec || "",
      high_specifications: data?.high_specifications || "",
      high_image: data?.high_image || "",
      disclaimer_tc: data?.disclaimer_tc || "",
      core_netsuite_product_id: data?.core_netsuite_product_id || "",
      med_netsuite_product_id: data?.med_netsuite_product_id || "",
      high_netsuite_product_id: data?.high_netsuite_product_id || "",
      laptop_detail_page_id : data?.laptop_detail_page_id || 0,
    });
    
    const validationSchema = Yup.object({
      core_laptop_model: Yup.string().required("Laptop Model is required").nullable().trim(),
      core_title: Yup.string().required("Laptop Title is required").nullable().trim(),
      core_fee: Yup.number()
      .min(1, 'The minimum fee is 1')
      .max(999999, 'The maximum fee is 999999')
      .required("Laptop Fee is required"),
      core_dep: Yup.number()
      .min(1, 'The minimum deposit is 1')
      .max(999999, 'The maximum deposit is 999999')
      .required("Laptop Deposit is required"),
      core_short_dec: Yup.string().required("Short Description is required").nullable().trim(),
      core_specifications: Yup.string().required("Specifications are required"),
      // core_image: Yup.string().required("Image is required"),
      med_laptop_model: Yup.string().required("Laptop Model is required").nullable().trim(),
      med_title: Yup.string().required("Laptop Title is required").nullable().trim(),
      med_fee: Yup.number()
      .min(1, 'The minimum fee is 1')
      .max(999999, 'The maximum fee is 999999')
      .required("Laptop Fee is required"),
      med_dep: Yup.number()
      .min(1, 'The minimum deposit is 1')
      .max(999999, 'The maximum deposit is 999999')
      .required("Laptop Deposit is required"),
      med_short_dec: Yup.string().required("Short Description is required").nullable().trim(),
      med_specifications: Yup.string().required("Specifications are required"),
      // med_image: Yup.string().required("Image is required"),
      high_laptop_model: Yup.string().required("Laptop Model is required").nullable().trim(),
      high_title: Yup.string().required("Laptop Title is required").nullable().trim(),
      high_fee: Yup.number()
      .min(1, 'The minimum fee is 1')
      .max(999999, 'The maximum fee is 999999')
      .required("Laptop Fee is required"),
      high_dep: Yup.number()
      .min(1, 'The minimum deposit is 1')
      .max(999999, 'The maximum deposit is 999999')
      .required("Laptop Deposit is required"),
      high_short_dec: Yup.string().required("Short Description is required").nullable().trim(),
      high_specifications: Yup.string().required("Specifications are required"),
      // high_image: Yup.string().required("Image is required"),
      disclaimer_tc: Yup.string().required("Terms and Conditions are required"),
      core_netsuite_product_id: Yup.string().required("Core NetSuite Product is required"),
      med_netsuite_product_id: Yup.string().required("Medium NetSuite Product is required"),
      high_netsuite_product_id: Yup.string().required("High NetSuite Product is required"),
    });
    
    
    
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: generateInitialValues(brandData),
      validationSchema,
      onSubmit: async (values, { setSubmitting }) => {
        try {
          const formData = new FormData();
          Object.entries(values).forEach(([key, value]) => {
            formData.append(key, value);
          });
          
          if (subType === "update") {
            formData.append("brand_Id", subId);
            // formData.append("laptop_detail_page_id",assignedPopupPages?.id)
            await updateLaptopPricing(formData);
            Swal.fire("Success!", "Laptop Pricing updated successfully!", "success");
          }
          // history.push("/websiteManagement/siteSetup/brand_Templates");
        } catch (error) {
          Swal.fire("Error!", `Failed to update Laptop Pricing`, "error");
        } finally {
          setSubmitting(false);
        }
      }
    });
    useEffect(() => {
      console.log("))))))))))))))))))))))))))))))))))",formik.values.laptop_detail_page_id);
    },[formik.values])
    
    const handleEditorChange = (key, value) => {
      setEditorStates((prevStates) => ({
        ...prevStates,
        [key]: value
      }));
      formik.setFieldValue(key, value);
    };
    
    const renderSection = useCallback(
      (section, label, index) => (
        <>
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header"><FontAwesomeIcon icon={faInfoCircle} color="var(--topbar-color)" /> {label} Specification Details</h4>
        </div>
        </div>
        <FormField field={{ name: `${section}_laptop_model`, label: "Laptop Model" }} formik={formik} col_md={3} col_lg={3} />
        <FormField field={{ name: `${section}_title`, label: "Laptop Title" }} formik={formik} col_md={3} col_lg={3} />
        <FormField field={{ name: `${section}_netsuite_product_id`, label: "NetSuite Product" }} selectOptions={netsuiteList} formik={formik} col_md={3} col_lg={3} />
        <FormField field={{ name: `${section}_fee`, label: "Laptop Fee", type: "number", sign: true }} formik={formik} col_md={3} col_lg={2} />
        <FormField field={{ name: `${section}_dep`, label: "Laptop Deposit", type: "number", sign: true }} formik={formik} col_md={3} col_lg={2} />
        <FileUploadField name={`${section}_image`} label="Image" formik={formik} accept=".png,.jpg,.jpeg" col_md={3} col_lg={2} />
        <FormField field={{ name: `${section}_short_dec`, label: "Short Description" }} formik={formik} col_md={12} col_lg={12} />
        {loading ? "" : <div className="col-md-12">
          <label htmlFor={`${section}_specifications`}>Specifications *</label>
          <div className="form-icon-group mb-6 laptop-pricing">
          <HtmlInputEditor
          editorState={editorStates[`${section}_specifications`]}
          setEditorState={(value) => handleEditorChange(`${section}_specifications`, value)}
          isInValid={formik.errors[`${section}_specifications`]}
          isCKEditor={true}
          hideSign={true}
          />
          </div>
          </div>
        }
        </>
      ),
      [formik, editorStates]
    );
    useEffect(() => {
      const payload = {
        // page:assignPageTableState.page,
        // perPage:assignPageTableState.perPage,
        // search:assignPageSearch,
        // key:assignPageTableState.sortKey,
        // sort:assignPageTableState.sortOrder,
        exportStatus: "true",
        // setSearch:setAssignPageSearch,
        // viaBrand:pageBrand.arr,
        // viaStatus:pageStatus.arr,
      };
      
      GetPagesList(payload)
      .then((res) => {
        if (res.status == 200) {
          setAllPageListData(res.data?.Data);
        }
      })
      .catch((err) => console.log(err));
    }, []);
    
    return (
      <div className="card card-body-inr">
      <FormikProvider value={formik}>
      <h4 className="fw-bold mb-4">Laptop Pricing</h4>  
      <Form onSubmit={formik.handleSubmit}>
      <div className="row">
      <div className="col-md-12 flex-column mb-3">
      <label className="d-block" htmlFor="">Laptop Pricing & Details Page <FontAwesomeIcon icon={faInfoCircle} color="var(--topbar-color)" /></label>
      <div className="d-flex">
      {(
        <div className="d-flex align-items-start flex-column">
        
        <button
        className={
          "btn btn-save btn-success" +
          (formik.errors.laptop_detail_page_id &&
            formik.touched.laptop_detail_page_id
            ? " file-req is-invalid"
            : "")
          }
          type="button"
          title="Assign Page"
          data-toggle="modal"
          data-target="#assignPagePopup"
          >
          <i className="fal fa-plus"></i> Assign Page
          </button>
          {formik.values.laptop_detail_page_id
            ? allpageListData
            ?.filter(
              (page) =>
                page.PageID ===
              formik.values.laptop_detail_page_id
            )
            .map((assignedPage) => (
              <div
              className="frm-group w-100"
              key={assignedPage.PageID}
              >
              <ul className="list-unstyled attached-file-ul m-0">
              <li>
              <a
              target="_blank"
              href={`/website_management/pages/staticpages/table/update/${assignedPage.PageID}`}
              >
              <span
              className="textLimit100 as-text-blue w-100"
              title={assignedPage.PageTitle}
              >
              {TrimText(assignedPage.PageTitle, 30)}
              </span>
              </a>
              <button
              className="btn btn-danger rounded-circle btn-dropdown-item"
              title="Delete"
              onClick={() => {
                formik.setFieldValue(
                  "laptop_detail_page_id",
                  0
                );
              }}
              >
              <i className="fal fa-trash-alt"></i>
              </button>
              </li>
              </ul>
              </div>
            ))
            : null}
            </div>)}
            </div>
            </div>
            {renderSection("core", "Core", 1)}
            {renderSection("med", "Medium", 2)}
            {renderSection("high", "High", 3)}
            <div className="col-md-12">
            <label htmlFor={"disclaimer_tc"}>Disclaimer/Terms & Conditions*</label>
            <div className="form-icon-group mb-6 laptop-pricing">
            <HtmlInputEditor
            editorState={editorStates.disclaimer_tc}
            setEditorState={(value) => handleEditorChange("disclaimer_tc", value)}
            isInValid={formik.errors.disclaimer_tc}
            isCKEditor={true}
            hideSign={true}
            />
            </div>
            </div>
            </div>
            <div className="form-group form-group-save-cancel">
            <button className="btn btn-save btn-success" disabled={formik.isSubmitting} type="submit" title="Save">
            {formik.isSubmitting ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fal fa-save"></i>
            )} Save
            </button>
            {Object.keys(formik.values).map((key) => {
              if (formik.touched[key] && formik.errors[key]) {
                return (
                  <div className="invalid-feedback d-block">
                  {formik.errors[key]}
                  </div>
                );
              }
            })}
            </div>
            </Form>
            </FormikProvider>
            <div
            className="topic-add-modal modal able_modal01 fade"
            id="assignPagePopup"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            >
            <div
            className="modal-dialog modal-dialog-centered available-assessment-modal"
            role="document"
            >
            <div className="modal-content">
            <div className="modal-header modal-header-custom">
            <h5 className="modal-title">
            <i className="fal fa-plus"></i> Assign Page
            </h5>
            <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            >
            <span aria-hidden="true">×</span>
            </button>
            </div>
            <div className="modal-body">
            <DataTableComponent
            data={assignPageList}
            loading={assignPageLoading}
            state={assignPageTableState}
            setState={setAssignPageTableState}
            setSearch={setAssignPageSearch}
            totalRows={assignPageTotalRows}
            columns={assignPageColumn}
            exportFunction={exportAssignPageData}
            exportFileName={"Assign_Page"}
            isInsidePopUp={true}
            filters={[
              {
                filterName: "Status",
                optionArr: PageFilterData.status,
                state: pageStatus,
                setState: setPageStatus,
                renderLabelFunction: RenderPMCPageStatus,
              },
              
              {
                filterName: "Brand Templates",
                optionArr: PageFilterData.BrandList,
                state: pageBrand,
                isOptionReversed: false,
                stopOptionSorting: true,
                setState: setPageBrand,
                // renderLabelFunction: RenderPageBrands,
              },
            ]}
            />
            </div>
            </div>
            </div>
            </div>
            </div>
          );
        };
        
        export default LaptopPricing;
        