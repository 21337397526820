import React from "react";
import {useParams} from "react-router";
import { GetStudentAuditFilters, GetStudentAuditTrailData } from "../../../../../services/StudentsSettingServices";
import AuditTrailsTable from "../../../../common/AuditTrailsTable";
import { RenderStudentResourceType } from "../../../../../utils/CommonGroupingItem";

function EmployeeAuditTrail() {
  const { id } = useParams();

  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]
  
  return (
    <AuditTrailsTable 
    apiFunction={GetStudentAuditTrailData}
    filterArrApi={GetStudentAuditFilters}
    columnsToShow={columnsToShow}
    exportFileName={"Employee_Audit_Trail"}
    isResourceNameShown={true}
    apiParams={{action_id: id}}
    actionId={id}
    isShowingResourceTypeFilter
    renderResourceTypeFunction={RenderStudentResourceType}
    />
  )  
}

export default EmployeeAuditTrail;
