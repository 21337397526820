import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import DataTableComponent from './DataTableComponent'
import { TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_TIME_FORMAT } from '../../utils/Constants';
import { TrimText } from './TrimText';
import { RenderAuditTrailActionType, RenderCourseResourceType } from '../../utils/CommonGroupingItem';
import UserColumn from './UserColumn';
import axios from 'axios';
import { capitalize } from '../../utils/commonFunction';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import HtmlParser from 'react-html-parser';

function AuditTrailsTable({ apiFunction = () => { }, filterArrApi = () => { }, columnsToShow = [], actionId, apiParams, exportFileName = "", resourceNameRedirect = () => `/`, isResourceNameShown = false, isShowingResourceTypeFilter = false, renderResourceTypeFunction }) {
    // apiFunction :: this includes just the name of the function that is created in services function for api call
    // filterArrApi :: this includes just the name of the function that is created in services function for filters list api
    // columnsToShow :: this should be an array of string, these strings should be name of columns which need to be showen in table
    // actionId :: this is id for getting single record in api
    // apiParams :: this is an object that has parameters related to action id and parent action id for list api 
    // exportFileName :: this is the file name for file exported
    // resourceNameRedirect :: it is a function to redirect to particular resource of that audit trail
    // isResourceNameShown :: this is a boolean value to show resource name in every condition or this can also be used as forcefully showing resourceName in any case
    // isShowingResourceTypeFilter :: Boolean Value for showing Type Filter
    // renderResourceTypeFunction :: Render function for resource type with HTML
    const {tab, type} = useParams()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const [yearList, setYearList] = useState([]);
    const [monthList, setMonthList] = useState([]);
    const [actionTypeList, setActionTypeList] = useState([]);
    const [resourceTypeList, setResourceTypeList] = useState([]);
    const [years, setYears] = useState({ arr: [], checkObj: {} });
    const [months, setMonths] = useState({ arr: [], checkObj: {} });
    const [actionType, setActionType] = useState({ arr: [], checkObj: {} });
    const [resourceType, setResourceType] = useState({ arr: [], checkObj: {} })
    const [search, setSearch] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [tableState, setTableState] = useState({
        perPage: 10,
        page: 1,
        sortOrder: "DESC",
        sortKey: "created_at"
    })

    useEffect(() => {
        const cancelTokenSources = [];

        const getData = async () => {
            setLoading(true)

        cancelTokenSources.forEach(source => {
            source.cancel('New request made');
        });
          
        const source = axios.CancelToken.source();
        cancelTokenSources.push(source);

        let data = {
            viaActionType: actionType.arr,
            resource_type:resourceType.arr,
            viaYears: years.arr,
            viaMonths: months.arr,
            page: tableState.page,
            limit: tableState.perPage,
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            search: search,
            exportStatus: "false",
            ...apiParams
        }
        if(tab == "shortCourseAuditTrail"){
            data.type = "1"
        }
        else if(tab == "auditTrail" && type == "open"){
            data.type = "0"
        }

        try {
            const res = await apiFunction(data, source.token);
            setTotalRows(res.data.total)
            fixDataFormat(res.data.data)
            if (res.status === 200) {
            setLoading(false)
            }
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.error(error);
              setLoading(false);
            }
          }
        }

        getData();

        return () => {
            cancelTokenSources.forEach(source => {
              source.cancel('Component unmounted');
            });
          };
    }, [tableState, search, actionType, years, months, resourceType])

    useEffect(() => {
        if (actionId && !isResourceNameShown) {
            columnsToShow.filter(item => item !== "Resource Name")
        }
        // filterArrApi().then(res => {
        const dropdownSource = axios.CancelToken.source();
        const fetchData = async () => {
            try {
                const payload={
                 ...apiParams
                //  actionId : actionId ? apiParams : {}, 
                }
                if(tab == "shortCourseAuditTrail"){
                    payload.type = "1"
                }
                else if(tab == "auditTrail" && type == "open"){
                    payload.type = "0"
                }

              const res = await filterArrApi(payload,dropdownSource.token);
              setYearList(res.data.getYears ? res.data.getYears : [])
              setMonthList(res.data.getMonths ? res.data.getMonths : [])
              setActionTypeList(res.data.getActionType ? res.data.getActionType : [])
              setResourceTypeList(res.data.getResourceType ? res.data.getResourceType : []);
            } catch (error) {
              if (!axios.isCancel(error)) {
                console.error(error);
              }
            }
          };
        
          fetchData();
        
          return () => {
            dropdownSource.cancel('Component unmounted');
          };    
    }, [])

    const fixDataFormat = (arr) => {
        let tempArr = [];
        arr.map((item) => {
            try {
                let singleItem = {
                    ...item,
                    description: JSON.parse(item.description),
                    oldValue: item.old_data ? JSON.parse(item.old_data) : {},
                    newValue: item.new_data ? JSON.parse(item.new_data) : {}


                };
                tempArr.push(singleItem);
            } catch (err) {
                if ("Unterminated string") {
                    console.log(err)
                }
            }
        });
        // console.log("new Array", tempArr)
        setData(tempArr);
    };

    const columns = useMemo(() => [
        {
            name: "User",
            selector: "user",
            sortField: "authFullName",
            sortable: true,
            cell: (row) => (
                <UserColumn
                    firstName={row?.Firstname || "NA"}
                    lastName={row?.Lastname || "NA"}
                    userId={row?.auth_id}
                    imgUrl={row?.picture_me}
                    userActiveStatus={row?.status}
                    mobile={row?.Mobile}
                    email={row?.Email}
                    isPopUpRight={true}
                />
            ),
        },
        columnsToShow.includes("Action Type") && {
            name: "Action Type",
            selector: "action_type",
            sortField: "action_type",
            sortable: true,
            cell: (row) => RenderAuditTrailActionType(row.action_type).html,
        },
        columnsToShow.includes("Resource Type") && {
            name: "Resource Type",
            selector: "resource_type",
            sortField: "resource_type",
            sortable: true,
            cell: (row) => (
                <span title={row.resource_type ? renderResourceTypeFunction ?  renderResourceTypeFunction(row.resource_type).html : capitalize(row.resource_type) : "N/A"}>

                {row.resource_type ? renderResourceTypeFunction ?  renderResourceTypeFunction(row.resource_type).html : capitalize(row.resource_type) : "N/A"}
                </span>
            )
        },
        (isResourceNameShown || (columnsToShow.includes("Resource Name") && !actionId)) ? {
            name: "Resource Name",
            selector: "resource_name",
            sortField: "resource_name",
            sortable: true,
            cell: (row) => {
                return row.resource_name ? (
                    <span className="overflow-ellipsis2"  title={row.resource_name ? row.resource_name.replace(/<[^>]+>/g, '') : ''}>
                        {row.action_type === "Deleted" ?
                            HtmlParser(row.resource_name) : 
                            <Link to={row?.resource_link && row?.resource_link !== ""  ? row?.resource_link?.startsWith("/") ? row?.resource_link : `/${row?.resource_link}` : resourceNameRedirect(row?.action_id, row?.resource_type, { course_id: row?.course_id })} className="as-text-blue curser">{HtmlParser(row?.resource_name)}</Link>
                        }
                    </span>
                ) : (
                    "N/A"
                )
            },
        } : false,
        {
            name: "Old Value",
            cell: (row) => {
                return <span title={JSON.stringify(row.oldValue || "")}>{printObjValues(row.oldValue || "", 0)}</span>
            }
        },
        {
            name: "New Value",
            cell: (row) => {
                return <span title={JSON.stringify(row.newValue || "")}>{printObjValues(row.newValue || "", 0)}</span>
            }
        },
        {
            name: "Date",
            sortable: true,
            selector: "created_at",
            sortField: "created_at",
            cell: (row) => {
                return row.created_at ? (
                    <>
                    <span>

                        <p title={moment(row.created_at, "YYYY-MM-DD HH:mm:ss").format(TABLE_DATE_FORMAT + ",")}>{moment(row.created_at, "YYYY-MM-DD HH:mm:ss").format(TABLE_DATE_FORMAT + ",")}</p>
                        <p title={moment(row.created_at, "YYYY-MM-DD HH:mm:ss").format(TABLE_TIME_FORMAT)}>{moment(row.created_at, "YYYY-MM-DD HH:mm:ss").format(TABLE_TIME_FORMAT)}</p>
                    </span>
                    </>
                ) : (
                    "NA"
                );
            },
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="assessment-08">
                    <div className="as-buttons">
                        <button
                            className="btn btn-primary rounded-circle"
                            title="Open"
                            onClick={() => logPop(row.description)}
                        >
                            <i className="fal fa-folder-open"></i>
                        </button>
                    </div>
                </div>
            ),
        },
    ]);

    const printObjValues = (recievedValue, count) => {
        let tempCount = count
        if (tempCount > 2) {
            return ""
        }
        let obj = new Object(recievedValue);
        if (obj && !Object.keys(obj || {}).length && obj.constructor === Object) {
            return [<>N/A <br /> </>]
        }
        return obj ? Object.keys(obj || {}).map((value, index) => {
            if (index < 2 && tempCount < 2) {
                let itemDetail = checkTypeCondition(obj[value])
                if (itemDetail.type === "object") {
                    if (Array.isArray(itemDetail.value)) {
                        tempCount += 1
                        let arr = printArrayValue(itemDetail.value, value, tempCount)
                        if (tempCount === 2) {
                            arr.length = 1
                        }
                        return arr.map((val, i) => <React.Fragment key={i}>{val}</React.Fragment>)
                    } else {
                        tempCount += 1
                        let anotherObj = printObjValues(itemDetail.value, tempCount)
                        return anotherObj ? Object.keys(anotherObj || {}).map(k => <React.Fragment key={k}>{anotherObj[k]}</React.Fragment>) : "N/A"
                    }
                }else{
                    tempCount += 1
                    return <React.Fragment key={index}>
                        {TrimText(`${value}: ${itemDetail.value}${index ? "" : ","}`, 30)}
                        <br />
                    </React.Fragment>
                }
            } else {
                return ""
            }
        }) : <>N/A <br /></>
    }

    const checkTypeCondition = (value) => {
        let itemType
        try {
            itemType = JSON.parse(value)
        } catch (error) {
            itemType = value
        }
        return { type: typeof itemType, value: itemType }
    }

    const printArrayValue = (arr, arrName, count) => {
        return arr.map((item, index) => {
            if (index < 2 && count < 2) {
                let itemDetail = checkTypeCondition(item);
                if (itemDetail.type === "object") {
                    if (Array.isArray(itemDetail.value)) {
                        count+=1;
                        return printArrayValue(itemDetail.value, "", count)
                    } else {
                        return printObjValues(itemDetail.value, count)
                    }
                }
                count += 1
                return <React.Fragment key={index}>
                    {`${arrName}[${index}]: ${itemDetail.value}${index ? "" : ","}`}
                    <br />
                </React.Fragment>
            } else {
                return ""
            }
        })
    }

    const logPop = (data) => {
        let finalData = data;
        if (
            Object.keys(finalData.object.definition.Old).length &&
            finalData.object.definition.Old.start
        ) {
            // check that there is object named Old and it has key named start now updating it
            finalData.object.definition.Old.start =
                finalData.object.definition.Old.start.toString().length == 10
                    ? moment
                        .unix(finalData.object.definition.Old.start)
                        .format(TABLE_DATE_TIME_FORMAT)
                    : moment(finalData.object.definition.Old.start).format(
                        TABLE_DATE_TIME_FORMAT
                    );
        }
        if (
            Object.keys(finalData.object.definition.Old).length &&
            finalData.object.definition.Old.end
        ) {
            // check that there is object named Old and it has key named end now updating it
            finalData.object.definition.Old.end =
                finalData.object.definition.Old.end.toString().length == 10
                    ? moment
                        .unix(finalData.object.definition.Old.end)
                        .format(TABLE_DATE_TIME_FORMAT)
                    : moment(finalData.object.definition.Old.end).format(
                        TABLE_DATE_TIME_FORMAT
                    );
        }
        if (
            Object.keys(finalData.object.definition.New).length &&
            finalData.object.definition.New.start
        ) {
            // check that there is object named New and it has key named start now updating it
            finalData.object.definition.New.start =
                finalData.object.definition.New.start.toString().length == 10
                    ? moment
                        .unix(finalData.object.definition.New.start)
                        .format(TABLE_DATE_TIME_FORMAT)
                    : moment(finalData.object.definition.New.start).format(
                        TABLE_DATE_TIME_FORMAT
                    );
        }
        if (
            Object.keys(finalData.object.definition.New).length &&
            finalData.object.definition.New.end
        ) {
            // check that there is object named Old and it has key named start now updating it
            finalData.object.definition.New.end =
                finalData.object.definition.New.end.toString().length == 10
                    ? moment
                        .unix(finalData.object.definition.New.end)
                        .format(TABLE_DATE_TIME_FORMAT)
                    : moment(finalData.object.definition.New.end).format(
                        TABLE_DATE_TIME_FORMAT
                    );
        }
        //Updating TimeStamp
        finalData.timestamp = moment(finalData.timestamp).format(
            TABLE_DATE_TIME_FORMAT
        );
        //Now printing updated Value
        Swal.fire({
            html: `<div class="audit-trial-json"><pre>${JSON.stringify(
                finalData,
                undefined,
                1
            )}</pre></div>`,
        });
    };

    const exportData = (fileType, fileName) => {
        if (actionId && !isResourceNameShown) {
            columnsToShow = columnsToShow.filter(item => item !== "Resource Name")
        }
        const header = ["User", ...columnsToShow, "Old Value", "New Value", "Date"];
        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });
        let data = {
            viaActionType: actionType.arr,
            resource_type: resourceType.arr,
            viaYears: years.arr,
            viaMonths: months.arr,
            page: tableState.page,
            limit: tableState.perPage,
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            search: search,
            exportStatus: "true",
            ...apiParams
        }
        // if (actionId) {
        //     data.action_id = actionId
        //     data.actionColName = "action_id"
        // }
        apiFunction(data).then(res => {
            let data = res.data;
            data = data?.map((row) => {
                let obj = {
                    User: row.authFullName ? row.authFullName : "-",
                    "Action Type": row.action_type ? RenderAuditTrailActionType(row.action_type).text : "-",
                    "Resource Type": row?.resource_type ? renderResourceTypeFunction ?  renderResourceTypeFunction(row?.resource_type).text : capitalize(row.resource_type) : "-",
                    "Resource Name": row?.resource_name ? row?.resource_name : "-",
                    "Old Value": row.old_data ? row.old_data : "-",
                    "New Value": row.new_data ? row.new_data : "-",
                    Date: row.created_at ? moment(row.created_at).format(TABLE_DATE_TIME_FORMAT) : "-",
                }
                if (actionId && !isResourceNameShown) {
                    delete obj['Resource Name']
                }
                return obj
            });

            if (fileType === "csv") {
                const csvString = Papa.unparse({ fields: header, data });
                const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

                const blobURL = window.URL.createObjectURL(blob);

                // Create new tag for download file
                const anchor = document.createElement("a");
                anchor.download = fileName;
                anchor.href = blobURL;
                anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
                anchor.click();

                // Remove URL.createObjectURL. The browser should not save the reference to the file.
                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    URL.revokeObjectURL(blobURL);
                }, 1000);
                Swal.close();

            } else if (fileType === "xlsx") {
                const compatibleData = data.map((row) => {
                    const obj = {};
                    header.map((col, index) => {
                        obj[col] = row[col];
                    });
                    return obj;
                });

                let wb = XLSX.utils.book_new();
                let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                    header,
                });
                XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                XLSX.writeFile(wb, `${fileName}.xlsx`);
                Swal.close();
                return false;
            }
            if (fileType === "pdf") {
                const compatibleData = data.map(item => Object.values(item));
                const doc = new JsPDF();
                doc.autoTable({
                    head: [header],
                    body: compatibleData,
                    columnStyles: actionId ? {
                        0: { columnWidth: 20 },
                        1: { columnWidth: 24 },
                        2: { columnWidth: 24 },
                        3: { columnWidth: 45 },
                        4: { columnWidth: 45 },
                        5: { columnWidth: 20 },
                    } : {
                        0: { columnWidth: 20 },
                        1: { columnWidth: 20 },
                        2: { columnWidth: 20 },
                        3: { columnWidth: 20 },
                        4: { columnWidth: 40 },
                        5: { columnWidth: 40 },
                        6: { columnWidth: 20 },
                    },
                    styles: {
                        minCellHeight: 10,
                        minCellWidth: 5,
                        halign: "left",
                        fontSize: 8,
                    },
                });
                doc.save(`${fileName}.pdf`);
                Swal.close();
                return false;
            }
        }).catch((err) => { console.log(err); Swal.close() })
    };

    return (
        <DataTableComponent
            data={data}
            // this filter is to remove false values from columns array which are because that perticular column is not available in columnToShow array
            columns={columns.filter(item => item)}
            loading={loading}
            setSearch={setSearch}
            state={tableState}
            setState={setTableState}
            totalRows={totalRows}
            exportFunction={exportData}
            exportFileName={exportFileName}
            filters={[
                {
                    filterName: "Action Type",
                    state: actionType,
                    setState: setActionType,
                    optionArr: actionTypeList,
                    renderLabelFunction: RenderAuditTrailActionType
                },
                {
                    filterName: "Months",
                    state: months,
                    setState: setMonths,
                    optionArr: monthList,
                    stopOptionSorting: true
                },
                {
                    filterName: "Years",
                    state: years,
                    setState: setYears,
                    optionArr: yearList
                },
                isShowingResourceTypeFilter && {
                    filterName: "Resource Type",
                    state: resourceType,
                    setState: setResourceType,
                    optionArr: resourceTypeList,
                    renderLabelFunction: renderResourceTypeFunction
                },
            ].filter(item => item)}
        />
    )
}

export default AuditTrailsTable
