const initialState = {
  quiz: new Object(),
  id: 0
};

const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_QUIZ":
      state.quiz[action.payload[0]] = action.payload[1];
      return { quiz: state.quiz };
    case "SET_QUIZ_COMPLETE":
      state.quiz = action.payload;
      return { quiz: state.quiz };
      case "SET_QUIZ_ID":
        state.id = action.payload;
        return { id: state.id };
    case "CLEAR_QUIZ":
      state.quiz = new Object();
      return { quiz: state.quiz };
    default:
      return state;
  }
};

export default quizReducer;
