import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import hasPermission from "../../../utils/hasMultiplePermission";
import Tablefilter from "../../common/Tablefilter";
import {
  RenderWebsiteBrandTemplate,
  RenderWebsitelistStatus,
} from "../../../utils/CommonStatusItems";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import {
  DeleteEventLandingPage,
  GetEventLandingPageFiltersList,
  GetEventLandingPageList,
  GetEventLandingPageListcancelToken,
} from "../../../services/EventService";
import moment from "moment";

function EventLandingPage() {
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [websiteData, setWebsiteData] = useState([]);
  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [updateData, setUpdateData] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterData, setFilterData] = useState({ status: [] });
  const history = useHistory()

  useEffect(() => {
    let response = hasPermission({
        scopes: ["wbseventlpageview"],
        permissions: givenPermsisions,
    });
    if (!response) {
        history.push("/noaccess");
    }
}, []);

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });
  useEffect(() => {
    const storedFilters = JSON.parse(
      localStorage.getItem("filterStateEventLandigPage")
    );
    if (storedFilters) {
      setStatus(storedFilters.status);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      status,
      filterData,
    };
    localStorage.setItem(
      "filterStateEventLandigPage",
      JSON.stringify(filterState)
    );
  }, [status]);
  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });
  useEffect(() => {
    const cancelTokenSources = [];
    const getWebsiteList = async () => {
      setloading(true);
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      const websiteData = {
        page: page,
        perPage: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        viaStatus: status.arr,
      };

      try {
        const res = await GetEventLandingPageListcancelToken(
          websiteData,
          source.token
        );
        setWebsiteData(res.data?.data);
        setTotalRows(res.data?.total);
        setPerPage(res.data?.perPage);
        if (res.status == 200) {
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };

    getWebsiteList();
    console.log("first",perPage)
    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [
    page,
    perPage,
    sortOrder,
    sortkey,
    search,
    status,
    searchStatusCheck,
    updateData,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await GetEventLandingPageFiltersList();

        setFilterData({
          status: res?.data?.eventLandingPagesStatusFilters,
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, []);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  const resetFilter = () => {
    setStatus({ arr: [], checkObj: {} });
    setSearchStatusCheck({});
    setSearch("");
    setDebouncedTerm("")
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  

  // const sortingName = (rowA, rowB) => {
  //   const name1 = (rowA.first_name + " " + rowA.last_name).trim().toLowerCase();
  //   const name2 = (rowB.first_name + " " + rowB.last_name).trim().toLowerCase();
  //   return name1.localeCompare(name2);
  // };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteEventLandingPage({ PageID: id })
          .then((res) => {
            Swal.fire("Deleted!", "Website has been deleted.", "success");
            setUpdateData(!updateData);
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "PageTitle",
      sortField: "PageTitle",
      sortable: true,
      minWidth: "290px",
      cell: (row) => (
        <>
          <div className="assigned-title-block-new">
            {/* <div className="assigned-title-itm"> */}
            <PermissionsGate scopes={['wbseventlpageedit']} RenderError={() => (<p>{row?.PageTitle}</p>)}>

            <Link
              className="as-text-blue curser feature-name"
              to={`/websiteManagement/events/landing_page/page_details/open/${row.PageID}`}
              title={row.PageTitle}
              >
              <span className="textLimit200">{row.PageTitle}</span>
            </Link>
            </PermissionsGate>
            {/* </div> */}
          </div>
        </>
      ),
    },

    {
      name: "Status",
      selector: "IsPublished",
      sortField: "IsPublished",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) =>
        row.IsPublished != null && row.IsPublished != undefined
          ? RenderWebsitelistStatus(row.IsPublished).html
          : "-",
    },
    {
      name: "Brand Template",
      selector: "brandTemplete",
      sortField: "brandTemplete",
      sortable: false,
      maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => {
        if (row.brandTemplete.length) {
          return (
            <>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {row.brandTemplete.map((d, i) => (
                  <React.Fragment key={i}>
                    {RenderWebsiteBrandTemplate(d?.templateName).html}
                  </React.Fragment>
                ))}
              </div>
            </>
          );
        } else {
          return "-";
        }
      },
    },
    {
      name: "Last Updated",
      selector: "LastUpdateTimestamp",
      sortField: "LastUpdateTimestamp",
      sortable: true,
      cell: (row) => {
        let dateString = row.LastUpdateTimestamp;
        let dateObj = new Date(dateString);
        let day = ("0" + dateObj.getDate()).slice(-2);
        let monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let month = monthNames[dateObj.getMonth()];
        let year = ("" + dateObj.getFullYear()).slice(-2);
        let formattedDate = `${day} ${month} ${year}`;
        return formattedDate;
      },
    },
    {
      name: "Upcoming Events",
      selector: "active_events",
      sortField: "active_events",
      sortable: true,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className="textLimit100">{row.active_events}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <PermissionsGate scopes={['wbseventlpageedit']} RenderError={() => (
              <button className="btn btn-primary rounded-circle" disabled>
                <i className="fal fa-folder-open"></i>
              </button>
            )}>

            <Link
              className="btn btn-primary rounded-circle"
              to={`/websiteManagement/events/landing_page/page_details/update/${row.PageID}`}
              title="Open"
              >
              <i className="fal fa-folder-open"></i>
            </Link>
            </PermissionsGate>
            <PermissionsGate scopes={['wbseventlpagedelete']} RenderError={() => (
              <button className="btn btn-danger rounded-circle" disabled>
                <i className="fal fa-trash-alt"></i>
              </button>
            )}>


            <button
              className="btn btn-danger rounded-circle"
              onClick={() => handleDelete(row?.PageID)}
              >
              <i className="fal fa-trash-alt"></i>
            </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = [
      "Name",
      "Status",
      "Brand Template",
      "Last Update",
      "Upcoming Events",
    ];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const websiteData = {
      page: page,
      perPage: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      viaStatus: status.arr,
      exportStatus: "true",
    };

    GetEventLandingPageList(websiteData)
      .then((res) => {
        data = res?.data;
        data = data?.map((row) => {
          return {
            ...row,
            Name: row?.PageTitle || "-",
            Status: RenderWebsitelistStatus(row.IsPublished).text || "-",
            // "Brand Template": row?.brandTemplete || "-",
            "Brand Template":
              row?.brandTemplete?.map((d, i) => d?.templateName).join(",") ||
              "-",
            "Last Update":
              moment(row?.LastUpdateTimestamp)?.format("DD MMM YY HH:mm") ||
              "-",
            "Upcoming Events": row?.active_events || "-",
          };
        });

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data?.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data?.map((row) => {
            let dateString = row.LastUpdateTimestamp;
            let dateObj = new Date(dateString);
            let formattedDate = moment(dateObj)?.format("DD MMM YY HH:mm");;

            return [
              row?.PageTitle,
              RenderWebsitelistStatus(row?.IsPublished).text,
              row?.brandTemplete?.map((d, i) => d?.templateName).join(",") ||
                "-",
              formattedDate,
              row?.active_events,
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [debouncedTerm]);

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom program-table">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={debouncedTerm}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner filter-custom-new`}>
                    <Tablefilter
                      filterName="Status"
                      optionArr={filterData?.status}
                      state={status}
                      setState={setStatus}
                      stopOptionSorting={true}
                      isOptionReversed={false}
                      renderLabelFunction={RenderWebsitelistStatus}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Event_landingPage");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Event_landingPage");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Event_landingPage");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate
                  RenderError={() => (
                    <button
                      type="button"
                      className="btn btn-primary"
                      title="Create new"
                      disabled
                    >
                      <i className="fal fa-plus"></i>Create New
                    </button>
                  )}
                  scopes={["wbseventlpageadd"]}
                >
                  <Link
                    to={`/websiteManagement/events/landing_page/page_details/create`}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      title="Create new"
                    >
                      <i className="fal fa-plus"></i>Create New
                    </button>
                  </Link>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          paginationDefaultPage={page}
          progressPending={loading}
          data={websiteData}
          progressComponent={<SkeletonTicketList />}
          defaultSortField={sortkey}
          defaultSortAsc={false}
          columns={columns}
          pagination={true}
          // noDataComponent={Str.noRecord}
          onSort={handleSort}
          sortServer
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
    </div>
  );
}

export default EventLandingPage;
