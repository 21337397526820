
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ListOfTemplate from "../components/courses/CourseGradeSettings/ListOfTemplate";
import AddTemplate from "../components/courses/CourseGradeSettings/AddTemplate";
import AuditTrail from "../components/courses/CourseGradeSettings/AuditTrails";
import PageComponent from "../components/common/PageComponent";
import { GradeLetterTemplateDetail, GradeLetterTemplateDetailCancelToken } from "../services/GradeSettingService";
import axios from "axios";


const TemplateTable = () => {
  const [data, setData] = useState([]);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const stdName = params.get("stdName");
  const courseName = params.get("courseName");
  const { id, tab } = useParams();

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      const data = {
        id: id,
      };
      try {
        const res = await GradeLetterTemplateDetailCancelToken(data,dropdownSource.token);
        if(res.status == 200){
          setData(res?.data?.list_data[0]?.name);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
      if(id){
        fetchData();
      }
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, [id]);

  return (
    <PageComponent
      heading={tab === "grades" ? ["Gradebook:", courseName, stdName] : (tab === "create" || tab === "edit" || tab === "editAuditTrails") ? ["Grading", "Letter", "Templates", tab !== "create" ? `${data}` : "Create New Template"] : ["Grading", "Letter", "Templates"]}
      isBackButtonShown
      disableColonShown
      auditTrailUrl={tab === "edit" || tab === "editAuditTrails" ? `/courseAdministration/gradingTemplate/editAuditTrails/${id}` : `/courseAdministration/gradingTemplate/auditTrails`}
      studentGrades={tab === "grades" ? true : false}
      addTemplate={tab === "create" || tab === "edit" || tab === "editAuditTrails"}
      componentToRender={[
        {
          condition: tab === "table",
          component: <ListOfTemplate />
          // component: <StudentGrades courseName={courseName} stdName={stdName}/>
        },
        {
          condition: tab === "create",
          component: <AddTemplate />
        },
        {
          condition: tab === "edit",
          component: <AddTemplate />
        },
        {
          condition: tab === "auditTrails",
          component: <AuditTrail />
        },
        {
          condition: tab === "editAuditTrails",
          component: <AuditTrail />
        },
      ]}
      tabs={tab === "table" || tab === "auditTrails" ? [
        {
          tabName: "Grading Template",
          tabIcon: "fa-badge-check",
          url: `/courseAdministration/gradingTemplate/table`,
          condition: tab === "table"
        },
        {
          globalIconName: "audittrail",
          url: `/courseAdministration/gradingTemplate/auditTrails`,
          condition: tab === "auditTrails"
        },
      ] : tab === "edit" || tab === "editAuditTrails" ? [
        {
          globalIconName: "details",
          url: `/courseAdministration/gradingTemplate/edit/${id}`,
          condition: tab === "edit"
        },
        {
          globalIconName: "audittrail",
          url: `/courseAdministration/gradingTemplate/editAuditTrails/${id}`,
          condition: tab === "editAuditTrails"
        },
      ] : []}
    />
  );
};

export default TemplateTable;
