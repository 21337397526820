import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import { IMAGE_URL } from "../../../utils/Constants";
import { GetCampusMgtList } from "../../../services/CampusMgtService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { formatQuestion } from "../../../utils/FormatQuestion";
import { TrimText } from "../../common/TrimText";
import Swal from "sweetalert2";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";
import Tablefilter from "../../common/Tablefilter";
import PermissionsGate from "../../../utils/permissionGate";
import { ColorRender } from "../usermanagement/CheckRole";
import { InitialRender } from "../../common/Helper";
import Hover from "../../common/Hover";
import DataTableComponent from "../../common/DataTableComponent";
import { number } from "yup";
import { createGlobalStyle } from "styled-components";
import moment from "moment";
import { DeleteUser } from "../../../services/UserService";
import { useSelector } from "react-redux";
import { RenderCategory, RenderDepartment, RenderSoftphoneType } from "../../../utils/CommonGroupingItem";
import { GetCallLogsListFilters,GetCallLogList } from "../../../services/SoftphoneService";
import PopupComponent from "../../common/PopupComponent";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import hasPermission from "../../../utils/hasMultiplePermission";

const CallLogs = ({onPage}) => {
  const [dataArr, setDataArr] = useState([]);
  const {tab ,subTab, id} = useParams()
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("callLogTime");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [deleterow, setDeleteRow] = useState(false);
  const user = useSelector((state) => state.user);
  const TABLE_DATE_FORMAT = "DD MMM YY"
  const [ext, setExt] = useState({ arr: [], checkObj: {} })
  const [type, setType] = useState({ arr: [], checkObj: {} })
  const [department, setDepartment] = useState({ arr: [], checkObj: {} })
  const [category, setCategory] = useState({ arr: [], checkObj: {} })
  const [typeArr,setTypeArr] = useState([])
  const [extArr,setExtArr] = useState([])
  const [departmentArr,setDepartmentArr] = useState([])
  const [categoryArr,setCategoryArr] = useState([])
  const [message, setMessage] = useState(null)
  const history = useHistory()

  const givenPermsisions = useSelector((state) => state.givenPermission);
  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("softphoneFilters"))
    if(storedFilters){
      setExt(storedFilters?.ext)
      setType(storedFilters?.type)
      setDepartment(storedFilters?.department)
      setCategory(storedFilters?.category)
    }
  },[])

  useEffect(
    () => {
      if(onPage == "student"){
        let response = hasPermission({
          scopes: ["studentaudittrialcalllogview"],
          permissions: givenPermsisions,
        });
        if (!response) {
          history.push("/noaccess");
        }
      }
      if(onPage == "user"){
        let response = hasPermission({
          scopes: ["useraudittrialcalllogview"],
          permissions: givenPermsisions,
        });
        if (!response) {
          history.push("/noaccess");
        }
      }
    },[]
)

  useEffect(() => {
    const filterState = {
      ext,
      type,
      department,
      category,
    }
    localStorage.setItem("softphoneFilters",JSON.stringify(filterState))
  },[ext,type,department,category])

  useEffect(() => {
    const cancelTokenSources = [];

    const handleCampusList = async () => {
      setLoading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();

      cancelTokenSources.push(source);
      const cData = {
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        name: search,
        exportStatus: "false",
        type : type?.arr ? type?.arr : [],
        extension : ext?.arr ? ext?.arr : [],
        viaCategory : category?.arr ? category?.arr : [],
        viaDepartment : department?.arr? department?.arr : [],
      }
      cData[onPage == "user" ? "uid" : onPage == "student" ? "id" : null] = id ?? ""
      try {
        const res = await GetCallLogList(cData)
        if (res.status == 200) {
          setDataArr(res.data?.data_list?.data)
          setTotalRows(res.data.data_list?.total);
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    }
    handleCampusList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    }
  }, [page, perPage, sortkey, sortOrder, search,ext,type,department,category]);

  useEffect( () => {
    const getCallLogsFilter = async () => {
      let str = `${onPage == "user" ? "uid" : onPage == "student" ? "id" : null}=${id ?? ""}`
      const res = await GetCallLogsListFilters(str)
      setDepartmentArr(res.data?.departmentFilter)
      setTypeArr(res.data?.callTypeList)
      setCategoryArr(res.data?.categoryFilters)
      setExtArr(res.data?.extensionList)
    }
    getCallLogsFilter()
  },[])

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };


  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };


  const convertDurationTime = useCallback(
    (msec,createdDate) => {
      let timeZoneOffset = new Date().getTimezoneOffset();
      let startTime = (new Date(createdDate).getTime());
      let endTime = new Date(startTime + msec).getTime()
      startTime -= timeZoneOffset*60000
      endTime -= timeZoneOffset*60000
      let prettyEnd = "" , prettyStart = ""
      if(startTime && endTime){
        prettyStart = moment(startTime).format('HH:mm')
        prettyEnd = moment(endTime).format('HH:mm')
      }
      return `${prettyStart} - ${prettyEnd}`
    },[]
  )
  // const convertDurationTime = (sec,createdDate) => {
  //   console.log("convertDurationTime");
  //   let startTime = (new Date(createdDate).getTime())
  //   let endTime = new Date(startTime + sec*1000).getTime()
  //   let prettyEnd = "" , prettyStart = ""
  //   if(startTime && endTime){
  //     prettyStart = moment.utc(startTime).format('HH:mm')
  //     prettyEnd = moment.utc(endTime).format('HH:mm')
  //   }
  //   return `${prettyStart} - ${prettyEnd}`
  // }

const convertTime = useCallback(
  (sec) => {
    sec = sec/1000
    let minute = parseInt(sec/60)
    let seconds = parseInt(sec%60)

    let stringTo = minute > 0 && seconds > 0 ? `${minute}m, ${seconds}s` : minute > 0 ? `${minute}m` : `${seconds}s`
    return stringTo
  },[]
)
  // const convertTime = (sec) => {
  //   console.log("convertTime");
  //   let minute = parseInt(sec/60)
  //   let seconds = sec%60

  //   let stringTo = minute > 0 && seconds > 0 ? `${minute}m, ${seconds}s` : minute > 0 ? `${minute}m` : `${seconds}s`
  //   return stringTo
  // }

  const handleDelete = (ID) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteUser({ UserID: ID }).then((res) => setDeleteRow(!deleterow));

        Swal.fire("Deleted!", "Your user has been deleted.", "success");
      }
    });
  };

  const callDurationColumn = (duration,history) => {
    const parsedHistory = JSON.parse(history)
    let startTime = parsedHistory?.callLogInfo?.callLogStartTime
    let endTime = parsedHistory?.callLogInfo?.callLogEndTime
    return startTime && endTime ? `${startTime} - ${endTime}` : "-"
  }
  

  const columns = useMemo(() => [
    {
      name: "User",
      selector: "userName",
      sortField: "userName",
      sortable: true,
      cell: (row) => (
        <div className="assigned-title-block-new" title={row.student_name}>
          <div className="assigned-title-itm">
            <span
              className={
                "assigned-title-blk  name-icon cat-dark-red " +
                ColorRender(row.role_name)
              }
            >
              {row.picture_me ? <img src={`${IMAGE_URL}/${row.picture_me.replace("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.user_firstName, row.user_lastName])}


              <span className={`profile-box-2-status ${row.user_status}`}>
                    <i className="fas fa-circle"></i>
                </span>
              <Hover
                firstName={row.user_firstName}
                lastName={row.user_lastName}
                photo={row.picture_me}
                email={row.user_email}
                mobile={row.user_mobile}
                status={"active"}
                activity_status={row.user_status}
                right={true}
                role={"admin"}
              />
            </span> 
            <PermissionsGate scopes={['umedit']} RenderError={() => <p>{row.Firstname}</p>}>
              <span className="as-text-blue curser" onClick={() => setMessage(row?.subject)} data-toggle="modal" data-target="#subject_message">
                <span title={row.userName}>{row.userName}</span>
              </span>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
    {
      name: "Call ID",
      selector: "callid",
      sortField: "id",
      sortable: true,
      cell: (row) => (
        <span className="feature-name" title={row?.callid.toString().slice(1, -1)}>
          <span className="textLimit100">{row?.callid.toString().slice(1, -1)}</span>
        </span>
      ),
    },
    {
      name: "Extension",
      selector: "extension",
      sortField: "extension",
      sortable: true,
      cell: (row) => (
        <span className="feature-name" title={row?.extension != "" && row?.extension != null ? row?.extension : "-"}>
          <span className="textLimit100">{row?.extension != "" && row?.extension != null ? row?.extension : "-"}</span>
        </span>
      ),
    },
    {
      name: "Date",
      selector: "callLogTime",
      sortField: "callLogTime",
      sortable: true,
      cell: (row) => (
          <>
          <span className="textLimit100 mb-1" title={moment(new Date(row.callLogTime).getTime()).format(TABLE_DATE_FORMAT)}>{moment(new Date(row.callLogTime).getTime()).format(TABLE_DATE_FORMAT)}</span>
          <span className="textLimit100" title={moment(new Date(row.callLogTime).getTime()).format('HH:mm')}>{moment(new Date(row.callLogTime).getTime()).format('HH:mm')}</span>
          </>
      ),
    },
    {
      name: "Call Duration",
      selector: "duration",
      sortField: "duration",
      sortable: true,
      cell: (row) => (
        <>
        {row.duration != 0 && <span className="textLimit100 mb-1" title={callDurationColumn(row.duration,row.history)}>{callDurationColumn(row.duration,row.history)}</span>}
        <span className="textLimit100" title={convertTime(row.duration)}>{convertTime(row.duration)}</span>
        </>
      ),
    },
    {
      name: "Phone Number",
      selector: "cellNumber",
      sortField: "cellNumber",
      sortable: true,
      cell: (row) => (
        <span className="feature-name" title={row.cellNumber}>
          <span className="textLimit100">{row.cellNumber}</span>
        </span>
      ),
    },
    {
      name: "Type",
      selector: "direction",
      sortField: "direction",
      sortable: true,
      cell: (row) => (
        <>
        {
          row.direction != null ? <>
            <span style={{width : "7px" ,height : "7px",marginRight : "3px",borderRadius : "50%",display : "inline-block",background : row.direction == 2 ? "#3B82F6" : row.direction == 1 ? "#22C55E" : row.direction == 3 ? "#DC7633" : "#9847ff"}}></span>
            <span style={{color : row.direction == 2 ? "#3B82F6" : row.direction == 1 ? "#22C55E" : row.direction == 3 ? "#DC7633" : "#9847ff"}} title={row.direction == 2 ? "Incoming" : row.direction == 1 ? "Outgoing" : "Missed"}>{row.direction == 2 ? "Incoming" : row.direction == 1 ? "Outgoing" : row.direction == 3 ? "Missed" : "Declined"}</span>
          </> : ""
        }
        </>
      ),
    },
    {
      name: "Department",
      selector: "department",
      wrap: true,
      sortable: true,
      sortField: "department",
      cell: (row) =>
        row.department ? (
          <span className="textLimit76">
            {RenderDepartment(row.department).html}
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Category",
      selector: "category",
      wrap: true,
      sortable: true,
      sortField: "category",
      cell: (row) =>
        row.category ? (
          <span className="feature-name">
            {" "}
            <span className="textLimit100" title={row.category}>
              {RenderCategory(row.category).html}
            </span>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Action",
      selector: "",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate scopes={['umedit']}>
              <span title="Open" className="btn btn-primary rounded-circle" onClick={() => setMessage(row?.subject)} data-toggle="modal" data-target="#subject_message"
              >
                <i className="fa fa-eye"></i>
              </span>
            </PermissionsGate>
            
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data;
    const header = ["User", "Call ID","Extension","Date","Call Duration","Phone Number","Type","Department","Category"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    const cData = {
      page: page,
      limit: perPage,
      type : type?.arr ? type?.arr : [],
      extension : ext?.arr ? ext?.arr : [],
      viaCategory : category?.arr ? category?.arr : [],
      viaDepartment : department?.arr? department?.arr : [],
      key: sortkey,
      sort: sortOrder,
      name: search,
      exportStatus: "true",
    }
    cData[onPage == "user" ? "uid" : onPage == "student" ? "id" : null] = id ?? ""
    GetCallLogList(cData).then(res => {
      data = res.data?.data_list;
      data = data?.map((row, ind) => ({
        ...row,
        "User": row.userName ? row.userName : "-",
        "Call ID" : row.callid ? row.callid.toString().slice(1, -1) : "-",
        "Extension": row.extension ? row.extension : "-",
        "Date" : row.callLogTime ? moment(new Date(row.callLogTime).getTime()).format(TABLE_DATE_FORMAT) : "-",
        "Call Duration" : row.duration!=null ? `${row.duration != 0 ? convertDurationTime(row.duration,row.callLogTime) : ""} 
        ${convertTime(row.duration)}` : "-",
        "Phone Number" : row.cellNumber ? row.cellNumber: "-",
        "Type" : row.direction != null ? row.direction == 1 ? "Outgoing" : row.direction == 2 ? "Incoming" : row.direction == 3 ? "Missed" : "Declined" : "-",
        "Department": row.department ? row.department : "-",
        "Category": row.category ? row.category : "-",

      }));
      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        // console.log(csvString);
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
        anchor.click();

        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        // Returning false as downloading of file is already taken care of
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [row["User"], row["Call ID"],row["Extension"],row["Date"],row["Call Duration"],row["Phone Number"],row["Type"],row["Department"],row["Category"]];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            color : "white",
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }

    }).catch(error => {
      console.log("error -----> ", error);
    })




  };

  const resetFilter = () => {
    setSearch("");
    setExt({ arr: [], checkObj: {} })
    setType({ arr: [], checkObj: {} })
    setCategory({ arr: [], checkObj: {} })
    setDepartment({ arr: [], checkObj: {} })
    setDebouncedTerm("")
  };


  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      // const aField = rowA[selector].toString().toLowerCase()
      // const bField = rowB[selector].toString().toLowerCase()

      // let comparison = 0;

      // if (aField > bField) {
      //   comparison = 1;
      // } else if (aField < bField) {
      //   comparison = -1;
      // }

      // return direction === 'desc' ? comparison * -1 : comparison;
    }
  );
  };
  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={debouncedTerm} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div
                    className={`filter-scroll-inner  filter-custom-new`}
                  >
                    <Tablefilter
                      filterName={"Extension"}
                      optionArr={extArr || []}
                      state={ext}
                      setState={setExt}
                      uniqueId={"extension"}
                    />
                    <Tablefilter
                      filterName={"Type"}
                      optionArr={typeArr || []}
                      state={type}
                      setState={setType}
                      uniqueId={"callType"}
                      renderLabelFunction={RenderSoftphoneType}
                    />
                    <Tablefilter
                      filterName={"Department"}
                      optionArr={departmentArr || []}
                      state={department}
                      setState={setDepartment}
                      uniqueId="department"
                      renderLabelFunction={RenderDepartment}
                    />
                    <Tablefilter
                      filterName={"Category"}
                      optionArr={categoryArr || []}
                      state={category}
                      setState={setCategory}
                      uniqueId="category"
                      renderLabelFunction={RenderCategory}
                    />

                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      type="button"
                      className="btn btn-primary"
                      title="Reset"
                      onClick={resetFilter}
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Softphone_List");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Softphone_List");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Softphone_List");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman d-flex">
                <Link className="ml-auto" to='/administration/campusManagement/addCampus'>
                  <button className="btn btn-primary" title="Create New">
                    <i className="fal fa-plus"></i>Create New
                  </button>
                </Link>
              </div>
            </div>
          </div> */}
        </div>
        <DataTable
          paginationDefaultPage={page}
          data={dataArr}
          columns={columns}
          defaultSortAsc={false}
          defaultSortField="callLogTime"
          paginationServer
          pagination
          paginationTotalRows={totalRows}
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          onSort={handleSort}
          sortFunction={customSort}
          // paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
      <div className="topic-add-modal modal fade" id="message" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered available-assessment-modal" role="document">

          <div className="modal-content">
            {/* <div className="modal-body"> */}
            <div class="modal-header modal-header-custom"><h5 class="modal-title"><i class="fal fa-info"></i> Message</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>

            {/* <QBTable refresh={handlerefresh} /> */}
          </div>
        </div>
      </div>
      {/* <PopupComponent
                quiz = {id}
                id="addBulkQuestion"
                type="addBulkQuestion"
                header={{
                    iconName: "fa-bars",
                    heading: "Add Bulk Question"
                }}
                modalBody={<DisplayQuestion question={addBulkQuestionData} from="assignedQTable" />}
            /> */}
      <PopupComponent
        // quiz = {id}
        id="subject_message"
        type="form"
        header={{
          iconName: "fa-solid fa-eye",
          heading: "Message"
        }}
        inputs={[
          <>
          {message ? message : "No message found"}

          </>,
        ]}
      />
    </div>
  );
};

export default CallLogs;

