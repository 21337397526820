import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { AssignAssemblies, AssignAssembly, GetAssignedAssemblies } from "../../../services/CourseService";
import Swal from "sweetalert2";
import "jspdf-autotable";
import SortableTree from "react-sortable-tree";
import TreeTheme from "react-sortable-tree-theme-minimal";
import Placeholder from "../ContentAssembliesBlock/AssemblyListComponent/PalaceHolder";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";

const AssembliesDrag = (props) => {
  const {id}=useParams();
  const history = useHistory();
  const [assignRender, setAssignRender] = useState([])
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    getTableData();
  }, [])

  const getTableData = () => {
    setLoading(true);
    GetAssignedAssemblies(id).then(data => {
      let data_items = data.data.assemblies_list.map((item)=>{
        return {
          id: item.id,
          title: item.name ?
          <span className="textLimit100">{item.name}</span> 
          : <p>-</p>,
          data: {...item},
          className: "parent-node"
        }
      })
      setAssignRender(data_items)
      setLoading(false);
    }).catch(error => {
      setLoading(false);
    })
  }


  const handleSaveData=()=>{
    const data = assignRender;
    let sendData=data.map((val)=>{
        return val.data.id
    })
    const result = {
        id_subject : id,
        id_assembly : sendData ? sendData : []
    }
    AssignAssemblies(result).then((res)=>{
        Swal.fire({
            icon: "success",
            title: "Success",
            text: `${res?.data?.message}`,
          }).then(() => {
            history.push(`/courseAdministration/coursesdetails/${id}/assemblies/show`)
          });
        }).catch((err) => {
          console.log(err);
          // setIsDisabled(false);
          Swal.fire({
            icon: "error",
            title: "error",
            text: `${err?.response?.data?.message}`,
          });
    })
  }





  const handleChangeData = (newData) => {
    setAssignRender(newData);
  }
  const handleDragNode = (dragData) => {
    const { isDragging } = dragData;

    if (isDragging === false) {
      // isAvailData(false);
    }

  }

  return (
    <>
    <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="custom-table-div filter-search-icon card card-table-custom course-table-filter-sec">
          {loading ? <SkeletonTicketList /> : (
            <div className="p-15px">
                <div className="main-block" style={{ height: "auto" }}>
                    <div className="tree-wrapper">
                        <SortableTree
                            theme={TreeTheme}
                            className="my-class"
                            isVirtualized={false}
                            treeData={assignRender ? assignRender : []}
                            onChange={(treeData) => handleChangeData(treeData)}
                            maxDepth={3}
                            onDragStateChanged={(dragData) => handleDragNode(dragData)}
                            style={{
                            padding: "0px",
                            width: "100%",
                            }}
                            // onVisibilityToggle={(data) => nodeToggle(data)}
                            generateNodeProps={(rowInfo) => {
                            const { node, parentNode } = rowInfo;
                            return {
                                class: "main-category-node",
                                style: { borderRadius: "10px", width: "100%" }
                            }
                            }}
                            placeholderRenderer={Placeholder}
                        />
                    </div>
                </div>
                <div className="form-group form-group-save-cancel mt-3 mb-0">
                {/* <PermissionsGate errorProps={{disabled:true}} scopes={["caedit"]}> */}
                <button className="btn btn-primary" type="button" onClick={() => { handleSaveData() }} title="Save">
                    {/* {isDisabled === false ? <i className="fal fa-save"></i> : <i className="fas fa-cog fa-spin"></i>} */}
                    <i className="fal fa-save"></i>
                    Save
                </button>
                {/* </PermissionsGate> */}
                <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.push("/courses/content/assemblies/table")}>
                <i className="fal fa-times"></i>Cancel
                </button>
            </div>
            </div>
          )}
        </div>
    </div>

    </>
  );
};

export default AssembliesDrag