import React from "react";
import NetsuiteDetails from "./NetsuiteDetails";

import PortalInformation from "./PortalInformation";

function StudentDetails() {
  return (
    <>
      <PortalInformation/>
      <NetsuiteDetails/>
    </>
  );
}

export default StudentDetails;
