import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import RegisterNewStudent from "../registration/RegisterNewStudent";
import OpenReRegistration from "./OpenReRegistration";
import ListReRegistration from "./ListReRegistration";
import AuditTrail from "./AuditTrails";
import AssignedProgramme from "./AssignedProgramme";
import StudentList from "./StudentList";
import { GlobalIconNames } from "../../../utils/GlobalIconNames";

const ProgrammeReRegistration = (props) => {
  const { tab, page, id } = useParams();
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const description = params.get("description")
    ? params.get("description")
    : "";

  useEffect(() => {
    if (page === "open-re-registration") {
      changeTabName("Re-Registration: " + description);
    } else if (
      page === "open-re-registration" ||
      page === "list-student-status"
    ) {
      changeTabName("List Re-Registration");
    }
  }, [page]);

  function changeTabName(name = "Open Re-Registration") {
    props.changeName(name);
  }

  return (
    <>
      {page === "create" ? (
        <RegisterNewStudent />
      ) : page === "open-re-registration" && tab !== "add" ? (
        <>
          <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
            <li
              className="nav-item"
              onClick={() => {
                history.push(
                  `/courseAdministration/registration/open-re-registration/open/${id}?description=${description}`
                );
                changeTabName("Re-Registration: " + description);
              }}
            >
              <a
                className={"nav-link" + (tab === "open" ? " active" : "")}
                id="pills-tab15"
                data-toggle="pill"
                href="#pills-tab15"
                role="tab"
                aria-controls="pills-tab15"
                aria-selected="true"
              >
                {GlobalIconNames("details").html}
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                history.push(
                  `/courseAdministration/registration/open-re-registration/assignedProgramme/${id}?description=${description}`
                );
              }}
            >
              <a
                className={
                  "nav-link" + (tab === "assignedProgramme" ? " active" : "")
                }
                id="pills-tab16"
                data-toggle="pill"
                href="#pills-tab16"
                role="tab"
                aria-controls="pills-tab16"
                aria-selected="true"
              >
                {GlobalIconNames("assignedProgrammes").html}
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                history.push(
                  `/courseAdministration/registration/open-re-registration/studentList/${id}?description=${description}`
                );
              }}
            >
              <a
                className={
                  "nav-link" + (tab === "studentList" ? " active" : "")
                }
                id="pills-tab26"
                data-toggle="pill"
                href="#pills-tab26"
                role="tab"
                aria-controls="pills-tab26"
                aria-selected="true"
              >
                {GlobalIconNames("Student List", "fa-users", "Student List").html}
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                history.push(
                  `/courseAdministration/registration/open-re-registration/audittrails/${id}?description=${description}`
                );
              }}
            >
              <a
                className={
                  "nav-link" + (tab === "audittrails" ? " active" : "")
                }
                id="pills-tab17"
                data-toggle="pill"
                href="#pills-tab17"
                role="tab"
                aria-controls="pills-tab17"
                aria-selected="true"
              >
                {GlobalIconNames("auditTrail").html}
              </a>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className={"tab-pane fade active show"}
              id="pills-tab14"
              role="tabpanel"
              aria-labelledby="pills-tab14"
            >
              {tab === "open" && <OpenReRegistration />}
              {tab === "assignedProgramme" && <AssignedProgramme />}
              {tab === "studentList" && <StudentList />}
              {tab === "audittrails" && <AuditTrail />}
            </div>
          </div>
        </>
      ) : page === "open-re-registration" && tab === "add" ? (
        <OpenReRegistration />
      ) : (
        <>
          <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
            <li
              className="nav-item"
              onClick={() => {
                history.push(
                  page === "open-re-registration"
                    ? `/courseAdministration/registration/open-re-registration/open/${id}`
                    : `/courseAdministration/registration/list-re-registration`
                );
                changeTabName("List Re-Registration");
              }}
            >
              <a
                className={
                  "nav-link" +
                  (page === "list-re-registration" ||
                    page === "list-student-status" ||
                    page === "open-re-registration"
                    ? " active"
                    : "")
                }
                id="pills-tab15"
                data-toggle="pill"
                href="#pills-tab15"
                role="tab"
                aria-controls="pills-tab15"
                aria-selected="true"
              >
                {page === "open-re-registration"
                  ? GlobalIconNames("Open Re-Registration", "fa-bars", "Open Re-Registration").html
                  : GlobalIconNames("List Re-Registration", "fa-bars", "List Re-Registration").html}
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                history.push(
                  `/courseAdministration/registration/audittrails?description=${description}`
                );
              }}
            >
              <a
                className={
                  "nav-link" + (page === "audittrails" ? " active" : "")
                }
                id="pills-tab16"
                data-toggle="pill"
                href="#pills-tab16"
                role="tab"
                aria-controls="pills-tab16"
                aria-selected="true"
              >
                {GlobalIconNames("auditTrail").html}
              </a>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className={"tab-pane fade active show"}
              id="pills-tab14"
              role="tabpanel"
              aria-labelledby="pills-tab14"
            >
              {page === "open-re-registration" && <OpenReRegistration />}
              {page === "list-re-registration" && <ListReRegistration />}
              {page === "list-student-status" && <ListReRegistration />}
              {page === "audittrails" && <AuditTrail />}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ProgrammeReRegistration;
