import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  GetCourseOnlineClassesList,
  GetClassTypeOptionList,
  AddOnlineClass,
  GetList,
  GetOnlineClassesData,
  EditOnlineClass,
  GetCourseDetails,
  GetLoadBalancing,
} from "../../../../services/CourseService";
import { useParams, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import moment from "moment";
import { TABLE_DATE_FORMAT, FORM_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, FORM_TIME_FORMAT, NAME_REGEX } from "../../../../utils/Constants";
import PermissionsGate from "../../../../utils/permissionGate";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";

const OnlineClassesForm = () => {

  const { id, type, openTabs, subTab, subId } = useParams();
  const [editData, setEditData] = useState({})
  const [saveDisbale, setSaveDisbale] = useState(false)
  const [classType, setClassType] = useState([]);
  const [campusList, setCampusList] = useState([]);
  const [guestLecturer, setGuestLecturer] = useState([]);
  const [editRecurring, setEditRecurring] = useState({})
  const [lecturerList, setLecturerList] = useState([
    { value: "Lecturer", label: "Lecturer" },
    { value: "Support", label: "Support" },
    { value: "Principle", label: "Principle" },
  ]);
  const [incrementType, setIncrementType] = useState([
    { value: "numeric", label: "Numeric" },
    { value: "alphabet", label: "Alphabet" },
  ]);
  const [campusData, setCampusData] = useState([]);
  const history = useHistory();
  const [defaultTime, setDefaultTime] = useState({});
  const [selectedDays, setSelectedDays] = useState([]);
  const [classDetails, setClassDetails] = useState({});
  const [serverData, setServerData] = useState([])

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    if (subTab === "open") {
      let data = {
        intake: id,
        class_id: type,
        isRecurring: openTabs
      }
      GetOnlineClassesData(data).then((res) => {
        setEditData(res?.data?.data);
        let lect1 = []
        let tempArr = res?.data?.data?.multiple_lecture.map((lect) => {
          if (res.data?.data?.lecturer?.UserID != lect?.get_lecture_details?.UserID) {
            lect1.push(lect?.get_lecture_details?.UserID)
          }
        })
        setGuestLecturer(lect1 ? lect1 : [])
        setClassDetails(res.data?.data)
        formik.setFieldValue("lecturer", res?.data?.data?.lecturer?.UserID)

        try {
          if (res?.data?.data?.recurringDetails !== "null") {
            let data = JSON.parse(res?.data?.data?.recurringDetails)
            let dayArr = []
            setEditRecurring(data)
            Object.keys(data).map((key) => {
              if (data[key] == 1 && ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].includes(key)) {
                dayArr.push(key)
              }
            })
            setSelectedDays(dayArr)
          } else {
            setEditRecurring({})
          }
        }
        catch {
          setEditRecurring({})
        }
      }).catch((err) => {
        console.log(err);
      })
    } else {
      GetCourseDetails(id).then(res => {
        // setGuestLecturer([res.data.lecturer_id])
        formik.setFieldValue("lecturer", res.data.lecturer_id)
      }).catch(err => console.log("errorr", err))
    }
  }, [])

  useEffect(() => {
    GetLoadBalancing().then((res) => {
      let demo = res.data.res;
      let result = [];
      demo.map((val) => {
        result.push({ value: val.id, label: val.server_display_name })
      })
      setServerData(result)
    }).catch(err => console.log(err))
  }, [])


  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const getOnlineClassesList = () => {
    GetCourseOnlineClassesList(id).then((response) => {
      // setOnlineClassList(response.data?.online_classes);
    });

    GetClassTypeOptionList(id).then((response) => {
      if (subTab === "open") {
        let data = {
          intake: id,
          class_id: type,
          isRecurring: openTabs
        }
        GetOnlineClassesData(data).then((res) => {
          let tempArr = []
          response.data.campus.map(val => {
            let tempObj = res.data?.data?.online_classes_campus_room?.find(item => {
              return val.id == item.campus_id
            })
            if (tempObj) {
              let selectedObj = val.class_campus_many.find(item => {
                return item.value == tempObj.room_id
              })
              tempArr.push({ ...val, selectedObj })
            } else {
              tempArr.push(val)
            }
          })
          setCampusList(tempArr)
        })
      }
      else {
        setCampusList(response?.data?.campus);
      }
      setClassType(response.data?.class_type);
      // setCampusList(response?.data?.campus);
      setDefaultTime(
        response?.data?.default_time?.setting_info
          ? JSON.parse(response?.data?.default_time?.setting_info)
          : {}
      );
    });

    GetList().then((data) => {
      let modifiedArr = [];
      let coach = data?.data?.lecturer_coach;
      coach.map((ele) => {
        modifiedArr.push({
          value: ele.UserID,
          label: ele.name,
          id: ele.UserID,
        });
      });
      setLecturerList(modifiedArr);
    });
  };

  useEffect(() => {
    getOnlineClassesList();
  }, []);

  const getDate = () => {
    if (editData?.class_date && editData?.class_date != undefined) {
      return new Date(editData?.class_date)
    } else {
      return ""
    }
  }
  const getStartTime = () => {
    let start = new Date(moment(editData.class_from, "h:mm:a").format(TABLE_DATE_TIME_FORMAT));
    if (editData?.class_from && editData?.class_from != undefined && !isNaN(start.getTime())) {
      return start;
    } else {
      return ""
    }
  }
  const getEndTime = () => {
    let end = new Date(moment(editData.class_to, "h:mm:a").format(TABLE_DATE_TIME_FORMAT));
    if (editData?.class_to && editData?.class_to != undefined && !isNaN(end.getTime())) {
      return end;
    }
    else {
      return ""
    }
  }
  const formik = useFormik({
    // enableReinitialize: subTab == "open" ? true : false,
    enableReinitialize: true,
    initialValues: {
      Name: editData && editData?.class_title ? editData?.class_title : "",
      class_type: editData && editData?.class_type ? editData?.class_type : "",
      CloseDate: "",
      date: getDate(),
      // start_time: getStartTime(),
      // end_time: getEndTime(),
      start_time: "",
      end_time: "",
      // isRecurring: editData && editData?.isRecurring ? editData?.isRecurring : 0,
      isRecurring: 0,
      CloseMonth: "",
      CloseMonthInNumber: "",
      CloseYear: "",
      OpenHour: "",
      OpenMinute: "",
      CloseHour: "",
      CloseMinute: "",
      TimeLimitHours: "",
      TimeLimitMinutes: "",
      Description: editData?.class_description ? editData?.class_description : "",
      cptType: campusData,
      lecturer: classDetails && classDetails.lecturer ? classDetails.lecturer.UserID : "",
      guestLecturer: guestLecturer ? guestLecturer : [],
      showToStudent: true,
      studentBeforeTime: 30,
      presenterBeforeTime: 30,
      recordingBeforeTime: 30,
      campus: "",
      redirect_server_id: editData?.redirect_server_id && editData?.redirect_server_id != null ? editData?.redirect_server_id : "",
      StudentCheck: editData && editData.student_notification != null && editData.student_notification == "leter" ? true : false,
      PushNotification: editData && editData.isPushnotificationActive != null && editData.isPushnotificationActive ? true : false,
      PresentCheck: editData && editData.lecturer_notification ? true : false,
      EmailNotification: editData && editData.isLectureEmail ? true : false,
      setClassRecording: {
        RecordCheck: editData && editData.isRecordingChecked ? true : false,
        PushNotification: editData && editData.recording_pushnotification ? true : false,
        EmailNotification: editData && editData.recording_email ? true : false,
      }


    },
    validationSchema: Yup.object({
      Name: Yup.string().matches(NAME_REGEX, "Please enter valid name").required("Name is required").trim(),
      class_type: Yup.string().required("Class Type is required"),
      lecturer: Yup.string().required("Lecturer is required"),
      // guestLecturer: Yup.string().required("Guest Lecturer is required"),
      date: Yup.string().required("Date is required"),
      start_time: Yup.string().required("Start time is required"),
      end_time: Yup.string().required("End time is required"),
      campus: Yup.string().test("required", "One campus must be select on campus or hybrid class", function (value) {
        const { class_type } = this.parent;
        let isValid = false
        campusList.map(val => {
          if (val.selectedObj && !isValid) {
            isValid = true
          } else if (!class_type || class_type == "bbb_class") {
            isValid = true
          }
        })
        return isValid
      }),
      setClassRecording: Yup.object().shape(
        {
          PushNotification: Yup.bool().when(["RecordCheck", "EmailNotification"], {
            is: (RecordCheck, EmailNotification) => {
              return RecordCheck && !EmailNotification
            },
            then: Yup.bool().oneOf([true], "Please select at least one notification method to notify the student about class recording.")
          }),
          EmailNotification: Yup.bool().when(["RecordCheck", "PushNotification"], {
            is: (RecordCheck, PushNotification) => {
              return RecordCheck && !PushNotification
            },
            then: Yup.bool().oneOf([true], "Please select at least one notification method to notify the student about class recording.")
          })
        },
        [
          ["PushNotification", "EmailNotification"],
          ["EmailNotification", "PushNotification"]
        ]

      )
    }),
    onSubmit: (values, { resetForm }) => {
      setSaveDisbale(true);
      if (modalFormik.values.range_end_date && (values.date > modalFormik.values.range_end_date) && modalFormik.values.recurrence_range == "0" && values.isRecurring == "1") {
        formik.setErrors({ date: "Start date should be less than recurring end date" })
        return;
      }
      let campuses = campusList;
      let startTime = new Date(values.start_time).getHours();
      let startType = " am";
      if (startTime == 12) {
        startTime = 12;
        startType = " pm";
      } else if (startTime > 12) {
        startTime = startTime - 12;
        startType = " pm";
      }

      let closeTime = new Date(values?.end_time).getHours();
      let closeType = " am";
      if (closeTime == 12) {
        closeTime = 12;
        closeType = " pm";
      } else if (closeTime > 12) {
        closeTime = closeTime - 12;
        closeType = " pm";
      }

      var dateObj = new Date(values.date);
      var month = dateObj.getMonth() + 1; //months from 1-12
      var day = dateObj.getDate();
      var year = dateObj.getFullYear();
      let formData = new FormData();
      formData.append("title", values?.Name);
      // formData.append("class_type", values?.class_type);
      formData.append("class_type", values?.class_type);
      formData.append(
        "date",
        `${year}-${month <= 9 ? "0" + month : month}-${day}`
      );
      formData.append(
        "start_time",
        `${startTime == 0 ? 12 : startTime}:${(new Date(values.start_time).getMinutes() < 10 ? "0" : "") +
        new Date(values.start_time).getMinutes()
        }${startType}`
      );
      formData.append(
        "end_time",
        `${closeTime}:${(new Date(values.end_time).getMinutes() < 10 ? "0" : "") +
        new Date(values.end_time).getMinutes()
        }${closeType}`
      );
      formData.append("sms_description", "")
      formData.append("intake", id);

      values.guestLecturer.map((id, index) => {
        formData.append(`lect_ids[${++index}]`, id);
      });
      formData.append(`lect_ids[0]`, values.lecturer);
      formData.append("redirect_server_id", values.redirect_server_id)
      // formData.append("campus_rooms", JSON.stringify(campusDropdown));
      formData.append("student_publish_time", values.studentBeforeTime);
      formData.append("lecturer_publish_time", values.presenterBeforeTime);
      formData.append("recording_publish_time", values.recordingBeforeTime);

      formData.append("daily_weekly", modalFormik.values.recurrence);
      formData.append("daily_days", modalFormik.values.daily_days);
      formData.append("every_daily", modalFormik.values.recurrence_pattern);
      formData.append("weekly_days", modalFormik.values.weeks);
      var enddateObj = new Date(modalFormik.values.range_end_date);
      var endMonth = enddateObj.getMonth() + 1; //months from 1-12
      var endDay = enddateObj.getDate();
      var endYear = enddateObj.getFullYear();


      var startdateObj = new Date(modalFormik.values.range_start_date);
      var startMonth = startdateObj.getMonth() + 1; //months from 1-12
      var startDay = startdateObj.getDate();
      var startYear = startdateObj.getFullYear();


      if (modalFormik.values.range_end_date) {
        formData.append(
          "range_end_date",
          `${endYear}-${endMonth <= 9 ? "0" + endMonth : endMonth}-${endDay}`
        );
      }
      if (modalFormik.values.range_start_date) {
        formData.append("range_start_date", `${startYear}-${startMonth <= 9 ? "0" + startMonth : startMonth}-${startDay}`)
      }
      formData.append("occurence", modalFormik.values.ocuurence);
      formData.append("increment_type", modalFormik.values.increment_type);
      formData.append("increment_value", modalFormik.values.increment_value);
      if (modalFormik.values.class_text) {
        formData.append("class_text", (modalFormik.values.class_text).trim());
      }
      formData.append("isRecurring", formik.values.isRecurring);
      if (modalFormik.values.recurrence_range == 1) { formData.append("end_range", "number_range") } else {
        formData.append("end_range", "date_range")
      }

      if (modalFormik.values.prefix == "none") {
        formData.append("class_prefix_sufix", "none")
      }
      else if (modalFormik.values.prefix == "prefix") {
        formData.append("class_prefix_sufix", "prefix")
      }
      else {
        formData.append("class_prefix_sufix", "sufix")
      }

      if (modalFormik.values.weekday.includes("Monday")) {
        formData.append("Monday", "Monday");
      }
      if (modalFormik.values.weekday.includes("Tuesday")) {
        formData.append("Tuesday", "Tuesday");
      }

      if (modalFormik.values.weekday.includes("Wednesday")) {
        formData.append("Wednesday", "Wednesday");
      }

      if (modalFormik.values.weekday.includes("Thursday")) {
        formData.append("Thursday", "Thursday");
      }
      if (modalFormik.values.weekday.includes("Friday")) {
        formData.append("Friday", "Friday");
      }
      if (modalFormik.values.weekday.includes("Saturday")) {
        formData.append("Saturday", "Saturday");
      }
      if (modalFormik.values.weekday.includes("Sunday")) {
        formData.append("Sunday", "Sunday");
      }

      campuses.map((value, index) => {
        if (value.selectedObj) {
          formData.append(`campuses[${index}]`, value.id);
          formData.append(`campus_rooms[${index}]`, value.selectedObj.value);
        } else {
          formData.append(`campuses[${index}]`, "");
          formData.append(`campus_rooms[${index}]`, "");
        }
        // formData.append("campuses",JSON.stringify(campuses));
      });
      formData.append("description", values.Description);
      formData.append(
        "description_record",
        values.showToStudent == true ? 1 : 0
      );
      if (subTab == "open") {
        formData.append("isUpdateAll", subId ? subId : "0");
        formData.append("portal_class_id", editData.id);
        formData.append("start_recording_auto", editData.class_auto_record);
        formData.append("class_id", editData.class_id);
        formData.append("recurringId", editData.recurringId);
      }
      formData.append("student_notification", values.StudentCheck == true ? "leter" : "now");
      formData.append("isPushnotification", values.PushNotification == true ? "1" : "0");
      // formData.append("isEmail",values.setStudentClass.EmailNotification==true ? "1" : "0");

      formData.append("lecturer_notification", values.PresentCheck == true ? "leter" : "now");
      formData.append("isLectureEmail", values.EmailNotification == true ? "1" : "0");
      // formData.append("isLectureSms",values.setPresentClass.PushNotification==true ? "1" : "0");

      formData.append("isRecordingChecked", values.setClassRecording.RecordCheck == true ? "1" : "0");
      formData.append("recording_email", values.setClassRecording.EmailNotification == true ? "1" : "0");
      formData.append("recording_pushnotification", values.setClassRecording.PushNotification == true ? "1" : "0");



      if (subTab == "open") {
        EditOnlineClass(formData)
          .then((res) => {
            setSaveDisbale(false)
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added successfully",
            });
            // resetForm();
            // setImage(user);
            history.push(
              `/courseAdministration/coursesdetails/${id}/onlineClasses/show`
            );
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data?.data}`,
            });
          });
      }
      else {
        AddOnlineClass(formData)
          .then((res) => {
            setSaveDisbale(false)
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added successfully",
            });
            // resetForm();
            // setImage(user);
            history.push(
              `/courseAdministration/coursesdetails/${id}/onlineClasses/show`
            );
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data?.data}`,
            });
          });
      }
    },
  });

  const setHoursAndMinute = (date, defaultTime, type) => {
    if (editData?.class_from && editData?.class_to) {
      if (type === "start") {
        return getStartTime();
      } else {
        return getEndTime();
      }
    } else {
      date.setHours(defaultTime?.split("")[0] + defaultTime?.split("")[1]);
      date.setMinutes(defaultTime?.split("")[3] + defaultTime?.split("")[4]);
      // console.log("s3->",date);
      return date;
    }
  }

  useEffect(() => {
    if (defaultTime && defaultTime.start_time && defaultTime.end_time) {
      formik.setFieldValue(
        "start_time",
        setHoursAndMinute(new Date(), defaultTime?.start_time, "start")
      );
      formik.setFieldValue(
        "end_time",
        setHoursAndMinute(new Date(), defaultTime?.end_time, "end")
      );
    }
  }, [defaultTime]);

  const setWeekday = () => {
    let dayArr = [];
    let arr = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    Object.keys(editRecurring).map((key) => {
      if (editRecurring[key] == 1 && arr.includes(key)) {
        dayArr.push(key)
      }
    })

    if (dayArr.length) {
      return dayArr
    } else {
      return []
    }
  }
  const getRangeEndDate = () => {
    if (editRecurring?.range_end_date && editRecurring?.range_end_date != undefined) {
      if (editRecurring?.range_end_date.toString().includes("-")) {
        return new Date(editRecurring?.range_end_date);
      }
      else {
        return new Date(moment.unix(editRecurring?.range_end_date).format(TABLE_DATE_FORMAT))
      }

    } else {
      return ""
    }
  }
  const getStartDate = () => {
    if (editRecurring?.range_start_date && editRecurring?.range_start_date != undefined) {
      return new Date(editRecurring?.range_start_date)
    } else {
      return ""
    }
  }

  const modalFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      daily_weekly: "",
      every_daily: editRecurring.every_day ? editRecurring.every_day : "every_day",
      daily_days: editRecurring?.daily_days ? editRecurring?.daily_days : 1,
      weekly_days: "",
      range_end_date: getRangeEndDate(),
      range_start_date: getStartDate(),
      recurrence: editRecurring.daily == 1 ? "daily" : editRecurring.weekly == 1 ? "weekly" : "daily",
      recurrence_pattern: editRecurring.every_day == 1 ? "every_day" : editRecurring.every_weekday == 1 ? "every_weekday" : "every_day",
      recurrence_range: editRecurring?.number_range ? editRecurring?.number_range : type == "create" ? 1 : 0,
      prefix: editRecurring?.class_prefix_sufix ? editRecurring?.class_prefix_sufix === "suffix" ? "sufix" : editRecurring?.class_prefix_sufix : "none",
      class_text: editRecurring ? editRecurring?.class_text : "",
      increment_type: editRecurring?.increment_type ? editRecurring?.increment_type : "",
      increment_value: editRecurring?.increment_value ? editRecurring?.increment_value : "",
      weekday: setWeekday(),
      ocuurence: editRecurring?.occurence ? parseInt(editRecurring?.occurence) : 1,
      weeks: editRecurring.weekly_days ? editRecurring.weekly_days : 1,
      NsetClassRecordingNot: {
        RecordCheck: editData && editData.isRecordingChecked && editData.isRecordingChecked ? true : false,
      }
    },
    validationSchema: Yup.object({
      recurrence: Yup.string().required("Daily Weeks required"),
      recurrence_pattern: Yup.string().when("recurrence", {
        is: "Daily",
        then: Yup.string().required("Recurrence pattern is required"),
      }),

      increment_type: Yup.string().when("prefix", (prefix) => {
        if (prefix == "prefix" || prefix == "sufix")
          return Yup.string().required("Increment type is required");
      }),
      increment_value: Yup.string().when("prefix", (prefix) => {
        if (prefix == "prefix" || prefix == "sufix")
          return Yup.string().required("Increment value is required");
      }),
      range_end_date: Yup.string().when("recurrence_range", {
        is: 0,
        then: Yup.string().required("End Date is required").test("is-greater", "End Date should be greater", function (value) {
          const { range_start_date } = this.parent;
          return moment(value).isSameOrAfter(moment(range_start_date));
        }),
      }),
      range_start_date: Yup.string().test("required", "Start Date is Required", function (value) {
        return (subTab == "open" && value) || type == "create"
      }).test("is-after", "Start Date must be greater than or equal to today", function (value) {
        return moment(value).isSameOrAfter(moment(new Date()).subtract(1, "day"));
      }),
      ocuurence: Yup.string().when("recurrence_range", {
        is: 1,
        then: Yup.string().required("Ocuuence should be more than 1"),
      }),
      weekday: Yup.string().when("recurrence", {
        is: "Weekly",
        then: Yup.string().required("Weekday is required"),
      }),
      weeks: Yup.string().when("recurrence", {
        is: "Weekly",
        then: Yup.string().required("Week is required"),
      }),
    }),
    onSubmit: (values) => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Successfully Updated",
        timer: 1000,
        showConfirmButton: false
      });
      $("#recurrance").modal("hide")
      formik.setFieldValue("isRecurring", 1);
    },
  });

  const onAdtypeHandleChange = (data) => {
    const lecturerSelected = data?.map((obj) => {
      return obj.value;
    });
    const lecturerSelectedId = data?.map((obj) => {
      return obj.id;
    });
    setGuestLecturer(lecturerSelected);
    formik.setFieldValue("lecturer", lecturerSelectedId);
  };

  const weeklyHandle = (value) => {
    let arr = selectedDays;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }

    setSelectedDays(arr);
    modalFormik.setFieldValue("weekday", arr);
    // setWeekly((prev) => [...prev, value]);
  };

  const handleIncrement = (e) => {
    let x = e.keyCode;
    //condition if backspace or delete key is pressed then increment_value will be empty string
    if (x == 8 || x == 46) {
      modalFormik.setFieldValue("increment_value", "");
    }
    if (modalFormik.values.increment_value.length < 1) {
      //below condition is to check if increment_type is numeric and keyCode of key pressed is between numeric key code
      if (
        ((x >= 48 && x <= 57) || (x >= 96 && x <= 105)) &&
        modalFormik.values.increment_type == "numeric"
      ) {
        modalFormik.setFieldValue("increment_value", e.key);
      } else if (
        x >= 65 &&
        x <= 90 &&
        modalFormik.values.increment_type == "alphabet"
      )
        modalFormik.setFieldValue("increment_value", e.key);
    }
  };

  const handleRecuurence = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        formik.setFieldValue("isRecurring", 0)
        $("#recurrance").modal('hide');
      }
    })
  }

  const handleSearchDateTime = (e) => {
      let searchedValue = e.target.value
      let tempArr = document.getElementsByClassName("react-datepicker__time-list-item")
      let list = Array.from(tempArr)
      if (!list.length) return
      list.map(item => {
        if (!item.innerHTML.includes(searchedValue)) {
          item.classList.add("d-none")
        } else {
          item.classList.remove("d-none")
        }
      })
  }

  return (
    <>
      <div className="card-new card card-profile-info-card mb-30">
        <div className="">
          <form>
            <div className="card-header">Details</div>
            <div className="card-body-inr card-body-info">
              <div className="basic-info-box">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="Name">Enter Name* </label>
                      <input
                        type="text"
                        title="Enter Name"
                        className={
                          "form-control" +
                          (formik.errors.Name && formik.touched.Name
                            ? " is-invalid"
                            : "")
                        }
                        placeholder="Enter Name"
                        name="Name"
                        id="Name"
                        value={formik.values.Name || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="Type">Type*</label>
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.class_type && formik.touched.class_type
                            ? " is-invalid"
                            : "")
                        }
                        name="class_type"
                        id="Type"
                        title="Class Type"
                        placeholder={
                          formik.values.class_type
                            ? formik.values.class_type
                            : "Select Type"
                        }
                        options={classType}
                        onBlur={formik.handleBlur}
                        onChange={(value) => {
                          if (value) {
                            formik.setFieldValue("class_type", value.value);
                          } else {
                            formik.setFieldValue("class_type", "");
                          }
                        }}
                        value={classType.filter(function (option) {
                          return option.value == formik.values.class_type;
                        })}
                        isClearable

                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="CloseDate">Date* </label>
                      <DatePicker
                        selected={formik.values.date}
                        onChange={(date) => {
                          formik.setFieldValue("date", date);
                        }}
                        minDate={new Date()}
                        // showTimeSelect
                        filterTime={filterPassedTime}
                        dateFormat={FORM_DATE_FORMAT}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormatCalendar="MMMM"
                        // className="form-control"
                        className={
                          "form-control cursor-pointer" +
                          (formik.errors.date && formik.touched.date
                            ? " is-invalid"
                            : "")
                        }
                        // disabled={!isEditable && type === "open"}
                        title="Start Date"
                        onBlur={formik.handleBlur}
                        placeholderText="Select Date"
                        timeIntervals={15}
                        onChangeRaw={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-12 col-lg-4"> */}
                  {/* <div className="row"> */}
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="CloseDate">From* </label>
                      <div className="m-auto time-select">
                        <DatePicker
                          className={
                            "form-control cursor-pointer" +
                            (formik.errors.start_time &&
                              formik.touched.start_time
                              ? " is-invalid"
                              : "")
                          }
                          selected={formik.values.start_time}
                          // selected={defaultTime?.start_time && setHoursAndMinute(new Date(), defaultTime?.start_time)}
                          onChange={(date) => {
                            formik.setFieldValue("start_time", date);
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          filterTime={filterPassedTime}
                          timeIntervals={5}
                          timeCaption="Time"
                          dateFormat={FORM_TIME_FORMAT}
                          timeFormat={FORM_TIME_FORMAT}
                          onChangeRaw={(e) => handleSearchDateTime(e)}
                          title="From"
                          onBlur={formik.handleBlur}
                          placeholderText="Select From Time"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-2">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="CloseDate">To* </label>
                      <div className="form-grp-dates time-select">
                        <DatePicker
                          disabled={!formik.values.start_time}
                          className={
                            "form-control cursor-pointer" +
                            (formik.errors.end_time &&
                              formik.touched.end_time
                              ? " is-invalid"
                              : "")
                          }
                          selected={formik.values.end_time}
                          onChange={(date) => {
                            formik.setFieldValue("end_time", date);
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          filterTime={filterPassedTime}
                          timeIntervals={5}
                          timeCaption="Time"
                          dateFormat={FORM_TIME_FORMAT}
                          timeFormat={FORM_TIME_FORMAT}
                          title="To"
                          onBlur={formik.handleBlur}
                          placeholderText="Select To Time"
                          onChangeRaw={(e) => handleSearchDateTime(e)}
                          minTime={new Date(
                            formik.values.start_time
                          ).setTime(
                            new Date(formik.values.start_time).getTime() +
                            5 * 60000
                          )}
                          maxTime={new Date().setHours(23, 59, 0, 0)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* </div> */}
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label>Description</label>
                      <input
                        type="text"
                        title="Enter Description"
                        className={
                          "form-control" +
                          (formik.errors.Description &&
                            formik.touched.Description
                            ? " is-invalid"
                            : "")
                        }
                        placeholder="Enter Description"
                        name="Description"
                        id="Description"
                        value={formik.values.Description || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <PermissionsGate scopes={["cocserverview"]}>
                        <label htmlFor="Type">Choose Server</label>
                        <Select
                          className={
                            "form-control custom-select-box" +
                            (formik.errors.redirect_server_id && formik.touched.redirect_server_id
                              ? " is-invalid"
                              : "")
                          }
                          name="redirect_server_id"
                          id="redirect_server_id"
                          title="Choose Server"
                          placeholder={
                            formik.values.redirect_server_id
                              ? formik.values.redirect_server_id
                              : "Select Type"
                          }
                          options={serverData}
                          onBlur={formik.handleBlur}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("redirect_server_id", value.value);
                            } else {
                              formik.setFieldValue("redirect_server_id", "");
                            }
                          }}
                          value={serverData.filter(function (option) {
                            return option.value == formik.values.redirect_server_id;
                          })}
                          isClearable
                          isDisabled={!hasPermission({ scopes: ["cocserveredit"], permissions: givenPermsisions })}
                        />
                      </PermissionsGate>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group d-inline-flex pt-4">
                      <label> </label>
                      <div className="custom-control custom-checkbox text-left">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="showToStudent"
                          name="showToStudent"
                          checked={formik.values.showToStudent}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="showToStudent"
                        ></label>
                      </div>
                      <label>Show description to student</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="card-header">Resources</div>
            <div className="card-body-inr card-body-info">
              <div className="basic-info-box">

                <div className="row">
                  {campusList && formik.values.class_type != 'bbb_class' &&
                    campusList.length > 0 &&
                    campusList.map((elem, index) => (
                      <div className="col-md-6 col-lg-4" key={index}>
                        <div className="form-group-blk mb-3">
                          <label>
                            {elem.campus_name ? <>{elem.campus_name + (formik.values.class_type && formik.values.class_type !== "bbb_class" ? "*" : "")} </> : "-"}
                          </label>
                          <div className="input-checkbox-block">
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (formik.errors.campus && formik.touched.campus
                                  ? " is-invalid"
                                  : "")
                              }
                              name={"campus_checkbox_rooms[" + elem.id + "]"}
                              id={"cptType-" + index}
                              title="CPT Class Type"
                              placeholder={elem.selectedObj ? elem.selectedObj.label : "Select Room"}
                              options={elem.class_campus_many}
                              onBlur={formik.handleBlur}
                              onChange={async (value) => {
                                let tempCampus = campusList;
                                tempCampus.map((item) => {
                                  if (item.id == elem.id) {
                                    if (value) {
                                      item.selectedObj = value;
                                    } else {
                                      item.selectedObj = "";
                                    }
                                    return item;
                                  } else {
                                    return item;
                                  }
                                });
                                setCampusList((oldData) => [...tempCampus])
                              }}
                              isDisabled={formik.values.class_type === "bbb_class" || formik.values.class_type === "" || formik.values.class_type === null ? true : false}
                              value={elem.selectedObj}
                              isClearable
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label>Lecturer*</label>
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.lecturer && formik.touched.lecturer
                            ? " is-invalid"
                            : "")
                        }
                        name="lecturer"
                        value={lecturerList.filter((obj) => {
                          return obj.value == formik.values.lecturer
                        }
                        )}
                        onChange={(value) => {
                          if (value) {
                            formik.setFieldValue("lecturer", value.value)
                          } else {
                            formik.setFieldValue("lecturer", "")
                          }
                        }}
                        onBlur={formik.handleBlur}
                        options={lecturerList}
                        maxMenuHeight={175}
                        isClearable
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label>Guest Lecturer </label>
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.guestLecturer && formik.touched.guestLecturer
                            ? " is-invalid"
                            : "")
                        }
                        name="guestLecturer"
                        value={formik.values.guestLecturer ? lecturerList.filter((obj) =>
                          formik.values.guestLecturer.includes(obj.value)
                        ) : ""}
                        onChange={(value) => {
                          if (value) {
                            let arr = []
                            value.map(item => {
                              arr.push(item.value)
                            })
                            formik.setFieldValue("guestLecturer", arr)
                          } else {
                            formik.setFieldValue("guestLecturer", [])
                          }
                        }}
                        onBlur={formik.handleBlur}
                        options={lecturerList}
                        maxMenuHeight={175}
                        isMulti={true}
                      />
                    </div>
                  </div>
                  {type == "create" || openTabs == "0" ? <div className="col-md-6 col-lg-4"><div className="form-group-blk mb-3">
                    <label>Make Recurring</label>
                    <div className="make-recurring-btn">
                      <button
                        className="btn btn-save btn-success"
                        data-toggle="modal"
                        data-target="#recurrance"
                        // onClick={formik.handleSubmit}
                        type="button"
                        title="Make Recurring"
                      >
                        <i className="fal fa-save"></i>Make Recurring
                      </button>
                    </div>
                  </div>

                  </div> : subTab == "open" && openTabs == "1" && subId == "1" ? <div className="col-md-6 col-lg-4"><div className="form-group-blk mb-3">
                    <label>Edit Recurring</label>
                    <div className="make-recurring-btn">
                      <button
                        className="btn btn-save btn-success"
                        data-toggle="modal"
                        data-target="#recurrance"
                        // onClick={formik.handleSubmit}
                        type="button"
                        title="Edit Recurring"
                      >
                        <i className="fal fa-save"></i>Edit Recurring
                      </button>
                    </div>
                  </div>

                  </div> : ""}
                  {/* {(type == "create" || (subTab == "open" && openTabs == "1")) && <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label>Make Recurring</label>
                      <div className="make-recurring-btn">
                        <button
                          className="btn btn-save btn-success"
                          data-toggle="modal"
                          data-target="#recurrance"
                          // onClick={formik.handleSubmit}
                          type="button"
                          title="Make Recurring"
                        >
                          <i className="fal fa-save"></i>Make Recurring
                        </button>
                      </div>

                    </div>
                  </div>} */}
                </div>
              </div>
            </div>
            <hr />

            <div className="card-header">Notifications</div>
            <div className="card-body-inr card-body-info">
              <div className="basic-info-box">
                <h5>Student Class Reminder</h5>
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="form-check-grps">
                      <div className="form-group mb-4 mr-5">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="setStudentClass"
                            name="setStudentClass"
                            checked={formik.values && formik.values.StudentCheck ? formik.values.StudentCheck : false}
                            onChange={(e) => { formik.setFieldValue("StudentCheck", e.target.checked); formik.setFieldValue("PushNotification", e.target.checked); }}
                            onBlur={formik.handleBlur}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="setStudentClass"
                          >
                            Enabled
                          </label>
                        </div>
                      </div>
                      <div className="send-via-blk mb-4">
                        <label>Send Via </label>
                        <div className="form-icon-group">
                          <div className="custom-control custom-checkbox text-left">
                            {/* <input type="checkbox" className="custom-control-input" id="customCheck2Student" name="push notification"
                            //  onChange={formik.handleChange} onBlur={formik.handleBlur} 
                            /> */}
                            <input
                              disabled={!formik.values.StudentCheck}
                              type="checkbox"
                              className="custom-control-input"
                              id="setStudentClass1"
                              name="setStudentClass.PushNotification"
                              checked={formik.values.StudentCheck && formik.values.PushNotification}
                              onChange={(e) => { formik.setFieldValue("PushNotification", e.target.checked); formik.setFieldValue("StudentCheck") }}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="setStudentClass1"
                            >
                              Push Notification
                            </label>
                          </div>
                          {/* <div className="custom-control custom-checkbox text-left ml-4">
                            <input
                              disabled={formik.values.setStudentClass && !formik.values.setStudentClass.StudentCheck}
                              type="checkbox"
                              className="custom-control-input"
                              id="setStudentClass2"
                              name="setStudentClass.EmailNotification"
                              checked={formik.values.setStudentClass && formik.values.setStudentClass.EmailNotification}
                              onChange={(e) => formik.setFieldValue("setStudentClass.EmailNotification", e.target.checked)}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="setStudentClass2"
                            >
                              Email
                            </label>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label>Before Class Starts</label>
                      <input
                        type="text"
                        className={
                          "form-control" +
                          (formik.errors.studentBeforeTime &&
                            formik.touched.studentBeforeTime
                            ? " is-invalid"
                            : "")
                        }
                        placeholder="30 min"
                        name="studentBeforeTime"
                        id="studentBeforeTime"
                        value={formik.values.studentBeforeTime + " min"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <label>Email editing setting </label>
                    <div className="form-group-blk mt-2">
                      <a
                        style={{
                          fontSize: "24px",
                          color: "var(--submenu-hover-color)",
                        }}
                        href={`https://www.myaie.ac/cadcocms/emails_list_edit.php?uid=55`}
                        target="_blank"
                      >
                        <i className="fal fa-cog"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <h5>Presenter class reminder</h5>
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="form-check-grps">
                      <div className="form-group mb-4 mr-5">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="setPresentClass"
                            name="setPresentClass"
                            checked={formik.values.PresentCheck}
                            onChange={(e) => { formik.setFieldValue("PresentCheck", e.target.checked); formik.setFieldValue("EmailNotification", e.target.checked) }}
                            onBlur={formik.handleBlur}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="setPresentClass"
                          >
                            Enabled
                          </label>
                        </div>
                      </div>
                      <div className="send-via-blk mb-4">
                        <label>Send Via </label>
                        <div className="form-icon-group">
                          {/* <div className="custom-control custom-checkbox text-left">
                            <input
                              disabled={formik.values.setPresentClass && !formik.values.setPresentClass.PresentCheck}
                              type="checkbox"
                              className="custom-control-input"
                              id="setPresentClass1"
                              name="setPresentClass1"
                              checked={formik.values.setPresentClass && formik.values.setPresentClass.PushNotification}
                              onChange={(e) => formik.setFieldValue("setPresentClass.PushNotification", e.target.checked)}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="setPresentClass1"
                            >
                              Push Notification
                            </label>
                          </div> */}
                          <div className="custom-control custom-checkbox text-left ml-4">
                            {/* <input type="checkbox" className="custom-control-input" id="customCheck3Presenter" name="dhetExasm"
                            //  onChange={formik.handleChange} onBlur={formik.handleBlur} 
                            /> */}
                            <input
                              disabled={!formik.values.PresentCheck}
                              type="checkbox"
                              className="custom-control-input"
                              id="setPresentClass2"
                              name="setPresentClass2"
                              checked={formik.values.PresentCheck && formik.values.EmailNotification}
                              onChange={(e) => { formik.setFieldValue("EmailNotification", e.target.checked); formik.setFieldValue("PresentCheck", e.target.checked) }}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="setPresentClass2"
                            >
                              Email
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label>Before Class Starts</label>
                      <input
                        type="text"
                        className={
                          "form-control" +
                          (formik.errors.presenterBeforeTime &&
                            formik.touched.presenterBeforeTime
                            ? " is-invalid"
                            : "")
                        }
                        placeholder="30 min"
                        name="presenterBeforeTime"
                        id="presenterBeforeTime"
                        value={formik.values.studentBeforeTime + " min"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <label>Email editing setting </label>
                    <div className="form-group-blk mt-2">
                      <a
                        style={{
                          fontSize: "24px",
                          color: "var(--submenu-hover-color)",
                        }}
                        href={`https://www.myaie.ac/cadcocms/emails_list_edit.php?uid=61`}
                        target="_blank"
                      >
                        <i className="fal fa-cog"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <h5>Class recording available</h5>
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="form-check-grps">
                      <div className="form-group mb-4 mr-5">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="setClassRecording"
                            name="setClassRecording"
                            checked={formik.values.setClassRecording && formik.values.setClassRecording.RecordCheck}
                            onChange={(e) => { formik.setFieldValue("setClassRecording.RecordCheck", e.target.checked); formik.setFieldValue("setClassRecording.PushNotification", false); formik.setFieldValue("setClassRecording.EmailNotification", false) }}
                            onBlur={formik.handleBlur}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="setClassRecording"
                          >
                            Enabled
                          </label>
                        </div>
                      </div>
                      <div className="send-via-blk mb-4">
                        <label>Send Via </label>
                        <div className="form-icon-group">
                          <div className="custom-control custom-checkbox text-left">
                            {/* <input type="checkbox" className="custom-control-input" id="customCheck2Class" name="dhetExam"
                            //  onChange={formik.handleChange} onBlur={formik.handleBlur} 
                            /> */}
                            <input
                              disabled={formik.values.setClassRecording && !formik.values.setClassRecording.RecordCheck}
                              type="checkbox"
                              className="custom-control-input"
                              id="setClassRecording1"
                              name="setClassRecording1"
                              checked={formik.values.setClassRecording && formik.values.setClassRecording.PushNotification}
                              onChange={(e) => formik.setFieldValue("setClassRecording.PushNotification", e.target.checked)}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="setClassRecording1"
                            >
                              Push Notification
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox text-left ml-4">
                            <input
                              disabled={formik.values.setClassRecording && !formik.values.setClassRecording.RecordCheck}
                              type="checkbox"
                              className="custom-control-input"
                              id="setClassRecording2"
                              name="setClassRecording2"
                              checked={formik.values.setClassRecording && formik.values.setClassRecording.EmailNotification}
                              onChange={(e) => formik.setFieldValue("setClassRecording.EmailNotification", e.target.checked)}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="setClassRecording2"
                            >
                              Email
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <label>Email editing setting </label>
                    <div className="form-group-blk mt-2">
                      <a
                        style={{
                          fontSize: "24px",
                          color: "var(--submenu-hover-color)",
                        }}
                        href={`https://www.myaie.ac/cadcocms/emails_list_edit.php?uid=59`}
                        target="_blank"
                      >
                        <i className="fal fa-cog"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group form-group-save-cancel mt-3 mb-0">
              <div>
                <button
                  className="btn btn-save btn-success"
                  onClick={formik.handleSubmit}
                  type="submit"
                  title="Save"
                  disabled={saveDisbale}
                >
                  {saveDisbale ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}Save
                </button>
                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  title="Cancel"
                  onClick={() => { history.goBack() }}
                >
                  <i className="fal fa-times"></i>Cancel
                </button>
              </div>
              {Object.keys(formik.values).map(key => {
                if (formik.touched[key] && formik.errors[key]) {
                  if (key == "setClassRecording") {
                    return (
                      <div className="invalid-feedback d-block">
                        {formik.errors[key].EmailNotification}
                      </div>
                    )
                  }
                  return (
                    <div className="invalid-feedback d-block">
                      {formik.errors[key]}
                    </div>
                  )
                }
              })}
            </div>
          </form>
        </div>

        <div
          className="topic-add-modal modal fade"
          id="recurrance"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content modal-border-update">
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fal fa-save"></i> Class Recurrence
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="t p-30px">
                  <div className="card-header mb-4">Recurrence Pattern</div>
                  <div className="card-body-inr card-body-info">
                    <div className="basic-info-box">

                      <div className="recurrencePattern">
                        <div className="row">
                          <div className="col-md-2 popup-item-border-right">
                            <div
                              className="form-icon-group"
                              title="Recurrence Pattern"
                            >
                              <div className="dailyWeekdayHead">
                                <div
                                  className="custom-control custom-radio mb-3 mt-2"
                                  title="Daily"
                                >
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    id="daily"
                                    name="recurrence"
                                    checked={
                                      modalFormik.values.recurrence == "daily"
                                    }
                                    onChange={() => {
                                      modalFormik.setFieldValue(
                                        "recurrence",
                                        "daily"
                                      );
                                    }}
                                    onBlur={modalFormik.handleBlur}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="daily"
                                  >
                                    Daily
                                  </label>
                                </div>
                                <div
                                  className="custom-control custom-radio "
                                  title="Weekly"
                                >
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    title=""

                                    id="weekly"
                                    name="recurrence"
                                    checked={
                                      modalFormik.values.recurrence == "weekly"
                                    }
                                    onChange={() => {
                                      modalFormik.setFieldValue(
                                        "recurrence",
                                        "weekly"
                                      );
                                    }}
                                    onBlur={modalFormik.handleBlur}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="weekly"
                                  >
                                    Weekly
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-10">
                            {modalFormik.values.recurrence == "daily" ? (
                              <div className="basic-info-box-list modal-info-list">
                                <ul>
                                  <li className="popup-item-vertical-center">
                                    <div
                                      className="custom-control custom-radio mr-2"
                                      title="Every"
                                    >
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        id="every"
                                        name="recurrence_pattern"
                                        checked={
                                          modalFormik.values.recurrence_pattern ==
                                          "every_day"
                                        }
                                        onChange={() =>
                                          modalFormik.setFieldValue(
                                            "recurrence_pattern",
                                            "every_day"
                                          )
                                        }
                                        onBlur={modalFormik.handleBlur}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="every"
                                      >
                                        Every
                                      </label>
                                    </div>
                                    <input
                                      type="text"
                                      id="daily_days"
                                      name="daily_days"
                                      className="form-control w-25"
                                      title="Daily days"
                                      value={modalFormik.values.daily_days}
                                      onChange={(e) => {
                                        const re = /^[0-9\b]+$/;

                                        // if value is not blank, then test the regex

                                        if (e.target.value === '' || re.test(e.target.value)) {
                                          return modalFormik.setFieldValue("daily_days", e.target.value)
                                        }
                                      }}
                                      onBlur={modalFormik.handleBlur}
                                    />
                                    <p>Days</p>
                                  </li>
                                  <li>
                                    <div
                                      className="custom-control custom-radio mr-2"
                                      title="Every Weekday"
                                    >
                                      <input
                                        type="radio"
                                        id="every_weekday"
                                        name="recurrence_pattern"
                                        checked={
                                          modalFormik.values.recurrence_pattern ==
                                          "every_weekday"
                                        }
                                        onChange={() =>
                                          modalFormik.setFieldValue(
                                            "recurrence_pattern",
                                            "every_weekday"
                                          )
                                        }
                                        className="custom-control-input"
                                        onBlur={formik.handleBlur}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="every_weekday"
                                      >
                                        Every Weekday
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            ) : (
                              <div className="basic-info-box-list modal-info-list">
                                <ul>
                                  <li className="popup-item-vertical-center">
                                    <span className="title">Recur every</span>
                                    <input
                                      type="text"
                                      name="weeks"
                                      id="weeks"
                                      value={modalFormik.values.weeks}
                                      onChange={(e) => {
                                        const re = /^[0-9\b]+$/;

                                        // if value is not blank, then test the regex

                                        if (e.target.value === '' || re.test(e.target.value)) {
                                          return modalFormik.setFieldValue("weeks", e.target.value)
                                        }
                                      }}
                                      className={
                                        "form-control w-25" +
                                        (modalFormik.errors.weeks &&
                                          modalFormik.touched.weeks
                                          ? " is-invalid"
                                          : "")
                                      }
                                      title="Weeks"
                                      onBlur={formik.handleBlur}
                                    />
                                    <p>week(s) on:</p>
                                  </li>
                                  <li>
                                    <div className="form-group-blk">
                                      <label></label>
                                      <div className="custom-check custom-control custom-checkbox custom-control-inline mt-1">
                                        <input
                                          type="checkbox"
                                          title="Monday"
                                          checked={modalFormik.values.weekday.includes(
                                            "Monday"
                                          )}
                                          className="custom-control-input"
                                          id="monday"
                                          name="weekday"
                                          onChange={() => weeklyHandle("Monday")}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="monday"
                                        >
                                          Monday
                                        </label>
                                      </div>
                                      <div className="custom-check custom-control custom-checkbox custom-control-inline mt-1">
                                        <input
                                          type="checkbox"
                                          title="Tuesday"
                                          checked={modalFormik.values.weekday.includes(
                                            "Tuesday"
                                          )}
                                          className="custom-control-input"
                                          id="tuesday"
                                          name="weekday"
                                          onChange={() => weeklyHandle("Tuesday")}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="tuesday"
                                        >
                                          Tuesday
                                        </label>
                                      </div>
                                      <div className="custom-check custom-control custom-checkbox custom-control-inline mt-1">
                                        <input
                                          type="checkbox"
                                          title="Wednesday"
                                          checked={modalFormik.values.weekday.includes(
                                            "Wednesday"
                                          )}
                                          onChange={() => weeklyHandle("Wednesday")}
                                          className="custom-control-input"
                                          id="wednesday"
                                          name="weekday"
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="wednesday"
                                        >
                                          Wednesday
                                        </label>
                                      </div>
                                      <div className="custom-check custom-control custom-checkbox custom-control-inline mt-1">
                                        <input
                                          type="checkbox"
                                          title="Thursday"
                                          className="custom-control-input"
                                          id="thursday"
                                          name="weekday"
                                          checked={modalFormik.values.weekday.includes(
                                            "Thursday"
                                          )}
                                          onChange={() => weeklyHandle("Thursday")}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="thursday"
                                        >
                                          Thursday
                                        </label>
                                      </div>
                                      <div className="custom-check custom-control custom-checkbox custom-control-inline mt-1">
                                        <input
                                          type="checkbox"
                                          title="Friday"
                                          checked={modalFormik.values.weekday.includes(
                                            "Friday"
                                          )}
                                          onChange={() => weeklyHandle("Friday")}
                                          className="custom-control-input"
                                          id="friday"
                                          name="weekday"
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="friday"
                                        >
                                          Friday
                                        </label>
                                      </div>
                                      <div className="custom-check custom-control custom-checkbox custom-control-inline mt-1">
                                        <input
                                          type="checkbox"
                                          title="Saturday"
                                          checked={modalFormik.values.weekday.includes(
                                            "Saturday"
                                          )}
                                          onChange={() => weeklyHandle("Saturday")}
                                          className="custom-control-input"
                                          id="saturday"
                                          name="weekday"
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="saturday"
                                        >
                                          Saturday
                                        </label>
                                      </div>
                                      <div className="custom-check custom-control custom-checkbox custom-control-inline mt-1">
                                        <input
                                          type="checkbox"
                                          title="Sunday"
                                          checked={modalFormik.values.weekday.includes(
                                            "Sunday"
                                          )}
                                          onChange={() => weeklyHandle("Sunday")}
                                          className="custom-control-input"
                                          id="sunday"
                                          name="weekday"
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="sunday"
                                        >
                                          Sunday
                                        </label>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="range-of-recurrence">
                        <div className="card-header mb-4">Range of Recurrence</div>
                        <div className="row">

                          <div className="col-md-12">
                            <div className="row">
                              {subTab == "open" && <div className="col-md-4">
                                <div className="row">
                                  <div className="col-md-12 mb-4">
                                    <div
                                      className="form-icon-group"
                                      title="Range of Recurrence"
                                    >
                                      <div className="d-flex popup-item-vertical-center">
                                        <label className="mr-2">Start</label>
                                        <DatePicker
                                          minDate={new Date()}
                                          dateFormat={FORM_DATE_FORMAT}

                                          showYearDropdown

                                          scrollableYearDropdown
                                          dateFormatCalendar="MMMM"
                                          selected={modalFormik.values.range_start_date}
                                          onChangeRaw={(e) => e.preventDefault()}
                                          className="form-control w-100"
                                          onChange={(date) => {
                                            modalFormik.setFieldValue(
                                              "range_start_date",
                                              date
                                            );
                                          }}

                                        />

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>}
                              <div className="col-md-8">
                                <div className="row">
                                  <div className="col-md-12 mb-4">
                                    <div
                                      className="form-icon-group"
                                      title="Range of Recurrence"
                                    >
                                      <div className="">
                                        <div
                                          className="custom-control custom-radio custom-control-inline popup-item-vertical-center recurring-custom-control"
                                          title="End By"
                                        >
                                          <input
                                            type="radio"
                                            id="endby"
                                            name="recurrence_range"
                                            checked={
                                              modalFormik.values.recurrence_range == 0
                                            }
                                            onChange={() =>
                                              modalFormik.setFieldValue(
                                                "recurrence_range",
                                                0
                                              )
                                            }
                                            className="custom-control-input"
                                            onBlur={formik.handleBlur}
                                          />
                                          <label
                                            className="custom-control-label custom-control-inline recurring-custom-control"
                                            htmlFor="endby"
                                          >
                                            End By
                                          </label>
                                          <DatePicker
                                            disabled={
                                              modalFormik.values.recurrence_range == 1
                                            }
                                            minDate={new Date(formik.values.date).setDate(new Date(formik.values.date).getDate() + 1)}
                                            dateFormat={FORM_DATE_FORMAT}

                                            showYearDropdown

                                            scrollableYearDropdown
                                            dateFormatCalendar="MMMM"
                                            selected={modalFormik.values.range_end_date}
                                            onChangeRaw={(e) => e.preventDefault()}
                                            className="form-control w-100"
                                            onChange={(date) => {
                                              modalFormik.setFieldValue(
                                                "range_end_date",
                                                date
                                              );
                                            }}
                                          //  className={
                                          //   "form-control cursor-pointer" +
                                          //   (formik.errors.date && formik.touched.date
                                          //     ? " is-invalid"
                                          //     : "")
                                          // }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mb-4">
                                    <div className="form-icon-group" title="">
                                      <div
                                        className="custom-control custom-radio custom-control-inline popup-item-vertical-center"
                                        title="End After"
                                      >
                                        <input
                                          type="radio"
                                          id="end2"
                                          name="recurrence_range"
                                          checked={
                                            !modalFormik.values.recurrence_range == 0
                                          }
                                          onChange={() =>
                                            modalFormik.setFieldValue("recurrence_range", 1)
                                          }
                                          className="custom-control-input"
                                          title="SendWhen"
                                          onBlur={formik.handleBlur}
                                        />
                                        <label
                                          className="custom-control-label custom-control-inline"
                                          htmlFor="end2"
                                        >
                                          End After
                                        </label>
                                        <div className="basic-info-box-list modal-info-list custom-control-inline">
                                          <input
                                            type="text"
                                            name="ocuurence"
                                            id="ocuurence"
                                            onChange={(e) => {
                                              const re = /^[0-9\b]+$/;

                                              // if value is not blank, then test the regex

                                              if (e.target.value === '' || re.test(e.target.value)) {
                                                return modalFormik.setFieldValue("ocuurence", e.target.value)
                                              }
                                            }}
                                            value={modalFormik.values.ocuurence}
                                            // onChange={modalFormik.handleChange}
                                            // onKeyDown={(e) => handleOcuurence(e)}
                                            disabled={
                                              modalFormik.values.recurrence_range == 0
                                            }
                                            className={
                                              "form-control w-100" +
                                              (modalFormik.errors.ocuurence &&
                                                modalFormik.touched.ocuurence
                                                ? " is-invalid"
                                                : "")
                                            }
                                            title="Occurence"
                                            onBlur={formik.handleBlur}
                                          />
                                          <span className="title ml-2 popup-item-vertical-center">
                                            Occurences
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      <hr />
                      <div className="card-header mb-4">Prefix / Sufix</div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-icon-group" title="">
                            <div className="prefix-sufix-box">
                              <div style={{ zIndex: "inherit" }}
                                className="custom-control custom-radio custom-control-inline"
                                title="None"
                              >
                                <input
                                  type="radio"
                                  id="none"
                                  name="prefix"
                                  checked={modalFormik.values.prefix == "none"}
                                  onChange={() =>
                                    modalFormik.setFieldValue("prefix", "none")
                                  }
                                  className="custom-control-input"
                                  onBlur={formik.handleBlur}
                                />
                                <label
                                  className="custom-control-label custom-control-inline"
                                  htmlFor="none"
                                >
                                  None
                                </label>
                              </div>
                              <div style={{ zIndex: "inherit" }}
                                className="custom-control custom-radio custom-control-inline"
                                title="Prefix"
                              >
                                <input
                                  type="radio"
                                  id="prefix"
                                  name="prefix"
                                  checked={modalFormik.values.prefix == "prefix"}
                                  onChange={() =>
                                    modalFormik.setFieldValue("prefix", "prefix")
                                  }
                                  className="custom-control-input"
                                  onBlur={formik.handleBlur}
                                />
                                <label
                                  className="custom-control-label custom-control-inline"
                                  htmlFor="prefix"
                                >
                                  Prefix
                                </label>
                              </div>
                              <div style={{ zIndex: "inherit" }}
                                className="custom-control custom-radio custom-control-inline"
                                title="Sufix"
                              >
                                <input
                                  type="radio"
                                  id="suffix"
                                  name="prefix"
                                  checked={modalFormik.values.prefix == "sufix"}
                                  onChange={() =>
                                    modalFormik.setFieldValue("prefix", "sufix")
                                  }
                                  className="custom-control-input"
                                  onBlur={formik.handleBlur}
                                />
                                <label
                                  className="custom-control-label custom-control-inline"
                                  htmlFor="suffix"
                                >
                                  Sufix
                                </label>
                              </div>
                              <div className="enterText custom-control-inline">
                                <input
                                  type="text"
                                  placeholder="enter text"
                                  disabled={modalFormik.values.prefix == "none"}
                                  className="form-control"
                                  title="class text"
                                  name="class_text"
                                  onBlur={formik.handleBlur}
                                  value={modalFormik.values.class_text}
                                  onChange={modalFormik.handleChange}
                                />
                              </div>
                              <div className="basic-info-box-list selectType modal-info-list custom-control-inline">
                                <Select
                                  isDisabled={modalFormik.values.prefix == "none"}
                                  className={
                                    "form-control custom-select-box" +
                                    (modalFormik.errors.increment_type &&
                                      modalFormik.touched.increment_type
                                      ? " is-invalid"
                                      : "")
                                  }
                                  title="Increment Type"
                                  placeholder={
                                    modalFormik.values.increment_type
                                      ? modalFormik.values.increment_type
                                      : "Select Type"
                                  }
                                  options={incrementType}
                                  onChange={(value) => {
                                    if (value) {
                                      modalFormik.setFieldValue("increment_type", value.value);
                                      if (value.value == "numeric") {
                                        modalFormik.setFieldValue("increment_value", 1);
                                      } else {
                                        modalFormik.setFieldValue("increment_value", "A");
                                      }
                                    } else {
                                      modalFormik.setFieldValue("increment_type", "");
                                      modalFormik.setFieldValue("increment_value", "");
                                    }
                                  }}
                                  onBlur={modalFormik.handleBlur}
                                  name="increment_type"
                                  value={incrementType?.filter(val => val.value === modalFormik.values.increment_type)}
                                  // value={incrementType.filter((obj) => {
                                  //   return incrementType?.includes(obj.value)
                                  //     ? obj
                                  //     : "";
                                  // })}
                                  isClearable
                                />
                              </div>
                              <div className="incrementValueBox custom-control-inline">
                                <input
                                  disabled={modalFormik.values.prefix == "none"}
                                  type="text"
                                  id="increment_value"
                                  name="increment_value"
                                  className={
                                    "form-control" +
                                    (modalFormik.errors.increment_value &&
                                      modalFormik.touched.increment_value
                                      ? " is-invalid"
                                      : "")
                                  }
                                  title="Increment Value"
                                  value={modalFormik.values.increment_value}
                                  // onChange={modalFormik.handleChange}
                                  onKeyDown={(e) => handleIncrement(e)}
                                  onBlur={modalFormik.handleBlur}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <div className="form-group form-group-save-cancel mt-3 popup-button-margin">
                {modalFormik.isValid ? (
                  <button
                    type="button"
                    title="Save"
                    className="btn btn-primary"
                    // data-dismiss="modal"
                    onClick={modalFormik.handleSubmit}
                  // onClick={handleSendResponse} disabled={disableNeeded}
                  >
                    <i className="fal fa-save"></i>
                    Save
                  </button>
                ) : (
                  <button
                    type="button"
                    title="Save"
                    className="btn btn-primary"
                    onClick={() => {
                      modalFormik.handleSubmit()
                      formik.setFieldValue("isRecurring", 1)
                    }

                    }
                  // onChange={()=>formik.setFieldValue("isRecurring",1)}

                  // onClick={handleSendResponse} disabled={disableNeeded}
                  >
                    <i className="fal fa-save"></i>
                    Save
                  </button>
                )}
                <button
                  type="button"
                  title="Remove Recurrence"
                  className="btn btn-primary"
                  // data-dismiss="modal"
                  onClick={() => handleRecuurence()}
                // handleRecuurence onChange={()=>formik.setFieldValue("isRecurring",0)}
                >
                  <i className="fal fa-times"></i>
                  Remove Recurrence
                </button>

                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  title="Close"
                >
                  <i className="fal fa-times"></i>
                  Close
                </button>
                {Object.keys(modalFormik.values).map(key => {
                  if (modalFormik.touched[key] && modalFormik.errors[key]) {
                    return (
                      <div className="invalid-feedback d-block">
                        {modalFormik.errors[key]}
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineClassesForm;
