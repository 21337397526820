import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import { GetStudentDetailsCancelToken } from "../../../services/StudentsSettingServices";
import axios from "axios";
import AuditTrail from "../../StudentAdministration/Students/AuditTrail";
import WebsiteInformation from "./WebsiteInformation";
import { GetWebsiteUpdateList } from "../../../services/WebsiteListServer";
// This will be shown in merge-all branch

function AddWebsiteList() {
    const history = useHistory();
    const { type, id = "", tab, subType, subId } = useParams();
    const [loading, setLoading] = useState(false);
    const [websiteDetail, setWebsiteDetail] = useState([]);
    const [updatedWebsiteName, setUpdatedWebsiteName] = useState([]);

    const handleUpdate = (id) => {
        GetWebsiteUpdateList(id)
            .then(res => {
                setUpdatedWebsiteName(res?.data?.Data);
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        const cancelTokenSources = [];

        const getData = async () => {
            setLoading(true);
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);

            try {
                setWebsiteDetail({ first_name: "", last_name: "" });
                const res = await GetStudentDetailsCancelToken(id, source.token);
                if (res.status == 200) {
                    setWebsiteDetail(res.data.data_list);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            } finally {
                setLoading(false);
            }
        }

        if (type == "open") {
            getData();
        }

        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [type]);

    useEffect(() => {
        handleUpdate(id)
    }, [id])
    

    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div className={"sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")}>
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                Site Setup&nbsp;:&nbsp;
                                                <span>{type === "create" ? "Create New Website" : updatedWebsiteName?.siteName}</span>
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {(type == "open" || type == "auditTrail") && (
                                        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                            <li className="nav-item" title="Website Information" onClick={() => history.push(`/websiteManagement/siteSetup/table/open/${id}`)}>
                                                <Link
                                                    className={`nav-link ${type === "open" && "active"}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/siteSetup/table/open/${id}`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                >
                                                    <i class="fal fa-info-circle"></i>
                                                    Website Information
                                                </Link>
                                            </li>
                                            <li className="nav-item" title="Audit trail" onClick={() => history.push(`/websiteManagement/siteSetup/table/auditTrail/${id}`)}>
                                                <Link
                                                    className={`nav-link ${type === "auditTrail" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/websiteManagement/siteSetup/table/auditTrail/${id}`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                >
                                                    <i className="fal fa-history"></i>Audit trail
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {type == "create" && <WebsiteInformation />}
                                            {type == "open" && <WebsiteInformation />}
                                            {type == "auditTrail" && <AuditTrail />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddWebsiteList;
