import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import { useHistory, useLocation } from "react-router";
import { Link, useParams } from "react-router-dom";
import { GetBrandSettingDetail, GetBrandSettingDetailCancelToken } from "../../../services/BrandServices";
import EditBrandSetting from "./EditBrandSetting";
import Swal from "sweetalert2";
import axios from "axios";

const EditBrand = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [brandSettingData, setBrandSettingData] = useState({});
  
  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const response = await GetBrandSettingDetailCancelToken(id,dropdownSource.token);
        if (response?.status === 200) {
          if(response?.data?.brand_setting){
            setBrandSettingData(response?.data?.brand_setting);
          }else{
            Swal.fire({
              icon: "Info",
              title: "Info",
              text: "No record found",
            })
  
            history.push("/administration/brandSetting/list")
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
    }, []);

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className=" wrap-box-right">
              <div className="my-tickets-heading-blk flex-direction mb-2 w-100">
                <div className="d-flex align-items-center ">
                  <div className="name-w-head d-flex align-items-center ">
                    <h4 className="text-left-align landing-heading heading_color_dynamic">Brand:  <span>{brandSettingData && brandSettingData?.brand_name}</span></h4>
                  </div>
                  <div className="text-md-right action2-blk">
                    <div className="ticket-view-btn d-flex align-items-center">
                      <div className="add-new-ticket-bx">
                        <button onClick={()=>history.goBack()} title="Back" className="btn btn-white-bordered">
                          <i className="fal fa-angle-left"></i>Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                {/*  */}
                <div className="tabs-wrap">
                  <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li className="nav-item" onClick={() => history.push(`/administration/brandSetting/editBrand/${brandSettingData?.id}`)}>
                      <div className="nav-link active" id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                        <i className="fal fa-info-circle"></i>Details
                      </div>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          location.pathname.includes("add") ? `/administration/brandSetting/addPortalSetting/visualsTab/${id}` : `/administration/brandSetting/editPortalSetting/visualsTab/${id}`
                        )
                      }
                    >
                      <a className="nav-link " id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                      <i className="fal fa-laptop"></i>Visuals
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => history.push(`/administration/brandSetting/singleAuditTrail/${id}`)}
                      title={"Audit Trail"}
                    >
                      <Link
                        className="nav-link "
                        aria-disabled={false}
                        id="pills-tab1"
                        data-toggle="pill"
                        to={"/administration/brandSetting/singleAuditTrail"}
                        role="listitem"
                      >
                        <i className="fal fa-history"></i>Audit Trail
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      <EditBrandSetting brandSettingData={brandSettingData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBrand;
