import React, { useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import TicketList from "../components/supportadministration/TicketList";
import AuditTrail from "../components/supportadministration/AuditTrail";
import Tabs from "../components/studentsupport/Tabs";
import { useHistory, useParams } from "react-router-dom";

const SupportAdministration = () => {

  const { tab } = useParams();
  const history = useHistory();

  return <>
    <Header></Header>
    <div className="content-wrapper d-flex flex-wrap">
      <SideBar></SideBar>
      <div className="sidebar-right">
        <div className="sub-menu-content-block">
          {/* <Tabs /> */}
          <div className="sub-menu-right-block">
            <div className="wrap-box-right">
              <div className="my-tickets-heading-blk flex-direction mb-2">
                <div className="d-flex align-items-center ">
                  <div className="name-w-head d-flex align-items-center ">
                    <h4 className="text-left-align landing-heading heading_color_dynamic">
                      Ticket <span>List</span>
                    </h4>
                  </div>
                  <div className="text-md-right action2-blk">
                    <div className="ticket-view-btn d-flex align-items-center">
                      <div className="add-new-ticket-bx">
                        <button
                          onClick={() => history.goBack()}
                          title="Back"
                          className="btn btn-white-bordered"
                        >
                          <i className="fal fa-angle-left"></i>Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tabs-wrap">
                <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                  {/* <li className="nav-item" onClick={() => history.push("/support") }>
                  <a className={`nav-link ${tab !== "audittrail" ? "active" : ""}`} id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true">
                    <i className="fal fa-ticket-alt"></i>
                    Tickets
                  </a>
                </li> */}
                  <li
                    className="nav-item"
                    onClick={() => { history.push("/support/all") }}
                  >
                    <a
                      className={`nav-link cursor-pointer ${tab === "all" ? "active" : ""}`}
                      id="pills-tab2"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="pills-tab12"
                      aria-selected="true"
                    >
                      <i className="fal fa-globe-africa"></i>All Tickets
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => { history.push("/support/TotalDangerTicket") }}
                  >
                    <a
                      className={`nav-link cursor-pointer ${tab === "TotalDangerTicket" ? "active" : ""}`}
                      id="pills-tab2"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="pills-tab12"
                      aria-selected="true"
                    >
                      <i className="fal fa-file-exclamation"></i>Tickets in Danger Zone
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => { history.push("/support/TotalNewTicket") }}
                  >
                    <a
                      className={`nav-link cursor-pointer ${tab === "TotalNewTicket" ? "active" : ""}`}
                      id="pills-tab3"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="pills-tab13"
                      aria-selected="true"
                    >
                      <i className="fal fa-laptop"></i>New
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => { history.push("/support/TotalPendingAcademyTicket") }}
                  >
                    <a
                      className={`nav-link cursor-pointer ${tab === "TotalPendingAcademyTicket" ? "active" : ""}`}
                      id="pills-tab1"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="pills-tab11"
                      aria-selected="true"
                    >
                      <i className="fal fa-file-spreadsheet"></i>
                      Pending-Academy
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => { history.push("/support/TotalPendingStudentTicket") }}
                  >
                    <a
                      className={`nav-link cursor-pointer ${tab === "TotalPendingStudentTicket" ? "active" : ""
                        }`}
                      id="pills-tab1"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="pills-tab11"
                      aria-selected="true"
                    >
                      <i className="fal fa-file-alt"></i>
                      Pending Student
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => { history.push("/support/Pending-Investigation") }}
                  >
                    <a
                      className={`nav-link cursor-pointer ${tab === "Pending-Investigation" ? "active" : ""}`}
                      id="pills-tab1"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="pills-tab11"
                      aria-selected="true"
                    >
                      <i className="fal fa-file-search"></i>
                      Pending-Investigation
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => { history.push("/support/TotalClosedTicket") }}
                  >
                    <a
                      className={`nav-link cursor-pointer ${tab === "TotalClosedTicket" ? "active" : ""}`}
                      id="pills-tab1"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="pills-tab11"
                      aria-selected="true"
                    >
                      <i className="fal fa-file-check"></i>
                      Tickets Closed
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => history.push("/support/audittrail")} >
                    <a className={`nav-link ${tab === "audittrail" ? "active" : ""}`} id="pills-tab2" data-toggle="pill" href="#pills-tab12" role="tab" aria-controls="pills-tab12" aria-selected="true">
                      <i className="fal fa-history"></i>Audit trail
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div className={"tab-pane fade " + !tab ? "active show" : ""} id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                    {tab != "audittrail" && <TicketList />}
                  </div>
                  <div className={"tab-pane fade " + tab == "audittrail" ? "active show" : ""} id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
                    {tab == "audittrail" && <AuditTrail />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>

};

export default SupportAdministration;
