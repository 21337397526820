import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetCommonContent, GetStudentDropdown, UpdateStudentPersonalInfo } from "../../../../../services/StudentsSettingServices";
import moment from "moment";
import { IMAGE_URL, NAME_REGEX, FORM_DATE_FORMAT } from "../../../../../utils/Constants";
import Swal from "sweetalert2";
import { myCompare } from "../../../../common/MyCompare";
import { ImagePopup } from "../../../../common/ImagePopup";
import PermissionsGate from "../../../../../utils/permissionGate";
import { getNationlityList } from "../../../../../services/RegistrationService";

function BasicDetailsBlock({ studentData, loading, setUpdateRow, updateRow, setFilePreview, filePreview }) {
  const { id } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [studentData, setStudentData] = useState();
  const [sData, setSData] = useState(studentData);
  const [profilePic, setProfilePic] = useState();
  const [profilePicName, setProfilePicName] = useState();

  const [nationality, setNationality] = useState([]);
  const [title, setTitle] = useState([]);
  const [race, setRace] = useState([]);
  const [gender, setGender] = useState([]);
  const [disablity, setDisablity] = useState([]);
  const [homeLan, setHomeLan] = useState([]);
  const [dateOfBirth, setDateOfBirth] = useState();

  const fixDataFormet = (obj) => {
    let tempArr = [];
    Object.keys(obj).map((item) => {
      tempArr.push({ value: item, label: obj[item] });
    });
    return tempArr;
  };

  const fixDataFormetForNationality = (obj) => {
    let tempArr = [];
    obj.map((item) => {
      tempArr.push({ value: item.crm_country_slug ? item.crm_country_slug : item.country_code, label: item.country });
    });
    return tempArr;
  };

  useEffect(() => {
    if (studentData && studentData.bday_year) {
      setProfilePicName(studentData.photo);
      setDateOfBirth(moment(studentData.bday_year.toString() + "-" + studentData.bday_month + "-" + studentData.bday_day).unix());
    }
    GetStudentDropdown().then((res) => {
      setTitle(fixDataFormet(res.data.title));
      setRace(fixDataFormet(res.data.race));
      setDisablity(res.data.disability);
    });
    getNationlityList().then((res) => {
      setNationality(fixDataFormetForNationality(res.data.data));
    });
    GetCommonContent().then((res) => {
      setGender(res.data.gender);
      setHomeLan(res.data.language);
    });
  }, [sData, studentData]);

  const tempStatusArr = [];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      StudentNumber: studentData && studentData.student_crm_id ? studentData.student_crm_id : "",
      ProfilePicture: studentData ? studentData.photo : "",
      Initials: studentData && studentData.initials !== null ? studentData.initials : "",
      FirstName: studentData ? studentData.first_name : "",
      SecondName: studentData && studentData.second_name!=null ? studentData.second_name : "",
      ThirdName: studentData ? studentData.third_name : "",
      LastName: studentData ? studentData.last_name : "",
      MaidenName: studentData ? studentData.maiden : "",
      Title: studentData ? studentData.title : "",
      Nationality: studentData ? studentData.nationality : "",
      RSAID: studentData ? studentData.number : "",
      ExamId: studentData && studentData.exam_id_number!=null ? studentData.exam_id_number : "",
      DOB: studentData ? studentData.bday_date : "",
      Race: studentData ? studentData.race : "",
      Gender: studentData ? studentData.gender : "",
      Disablity: studentData ? studentData.disability : "",
      HomeLan: studentData ? studentData.language : "",
      MedicalAid: studentData ? studentData.medical_aid : "",
      MedicalAidNum: studentData ? studentData.medical_aid_number : "",
      EmergencyNumberOne: studentData && studentData.emergency_contact_no_one !== null ? studentData.emergency_contact_no_one : "",
      EmergencyContactNameOne: studentData && studentData.emergency_contact_name_one !== null ? studentData.emergency_contact_name_one : "",
      EmergencyNumberTwo: studentData ? studentData.emergency_contact_no_two : "",
      EmergencyContactNameTwo: studentData && studentData.emergency_contact_name_two !== null ? studentData.emergency_contact_name_two : "",
    },
    validationSchema: Yup.object({
      StudentNumber: Yup.string().required("Student Number is required").trim(),
      // ProfilePicture: Yup.mixed().required("Profile Picture is required"),
      // Initials: Yup.string().required("Initials is required").trim(),
      FirstName: Yup.string().matches(NAME_REGEX, "Please enter valid first name").required("First Name is required").trim(),
      // SecondName: Yup.string().required("Second Name is required").trim(),
      // ThirdName: Yup.string().required("Third Name is required").trim(),
      LastName: Yup.string().matches(NAME_REGEX, "Please enter valid last name").required("Last Name is required").trim(),
      // MaidenName: Yup.string().required("Maiden Name is required").trim(),
      // Title: Yup.string().required("Title is required"),
      // Nationality: Yup.string().required("Nationality is required"),
      RSAID: Yup.string().matches(/^[0-9A-Za-z]{13,}$/,"RSA ID number should be at least 13 characters"),
      ExamId: Yup.string().matches(/^[0-9A-Za-z]{13,}$/,"Exam ID number should be at least 13 characters"),
      // DOB: Yup.string().required("Date Of Birth is required"),
      // Race: Yup.string().required("Race is required"),
      // Gender: Yup.string().required("Gender is required"),
      // Disablity: Yup.string().required("Disablity is required"),
      // HomeLan: Yup.string().required("Home Language is required"),
      // MedicalAid: Yup.string().required("Medical Aid is required").trim(),
        // .required("Medical Aid Number is required")
        // .typeError("Medical Aid Number Must be a Number"),
        // .test("Is positive?", "Medical Aid Number Must Be Positve Number", (value) => value > 0),
     // EmergencyContactNameOne: Yup.string().required("Emergency Contact Name 1 is required").trim(),
      // EmergencyContactNameTwo: Yup.string().required("Emergency Contact Name 2 is required").trim(),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      let formData = new FormData();
      formData.append("section_type", "basic_info");
      formData.append("student_id", id);
      formData.append("student_number", values.StudentNumber);
      formData.append("photo", values.ProfilePicture);
      formData.append("initials", values.Initials);
      formData.append("first_name", values.FirstName);
      formData.append("second_name", values.SecondName);
      formData.append("third_name", values.ThirdName);
      formData.append("last_name", values.LastName);
      formData.append("title", values.Title);
      formData.append("nationality", values.Nationality);
      formData.append("rsadata", values.RSAID);
      formData.append("exam_id_number", values.ExamId);
      formData.append("race", values.Race);
      formData.append("gender", values.Gender);
      formData.append("disability", values.Disablity);
      formData.append("language", values.HomeLan);
      formData.append("medical_aid", values.MedicalAid);
      formData.append("medical_aid_number", values.MedicalAidNum);
      formData.append("maiden", values.MaidenName);
      formData.append("emergency_contact_name_one", values.EmergencyContactNameOne);
      formData.append("emergency_contact_no_one", values.EmergencyNumberOne);
      formData.append("emergency_contact_name_two", values.EmergencyContactNameTwo);
      formData.append("emergency_contact_no_two", values.EmergencyNumberTwo);
      formData.append("bday_date", values?.DOB && values?.DOB !== null ? moment(values.DOB).unix() : "");
      UpdateStudentPersonalInfo(formData)
        .then((res) => {
          setDisabled(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
          setUpdateRow(!updateRow);
        })
        .catch((err) => {
          setDisabled(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.message,
          });
          setUpdateRow(!updateRow);
        });
    },
  });

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let formData = new FormData();
        formData.append("section_type", "deletePhoto");
        formData.append("student_id", id);
        UpdateStudentPersonalInfo(formData)
          .then((res) => {
            formik.setFieldValue("photo", "");
            setDisabled(false);
            Swal.fire({
              icon: "warning",
              title: "Deleting Record",
              showConfirmButton: false,
            });
            Swal.fire("Deleted!", "File has been deleted.", "success");
          })
          .catch((err) => {
            setDisabled(false);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err.response.data.message,
            });
          });
        setUpdateRow(!updateRow);
      }
    });
  };

  const showImage = (link) => {
    // var string = doc.output(link);
    // var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
    // var x = window.open();
    // x.document.open();
    // x.document.write(iframe);
    // x.document.close();

    var newTab = window.open();
    newTab.document.body.innerHTML = "<img src=" + link + ' width="100px" height="100px">';

    // var win = window.open();
    // win.document.write('<iframe src="' + link + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      {/* {filePreview ? <ImagePopup link={filePreview
        ? filePreview
        : (IMAGE_URL +
          "/" +
          profilePicName
            .replace("/public_html", "")
            .replace("public", ""))}
      />
        : null} */}
      <div className="edit-icon new-card-header">
        <div className="card-header">Basic Details</div>
        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      {/* {loading ? <CustomSekletonForm num={23} belowCol={2} btnNum={2} /> : */}
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="StudentNumber">Student Number *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="StudentNumber"
                className={"form-control" + (formik.errors.StudentNumber && formik.touched.StudentNumber ? " is-invalid" : "")}
                name="StudentNumber"
                placeholder="Enter Student Number"
                value={formik.values.StudentNumber}
                onChange={formik.handleChange}
                disabled
                onBlur={formik.handleBlur}
                title="Student Number"
              />
              {formik.errors.StudentNumber && formik.touched.StudentNumber ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label>Profile Picture</label>
              <div className="form-group atttach-file">
                <label className={formik.errors.ProfilePicture && formik.touched.ProfilePicture && "file-req is-invalid"}>
                  <i className="fal fa-paperclip"></i>
                  <span>Add Attachment</span>
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control  form-control-aatch-file"
                    onChange={(e) => {
                      if (e.target.files.length) {
                        let reader = new FileReader();
                        reader.onload = (e) => {
                          setFilePreview(e.target.result);
                        };
                        reader.readAsDataURL(e.target.files[0]);
                        formik.setFieldValue("ProfilePicture", e.target.files[0]);
                        setProfilePic(e.target.files[0]);
                        setProfilePicName(e.target.files[0].name);
                      }
                    }}
                  />
                </label>
              </div>
              {profilePicName && (
                <>
                  {filePreview ? (
                    <>
                      {/* <div className="form-icon-group" title="Profile">
                        <figure className="upload-img-size" title="Porfile">
                          <img src={filePreview} alt={"Student Profile"} />
                        </figure>
                      </div> */}
                      <div className="frm-group">
                        <ul className="list-unstyled attached-file-ul m-0">
                          <li>
                            <a>
                              <i className="fal fa-file"></i>
                              &nbsp;{profilePicName}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <div className="form-icon-group" title="Profile">
                        <figure className="upload-img-size" title="Profile">
                          <img src={IMAGE_URL + "/" + profilePicName.replace("/public_html", "").replace("public", "")} alt={"Student Profile"} />
                        </figure>
                      </div> */}
                      <div className="frm-group">
                        <ul className="list-unstyled attached-file-ul m-0">
                          <li>
                            <a href={IMAGE_URL + "/" + profilePicName.replace("home/myaie/public_html/", "").replace("public/", "")}>
                              <i className="fal fa-file"></i>
                              &nbsp;{profilePicName.split("/").reverse()[0]}
                            </a>
                            {!filePreview ? (
                              <PermissionsGate errorProps={{ disabled: true }} scopes={["spidelete"]}>
                                <button className="btn btn-danger" onClick={() => handleDelete()} type={"button"}>
                                  <i className="fal fa-trash-alt"></i>
                                </button>
                              </PermissionsGate>
                            ) : (
                              ""
                            )}
                          </li>
                        </ul>
                      </div>
                    </>
                    // <a data-toggle="modal" data-target="#exampleModal" href={filePreview ? filePreview : IMAGE_URL + "/" + profilePicName.replace("/public_html", "").replace("public", "")}>
                    //   <i className="fal fa-file"></i>
                    //   &nbsp;{profilePicName.split("/").reverse()[0]}
                    // </a>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Initials">Initials</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="Initials"
                className={"form-control" + (formik.errors.Initials && formik.touched.Initials ? " is-invalid" : "")}
                name="Initials"
                placeholder="Enter Initials"
                value={formik.values.Initials}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Initials"
              />
              {formik.errors.Initials && formik.touched.Initials ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="FirstName">First Name *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="FirstName"
                className={"form-control" + (formik.errors.FirstName && formik.touched.FirstName ? " is-invalid" : "")}
                name="FirstName"
                placeholder="Enter First Name"
                value={formik.values.FirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="First Name"
              />
              {formik.errors.FirstName && formik.touched.FirstName ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="SecondName">Second Names </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="SecondName"
                className={"form-control" + (formik.errors.SecondName && formik.touched.SecondName ? " is-invalid" : "")}
                name="SecondName"
                placeholder="Enter Second Name"
                value={formik.values.SecondName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Second Name"
              />
              {formik.errors.SecondName && formik.touched.SecondName ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="ThirdName">Third Name </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="ThirdName"
                className={"form-control" + (formik.errors.ThirdName && formik.touched.ThirdName ? " is-invalid" : "")}
                name="ThirdName"
                placeholder="Enter Third Name"
                value={formik.values.ThirdName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Third Name"
              />
              {formik.errors.ThirdName && formik.touched.ThirdName ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="LastName">Last Name *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="LastName"
                className={"form-control" + (formik.errors.LastName && formik.touched.LastName ? " is-invalid" : "")}
                name="LastName"
                placeholder="Enter Last Name"
                value={formik.values.LastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Last Name"
              />
              {formik.errors.LastName && formik.touched.LastName ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="MaidenName">Maiden Name </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="MaidenName"
                className={"form-control" + (formik.errors.MaidenName && formik.touched.MaidenName ? " is-invalid" : "")}
                name="MaidenName"
                placeholder="Enter Maiden Name"
                value={formik.values.MaidenName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Maiden Name"
              />
              {formik.errors.MaidenName && formik.touched.MaidenName ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Title">Title </label>
            <div className="form-icon-group mb-4" title="Title">
              <Select
                className={"form-control custom-select-box " + (formik.errors.Title && formik.touched.Title ? " is-invalid" : "")}
                name="Title"
                value={title.filter((item) => {
                  return item.value == formik.values.Title;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("Title", value.value);
                  } else {
                    formik.setFieldValue("Title", "");
                  }                  
                }}
                onBlur={formik.handleBlur}
                options={title.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={formik.values.Title ? formik.values.Title : "Select"}
                isClearable
              />
              {formik.errors.Title && formik.touched.Title ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Nationality">Nationality </label>
            <div className="form-icon-group mb-4" title="Nationality">
              <Select
                className={"form-control custom-select-box " + (formik.errors.Nationality && formik.touched.Nationality ? " is-invalid" : "")}
                name="Nationality"
                value={nationality.filter((item) => {
                  return item.value == formik.values.Nationality;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("Nationality", value.value);
                  } else {
                    formik.setFieldValue("Nationality", "");
                  }                  
                }}
                onBlur={formik.handleBlur}
                options={nationality.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={formik.values.Nationality ? formik.values.Nationality : "Select"}
                isClearable
              />
              {formik.errors.Nationality && formik.touched.Nationality ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="RSAID">RSA ID </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="RSAID"
                className={"form-control" + (formik.errors.RSAID && formik.touched.RSAID ? " is-invalid" : "")}
                name="RSAID"
                placeholder="Enter RAS ID"
                value={formik.values.RSAID}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="RSA ID"
              />
              {formik.errors.RSAID && formik.touched.RSAID ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="ExamId">Exam Id Number </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="ExamId"
                className={"form-control" + (formik.errors.ExamId && formik.touched.ExamId ? " is-invalid" : "")}
                name="ExamId"
                placeholder="Enter Exam Id Number"
                value={formik.values.ExamId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Exam Id Number"
              />
              {formik.errors.ExamId && formik.touched.ExamId ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="DateOfBirth">Date Of Birth</label>
            <div className="form-group-blk mb-4">
              <DatePicker
                selected={formik.values.DOB}
                onChange={(date) => {
                  formik.setFieldValue("DOB", date);
                }}
                maxDate={new Date()}
                // showTimeSelect
                //   filterTime={filterPassedTime}
                dateFormat={FORM_DATE_FORMAT}
                // className="form-control"
                className={"form-control cursor-pointer" + (formik.errors.DOB && formik.touched.DOB ? " is-invalid" : "")}
                title="Date Of Birth"
                onBlur={formik.handleBlur}
                placeholderText="Select Date Of Birth"
              />
              {formik.errors.DOB && formik.touched.DOB ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Race">Race </label>
            <div className="form-icon-group mb-4" title="Race">
              <Select
                className={"form-control custom-select-box " + (formik.errors.Race && formik.touched.Race ? " is-invalid" : "")}
                name="Race"
                value={race.filter((item) => {
                  return item.value == formik.values.Race;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("Race", value.value);
                  } else {
                    formik.setFieldValue("Race", "");
                  }                  
                }}
                onBlur={formik.handleBlur}
                options={race.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={formik.values.Race ? formik.values.Race : "Select"}
                isClearable
              />
              {formik.errors.Race && formik.touched.Race ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Gender">Gender </label>
            <div className="form-icon-group mb-4" title="Gender">
              <Select
                className={"form-control custom-select-box " + (formik.errors.Gender && formik.touched.Gender ? " is-invalid" : "")}
                name="Gender"
                value={gender.filter((item) => {
                  return item.value == formik.values.Gender;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("Gender", value.value);
                  } else {
                    formik.setFieldValue("Gender", "");
                  }
                }}
                onBlur={formik.handleBlur}
                options={gender.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={formik.values.Gender ? formik.values.Gender : "Select"}
                isClearable
              />
              {formik.errors.Gender && formik.touched.Gender ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Disablity">Disability </label>
            <div className="form-icon-group mb-4" title="Disability">
              <Select
                className={"form-control custom-select-box " + (formik.errors.Disablity && formik.touched.Disablity ? " is-invalid" : "")}
                name="Disablity"
                value={disablity.filter((item) => {
                  return item.value == formik.values.Disablity;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("Disablity", value.value);
                  } else {
                    formik.setFieldValue("Disablity", "");
                  }
                }}
                onBlur={formik.handleBlur}
                options={disablity.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={formik.values.Disablity ? formik.values.Disablity : "Select"}
                isClearable
              />
              {formik.errors.Disablity && formik.touched.Disablity ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="HomeLan">Home Language </label>
            <div className="form-icon-group mb-4" title="Home Language">
              <Select
                className={"form-control custom-select-box " + (formik.errors.HomeLan && formik.touched.HomeLan ? " is-invalid" : "")}
                name="HomeLan"
                value={homeLan?.filter(val=>val.value.toString().toLowerCase() === formik.values.HomeLan.toString().toLowerCase())}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("HomeLan", value.value);
                  } else {
                    formik.setFieldValue("HomeLan", "");
                  }                  
                }}
                onBlur={formik.handleBlur}
                options={homeLan.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={formik.values.HomeLan ? formik.values.HomeLan : "Select"}
                isClearable
              />
              {formik.errors.HomeLan && formik.touched.HomeLan ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="MedicalAid">Medical Aid </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="MedicalAid"
                className={"form-control" + (formik.errors.MedicalAid && formik.touched.MedicalAid ? " is-invalid" : "")}
                name="MedicalAid"
                placeholder="Enter Medical Aid"
                value={formik.values.MedicalAid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Medical Aid"
              />
              {formik.errors.MedicalAid && formik.touched.MedicalAid ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="MedicalAidNum">Medical Aid Number </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="MedicalAidNum"
                className={"form-control" + (formik.errors.MedicalAidNum && formik.touched.MedicalAidNum ? " is-invalid" : "")}
                name="MedicalAidNum"
                placeholder="Enter Medical Aid Number"
                value={formik.values.MedicalAidNum}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Medical Aid Number"
              />
              {formik.errors.MedicalAidNum && formik.touched.MedicalAidNum ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="EmergencyContactNameOne">Emergency Contact Name 1 </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="EmergencyContactNameOne"
                className={"form-control" + (formik.errors.EmergencyContactNameOne && formik.touched.EmergencyContactNameOne ? " is-invalid" : "")}
                name="EmergencyContactNameOne"
                placeholder="Enter Emergency Contact Name"
                value={formik.values.EmergencyContactNameOne}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Emergency Contact Name"
              />
              {formik.errors.EmergencyContactNameOne && formik.touched.EmergencyContactNameOne ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="EmergencyContactNameOne">Emergency Contact Number 1 </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="EmergencyNumberOne"
                className={"form-control" + (formik.errors.EmergencyNumberOne && formik.touched.EmergencyNumberOne ? " is-invalid" : "")}
                name="EmergencyNumberOne"
                placeholder="Enter Emergency Contact Number"
                value={formik.values.EmergencyNumberOne}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Emergency Contact Number"
              />
              {formik.errors.EmergencyNumberOne && formik.touched.EmergencyNumberOne ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="EmergencyContactNameTwo">Emergency Contact Name 2 </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="EmergencyContactNameTwo"
                className={"form-control" + (formik.errors.EmergencyContactNameTwo && formik.touched.EmergencyContactNameTwo ? " is-invalid" : "")}
                name="EmergencyContactNameTwo"
                placeholder="Enter Emergency Contact Name"
                value={formik.values.EmergencyContactNameTwo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Emergency Contact Name"
              />
              {formik.errors.EmergencyContactNameTwo && formik.touched.EmergencyContactNameTwo ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="EmergencyNumberTwo">Emergency Contact Number 2 </label>
            <div className="form-icon-group mb-4">
              <input
                type="EmergencyNumberTwo"
                id="EmergencyNumberTwo"
                className={"form-control" + (formik.errors.EmergencyNumberTwo && formik.touched.EmergencyNumberTwo ? " is-invalid" : "")}
                name="EmergencyNumberTwo"
                placeholder="Enter Emergency Contact Number"
                value={formik.values.EmergencyNumberTwo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Emergency Contact Number"
              />
              {formik.errors.EmergencyNumberTwo && formik.touched.EmergencyNumberTwo ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group form-group-save-cancel">
          <PermissionsGate errorProps={{ disabled: true }} scopes={["spiedit"]}>
            <button className="btn btn-save btn-success" type="submit" title="Save" disabled={disabled || !formik.dirty || isLoaded}>
              {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
              Save
            </button>
          </PermissionsGate>
          <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => {
            setProfilePic();
            setProfilePicName();
            formik.resetForm()
          }}>
            <i className="fal fa-times"></i>
            Cancel
          </button>
          {Object.keys(formik.values).map(key => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block">
                  {formik.errors[key]}
                </div>
              )
            }
          })}
          {/* {formik.touched.StudentNumber && formik.errors.StudentNumber ? <div className="invalid-feedback d-block">{formik.errors.StudentNumber}</div> : null}
          {formik.touched.ProfilePicture && formik.errors.ProfilePicture ? <div className="invalid-feedback d-block">{formik.errors.ProfilePicture}</div> : null}
          {formik.touched.Initials && formik.errors.Initials ? <div className="invalid-feedback d-block">{formik.errors.Initials}</div> : null}
          {formik.touched.FirstName && formik.errors.FirstName ? <div className="invalid-feedback d-block">{formik.errors.FirstName}</div> : null}
          {formik.touched.SecondName && formik.errors.SecondName ? <div className="invalid-feedback d-block">{formik.errors.SecondName}</div> : null}
          {formik.touched.ThirdName && formik.errors.ThirdName ? <div className="invalid-feedback d-block">{formik.errors.ThirdName}</div> : null}
          {formik.touched.LastName && formik.errors.LastName ? <div className="invalid-feedback d-block">{formik.errors.LastName}</div> : null}
          {formik.touched.MaidenName && formik.errors.MaidenName ? <div className="invalid-feedback d-block">{formik.errors.MaidenName}</div> : null}
          {formik.touched.Title && formik.errors.Title ? <div className="invalid-feedback d-block">{formik.errors.Title}</div> : null}
          {formik.touched.Nationality && formik.errors.Nationality ? <div className="invalid-feedback d-block">{formik.errors.Nationality}</div> : null}
          {formik.touched.RSAID && formik.errors.RSAID ? <div className="invalid-feedback d-block">{formik.errors.RSAID}</div> : null}
          {formik.touched.ExamId && formik.errors.ExamId ? <div className="invalid-feedback d-block">{formik.errors.ExamId}</div> : null}
          {formik.touched.DOB && formik.errors.DOB ? <div className="invalid-feedback d-block">{formik.errors.DOB}</div> : null}
          {formik.touched.Race && formik.errors.Race ? <div className="invalid-feedback d-block">{formik.errors.Race}</div> : null}
          {formik.touched.Gender && formik.errors.Gender ? <div className="invalid-feedback d-block">{formik.errors.Gender}</div> : null}
          {formik.touched.Disablity && formik.errors.Disablity ? <div className="invalid-feedback d-block">{formik.errors.Disablity}</div> : null}
          {formik.touched.HomeLan && formik.errors.HomeLan ? <div className="invalid-feedback d-block">{formik.errors.HomeLan}</div> : null}
          {formik.touched.MedicalAid && formik.errors.MedicalAid ? <div className="invalid-feedback d-block">{formik.errors.MedicalAid}</div> : null}
          {formik.touched.MedicalAidNum && formik.errors.MedicalAidNum ? <div className="invalid-feedback d-block">{formik.errors.MedicalAidNum}</div> : null}
          {formik.touched.EmergencyContactNameOne && formik.errors.EmergencyContactNameOne ? <div className="invalid-feedback d-block">{formik.errors.EmergencyContactNameOne}</div> : null}
          {formik.touched.EmergencyNumberOne && formik.errors.EmergencyNumberOne ? <div className="invalid-feedback d-block">{formik.errors.EmergencyNumberOne}</div> : null}
          {formik.touched.EmergencyContactNameTwo && formik.errors.EmergencyContactNameTwo ? <div className="invalid-feedback d-block">{formik.errors.EmergencyContactNameTwo}</div> : null}
          {formik.touched.EmergencyNumberTwo && formik.errors.EmergencyNumberTwo ? <div className="invalid-feedback d-block">{formik.errors.EmergencyNumberTwo}</div> : null} */}
        </div>
      </form>
      {/* }  */}
    </div>
  );
}

export default BasicDetailsBlock;
