import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import ListOfStudents from "../components/StudentAdministration/Students/ListOfStudents";
import StudentForm from "../components/StudentAdministration/Students/StudentForm";
import AuditTrail from "../components/StudentAdministration/Students/AuditTrail";
import StudentTabs from "../components/StudentAdministration/Students/StudentTabs";
import Tabs from "../components/StudentAdministration/Tabs";
import { GetStudentDetails, GetStudentDetailsCancelToken } from "../services/StudentsSettingServices";
import PermissionsGate from "../utils/permissionGate";
import { GlobalIconNames } from "../utils/GlobalIconNames";

import axios from "axios";
import { STUDENT_PORTAL_URL } from "../utils/Constants";
// This will be shown in merge-all branch

function StudentAdministration() {
  const history = useHistory();
  const { type, id, tab, subType, subId } = useParams();

  const [loading, setLoading] = useState(false);
  const [studentDetail, setStudentDetail] = useState([]);
  const [QAview, setQAview] = useState(false);

  useEffect(() => {
    const cancelTokenSources = [];

    const getData = async () => {
      setLoading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        setStudentDetail({ first_name: "", last_name: "" });
        const res = await GetStudentDetailsCancelToken(id, source.token);
        if (res.status == 200) {
          setStudentDetail(res.data.data_list);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }finally{
        setLoading(false);
      }
    }

    if (type == "open") {
      getData();
    }

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [type]);

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className={"sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")}>
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                      {type === "add"
                        ? "Add"
                        : type === "open"
                        ? ( <> Student: <span>{studentDetail?.first_name} {studentDetail?.last_name}</span> </>)
                        : ( <>Students{QAview &&  <>: <span>QA View</span></> } </>)}
                    </h4>
                    </div>
                      <div className="text-md-right action2-blk">
                        <div className="ticket-view-btn d-flex align-items-center">
                          <div className="add-new-ticket-bx">
                            <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                              <i className="fal fa-angle-left"></i>Back
                            </button>
                          </div>
                        </div>
                      </div>
                    {type == "open" && (
                      <div className="text-md-right action2-blk">
                        <div className="ticket-view-btn d-flex align-items-center">
                          <div className="add-new-ticket-bx">
                            <PermissionsGate scopes={["sstudview"]} RenderError={() => (
                              <button
                                title="Student View"
                                className="btn btn-white-bordered"
                                disabled
                              >
                                {GlobalIconNames("studentview").html}
                              </button>
                            )}>
                              {loading ?
                               <button className="btn btn-white-bordered" title="Student View" disabled>
                               <i className="fas fa-cog fa-spin"></i> Student View
                               </button> 
                               :<a
                                  title="Student View"
                                  className="btn btn-white-bordered"
                                  href={`${STUDENT_PORTAL_URL}/viewStudent?email=${studentDetail && studentDetail.email ? studentDetail.email : ""}&password=${studentDetail && studentDetail.password ? studentDetail.password : ""}`}
                                  target="_blank"
                                >
                                  {GlobalIconNames("studentview").html}
                                </a>}
                            </PermissionsGate>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="search-filter-div-right">
                    <div className=" filter-search-bar-blk">
                      <div className="add-ticket-blk button-reset dropdown-comman">
                        <div>
                          {type !== "add" && type !== "open" && <button
                            className="btn btn-primary"
                            title={
                              QAview ? "Standard View" : "QA View"
                            }
                            onClick={() => setQAview(!QAview)}
                          >
                            <i className="fal fa-eye"></i>{" "}
                            {QAview ? "Standard View" : "QA View"}
                          </button>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  {(type == "auditTrail" || type == "table") && (
                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                      <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/table`)}>
                        <Link
                          className={`nav-link ${type === "table" && "active"}`}
                          id="pills-tab1"
                          data-toggle="pill"
                          to={`/studentAdministration/students/table`}
                          role="tab"
                          aria-controls="pills-tab11"
                          aria-selected="true"
                        >
                          <i className="fal fa-users"></i>
                          Students List
                        </Link>
                      </li>
                      <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/auditTrail`)}>
                        <Link
                          className={`nav-link ${type === "auditTrail" && "active"}`}
                          id="pills-tab2"
                          data-toggle="pill"
                          to={`/studentAdministration/students/auditTrail`}
                          role="tab"
                          aria-controls="pills-tab12"
                          aria-selected="true"
                        >
                          <i className="fal fa-history"></i>Audit trail
                        </Link>
                      </li>
                    </ul>
                  )}
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      {type == "table" && <ListOfStudents QAview={QAview} />}
                      {type == "auditTrail" && <AuditTrail />}
                      {type == "add" && <StudentForm />}
                      {type == "open" && <StudentTabs studentData={studentDetail} studentName={studentDetail ? studentDetail.first_name + " " + studentDetail.last_name : ""} />}
                    </div>
                  </div>
                </div>
                {/* {type == "table" && <ListOfStudents />}
                {type == "auditTrail" && <ListOfStudents />}
                {type == "add" && <StudentForm />}
                {type == "open" && <StudentTabs studentData={studentDetail} studentName={studentDetail ? studentDetail.first_name + " " + studentDetail.last_name : ""} />} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentAdministration;
