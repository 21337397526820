import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { TABLE_ROWS_PER_PAGE } from "../../../../../utils/Constants";
import PermissionsGate from "../../../../../utils/permissionGate";
import { RenderQuizAttemptResultStatus } from "../../../../../utils/CommonStatusItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Str from "../../../../common/Str";

const StudentGradeBookTable = (props) => {
  let percentageView = props.percentageView;
  const { id } = useParams();
  const [expandedRows, setExpandedRows] = useState([]);
  const [courseList, setCourseList] = useState(props?.courseWithAssessmentData);
  const [isWeightShown, setIsWeightShown] = useState(props?.isWeightShown);
  const [gradingStandardFromProps, setGradingStandardFromProps] = useState(props?.gradingStandard)

  useEffect(() => {
    if(props.gradingStandard){
      setGradingStandardFromProps(props.gradingStandard)
    }
    if (props?.courseWithAssessmentData) {
      setCourseList(props?.courseWithAssessmentData)
    }
  }, [props])
  

  const handleRowToggle = (rowId, e, iconClick) => {
    if (
      e &&
      e.target &&
      e.target.parentElement &&
      e.target.parentElement.parentElement
    ) {
      var parentElements = e.target.parentElement.parentElement.parentElement;
    }
    const isRowExpanded = expandedRows.includes(rowId);
    const rows = document.getElementsByClassName("expanded-course-row");
    for (let i = 0; i < rows.length; i++) {
      rows[i].classList.remove("expanded-course-row");
    }
    let tempExpendedRows = expandedRows;
    if (isRowExpanded) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
      tempExpendedRows = expandedRows.filter((id) => id !== rowId);
    } else {
      if (parentElements && iconClick) {
        parentElements.classList.add("expanded-course-row");
      } else {
        e.currentTarget.classList.add("expanded-course-row");
      }
      setExpandedRows([rowId]);
      tempExpendedRows = [rowId];
    }
    let tempArr = courseList.map((row) => {
      return {
        ...row,
        isExpanded: tempExpendedRows.includes(row.id),
      };
    });
    setCourseList(tempArr);
  };

  const isRowExpanded = (rowId) => expandedRows.includes(rowId);

  const sortingName = (rowA, rowB) => {
    const name1 = rowA.name ? rowA.name.toString() : "";
    const name2 = rowB.name ? rowB.name.toString() : "";
    return name1.localeCompare(name2);
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      sortFunction: sortingName,
      cell: (row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div
                className="expand-toggle-button mr-2"
                onClick={(e) => handleRowToggle(row.id, e, "iconClick")}
              >
                {row.isExpanded ? (
                  <span style={{ pointerEvents: "none", fontSize: "14px" }}>
                    <i className="fas fa-minus-circle"></i>
                  </span>
                ) : (
                  <span style={{ pointerEvents: "none", fontSize: "14px" }}>
                    <i className="fas fa-plus-circle"></i>
                  </span>
                )}
              </div>
              {props.gradingStandard == 2 ? 
              <span title={row.name} className="overflow-ellipsis2 ">
                {row.name ? (
                  <Link
                    className="as-text-topbar-gradebook curser feature-name"
                    to={`/courseAdministration/gradeMix/${props?.qid}/details/${row?.id}/open`}
                  >
                    <span className="textLimit100">{row.name}</span>
                  </Link>
                ) : (
                  "-"
                )}
              </span> :
              <span title={row.course_name} className="overflow-ellipsis2 ">
                {row.course_name ? (
                  <Link
                    className="as-text-topbar-gradebook curser feature-name"
                    to={`/courseAdministration/coursesdetails/${row?.id}/detailCourses/show`}
                  >
                    <span className="textLimit100">{row.course_name}</span>
                  </Link>
                ) : (
                  "-"
                )}
              </span>
              }
            </div>
          </>
        );
      },
    },
    isWeightShown && {
      name: "Weight",
      selector: "code",
      cell: (row) => {
        return row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : (
          <>
            <div className="resultstatus_flex arrowhide">
              <span>{row?.weight}</span>
            </div>
          </>
        );
      },
    },
    {
      name: "Score Recieved",
      selector: "year",
      cell: (row) =>
        row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : (
          <>
          { props.gradingStandard == 2 ? 
           (row.allAss_ass_mark &&
            row.allAss_total_mark ? (
            <div className="resultstatus_flex arrowhide">
              {row.allAss_ass_mark} /{" "}
              {row.allAss_total_mark}
            </div>
          ) : (
            "N/A"
          )) : ( row.getAssessmentCalculate_sum_ass_mark &&
              row.getAssessmentCalculate_sum_ass_total_mark ? (
              <div className="resultstatus_flex arrowhide">
                {row.getAssessmentCalculate_sum_ass_mark} /{" "}
                {row.getAssessmentCalculate_sum_ass_total_mark}
              </div>
            ) : (
              "N/A"
            ))
           }
          </>
        ),
    },
    {
      name: "Range",
      selector: "open_date_time",
      cell: (row) =>
        row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : props.gradingStandard == 2 ? (
          <p>
            {row.allAss_total_mark ? (
              <span>0 - {row.allAss_total_mark}</span>
            ) : (
              "N/A"
            )}
          </p>
        ) : (
          <p>
            {row.getAssessmentCalculate_sum_ass_total_mark ? (
              <span>0 - {row.getAssessmentCalculate_sum_ass_total_mark}</span>
            ) : (
              "N/A"
            )}
          </p>
        ),
    },
    {
      name: "Grade",
      selector: "close_date_time_mili",
      cell: (row) =>
        row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : percentageView ? (
          row.scoreRecieved + "%"
        ) : row?.grading_type && row?.grading_type == 3 && props.gradingStandard != 2 ? (
          `${row?.getAssessmentCalculate_sum_ass_mark}/${row?.getAssessmentCalculate_sum_ass_total_mark}`
        ) : row?.grading_type && row?.grading_type == 3 && props.gradingStandard == 2 ? (
          `${row?.allAss_ass_mark}/${row?.allAss_total_mark}`
        ) : row?.gradeText ? (
          row?.gradeText
        ) : (
          ""
        ),
    },
    // iscontributionShown && {
    //   name: "Contribution to Final Score ",
    //   selector: "type",
    //   cell: (row) => {
    //     return row.grant_exemption ? (
    //       <span className="exempted-text">
    //         Exempted <i className="fal fa-info-circle"></i>
    //       </span>
    //     ) : !row?.assessments.length ||
    //       row?.submited == "0" ||
    //       moment.unix(row?.close_date_time) > moment.unix(new Date()) ? (
    //       "N/A"
    //     ) : percentageView && row.contributionMark ? (
    //       row.contributionMark.includes("%") ? (
    //         Math.round(
    //           parseFloat(row.contributionMark.replace("%", ""))
    //         ).toString() + "%"
    //       ) : (
    //         row.contributionMark
    //       )
    //     ) : (row?.grading_type == "0" || row?.grading_type == "3") &&
    //       row.contributionMark ? (
    //       row.contributionMark.includes("%") ? (
    //         Math.round(
    //           parseFloat(row.contributionMark.replace("%", ""))
    //         ).toString() + "%"
    //       ) : (
    //         row.contributionMark
    //       )
    //     ) : (
    //       ""
    //     );
    //   },
    // },
    {
      name: "Result",
      selector: "markerName",
      sortable: true,
      cell: (row) =>
        row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : row?.pass_status == "N/A" ? (
          "N/A"
        ) : row.pass_status ? (
          RenderQuizAttemptResultStatus(row.pass_status).html
        ) : (
          ""
        ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            {row.grant_exemption ? (
              <PermissionsGate
                scopes={["studentgge"]}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-success rounded-circle"
                  title="Remove from Exemption"
                  onClick={() => props.handleExemption(row.id, 0, 1, row.id, props.qid)}
                >
                  <i className="fal fa-user-plus"></i>
                </button>
              </PermissionsGate>
            ) : (
              <PermissionsGate
                scopes={["studentgge"]}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-danger rounded-circle"
                  title="Grant Exemption"
                  onClick={() => props.handleExemption(row.id, 1, 1, row.id, props.qid)}
                >
                  <i className="fal fa-user-times"></i>
                </button>
              </PermissionsGate>
            )}
          </div>
        </div>
      ),
    },
  ]);

  const ExpandedRowComponent = (assessmentProps) => {
    let data = assessmentProps.data.data;
    let isExempted = assessmentProps.isExempted;
    let isSupplementary = assessmentProps?.data?.isSupplementary
    const Assessmentcolumns = useMemo(() => [
      {
        name: "Name",
        selector: "name",
        sortField: "name",
        sortable: true,
        cell: (row) => {
          return (
            gradingStandardFromProps == 2 ? (
            <span title={row.assessment_name} className={`overflow-ellipsis2 ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ?  "supplementry-pointer-events" : ""}`}>
              {row.assessment_name ? (
                <Link
                  className={`as-text-blue curser feature-name ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}
                  to={`/courseAdministration/coursesdetails/${row.grademix_intake_id}/assessments/open/assigned/Details/${row.assessment_id}`}
                >
                  <span className="textLimit100">{row.assessment_name}</span>
                </Link>
              ) : (
                "-"
              )}
            </span>
            ) : ( 
            <span title={row.name} className={`overflow-ellipsis2 ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ?  "supplementry-pointer-events" : ""}`}>
              {row.name ? (
                <Link
                className={`as-text-blue curser feature-name ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}
                  to={`/courseAdministration/coursesdetails/${assessmentProps.data.intakeId}/assessments/open/assigned/Details/${row.assessment_id}`}
                >
                  <span className="textLimit100">{row.name}</span>
                </Link>
              ) : (
                "-"
              )}
            </span>
            )
          );
        },
      },
      isWeightShown && {
        name: "Weight",
        selector: "weight",
        sortField: "ass_weighting",
        sortable: true,
        cell: (row) =>
          row.grant_exemption || isExempted ? (
            <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : (
            <>
              <div
                className={`resultstatus_flex arrowhide ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}
                style={{ width: "35%" }}
              >
                <div className="form-icon-group status-select-form">
                  <span>{row.ass_weighting}</span>
                </div>
              </div>
            </>
          ),
      },
      {
        name: "Score Recieved",
        selector: "ass_mark",
        sortable: false,
        cell: (row) =>
          row.grant_exemption || isExempted ? (
            <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : (
            <div className={`resultstatus_flex arrowhide ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              <div className="form-icon-group status-select-form">
                <span className="min-width-max-content">
                  {row.ass_mark} / {row.ass_total_mark}
                </span>
              </div>
              {row?.assessmentMarkingStatus?.toString() === "notsubmitted" ||
                row?.assessmentMarkingStatus?.toString() === "NOT SUBMITTED" ? (
                <div
                  className="grade-info-ic grade-info-red ml-2"
                  title="Not Submitted"
                >
                  <i className="fal fa-file-times"></i>
                </div>
              ) : (
                ""
              )}
              {!row?.mark_edited || row?.mark_edited == "0" ? (
                ""
              ) : (
                <div
                  className="grade-info-ic grade-info-orange ml-2"
                  title="Mark Edited"
                >
                  <i className="fal fa-edit"></i>
                </div>
              )}
              {row?.assessmentStatus?.toString() === "OPEN" ? (
                <div
                  className="grade-info-ic grade-info-green ml-2"
                  title="Open/Extension"
                >
                  <i className="fal fa-calendar-day"></i>
                </div>
              ) : (
                ""
              )}
              {row?.assessmentStatus?.toString() === "PENDING" ? (
                <div
                  className="grade-info-ic grade-info-blue ml-2"
                  title="Upcoming Assessment"
                >
                  <i className="fal fa-calendar-alt"></i>
                </div>
              ) : (
                ""
              )}
            </div>
          ),
      },
      {
        name: "Range",
        selector: "ass_total_mark",
        sortable: false,
        cell: (row) =>
          row.grant_exemption || isExempted ? (
            <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : (
            <p className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              {row.ass_total_mark ? <span>0 - {row.ass_total_mark}</span> : "N/A"}
            </p>
          ),
      },
      {
        name: "Grade",
        selector: "grade",
        sortable: false,
        cell: (row) => {
          let options = [
            { label: "Red", value: "0" },
            { label: "Yellow", value: "49" },
            { label: "Green", value: "100" },
          ];
          return row.grant_exemption || isExempted ? (
            <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : percentageView ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>{row.ass_percentage}%</span>
          ) : row?.submited == "0" ||
            moment.unix(row?.close_date_time) > moment.unix(new Date()) ? (
              <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : row?.grading_type == 3 ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>{`${row?.gradeText}/${row?.ass_total_mark}`}</span>
          ) : row?.gradeText ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>{row?.gradeText}</span>
          ) : (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>N/A</span>
          );
        },
      },
      // iscontributionShown && {
      //   name: "Contribution to Final Score ",
      //   selector: "contributionMark",
      //   sortable: false,
      //   cell: (row) => {
      //     return row.grant_exemption || isExempted ? (
      //       <span className="exempted-text">
      //         Exempted <i className="fal fa-info-circle"></i>
      //       </span>
      //     ) : row?.submited == "0" ||
      //       moment.unix(row?.close_date_time) > moment.unix(new Date()) ? (
      //       "N/A"
      //     ) : percentageView && row.contributionMark ? (
      //       row.contributionMark.includes("%") ? (
      //         Math.round(
      //           parseFloat(row.contributionMark.replace("%", ""))
      //         ).toString() + "%"
      //       ) : (
      //         row.contributionMark
      //       )
      //     ) : (row?.grading_type == "0" || row?.grading_type == "3") &&
      //       row.contributionMark ? (
      //       row.contributionMark.includes("%") ? (
      //         Math.round(
      //           parseFloat(row.contributionMark.replace("%", ""))
      //         ).toString() + "%"
      //       ) : (
      //         row.contributionMark
      //       )
      //     ) : row.contributionMark ? (
      //       row.contributionMark
      //     ) : (
      //       "N/A"
      //     );
      //   },
      // },
      {
        name: "Result",
        selector: "pass_status",
        sortable: false,
        cell: (row) =>
          row.grant_exemption || isExempted ? (
            <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : row?.submited == "0" ||
            moment.unix(row?.close_date_time) > moment.unix(new Date()) ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : row.pass_status == "N/A" ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : row.pass_status ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>{RenderQuizAttemptResultStatus(row.pass_status).html}</span>
          ) : (
            "-"
          ),
      },
      {
        name: "Actions",
        cell: (row) => (
          <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons">
              {row.grant_exemption || isExempted ? (
                <PermissionsGate
                  scopes={["studentgge"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-success rounded-circle"
                    title="Remove from Exemption"
                    onClick={() =>
                      props.handleExemption(
                        row.assessment_id,
                        0,
                        0,
                        assessmentProps.data.intakeId, props.qid
                      )
                    }
                    disabled={row.lock_grades == "1" || (isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent || (row?.ass_type == 9 && !row.isAssignedSickStudent) || (row?.ass_type == 8 && !row.isSupplementaryForThisStudent) || isSupplementary}
                  >
                    <i className="fal fa-user-plus"></i>
                  </button>
                </PermissionsGate>
              ) : (
                <PermissionsGate
                  scopes={["studentgge"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Grant Exemption"
                    onClick={() =>
                      props.handleExemption(
                        row.assessment_id,
                        1,
                        0,
                        assessmentProps.data.intakeId, props.qid
                      )
                    }
                    disabled={row.lock_grades == "1" || (isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent || (row?.ass_type == 9 && !row.isAssignedSickStudent) || (row?.ass_type == 8 && !row.isSupplementaryForThisStudent) || isSupplementary}
                  >
                    <i className="fal fa-user-times"></i>
                  </button>
                </PermissionsGate>
              )}
            </div>
          </div>
        ),
      },
    ]);

    return (
      <DataTable
        noHeader
        noTableHead
        data={data}
        columns={Assessmentcolumns.filter((item) => item)}
        pagination={false}
        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        noDataComponent={
          <span className="my-10px">
            There are no assessments linked to this course
          </span>
        }
      />
    );
  };

  const ExpandedRowWrapper = ({ data }) => {
    return (
      <ExpandedRowComponent
        key={data?.id}
        isExempted={data?.grant_exemption}
        data={{ data: data?.assessments, intakeId: data?.id, isSupplementary: data?.assessments.some((supplement)=> supplement.isSupplementaryForThisStudent && !supplement.isSickForThisStudent ) }}
      />
    );
  };

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
        <div className="l-o-c-t filter-search-icon card card-table-custom ">
          <DataTable
            data={courseList}
            columns={columns.filter((item) => item)}
            pagination={true}
            defaultSortField="name"
            defaultSortAsc={false}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            expandableRows
            expandOnRowClicked
            expandableRowsComponent={<ExpandedRowWrapper />}
            onRowClicked={(row, e) => handleRowToggle(row?.id, e)}
            expandableIcon={
              <FontAwesomeIcon
                icon={faCirclePlus}
                className="custom-collapse-icon"
              />
            } // Customize the expand icon
            collapseIcon={
              <FontAwesomeIcon
                icon={faCircleMinus}
                className="custom-collapse-icon"
              />
            }
            expandableRowExpanded={(row) => isRowExpanded(row.id)} // Check if row is expanded
            className="gradebook-expand-table"
            noDataComponent={Str.noRecord}
          />
        </div>
      </div>
    </>
  );
};

export default StudentGradeBookTable;
