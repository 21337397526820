import React from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { DeleteCertificate, MatricCertificate } from "../../../../../services/StudentsSettingServices";
import { IMAGE_URL } from "../../../../../utils/Constants";
import PermissionsGate from "../../../../../utils/permissionGate";

function PersonalDocumentBlock({ studentData, loading, setUpdateRow, updateRow}) {
  const { id } = useParams();

  const handleDelete = (certId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        DeleteCertificate(id, certId, "personal_doc")
          .then((res) => {
            Swal.fire("Deleted!", "File Deleted.", "success");
            setUpdateRow(!updateRow);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              setUpdateRow(!updateRow);
              Swal.fire("", `Unable To Delete.`, "warning");
            }
          });
        setUpdateRow(!updateRow);
      }
    });
  };

  const handleFileChange = (e) => {
    if (e.target.files.length) {
      const formData = new FormData();
      formData.append("student", id);
      formData.append("type", "personal_doc");
      formData.append("from_admin", 1);
      formData.append("file_input", e.target.files[0]);
      MatricCertificate(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "File Added successfully",
          });
          setUpdateRow(!updateRow);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err?.response?.data?.message,
          });
          setUpdateRow(!updateRow);
        });
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Personal Documents</div>
        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      <form>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group-blk mb-3">
              <label>ID *</label>
              <div className="form-group atttach-file m-0">
                <label>
                  <i className="fal fa-paperclip"></i>
                  <span>Add Attachment</span>
                  <PermissionsGate errorProps={{ disabled: true }} scopes={["spiedit"]}>
                    <input
                      type="file"
                      className="form-control  form-control-aatch-file"
                       accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                  </PermissionsGate>
                </label>
              </div>
            </div>
          </div>
          {studentData && studentData.length ? (
            <div className="col-md-4">
              <div className="form-group-blk mb-3">
                <div className="files-list-item frm-group">
                  <label>List Of Documents </label>
                  <ul className="list-unstyled attached-file-ul m-0">
                    {studentData.map((item, index) => {
                      return (
                        <li key={index}>
                          <a href={item.url.replace("/home/myaie/public_html/", IMAGE_URL + '/' ).replace("public/", IMAGE_URL + '/')} target="_blank">
                            <i className="fal fa-file"></i>
                            &nbsp;{item.url.split("/").reverse()[0]}&nbsp;
                          </a>
                          <PermissionsGate errorProps={{ disabled: true }} scopes={["spidelete"]}>
                            <button className="btn btn-danger" onClick={() => handleDelete(item.id)} type={"button"}>
                              <i className="fal fa-trash-alt"></i>
                            </button>
                          </PermissionsGate>
                          {/* <a href="javascript:void(0);" className="as-text-red curser" >
                              <i className="fal fa-trash-alt"></i>
                            </a> */}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </form>
    </div>
  );
}

export default PersonalDocumentBlock;
