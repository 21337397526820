import React, { useEffect, useState, useMemo, createRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import {
  AddEditCampusClass,
  AddRoomToCampus,
  DeleteRoomOfCampus,
  GetCampusDetails,
  GetCampusDetailsCancelToken,
  GetCampusRoomsList,
  GetCampusRoomsListCancelToken,
} from "../../../services/CampusMgtService";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { AllBrandList, AllBrandListCancelToken } from "../../../services/RegistrationService";
import Select from "react-select";
import { FilePond, File, registerPlugin, setOptions } from "react-filepond";
import "filepond/dist/filepond.min.css";

import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../../assets/css/filePond.css";
import { FORM_TIME_FORMAT, IMAGE_URL } from "../../../utils/Constants";
import { TrimText } from "../../common/TrimText";
import { isArray } from "underscore";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import { formatQuestion } from "../../../utils/FormatQuestion";
import DatePicker from 'react-datepicker'
import axios from "axios";
import { modifiyOptionsForSelect } from "../../../utils/commonFunction";
import { GetNetsuiteCampusListCancelToken } from "../../../services/ProgrammeServices";

const CampusDetails = () => {
  const filePondRef = createRef();
  const history = useHistory();
  const [dataArr, setDataArr] = useState([]);
  const search = useLocation().search;
  const [classId, setClassId] = useState(new URLSearchParams(search).get("id"));
  const [classDetails, setClassDetails] = useState({});
  const [loaderPosition, setLoaderPosition] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const className = new URLSearchParams(search).get("name");
  const [loading, setLoading] = useState(true);
  const [allBrands, setAllBrands] = useState([]);
  const [files, setFiles] = useState([]);
  const [removeFile, setRemoveFile] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [showcursure, setShowCurser] = useState(false);
  const [showFilePond, setShowFilePond] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const [mapImageDisplay, setMapImageDisplay] = useState("");
  const [netsuiteProdList, setNetsuiteProdList] = useState([])
  const [editorState, setEditorState] = useState();

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    const cancelDropdownSource2 = [];
  
    const fetchData = async () => {
      try {
        const res = await GetCampusDetailsCancelToken(classId, dropdownSource.token);
        if (res?.status == 200) {
          let classData = res && res.data && res.data.data_list ? res.data.data_list : {};
          if(classData.open_time!==""){
            let stTime = classData.open_time.split(':')
            let stMin = stTime[1].split(' ')
            let stHour = stMin[1] == 'PM' ? Number(stTime[0]) + 12 : stTime[0]
            let tempStartTime = new Date().setHours(stHour, stMin[0])
            classData['open_time'] = tempStartTime;
          }
          if(classData.close_time!==""){
            let endTime = classData.close_time.split(':')
            let endMin = endTime[1].split(' ')
            let endHour = endMin[1] == 'PM' ? Number(endTime[0]) + 12 : endTime[0]
            let tempEndTime = new Date().setHours(endHour, endMin[0])
            classData['close_time'] = tempEndTime
          }
          setClassDetails({...classData});
          setMapImageDisplay(
            `${IMAGE_URL}/${res.data.data_list.campus_image
              .replace("public/", "")
              .replace("home/myaie/public_html/", "")}`
          );
          setEditorState(res.data.data_list.campus_address)
          let newbrand =
            res.data.data_list.CampusAssignBrands &&
            res.data.data_list.CampusAssignBrands.map((brand) => {
              return brand.brand_id;
            });
          setBrandData(newbrand);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    if (classId !== null) {
    fetchData();
    }
    
    const getNetsuiteProductData = async () => {
      cancelDropdownSource2.forEach((source) => {
        source.cancel("New request made");
      });
      const source2 = axios.CancelToken.source();
      cancelDropdownSource2.push(source2);

      try {
        const res = await GetNetsuiteCampusListCancelToken("", source2.token);
        
        if (res.status == 200) {
          const respData =
            res.data.data && res.data.data.items ? res.data.data.items : [];
          const modifyResponse = modifiyOptionsForSelect(respData, "netsuitcampus");
          setNetsuiteProdList(modifyResponse);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    getNetsuiteProductData();

    return () => {
      dropdownSource.cancel('Component unmounted');
      cancelDropdownSource2.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const data = await GetCampusRoomsListCancelToken(classId,dropdownSource.token);
        if (data?.status == 200) {
          setDataArr(
            data && data.data && data.data.data_list ? data.data.data_list : []
          );
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
    if (classId !== null) {
    fetchData();
    }
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const response = await AllBrandListCancelToken(dropdownSource.token);
        if (response?.status == 200) {
          let brands = [];
          let res = response.data?.allBrandList;
          res.map((data) => {
            brands.push({ value: data.id, label: data.brand_name });
          });
          setAllBrands(brands);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  function getClassDetails() {
    GetCampusDetails(classId)
      .then((res) => {
        let classData = res && res.data && res.data.data_list ? res.data.data_list : {};
        if(classData.open_time!==""){
          let stTime = classData.open_time.split(':')
          let stMin = stTime[1].split(' ')
          let stHour = stMin[1] == 'PM' ? Number(stTime[0]) + 12 : stTime[0]
          let tempStartTime = new Date().setHours(stHour, stMin[0])
          classData['open_time'] = tempStartTime;
        }
        if(classData.close_time!==""){
          let endTime = classData.close_time.split(':')
          let endMin = endTime[1].split(' ')
          let endHour = endMin[1] == 'PM' ? Number(endTime[0]) + 12 : endTime[0]
          let tempEndTime = new Date().setHours(endHour, endMin[0])
          classData['close_time'] = tempEndTime
        }
        setClassDetails({...classData});
        setMapImageDisplay(
          `${IMAGE_URL}/${res.data.data_list.campus_image
            .replace("public/", "")
            .replace("home/myaie/public_html/", "")}`
        );
        setEditorState(res.data.data_list.campus_address)
        let newbrand =
          res.data.data_list.CampusAssignBrands &&
          res.data.data_list.CampusAssignBrands.map((brand) => {
            return brand.brand_id;
          });
        setBrandData(newbrand);
      })
      .catch((error) => {
        console.log("error ---> ", error);
      });
  }

  function getRoomList() {
    GetCampusRoomsList(classId)
      .then((data) => {
        setDataArr(
          data && data.data && data.data.data_list ? data.data.data_list : []
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log("error> >> > >", error);
      });
  }

  const formik = useFormik({
    enableReinitialize: classId ? true : false,
    initialValues: {
      name: classDetails.campus_name ? classDetails.campus_name : "",
      address: classDetails.campus_address ? classDetails.campus_address : "",
      // brand_name: classDetails.CampusAssignBrands
      //   ? classDetails.CampusAssignBrands
      //   : "",
      url: classDetails.url ? classDetails.url : "",
      open_time:classDetails.open_time ? classDetails.open_time :"",
      close_time:classDetails.close_time ? classDetails.close_time :"",
      // netsuite_product: classDetails?.netsuite_product ? classDetails.netsuite_product : "",
      netsuite_id: classDetails?.netsuite_id ? classDetails.netsuite_id : "",
      netsuite_department_id: classDetails.netsuite_department_id
        ?
        classDetails.netsuite_department_id
        : "",
      color:classDetails.color ? classDetails.color :"",
      // image: Object.keys(classDetails).length ?  classDetails.campus_image : files.length>0 ? files :"",
      image:
        classDetails && classDetails.campus_image
          ? IMAGE_URL +
            "/" +
            classDetails.campus_image
              .replace("public/", "")
              .replace("home/myaie/public_html/", "")
          : "",
      published:
        classDetails && classDetails.is_publish
          ? classDetails?.is_publish
          : "0",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required").max(255, "Name should not be greater than 255 Character"),
      open_time: Yup.string().required("Open Time is required"),
      close_time: Yup.string().required("Close Time is required"),
      color: Yup.string().required("Grouping Color is required"),
      // netsuite_product: Yup.string().required("Netsuite Product is required"),
      address: Yup.string().test(
        "required",
        "Address is Required",
        (value) => {
          let feature = formatQuestion(editorState);
          let isInvalid = feature == "-";
          return !isInvalid;
        }
      ),
      // brand_name: Yup.string().required("Brand name is required"),
      url: Yup.string().required("URL is required"),
      image: Yup.string().required("Map Image is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      let openTime = moment(new Date(values.open_time)).format(FORM_TIME_FORMAT).toUpperCase()
      let closeTime = moment(new Date(values.close_time)).format(FORM_TIME_FORMAT).toUpperCase()
      let formData = new FormData();
      formData.append("campus_name", values.name);
      formData.append("open_time", openTime);
      formData.append("close_time", closeTime);
      formData.append("color", values.color);
      formData.append("campus_address", editorState);
      formData.append("netsuite_product", values.netsuite_product);
      formData.append("netsuite_department_id", values.netsuite_department_id ? values.netsuite_department_id: "");
      formData.append("netsuite_id", values.netsuite_id);
      // formData.append("brand_id", JSON.stringify(values.brand_name));
      formData.append("url", values.url);
      if(isArray(values.image)){
        formData.append("campusImage", values.image[0].file);
      }
      else{
        formData.append("campusImage", values.image);
      }
      
      formData.append("is_publish", values.published);
      if (classId !== null) formData.append("id", classId);
      setLoaderPosition(1);
      AddEditCampusClass(formData, classId !== null ? true : false)
        .then((data) => {
          setLoaderPosition(0);
          Swal.fire("Added!", "Your record has been Created.", "success");
          history.push("/administration/campusManagement/campusList");
        })
        .catch((error) => {
          console.log("error ----> ", error);
          setLoaderPosition(0);
        });
    },
  });

  const attachmentsArrUpdate = (file) => {
    let tempAttach = attachments;
    for (var i = 0; i < tempAttach.length; i++) {
      if (tempAttach[i].includes(file.split(".")[0])) {
        tempAttach.splice(i, 1);
        break;
      }
    }
    if (tempAttach.length < 1) {
      setTimeout(() => {
        setShowFilePond(false);
        setShowCurser(!showcursure);
      }, 500);
    }
  };
  function handleBrandSelected(value) {
    const brandname = value?.map((obj) => {
      return obj.value;
    });
    setBrandData(brandname);
    formik.setFieldValue("brand_name", brandname);
  }

  const handleMapImage = () => {
    formik.setFieldValue("image", "");
    setMapImageDisplay("");
  };
  

  return (
    <>
      <div className="card card-profile-info-card">
        <div className={`card-body `}>
          <div className="card-body-inr card-body-info">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <div className="form-group mb-4">
                    <label>Name *</label>
                    <input
                      className={
                        "form-control" +
                        (formik.errors.name && formik.touched.name
                          ? " is-invalid"
                          : "")
                      }
                      value={formik.values.name}
                      placeholder="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="name"
                      title="Name"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group mb-4">
                    <label>URL *</label>
                    <input
                      className={
                        "form-control" +
                        (formik.errors.url && formik.touched.url
                          ? " is-invalid"
                          : "")
                      }
                      value={formik.values.url}
                      placeholder="url"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="url"
                      title="URL"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group mb-4">
                    <label>Open Time *</label>
                    <DatePicker
                      selected={formik.values.open_time}
                      showTimeSelect
                      showTimeSelectOnly
                      placeholderText="HH:MM"
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat={FORM_TIME_FORMAT}
                      timeFormat={FORM_TIME_FORMAT}
                      className={"form-control" + (formik.errors.open_time && formik.touched.open_time ? " is-invalid" : "")}
                      name="open_time"
                      onChange={date => formik.setFieldValue('open_time', date)}
                      onBlur={formik.handleBlur}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </div>     
                <div className="col-md-6 col-lg-3">
                  <div className="form-group mb-4">
                    <label>Close Time *</label>
                    <DatePicker
                      selected={formik.values.close_time}
                      showTimeSelect
                      placeholderText="HH:MM"
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat={FORM_TIME_FORMAT}
                      timeFormat={FORM_TIME_FORMAT}
                      className={"form-control" + (formik.errors.close_time && formik.touched.close_time ? " is-invalid" : "")}
                      name="close_time"
                      onChange={date => formik.setFieldValue('close_time', date)}
                      onBlur={formik.handleBlur}                                            
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </div>       
                <div className="col-md-6 col-lg-3">
                  <label for="color">Grouping Color *</label>
                  <div className="form-icon-group mb-4">
                    <input
                      type="color"
                      className={
                        "form-control" +
                        (formik.errors.color && formik.touched.color
                          ? " is-invalid"
                          : "")
                      }
                      value={formik.values.color}
                      placeholder="Grouping Color"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="color"
                      id="color"
                      title="Grouping Color"
                    />                    
                  </div>
                </div>    
                <div className="col-md-6 col-lg-3">
                  <div className="form-group mb-4">
                    <label>Map Image *</label>
                    <div className="mb-3">
                      <div className="drag_card_btn" title="Map Image *">
                        <label
                          className={
                            "map-image-height " +
                            "form-control" +
                            (formik.errors.image && formik.touched.image
                              ? " is-invalid"
                              : "")
                          }
                        >
                          <FilePond
                            name="post"
                            allowMultiple={true}
                            maxFiles={1}
                            ref={filePondRef}
                            //  files={formik.values.image}
                            onupdatefiles={(e) => {
                              formik.setFieldValue("image", e);
                            }}
                            beforeRemoveFile={(f) => {
                              formik.setFieldValue("image", "");
                              setRemoveFile(f.file.name);
                              attachmentsArrUpdate(f.file.name);
                            }}
                            onaddfile={(err, fileItem) => {
                              setShowFilePond(true);
                            }}
                            acceptedFileTypes={["image/*"]}
                          />
                        </label>
                        {mapImageDisplay && 
                        <ul className="list-unstyled attached-file-ul m-0 ">
                          <li>
                            <a
                              title={mapImageDisplay}
                              href={mapImageDisplay}
                              target="_blank"
                            >
                              <i className="fal fa-file"></i>
                              &nbsp;
                              {TrimText(
                                mapImageDisplay ? mapImageDisplay : "",
                                70
                              )}
                            </a>
                            {/* <button
                              className="btn btn-danger rounded-circle btn-dropdown-item"
                              title="Delete"
                              onClick={() => handleMapImage()}
                            >
                              <i className="fal fa-trash-alt"></i>
                            </button> */}
                          </li>
                        </ul>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <label>&nbsp;</label>
                  <div className="form-icon-group mb-4">
                    <label>&nbsp;</label>
                    <div className="custom-check-group">
                      <div className="custom-check custom-control custom-checkbox mt-md-3" title="Published">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="published"
                          name="published"
                          checked={formik.values.published == "1"}
                          onChange={() =>{
                            if(formik.values.published == "1"){
                              formik.setFieldValue("published", "0")
                            }else{
                              formik.setFieldValue("published", "1")
                            }}

                            
                          }
                          onBlur={formik.handleBlur}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="published"
                        >
                          Published
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                <div className="form-group-blk mb-3" title="Netsuite Campus">
                    <label>
                      Netsuite Campus *
                      <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.netsuite_product &&
                        formik.touched.netsuite_product
                          ? " is-invalid"
                          : "")
                      }
                      name="netsuite_product"
                      value={netsuiteProdList.filter(
                        (val) => val?.value == formik.values.netsuite_id
                      )}
                      onChange={(value) => {
                        if (value) {
                          // formik.setFieldValue("netsuite_product", value.value);
                          formik.setFieldValue("netsuite_id", value.value)
                        } else {
                          // formik.setFieldValue("netsuite_product", "");
                          formik.setFieldValue("netsuite_id", "")
                        }
                      }}
                      onBlur={formik.handleBlur}
                      options={netsuiteProdList}
                      maxMenuHeight={175}
                      placeholder={"Select Netsuite Product"}
                      isClearable
                    />
                  </div>
                </div>    
                <div className="col-md-12">
                  <div className="form-group-blk mb-4">
                    <label>Address *</label>
                    {((editorState != null || editorState != undefined) || classId == null) && <HtmlInputEditor
                      editorState={editorState}
                      setEditorState={setEditorState}
                      hideSign={true}
                      isCKEditor={true}
                      isInvalid={
                        formik.touched.address &&
                        formik.errors.address
                      }
                    />}
                  </div>
                </div>
              </div>
              <div className="form-group form-group-save-cancel">
                <button
                  className="btn btn-save btn-success"
                  type="submit"
                  title="Save"
                  disabled={false}
                >
                  {loaderPosition === 1 && (
                    <>
                      <i className="fas fa-cog fa-spin"></i>Save
                    </>
                  )}
                  {loaderPosition !== 1 && (
                    <>
                      <i className="fal fa-save"></i>
                      Save
                    </>
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  title="Close"
                  onClick={() => {
                    history.push("/administration/campusManagement/campusList");
                  }}
                >
                  <i className="fal fa-times"></i>
                  Close
                </button>
                {Object.keys(formik.values).map((key) => {
                  if (formik.touched[key] && formik.errors[key]) {
                    return (
                      <div className="invalid-feedback d-block">
                        {formik.errors[key]}
                      </div>
                    );
                  }
                })}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampusDetails;
