import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";

import { GetListOfRole, GetListOfRoleCancelToken } from "../../../services/UserService";
import { InitialRender } from "../../common/Helper";
import { IMAGE_URL, TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import { TrimText } from "../../common/TrimText";
import { DeleteBrandSetting, GetListOfBrandSetting, GetListOfBrandSettingCancelToken } from "../../../services/BrandServices";
import { error } from "jquery";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const ListOfBrands = () => {
  const loc = useLocation();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [role, setRole] = useState(loc && loc.state && loc.state.name ? [loc.state.name] : []);
  const [searchRoleCheck, setSearchRoleCheck] = useState(loc && loc.state && loc.state.role_name ? loc.state.role_name : {});
  const [company, setCompany] = useState([]);
  const [searchCompanyCheck, setSearchCompanyCheck] = useState({});
  const [location, setLocation] = useState([]);
  const [searchLocationCheck, setSearchLocationCheck] = useState({});

  const [report, setReport] = useState([]);
  const [searchReportCheck, setSearchReportCheck] = useState({});

  const [deleterow, setDeleteRow] = useState(false);

  const [userData, setUserData] = useState([]);
  const [brandSettingData, setBrandSettingData] = useState([]);
  const [roleList, setRoleList] = useState();

  const user = useSelector((state) => state.user);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    handleTableScroll()
  }, [isLoaded])

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const getRoles = async () => {
      try {
        const res = await GetListOfRoleCancelToken(dropdownSource.token);
        setRoleList(res.data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    getRoles();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, [deleterow]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleStatusFilter = (e) => {
    const value = e.target.value;
    let arr = status;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setStatus(arr);
    let id = e.target.id;
    setSearchStatusCheck({ ...searchStatusCheck, [id]: !searchStatusCheck[id] });
  };

  const handleRoleFilter = (e) => {
    const value = e.target.value;
    let arr = role;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setRole(arr);
    let id = e.target.id;
    setSearchRoleCheck({ ...searchRoleCheck, [id]: !searchRoleCheck[id] });
  };

  const handleCompanyFilter = (e) => {
    const value = e.target.value;
    let arr = company;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setCompany(arr);
    let id = e.target.id;
    setSearchCompanyCheck({ ...searchCompanyCheck, [id]: !searchCompanyCheck[id] });
  };

  const handleLocationFilter = (e) => {
    const value = e.target.value;
    let arr = location;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setLocation(arr);
    let id = e.target.id;
    setSearchLocationCheck({ ...searchLocationCheck, [id]: !searchLocationCheck[id] });
  };

  const handleReportToFilter = (e) => {
    const value = e.target.value;
    let arr = report;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setReport(arr);
    let id = e.target.id;
    setSearchReportCheck({ ...searchReportCheck, [id]: !searchReportCheck[id] });
  };

  const renderRoleUI = () => {
    // let arr = ["Admin", "Student Support", "Manager", "Developer", "Qualifications Coordinator", "Principle/Deputy Principle", "Lecturer", "Editor", "Sales", "Accounts"];
    return roleList?.map((i, index) => {
      return (
        <li key={index}>
          <input type="checkbox" id={i.role_name} value={i.role_name} onClick={handleRoleFilter} checked={searchRoleCheck[i.role_name]} />
          <label htmlFor={i.role_name}>
            <span
              className={
                "cat "
                // + Color(i.role_name)
              }
            >
              <i className="fas fa-circle mr-1"></i> {i?.role_name}
            </span>
          </label>
        </li>
      );
    });
  };

  const renderReportsToUI = () => {
    let data = brandSettingData.sort((a, b) => {
      let fa = a.brand_name.toLowerCase(),
        fb = b.brand_name.toLowerCase();

      if (fa < fb) {
        return -1;
      }

      if (fa > fb) {
        return 1;
      }

      return 0;
    });
    return data?.map((i, index) => {
      return (
        <li key={index}>
          <input type="checkbox" id={i.brand_name + " " + i?.Lastname} value={i.brand_name} onClick={handleReportToFilter} checked={searchReportCheck[i.brand_name + " " + i?.Lastname]} />
          <label htmlFor={i.brand_name + " " + i?.Lastname}>
            <span>
              {i?.brand_name} {i?.Lastname}
            </span>
          </label>
        </li>
      );
    });
  };

  const resetFilter = () => {
    setStatus([]);
    setSearchStatusCheck({});
    setRole([]);
    setSearchRoleCheck({});
    setCompany([]);
    setSearchCompanyCheck({});
    setLocation([]);
    setSearchLocationCheck({});
    setReport([]);
    setSearchReportCheck({});
    setSearch("");
  };

  // TODO Data

  const dataToRender = () => {
    let updatedData = [];
    let allData = brandSettingData;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes = item.id.toString().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempbrand_name = allData.filter((item) => {
        let includes = item.brand_name && item.brand_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempLastname = allData.filter((item) => {
        let includes = item.Lastname && item.Lastname.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempdomain_url = allData.filter((item) => {
        let includes = item.domain_url && item.domain_url.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempRole = allData.filter((item) => {
        let includes = item.role_name && item.role_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempCompany = allData.filter((item) => {
        let includes = item.Company && item.Company.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempLocation = allData.filter((item) => {
        let includes = item.Location && item.Location.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempReportTO = allData.filter((item) => {
        let includes = item.report_user_brand_name && item.report_user_brand_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        // ...tempId,
        ...tempbrand_name,
        ...tempLastname,
        ...tempdomain_url,
        ...tempRole,
        ...tempCompany,
        ...tempLocation,
        ...tempReportTO
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.find(function (post, index) {
          if (post.toString() === item.is_active.toString() ? item.is_active.toString() : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (role.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = role.find(function (user, index) {
          if (user.toLowerCase() == (item.role_name ? item.role_name.toLowerCase() : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }
    if (company.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = company.find(function (user, index) {
          if (user.toLowerCase() == (item.Company ? item.Company.toLowerCase() : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }

    if (location.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = location.find(function (user, index) {
          if (user.toLowerCase() == (item.Location ? item.Location.toLowerCase() : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }

    if (report.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = report.find(function (user, index) {
          if (user.toLowerCase() == (item.report_user_brand_name ? item.report_user_brand_name.toLowerCase() : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }

    if (status.length || search.length || role.length || company.length || report.length || location.length) {
      return updatedData;
    } else {
      return brandSettingData;
    }
  };

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const response = await GetListOfBrandSettingCancelToken(dropdownSource.token);
        setBrandSettingData(response?.data?.brand_setting_list);
        if(response?.status == 200){
          setIsLoaded(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsLoaded(false);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
}, [deleterow]);


  const columns = useMemo(() => [
    // {
    //   name: "ID",
    //   selector: "id",
    //   sortable: true,
    //   // maxWidth: "80px",
    //   // minWidth: "80px",
    //   cell: (row) => (
    //     <div className="ticket-id">
    //       <span className="overflow-ellipsis2">
    //         <b>#{row.id}</b>
    //       </span>
    //     </div>
    //   ),
    // },
    {
      name: "Name",
      selector: "brand_name",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm ">
            {/* <span
              className={
                "assigned-title-blk  name-icon cat-dark-red "
                // + ColorRender(row.role_name)
              }
            >
              {row.picture_me ? <img src={`${IMAGE_URL}${row.picture_me.replace("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.brand_name])}
              <span className={`profile-box-2-status ${row.Status ? row.Status : "Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
              <div className="assigned-title-info popup-right">
                <article>
                  <figure
                  //   className={ColorRender(row.role_name)}
                  >
                    {row.picture_me ? <img src={`${IMAGE_URL}${row.picture_me.replace("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.brand_name])}
                  </figure>
                  <figcaption>
                    <h4>
                      {row.brand_name} {row.Lastname}
                    </h4>
                    <a href="javascript:void(0);" onClick={() => navigator.clipboard.writeText(row.domain_url)}>
                      <i className="fal fa-envelope"></i> {row.domain_url}
                    </a>
                    <a href="javascript:void(0);" onClick={() => navigator.clipboard.writeText(row.Mobile)}>
                      <i className="fal fa-phone-alt"></i> {row.Mobile ? row.Mobile : "NA"}
                    </a>
                    <h6 className={row.is_active ? "as-green-bg as-widget" : "as-red-bg as-widget"}>{row.is_active ? "ACTIVE" : "INACTIVE"}</h6>
                  </figcaption>
                </article>
              </div>
            </span> */}
            {/* <PermissionsGate scopes={["bmedit"]} RenderError={() => <p>{row.brand_name}</p>}> */}
            <p>
              <Link className="as-text-blue curser " to={`/administration/brandSetting/editBrand/${row.id}`}>
                {row.brand_name}
              </Link>
            </p>
            {/* </PermissionsGate> */}
          </div>
        </div>
      ),
    },
    { name: "Domain URL", selector: "domain_url", sortable: true, cell: (row) => (row.domain_url ? row.domain_url : "NA") },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <Link className="btn btn-primary rounded-circle" to={`/administration/brandSetting/editBrand/${row.id}`} title="Open">
              <i className="fal fa-folder-open"></i>
            </Link>
            <PermissionsGate scopes={["bmdelete"]}>
              <button className="btn btn-danger rounded-circle" onClick={() => handleDelete(row.id)} title="Delete">
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);
  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteBrandSetting({ id: ID }).then((res) => {
            setDeleteRow(!deleterow);
          });
          history.push("/administration/brandSetting/list");

          Swal.fire("Deleted!", "Brand has been deleted.", "success");
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setDeleteRow(!deleterow);
        history.push("/administration/brandSetting/list");
      });
  };

  const handleEdit = (row, ID) => {
    history.push(`/administration/brandSetting/editBrand/${ID}`);
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Domain_URL"];

    data = data?.map((row) => ({
      ...row,
      Name: row?.brand_name ? row?.brand_name : "NA",
      Domain_URL: row.domain_url ? row.domain_url : "NA",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.brand_name, row.domain_url];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                  <div className="filter-scroll">
                      <div className={`filter-scroll-inner  filter-custom-new`}>
                      </div>
                  </div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("xlsx", "Brand_Setting");
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", "Brand_Setting");
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", "Brand_Setting");
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <Link to={`/administration/brandSetting/addNewBrand`}>
                  <PermissionsGate scopes={["bmadd"]} errorProps={{ disabled: true }}>
                    <button className="btn btn-primary" title="New Brand">
                      <i className="fal fa-plus"></i>New Brand
                    </button>
                  </PermissionsGate>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {isLoaded ? <SkeletonTicketList />
        :<DataTable
          data={dataToRender()}
          defaultSortField="brand_name"
          defaultSortAsc={true}
          columns={columns}
          pagination={true}
          noDataComponent={Str.noRecord}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
        }
      </div>
    </div>
  );
};

export default ListOfBrands;
