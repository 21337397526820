import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";

import { EMAIL_REGEX, MOBILE_REGEX} from "../../../../utils/Constants";
import Select from "react-select";
import { Status, Category, centreNumber } from "../../DataSelect";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AddEditCenter,
  GetEditCenterData,
} from "../../../../services/ExternalExamServices";
import { setExamCenter } from "../../../../store/actions";
import PermissionsGate from "../../../../utils/permissionGate";
import hasPermission from "../../../../utils/hasMultiplePermission";
import axios from "axios";

const AddCentre = () => {
  const { id, type } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [editorState, setEditorState] = React.useState("");
  const [centreData, setCentreData] = useState({});
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false)
  const [isStateReady, setIsStateReady] = useState(false)

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    setLoading(true)
    if (id) {
      let response = hasPermission({ scopes: ["eeepedit"], permissions: givenPermsisions });
      if (!response) {
        history.push("/noaccess")
      }else{
        const source = axios.CancelToken.source();
        const getDetails = async () =>{
          try {
            const res = await GetEditCenterData(id, source.token);
            
            if(res.status==200 && res?.data?.data){
              setCentreData(res?.data?.data);
              dispatch(setExamCenter(res.data.data.name));
              let detail = res?.data?.data?.details;
              setEditorState(detail);
              setIsStateReady(true)
              setLoading(false)
            }else{
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Record not Available",
              }).then(() => { history.goBack() });
            }
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.error(error);
              setIsStateReady(true)
              setLoading(false)
            }
          }
        }

        getDetails();
        return () =>{
          source.cancel('Component unmounted');
        }
      }       
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      No: "",
      name: centreData.name ? centreData.name : "",
      alias: centreData.centre_alias ? centreData.centre_alias : "",
      mobile: centreData.contact_number ? centreData.contact_number : "",
      address: centreData.center_physical_address ? centreData.center_physical_address : "",
      contact_person: centreData.contact_person ? centreData.contact_person : "",
      code: centreData.code ? centreData.code : "",
      status: centreData.active ? centreData.active :"0",
      category: centreData.centre_category ? centreData.centre_category : "",
      centre_number: centreData.centre_number ? centreData.centre_number : "",
      details: centreData.centre_number ? centreData.centre_number : "",
      email:  centreData.centre_email ? centreData.centre_email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .strict(true).max(250, "Name should be less than 250 characters")
        // .matches(NAME_REGEX, "Please enter valid name")
        .trim("Name cannot include leading and trailing spaces")
        .required("Name is required"),
      address: Yup.string()
        .strict()
        .trim("Address cannot include leading and trailing spaces"),
        // .required("Address is required"),
      alias: Yup.string()
        .strict(true)
        // .matches(NAME_REGEX, "Please enter valid alias name")
        .trim("Alias cannot include leading and trailing spaces"),
        // .required("Alias is required"),
      mobile: Yup.string()
        .strict(true)
        .matches(MOBILE_REGEX, "Contact number should be 0-9 of 10 digits")
        // .required("Contact number is required")
        .trim("Contact number cannot have leading and trailing spaces")
        .min(10, "Contact number should be 0-9 of 10 digits")
        .max(16, "Contact number should be 0-9 of 10 digits"),
      code: Yup.string()
        .strict(true)
        .trim("Code cannot include leading and trailing spaces"),
        // .required("Code is required"),

      contact_person: Yup.string().strict(true)
      // .required("Contact Person is required")
      .trim("Contact person cannot include leading and trailing spaces"),
      // category: Yup.string().required("Category is required"),
      // centre_number: Yup.string().required("Centre Number is required"),
      status: Yup.string().required("Status is required"),
      // details: Yup.string().test("required", "Details is required",
      // (value) => {
      //   let feature = formatQuestion(editorState);
      //   let isInvalid = feature == "-";
      //   return !isInvalid;
      // }),
      email: Yup.string().strict(true).trim("Email cannot include leading and trailing spaces")
        .email("Invalid email address").matches(EMAIL_REGEX, "Please Enter a Valid Email")
        // .required("Email is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaveLoading(true)
      let formData = new FormData();
      if (type == "edit") {
        formData.set("id", id);
      }

      formData.set("name", values?.name);
      formData.set("center_physical_address", values?.address);
      formData.set("contact_person", values?.contact_person);
      formData.set("contact_number", values?.mobile);
      formData.set("code", values?.code);
      formData.set("centre_number", values?.centre_number);
      formData.set("centre_alias", values?.alias);
      formData.set("centre_category", values?.category);
      formData.set("centre_email", values?.email);
      formData.set("active", values?.status);
      formData.set("type", "centre");
      formData.set("details", editorState);
      AddEditCenter(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
          });
          // resetForm();
          setSaveLoading(false)

          history.push("/studentAdministration/externalExam/examCentres/table");
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.data}`,
          });
          setSaveLoading(false)
        });
    },
  });

  const handleCancel = () =>{
    if(id){
      formik.resetForm();
    }else{
      history.push("/studentAdministration/externalExam/examCentres/table");
    }
  }

  return (
    <div className="card pt-3 pl-3 pr-3 card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="user-add-blk-main row mt-2 align-items-center">
          {/* <div className="col-md-4 mb-4"></div> */}
        </div>

        <div className="row">
          <div className="col-md-4">
            <label htmlFor="name">Name*</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.name && formik.touched.name
                    ? " is-invalid"
                    : "")
                }
                name="name"
                placeholder="Name"
                title="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.name && formik.touched.name ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>

          <div className="col-md-4">
            <label htmlFor="alias">Alias</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.alias && formik.touched.alias
                    ? " is-invalid"
                    : "")
                }
                name="alias"
                placeholder="Alias"
                title="Alias"
                value={formik.values.alias}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.alias && formik.touched.alias ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="address">Address</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.address && formik.touched.address
                    ? " is-invalid"
                    : "")
                }
                name="address"
                placeholder="Address"
                title="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.address && formik.touched.address ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <label htmlFor="email">Email</label>
            <div className="form-icon-group mb-4">
              <input
                type="email"
                className={
                  "form-control" +
                  (formik.errors.email && formik.touched.email
                    ? " is-invalid"
                    : "")
                }
                name="email"
                placeholder="Enter email address"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Email"
              />
              {formik.errors.email && formik.touched.email ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="mobile">Contact Number</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.mobile && formik.touched.mobile
                    ? " is-invalid"
                    : "")
                }
                name="mobile"
                title="Contact Number"
                placeholder="Enter Contact number"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.mobile && formik.touched.mobile ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="contact_person">Contact Person</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.contact_person && formik.touched.contact_person
                    ? " is-invalid"
                    : "")
                }
                name="contact_person"
                placeholder="Contact Person"
                title="Contact Person"
                value={formik.values.contact_person}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.contact_person && formik.touched.contact_person ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <label htmlFor="code">Code</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.code && formik.touched.code
                    ? " is-invalid"
                    : "")
                }
                name="code"
                title="Code"
                placeholder="Enter Code"
                value={formik.values.code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.code && formik.touched.code ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="status">Status*</label>
            <div className="form-icon-group mb-4" title="Status">
              <Select
                className={
                  "form-control custom-select-box" +
                  (formik.errors.status && formik.touched.status
                    ? " is-invalid"
                    : "")
                }
                name="status"
                value={Status.filter((value) => {
                  return value.value == formik.values.status;
                })}
                onChange={(value) =>{
                  if(value){
                    formik.setFieldValue("status", value.value)
                  } else {
                    formik.setFieldValue("status", "")
                  }
                }
                }
                isClearable
                onBlur={formik.handleBlur}
                options={Status}
                maxMenuHeight={175}
                placeholder={
                  formik.values.status ? formik.values.status : "Status  "
                }
              />
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="category">Category</label>
            <div className="form-icon-group mb-4" title="Category">
              <Select
                className={
                  "form-control custom-select-box" +
                  (formik.errors.category && formik.touched.category
                    ? " is-invalid"
                    : "")
                }
                name="category"
                value={Category.filter((val)=>{
                  return val.value == formik.values.category
                }) || ""}
                onChange={(value) =>{
                  if(value){
                    formik.setFieldValue("category", value.value)
                  } else {
                    formik.setFieldValue("category", "")
                  }
                }}
                isClearable
                onBlur={formik.handleBlur}
                options={Category}
                maxMenuHeight={175}
                placeholder={
                  formik.values.category ? formik.values.category : "Category  "
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <label htmlFor="centre_number">Centre Number</label>
            <div className="form-icon-group mb-4" title="Centre Number">
              <Select
                className={
                  "form-control custom-select-box" +
                  (formik.errors.centre_number && formik.touched.centre_number
                    ? " is-invalid"
                    : "")
                }
                name="centre_number"
                value={centreNumber.filter((val)=>{
                  return val.value == formik.values.centre_number
                }) || ""}
                options={centreNumber}
                onChange={(value) =>{
                  if(value){
                    formik.setFieldValue("centre_number", value.value)
                  } else {
                    formik.setFieldValue("centre_number", "")
                  }
                }
                  
                }
                onBlur={formik.handleBlur}
                isClearable
                // options={centre_number}
                maxMenuHeight={175}
                placeholder={
                  formik.values.centre_number
                    ? formik.values.centre_number
                    : "Centre Number"
                }
              />
            </div>
          </div>
          <div className="col-md-12">
            <label>Details</label>
            {((type == "edit" && isStateReady) || type=="add") && <HtmlInputEditor
              editorState={editorState}
              setEditorState={setEditorState}
              loading={(id && loading) ? loading : true}
              hideSign
              isCKEditor
              isInvalid={
                formik.touched.details &&
                formik.errors.details
              }
            />}
          </div>
        </div>

        <div className="form-group form-group-save-cancel">
        <PermissionsGate scopes={type=="add" ? ["eeecadd"] : ["eeecedit"]}>
          <button
            disabled={saveLoading}
            className="btn btn-save btn-success"
            type="submit"
            title="Save"
            onClick={formik.handleSubmit}
          >
            {saveLoading ? <i className="fas fa-cog fa-spin"></i> :<i className="fal fa-save"></i>}
            Save
          </button>
          </PermissionsGate>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => handleCancel()}
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>
          {formik.touched.name && formik.errors.name ? (
            <div className="invalid-feedback d-block">{formik.errors.name}</div>
          ) : null}
          {formik.touched.alias && formik.errors.alias ? (
            <div className="invalid-feedback d-block">
              {formik.errors.alias}
            </div>
          ) : null}
          {formik.touched.email && formik.errors.email ? (
            <div className="invalid-feedback d-block">
              {formik.errors.email}
            </div>
          ) : null}
          {formik.touched.address && formik.errors.address ? (
            <div className="invalid-feedback d-block">
              {formik.errors.address}
            </div>
          ) : null}
          {formik.touched.contact_person && formik.errors.contact_person ? (
            <div className="invalid-feedback d-block">
              {formik.errors.contact_person}
            </div>
          ) : null}
          {formik.touched.mobile && formik.errors.mobile ? (
            <div className="invalid-feedback d-block">
              {formik.errors.mobile}
            </div>
          ) : null}
          {formik.touched.status && formik.errors.status ? (
            <div className="invalid-feedback d-block">
              {formik.errors.status}
            </div>
          ) : null}
          {formik.touched.code && formik.errors.code ? (
            <div className="invalid-feedback d-block">{formik.errors.code}</div>
          ) : null}
          {formik.touched.category && formik.errors.category ? (
            <div className="invalid-feedback d-block">
              {formik.errors.category}
            </div>
          ) : null}
          {formik.touched.centre_number && formik.errors.centre_number ? (
            <div className="invalid-feedback d-block">
              {formik.errors.centre_number}
            </div>
          ) : null}
          {formik.touched.details && formik.errors.details ? (
            <div className="invalid-feedback d-block">
              {formik.errors.details}
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default AddCentre;
