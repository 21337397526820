import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import {
  AddStudentHubCategoryData,
  EditStudentHubCategoryData,
  GetCategoryDetail,
} from "../../../services/StudentService";

import { useLocation, useParams } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import HtmlInputEditor from "../../common/HtmlInputEditor";

let Id = "";
let TabId = "";
let CatId = "";
let Action = "";
const CategoryAction = () => {
  const history = useHistory();
  const location = useLocation();
  const [categoryData, setCategoryData] = useState([]);
  const [selectKeywords, setSelectedKeywords] = useState([]);

  const { tabId, id } = useParams();
  TabId = tabId;
  CatId = id;
  if (history.location.state) {
    const { id, action } = history.location.state;
    Id = id;
    Action = action;
  }


  useEffect(() => {
    if (location.pathname != `/support/student-hub-library/student-hub/${TabId}/section/create/new`) {
      GetCategoryDetail({ categoryId: CatId })
        .then((res) => {
          setCategoryData(res.data?.hub_categories);
          setSelectedKeywords(res.data?.hub_categories.categoryKeywords?.split(","));
          formik.setFieldValue("categoryKeywords", res.data?.hub_categories.categoryKeywords?.split(","))
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: location.pathname == `/support/student-hub-library/student-hub/${TabId}/section/create/new` ? false : true,
    initialValues: {
      categoryName: categoryData?.categoryName,
      categoryDescription: categoryData?.categoryDescription,
      categoryVisible:
        categoryData?.categoryVisible && categoryData?.categoryVisible == 1 ? true : false,
      categoryKeywords: selectKeywords,
      tab_id: tabId
    },
    validationSchema: Yup.object({
      categoryName: Yup.string().required("Category Name is required"),
      categoryVisible: Yup.string().required("Visible is required"),
      categoryKeywords: Yup.array().min(1, "atleast one keyword is required").required(),
    }),
    onSubmit: (values) => {
      
      values.categoryVisible = values.categoryVisible == true ? 1 : 0;
      
      if (location.pathname ===`/support/student-hub-library/student-hub/${TabId}/section/create/new`) {
        values = { ...values };
        AddStudentHubCategoryData(values)
          .then((res) => {
            if(res.status===200){
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Updated successfully",
                onClose: goToListing,
              });
            }           
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data?.data}`,
            });
          });
      } else {
        values = { ...values, categoryId:id };
        EditStudentHubCategoryData(values)
          .then((res) => {
            if(res.status===200){
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Updated successfully",
                onClose: goToListing,
              })
            }
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data?.data}`,
            });
          });
        
      }
    },
  });

  const goToListing = () => {
    // history.push(`/support/student-hub-library/student-hub/${TabId}/section/open/${Id}`, {
    //   id: TabId,
    //   action: "open",
    // });
    history.push(
      `/support/student-hub-library/student-hub/open/${TabId}`,
      {
        id: TabId,
        action: "open",
      }
    );
  };

  const handleKeyDown = (event) => {
    var keyword = selectKeywords;
    if (event.key === "Enter") {
      if (event.target.value) {
        keyword.push(event.target.value);
        event.target.value = ""
        setSelectedKeywords((keyword) => [...keyword]);
        formik.setFieldValue("categoryKeywords", keyword);
      }

    }
  };

  const removeKeyword = (index) => {
    var keyword = selectKeywords;
    var keywords = keyword.splice(index, 1)
    setSelectedKeywords((keyword) => [...keyword])
    formik.setFieldValue("categoryKeywords", keyword);
  };

  function onKeyDown1(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  //   const initials = userData?.Firstname[0] + userData?.Lastname[0];
  // NEW EDITOR
  // const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

  // const onEditorStateChange = editorState => setEditorState(editorState);

  // NEW EDITOR  
  return (
    <div className="card card-profile-info-card">
      {/* <div className="card-header">Section Data</div> */}
      <div className="card-body-inr card-body-info">
        <form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown1}>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <label>Name*</label>
              <div className="form-icon-group mb-4">
                <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.categoryName && formik.touched.categoryName
                      ? " is-invalid"
                      : "")
                  }
                  name="categoryName"
                  placeholder="Name"
                  title="Name"
                  value={formik.values.categoryName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.categoryName && formik.touched.categoryName ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <label>Keywords*</label>
              <div className="form-icon-group">
                <div className="form-control custom-select-box">
                  <input
                    type="text"
                    className={"form-control " + (formik.errors.categoryKeywords && formik.touched.categoryKeywords ? " is-invalid" : "")}
                    name="keywords"
                    placeholder="keywords"
                    title="Keywords"
                    onBlur={formik.handleBlur}
                    onKeyDown={handleKeyDown}
                  />
                  {formik.errors.categoryKeywords &&
                    formik.touched.categoryKeywords ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              {selectKeywords.length > 0 && (
                <ul className="tag-list">
                  {selectKeywords?.map((ele, index) => (
                    <li>
                      {ele}
                      <span onClick={() => removeKeyword(index)}>
                        <i className="fal fa-times"></i>
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group mb-4" title="Published">
                <label>Published</label>
                <div className="custom-control custom-checkbox text-left mt-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="categoryVisible"
                    name="categoryVisible"
                    checked={formik.values.categoryVisible}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="categoryVisible"
                  >
                    Published
                  </label>
                  {formik.errors.categoryVisible &&
                    formik.touched.categoryVisible ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className=" mb-4">
                <label>Description</label>
                <HtmlInputEditor
                  name="categoryDescription"
                  isCKEditor={true}
                  // editorState={editorState}
                  // hideSign={true}
                  // setEditorState={setEditorState}
                  // belowNoMargin={true}
                  editorState={formik.values.categoryDescription}
                  hideSign={true}
                  belowNoMargin={true}
                  setEditorState={(val) => { formik.setFieldValue("categoryDescription", val) }}
                  isInvalid={formik.touched.categoryDescription && formik.errors.categoryDescription}
                />
              </div>
            </div>
          </div>
          <div className="form-group-save-cancel">
            <PermissionsGate errorProps={{ disabled: true }} scopes={["shedit"]}>
              <button
                className="btn btn-save btn-success"
                type="submit"
                title="Save"
              >
                <i className="fal fa-save"></i>
                Save
              </button>
            </PermissionsGate>
            <button
              className="btn btn-close btn-danger"
              type="button"
              title="Cancel"
              onClick={() => history.goBack()}
            >
              <i className="fal fa-times"></i>
              Cancel
            </button>
          </div>
          <div className="form-group form-group-save-cancel ml-new">
            {formik.touched.categoryName && formik.errors.categoryName ? (
              <div className="invalid-feedback d-block">
                {formik.errors.categoryName}
              </div>
            ) : null}
            {formik.touched.categoryVisible && formik.errors.categoryVisible ? (
              <div className="invalid-feedback d-block">
                {formik.errors.categoryVisible}
              </div>
            ) : null}
            {formik.touched.categoryKeywords && formik.errors.categoryKeywords ? (
              <div className="invalid-feedback d-block">
                {formik.errors.categoryKeywords}
              </div>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CategoryAction;
