import React, { useState, useEffect, useRef } from "react";
import { ForgotPasswordService, getMobileNumberByEmail } from "../services/LoginService";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function ResetPasswordForm(props) {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [sms, setSms] = useState(false);
  const [smsMsg, setSmsMsg] = useState("");
  const [email, setEmail] = useState("");

  const emailField = useRef();
  const smsField = useRef();

  let history = useHistory();

  // const handleCheck = (e) => {
  //   setChecked(e.target.checked);
  // };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Email: "",
      sms: sms,
    },
    validationSchema: Yup.object({
      Email: Yup.string().email("Invalid email address").required("Email is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);

      ForgotPasswordService(values)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
            timer: 3000,
            onOpen: function () {
              Swal.showLoading();
            },
          }).then(function (result) {
            if (result.dismiss === "timer") {
              setLoading(false);
              history.push("/home");
            }
          });
          // console.log(res?.Status)
          // if (res.data.status == 1) {
          //   Swal.fire({
          //     icon: "success",
          //     title: "Success",
          //     text: res.data.message,
          //     timer: 3000,
          //     onOpen: function () {
          //       Swal.showLoading();
          //     },
          //   }).then(function (result) {
          //     if (result.dismiss === "timer") {
          //       setLoading(false);
          //       history.push("/home");
          //     }
          //   });
          // } else {
          //   Swal.fire({
          //     icon: "error",
          //     title: "Failed",
          //     text: res.data.message,
          //   });
          //   setLoading(false);
          // }
        })
        .catch(function (err) {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: err.response.data.message,
          });
          setLoading(false);
          return false;
        });
    },
  });

  const handleSms = (e) => {
    if (e.target.checked) {
      if (emailField.current.value != "") {
        setSms(true);
        setChecked(true);
        setSmsMsg("");

        if (formik.errors.email) {
          setSmsMsg("Incorrect Email");
        } else {
          messageFunction();
        }
      } else {
        setSmsMsg("Can’t send SMS without email address entered");
      }
    } else {
      setSms(false);
      setChecked(false);
      setSmsMsg("");
    }
  };

  const messageFunction = () => {
    getMobileNumberByEmail({ email: emailField.current.value })
      .then((res) => {
        setSmsMsg("Sms will be sent to default mobile number: " + res.data.mobile);
      })
      .catch((error) => {
        setSmsMsg("Email not found in database!");
      });
  };

  const handleEmail = (e) => {
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(e.target.value) && smsField.current.checked) {
      setSmsMsg("Incorrect Email");
    } else {
      setSmsMsg("");
    }

    //Showed mobile number when user write valide email and tick sms
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(e.target.value) && smsField.current.checked) {
      messageFunction();
    }

    setEmail(e.target.value);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text" id="">
              <i className="fal fa-user"></i>
            </span>
          </div>
          <input
            type="email"
            className={"form-control" + (formik.errors.Email && formik.touched.Email ? " is-invalid" : "")}
            placeholder="Email"
            name="Email"
            // onChange={(e) => handleEmail(e)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Email}
            // ref={emailField}
          />
        </div>

        {/* <div className="custom-control custom-checkbox text-left">
          {/* <input
            type="checkbox"
            className={"custom-control-input " + (formik.touched.sms && formik.errors.sms ? " is-invalid" : "")}
            name="sms"
            onChange={(e) => handleSms(e)}
            onBlur={formik.handleBlur}
            checked={checked}
            id="customCheck1"
            ref={smsField}
          /> */}

          {/* {formik.touched.sms && formik.errors.sms ? <div className="invalid-feedback">{formik.errors.sms}</div> : null} */}

          {/* <label className="custom-control-label" for="customCheck1">
            Include SMS
          </label> */}

          {/* <p className="smsMessage">{smsMsg && smsMsg}</p> 
        </div> */}

        <button type="submit" className="btn btn-white-bordered btn-submit-info btn-primary " disabled={loading} title="Reset Password">
          {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paper-plane"></i>} Reset Password
        </button>

        {formik.touched.Email && formik.errors.Email ? <div className="invalid-feedback d-block">{formik.errors.Email}</div> : null}
      </form>
    </>
  );
}
