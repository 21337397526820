import React from "react";
import { useParams } from "react-router";
import { GetStudentAuditTrailData, GetStudentPortalAuditFilters, GetStudentPortalAuditTrail } from "../../../../../services/StudentsSettingServices";
import AuditTrailsTable from "../../../../common/AuditTrailsTable";
import { RenderStudentResourceType } from "../../../../../utils/CommonGroupingItem";

function StudentAuditTrail() {
  const { id } = useParams();

  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]

  return(
    <AuditTrailsTable
    apiFunction={GetStudentPortalAuditTrail}
    filterArrApi={GetStudentPortalAuditFilters}
    columnsToShow={columnsToShow}
    exportFileName={"Student_Audit_Trail"}
    isResourceNameShown={true}
    apiParams={{action_id: id}}
    actionId={id}
    isShowingResourceTypeFilter
    renderResourceTypeFunction={RenderStudentResourceType}
    />
  )
}

export default StudentAuditTrail;
