import React from 'react'
import {
    PDFDownloadLink,
    Document,
    Page,
    Image,
    View,
    StyleSheet,
    Text,
    Font,
} from "@react-pdf/renderer";



const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    padding : 20
  },
  heading: {
    fontSize: 12,
    lineHeight: "normal",    
    fontFamily: "Helvetica-Bold"
  },
  boldText: {
    fontSize: 8,
    lineHeight: "normal",    
    fontFamily: "Helvetica-Bold"
  },
  normalText: {
    fontSize: 8,
    lineHeight: "normal",    
    fontFamily: "Helvetica"
  },
  text:{
    fontSize:10,
    fontFamily: "Helvetica"
  },
  headInfoBox:{
    borderTop: "1px solid #000",
    borderBottom: "1px solid #000",
    padding:10,
    display:"flex",
    flexDirection:'row'
  },
  headInfoBoxLeft:{
    display:"flex",
    flexDirection:'column',
    width:"50%",
  },

  headInfoBoxRight:{
    display:"flex",
    flexDirection:"column",
    width:"50%"
  },
  inputContainer:{
    display:'flex',
    padding:"2px 5px 5px 2px",
    flexDirection:'row',
    justifyContent:'space-between',
  },
  inputBox:{
    backgroundColor:"yellow",
    padding:"2px",
    width:"50%",
    border:"1px solid #000",
    minHeight:"20px",
    display:"flex",
    alignItems:"center",
    textAlign:"center",
    justifyContent:"center"
  },
  tableBox: {
    // border: "1px solid #000"
  },
  tableRow:{
    display:'flex',
    flexDirection:"row", 
    fontFamily: "Helvetica-Bold"   
  },
  tableCell:{
    padding:'2px',
    borderLeft: "1px solid #000",
    borderBottom: "1px solid #000",
  },
  tableCellLast:{
    padding:'2px',
    borderLeft: "1px solid #000",
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000"
  },
  tableHead:{
    display: "flex",
    flexDirection: "row",
    backgroundColor:"yellow",
    fontFamily: "Helvetica-Bold"
  },
  bottomNote:{
    fontSize: 8,
    fontFamily: "Helvetica-Bold",
    color:"red",
    marginTop:"15px"
  },
  infoText:{
    display:"flex",
    flexDirection:"row",    
    paddingBottom:"5px"
  }
});
function InvoicePdf({data}) {  
  return (
    <Document>
      <Page size="A4" style={[styles.page]}>        
        <Text style={styles.heading}>
          The CAD Corporation Draughting Academy (PTY) LTD
        </Text>
        <Text style={styles.text}>Reg: 2012/211963/07 VAT No: 4580266627</Text>
        <View style={styles.headInfoBox}>
          <View style={styles.headInfoBoxLeft}>
            <View style={{...styles.inputContainer}}>
              <Text style={styles.boldText}>To:</Text>
              <View style={styles.inputBox}><Text style={styles.boldText}>{" "}</Text></View>
            </View>            
          </View>
          <View style={styles.headInfoBoxRight}>
            <View style={{...styles.inputContainer}}>
              <Text style={styles.boldText}>Invoice Number:</Text>
              <View style={styles.inputBox}><Text style={styles.boldText}>{data.invoice_number ? data.invoice_number : " "}</Text></View>
            </View>
            <View style={{...styles.inputContainer}}>
              <Text style={styles.boldText}>Invoice Date:</Text>
              <View style={styles.inputBox}><Text style={styles.boldText}>{data.invoice_date}</Text></View>
            </View>
            <View style={{...styles.inputContainer}}>
              <Text style={styles.boldText}>Invoice Due:</Text>
              <View style={styles.inputBox}><Text style={styles.boldText}>{data.invoice_due_date}</Text></View>
            </View>
          </View>
        </View>         
        <View style={styles.tableBox}>
          <View style={styles.tableHead}>
            <View style={{...styles.tableCell, width:"20%"}}> 
              <Text style={styles.boldText}>
                No
              </Text>
            </View>
            <View style={{...styles.tableCell, width:"50%"}}> 
              <Text style={styles.boldText}>
                Description
              </Text>
            </View>
            <View style={{...styles.tableCellLast, width:"30%"}}> 
              <Text style={styles.boldText}>
                Amount
              </Text>
            </View>
          </View>
          <View>     
            {data?.subjects.map((row,index)=>(
              <View style={styles.tableRow}>
                <View style={{...styles.tableCell, width:"20%"}}> 
                  <Text style={styles.boldText}>
                    {index+1}
                  </Text>
                </View>
                <View style={{...styles.tableCell, width:"50%"}}> 
                  <Text style={styles.boldText}>
                    {row.name}
                  </Text>
                </View>
                <View style={{...styles.tableCellLast, width:"30%"}}> 
                  <Text style={styles.boldText}>
                    {data.exam_subject_cost}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableRow}>
              <View style={{width:"70%", textAlign:"right"}}>
                <Text style={styles.boldText}>
                  Sub Total
                </Text>
              </View>
              <View style={{...styles.tableCellLast, width:"30%"}}> 
                <Text style={styles.boldText}>
                  {data.sub_total}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableRow}>
              <View style={{width:"70%", textAlign:"right"}}>
                <Text style={styles.boldText}>
                  VAT
                </Text>
              </View>
              <View style={{...styles.tableCellLast, width:"30%"}}> 
                <Text style={styles.boldText}>
                  {data.vat}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableRow}>
              <View style={{width:"70%", textAlign:"right"}}>
                <Text style={styles.boldText}>
                  Total
                </Text>
              </View>
              <View style={{...styles.tableCellLast, width:"30%"}}> 
                <Text style={styles.boldText}>
                  {data.total}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.infoText}>
          <Text style={{...styles.boldText, width:"10%"}}>Bank:</Text>
          <Text style={styles.normalText}>ABSA</Text>
        </View>
        <View style={styles.infoText}>
          <Text style={{...styles.boldText, width:"10%"}}>Branch:</Text>
          <Text style={styles.normalText}>Centurion</Text>
        </View>
        <View style={styles.infoText}>
          <Text style={{...styles.boldText, width:"10%"}}>Branch Code:</Text>
          <Text style={styles.normalText}>632005</Text>
        </View>
        <View style={styles.infoText}>
          <Text style={{...styles.boldText, width:"10%"}}>Acc Number:</Text>
          <Text style={styles.normalText}>4080813053 (Cheque Acc)</Text>
        </View>
        <View>
          <Text style={styles.bottomNote}>NB: Please use the invoice number on your deposit slip or electronic payment and fax the proof of payment to our offices</Text>
        </View>     
      </Page>
    </Document>
  )
}

export default InvoicePdf