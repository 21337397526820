import React, { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { GetListOfResources, DeleteAssemblyResource } from "../../../services/CourseService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { TrimText } from "../../common/TrimText";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";

const ResourcePool = () => {

  const history = useHistory();
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBlock, setSelectedBlock] = useState("");
  const [deleteRow, setDeleteRow] = useState(false);
  const [search, setSearch] = useState("");

  // Pagination related states
  const [sortkey, setSortKey] = useState("item_name");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  // var serachTimeHandle = null;
  // End of Pagination related states

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    getData();
  }, [deleteRow, page, perPage, sortOrder, sortkey, search]);

  function getData() {
    let obj = {
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search
    }

    GetListOfResources(obj).then((res) => {
      setLoading(false);
      setResources(res?.data?.assemblies_item_list?.data);
      setTotalRows(res?.data?.assemblies_item_list?.total);
    })
  }

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (e) => {

    if (!e.destination) return;

    // let tempData = Array.from(users);
    // let [source_data] = tempData.splice(e.source.index, 1);
    // tempData.splice(e.destination.index, 0, source_data);

    const sourceIndex = e.source.index;
    const destIndex = e.destination.index;

    if (e.type === "outer") {
      let tempData = reorder(resources, sourceIndex, destIndex);
      setResources(tempData);
    }

    if (e.type === "inner") {
      const element = e.draggableId.split("+");
      let newarray = resources[element[0]].sub_items;
      let tempdata = reorder(newarray, sourceIndex, destIndex);

      const newUsers = JSON.parse(JSON.stringify(resources));
      newUsers[element[0]].sub_items = tempdata;

      setResources(newUsers);

      // change the index of dummy array of user
      // [a[3], a[4]] = [a[4], a[3]]
      // [a[e.source.index],a[e.destination.index]] = [a[e.destination.index],a[e.source.index]]
    }
  };

  const handleBlock = (i) => {
    if (selectedBlock == "lowerBlock-" + i) {
      setSelectedBlock("");
    } else {
      setSelectedBlock("lowerBlock-" + i);
    }
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAssemblyResource({ item_id: id }).then((res) => setDeleteRow(!deleteRow));
        Swal.fire("Deleted!", "Your resource has been deleted.", "success");
      }
    });
  };

  // Resources list (Module/Category list):
  // 1.  Category
  // 2.  #Resources
  // 3.  #Linked Assemblies
  // 4.  Button “Open”  -> SPEC 4
  // 5.  Expadable Button
  //     1. Duplicate as New -> SPEC 4
  //     2. Delete -> Confirmation pop-up

  // const ExpandedComponent = ({ data }) => <div>hi</div>;

  const itemCountSorting = (rowA, rowB) => {
    const name1 = rowA?.sub_items.length && rowA?.sub_items[0] && rowA?.sub_items[0]?.__meta__ && rowA?.sub_items[0]?.__meta__?.items_count ? rowA?.sub_items[0]?.__meta__?.items_count : ""
    const name2 = rowB?.sub_items.length && rowB?.sub_items[0] && rowB?.sub_items[0]?.__meta__ && rowB?.sub_items[0]?.__meta__?.items_count ? rowB?.sub_items[0]?.__meta__?.items_count : ""
    return (name1 > name2)
  };

  const assemblyCountSorting = (rowA, rowB) => {
    const name1 = rowA?.sub_items.length && rowA?.sub_items[0] && rowA?.sub_items[0]?.__meta__ && rowA?.sub_items[0]?.__meta__?.assembly_count ? rowA?.sub_items[0]?.__meta__?.assembly_count : ""
    const name2 = rowB?.sub_items.length && rowB?.sub_items[0] && rowB?.sub_items[0]?.__meta__ && rowB?.sub_items[0]?.__meta__?.assembly_count ? rowB?.sub_items[0]?.__meta__?.assembly_count : ""
    return (name1 > name2)
  };

  const columns = useMemo(() => [
    // {
    //   name: "ID",
    //   selector: "item_id",
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="ticket-id">
    //       <span className="overflow-ellipsis2">
    //         <b>#{row.item_id}</b>
    //       </span>
    //     </div>
    //   ),
    // },
    {
      name: "Category",
      selector: "item_name",
      sortable: true,
      // wrap: true,
      cell: (row) => <Link className="as-text-blue curser" to={`/courses/content/resource-pool/edit/${row.item_id}`} class="as-text-blue curser " title={row.item_name}>{row.item_name ? TrimText(row.item_name) : ""}</Link>,
    },
    {
      name: "Resources",
      selector: "Resources",
      sortable: true,
      // sortFunction: itemCountSorting,
      // wrap: true,
      cell: (row) => (<span title={row?.items_count ? row?.items_count : "0"}>{row?.items_count ? row?.items_count : "0"}</span>),
      // cell: (row) => (<p title={row?.sub_items[0]?.__meta__?.items_count ? row?.sub_items[0]?.__meta__?.items_count : "0"}>{row?.sub_items[0]?.__meta__?.items_count ? row?.sub_items[0]?.__meta__?.items_count : "0"}</p>),
    },
    {
      name: "Linked Assemblies",
      selector: "LinkedAssemblies",
      sortable: true,
      // sortFunction: assemblyCountSorting,
      // wrap: true,
      cell: (row) => (<span title={row?.assemble_count ? row?.assemble_count : "0"}>{row?.assemble_count ? row?.assemble_count : "0"}</span>),
      // cell: (row) => (<span title={row?.sub_items[0]?.__meta__?.assembly_count ? row?.sub_items[0]?.__meta__?.assembly_count : "0"}>{row?.sub_items[0]?.__meta__?.assembly_count ? row?.sub_items[0]?.__meta__?.assembly_count : "0"}</span>),
    },
    {
      name: "Action",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <Link to={`/courses/content/resource-pool/edit/${row.item_id}`}>
              <button className="btn btn-primary rounded-circle" title="Open" >
                {/* <button className="btn btn-primary rounded-circle" title="Open" onClick={() => history.push(`/courses/content/resource-pool/edit/${row.item_id}`)}> */}
                <i className="fal fa-folder-open"></i>
              </button>
            </Link>

            <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" title="More" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <Link to={`/courses/content/resource-pool/duplicate/${row.item_id}`} class="mr-1">
                  <button className="btn btn-primary rounded-circle" title="Duplicate">
                    <i className="fal fa-copy"></i>
                  </button>
                </Link>
                <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => handleDelete(row.item_id)}>
                  <i className="fal fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Category", "Resources", "Linked Assemblies"];
    // console.log("data exportData", data);
    data = data?.map((row) => ({
      ...row,
      Category: row?.item_name || "-",
      Resources: row?.sub_items[0]?.__meta__?.items_count || "-",
      "Linked Assemblies": row?.sub_items[0]?.__meta__?.assembly_count || "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Category, row.Resources, row["Linked Assemblies"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const dataToRender = () => {
    let updatedData = [];
    // console.log("resources from dataToRender", resources);
    let allData = resources;
    if (search?.length) {
      // let tempId = allData.filter((item) => {
      //   let includes = item.item_id.toString().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempTabName = allData.filter((item) => {
        let includes =
          item.item_name &&
          item.item_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        // ...tempId,
        ...tempTabName,
        // ...tempVisible,
        // ...tempIcon,
        // ...tempLinkedBrand,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (search.length) {
      return updatedData;
    } else {
      return allData;
    }
  }

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);

    // clearInterval(serachTimeHandle);
    // var serachTimeHandle = setInterval(() => {
    //   getData();
    //   clearInterval(serachTimeHandle);
    // }, 1500);
    // console.log("settimeout's value  >>>> ", serachTimeHandle);
    // return () => {
    //   clearTimeout(timeout);
    // };
  };

  const reset = () => {
    setSearch("");
    // setPerPage(10);
    // setPage(1);
  }

  return (
    <>
      <div className="custom-table-div filter-search-icon card card-table-custom custom-table-expand-blk">
        <div className="filter-search-bar-blk">
          <div className="filter-button-group">
            <div className="filter-eff filter-data-btn">
              <button className="filter-buttons">
                <i className="fal fa-filter"></i>
              </button>
            </div>

            <div className="button-reset dropdown-comman">
              <button className="btn btn-primary" title="Reset" onClick={() => { reset() }}>
                <i className="fal fa-redo"></i>Reset
              </button>
            </div>

            <div className="files-export-group">
              <button
                type="button"
                className="btn btn-files"
                onClick={() => { exportData("xlsx", "Resource Pool"); }}
                title="Export spreadsheet"
              >
                <i className="fal fa-file-excel icon"></i>
              </button>

              <button
                type="button"
                className="btn btn-files"
                onClick={() => { exportData("csv", "Users Audit Trail"); }}
                title="Export CSV"
              >
                <i className="fal fa-file-csv icon"></i>
              </button>

              <button
                type="button"
                className="btn btn-files"
                onClick={() => { exportData("pdf", "Users Audit Trail"); }}
                title="Export PDF"
              >
                <i className="fal fa-file-pdf icon"></i>
              </button>
            </div>
          </div>
          <div className="add-ticket-blk button-reset dropdown-comman">
            <Link to={`/courses/content/resource-pool/add`}>
              <button className="btn btn-primary" title="New Resource">
                {/* <button className="btn btn-primary" title="New Resource" onClick={() => history.push("/courses/content/resource-pool/add")}> */}
                <i className="fal fa-plus"></i>New Resource
              </button>
            </Link>
          </div>
        </div>

        <div className="system-administration-table table-responsive">
          <div className="table-responsive-div">
            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
              <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                <div id="assessment-table-main_filter" className="dataTables_filter">
                  <label>
                    <input onChange={handleSearchFilter} value={search} type="search" className="" placeholder="Search" aria-controls="assessment-table-main" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loading ? <SkeletonTicketList />
          : <DataTable
            data={dataToRender()}
            columns={columns}
            pagination={true}
            defaultSortAsc={true}
            defaultSortField="item_name"
            onSort={handleSort}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
            paginationServer
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />}

        {/* <DataTable data={dataToRender()} columns={columns} pagination={true} expandableRows={true} expandableRowsComponent={<ExpandedComponent></ExpandedComponent>} /> */}

        {/* { loading  ? <SkeletonTicketList /> : <div className="custom-table-expandable App mt-4">
          <DragDropContext onDragEnd={handleDragEnd}>
            <table className="table borderd">
              <thead>
                <tr>
                  <th />
                  <th />
                  <th>Category</th>
                  <th>#Resources</th>
                  <th>#Linked Assemblies</th>
                  <th>Actions </th>
                </tr>
              </thead>
              <Droppable droppableId="droppable-1" type="outer">
                {(provider) => (
                  <tbody className="text-capitalize" ref={provider.innerRef} {...provider.droppableProps}>
                    {
                      console.log("first resources", resources)}
                    {resources?.map((resource, index) => (
                      <Draggable key={index} draggableId={`${resource.item_id}`} index={index}>
                        {(provider) => {
                          return (
                            <>
                              <tr {...provider.draggableProps} ref={provider.innerRef}>
                                <td {...provider.dragHandleProps}>
                                  <i className="fal fa-arrows"></i>
                                </td>
                                <td>
                                  <button type="button" className="btn expand-btn" data-toggle="toggle" onClick={() => {handleBlock(index)}}>
                                    {resource?.sub_items.length>0 && <i className="fas fa-caret-down"></i>}
                                    
                                  </button>
                                </td>
                                <td>{resource.item_name}</td>
                                <td><b>{resource?.sub_items[0]?.__meta__?.items_count ? "#" + resource?.sub_items[0]?.__meta__?.items_count : "-"}</b></td>
                                <td><b>{resource?.sub_items[0]?.__meta__?.assembly_count ? "#" + resource?.sub_items[0]?.__meta__?.assembly_count : "-"}</b></td>
                                <td>
                                  <div className="assessment-08 btn-dropdown-grp">
                                    <div className="as-buttons">
                                      <button className="btn btn-primary rounded-circle" title="Open" onClick={() => history.push(`/courses/content/resource-pool/edit/${resource.item_id}`)}>
                                        <i className="fal fa-folder-open"></i>
                                      </button>

                                      <div className="dropdown btn-dropdown-item">
                                        <button
                                          className="btn btn-primary rounded-circle dropdown-toggle"
                                          type="button"
                                          id="dropdownMenuButton"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="fal fa-ellipsis-h-alt"></i>
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <button className="btn btn-primary rounded-circle" title="Duplicate" onClick={() => history.push(`/courses/content/resource-pool/duplicate/${resource.item_id}`)}>
                                            <i className="fal fa-copy"></i>
                                          </button>
                                          <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => handleDelete(resource.item_id)}>
                                            <i className="fal fa-trash-alt"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              {selectedBlock == `lowerBlock-${index}` && <tr className="hideTr">
                                <td colSpan={6}>
                                  {resource?.sub_items?.length > 0 ?
                                  <Droppable droppableId={`droppable-inner-${index}`} type="inner">
                                  {(nprovider) => (
                                    <div className="custom-table-div card-table-custom">
                                      <div className="rdt_Table" ref={nprovider.innerRef} {...nprovider.droppableProps}>
                                        {resource?.sub_items?.map((item, index1) => (
                                          <Draggable key={index1} draggableId={`${index}+${item.item_id}`} index={index1}>
                                            {(nprovider) => {
                                              // console.log("nested", nprovider.innerRef);
                                              return (
                                                <div className="rdt_TableRow" {...nprovider.draggableProps} ref={nprovider.innerRef}>
                                                  <div className="rdt_TableCell" {...nprovider.dragHandleProps}>
                                                    <i className="fal fa-arrows"></i>
                                                  </div>
                                                  <div className="rdt_TableCell">{item.item_name}</div>
                                                  
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </Droppable> : "No data"
                                  }
                                  
                                </td>
                              </tr>}

                            </>
                          );
                        }}
                      </Draggable>
                    ))}
                  </tbody>
                )}
              </Droppable>
            </table>
          </DragDropContext>

          <div className="table-pagination">
            <nav className="dKvXPZ rdt_Pagination">
              <span className="kkNaNQ exREar">Rows per page:</span>
              <div className="gENuGq">
                <select aria-label="Rows per page:" className="sc-iwjdpV gKsGGb">
                  <option value="10" selected="">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                </select>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M7 10l5 5 5-5z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
              <span className="kkNaNQ bqKZDG">1-2 of 2</span>
              <div className="hGeawu">
                <button id="pagination-first-page" type="button" aria-label="First Page" aria-disabled="true" disabled className="iNniLt">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                  </svg>
                </button>
                <button id="pagination-previous-page" type="button" aria-label="Previous Page" aria-disabled="true" disabled className="iNniLt">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <button id="pagination-next-page" type="button" aria-label="Next Page" aria-disabled="true" disabled className="iNniLt">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <button id="pagination-last-page" type="button" aria-label="Last Page" aria-disabled="true" disabled className="iNniLt">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                  </svg>
                </button>
              </div>
            </nav>
          </div>
          
        </div> } */}


      </div>
    </>
  );
};

export default ResourcePool;
