import React from "react";
import { useParams } from "react-router-dom";
import AuditTrail from "../AuditTrails";
import EmailAuditTrail from "./EmailAuditTrail";
import EmailEdit from "./EmailEdit";
import ListTable from "./ListTable";

function EmailTab({ setName }) {
  const { tab, type, id } = useParams();
  return (
    <div>
      {type && type == "table" && !id && <ListTable />}
      {type && type == "edit" && id && <EmailEdit setName={setName} emailId={id} />}
      {type && type == "add" && <EmailEdit />}
      {/* {type && type == "add" && !id && <CustomerAdd />}
  {type && type == "edit" && id && <CustomerEdit customer_id={id} />}*/}
    </div>
  );
}

export default EmailTab;
