import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { EditBrandSettingAPI, GetBrandSettingCommonContent, GetBrandSettingDetail } from "../../../services/BrandServices";
import { URL_REGEX } from "../../../utils/Constants";
import { GlobalIconNames } from "../../../utils/GlobalIconNames";
import PermissionsGate from "../../../utils/permissionGate";
import Header from "../../common/Header";
import { getCurrentTimeHomePage } from "../../common/Helper";
import SideBar from "../../common/SideBar";
import Tabs from "../Tabs";

function DetailTab() {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [disabled, setDisabled] = useState(false); 
  const [brandSettingData, setBrandSettingData] = useState({});
  const [brandWelcomeLetter, setBrandWelcomeLetter] = useState([]);
  const [brandWelcomeLetterSelected, setBrandWelcomeLetterSelected] = useState();
  const [brandWelcomeLetterLabel, setBrandWelcomeLetterLabel] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      PortalTitle: brandSettingData && brandSettingData.title ? brandSettingData.title : "",
      IOS_url: brandSettingData && brandSettingData.iosAppUrl ? brandSettingData.iosAppUrl : "",
      Android: brandSettingData && brandSettingData.androidAppUrl ? brandSettingData.androidAppUrl : "",
      StudentCardQR: brandSettingData && brandSettingData.studentCardVerifyUrl ? brandSettingData.studentCardVerifyUrl : "",
      BrandWelcomeLetter: brandSettingData && brandSettingData.brand_welcome_letter ? brandSettingData?.brand_welcome_letter : "",
    },
    validationSchema: Yup.object({
      PortalTitle: Yup.string().required("Portal Title is required").trim("Enter valid title"),
      IOS_url: Yup.string().url("Please Enter IOS_url URL").matches(URL_REGEX, "IOS_url URL is not valid").required("IOS_url Url Is Required"),
      Android: Yup.string().url("Please Enter Android URL").matches(URL_REGEX, "Android URL is not valid").required("Android Url Is Required"),
      StudentCardQR: Yup.string().url("Please Enter Student Card QR URL").matches(URL_REGEX, "Student Card QR URL is not valid").required("Student Card QR Url Is Required"),
      BrandWelcomeLetter: Yup.mixed().required("Welcome Letter is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setDisabled(true)
      let formData = new FormData();
      formData.set("type", "portal_detail");
      formData.set("id", id);
      formData.set("title", values?.PortalTitle);
      formData.set("androidAppUrl", values?.Android);
      formData.set("iosAppUrl", values?.IOS_url);
      formData.set("studentCardVerifyUrl", values?.StudentCardQR);
      formData.set("brand_welcome_letter", brandWelcomeLetterSelected);
      EditBrandSettingAPI(formData)
        .then((res) => {
          for (var value of formData.values()) {
          }
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Added successfully",
          });
          history.push(location.pathname.includes("add") ? `/administration/brandSetting/addPortalSetting/visualsTab/${id}` : `/administration/brandSetting/editPortalSetting/visualsTab/${id}`);
        })
        .catch((err) => {
          console.log("error: ", err?.response);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.error?.message}`,
          });
        });
    },
  });
  useEffect(() => {
    GetBrandSettingCommonContent()
      .then((response) => {
        setBrandWelcomeLetterSelected(brandSettingData?.brand_welcome_letter);
        let x = {
          value: "",
          label: "",
        };
        x = response?.data?.brand_welcome_letter.map((data) => {
          return { ...x, value: `${data.value}`, label: `${data.title}` };
        });
        setBrandWelcomeLetter(x);
      })
      .catch((error) => console.log("error: ", error));

    GetBrandSettingDetail(id)
      .then((response) => {
        if (response?.status === 200) {
          setBrandSettingData(response?.data?.brand_setting);
          setBrandWelcomeLetterSelected(response?.data?.brand_setting?.brand_welcome_letter);
          formik.setFieldValue("BrandWelcomeLetter", response?.data?.brand_setting?.brand_welcome_letter);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, []);

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                <div className="d-flex align-items-center ">
                  <div className="name-w-head d-flex align-items-center ">
                    <h4 className="text-left-align landing-heading heading_color_dynamic">Brand: <span>{brandSettingData && brandSettingData?.brand_name}</span></h4>
                  </div>
                  <div className="text-md-right action2-blk">
                    <div className="ticket-view-btn d-flex align-items-center">
                      <div className="add-new-ticket-bx">
                        <button onClick={()=>history.goBack()} title="Back" className="btn btn-white-bordered">
                          <i className="fal fa-angle-left"></i>Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="tabs-wrap">
                  <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li
                      className="nav-item"
                      onClick={() => history.push(location.pathname.includes("add") ? `/administration/brandSetting/addNewBrand` : `/administration/brandSetting/editBrand/${id}`)}
                    >
                      <a className="nav-link " id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                        <i className="fal fa-info-circle"></i>Details
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          location.pathname.includes("add") ? `/administration/brandSetting/addPortalSetting/detailsTab/${id}` : `/administration/brandSetting/editPortalSetting/detailsTab/${id}`
                        )
                      }
                    >
                      <a className="nav-link active" id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                        <i class="fal fa-books"></i>Portal Settings
                      </a>
                    </li>
                    {location.pathname.includes("add") ? "" : (<li
                      className="nav-item"
                      onClick={() => history.push(`/administration/brandSetting/singleAuditTrail/${id}`)}
                      title={"Audit Trail"}
                    >
                      <Link
                        className="nav-link "
                        aria-disabled={false}
                        id="pills-tab1"
                        data-toggle="pill"
                        to={"/administration/brandSetting/singleAuditTrail"}
                        role="listitem"
                        // aria-controls={isPortalEnabled && "pills-tab3"}
                        // aria-selected={isPortalEnabled}
                      >
                        {GlobalIconNames("auditTrail")}
                      </Link>
                    </li>)}
                  </ul>
                  <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          location.pathname.includes("add") ? `/administration/brandSetting/addPortalSetting/detailsTab/${id}` : `/administration/brandSetting/editPortalSetting/detailsTab/${id}`
                        )
                      }
                    >
                      <a className="nav-link active " id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                      <i className="fal fa-file-alt"></i>Details
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          location.pathname.includes("add") ? `/administration/brandSetting/addPortalSetting/visualsTab/${id}` : `/administration/brandSetting/editPortalSetting/visualsTab/${id}`
                        )
                      }
                    >
                      <a className="nav-link " id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                      <i class="fal fa-laptop"></i>Visuals
                      </a>
                    </li>
                  </ul>
                  <div className="card card-body-inr">
                    <form onSubmit={formik.handleSubmit}>
                      {/* <h4 className="text-left-align landing-heading heading_color_dynamic">Details Tab</h4> */}
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="portalTitle">Portal Title *</label>
                          <div className="form-icon-group mb-4" title="Title">
                            <input
                              type="text"
                              id="portalTitle"
                              className={"form-control" + (formik.errors.PortalTitle && formik.touched.PortalTitle ? " is-invalid" : "")}
                              name="PortalTitle"
                              placeholder="Portal Title"
                              title="Portal Title"
                              value={formik.values.PortalTitle}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="IOS_url">IOS App URL *</label>
                          <div className="form-icon-group mb-4">
                            <input
                              type="text"
                              id="IOS_url"
                              className={"form-control" + (formik.errors.IOS_url && formik.touched.IOS_url ? " is-invalid" : "")}
                              name="IOS_url"
                              title="IOS_url"
                              placeholder="Enter IOS URL"
                              value={formik.values.IOS_url.replace(" ", "")}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.IOS_url && formik.touched.IOS_url ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="android">Android App URL *</label>
                          <div className="form-icon-group mb-4">
                            <input
                              type="text"
                              id="android"
                              className={"form-control" + (formik.errors.Android && formik.touched.Android ? " is-invalid" : "")}
                              name="Android"
                              title="Android"
                              placeholder="Enter Android URL"
                              value={formik.values.Android.replace(" ", "")}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.Android && formik.touched.Android ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="studentCard">Student Card QR *</label>
                          <div className="form-icon-group mb-4">
                            <input
                              type="text"
                              id="studentCard"
                              className={"form-control" + (formik.errors.StudentCardQR && formik.touched.StudentCardQR ? " is-invalid" : "")}
                              name="StudentCardQR"
                              title="StudentCardQR"
                              placeholder="Enter Student Card QR URL"
                              value={formik.values.StudentCardQR.replace(" ", "")}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.StudentCardQR && formik.touched.StudentCardQR ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="BrandWelcomeLetter">Welcome Letter *</label>
                          <div className="">
                            <div className="form-icon-group mb-4 " title="Welcome Letter">
                              <Select
                                className={"form-control custom-select-box" + (formik.errors.BrandWelcomeLetter && formik.touched.BrandWelcomeLetter ? " is-invalid" : "")}
                                name="BrandWelcomeLetter"
                                value={brandWelcomeLetter.filter(function (option) {
                                  return option.value == formik.values.BrandWelcomeLetter;
                                })}
                                onChange={(value) => {
                                  setBrandWelcomeLetterSelected(value.value);
                                  formik.setFieldValue("BrandWelcomeLetter", value.label);
                                }}
                                // getOptionLabel={(option) => option.title}
                                // getOptionValue={(option) => option.value}
                                onBlur={formik.handleBlur}
                                options={brandWelcomeLetter}
                                maxMenuHeight={175}
                                placeholder={formik.values.BrandWelcomeLetter ? formik.values.BrandWelcomeLetter : "Select"}
                              />
                              {formik.errors.BrandWelcomeLetter && formik.touched.BrandWelcomeLetter ? (
                                <span className="exclamation">
                                  <i className="fal fa-exclamation-circle"></i>
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group form-group-save-cancel">
                      <PermissionsGate scopes={location.pathname.includes("add") ? ["bmadd"] : ["bmedit"]} errorProps={{ disabled: true }}>
                        <button className="btn btn-save btn-success" type="submit" title="Save" disabled={!(formik.dirty)}>
                        {disabled ? <i className="fas fa-cog fa-spin"></i>: <i className="fal fa-save"></i>}
                          Save
                        </button>
                        </PermissionsGate>
                        <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.push("/administration/brandSetting/list")}>
                          <i className="fal fa-times"></i>
                          Cancel
                        </button>

                        {formik.touched.PortalTitle && formik.errors.PortalTitle ? <div className="invalid-feedback d-block">{formik.errors.PortalTitle}</div> : null}
                        {formik.touched.IOS_url && formik.errors.IOS_url ? <div className="invalid-feedback d-block">{formik.errors.IOS_url}</div> : null}
                        {formik.touched.Android && formik.errors.Android ? <div className="invalid-feedback d-block">{formik.errors.Android}</div> : null}
                        {formik.touched.StudentCardQR && formik.errors.StudentCardQR ? <div className="invalid-feedback d-block">{formik.errors.StudentCardQR}</div> : null}
                        {formik.touched.BrandWelcomeLetter && formik.errors.BrandWelcomeLetter ? <div className="invalid-feedback d-block">{formik.errors.BrandWelcomeLetter}</div> : null}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailTab;
