import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import PermissionsGate from "../../../utils/permissionGate";
import hasPermission from "../../../utils/hasMultiplePermission";
import { LayoutHeaderStatus, RenderWebsiteBrandTemplate } from "../../../utils/CommonStatusItems";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";
import { RenderPopupType } from "../../../utils/CommonGroupingItem";
import DataTableComponentFrontPagination from "../../common/DataTableComponentFrontPagination";
import { getHeadersPageListFilters, getPageList } from "../../../services/LayoutHeaderServices";


function AssignPagePopup({ formik, assignedPage, setAssignedPage, id ,modalId, some,assignedPageRef }) {
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [brand, setBrand] = useState({ arr: [], checkObj: {} });
  const [type, setType] = useState({ arr: [], checkObj: {} });
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [searchAssigned, setSearchAssigned] = useState("");
  const [headerData, setHeaderData] = useState([]);
  const [loading, setloading] = useState(true);
  const [filterData, setFilterData] = useState({
    status: [],
    brand: [],
    type: [],
  });


  const assign = assignedPageRef.current;
  

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });

  useEffect(() => {
    const getPageListType = async () => {
      setloading(true);

      try {
        const res = await getPageList(id , 0);
        setHeaderData(res?.data?.data);
        if (res.status == 200) {
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };

    getPageListType();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getHeadersPageListFilters();

        setFilterData({
          ...res.data,
          status: res.data.statusFilters ? res.data.statusFilters : [],
          brand: res.data.brandFilters ? res.data.brandFilters : [],
          type: res.data.typeFilters ? res.data.typeFilters : [],
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, []);


  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "PageTitle",
      sortField: "PageTitle",
      sortable: true,
      cell: (row) => (
        <Link
          title={row.PageTitle}
          className="as-text-blue curser feature-name"
          to={`${row.flag == 4 ? `/website_management/pages/categorylandingpages/table/open/${row.id}` : row.flag == 0 ? `/website_management/pages/staticpages/table/update/${row.id}` : row.flag == 2 ? `/website_management/pages/courselandingpages/table/open/${row.id}` : row.flag == 3 ? `/website_management/pages/campuspages/table/update/${row.id}` : row.flag == 1 ? `/websiteManagement/events/landing_page/page_details/update/${row.id}` : null}`}
          onClick={() => {
            $("#assignPagePopupBrandTemp").modal("hide");
          }}
        >
          <span className="textLimit100">
            {row.PageTitle ? row.PageTitle : ""}{" "}
          </span>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) =>
        row.status?.toString()
          ? LayoutHeaderStatus(row.status)?.html
          : "-",
    },
    {
      name: "Brand Templates",
      selector: "brandTemplete",
      sortField: "brandTemplete",
      sortable: false,
      cell: (row) => {
        if (row.brandTemplete.length) {
          return (
            <>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {row?.brandTemplete.map((d, i) => (
                  <React.Fragment key={i}>
                    {RenderWebsiteBrandTemplate(d?.name)?.html}
                  </React.Fragment>
                ))}
              </div>
            </>
          );
        } else {
          return "-";
        }
      },
    },
    {
      name: "Type",
      selector: "type",
      sortField: "type",
      sortable: true,
      cell: (row) => row?.type ? RenderPopupType(row?.type, "popup")?.html : "-"
    },
    {
      name: "Action",
      selector: "",
      maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            {(some ? some?.PageID : assign?.PageID )!= row.PageID ? (
              <button
                type="button"
                className="btn btn-primary rounded-circle"
                onClick={() => {
                  setAssignedPage({ ...row });
                  if(modalId){
                    $(modalId).modal("hide");
                  }else{
                    $("#assignPagePopupBrandTemp").modal("hide");
                  }
                }}
                title="Assign Page"
              >
                <i className="fal fa-plus"></i>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-danger rounded-circle"
                onClick={() => {
                  setAssignedPage({});
                }}
                title="Assign Page"
              >
                <i className="fal fa-minus"></i>
              </button>
            )}
          </div>
        </div>
      ),
    },
  ]);

  const dataToRenderAvailable = () => {
    let updatedData = headerData;
  
    // Apply search filter
    if (searchAssigned.length) {
      updatedData = updatedData.filter((item) =>
        item?.PageTitle?.toLowerCase().includes(searchAssigned.toLowerCase())
      );
    }
  
    // Apply status filter
    if (status.arr.length) {
      updatedData = updatedData.filter((item) =>
        status.arr.includes(item.status)
      );
    }
  
    // Apply brand filter
    if (brand.arr.length) {
      updatedData = updatedData.filter((item) =>
        item.brandTemplete.some((template) =>
          brand.arr.includes(template.name)
        )
      );
    }
  
    // Apply type filter
    if (type.arr.length) {
      updatedData = updatedData.filter((item) =>
        type.arr.includes(item.type)
      );
    }
  
    return updatedData;
  };
  

  const exportAvailableData = () => {
    let data = dataToRenderAvailable();

    const header = [
      "Name",
      "Status",
      "Brand Template",
      "Type",
    ];
    data = data.map((row) => {
      const brandTemplate = row.brandTemplete?.map((item) => item.name).join(",");

      return ({
        "Name": row.PageTitle ? row.PageTitle : "-",
        "Status": row.status,
        "Brand Template": brandTemplate,
        "Type": row?.type,
      })
    });

    const pdfData = data.map((row) => {
      return [
        row["Name"],
        row["Status"],
        row["Brand Template"],
        row["Type"],
      ];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };

  return (
    <>
      <DataTableComponentFrontPagination
        data={dataToRenderAvailable()}
        columns={columns}
        loading={loading}
        exportData={exportAvailableData}
        exportFileName={"Assigned_pages"}
        defaultSort={{ defaultSortColumn: "name", defaultSortAsc: false }}
        custumcss="border-0"
        search={searchAssigned}
        progressComponent={<SkeletonTicketList />}
        setSearch={setSearchAssigned}
        filters={[
          {
            filterName: "Status",
            optionArr: filterData?.status,
            state: status,
            setState: setStatus,
            uniqueId: "Headerstatus",
            isOptionReversed: true,
            renderLabelFunction: LayoutHeaderStatus
          },
          {
            filterName: "Brand Template",
            optionArr: filterData?.brand,
            state: brand,
            setState: setBrand,
            uniqueId: "HeaderBrandTemplate",
            isOptionReversed: true,
          },
          {
            filterName: "Type",
            optionArr: filterData?.type,
            state: type,
            setState: setType,
            uniqueId: "availableStudyType",
            renderLabelFunction: RenderPopupType,
          }
        ]}
      // bottomButton={
      //   [
      //     <div className="form-group form-group-save-cancel">
      //       <button
      //         className="btn btn-save btn-success"
      //         type="button"
      //         title="Save"
      //         data-dismiss="modal"
      //         aria-label="Close"
      //         // disabled={loading ? true : false}
      //       >
      //         {/* {loading === false ? (
      //           <i className="fal fa-save"></i>
      //         ) : (
      //           <i className="fas fa-cog fa-spin"></i>
      //         )} */}
      //         <i className="fal fa-save"></i>
      //         Save
      //       </button>
      //       <button
      //         className="btn btn-close btn-danger"
      //         type="button"
      //         title="Cancel"
      //         data-dismiss="modal"
      //         aria-label="Close"
      //       >
      //         <i className="fal fa-times"></i>Cancel
      //       </button>
      //     </div>
      //   ]
      // }
      />
    </>
  );
}
export default AssignPagePopup;
