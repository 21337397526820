import $ from "jquery";
import "jspdf-autotable";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import CoursesTable from "./CoursesTable";
import listIcon from "../../../../../assets/images/list-tree.svg";
import PermissionsGate from "../../../../../utils/permissionGate";
import { RenderCorrespondence, RenderProgrammeOrCourseType } from "../../../../../utils/CommonGroupingItem";
import DataTableComponentFrontPagination from "../../../../common/DataTableComponentFrontPagination";
import { TABLE_DATE_FORMAT } from "../../../../../utils/Constants";
import { CouresListDropdown, GetCouresListDropdownCancelToken, GetCourselist, GetCourselistCancelToken } from "../../../../../services/DashBoardServices";
import AllCourseTable from "./AllCourseTable";
import axios from "axios";
import { GetAvailableSubject } from "../../../../../services/StudentsSettingServices";
import Swal from "sweetalert2";

function AssignedProgrmasAndCourses(props) {
  const [assignQualification, setAssignQualification] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);

  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [type, setType] = useState({ arr: [], checkObj: {} });
  const [intakeArr, setIntakeArr] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [allCourseLoading, setAllCourseLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10)
  const [sortKey, setSortKey] = useState("start");
  const [sortOrder, setSortOrder] = useState("DESC")
  const [courseSearch, setCourseSearch] = useState("");
  const [courseDropdown, setCourseDropdown] = useState({ programme: [], intake: [], learning: [] })
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
  const [reply, setReply] = useState({ arr: [], checkObj: {} });
  const [programme, setProgramme] = useState({ arr: [], checkObj: [] });
  const [status, setStatus] = useState({ arr: ["In Progress"], checkObj: { "statusIn Progress": true } });
  const [courseIntake, setCourseIntake] = useState({ arr: [], checkObj: {} });
  const [allCourseError, setAllCourseError] = useState(false)
  const [tempCheckedAllData, setTempCheckedAllData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [cancelTokenSources, setCancelTokenSources] = useState([]);
  const [gradingStandard, setGradingStandard] = useState()


  useEffect(() => {
    setAssignQualification(props.data);
    setIntakeArr(props.intakeArr);
    return () => {
      return
    }
  }, [props]);

  useEffect(() => {
    const cancelTokenDropdownSources = [];

    const fetchData = async () => {
      cancelTokenDropdownSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenDropdownSources.push(source);
  
      let data = {
        viaStatus: status.arr.length > 0 ? status.arr : ["Upcoming"]
      }
  
      try {
        const res = await GetCouresListDropdownCancelToken(data, source.token);
        setCourseDropdown({ ...res.data, programme: res.data.programme, intake: res.data.yearsAndIntaks, learning: res.data.intakeType })
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }

    fetchData();
  
    return () => {
      cancelTokenDropdownSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  // useEffect(() => {
    
  //   let data = {
  //     viaStatus: status.arr.length > 0 ? status.arr : ["Upcoming"]
  //   }
  //   CouresListDropdown(data)
  //     .then((data) => {
  //       setCourseDropdown({ ...data.data, programme: data.data.programme, intake: data.data.yearsAndIntaks, learning: data.data.intakeType })
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  // const handleAssignProgrammeClick = () => {
  //   // Calling the GetAvailableSubject API 
  //   const apiData = {
  //     student_id: props.id
  //   }

  //   GetAvailableSubject(apiData)
  //     .then((res) => {
  //       console.log("API response:", res);

  //       setAssignQualification(res.data.assign_subject);
  //     })
  //     .catch((error) => {
  //       console.error("API error:", error);
  //     });
  // };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "course",
      sortable: true,
      cell: (row) => (
        <>
          {row.course ? (
            row.programs_course_type !== "programs" ? (<Link
              to={`/courseAdministration/coursesdetails/${row.id}/dashboard/show`}
              className="as-text-blue curser feature-name"
              title={row.course}
            >
              <span className="textLimit100">{row.course}</span>
            </Link>) : (
            <Link
              to={{pathname: `/courseAdministration/Programmes/programme/open/${row.id}/details`}}
              className="as-text-blue curser feature-name"
              title={row.course}
            >
              <span className="textLimit100">{row.course}</span>
            </Link>
        )
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Start",
      selector: "start",
      sortable: true,
      cell: (row) => (
        <>
          {row.start ? (
            <div className="dateTime">
              <p className="right-space">{moment.unix(row.start).format(TABLE_DATE_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "End",
      selector: "end",
      sortable: true,
      cell: (row) => (
        <>
          {row.end ? (
            <div className="dateTime">
              <p className="right-space">{moment.unix(row.end).format(TABLE_DATE_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      cell: (row) => <>{row.year ? row.year + "-" + row.number : "-"}</>,
    },
    {
      name: "Type",
      selector: "programs_course_type",
      sortable: true,
      cell: (row) => <>{row.programs_course_type ? RenderProgrammeOrCourseType(row.programs_course_type).html : "-"}</>,
    },
    {
      name: "Learning Method",
      selector: "type",
      sortable: true,
      cell: (row) => <div>{row.type ? RenderCorrespondence(row.type).html : "-"}</div>,
    },
    {
      name: "Actions",
      selector: "",
      cell: (row, index) =>
        row.programs_course_type == "programs" ? (
          
            <div className="assessment-08 btn-dropdown-grp">
              <div className="as-buttons d-flex">
                {/* <button
                  title="Manage Subjects"
                  className="btn btn-primary rounded-circle"
                  onClick={() => {
                    showCourses(row);
                  }}
                >
                  <img src={listIcon} height="15px" width="15px" />
                </button> */}
                {<PermissionsGate
                      RenderError={() => (
                        <button className="btn btn-primary rounded-circle" disabled title="Open">
                          <i className="fal fa-folder-open"></i>
                        </button>
                      )}
                      scopes={["capview"]}
                    >
                      <Link className="btn btn-primary rounded-circle" to={{ pathname: `/courseAdministration/Programmes/programme/open/${row.id}/details` }} title="Open">
                        <i className="fal fa-folder-open"></i>
                      </Link>
                  </PermissionsGate>}
                <div className="dropdown btn-dropdown-item ">
                  <button
                    className="btn btn-primary rounded-circle dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    title="More"
                  >
                    <i className="fal fa-ellipsis-h-alt"></i>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {/* <PermissionsGate
                      RenderError={() => (
                        <button className="btn btn-primary rounded-circle" disabled title="Open">
                          <i className="fal fa-folder-open"></i>
                        </button>
                      )}
                      scopes={["capview"]}
                    >
                      <Link className="btn btn-primary rounded-circle" to={{ pathname: `/courseAdministration/Programmes/programme/open/${row.id}/details` }} title="Open">
                        <i className="fal fa-folder-open"></i>
                      </Link>
                    </PermissionsGate> */}
                    {
                      <button
                      title="Manage Subjects"
                      className="btn btn-primary rounded-circle"
                      onClick={() => {
                        setGradingStandard(row.grading_standard)
                        showCourses(row);
                      }}
                    >
                      <img src={listIcon} height="15px" width="15px" />
                    </button>
                    }
                    <PermissionsGate errorProps={{ disabled: true }} scopes={["scpdelete"]}>
                      <button
                        title="Unlink"
                        className="btn btn-danger rounded-circle"
                        onClick={() => {
                          props.onDelete(row);
                        }}
                      >
                        <i className="fal fa-unlink"></i>
                      </button>
                    </PermissionsGate>
                  </div>
                </div>
              </div>
            </div>
        
        ) : (
          <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons">
              <PermissionsGate
                RenderError={() => (
                  <button className="btn btn-primary rounded-circle" disabled title="Open">
                    <i className="fal fa-folder-open"></i>
                  </button>
                )}
                scopes={["capview"]}
              >
                <Link className="btn btn-primary rounded-circle" to={{ pathname: `/courseAdministration/coursesdetails/${row.id}/dashboard/show` }} title="Open">
                  <i className="fal fa-folder-open"></i>
                </Link>
              </PermissionsGate>
              <PermissionsGate errorProps={{ disabled: true }} scopes={["scpdelete"]}>
                <button
                  title="Unlink"
                  className="btn btn-danger rounded-circle"
                  onClick={() => {
                    props.onDelete(row);
                  }}
                >
                  <i className="fal fa-unlink"></i>
                </button>
              </PermissionsGate>
            </div>
          </div>
        ),
    },
  ]);

  function dataToRender() {
    let updatedData = [];
    let allData = assignQualification;
    if (search.length) {
      let tempName = allData.filter((item) => {
        let includes = item.course.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        ...tempName
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (intake.arr.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = intake.arr.find(function (post, index) {
          if (post == (item.year && item.number ? item.year + "/" + item.number : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (type.arr.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = type.arr.find(function (user, index) {
          if (user == (item.programs_course_type ? item.programs_course_type : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (intake.arr.length || search.length || type.arr.length) {
      return updatedData;
    } else {
      return assignQualification;
    }
  }

  const exportData = () => {
    let data = assignQualification;
    const header = ["Name", "Start", "End", "Intake", "Type", "Learning Method"];

    data = data?.map((row) => ({
      ...row,
      Name: row.course,
      "Start": row.start ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : "-",
      "End": row.end ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : "-",
      Intake: row.year ? row.year + "-" + row.number : "-",
      Type: row.programs_course_type ? RenderProgrammeOrCourseType(row.programs_course_type).text : "-",
      "Learning Method": row.type ? RenderCorrespondence(row.type).text : "-",
    }));
    const pdfData = data.map((row) => {
      return [row.Name, row["Start"], row["End"], row["Intake"], row.Type, row["Learning Method"]];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };

  const getAllCourses = async () => {
    setAllCourseError(false)
    setTempCheckedAllData([])
    setSelectedRows([])
    setAllCourseLoading(true)

    cancelTokenSources.forEach(source => {
      source.cancel('New request made');
    });
    
    const source = axios.CancelToken.source();
    setCancelTokenSources([...cancelTokenSources, source]);

    const data = {
      pageNo: pageNo,
      size: perPage,
      intake: courseIntake.arr,
      viaLearnType: learningMethod.arr,
      key: sortKey,
      sort: sortOrder,
      viaStatus: status.arr,
      viaSearch: courseSearch,
      viaProgramme: programme.arr,
      viaMessage: reply.arr,
      viaGradingStandard: gradingStandard == 1 ? 3 : gradingStandard
      // exportStatus: "true"
    };

    try {
      const res = await GetCourselistCancelToken(data, source.token);
      setAllCourses(res.data.result)
      setTotalRows(res.data.Pagination.total)
      if(res.status == 200){
        setAllCourseLoading(false)
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
        setAllCourseLoading(false);
      }
    }
  }

  useEffect(() => {
    getAllCourses()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [pageNo, perPage, sortKey, sortOrder, courseSearch, status, learningMethod, programme, courseIntake, reply])


  async function showCourses(courses) {
    setSelectedCourses(courses);
    $("#viewCourses").modal("show");
  }

  async function closeCourses() {
    setSelectedCourses([]);
    $("#viewCourses").modal("hide");
  }

  function closeAllCourses() {
    $("#viewAllCourses").modal("hide");
  }

  function removeCourse(course) {
    assignQualification.map((elem, ind) => {
      if (elem.id === course.id) {
        if (!course.sub_item.length) {
          assignQualification.splice(ind, 1);
          setAssignQualification([...assignQualification]);
        } else {
          // assignQualification.splice(ind, 1);
          let tempArr = assignQualification;
          let selectedObj = tempArr.find((item) => item.id == course.id);
          const index = tempArr.indexOf(selectedObj);
          if (index !== -1) {
            tempArr[index] = course;
          }
          setAssignQualification(tempArr);
        }
        closeCourses();
      }
    });
  }

  function getChangedCourse(newCourse) {
    let arr = [...assignQualification];
    let index;
    arr = arr.map((elem, ind) => {
      if (elem.id === newCourse.id) {
        index = ind;
      } else {
        return elem;
      }
    });
    arr[index] = newCourse;
    setAssignQualification([...arr]);
  }

  // const updateData = () => {

  
  //   const apiData = {
  //     page: props.tableState.page,
  //     limit: props.tableState.perPage,
  //     key: props.tableState.sortKey,
  //     sort: props.tableState.sortOrder,
  //     search: props.search,
  //     viaInatke: props.availableIntake.arr,
  //     viaType: props.availableType.arr,
  //     exportStatus: "true",
  //     student_id: props.id,
  //   };
  
  //   GetAvailableSubject(apiData)
  //   .then((res) => {
  //    let data = res.data.assign_subject;
  //    const header = ["Name", "Start", "End", "Intake", "Type", "Learning Method"];

  //    data = data?.map((row) => ({
  //     ...row,
  //     Name: row.course,
  //     "Start": row.start ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : "-",
  //     "End": row.end ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : "-",
  //     Intake: row.year ? row.year + "-" + row.number : "-",
  //     Type: row.programs_course_type ? RenderProgrammeOrCourseType(row.programs_course_type).text : "-",
  //     "Learning Method": row.type ? RenderCorrespondence(row.type).text : "-",
  //   }));

  //   })
  
  // };

  return (
    <>
      <DataTableComponentFrontPagination
        data={dataToRender()}
        columns={columns}
        loading={props.loading}
        search={search}
        setSearch={setSearch}
        exportData={exportData}
        exportFileName={"Assigned_Programme"}
        defaultSort={{ defaultSortColumn: "start", defaultSortAsc: false }}
        isInsidePopUp
        filters={[
          {
            filterName: "Intake",
            optionArr: props.filterData.intake,
            state: intake,
            setState: setIntake,
            isOptionReversed: true,
          },
          {
            filterName: "Type",
            optionArr: props.filterData.type,
            state: type,
            setState: setType,
            renderLabelFunction: RenderProgrammeOrCourseType
          }
        ]}
        tableButton={
          [
            <button className="btn btn-primary" title="Assign Programme" data-toggle="modal" data-target="#Assign-Programme" >
              <i className="fal fa-plus"></i>Assign Programme
            </button>
          ]
        }
      />

      {/* Modal to show table of child courses */}
      <div className="topic-add-modal modal fade" id="viewCourses" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">Courses</h5>
              <button
                type="button"
                className="close"
                onClick={() => { closeCourses() }}
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">×
                </span>
              </button>

            </div>
            <div className="modal-body">
              <CoursesTable courses={selectedCourses} isRemoveBtn={true} removeCourse={removeCourse} setCourse={getChangedCourse} getAllCourses={getAllCourses} />
            </div>
          </div>
        </div>
      </div>
      <div className="topic-add-modal modal fade" id="viewAllCourses" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">Courses</h5>
              <button
                type="button"
                className="close"
                onClick={() => { closeAllCourses() }}
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">×
                </span>
              </button>

            </div>
            <div className="modal-body">
              <AllCourseTable
                courses={allCourses}
                loading={allCourseLoading}
                programmeCourse={selectedCourses}
                setSelectedProgramme={setSelectedCourses}
                closeAllCourses={closeAllCourses}
                totalRows={totalRows}
                pageNo={pageNo}
                perPage={perPage}
                setPerPage={setPerPage}
                setPageNo={setPageNo}
                setSortKey={setSortKey}
                setSortOrder={setSortOrder}
                setCourseSearch={setCourseSearch}
                search={courseSearch}
                intakeArr={courseDropdown.intake}
                programmeArr={courseDropdown.programme}
                learningArr={courseDropdown.learning}
                intake={courseIntake}
                setIntake={setCourseIntake}
                learningMethod={learningMethod}
                setLearningMethod={setLearningMethod}
                reply={reply}
                setReply={setReply}
                programme={programme}
                setProgramme={setProgramme}
                status={status}
                setStatus={setStatus}
                sortOrder={sortOrder}
                setAllCourseError={setAllCourseError}
                allCourseError={allCourseError}
                setTempCheckedAllData={setTempCheckedAllData}
                tempCheckedAllData={tempCheckedAllData}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
              />
            </div>
          </div>
        </div>
      </div>
      {/* End of Modal to show table of child courses */}
    </>
  );
}


export default AssignedProgrmasAndCourses;
