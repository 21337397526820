export const Status = [
  { value: "1", label: "Center Active" },
  { value: "0", label: "Center Closed" },
];
export const Category = [
  { value: "Draughting", label: "Draughting Subject" },
  { value: "Engineering & Draughting", label: "Engineering & Draughting Subject" },
  { value: "Engineering", label: "Engineering Subject" },
];

export const centreNumber = [
  { value: "2108", label: "2108-Cape Town" },
  { value: "2801", label: "2801-Midrand" },
  { value: "5013", label: "5013-External" },
];

export const Trimester = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];

export const ExamStatus = [
  { value: "CLOSED", label: "CLOSED" },
  { value: "HIDE", label: "HIDE" },
  { value: "IN PROGRESS", label: "IN PROGRESS" },
];

export const ApplicationStatus = [
  { value: "APPROVED", label: "APPROVED" },
  { value: "APPROVED-INV", label: "APPROVED-INV" },
  { value: "APPROVED-PAID", label: "APPROVED-PAID" },
  { value: "SUBMITTED", label: "Submitted" },
  { value: "WITHDRAWN", label: "Withdrawn" },
];

export const StudentType = [
  { value: "south-african-citizen", label: "South African Citizen" },
  { value: "international-student", label: "International Student" },
];

export const Gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

export const Race = [
  { value: "asian", label: "Asian" },
  { value: "african", label: "African" },
  { value: "coloured", label: "Coloured" },
  { value: "indian", label: "Indian" },
  { value: "white", label: "White" },
  { value: "other", label: "Other" },
];

export const InvoiceStatus = [
  { value: "open", label: "Open" },
  { value: "paid", label: "Paid" },
];
