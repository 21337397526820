import React from 'react'
import { useParams } from 'react-router-dom'
import InProgressCourses from './InProgressCourses';
import SelfPlacedCourses from './SelfPlacedCourses';
import UpcomingCourses from './UpcomingCourses';
import AllCourses from './AllCourses';
import PastCourses from './PastCourses';

function ListOfCourses({QAview}) {
  const { tab } = useParams();

  return (
     tab === "In Progress" ? <InProgressCourses QAview={QAview}/>
      : tab === "selfplaced" ? <SelfPlacedCourses QAview={QAview}/>
        : tab === "upcoming" ? <UpcomingCourses QAview={QAview}/>
          : tab === "allCourses" ? <AllCourses QAview={QAview}/>
          : <PastCourses QAview={QAview}/>
  )
}

export default ListOfCourses