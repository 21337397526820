import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import StudentAuditTrails from './StudentAuditTrails';
import UserAuditTrails from './UserAuditTrails';

function AuditTrailTabs() {
  const history = useHistory();
  const { type } = useParams()
  return (
    <div>
      <div className="tabs-wrap">
        
        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">

            {type == "user" && <UserAuditTrails />}
            {type == "student" && <StudentAuditTrails />}

          </div>
        </div>
      </div>
    </div>
  )
}

export default AuditTrailTabs