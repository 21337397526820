import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import { insertIndividualNotification } from "../../../services/EmailServices";
import HtmlParser from "react-html-parser";
import Select from 'react-select';
import { GetStudentsList } from "../../../services/StudentsSettingServices";
import { FORM_DATE_TIME_FORMAT, FORM_TIME_FORMAT } from "../../../utils/Constants";
import { TrimText } from "../../common/TrimText";

function AddNotificationList() {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [attachmentName, setAttachmentName] = useState(false);
  const [attachmentFilePreview, setAttachmentFilePreview] = useState(false);
  const [notificationToData, SetNotificationToData] = useState([]);

  useEffect(() => {
    const data = {
      key: "email",
      search: "",
      viaStatus: ["Active"],
      exportStatus: true,
    }
    GetStudentsList(data).then((res) => {
      let data = res?.data?.data;
      data = data?.map((item) => {
        return { label: item?.full_name, value: item?.id }
      });
      SetNotificationToData(data);
    }).catch((err) => {
      console.error("err", err);
    })
  }, []);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };
  const InitialValues = {
    Name: "",
    IncludeAccountPayer: "",
    Important: "",
    EmailNotification: false,
    PushNotification: false,
    SendWhen: 0,
    SendWhenDate: "",
    IsDraft: "",
    Title: "",
    Content: "",
    FileAttachment: "",
    notificationTo: [],
  }
  const AddNotificationListFormSchema = Yup.object().shape({
    Title: Yup.string().trim("Name cannot include leading and trailing spaces").required("Title is required"),
    textBody: Yup.string().required("Content is required"),
    SendWhenDate: Yup.string()
      .when("SendWhen", {
        is: 2,
        then: Yup.string().required("Date And Time is required"),
      }),
    notificationTo: Yup.array().required("Please Select Atleast one Notefication to value").min(1, "Please Select Atleast one Notefication to value").nullable(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: InitialValues,
    validationSchema: AddNotificationListFormSchema,
    onSubmit: (values, { resetForm }) => {
      const date = new Date(values?.SendWhenDate);
      const timeStampDate = date.getTime() / 1000;
      if (!values.EmailNotification) {
        if (!values.PushNotification) {
          formik.setErrors({ EmailNotification: "Please Select Atleast one Send Via Value", });
          return;
        }
      }
      let formData = new FormData();
      formData.append("ussub", values?.Title);
      formData.append("students_id", JSON.stringify(values?.notificationTo?.map((item) => item?.value)));
      formData.append("isnew", 1);
      formData.append("isDraft", 0);
      formData.append("usmsg", values?.textBody);
      formData.append("attachment", values?.FileAttachment);
      formData.append("publish", values?.SendWhen ? 1 : 0);
      values.SendWhen === 2 ? formData.append("publish_date", timeStampDate) : formData.append("publish_date", "");
      formData.append("sms_content", "");
      formData.append("isEmail", values?.EmailNotification ? 1 : 0);
      formData.append("isSms", 0);
      formData.append("isPushnotification", values?.PushNotification ? 1 : 0);
      formData.append("isPayer", values?.IncludeAccountPayer ? 1 : 0);
      formData.append("h_importance", values?.Important ? 1 : 0);

      setDisabled(true);
      insertIndividualNotification(formData)
        .then((res) => {
          Swal.fire({icon: "success",title: "Success",text: "Added successfully"});
          history.push(`/notification/bulknotification/bulk/table`);
        })
        .catch(err => {
          console.error("error:", err);
          setDisabled(false);
        });
    },
  });
  // formik errors
  const errorObject = formik.errors && formik.touched;
  let err = [];
  for (const property in errorObject) {
    err.push(formik.errors[property]);
  }

  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Title">Title *</label>
            <div className="form-icon-group mb-4" title="Title">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.Title && formik.touched.Title
                    ? " is-invalid"
                    : "")
                }
                name="Title"
                id="Title"
                placeholder="Title"
                title="Title"
                value={formik.values.Title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label></label>
              <div className="custom-check custom-control custom-checkbox mt-3">
                <input
                  type="checkbox"
                  title="Include Account Payer"
                  className="custom-control-input"
                  id="IncludeAccountPayer"
                  name="select"
                  checked={formik.values.IncludeAccountPayer}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "IncludeAccountPayer",
                      e.target.checked
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="IncludeAccountPayer"
                >
                  Include Account Payer
                </label>
              </div>

            </div>

          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label></label>
              <div className="custom-check custom-control custom-checkbox mt-3">
                <input
                  type="checkbox"
                  title="Important"
                  className="custom-control-input"
                  id="Important"
                  name="select"
                  checked={formik.values.Important}
                  onChange={(e) =>
                    formik.setFieldValue("Important", e.target.checked)
                  }
                />
                <label className="custom-control-label" htmlFor="Important">
                  High Importance
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <label>Send Via *</label>
            <div className="form-group-blk mb-3">
              <label></label>
              <div className="custom-check custom-control custom-checkbox custom-control-inline mt-3">
                <input
                  type="checkbox"
                  title="Email"
                  className="custom-control-input"
                  id="EmailNotification"
                  name="select"
                  checked={formik.values.EmailNotification}
                  onChange={(e) =>
                    formik.setFieldValue("EmailNotification", e.target.checked)
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="EmailNotification"
                >
                  Email
                </label>
              </div>
              <div className="custom-check custom-control custom-checkbox custom-control-inline mt-3">
                <input
                  type="checkbox"
                  title="Push Notification"
                  className="custom-control-input"
                  id="PushNotification"
                  name="select"
                  checked={formik.values.PushNotification}
                  onChange={(e) =>
                    formik.setFieldValue("PushNotification", e.target.checked)
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="PushNotification"
                >
                  Push Notification
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label title="SendWhen">Send When *</label>
            <div className="form-icon-group" title="SendWhen">
              <div className="custon_rd_new">
                <div
                  class="custom-control custom-radio custom-control-inline"
                  title="Now"
                >
                  <input
                    type="radio"
                    id="customRadioInline1"
                    name="customRadioInline"
                    class="custom-control-input"
                    checked={formik.values.SendWhen == 0}
                    onChange={() => formik.setFieldValue("SendWhen", 0)}
                    onBlur={formik.handleBlur}
                  />
                  <label class="custom-control-label" for="customRadioInline1">
                    Now
                  </label>
                </div>
                <div
                  class="custom-control custom-radio custom-control-inline"
                  title="Later"
                >
                  <input
                    type="radio"
                    id="customRadioInline2"
                    name="customRadioInline"
                    class="custom-control-input"
                    checked={!formik.values.SendWhen == 0}
                    onChange={() => formik.setFieldValue("SendWhen", 2)}
                    onBlur={formik.handleBlur}
                  />
                  <label class="custom-control-label" for="customRadioInline2">
                    Later
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label>Upload Attachment</label>
              <div className="form-group atttach-file m-0">
                <label
                  className={
                    formik.errors.FileAttachment &&
                    formik.touched.FileAttachment &&
                    "file-req is-invalid"
                  }
                >
                  <i className="fal fa-paperclip"></i>
                  <span>Add Attachment</span>
                  <input
                    type="file"
                    accept={"image/*, .docx, .doc, .xlsx, .pdf, .zip"}
                    className="form-control  form-control-aatch-file"
                    onChange={(e) => {
                      if (e.target.files.length) {
                        formik.setFieldValue("FileAttachment", e.target.files[0]);
                        setAttachmentName(e.target.files[0].name);
                        setAttachmentFilePreview(URL.createObjectURL(e.target.files[0]));
                      }
                    }}
                  />
                </label>
              </div>
              {attachmentName && (
                <>
                  <div class="frm-group">
                    <ul class="list-unstyled attached-file-ul m-0">
                      <li>
                        <a
                          href={attachmentFilePreview}
                          title={attachmentName}
                          target="_blank">
                          <i class="fal fa-file"></i>
                          &nbsp;{attachmentName}
                        </a>
                        <button
                          class="btn btn-danger"
                          onClick={() => {
                            setAttachmentFilePreview(false);
                            setAttachmentName(false);
                          }}
                        ><i class="fal fa-trash-alt"></i></button>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-md-6 col-lg-4" title="Choose">
            <div className="form-group">
              <label>Notification To *</label>
              <div className="form-icon-group mb-4" title="Brand Name">
                <div className="form-control custom-select-box">
                  <Select
                    className={"form-control custom-select-box" + (formik.errors.notificationTo && formik.touched.notificationTo ? " is-invalid" : "")}
                    name="notificationTo"
                    onChange={(e) => formik.setFieldValue("notificationTo", e)}
                    onBlur={formik.handleBlur}
                    options={notificationToData}
                    maxMenuHeight={175}
                    isMulti />
                  {formik.errors.notificationTo && formik.touched.notificationTo ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {!formik.values.SendWhen == 0 ? (
            <div className="col-md-6 col-lg-4">
              <label>Date *</label>
              <div className="form-group-blk mb-3">
                <DatePicker
                  selected={formik.values.SendWhenDate}
                  onChange={(date) => {
                    formik.setFieldValue("SendWhenDate", date);
                  }}
                  filterTime={filterPassedTime}
                  className={
                    "form-control cursor-pointer" +
                    (formik.errors.SendWhenDate && formik.touched.SendWhenDate
                      ? " is-invalid"
                      : "")
                  }
                  title="Date"
                  onBlur={formik.handleBlur}
                  placeholderText="Enter Date"
                  showYearDropdown
                  showTimeSelect
                  scrollableYearDropdown
                  dateFormatCalendar="MMMM"
                  dateFormat={FORM_DATE_TIME_FORMAT}
                  timeFormat={FORM_TIME_FORMAT}
                  minDate={new Date()}
                />
              </div>
              {formik.errors.SendWhenDate && formik.touched.SendWhenDate ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          ) : ("")}
          <div className="col-md-12">
            <label>Content *</label>
            <div className="form-icon-group mb-4 d-block">
              <HtmlInputEditor
                name="textBody"
                isCKEditor={true}
                editorState={formik.values.textBody}
                hideSign={true}
                belowNoMargin={true}
                setEditorState={(val) => { formik.setFieldValue("textBody", val) }}
                isInvalid={formik.touched.textBody && formik.errors.textBody}
              />
            </div>
          </div>
        </div>
        {/* send & preview Buttons */}
        <div className="form-group form-group-save-cancel">
          <button
            className="btn btn-save btn-success"
            type="submit"
            title="Send"
            onClick={() => formik.setFieldValue("IsDraft", 0)}
          >
            {disabled ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fas fa-location-arrow"></i>
            )}
            Send
          </button>
          <button
            className="btn btn-save btn-success"
            type="button"
            title="Preview"
            data-toggle="modal" data-target="#notifications"
          >
            {disabled ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fal fa-eye"></i>
            )}
            Preview
          </button>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => history.goBack()}
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>
          {/* display form errors */}
          {err?.map(item => item && <div className="invalid-feedback d-block">{item}</div>)}
        </div>
      </form>
      {/* show preview modal */}
      <div className="topic-add-modal modal fade" id="notifications" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content modal-border-update">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fas fa-bars"></i> Notification Details
                </h5>
                <button type="button" data-dismiss="modal" class="close" title="Close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
              </div>
              <div className="p-30px topic-add-modal-content">
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="basic-info-box-list">
                      <ul className="p-0 course-name-merge">
                        <li>
                          <span className="title">Subject/Title:&nbsp;</span>
                          <span className="word-break-all">{formik.values.Title}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="basic-info-box-list">
                      <ul className="p-0 course-name-merge">
                        <li>
                          <span className="title">Upload Attachment:&nbsp;</span>
                          {attachmentName ? 
                          <a title={attachmentName} href={attachmentFilePreview} target="_blank"><i class="fal fa-file"></i>&nbsp;{TrimText(attachmentName,15)}</a>
                          : "N/A"
                          }
                          
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="basic-info-box-list">
                      <ul className="p-0 course-name-merge">
                        <li>
                          <span className="title">Content:&nbsp;</span>
                          <span className="word-break-all">{HtmlParser(formik?.values?.textBody)}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="form-group form-group-save-cancel mt-3">
                  <button type="button" className="btn btn-danger" data-dismiss="modal" title="Close">
                    <i className="fal fa-times"></i>
                    Close
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer"></div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNotificationList