import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import PermissionsGate from "../../../utils/permissionGate";
import hasPermission from "../../../utils/hasMultiplePermission";
import { LayoutHeaderStatus, RenderCourseAssignedlistStatus, RenderWebsiteBrandTemplate } from "../../../utils/CommonStatusItems";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";
import { RenderPopupType } from "../../../utils/CommonGroupingItem";
import DataTableComponentFrontPagination from "../../common/DataTableComponentFrontPagination";
import { getHeaderProgrameAndShortCourseFilter, getQualificationList } from "../../../services/LayoutHeaderServices";


function AssignQualificationPopup({ formik, assignedPage, setAssignedPage, id, assignedQualificationRef }) {
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [brand, setBrand] = useState({ arr: [], checkObj: {} });
  const [type, setType] = useState({ arr: [], checkObj: {} });
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [searchAssigned, setSearchAssigned] = useState("");
  const [headerData, setHeaderData] = useState([]);
  const [loading, setloading] = useState(true);
  const [filterData, setFilterData] = useState({
    status: [],
    brand: [],
    type: [],
  });

  const assign = assignedQualificationRef.current;

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });

  useEffect(() => {
    const getPageListType = async () => {
      setloading(true);

      try {
        const res = await getQualificationList(id);
        setHeaderData(res?.data?.data);
        if (res.status == 200) {
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };

    getPageListType();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getHeaderProgrameAndShortCourseFilter();

        setFilterData({
          ...res.data,
          status: res.data.status ? res.data.status : [],
          brand: res.data.programmeType ? res.data.programmeType : [],
          type: res.data.type ? res.data.type : [],
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, []);


  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "title",
      sortField: "title",
      sortable: true,
      cell: (row) => (
        <Link
          title={row.title}
          className="as-text-blue curser feature-name"
          to={`${row.flag == 5 ? `/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${row.Id}` : row.flag == 6 ? `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.Id}` : null}`}
          onClick={() => {
            $("#assignQualificationPopup").modal("hide");
          }}
        >
          <span className="textLimit100">
            {row.title ? row.title : ""}{" "}
          </span>
        </Link>
      ),
    },
    {
      name: "Code",
      selector: "Code",
      sortField: "Code",
      sortable: true,
      cell: (row) => (row.Code ? row.Code : "-"),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) =>
        row.status?.toString()
          ? RenderCourseAssignedlistStatus(row.status)?.html
          : "-",
    },
    {
      name: "Programme Type",
      selector: "qualification_type",
      sortable: true,
      cell: (row) => row?.qualification_type ? (
        <div title={row?.qualification_type}>
          <span className={`cat ${row?.qualification_color}`}>
            <i className="fas fa-circle mr-1"></i>
            {row?.qualification_type}
          </span>
        </div>) : "-"
    },
    {
      name: "Type",
      selector: "type",
      sortField: "type",
      sortable: true,
      cell: (row) => row?.type ? RenderPopupType(row?.type, "popup")?.html : "-"
    },
    {
      name: "Action",
      selector: "",
      maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            {assign?.Id != row.Id ? (
              <button
                type="button"
                className="btn btn-primary rounded-circle"
                onClick={() => {
                  setAssignedPage({ ...row });
                  $("#assignQualificationPopup").modal("hide");
                }}
                title="Assign Page"
              >
                <i className="fal fa-plus"></i>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-danger rounded-circle"
                onClick={() => {
                  setAssignedPage({});
                }}
                title="Assign Page"
              >
                <i className="fal fa-minus"></i>
              </button>
            )}
          </div>
        </div>
      ),
    },
  ]);

  const dataToRenderAvailable = () => {
    let updatedData = headerData;

    // Apply search filter
    if (searchAssigned.length) {
      updatedData = updatedData.filter((item) =>
        item?.PageTitle?.toLowerCase().includes(searchAssigned.toLowerCase())
      );
    }

    // Apply status filter
    if (status.arr.length) {
      updatedData = updatedData.filter((item) =>
        status.arr.includes(item.status)
      );
    }

    // Apply brand filter
    if (brand.arr.length) {
      updatedData = updatedData.filter((item) =>
        item.brandTemplete.some((template) =>
          brand.arr.includes(template.name)
        )
      );
    }

    // Apply type filter
    if (type.arr.length) {
      updatedData = updatedData.filter((item) =>
        type.arr.includes(item.type)
      );
    }

    return updatedData;
  };

  const exportAvailableData = () => {
    let data = dataToRenderAvailable();

    const header = [
      "Name",
      "Code",
      "Status",
      "Programme Type",
      "Type",
    ];
    data = data.map((row) => {

      return ({
        Name: row.title ? row.title : "-",
        Code: row.Code,
        Status: row.status,
        "Programme Type": row.qualification_type,
        Type: row?.type,
      })
    });

    const pdfData = data.map((row) => {
      return [
        row["Name"],
        row["Code"],
        row["Status"],
        row["Programme Type"],
        row["Type"],
      ];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };

  return (
    <>
      <DataTableComponentFrontPagination
        data={dataToRenderAvailable()}
        columns={columns}
        loading={loading}
        exportData={exportAvailableData}
        exportFileName={"Assigned_qualifications"}
        defaultSort={{ defaultSortColumn: "name", defaultSortAsc: false }}
        custumcss="border-0"
        search={searchAssigned}
        progressComponent={<SkeletonTicketList />}
        setSearch={setSearchAssigned}
        filters={[
          {
            filterName: "Status",
            optionArr: filterData?.status,
            state: status,
            setState: setStatus,
            uniqueId: "Headerstatus",
            isOptionReversed: true,
            renderLabelFunction: RenderCourseAssignedlistStatus
          },
          {
            filterName: "Type",
            optionArr: filterData?.type,
            state: type,
            setState: setType,
            uniqueId: "availableStudyType",
            renderLabelFunction: RenderPopupType,
          },
          {
            filterName: "Programme Type",
            optionArr: filterData?.brand,
            state: brand,
            setState: setBrand,
            uniqueId: "HeaderProgrammeType",
            isOptionReversed: true,
          },
        ]}
      // bottomButton={
      //   [
      //     <div className="form-group form-group-save-cancel">
      //       <button
      //         className="btn btn-save btn-success"
      //         type="button"
      //         title="Save"
      //         data-dismiss="modal"
      //         aria-label="Close"
      //         // disabled={loading ? true : false}
      //       >
      //         {/* {loading === false ? (
      //           <i className="fal fa-save"></i>
      //         ) : (
      //           <i className="fas fa-cog fa-spin"></i>
      //         )} */}
      //         <i className="fal fa-save"></i>
      //         Save
      //       </button>
      //       <button
      //         className="btn btn-close btn-danger"
      //         type="button"
      //         title="Cancel"
      //         data-dismiss="modal"
      //         aria-label="Close"
      //       >
      //         <i className="fal fa-times"></i>Cancel
      //       </button>
      //     </div>
      //   ]
      // }
      />
    </>
  );
}
export default AssignQualificationPopup;
