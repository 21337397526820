import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import hasPermission from '../../../utils/hasMultiplePermission';
import ProgrammeForm from './ProgrammeForm'

function Details({ programmeFunction, timeTableDoc, timeTableDocName, setTimeTableDoc, setTimeTableDocName, programmeData, refresh, setRefresh }) {
  const history = useHistory()
  const givenPermsisions = useSelector((state) => state.givenPermission);
  useEffect(() => {
    let response = hasPermission({ scopes: ["capview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])
  
  return (
    <div>
        <ProgrammeForm programmeFunction={programmeFunction} timeTableDoc={timeTableDoc} timeTableDocName={timeTableDocName} setTimeTableDoc={setTimeTableDoc} setTimeTableDocName={setTimeTableDocName} programmeData={programmeData} refresh={refresh} setRefresh={setRefresh} />
    </div>
  )
}

export default Details