import React, { useState, useEffect, useMemo } from 'react';
import Header from '../../common/Header';
import SideBar from '../../common/SideBar';
import { CustomButton } from '../../common/Buttons';
import PermissionsGate from '../../../utils/permissionGate';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DataTableComponent from '../../common/DataTableComponent';
import {
  GetCertificateTemplates,
  CreateCertificateTemplate,
} from '../../../services/CertificateServices';
import { GlobalIconNames } from '../../../utils/GlobalIconNames';
import { GetFilters } from '../../../services/CertificateServices';
import Swal from 'sweetalert2';
import { convertBase64toText } from '../../common/ImageConversions';

const DuplicateTemplate = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [topics, setTopics] = useState({ arr: [], checkObj: {} });
  const [brands, setBrands] = useState({ arr: [], checkObj: {} });
  const [size, setSize] = useState({ arr: [], checkObj: {} });
  const [filterData, setFilterData] = useState({ brands: [], topics: [] });
  const [courseName, setCourseName] = useState('');
  const [isCourseNameChanged, setIsCourseNameChanged] = useState(false);
  const [formData, setFormData] = useState({
    Name: '',
    Brand: '',
    Size: '',
    KeyWords: '',
    Topics: '',
    ShowStudentName: false,
    ShowCourseName: false,
    ShowDate: false,
    BackgroundImage: '',
  });

  let suffix = ' - Copy';

  const paperSizes = [
    {
      value: 'A4 Landscape',
      label: 'A4 Landscape',
    },
    {
      value: 'A4(Portrait)',
      label: 'A4(Portrait)',
    },
    {
      value: 'Custom',
      label: 'Custom',
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true); // Setting loading to true while fetching data
        const response = await GetCertificateTemplates();

        let filteredData = response.data.filter((item) => item.isDeleted === 0);

        if (brands.arr.length > 0) {
          filteredData = filteredData.filter((item) =>
            brands.arr.includes(item.Brand)
          );
        }

        if (topics.arr.length > 0) {
          filteredData = filteredData.filter((item) => {
            const itemTopics = item.Topics ? item.Topics.split(',') : [];
            return topics.arr.every((topic) => itemTopics.includes(topic));
          });
        }

        if (size.arr.length > 0) {
          filteredData = filteredData.filter((item) =>
            size.arr.includes(item.Size)
          );
        }

        if (search.trim() !== '') {
          const searchTerm = search.toLowerCase();
          filteredData = filteredData.filter(
            (item) =>
              (item.Name && item.Name.toLowerCase().includes(searchTerm)) ||
              (item.Keywords &&
                item.Keywords.toLowerCase().includes(searchTerm))
          );
        }

        setData(filteredData); // Store the filtered data in the state
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }

    // Triggering fetchData immediately when search term changes
    fetchData();
  }, [search, topics, brands, size]);

  useEffect(() => {
    GetFilters()
      .then((res) => {
        setFilterData({
          ...res.data,
          brands: res.data.brands,
          topics: res.data.topics,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  function convertFilterData(filterData) {
    const convertedData = {};

    for (const key in filterData) {
      if (filterData.hasOwnProperty(key)) {
        convertedData[key] = filterData[key].map((value) => {
          return { value: value, label: value };
        });
      }
    }

    return convertedData;
  }

  const history = useHistory;

  const columns = useMemo(() => [
    {
      name: 'Name',
      selector: 'Name',
      sortable: true,
      sortField: 'name',
      minWidth: '190px',
      cell: (row) => (
        <PermissionsGate scopes={['capview']} errorProps={{ disabled: true }}>
          <Link
            title={row.Name}
            className="as-text-blue curser feature-name"
            to={`/courseAdministration/certificates/details/${row.id}`}
          >
            <span className="textLimit100">{row.Name ? row.Name : ''} </span>
          </Link>
        </PermissionsGate>
      ),
    },
    {
      name: 'Brand',
      selector: 'Brand',
      sortable: true,
      sortField: 'Brand',
      cell: (row) => <p>{row.Brand ? row.Brand : '-'}</p>,
    },
    {
      name: 'Topics',
      selector: 'Topics',
      sortable: true,
      sortField: 'Topics',
      cell: (row) => {
        if (row.Topics) {
          // Parsing the "Topics" field as a JSON array
          const topicsArray = row.Topics.split(',');

          const topicsList = topicsArray.map((topic, index) => (
            <span
              key={index}
              style={{
                backgroundColor: '#E2E2E2',
                color: '#252423',
                margin: '4px',
                padding: '3px',
              }}
            >
              {topic}
            </span>
          ));
          if (topicsArray.length > 4) {
            topicsList.push(
              <span
                key="more"
                style={{
                  backgroundColor: '#E2E2E2',
                  color: '#252423',
                  margin: '4px',
                  padding: '3px',
                  borderRadius: '4px',
                }}
              >
                ...
              </span>
            );
          }

          return <div>{topicsList}</div>;
        } else {
          return <p>-</p>; // Handle the case where "Topics" is not provided
        }
      },
    },
    {
      name: 'Keywords',
      selector: 'Keywords',
      sortable: true,
      sortField: 'Keywords',
      cell: (row) => {
        if (row.Keywords) {
          // Parsing the "Topics" field as a JSON array
          const keywordsArray = row.Keywords.split(',');
          const keywordsList = keywordsArray.map((keyword, index) => (
            <span
              key={index}
              style={{
                backgroundColor: '#E2E2E2',
                color: '#252423',
                margin: '4px',
                padding: '3px',
              }}
            >
              {keyword}
            </span>
          ));

          return <div>{keywordsList}</div>;
        } else {
          return <p>-</p>; // Handle the case where "Topics" is not provided
        }
      },
    },
    {
      name: 'Size',
      selector: 'Size',
      sortable: true,
      sortField: 'Size',
      cell: (row) => (row.Size ? row.Size : '-'),
    },
    {
      name: 'Action',
      selector: '',
      maxWidth: '50px',
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <Link
              className="btn btn-primary rounded-circle"
              title="Add"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => handleClick(row)}
            >
              <i className="fal fa-plus"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ]);

  const handleCourseChange = (e) => {
    setCourseName(e.target.value + '');
  };

  const fetchImageAndConvertToBlob = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blobImage = await response.blob();
      const file = new File([blobImage], 'image.png', { type: 'image/png' });
      return file;
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  const handleClick = async (rowData) => {
    const {
      id,
      Name,
      Brand,
      Size,
      Keywords,
      Topics,
      ShowStudentName,
      ShowCourseName,
      ShowDate,
      BackgroundImage,
    } = rowData;

    // Since we are getting data in array Buffer format, we need to convert it to base64 string
    // 1. Convert array buffer to base64text using the function convertBase64toText() defined in Base64Image.js
    // 2. Convert base64Text to normal text to get the image path.
    // 3. We retreive the backgroundImageBlob by passing imagePath the  using the function fetchImageAndConvertToBlob()
    // 4. this blob will be pass to background image in formData

    const base64String = btoa(
      String.fromCharCode.apply(null, new Uint8Array(BackgroundImage.data))
    );

    const imagePath = convertBase64toText(base64String);
    const imageUrl = '/' + imagePath.toString();

    const backgroundImageBlob = await fetchImageAndConvertToBlob(imageUrl);

    setFormData({
      id,
      Name,
      Brand,
      Size,
      Keywords,
      Topics,
      ShowStudentName,
      ShowCourseName,
      ShowDate,
      BackgroundImage: backgroundImageBlob,
    });
  };

  useEffect(() => {
    if (courseName !== formData.Name) {
      setIsCourseNameChanged(true);
    } else {
      setIsCourseNameChanged(false);
    }
  }, [courseName]);

  const handleSelect = (templateData) => {
    console.log('Certificate Template saved:', templateData);
  };

  const handleSave = async (e) => {
    try {
      const {
        Name,
        Brand,
        Size,
        Topics,
        Keywords,
        ShowStudentName,
        ShowCourseName,
        ShowDate,
        BackgroundImage,
      } = formData;

      const formData_ = new FormData();
      formData_.append(
        'Name',
        isCourseNameChanged ? courseName : Name + suffix
      );
      formData_.append('Brand', Brand);
      formData_.append('Size', Size);
      formData_.append('Keywords', Keywords);
      formData_.append('Topics', Topics);
      formData_.append('ShowStudentName', ShowStudentName ? 1 : 0);
      formData_.append('ShowCourseName', ShowCourseName ? 1 : 0);
      formData_.append('ShowDate', ShowDate ? 1 : 0);
      formData_.append('BackgroundImage', BackgroundImage);

      const response = await CreateCertificateTemplate(formData_);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
        allowOutsideClick: true,
      });
    } catch (error) {
      console.error('Error creating certificate template:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.message,
        allowOutsideClick: true,
      });
    }
  };

  const showCopySuffix = isCourseNameChanged
    ? courseName
    : formData.Name + suffix;

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="my-tickets-heading-blk flex-direction mb-2">
            <div className="d-flex align-items-center ">
              <div className="name-w-head d-flex align-items-center ">
                <h4 className="text-left-align landing-heading heading_color_dynamic">
                  Certificate Templates : Duplicate New
                </h4>
              </div>
              <div className="text-md-right action2-blk">
                <div className="ticket-view-btn d-flex align-items-center">
                  <div
                    className="add-new-ticket-bx"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <button
                      onClick={() => history.goBack()}
                      title="Back"
                      className="circular-button"
                    >
                      {/* <i className="fal fa-angle-left"></i>Back */}
                      {GlobalIconNames('back').html}
                    </button>
                    <button title="History" className="circular-button">
                      {GlobalIconNames('history').html}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="image-template-instruction-container">
            <div className="course-certificate-input-main-container">
              <div>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  encType="multipart/form-data"
                >
                  <div className="course-certificate-input-container">
                    <label htmlFor="certificateName">
                      Certificate Duplicate Information
                    </label>
                    <input
                      type="text"
                      id="duplicateCertificate"
                      className="cert-field"
                      value={showCopySuffix}
                      onChange={handleCourseChange}
                    />
                  </div>
                  <div className="course-cert-button-container">
                    <CustomButton
                      icon={'fa-save'}
                      fontColor={'white'}
                      backgroundColor={'#EF8700'}
                      text={'Save'}
                      onClick={handleSave}
                    />
                    <CustomButton
                      icon={'fa-times'}
                      fontColor={'white'}
                      backgroundColor={'#EF0000'}
                      text={'Cancel'}
                      onClick={handleSelect}
                    />
                  </div>
                </form>
              </div>
              <div className="course-certificate-input-btn">
                <button
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#availableTemplate"
                  title="Select Template"
                  type="button"
                >
                  <i className="fal fa-file"></i>Select Template
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="topic-add-modal modal able_modal01 fade"
        id="availableTemplate"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-plus"></i> Select Template
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <DataTableComponent
                columns={columns}
                loading={loading}
                setSearch={setSearch}
                data={data}
                filters={[
                  {
                    filterName: 'Brand',
                    optionArr: convertFilterData(filterData).brands,
                    state: brands,
                    setState: setBrands,
                  },
                  {
                    filterName: 'Topics',
                    optionArr: convertFilterData(filterData).topics,
                    state: topics,
                    setState: setTopics,
                  },
                  {
                    filterName: 'Size',
                    optionArr: paperSizes,
                    state: size,
                    setState: setSize,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DuplicateTemplate;
