import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { TrimText } from "../components/common/TrimText";
import AuditTrail from "../components/courses/Programs/AuditTrail";
import DetailsOfPrograms from "../components/courses/Programs/DetailsOfPrograms";
import ProgrammeGradeBookTable from "../components/courses/Programs/ProgrammeGradeBookTable";
import { GetProgramsDetailsCancelToken } from "../services/ProgrammeServices";
import { GlobalIconNames } from "../utils/GlobalIconNames";
import { GetStudentDetails, GetStudentDetailsCancelToken } from "../services/StudentsSettingServices";
import axios from "axios";
import Swal from "sweetalert2";
import ProgrammeGrademixGradeBookTable from "../components/courses/Programs/ProgrammeGrademixGradeBookTable";
const Programs = () => {
  const history = useHistory();
  const location = useLocation();
  let currentTabValue = location?.pathname?.split("/");
  const { tab, type, id, subTab } = useParams();
  const [programmeData, setProgrammeData] = useState({ course: "" });
  const [studentName, setStudentName] = useState("")
  const [studentData, setStudentData] = useState({})
  const [timeTableDoc, setTimeTableDoc] = useState();
  const [timeTableDocName, setTimeTableDocName] = useState("");
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    const cancelTokenSources = [];
    
    const getStudenDetails = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await GetStudentDetailsCancelToken(subTab, source.token);
        if (res.status === 200) {
          setStudentData(res.data?.data_list)
          setStudentName(res.data?.data_list?.first_name + " " + (res.data?.data_list?.last_name ? res.data?.data_list?.last_name : ""));
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    if(subTab) {
      getStudenDetails();
    }

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [subTab, type, id]);

  useEffect(() => {
    const cancelTokenSources = [];
    if (type == "open" || type == "duplicate") {

      const getProgrammeDetails = async () => {

        cancelTokenSources.forEach(source => {
          source.cancel('New request made');
        });

        const source = axios.CancelToken.source();
        cancelTokenSources.push(source);
        try {
          const res = await GetProgramsDetailsCancelToken(id, source.token);
          if (res.status === 200) {
            if (res.data) {
              setProgrammeData(res.data.programs);
              setTimeTableDoc(res.data.programs.timeTable);
              setTimeTableDocName(res.data.programs.timeTable)
            }
          } else {
            Swal.fire({
              icon: "Info",
              title: "Info",
              text: "No record found with this id",
            })
            history.push("/courseAdministration/Programmes/programme/show")
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
        }
      };

      getProgrammeDetails();
    } else {
      setProgrammeData({ course: "" });
      setTimeTableDoc();
      setTimeTableDocName("")
    }
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [subTab, type, id, refresh]);

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className={"sidebar-right " + (currentTabValue[3] === "programme" && (currentTabValue[6] === "grade" || currentTabValue[6] === "gradebook") && currentTabValue[7] === "table" ? "bottom-100" : "")}>
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        {currentTabValue.includes("gradebook") ? "Gradebook: " :
                        type == "show" || tab == "auditTrail"
                          ? "Programmes "
                          : type == "add"
                          ? "Add "
                          : "Programme: "}
                        {!currentTabValue.includes("gradebook") && <span
                          title={
                            type == "open"
                              ? programmeData && programmeData.course
                              : ""
                          }
                        >
                          {type == "show" || tab == "auditTrail"
                            ? ""
                            : type == "add"
                            ? "Programme"
                            : programmeData && TrimText(programmeData.course)}
                        </span>}
                        {currentTabValue.includes("gradebook") && <span title={programmeData && programmeData.course}>{`${programmeData && programmeData.course} - ${studentName}`}</span>}
                      </h4>
                      {/* <h4 className="text-left-align landing-heading heading_color_dynamic">Programmes{(type == "open" || type == "duplicate") && <>: <span>{programmeData && programmeData.course}</span></>} </h4> */}
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button
                            onClick={() => history.goBack()}
                            title="Back"
                            className="btn btn-white-bordered"
                          >
                            <i className="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  {type == "show" && (
                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(
                            type == "open"
                              ? `/courseAdministration/Programmes/programme/open/${id}/details`
                              : `/courseAdministration/Programmes/programme/show`
                          )
                        }
                      >
                        <a
                          className={`nav-link cursor-pointer ${
                            tab === "programme" ? "active" : ""
                          }`}
                          id="pills-tab1"
                          data-toggle="pill"
                          role="tab"
                          aria-controls="pills-tab11"
                          aria-selected="true"
                        >
                          {GlobalIconNames("qualification").html}
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(
                            type == "open"
                              ? `/courseAdministration/Programmes/singleAuditTrail/open/${id}`
                              : `/courseAdministration/Programmes/auditTrail/show`
                          )
                        }
                      >
                        <a
                          className={`nav-link cursor-pointer ${
                            tab === "auditTrail" || tab == "singleAuditTrail"
                              ? "active"
                              : ""
                          }`}
                          id="pills-tab2"
                          data-toggle="pill"
                          role="tab"
                          aria-controls="pills-tab12"
                          aria-selected="true"
                        >
                          {GlobalIconNames("audittrail").html}
                        </a>
                      </li>
                    </ul>
                  )}
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab11"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      {((tab === "programme" || tab == "singleAuditTrail") &&
                        !currentTabValue.includes("gradebook")) && (
                          <DetailsOfPrograms
                            programmeDetails={programmeData}
                            programmeName={
                              programmeData && programmeData.course
                            }
                            setProgrammeData={setProgrammeData}
                            timeTableDoc={timeTableDoc}
                            timeTableDocName={timeTableDocName}
                            setTimeTableDoc={setTimeTableDoc}
                            setTimeTableDocName={setTimeTableDocName}
                            studentDetails={studentData}
                            refresh={refresh}
                            setRefresh={setRefresh}
                          />
                        )}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab12"
                      role="tabpanel"
                      aria-labelledby="pills-tab2"
                    >
                      {tab === "auditTrail" && <AuditTrail />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab21"
                      role="tabpanel"
                      aria-labelledby="pills-tab21"
                    >
                      {(currentTabValue.includes("gradebook") && programmeData.grading_standard && programmeData.grading_standard == 2) ? (
                        <ProgrammeGrademixGradeBookTable studentDetails={studentData} programmeDetails={programmeData}/>
                      ) : (currentTabValue.includes("gradebook") && programmeData.grading_standard && programmeData.grading_standard != 2) ? (
                        <ProgrammeGradeBookTable studentDetails={studentData} programmeDetails={programmeData}/>
                      ) : <></>}
                    </div>
                    {/* <div className="tab-pane fade active show" id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
                      {tab === "singleAuditTrail" && <AuditTrail />}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Programs;
