import $ from "jquery";
import "jspdf-autotable";
import JsPDF from "jspdf";
import moment from "moment";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useMemo, useState } from "react";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import CoursesTable from './CoursesTable';
import { Link } from 'react-router-dom';
import { checkRole, ColorCode } from '../../../systemadministration/usermanagement/CheckRole';
import listIcon from "../../../../assets/images/list-tree.svg"
import { TrimText } from '../../../common/TrimText';
import AvailableQualificationAndCourses from './AvailableQualificationAndCourses';
import { RenderLearningMethod } from '../../../../utils/CommonGroupingItem';
import { TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import { GetCourselist, GetCourselistCancelToken } from "../../../../services/DashBoardServices";
import AllCourseTable from "./AllCourseTable";
import axios from "axios";
import { handleTableScroll } from "../../../../utils/commonFunction";

function AssignedProgrmasAndCourses(props) {
  const [dropdownValues, setDropdownValues] = useState(
    useSelector((state) => state.registerDropdownValues)
  );
  const [salesPerson, setSalesPerson] = useState([]);
  const [assignQualification, setAssignQualification] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [intake, setIntake] = useState([]);
  const [intakeYear, setIntakeYear] = useState([]);
  const [searchIntakeYearCheck, setSearchIntakeYearCheck] = useState({});
  const [deliveryMethodArr, setDeliveryMethodArr] = useState([]);
  const [deliveryMethod, setDeliveryMethod] = useState([])
  const [deliveryMethodCheck, setDeliveryMethodCheck] = useState({});
  const [courseType, setCourseType] = useState([]);
  const [courseTypeCheck, setCourseTypeCheck] = useState({});
  const [courseDropdown, setCourseDropdown] = useState({ programme: [], intake: [], learning: [] })
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
  const [reply, setReply] = useState({ arr: [], checkObj: {} });
  const [programme, setProgramme] = useState({ arr: [], checkObj: [] });
  const [status, setStatus] = useState({ arr: ["In Progress"], checkObj: { "statusIn Progress": true } });
  const [courseIntake, setCourseIntake] = useState({ arr: [], checkObj: {} });
  const [allCourseError, setAllCourseError] = useState(false)
  const [tempCheckedAllData, setTempCheckedAllData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [allCourseLoading, setAllCourseLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10)
  const [sortKey, setSortKey] = useState("start");
  const [sortOrder, setSortOrder] = useState("DESC")
  const [courseSearch, setCourseSearch] = useState("");
  const [allCourses, setAllCourses] = useState([]);
  const [cancelTokenSources, setCancelTokenSources] = useState([]);

  useEffect(() => {
    handleTableScroll()
  }, [props])

  function getValue(arrName, label) {
    let labelName = "";
    if (arrName === "sales_person") {
      for (let item of salesPerson) {
        if (item.value.toString() == label.toString()) {
          labelName = item.label;
          break;
        }
      }
      return labelName;
    } else {
      for (let item of dropdownValues[arrName]) {
        if (item.value.toString() == label.toString()) {
          labelName = item.label;
          break;
        }
      }

      if (labelName === "" && arrName === "re_registration_status")
        return label;
      return labelName;
    }
  }

  const getAllCourses = async () => {
    setAllCourseError(false)
    setTempCheckedAllData([])
    setSelectedRows([])
    setAllCourseLoading(true)

    cancelTokenSources.forEach(source => {
      source.cancel('New request made');
    });
    
    const source = axios.CancelToken.source();
    setCancelTokenSources([...cancelTokenSources, source]);

    const data = {
      pageNo: pageNo,
      size: perPage,
      intake: courseIntake.arr,
      viaLearnType: learningMethod.arr,
      key: sortKey,
      sort: sortOrder,
      viaStatus: status.arr,
      viaSearch: courseSearch,
      viaProgramme: programme.arr,
      viaMessage: reply.arr,
      // exportStatus: "true"
    };

    try {
      const res = await GetCourselistCancelToken(data, source.token);
      setAllCourses(res.data.result)
      setTotalRows(res.data.Pagination.total)
      if(res.status == 200){
        setAllCourseLoading(false)
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
        setAllCourseLoading(false);
      }
    }
  }

  useEffect(() => {
    getAllCourses()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [pageNo, perPage, sortKey, sortOrder, courseSearch, status, learningMethod, programme, courseIntake, reply])


  useEffect(() => {
    setAssignQualification(props.data);
    setDeliveryMethodArr(props.dropdownValues.study_type)
    setIntakeYear(props.dropdownValues.intake_year)
  }, [props])

  // function moveArrayItemToNewIndex(arr, old_index, new_index) {
  //   if (new_index >= arr.length) {
  //     var k = new_index - arr.length + 1;
  //     while (k--) {
  //       arr.push(undefined);
  //     }
  //   }
  //   arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  //   return arr;
  // };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "course",
      sortable: true,
      cell: (row) => (
        <span className="as-text-blue curser feature-name">
          {row.course ?
            <span className="textLimit100" onClick={() => { showCourses(row) }} title={row.course}>
              {row.course}
            </span> : "-"}
        </span>
      ),
    },
    {
      name: "Start",
      selector: "start",
      sortable: true,
      cell: (row) => (
        <>
          {row.start ? (
            <div className="dateTime">
              {/* {new Date(row?.start)} */}
              {/* <p className="right-space">{new Date(row.start)}</p>
                        <p>{moment(row.start).format(TABLE_TIME_FORMAT)}</p> */}
              <p className="right-space">
                {moment.unix(row.start).format(TABLE_DATE_FORMAT)}
              </p>
              {/* <p>{moment.unix(row.start).format(TABLE_TIME_FORMAT)}</p> */}
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "End",
      selector: "end",
      sortable: true,
      cell: (row) => (
        <>
          {row.end ? (
            <div className="dateTime">
              <p className="right-space">
                {moment.unix(row.end).format(TABLE_DATE_FORMAT)}
              </p>
              {/* <p>{moment.unix(row.end).format(TABLE_TIME_FORMAT)}</p> */}
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => <>{row.year ? row.year + "-" + row.number : "-"}</>,
    },
    {
      name: "Type",
      selector: "programs_course_type",
      sortable: true,
      cell: (row) => (
        <>
          {row.programs_course_type
            ? ColorCode("rereg_" + row.programs_course_type)
            : "-"}
        </>
      ),
    },
    {
      name: "Learning Method",
      selector: "type",
      sortable: true,
      cell: (row) => (
        <>{row.type ? RenderLearningMethod(row.type).html : "-"}</>
      ),
    },
    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row, index) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            {row &&
              row.programs_course_type &&
              row.programs_course_type === "programs" ? (
              <>
                <button
                  title="View"
                  className="btn btn-primary rounded-circle"
                  onClick={() => {
                    showCourses(row);
                  }}
                >
                  <img src={listIcon} height="15px" width="15px" />
                </button>

                {assignQualification.length && (
                  <div class="dropdown btn-dropdown-item ">
                    <button
                      class="btn btn-primary rounded-circle dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      title="More"
                    >
                      <i class="fal fa-ellipsis-h-alt"></i>
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link
                        className="btn btn-primary rounded-circle"
                        to={{
                          pathname: `/courseAdministration/Programmes/programme/open/${row.id}/details`,
                        }}
                        title="Open"
                      >
                        <i className="fal fa-folder-open"></i>
                      </Link>

                      <button
                        title="Unlink"
                        className="btn btn-danger rounded-circle"
                        onClick={() => {
                          props.onDelete(row);
                        }}
                      >
                        <i className="fal fa-unlink"></i>
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="assessment-08">
                <button
                  title="Unlink"
                  className="btn btn-danger rounded-circle"
                  onClick={() => {
                    props.onDelete(row);
                  }}
                >
                  <i className="fal fa-unlink"></i>
                </button>
              </div>
            )}
          </div>
        </div>
      ),
    },
  ]);

  // function moveUp(index) {
  //   let arr = [...assignQualification];
  //   arr = moveArrayItemToNewIndex(arr, index, index - 1)
  //   setAssignQualification(arr);
  //   props.setData(arr);
  // }

  // function moveDown(index) {
  //   let arr = [...assignQualification];
  //   arr = moveArrayItemToNewIndex(arr, index, index + 1)
  //   setAssignQualification(arr);
  //   props.setData(arr);
  // }

  function dataToRender() {
    let updatedData = [];
    let allData = assignQualification;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes =
      //     item.id &&
      //     item.id.toString().toLowerCase().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempName = allData.filter((item) => {
        let includes =
          item.course &&
          item.course.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        // ...tempId,
        ...tempName
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (intake.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = intake.find(function (user, index) {
          if (
            user.toString().toLowerCase() ==
            (item.year ? item.year.toString().toLowerCase() : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (deliveryMethod.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = deliveryMethod.find(function (user, index) {
          if (user.toString().toLowerCase() == (item.type ? item.type.toString().toLowerCase() : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (courseType.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = courseType.find(function (user, index) {
          if (
            user.toString().toLowerCase() ==
            (item.programs_course_type
              ? item.programs_course_type.toString().toLowerCase()
              : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (intake.length || search.length || deliveryMethod.length || courseType.length) {
      return updatedData;
    } else {
      return assignQualification;
    }
    // setAssignQualification(updatedData);
  }

  const handleFilters = (e, type = "") => {
    const value = e.target.value;
    if (type === "search") {
      setSearch(value);
    }
  };

  const handleIntakeFilter = (e) => {
    const value = e.target.value;
    let arr = intake;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setIntake(arr);
    let id = e.target.id;
    setSearchIntakeYearCheck({
      ...searchIntakeYearCheck,
      [id]: !searchIntakeYearCheck[id],
    });
  };

  const handleDeliveryMethodFilter = (e) => {
    const value = e.target.value;
    let arr = deliveryMethod;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setDeliveryMethod(arr);
    let id = e.target.id;
    setDeliveryMethodCheck({ ...deliveryMethodCheck, [id]: !deliveryMethodCheck[id] });
  }

  const handleTypeFilter = (e) => {
    const value = e.target.value;
    let arr = courseType;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setCourseType(arr);
    let id = e.target.id;
    setCourseTypeCheck({ ...courseTypeCheck, [id]: !courseTypeCheck[id] });
  };

  function resetFilter() {
    setSearch("");
    setIntake([]);
    setSearchIntakeYearCheck({});
    setDeliveryMethod([]);
    setDeliveryMethodCheck({});
    setCourseType([]);
    setCourseTypeCheck({});
  }

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Start", "End", "Intake", "Learning Method"];

    data = data?.map((row) => ({
      ...row,
      "Name": row.course,
      "Start": row.start ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : "-",
      "End": row.end ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : "-",
      "Intake": row.year ? row.year + "-" + row.number : "",
      "Learning Method": row.type ? RenderLearningMethod(row.type).text : "-"
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Name, row["Start"], row["End"], row.Intake, row["Learning Method"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  async function showCourses(courses) {
    setSelectedCourses(courses);
    $("#viewCourses").modal("show");
  }

  async function closeCourses() {
    setSelectedCourses([]);
    $("#viewCourses").modal("hide");
  }

  function closeAllCourses() {
    $("#viewAllCourses").modal("hide");
  }


  function removeCourse(course) {
    assignQualification.map((elem, ind) => {
      if (elem.id === course.id) {
        if (!course.getSubjects.length) {
          assignQualification.splice(ind, 1);
          setAssignQualification([...assignQualification]);
        } else {
          // assignQualification.splice(ind, 1);
          let tempArr = assignQualification;
          let selectedObj = tempArr.find((item) => item.id == course.id);
          const index = tempArr.indexOf(selectedObj);
          if (index !== -1) {
            tempArr[index] = course;
          }
          //   assignQualification.splice(ind, 1);
          //   setAssignQualification([...assignQualification, course]);
        }
        closeCourses();
      }
    });

    // props.removeCourse();
    // closeCourses();
    // let tempQualifications = [...assignQualification];
    // console.log("tempQualifications first time ---> ", tempQualifications);
    // return
    // tempQualifications.map((e, i) => {
    //     const tempSubjects = [];
    //     if (course.id === e.id) {
    //         console.log("i -----> ", i)
    //         course.getSubjects.map((elem, ind) => {
    //             console.log("ind ---> ", ind)
    //             e.getSubjects.map((element, index) => {
    //                 console.log("index ---> ", index);
    //                 if (elem.id === element.id) {
    //                     // console.log("found obj -=-=-=-=-=-=--> ", elem);
    //                     // e.getSubjects.splice(ind, 1);
    //                 } else {
    //                     tempSubjects.push(elem);
    //                 }
    //             })
    //         })
    //         console.log("tempSubjects ----> ", tempSubjects);
    //         tempQualifications[i].getSubjects = tempSubjects;
    //     }
    // })
    // // console.log("state setted ---> ", tempQualifications);
    // setAssignQualification(tempQualifications);
    // console.log("remove-----> ", course);
  }

  // TODO  receive course - DONE
  // TODO  set table
  function getChangedCourse(newCourse) {
    let arr = [...assignQualification];
    let index;
    arr = arr.map((elem, ind) => {
      if (elem.id === newCourse.id) {
        index = ind;
      } else {
        return elem;
      }
    });
    arr[index] = newCourse;
    setAssignQualification([...arr]);
  }

  return (
    <>
      {/* <div className="my-tickets-info-list Tickets-main-wrap"> */}
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
          <div className="table-responsive-div">
            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                <div id="assessment-table-main_filter" className="dataTables_filter">
                  <label>
                    <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={(e) => handleFilters(e, "search")} value={search} />
                  </label>
                  <div className="filter-eff filter-data-btn">
                    <button className="filter-buttons">
                      <i className="fal fa-filter"></i>
                    </button>
                  </div>
                </div>
            </div>
          </div>
          <div className="filter-button-group">
            <div className="filter-scroll">
              <div className={`filter-scroll-inner  filter-custom-new`}>
                <div className="assessment-table-filter">
                  <div className="btn-type-filter dropdown-comman">
                    <div className="dropdown multiselect">
                      <button
                        className={`btn btn-default dropdown-toggle ${deliveryMethod.length ? "btn-selected" : ""} `}
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                        title="Learning Method"
                      >
                        <span>
                        Learning Method <i className="fal fa-angle-down"></i>
                        </span>
                      </button>
                      <form className="dropdown-menu remove-list-style">
                        <ul aria-labelledby="dropdownMenu2">
                          {deliveryMethodArr &&
                            deliveryMethodArr.map((item, index) => {
                              return (
                                <li className="cat" key={item.value}>
                                  <input type="checkbox" id={"assignDeliveryMethod" + item.value} value={item.value} onClick={handleDeliveryMethodFilter} checked={deliveryMethodCheck["assignDeliveryMethod" + item.value]} />
                                  <label htmlFor={"assignDeliveryMethod" + item.value}>
                                    {RenderLearningMethod(item.value).html}
                                    {/* <span className="cat">{item.value}</span> */}
                                  </label>
                                </li>
                              );
                            })}
                        </ul>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="assessment-table-filter">
                  <div className="btn-type-filter dropdown-comman">
                    <div className="dropdown multiselect">
                      <button className={`btn btn-default dropdown-toggle ${courseType.length ? "btn-selected" : ""} `} type="button" id="assignDropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" title="Type" >
                        <span>
                          Type <i className="fal fa-angle-down"></i>
                        </span>
                      </button>
                      <form className="dropdown-menu remove-list-style">
                        <ul aria-labelledby="assignDropdownMenu">
                          <li>
                            <input type="checkbox" id={"assingPrograms"} value="programs" onClick={handleTypeFilter} checked={courseTypeCheck.assingPrograms} />
                            <label htmlFor="assingPrograms">
                              <span className="cat cat-yellowshade">
                                <i className="fas fa-circle mr-1"></i>Programs</span>
                            </label>
                          </li>
                          <li>
                            <input type="checkbox" id={"assingShortCourse"} value="shortcourse" onClick={handleTypeFilter} checked={courseTypeCheck.assingShortCourse} />
                            <label htmlFor="assingShortCourse">
                              <span className="cat cat-blue">
                                <i className="fas fa-circle mr-1"></i>Short Course</span>
                            </label>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="reset-btn-group">
              <div className="button-reset dropdown-comman">
                <button
                  className="btn btn-primary"
                  title="Reset"
                  onClick={resetFilter}
                >
                  <i className="fal fa-redo"></i>Reset
                </button>
              </div>
              <div className="files-export-group">
                <button
                  type="button"
                  className="btn btn-files"
                  onClick={() => {
                    exportData("xlsx", "Assigned_Programme_And_Course");
                  }}
                  title="Export spreadsheet"
                >
                  <i className="fal fa-file-excel icon"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-files"
                  onClick={() => {
                    exportData("csv", "Assigned_Programme_And_Course");
                  }}
                  title="Export CSV"
                >
                  <i className="fal fa-file-csv icon"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-files"
                  onClick={() => {
                    exportData("pdf", "Assigned_Programme_And_Course");
                  }}
                  title="Export PDF"
                >
                  <i className="fal fa-file-pdf icon"></i>
                </button>
              </div>
            </div>
          </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                < button className="btn btn-primary mr-2" data-toggle="modal" data-target="#available" title="Available Programmes And Courses" >
                  <i className="fal fa-plus"></i>Add Programme
                </button>
              </div>
            </div>
          </div>
        </div>

        {props.loading ? <SkeletonTicketList /> : <DataTable
          data={dataToRender()}
          defaultSortField="start"
          defaultSortAsc={false}
          columns={columns}
          pagination={true}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />}
      </div>
      {/* </div> */}
      {/* Modal to show table of child courses */}
      <div
        className="topic-add-modal modal fade" id="viewCourses" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered available-assessment-modal" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">Courses</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  closeCourses();
                }}
              >
                <span aria-hidden="true">×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <CoursesTable 
              getValue={getValue} 
              courses={selectedCourses} 
              isRemoveBtn={true} 
              removeCourse={removeCourse} 
              setCourse={getChangedCourse} 
              getAllCourses={getAllCourses}
              />
            </div>
          </div>
        </div>
      </div>
      {/* End of Modal to show table of child courses */}
      <div className="topic-add-modal modal fade" id="viewAllCourses" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">Courses</h5>
              <button
                type="button"
                className="close"
                onClick={() => { closeAllCourses() }}
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">×
                </span>
              </button>

            </div>
            <div className="modal-body">
              <AllCourseTable
                courses={allCourses}
                loading={allCourseLoading}
                programmeCourse={selectedCourses}
                setSelectedProgramme={setSelectedCourses}
                closeAllCourses={closeAllCourses}
                totalRows={totalRows}
                pageNo={pageNo}
                perPage={perPage}
                setPerPage={setPerPage}
                setPageNo={setPageNo}
                setSortKey={setSortKey}
                setSortOrder={setSortOrder}
                setCourseSearch={setCourseSearch}
                search={courseSearch}
                intakeArr={courseDropdown.intake}
                programmeArr={courseDropdown.programme}
                learningArr={courseDropdown.learning}
                intake={courseIntake}
                setIntake={setCourseIntake}
                learningMethod={learningMethod}
                setLearningMethod={setLearningMethod}
                reply={reply}
                setReply={setReply}
                programme={programme}
                setProgramme={setProgramme}
                status={status}
                setStatus={setStatus}
                sortOrder={sortOrder}
                setAllCourseError={setAllCourseError}
                allCourseError={allCourseError}
                setTempCheckedAllData={setTempCheckedAllData}
                tempCheckedAllData={tempCheckedAllData}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Modal to Show Available Programme */}
      <div
        className="topic-add-modal modal fade"
        id="available"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Add Programme</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
            <div className="modal-body">
              <AvailableQualificationAndCourses data={props.availdata} isAlreadyExists={props.isAlreadyExists} assignCourse={props.assignCourse} dropdownValues={dropdownValues} loading={props.availloading} />
            </div>
          </div>
        </div>
      </div>
      {/* End Of Modal Of Available Programme */}
    </>
  );
}

export default AssignedProgrmasAndCourses;
