export const IconName = (type) => {

    switch (type) {
        case "video":
            return "fal fa-video";
        case "link":
            return "fal fa-external-link";
        case "label":
            return "fal fa-tag";
        case "assessment":
            return "fal fa-file-check";        
        case 'word':
            return 'fal fa-file-word';            
        case 'zip':
            return 'fal fa-file-archive';            
        case 'pdf':
            return 'fal fa-file-pdf';            
        case 'excel':
            return 'fal fa-file-excel';            
        case 'image':
            return 'fal fa-file-image';            
        case 'txt':
            return 'fal fa-file-alt';            
        case 'pptx':
            return 'fal fa-file-powerpoint'; 
        case 'ppt':
            return 'fal fa-file-powerpoint';            
        default:
            return ""
    }
}
