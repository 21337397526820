import React, { useState, useMemo, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import { GetNotificationsList, DeleteNotifications, SendQuickNotification } from "../../../services/CourseService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import Swal from "sweetalert2";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import PermissionsGate from "../../../utils/permissionGate";
import HtmlInputEditor from "../../common/HtmlInputEditor";
// import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import $ from 'jquery';
import SendNotification from "../SendNotification";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import { GetListOfUser } from "../../../services/UserService";
import { TrimText } from "../../common/TrimText";
import HtmlParser from "react-html-parser";
import { handleTableScroll } from "../../../utils/commonFunction";

const Notifications = (props) => {

  const { id, type } = useParams()
  let history = useHistory();
  const [search, setSearch] = useState("");
  const [modalData, setModalData] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [isNotificationData, setIsNotificationData] = useState(false);
  // const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [notificationFields, setNotificationFields] = useState({
    class_id: "",
    subject: "",
    message: "",
    attachment: {},
    class_name: "",
  });
  const [editData, setEditData] = useState({});
  const [userData, setUserData] = useState([]);
  const [sentby, setSentBy] = useState([]);
  const [searchSentByCheck, setSearchSentByCheck] = useState({});

  useEffect(() => {
    handleTableScroll()
  }, [isNotificationData])

  useEffect(() => {
    GetListOfUser().then((res) => { setUserData(res?.data); }).catch((err) => console.log(err));
    getNotificationData();
  }, []);

  const handleReportToFilter = (e) => {
    const value = e.target.value;
    let arr = sentby;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setSentBy(arr);
    let id = e.target.id;
    setSearchSentByCheck({ ...searchSentByCheck, [id]: !searchSentByCheck[id] });
  };

  const renderSendByUI = () => {
    let data = userData.sort((a, b) => {
      let fa = a.Firstname.toLowerCase(),
        fb = b.Firstname.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return data?.map((i, index) => {
      return (
        <li key={index}>
          <input type="checkbox" id={i?.Firstname + " " + i?.Lastname} value={i?.Firstname} onClick={handleReportToFilter} checked={searchSentByCheck[i?.Firstname + " " + i?.Lastname]} />
          <label htmlFor={i?.Firstname + " " + i?.Lastname}>
            <span className="cat">{i?.Firstname} {i?.Lastname}</span>
          </label>
        </li>
      );
    });
  };

  const getNotificationData = () => {
    setIsNotificationData(true)
    setNotifications([]);
    GetNotificationsList(props.userId).then(data => {
      setNotifications(data && data.data && data.data.notifications ? data.data.notifications : []);
      setIsNotificationData(false);
    }).catch(error => {
      setIsNotificationData(false);
      // console.log("error =====> ", error);
    })
  }

  const DeleteNotification = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteNotifications({ notification_id: id }).then(data => {
          getNotificationData();
          Swal.fire("Deleted!", "Your record has been deleted.", "success");
        }).catch(error => {
          console.log("error while deleting >>>>> ", error);
        })
      }
    })
  }

  const sortingUser = (rowA, rowB) => {
    const name1 = rowA?.sendByCourse?.log.toString() ? rowA?.sendByCourse?.log.toString() : "-";
    const name2 = rowB?.sendByCourse?.log.toString() ? rowB?.sendByCourse?.log.toString() : "-";
    return name1.localeCompare(name2);
  };
  const sortingDate = (rowA, rowB) => {
    const name1 = rowA?.start.toString().length > 10 ? rowA?.start/1000 : rowA?.start
    const name2 = rowB?.start.toString().length > 10 ? rowB?.start/1000 : rowB?.start
    return new Date(name1) - new Date(name2);
  };

  const notificationColumns = useMemo(() => [
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
      wrap: true,
      cell: (row) =>
        row?.subject ?
          <span className="" title={row?.subject}>
            {row.draft_notification ?
              <Link to={{ pathname: `/courseAdministration/coursesdetails/${id}/notifications/create/${row.id}`  }} onClick={() => setEditData(row)} className="as-text-blue curser feature-name"><span className="textLimit100">{row.subject}</span></Link>
              : <span className="as-text-blue curser feature-name" data-toggle="modal" data-target="#notifications" onClick={() => setNotificationModalData(row)}><span className="textLimit100">{row.subject}</span></span>}

          </span> : (
            <p className="cat">
              <i className="fas fa-circle mr-1"></i> N/A
            </p>
          )
    },
    {
      name: "Sent Via",
      selector: "sentVia",
      sortable: true,
      wrap: true,
      // maxWidth: "250px",
      cell: (row) =>
        <>
          {row.draft_notification ? <div className="as-green-bg as-widget">{"Draft Saved On " + moment.unix(row.start.toString().length > 13 ? row.start/1000 : row.start).format(TABLE_DATE_TIME_FORMAT)}</div> : handleReturningIcon(row)}
          {/* {row.isPushnotificationActive !== 1 && row.isEmailActive == 1 && <i className="fal fa-envelope ml-1" aria-hidden="true"></i>}
          {row.isPushnotificationActive !== 1 && row.draft_notification == 1 && <i className="fa fa-comment ml-1" aria-hidden="true"></i>}
          {row.isPushnotificationActive !== 1 && row.isSmsActive == 1 && <i className="fal fa-mobile ml-1" aria-hidden="true"></i>}
          {row.isPushnotificationActive == 1 && <><i className="fal fa-envelope ml-1" aria-hidden="true"></i><i className="fal fa-mobile ml-1" aria-hidden="true"></i></>} */}
        </>
      // row.sentVia ? <div dangerouslySetInnerHTML={{ __html: row.sentVia }}></div> : (
      //   <p className="cat">
      //     <i className="fas fa-circle mr-1"></i> N/A
      //   </p>
      // ),
    },
    {
      name: "Sent By",
      selector: "log",
      sortable: true,
      wrap: true,
      sortFunction: sortingUser,
      cell: (row) =>
        row.sendByCourse && row.sendByCourse.log ?
          <span className="feature-name" title={row.sendByCourse && row.sendByCourse.log}>
            <span className="textLimit100">{row.sendByCourse && row.sendByCourse.log}</span>
          </span>
          : (
            <p>-</p>
          ),
    },
    {
      name: "Sent Date",
      selector: "start",
      sortable: true,
      wrap: true,
      sortFunction: sortingDate,
      // maxWidth: "250px",
      cell: (row) => (
        <>{row.start ?
          <div className="dateTime">
            <p className="right-space">{moment.unix(row.start.toString().length > 10 ? row.start/1000 : row.start).format(TABLE_DATE_FORMAT + ",")}</p>
            <p>{moment.unix(row.start.toString().length > 10 ? row.start/1000 : row.start).format(TABLE_TIME_FORMAT)}</p>
          </div>
          : (
            <p>-</p>
          )}</>
      )
    },
    {
      name: "Actions",
      selector: "",
      maxWidth: "100px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <button className="btn btn-sm btn-primary rounded-circle" title="View" data-toggle="modal" data-target="#notifications" onClick={() => setNotificationModalData(row)}><i className="fal fa-eye"></i></button>

            {row.draft_notification !== 1 && <PermissionsGate scopes={["cnodelete"]} errorProps={{ disabled: true }}>
              <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => DeleteNotification(row.id)}><i className="fal fa-trash-alt"></i></button>
            </PermissionsGate>}
            {row.draft_notification === 1 && <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button className="btn btn-sm btn-primary rounded-circle" onClick={() => { editNotification(row) }} title="Open" ><i className="fal fa-folder-open"></i></button>
                {/* <button className="btn btn-sm btn-primary rounded-circle" onClick={() => { editNotification(row) }} title="Open" data-toggle="modal" data-target="#addNotifications"><i className="fal fa-folder-open"></i></button> */}

                <PermissionsGate scopes={["cnodelete"]} errorProps={{ disabled: true }}>
                  <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => DeleteNotification(row.id)}><i className="fal fa-trash-alt"></i></button>
                </PermissionsGate>
              </div>
            </div>}
          </div>
        </div>
      ),
    },
  ]);

  function setNotificationModalData(data) {
    // const blocksFromHTML = convertFromRaw(JSON.parse(data.content));
    // setEditorState(EditorState.createWithContent(blocksFromHTML));
    let obj = {
      subject: data.subject,
      SentDate: data.start ? data.start : "",
      qualifications: data.qualifications ? data.qualifications : "-",
      content: data.content ? data.content : "",
      attachment: data.attachment ? modifiyLink(data.attachment) : "",
      // content: JSON.stringify(convertToRaw(EditorState.createWithContent(blocksFromHTML).getCurrentContent())),
    }
    setModalData(obj);
  }

  function modifiyLink(linkName) {
    if (linkName.includes("https")) {
      linkName = linkName
    } else {
      if (linkName.includes("public/")) {
        linkName = IMAGE_URL + "/" + linkName.replace("public/", "");
      } else if (!linkName.includes("public/")) {
        linkName = IMAGE_URL + "/" + linkName;
      } else {
        linkName = ""
      }
    }
    return linkName;
  }

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const notificationsToRender = () => {
    let updatedData = [];
    let allData = notifications;
    if (search.length) {
      let name = allData.filter((item) => {
        let includes = item.subject.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          // console.log("name: ", includes);
          return includes;
        } else return null;
      });
      //moment(row.sentDate).format(TABLE_DATE_FORMAT)
      let tempDate = allData.filter((item) => {
        let includes = item.subject && moment(item.subject).format(TABLE_DATE_FORMAT).toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [...name, ...tempDate];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (sentby.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = sentby.find(function (user, index) {
          if (item?.sendByCourse?.log.toString().toLowerCase().includes(user.toLowerCase())) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }

    if (search.length || sentby.length) {
      return updatedData;
    } else {
      return notifications;
    }
  };

  const resetFilter = () => {
    setSearch("");
    setSentBy([]);
    setSearchSentByCheck({});
  };

  const exportData = (fileType, fileName) => {
    let data = notificationsToRender();
    const header = ["Subject","Sent Via", "Sent By", "Sent Date"];

    data = data?.map((row, index) => ({
      ...row,
      Subject: row?.subject ? row?.subject : "-",
      "Sent By": row?.sendByCourse && row?.sendByCourse.log ? row?.sendByCourse.log : "-",
      "Sent Via": row?.draft_notification ? "Draft Saved On " + moment(row?.start.toString().length > 13 ? row?.start/1000 : row?.start).format(TABLE_DATE_TIME_FORMAT) : `${row?.isPushnotificationActive !== 1 && row?.isEmailActive == 1 ? " Email" : ""}${row?.isPushnotificationActive !== 1 && row?.draft_notification == 1 ? " Draft" : ""}${row?.isPushnotificationActive !== 1 && row?.isSmsActive == 1 ? " Sms" : ""}${row?.isPushnotificationActive == 1 ? " Email Sms" : ""}`,
      "Sent Date": row?.start ? moment(row?.start.toString().length > 13 ? row?.start/1000 : row?.start).format(TABLE_DATE_TIME_FORMAT) : "-",
    }));


    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });

      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Subject, row["Sent By"], row["Sent Via"], row["Sent Date"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        columnStyles: {
          0: {
            columnWidth: 40,
          },
          1: {
            columnWidth: 40,
          },
          2: {
            columnWidth: 40,
          },
          3: {
            columnWidth: 40,
          },
        },
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  function editNotification(data) {
    setEditData(data);
    history.push(data.id ? `/courseAdministration/coursesdetails/${id}/notifications/create/${data.id}` : `/courseAdministration/coursesdetails/${id}/notifications/create`)
  }

  const handleReturningIcon = (row) => {
    return (
      <>
        {row.isEmailActive ? (
          <><i className="fal fa-envelope"></i>&nbsp;</>
        ) : ("")}
        {row.isSmsActive ? (
          <><i className="fal fa-comment"></i>&nbsp;</>
        ) : ("")}
        {row.isPushnotificationActive ? <i className="fal fa-mobile"></i> : ""}

        {!row.isPushnotificationActive && !row.isSmsActive && !row.isEmailActive ? "-" : ""}
      </>
    );
  };

  return (
    <>
      <div className="">
        <div className={``}>
          {type === "show" && <>
            <div className="my-tickets-info-list Tickets-main-wrap">

              <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom days-overdue-cell-width">
                <div className="search-filter-div">
                  <div className="search-filter-div-left">
                    <div className="system-administration-table table-responsive">
                      <div className="table-responsive-div">
                        <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                          <div id="assessment-table-main_filter" className="dataTables_filter">
                            <label>
                              <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                            </label>
                            <div className="filter-eff filter-data-btn">
                              <button className="filter-buttons">
                                <i className="fal fa-filter"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="filter-button-group">
                        <div className="filter-scroll">
                          <div className={`filter-scroll-inner  filter-custom-new`}>
                            <div className="assessment-table-filter">
                              <div className="btn-type-filter dropdown-comman">
                                <div className={`dropdown multiselect `}>
                                  <button className={`btn btn-default dropdown-toggle ${sentby.length ? "btn-selected" : ""}`} type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" title="Sent By">
                                    <span>
                                      Sent By <i className="fal fa-angle-down"></i>
                                    </span>
                                  </button>
                                  <form className="dropdown-menu remove-list-style">
                                    <ul aria-labelledby="dropdownMenu1">
                                      {renderSendByUI()}
                                    </ul>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="reset-btn-group">
                          <div className="button-reset dropdown-comman">
                            <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                              <i className="fal fa-redo"></i>Reset
                            </button>
                          </div>
                          <div className="files-export-group">
                            <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Notifications") }} title="Export spreadsheet" >
                              <i className="fal fa-file-excel icon"></i>
                            </button>
                            <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Notifications") }} title="Export CSV" >
                              <i className="fal fa-file-csv icon"></i>
                            </button>
                            <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Notifications") }} title="Export PDF" >
                              <i className="fal fa-file-pdf icon"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="search-filter-div-right">
                    <div className=" filter-search-bar-blk">

                      <div className="add-ticket-blk button-reset dropdown-comman">
                        <PermissionsGate scopes={["cnoadd"]} RenderError={() =>
                          <button className="btn btn-primary" disabled title="Create New">
                            <i className="fal fa-plus"></i>Create New
                          </button>}>
                          <button className="btn btn-primary" type="button" onClick={() => editNotification({})} title="Create New">
                            <i className="fal fa-plus"></i>Create New
                          </button>
                          {/* <button className="btn btn-primary" data-toggle="modal" data-target="#addNotifications" title="New Notification">
                        <i className="fal fa-plus"></i>New Notification
                      </button> */}
                          {/* </Link> */}
                        </PermissionsGate>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="filter-search-icon card card-table-custom roles-table"></div> */}
                
                <DataTable
                  data={notificationsToRender()}
                  progressPending={isNotificationData}
                  progressComponent={<SkeletonTicketList />}
                  columns={notificationColumns}
                  pagination={true}
                  defaultSortField="start"
                  defaultSortAsc={false}
                  paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                />
              </div>
            </div>


            {/* {notifications.length !== 0 && <div className="form-group form-group-save-cancel">
              <button className="btn btn-save btn-success" type="submit" title="Save">
                <i className="fal fa-save"></i>Save
              </button>
              <button className="btn btn-close btn-danger" type="button" title="Cancel">
                <i className="fal fa-times"></i>Cancel
              </button>
            </div>} */}
          </>}
          {type === "create" && <>
            <div className="my-tickets-info-list Tickets-main-wrap">
              <SendNotification getData={getNotificationData} data={editData} isEdit={editData == {} ? false : true} />
            </div>
          </>}
        </div>
      </div>


      <div className="topic-add-modal modal fade" id="notifications" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content modal-border-update">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fas fa-bars"></i> Notification Details
                  </h5>
                  <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                  </button>
                      
              </div>
              <div className="t p-30px">
                <div className="topic-add-modal-content">
                  <div className="row center-label">
                    <div className="col-md-6 col-lg-12">
                      <div className="form-group-blk mb-3">
                          <label>Subject/Title:</label><br/>
                          <span className="font-weight-bold" title={modalData && modalData?.subject}>{modalData && TrimText(modalData?.subject, 90)}</span>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-12">
                      <div className="form-group-blk mb-3">
                          <label>Received Date/Time:</label><br/>
                          <span className="font-weight-bold">{modalData && modalData?.SentDate ? modalData?.SentDate && moment.unix(modalData?.SentDate).format(TABLE_DATE_TIME_FORMAT) : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-12">
                      <div className="form-group-blk mb-3">
                          <label>Related Qualification/Subject:</label><br/>
                          <span className="font-weight-bold">{modalData && modalData?.qualifications ? modalData?.qualifications.getCourseName.name+", Intake "+ modalData?.qualifications.number+", "+modalData?.qualifications.year+", "+ modalData?.qualifications.study_type : "--"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-12">
                      <div className="form-group-blk mb-3">
                          <span>{modalData && modalData?.content ? HtmlParser(modalData?.content) : "NA"}</span>
                      </div>
                    </div>
                    
                  </div>
                </div>
            

                <div className="form-group form-group-save-cancel mt-3">
                  <a href={modalData && modalData?.attachment ? modalData?.attachment.replace(
                    "home/myaie/public_html/",
                    ""
                  ).replace("public/","") : ""} target="_blank" download>
                    <button className="btn btn-primary mr-1" disabled={modalData?.attachment == ""}>
                      <i className="fal fa-arrow-down"></i>
                      Download Document
                    </button>
                  </a>

                  <button type="button" className="btn btn-danger" data-dismiss="modal">
                    <i className="fal fa-times"></i>
                    Close
                  </button>
                </div>
              </div>
          </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Notifications;
