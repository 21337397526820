import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import { RenderPMCStatus } from "../../../utils/CommonGroupingItem";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import {
    AddStaticPages,
    getStaticPagesBrandList,
    getStaticPagesCategory,
    getStaticPagesHeaderList,
    getStaticPagesPopupList,
    getStaticPagesStatusList,
    GetStaticUpdateList,
    updateStaticPages
} from "../../../services/StaticPgesServer";
import PermissionsGate from "../../../utils/permissionGate";

const StaticPagesOpt = () => {
    const history = useHistory();
    const { id, tab } = useParams();
    const [updateData, setUpdateData] = useState({});
    const [brandList, setBrandList] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [statusArr, setStatusArr] = useState([]);
    const [header, setHeader] = useState([]);
    const [popup, setPopup] = useState([]);
    const [loading, setLoading] = useState(false);

    const givenPermissions = useSelector((state) => state.givenPermission);
    
    let brandID = updateData?.brandTemplete?.map((data) => data.brandID);


    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                GetStaticUpdateList(id)
                    .then(res => {
                        setUpdateData(res?.data?.Data)
                        setLoading(false);
                    })
            } catch (error) {
                console.error("Error fetching data", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        setLoading(true);

        const fetchDropdownList = () => {
            getStaticPagesCategory()
                .then((res) => setCategoryData(res?.data?.Data))

            getStaticPagesStatusList()
                .then(res => setStatusArr(res?.data?.publishedStatus))

            getStaticPagesBrandList()
                .then(res => setBrandList(res?.data?.brandTypesFilters))

            getStaticPagesHeaderList()
                .then(res => setHeader(res?.data?.Data))

            getStaticPagesPopupList()
                .then(res => setPopup(res?.data?.Data))

            setLoading(false);
        }

        fetchDropdownList();
    }, [])

    const formik = useFormik({
        enableReinitialize: tab === "update" && true,
        initialValues: {
            PageID: id ? id : "",
            pageTitle: updateData?.PageTitle || "",
            category: updateData?.category || "",
            BodyContent: updateData?.BodyContent || "",
            SliderID: updateData?.SliderID || "",
            PopupID: updateData?.PopupID || "",
            FormDestinationEmail: updateData?.FormDestinationEmail || "",
            UseBottomForm: updateData?.UseBottomForm || 'No',
            conversion_code: updateData?.conversion_code || "",
            metaTitle: updateData?.MetaTitle || "",
            metaDescription: updateData?.MetaDescription || "",
            metaKeywords: updateData?.MetaKeywords || "",
            customCss: updateData?.customCss || "",
            Brand: brandID || [],
            addCustomCss: updateData?.addCustomCss || 0,
            ShowTitleInBody: updateData?.ShowTitleInBody || 0,
            IsPublished: updateData ? updateData?.IsPublished : "",
        },
        validationSchema: Yup.object({
            pageTitle: Yup.string().required("Page Title is required"),
            Brand: Yup.array().required("Brand Templates is required"),
            BodyContent: Yup.string().required("Page Content is required"),
            customCss: Yup.string().when("addCustomCss", {
                is: (value) => value == 1,
                then: Yup.string().required("Custom CSS is required"),
                otherwise: Yup.string().nullable()
            })
        }),
        onSubmit: async (values, actions) => {

            const formData = new FormData();

            // Trim and clean values, replace undefined or null with empty string
            const cleanedValues = Object.fromEntries(
                Object.entries(values).map(([key, value]) => [
                    key,
                    value === undefined || value === null ? "" : value
                ])
            );

            // Append values to FormData
            Object.keys(cleanedValues).forEach((key) => {
                const value = cleanedValues[key];

                if (Array.isArray(value)) {
                    if (key === "assignedPages") {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        value.forEach((val, idx) => {
                            formData.append(`${key}[${idx}]`, val !== undefined && val !== null ? val : "");
                        });
                    }
                } else {
                    formData.append(key, value !== undefined && value !== null ? value : "");
                }
            });

            if (tab === "update") {
                setLoading(true);
                updateStaticPages(formData)
                    .then(res => {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "updated Successfully",
                        });
                        setLoading(false);
                    })
                    .catch(err => {
                        console.error(err)
                        setLoading(false);
                    })
            } else {
                setLoading(true);
                try {
                    await AddStaticPages(formData);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Added Successfully",
                    });
                    actions.resetForm();
                    history.push("/website_management/pages/staticpages/table");
                    setLoading(false);
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response?.data?.message || "An error occurred",
                    });
                    setLoading(false);
                }
            }
        }
    });

    return (
        <div className="card card-profile-info-card">
            <div>
                <div className="new-card-header">
                    <div className="card-header pt-0">Page Information</div>
                </div>
                <div className="card-body-inr card-body-info">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Page Title">
                                    <label>Page Title *</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.pageTitle && formik.touched.pageTitle
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Page Title"
                                        name="pageTitle"
                                        id="pageTitle"
                                        value={formik.values.pageTitle}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Assigned Brand Templates">
                                    <label>Assigned Brand Templates* <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box is-multi" + (formik.errors.Brand && formik.touched.Brand
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="Brand"
                                        value={brandList.filter((data) => formik.values.Brand.includes(data.value))}
                                        onChange={(value) => formik.setFieldValue("Brand", value ? value.map((v) => v.value) : [])}
                                        onBlur={formik.handleBlur}
                                        options={brandList}
                                        maxMenuHeight={175}
                                        isMulti
                                        placeholder={"Select Assigned Brand Templates"}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Status">
                                    <label title="Status" >Status <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.IsPublished && formik.touched.IsPublished
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="IsPublished"
                                        value={statusArr.find(val => val.value === formik.values.IsPublished)}
                                        onChange={(option) => {
                                            formik.setFieldValue("IsPublished", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={statusArr}
                                        maxMenuHeight={175}
                                        placeholder="Select Status"
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-flex" style={{ marginTop: 35 }}>
                                <label className="mb-0" title="Show Page Title">
                                    Show Page Title <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2" title="Show Page Title">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="showTitleInBody"
                                            checked={formik.values.ShowTitleInBody == 1}
                                            onChange={(e) => {
                                                formik.setFieldValue("ShowTitleInBody", e.target.checked ? 1 : 0);
                                            }}
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Popup">
                                    <label>Category</label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.category && formik.touched.category
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="PopupID"
                                        value={categoryData.find(val => val.value == formik.values.category)}
                                        onChange={(option) => {
                                            formik.setFieldValue("category", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={categoryData}
                                        maxMenuHeight={175}
                                        placeholder="Default"
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group-blk mb-3">
                                    <label title="Page Content">Page Content*</label>
                                    <HtmlInputEditor
                                        editorState={formik.values.BodyContent}
                                        setEditorState={(editorState) => {
                                            formik.setFieldValue('BodyContent', editorState);
                                        }}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <hr className="border border-2" style={{ borderColor: "rgb(230, 235, 241)" }} />
                            </div>
                            <div className="new-card-header col-md-12">
                                <h4 className="card-header pt-0" title="Page Settings">
                                    <i
                                        className="fal fa-cog"
                                        style={{ color: "var(--topbar-color)" }}
                                    ></i> Page Settings
                                </h4>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Header">
                                    <label>Header <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.SliderID && formik.touched.SliderID
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="SliderID"
                                        value={header.find(val => val.value === formik.values.SliderID)}
                                        onChange={(option) => {
                                            formik.setFieldValue("SliderID", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={header}
                                        maxMenuHeight={175}
                                        placeholder={
                                            formik.values.SliderID || "AIE Default"
                                        }
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Popup">
                                    <label>Popup <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.PopupID && formik.touched.PopupID
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="PopupID"
                                        value={popup.find(val => val.value === formik.values.PopupID)}
                                        onChange={(option) => {
                                            formik.setFieldValue("PopupID", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={popup}
                                        maxMenuHeight={175}
                                        placeholder={
                                            formik.values.PopupID || "Default"
                                        }
                                        isClearable
                                    />
                                </div>
                            </div>
                            {/* <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3">
                                    <label>Form Email</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.FormDestinationEmail && formik.touched.FormDestinationEmail
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Form Email"
                                        name="FormDestinationEmail"
                                        id="email"
                                        title="email"
                                        value={formik.values.FormDestinationEmail}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div> */}
                            <div className="col-md-6 col-lg-3 d-flex" style={{ marginTop: 35 }} title="Show Bottom Form">
                                <label className="mb-0">
                                    Show Bottom Form <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="useBottomForm"
                                            checked={formik.values.UseBottomForm === "Yes"}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "UseBottomForm",
                                                    e.target.checked ? "Yes" : "No"
                                                )
                                            }}
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1" title="SEO Settings">
                                    <h4 className="card-header">
                                        <i
                                            className="fal fa-cog"
                                            style={{ color: "var(--topbar-color)" }}
                                        ></i> SEO Settings
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3" title="conversion">
                                    <label htmlFor="code">Conversion Code</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.conversion_code && formik.touched.conversion_code
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Conversion Code"
                                        name="conversion_code"
                                        id="conversion"
                                        maxLength={20}
                                        value={formik.values.conversion_code}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3" title="Meta Title">
                                    <label>Meta Title</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.metaTitle && formik.touched.metaTitle
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Meta Title"
                                        name="metaTitle"
                                        value={formik.values.metaTitle}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3" title="Meta Description">
                                    <label>Meta Description</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.metaDescription && formik.touched.metaDescription
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Meta Description"
                                        name="metaDescription"
                                        value={formik.values.metaDescription}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group-blk mb-3" title="Meta Keywords">
                                    <label>Meta Keywords</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.metaKeywords && formik.touched.metaKeywords
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Meta Keywords"
                                        name="metaKeywords"
                                        value={formik.values.metaKeywords}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1" title="Style Settings">
                                    <h4 className="card-header">
                                        <i
                                            className="fal fa-cog"
                                            style={{ color: "var(--topbar-color)" }}
                                        ></i> Style Settings
                                    </h4>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 d-flex align-items-center" title="Add Custom CSS">
                                <label className="mb-0">
                                    Add Custom CSS <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={formik.values.addCustomCss === 1}
                                            name="addCustomCss"
                                            onChange={(e) => {
                                                formik.setFieldValue("addCustomCss", e.target.checked ? 1 : 0);
                                            }}
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>

                            {formik.values.addCustomCss == 1 && <div className="col-md-12">
                                <div className="form-group-blk mb-3 mt-3" title="Customs CSS">
                                    <label>Customs CSS*</label>
                                    <HtmlInputEditor
                                        editorState={formik.values.customCss}
                                        setEditorState={(editorState) => {
                                            formik.setFieldValue('customCss', editorState);
                                        }}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>}
                        </div>
                        <div className="form-group form-group-save-cancel mt-4">
                            <PermissionsGate scopes={[tab == "create" ? 'wbsstaticpageadd' : 'wbsstaticpageedit']} RenderError={() => (
                                <button className="btn btn-save btn-success" disabled>
                                    <i className="fal fa-save"></i> Save
                                </button>
                            )}>

                                <button
                                    className="btn btn-save btn-success"
                                    type="submit"
                                    title="Save"
                                    disabled={loading}
                                >
                                    {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                                    Save
                                </button>
                            </PermissionsGate>

                            <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => formik.resetForm()}>
                                <i className="fal fa-times"></i>
                                Cancel
                            </button>
                        </div>
                        {Object.keys(formik.errors).map((key) => {
                            if (formik.touched[key]) {
                                return (
                                    <div className="invalid-feedback d-block" key={key}>
                                        {formik.errors[key]}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default StaticPagesOpt;
