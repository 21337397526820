import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom";
import InProgressSLPStudents from "./InProgressSLPStudents";
import PendingSignOffStudents from "./PendingSignOffStudents";
import NotesPendingResponseSLP from "./NotesPendingResponseSLP";
import NotYetStartedSLP from "./NotYetStartedSLP";
import PastRecommendedStartSLP from "./PastRecommendedStartSLP";
import PastCommittedEndSLP from "./PastCommittedEndSLP";
import $ from "jquery";

const ListofMentored = ({Course,globalReset,Programmes,Lecturer,filterSchool,filterIntake,filterLearningMethod,filterLecturer}) => {
  const history = useHistory();
  const { type } = useParams();
  const [subjectStatus, setSubjectStatus] = useState([type]);

  return (
    <>
      <div className="tabs-wrap">
        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
        <li
            className="nav-item"
            onClick={() => {
              setSubjectStatus(["in_progress"]);
              history.push("/home/mentored/in_progress");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${
                type === "in_progress" ? "active" : ""
              }`}
              id="pills-tab4"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab14"
              aria-selected="true"
            >
              <i className="fal fa-hourglass-half"></i>In Progress
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setSubjectStatus(["pending_sign_off"]);
              history.push("/home/mentored/pending_sign_off");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${
                type === "pending_sign_off" ? "active" : ""
              }`}
              id="pills-tab2"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab12"
              aria-selected="true"
            >
             <i className="fal fa-calendar"></i>Pending Sign-off
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setSubjectStatus(["notes_pending_response"]);
              history.push("/home/mentored/notes_pending_response");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${
                type === "notes_pending_response" ? "active" : ""
              }`}
              id="pills-tab5"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab15"
              aria-selected="true"
            >
              <i className="fal fa-comment-alt-dots"></i>Notes Pending Response
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setSubjectStatus(["not_started"]);
              history.push("/home/mentored/not_started");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${
                type === "not_started" ? "active" : ""
              }`}
              id="pills-tab6"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab16"
              aria-selected="true"
            >
              <i className="fal fa-clock"></i>Not Yet Started
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setSubjectStatus(["past_recommended_start"]);
              history.push("/home/mentored/past_recommended_start");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${
                type === "past_recommended_start" ? "active" : ""
              }`}
              id="pills-tab3"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab13"
              aria-selected="true"
            >
              <i className="fal fa-arrow-circle-right"></i>Past Recommended
              Start
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setSubjectStatus(["past_committed_end"]);
              history.push("/home/mentored/past_committed_end");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${
                type === "past_committed_end" ? "active" : ""
              }`}
              id="pills-tab1"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab11"
              aria-selected="true"
            >
              <i className="fa fa-history"></i>
              Past Committed End
            </a>
          </li>
        </ul>
      </div>
      {type === "in_progress" ? <InProgressSLPStudents subjectStatus={subjectStatus}  filterIntake={filterIntake} filterSchool={filterSchool} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer}/>
       : type === "pending_sign_off" ? <PendingSignOffStudents subjectStatus={subjectStatus}  filterIntake={filterIntake} filterSchool={filterSchool} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer}/>
       : type === "notes_pending_response" ? <NotesPendingResponseSLP subjectStatus={subjectStatus}  filterIntake={filterIntake} filterSchool={filterSchool} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer}/>
       : type === "not_started" ? <NotYetStartedSLP subjectStatus={subjectStatus}  filterIntake={filterIntake} filterSchool={filterSchool} filterLearningMethod={filterLearningMethod} filterLecturer={filterLecturer}/>
       : type === "past_recommended_start" ? <PastRecommendedStartSLP globalReset={globalReset} subjectStatus={subjectStatus} filterLecturer={filterLecturer} filterIntake={filterIntake} filterSchool={filterSchool} filterLearningMethod={filterLearningMethod} />
       : <PastCommittedEndSLP globalReset={globalReset} subjectStatus={subjectStatus} filterLecturer={filterLecturer} filterIntake={filterIntake} filterSchool={filterSchool} filterLearningMethod={filterLearningMethod}/>
      }
    </>
  );
};

export default ListofMentored;
