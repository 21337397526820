import React, { useEffect, useState } from "react";
import { getCurrentTimeHomePage } from "../../../common/Helper";
import { Link, useHistory, useParams } from "react-router-dom";
import QuestionBank from "./QuestionBank";
import AuditTrail from "./AuditTrail";
import ViewQuestion from "./ViewQuestion";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { ViewaQuestion, ViewaQuestionCancelToken } from "../../../../services/SmartAssessmentService";
import { tryParseJSONObject } from "../../../../utils/TryParseJSONObject";
import { TrimText } from "../../../common/TrimText";
import { GlobalIconNames } from "../../../../utils/GlobalIconNames";
import { RenderQuestionTypeForm } from "../../../../utils/CommonGroupingItem";
import axios from "axios";

const Tabs = () => {

  // /courseAdministration/smartAssessment/:tabName?   /:tab?   /:subtab?/:id?/:subId?
  // /courseAdministration/smartAssessment/questionBank/question/view   /1

  const givenPermsisions = useSelector((state) => state.givenPermission);
  const { tabName, tab, subtab, id } = useParams();
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [questionType, setQuestionType] = useState("");


  useEffect(() => {
    let response = hasPermission({ scopes: ["cquestionbview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }

    // if (id) {
    //   ViewaQuestion({ qId: id }).then((res) => {
    //     setTitle(res?.data[0].question_name);
    //     setQuestionType(res?.data[0].question_type);
    //   });
    // }
  }, [id]);

  useEffect(() => {
    const viewQuesSource = axios.CancelToken.source();

    const fetchViewAQuestion = async () => {
      try {
        const res = await ViewaQuestionCancelToken({ qId: id }, viewQuesSource.token);
        if(res.status == 200){
          setTitle(res?.data[0].question_name);
          setQuestionType(res?.data[0].question_type);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    if(id){
      fetchViewAQuestion();
    }
  
    return () => {
      viewQuesSource.cancel('Component unmounted');
    };
  }, [id])
  

  function getTheTabs(tabFor) {
    if (tabName === "questionBank" && ((!tab && !subtab) || tab === "audittrail")) {
      return (
        <div className="my-tickets-info-list Tickets-main-wrap">
          <div className={`my-tickets-heading-blk flex-direction mb-2`}>
            <div className="d-flex align-items-center ">
              <div className="name-w-head d-flex align-items-center ">
                <h4 className="text-left-align landing-heading heading_color_dynamic">
                  Question<span> Bank</span>
                </h4>
              </div>
              <div className="text-md-right action2-blk">
                <div className="ticket-view-btn d-flex align-items-center">
                  <div className="add-new-ticket-bx">
                    <button
                      onClick={() => history.goBack()}
                      title="Back"
                      className="btn btn-white-bordered"
                    >
                      <i className="fal fa-angle-left"></i>Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <div className="tabs-wrap">
            {/* {getTheTabs()} */}
            <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
              <li className="nav-item" onClick={() => history.push("/courseAdministration/smartAssessment/questionBank")}>
                <a className={`nav-link cursor-pointer ${tabName == "questionBank" && !tab ? "active" : ""}`} >
                 {GlobalIconNames("questionbank").html}
                </a>
              </li>
              <li className="nav-item" onClick={() => history.push("/courseAdministration/smartAssessment/questionBank/audittrail")}>
                <a className={`nav-link cursor-pointer ${tabName === "questionBank" && tab === "audittrail" ? "active" : ""}`} >
                  <i className="fal fa-history"></i>Audit trail
                </a>
              </li>
            </ul>
            <div className="tab-content" >
              <div className={"tab-pane fade active show"} >
                {tabName === "questionBank" && !tab && <QuestionBank />}
                {tabName === "questionBank" && tab === "audittrail" && <AuditTrail />}
              </div>
            </div>
          </div>
        </div >
      )
    } else {
      return (
        <>
          <div className="my-tickets-heading-blk flex-direction mb-2">
            <div className="d-flex align-items-center ">
              <div className="name-w-head d-flex align-items-center ">
                <h4 className="text-left-align landing-heading heading_color_dynamic">
                  {(subtab === "view" || subtab === "assignedQuizzes" || subtab === "audittrail") ? "Question:" : "Add"} {(subtab === "view" || subtab === "assignedQuizzes" || subtab === "audittrail") ? <span title={title} > {TrimText(title, 80)} </span> : <span>Question</span>}
                </h4>
              </div>
              <div className="text-md-right action2-blk">
                <div className="ticket-view-btn d-flex align-items-center">
                  <div className="add-new-ticket-bx">
                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                      <i className="fal fa-angle-left"></i>Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {subtab==="view" ?  
            <>{RenderQuestionTypeForm(questionType).html}</>
            :null}
          </div>
          <div className="tabs-wrap">
            {/* {getTheTabs()} */}
            <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
              {/* <li className="nav-item" onClick={() => history.push("/courseAdministration/smartAssessment/questionBank/question/view/" + id)}> */}
              <li className="nav-item">
                <Link className={`nav-link cursor-pointer ${tabName == "questionBank" && tab === "question" && subtab === "view" ? "active" : ""}`} to={"/courseAdministration/smartAssessment/questionBank/question/view/" + id} >
                  <i className="fal fa-users"></i>
                  Details
                </Link>
              </li>
              {/* <li className="nav-item" onClick={() => history.push("/courseAdministration/smartAssessment/questionBank/question/assignedQuizzes/" + id)}> */}
              <li className="nav-item" >
                <Link className={`nav-link cursor-pointer ${tabName == "questionBank" && tab === "question" && subtab === "assignedQuizzes" ? "active" : ""}`} to={"/courseAdministration/smartAssessment/questionBank/question/assignedQuizzes/" + id}>
                <i className="fal fa-file-alt"></i>
                  Assigned Quizzes
                </Link>
              </li>
              <li className="nav-item"
              //  onClick={() => history.push("/courseAdministration/smartAssessment/questionBank/audittrail")}
               >
                <Link className={`nav-link cursor-pointer ${tabName === "questionBank" && tab === "quiz" && subtab === "audittrail" ? "active" : ""}`} to={"/courseAdministration/smartAssessment/questionBank/quiz/audittrail/" + id}>
                  <i className="fal fa-history"></i>Audit trail
                </Link>
              </li>
            </ul>
            <div className="tab-content" >
              <div className={"tab-pane fade active show"} >
                {(subtab === "view" || subtab === "assignedQuizzes") && <ViewQuestion />}
                {/* {(tab == "question" && subtab === "duplicate" ) && <ViewQuestion />} */}
                {subtab === "audittrail" && <AuditTrail />}
                {/* {tabName === "questionBank" && !tab && <QuestionBank />}
                {tabName === "questionBank" && tab === "audittrail" && <AuditTrail />} */}
              </div>
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <>
      {/* {(subtab === "view" || subtab === "duplicate") ? (
        
      ) : ( */}
      {/* <> */}
      {/* <div className="my-tickets-info-list Tickets-main-wrap">
        <div className={`my-tickets-heading-blk flex-direction mb-2`}>
          <h4 className="text-left-align landing-heading heading_color_dynamic">
            Question<span> Bank</span>
          </h4>
        </div>
        <div className="tabs-wrap"> */}
      {getTheTabs()}
      {/* </div>
      </div > */}
      {/* </> */}
      {/* )} */}
    </>
  );
};

export default Tabs;
