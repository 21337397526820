import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { GetServerTime, AdjsutServerTime } from "../../../services/ServerService";
import moment from 'moment-timezone'
import { TABLE_DATE_TIME_FORMAT } from "../../../utils/Constants";

const Settings = () => {

  const history = useHistory();
  const [africaTime, setAfricaTime] = useState(moment().tz('Africa/Johannesburg').format(TABLE_DATE_TIME_FORMAT));
  const [loaderPosition, setLoaderPosition] = useState(0);

  useEffect(() => {
    getTime()
    formik.setFieldValue("STime", africaTime);
  }, []);

  const getTime = () => {
    setLoaderPosition(1);
    GetServerTime()
      .then((res) => {
        if (res?.status == 200) {
          if (res?.data?.res?.value) {
            formik.setFieldValue("hours", parseInt(res.data.res.value));
            formik.setFieldValue("ATime", (moment().tz('Africa/Johannesburg').add(parseInt(res.data.res.value), 'hours').format(TABLE_DATE_TIME_FORMAT)));
            setLoaderPosition(0)
          }
        }
      })
      .catch((err) => console.log(err));
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ATime: 'userData?.Email',
      STime: 'userData?.Password',
      hours: 0,
    },
    validationSchema: Yup.object({
      hours: Yup.number().required('hours field required').integer().min(-24, "Min -24 hours can be adjusted").max(24, "Max 24 hours can be adjusted")
    }),
    onSubmit: (values, { resetForm }) => {
      const body = {
        value: values.hours,
      };


      AdjsutServerTime(body)
        .then((res) => {
          getTime()
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
          // resetForm();
        })
        .catch((error) => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops!!!",
            text: "Something went wrong...",
          }).then((result) => { });
        });
    },
  });
  return (
    <>
      <div className="card card-profile-info-card">
        {/* <div className="card-header">Time Settings</div> */}
        <div >
          <div className="card-body-inr card-body-info">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label>Server Time</label>
                    <input
                      disabled
                      className={"form-control" + (formik.errors.STime && formik.touched.STime ? " is-invalid" : "")}
                      name="STime"
                      value={formik.values.STime}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.STime && formik.touched.STime ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label>Adjusted time</label>
                    <input
                      disabled
                      className={"form-control" + (formik.errors.ATime && formik.touched.ATime ? " is-invalid" : "")}
                      name="ATime"
                      value={formik.values.ATime}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.ATime && formik.touched.ATime ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label>Hours to adjust server time</label>
                    <input
                      type="number"
                      className={"form-control" + (formik.errors.hours && formik.touched.hours ? " is-invalid" : "")}
                      name="hours"
                      value={formik.values.hours}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.hours && formik.touched.hours ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                  {formik.touched.hours && formik.errors.hours ? <div className="invalid-feedback d-block">{formik.errors.hours}</div> : null}
                </div>
              </div>
              <div className="form-group form-group-save-cancel">
                <button className="btn btn-save btn-success" type="submit" title="Save" disabled={!(formik.dirty && formik.isValid)}>
                  {loaderPosition === 1 && <><i className="fas fa-cog fa-spin"></i>Save</>}
                  {loaderPosition !== 1 && <><i className="fal fa-save"></i>Save</>}
                </button>
                <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => formik.resetForm()}>
                  <i className="fal fa-times"></i>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
