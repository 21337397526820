import React from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getReRegisterationAuditFilters, getReRegisterationAuditFiltersCancelToken, getRegAuditData, getRegAuditDataCancelToken } from '../../../services/RegistrationService';
import AuditTrailsTable from '../../common/AuditTrailsTable';

function AuditTrails() {
const search = window.location.search;
const params = new URLSearchParams(search);
const studentId = +params.get('studentId');
  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]



  return (
    <AuditTrailsTable
      apiFunction={getRegAuditDataCancelToken}
      apiParams={{action_id: studentId}}
      columnsToShow={columnsToShow}
      filterArrApi={getReRegisterationAuditFiltersCancelToken}
      actionId={studentId}
      exportFileName={"Re-Registration_auditTrail"}
    />
  )
}

export default AuditTrails