import React, { useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { useLocation } from "react-router-dom";

const Courses = () => {
  const { pathname } = useLocation();
  const currentPath = pathname.split("/");

  const [phoneTabs, setPhoneTabs] = useState("phoneTab1")

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>

        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            <div className="sub-menu-right-block">

              <div className="wrap-box-right">
                {currentPath.includes("courseAdministration") &&
                  currentPath.includes("coursesDeonUnknown") && (
                    <div className="my-tickets-info-list Tickets-main-wrap">
                      <div className="my-tickets-heading-blk flex-direction mb-2">
                        <h4 className="text-left-align landing-heading heading_color_dynamic">
                          Courses
                        </h4>
                      </div>
                      <div className="phonesoft">
                        <div className="softphone">
                          <div className="softstatusback">
                            <div className="btnphoneclose"><i className="far fa-chevron-right"></i></div>
                            <div className="softstatusdot">&nbsp;</div>
                            <div className="softext">500</div>
                            <div className="softtimecall"><i className="fal fa-phone"></i> <span>03:34</span></div>
                            <div className="softactivity">Incoming</div>
                          </div>
                        </div>
                        <div className="phonecontent">
                          <div className="tabs-wrap">
                            <ul
                              className="nav nav-pills mb-4"
                              id="tabs"
                              role="tablist"
                            >
                              <li
                                className="nav-item"
                                onClick={() =>
                                  setPhoneTabs("phoneTab1")
                                }
                              >
                                <a
                                  className={
                                    "nav-link" + (phoneTabs === "phoneTab1" ? " active" : "")
                                  }
                                  id="pills-tab1"
                                  data-toggle="pill"
                                  href="#pills-tab11"
                                  role="tab"
                                  aria-controls="pills-tab1"
                                  aria-selected="false"
                                >
                                  <i class="fal fa-phone"></i>Phone
                                </a>
                              </li>
                              <li
                                className="nav-item"
                                onClick={() =>
                                  setPhoneTabs("phoneTab2")
                                }
                              >
                                <a
                                  className={
                                    "nav-link" +
                                    (phoneTabs === "phoneTab2" ? " active" : "")
                                  }
                                  id="pills-tab2"
                                  data-toggle="pill"
                                  href="#pills-tab22"
                                  role="tab"
                                  aria-controls="pills-tab2"
                                  aria-selected="true"
                                >
                                  <i class="fal fa-user"></i>Student
                                </a>
                              </li>
                              <li
                                className="nav-item"
                                onClick={() =>
                                  setPhoneTabs("phoneTab3")
                                }
                              >
                                <a
                                  className={
                                    "nav-link" + (phoneTabs === "phoneTab3" ? " active" : "")
                                  }
                                  id="pills-tab3"
                                  data-toggle="pill"
                                  href="#pills-tab33"
                                  role="tab"
                                  aria-controls="pills-tab3"
                                  aria-selected="false"
                                >
                                  <i class="fal fa-eye"></i>Netsuite
                                </a>
                              </li>
                              <li
                                className="nav-item plt4"
                                onClick={() =>
                                  setPhoneTabs("phoneTab4")
                                }
                              >
                                <a
                                  className={
                                    "nav-link" + (phoneTabs === "phoneTab4" ? " active" : "")
                                  }
                                  id="pills-tab1"
                                  data-toggle="pill"
                                  href="#pills-tab44"
                                  role="tab"
                                  aria-controls="pills-tab4"
                                  aria-selected="false"
                                >
                                  <i class="fal fa-cog"></i>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                              {/** this is the part which will render depending on phoneTabs condition */}
                              {phoneTabs == "phoneTab1" && <div>
                                <div>
                                  <div className="phonecaller">
                                    <div className="callerunknown"><i className="fal fa-user"></i></div>
                                    <div className="callertext"><span className="callername">Unknown Caller</span></div>
                                  </div>
                                  <form>
                                    <div className="pb-3"><input className="form-control" placeholder="Enter Cell Number"></input></div>
                                    <div><textarea className="form-control" rows="1" placeholder="Description"></textarea></div>
                                  </form>
                                </div>
                                <div className="phonekeypad">
                                  <div className="keypadsoft">1</div>
                                  <div className="keypadsoft">2</div>
                                  <div className="keypadsoft">3</div>
                                  <div className="keypadsoft">4</div>
                                  <div className="keypadsoft">5</div>
                                  <div className="keypadsoft">6</div>
                                  <div className="keypadsoft">7</div>
                                  <div className="keypadsoft">8</div>
                                  <div className="keypadsoft">9</div>
                                  <div className="keypadsoft"><i className="fal fa-asterisk"></i></div>
                                  <div className="keypadsoft">0</div>
                                  <div className="keypadsoft">R</div>
                                </div>
                                <div className="bottombuttons">
                                  <div className="bottombut"><i className="fal fa-volume-mute"></i></div>
                                  <div className="bottombut"><i className="fal fa-arrow-to-top"></i></div>
                                  <div className="bottombut activegreen"><i className="fal fa-phone"></i></div>
                                  <div className="bottombut"><i className="fal fa-pause"></i></div>
                                  <div className="bottombut"><i className="fal fa-user-plus"></i></div>
                                </div>
                                <div className="historyheader">History</div>
                                <div className="historyblock">
                                  <div className="historyblockheader">22 April 2022</div>
                                  <div className="historyblockcontent">
                                    <i className="historyblockig fal fa-arrow-to-right"></i><span className="historyblockname">Deon Erasmus</span><span className="historyblocktel">+27 71 620 6872</span>
                                    <i className="historyblockib fal fa-arrow-to-left"></i><span className="historyblockname">Deon Erasmus</span><span className="historyblocktel">+27 71 620 6872</span>
                                  </div>
                                </div>
                                <div className="historyblock">
                                  <div className="historyblockheader">23 April 2022</div>
                                  <div className="historyblockcontent">
                                    <i className="historyblockig fal fa-arrow-to-right"></i><span className="historyblockname">Deon Erasmus</span><span className="historyblocktel">+27 71 620 6872</span>
                                    <i className="historyblockib fal fa-arrow-to-left"></i><span className="historyblockname">Deon Erasmus</span><span className="historyblocktel">+27 71 620 6872</span>
                                  </div>
                                </div>
                              </div>}
                              {
                                phoneTabs == "phoneTab2" &&
                                <div>
                                  <form>
                                    <div className="form-group-blk">
                                      <div className="form-group-blk mb-3"><input className="form-control" placeholder="Name"></input></div>
                                      <div className="form-group-blk pb-3"><input className="form-control" placeholder="Surname"></input></div>
                                      <div className="form-group-blk pb-3"><input className="form-control" placeholder="Subject"></input></div>
                                      <div className="form-group-blk pb-3"><input className="form-control" placeholder="Student Number"></input></div>
                                      <div className="form-group-blk pb-3"><input className="form-control" placeholder="Phone Number"></input></div>
                                      <div className="form-group-blk pb-3"><input className="form-control" placeholder="Email Address"></input></div>
                                      <div className="form-group-blk pb-3"><select className="form-control">
                                        <option selected value="#">Department</option>
                                      </select></div>
                                      <div className="pb-3"><select className="form-control">
                                        <option selected value="#">Category</option>
                                      </select></div>
                                      <div><textarea className="form-control" rows="2" placeholder="Description"></textarea></div>
                                    </div>
                                    <div className="phonebuttons">
                                      <button
                                        className="btn btn-save btn-success"
                                        type="submit"
                                        title="Create Ticket">
                                        <i className="fal fa-save"></i>
                                        Create ticket
                                      </button>
                                    </div>
                                  </form>
                                  <div className="bottombuttonss">
                                    <div className="bottombut"><i className="fal fa-volume-mute"></i></div>
                                    <div className="bottombut"><i className="fal fa-arrow-to-top"></i></div>
                                    <div className="bottombutactive"><i className="fal fa-phone"></i></div>
                                    <div className="bottombut"><i className="fal fa-pause"></i></div>
                                    <div className="bottombut"><i className="fal fa-user-plus"></i></div>
                                  </div>
                                </div>
                              }
                              {
                                phoneTabs == "phoneTab3" && <div>
                                  <form>
                                    <div className="form-group-blk">
                                      <div className="form-group-blk mb-3"><input className="form-control" placeholder="Name"></input></div>
                                      <div className="form-group-blk pb-3"><input className="form-control" placeholder="Surname"></input></div>
                                      <div className="form-group-blk pb-3"><input className="form-control" placeholder="Email Address"></input></div>
                                      <div className="form-group-blk pb-3"><input className="form-control" placeholder="Phone Number"></input></div>
                                      <div><textarea className="form-control" rows="1" placeholder="Description"></textarea></div>
                                      <div className="netdate">20 September 2022&nbsp; &nbsp;03:56</div>
                                    </div>
                                    <div className="phonebuttons">
                                      <button
                                        className="btn btn-save btn-success"
                                        type="submit"
                                        title="Create Lead">
                                        <i className="fal fa-plus"></i>
                                        Create Lead
                                      </button>
                                    </div>
                                    <div className="accordph">
                                      <div className="accordianphone">
                                        <div className="accordiancoc">Customer Records </div>
                                        <i class="fal fa-chevron-down"></i>
                                      </div>
                                      <div className="accordianphone">
                                        <div className="accordiancoc">Opportunities </div>
                                        <i class="fal fa-chevron-down"></i>
                                      </div>
                                      <div className="accordianphone">
                                        <div className="accordiancoc">Contacts </div>
                                        <i class="fal fa-chevron-down"></i>
                                      </div>
                                    </div>
                                  </form>
                                  <div className="bottombuttonss">
                                    <div className="bottombut"><i className="fal fa-volume-mute"></i></div>
                                    <div className="bottombut"><i className="fal fa-arrow-to-top"></i></div>
                                    <div className="bottombutinactive"><i className="fal fa-phone"></i></div>
                                    <div className="bottombut"><i className="fal fa-pause"></i></div>
                                    <div className="bottombut"><i className="fal fa-user-plus"></i></div>
                                  </div>
                                </div>
                              }
                              {
                                phoneTabs == "phoneTab4" &&
                                <div className="settinglayout">
                                  <div className="settingtext">
                                    Mute Calls
                                  </div>
                                  <div className="settingtext">
                                    Notification Volume
                                  </div>
                                  <div className="settingtext">
                                    Call Volume
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                          <div>
                          </div>
                        </div>

                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;
