import React from "react";
import { useParams } from "react-router-dom";

import ExamSettingAdd from "./ExamSettingAdd";
import ExamSettingAuditTrail from "./ExamSettingAuditTrail";

function ExamSettingTab() {
  const { tab, type, id } = useParams();
  // console.log("detail page");
  return (
    <div>
      {type && type == "add" && !id && <ExamSettingAdd />}
      {type && type == "audit-trail" && <ExamSettingAuditTrail />}
      {/* {type && type == "add" && !id && <CustomerAdd />}
  {type && type == "edit" && id && <CustomerEdit customer_id={id} />}*/}
    </div>
  );
}

export default ExamSettingTab;
