import moment from "moment";
import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import {
  AssignSubjectToProgram,
  GetProgrammeModules,
  GetProgrammeModulesCancelToken,
  GetSubjectProgramAssignedOrNotAssignedListFilter,
} from "../../../../services/ProgrammeServices";
import Swal from "sweetalert2";
import Str from "../../../common/Str";
import PermissionsGate from "../../../../utils/permissionGate";
import {
  RenderCourseType,
  RenderLearningMethod,
} from "../../../../utils/CommonGroupingItem";
import Tablefilter from "../../../common/Tablefilter";
import { TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import { handleTableScroll } from "../../../../utils/commonFunction";
import axios from "axios";

const AvailableModules = ({ updateRow, setUpdateRow, dropdowns }) => {
  const { id } = useParams();

  const [deleteRow, setDeleteRow] = useState(false);

  const [assessmentList, setAssessmentList] = useState([]);
  const [isListLoaded, setIsListLoaded] = useState(true);
  
  const [intakeArr, setIntakeArr] = useState([]);
  const [learningMethodArr, setLearningMethodArr] = useState([]);
  const [courseListArr, setCourseListArr] = useState([]);
  
  const [search, setSearch] = useState("");
  const [courseType, setCourseType] = useState({ arr: [], checkObj: {} })
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} })
  const [intake, setIntake] = useState({ arr: [], checkObj: {} })
  const [filterData, setFilterData] = useState({learning : [], intake : [], type : []})

  useEffect(() => {
    handleTableScroll()
  }, [isListLoaded])

  useEffect(() => {
    let tempArr = []
    if (dropdowns) {
      setLearningMethodArr(dropdowns.study_type);
      setCourseListArr(dropdowns.type_course);
      dropdowns.intake_year && dropdowns.intake_year.length && dropdowns.intake_number && dropdowns.intake_number.length && dropdowns.intake_year.map(year => {
        dropdowns.intake_number.map(number => {
          tempArr.push({value: `${year.value}/${number.value}`, label: `${year.label}-${number.label}`})
        })
      })
    }
    setIntakeArr(tempArr)
  }, [dropdowns]);

  useEffect(() => {
    let listType=null;
    GetSubjectProgramAssignedOrNotAssignedListFilter(listType,id).then((res)=>{
      setFilterData({...res.data, learning : res.data.intakeTypeFilter, type : res.data.subjectTypeFilter, intake : res.data.yearsAndIntaksFilter})
    })
  }, [])

  useEffect(() => {
    const cancelTokenSources = [];

    const getProgramsList = async () => {
      setIsListLoaded(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await GetProgrammeModulesCancelToken(null, id, source.token);
        if (res.status === 200) {
          setAssessmentList(
            res.data.programs ? res.data.programs : []
          );
          setIsListLoaded(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsListLoaded(false);
        }
      }
    };
  
    getProgramsList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateRow]);

  const dataToRender = () => {
    let updatedData = [];
    let allData = assessmentList;
    if (search.length) {
      let tempNumber = allData.filter((item) => {
        let includes = item?.number?.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempName = allData.filter((item) => {
        let includes =
          item.name &&
          item.name.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempYear = allData.filter((item) => {
        let includes =
          item.year && item.year.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [...tempName, ...tempNumber, ...tempYear];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (courseType.arr.length) {
      let tempCourseType = updatedData;
      let tempResult = tempCourseType.filter((item) => {
        const startsWith = courseType.arr.find(function (post, index) {
          if (
            post.toLowerCase() ==
            (item.subject_type.toString().toLowerCase()
              ? item.subject_type.toString().toLowerCase()
              : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (learningMethod.arr.length) {
      let tempLearningMethod = updatedData;
      let tempResult = tempLearningMethod.filter((item) => {
        const startsMarking = learningMethod.arr.find(function (post, index) {
          if (
            post.toString().toLowerCase() ===
            (item.type.toString().toLowerCase()
              ? item.type.toString().toLowerCase()
              : "")
          )
            return item;
        });
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });

      updatedData = tempResult;
    }
    if (intake.arr.length) {
      let tempIntake = updatedData;
      let tempResult = tempIntake.filter((item) => {
        const startsIntakeYear = intake.arr.find(function (post, index) {
          if (post.toString() == (item.year && item.number && item.year.toString()
              ? item.year.toString() + "/" + item.number.toString()
              : "")
          )
            return item;
        });
        if (startsIntakeYear) {
          return startsIntakeYear;
        } else return null;
      });

      updatedData = tempResult;
    }

    return updatedData;
  };

  const handleAssignProgramme = (ID) => {
    const formData = new FormData();
    formData.append("intake", ID);
    formData.append("qualification", id);
    AssignSubjectToProgram(formData)
      .then((res) => {
        // setDeleteRow(!deleteRow);
        setUpdateRow(!updateRow);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
          timer: 3000,
        }).finally((res) => {
          // window.location.reload()
        });
      })
      .catch((err) => console.error("error :", err));
  };

  const sortingDate = (rowA, rowB) => {
    let date1 = new Date(
      rowA?.start?.toString().length == 10
        ? moment.unix(rowA.start).format(TABLE_DATE_FORMAT)
        : moment(parseInt(`${rowA.start}`)).format(TABLE_DATE_FORMAT)
    );
    let date2 = new Date(
      rowB?.start?.toString().length == 10
        ? moment.unix(rowB.start).format(TABLE_DATE_FORMAT)
        : moment(parseInt(`${rowB.start}`)).format(TABLE_DATE_FORMAT)
    );

    if (date2 > date1) {
      return 1;
    } else if (date2 < date1) {
      return -1;
    } else {
      return 0;
    }
  };
  const sortingDateEnd = (rowA, rowB) => {
    let date1 = new Date(
      rowA.end.toString().length == 10
        ? moment.unix(rowA.end).format(TABLE_DATE_FORMAT)
        : moment(parseInt(`${rowA.end}`)).format(TABLE_DATE_FORMAT)
    );
    let date2 = new Date(
      rowB.end.toString().length == 10
        ? moment.unix(rowB.end).format(TABLE_DATE_FORMAT)
        : moment(parseInt(`${rowB.end}`)).format(TABLE_DATE_FORMAT)
    );

    if (date2 > date1) {
      return 1;
    } else if (date2 < date1) {
      return -1;
    } else {
      return 0;
    }
  };

  const columns = useMemo(() => [
    // {
    //   name: "ID",
    //   selector: "id",
    //   sortable: true,
    //   // maxWidth: "80px",
    //   // minWidth: "80px",
    //   cell: (row) => (
    //     <div className="ticket-id">
    //       <span className="overflow-ellipsis2">
    //         <b>#{row.id}</b>
    //       </span>
    //     </div>
    //   ),
    // },
    {
      name: "Course Name",
      selector: "name",
      sortable: true,
      cell: (row) => {
        return (
          <>
            {row.name ? (
              <Link
                title={row.name}
                className="as-text-blue curser feature-name"
                href="javascript:void(0);"
                to={`/courseAdministration/coursesdetails/${row.course}/dashboard/show`}
              >
                <span title={row.name} className="textLimit100">{row.name}</span>
              </Link>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      name: "Learning Method",
      selector: "type",
      sortable: true,
      cell: (row) => (
        <p>{row.type ? RenderLearningMethod(row.type).html : "-"}</p>
      ),
    },
    // {
    //   name: "No",
    //   selector: "no",
    //   sortable: true,
    //   cell: (row, index) => (
    //     <b>{row.id}</b>
    //   )
    // },
    {
      name: "Start",
      selector: "start",
      sortable: true,
      sortFunction: sortingDate,
      cell: (row) => (
        row.start ? (
          <div className="dateTime">
            <span className="right-space">
              {row.start.toString().length == 10
                ? moment.unix(row.start).format(TABLE_DATE_FORMAT)
                : moment(parseInt(`${row.start}`)).format(TABLE_DATE_FORMAT)}
            </span>
            <span>
              {row.start.toString().length == 10
                ? moment.unix(row.start).format(TABLE_TIME_FORMAT)
                : moment(parseInt(`${row.start}`)).format(TABLE_TIME_FORMAT)}
            </span>
          </div>
        ) : (
          "-"
        )
      ),
    },
    {
      name: "End",
      selector: "end",
      sortable: true,
      sortFunction: sortingDateEnd,
      cell: (row) => (
        row.end ? (
          <div className="dateTime">
            <p className="right-space">
              {row.end.toString().length == 10
                ? moment.unix(row.end).format(TABLE_DATE_FORMAT)
                : moment(parseInt(`${row.end}`)).format(TABLE_DATE_FORMAT)}
            </p>
            <p>
              {row.end.toString().length == 10
                ? moment.unix(row.end).format(TABLE_TIME_FORMAT)
                : moment(parseInt(`${row.end}`)).format(TABLE_TIME_FORMAT)}
            </p>
          </div>
        ) : (
          "-"
        )
      ),
    },
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      cell: (row) => <p>{row.year ? row.year + "-" + row.number : "-"}</p>,
    },
    {
      name: "Course Type",
      selector: "subject_type",
      sortable: true,
      cell: (row) => (
        <p>
          {row.subject_type ? RenderCourseType(row.subject_type).html : "-"}
        </p>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["cview"]}
              RenderError={() => (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link
                className="btn btn-primary rounded-circle"
                title="Open"
                to={`/courseAdministration/coursesdetails/${row.course}/dashboard/show`}
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            <PermissionsGate
              scopes={["pcadd"]}
              errorProps={{ disabled: true }}
            >
              <button
                className="btn btn-primary rounded-circle"
                title="Add"
                onClick={() => handleAssignProgramme(row.course)}
              >
                <i className="fal fa-plus"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = [
      "Course Name",
      "Learning Method",
      "Start",
      "End",
      "Intake",
      "Course Type",
    ];
    data = data?.map((row) => ({
      ...row,
      // ID: row.id ? row.id : "",
      "Course Name": row?.name ? row?.name : "-",
      "Learning Method": row?.type ? RenderLearningMethod(row.type).text : "-",
      "Start": row?.start
        ? row.start.toString().length == 10
          ? moment.unix(row.start).format(TABLE_DATE_TIME_FORMAT)
          : moment(parseInt(`${row.start}`)).format(TABLE_DATE_TIME_FORMAT)
        : "-",
      "End": row?.end
        ? row.end.toString().length == 10
          ? moment.unix(row.end).format(TABLE_DATE_TIME_FORMAT)
          : moment(parseInt(`${row.end}`)).format(TABLE_DATE_TIME_FORMAT)
        : "-",
      Intake: row.number ? row.year + "-" + row.number : "-",
      "Course Type": row?.subject_type
        ? RenderCourseType(row.subject_type).text
        : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Course Name"],
          row["Learning Method"],
          row["Start"],
          row["End"],
          row["Intake"],
          row["Course Type"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        columnStyles: {
          0: {
            columnWidth: 45,
          },
          1: {
            columnWidth: 30,
          },
          2: {
            columnWidth: 20,
          },
          3: {
            columnWidth: 20,
          },
          4: {
            columnWidth: 20,
          },
          5: {
            columnWidth: 30,
          },
        },
        styles: {
          minCellHeight: 10,
          minCellWidth: 10,
          halign: "left",
          fontSize: 8,
        },
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const resetFilter = () => {
    setSearch("");
    setLearningMethod({ arr: [], checkObj: {} })
    setCourseType({ arr: [], checkObj: {} })
    setIntake({ arr: [], checkObj: {} })
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="custom-table-div filter-search-icon card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Learning Method"
                      optionArr={filterData.learning}
                      state={learningMethod}
                      setState={setLearningMethod}
                      renderLabelFunction={RenderLearningMethod}
                      uniqueId={"availableLearningMethod"}
                    />
                    <Tablefilter
                      filterName="Intake"
                      optionArr={filterData.intake}
                      state={intake}
                      setState={setIntake}
                      uniqueId={"availableIntake"}
                      isOptionReversed={true}
                    />
                    <Tablefilter
                      filterName="Course Type"
                      optionArr={filterData.type}
                      state={courseType}
                      setState={setCourseType}
                      renderLabelFunction={RenderCourseType}
                      uniqueId={"availableCourseType"}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export spreadsheet"
                      onClick={() => {
                        exportData("xlsx", "Available-Course");
                      }}
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export CSV"
                      onClick={() => {
                        exportData("csv", "Available-Course");
                      }}
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export PDF"
                      onClick={() => {
                        exportData("pdf", "Available-Course");
                      }}
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isListLoaded ? (
          <SkeletonTicketList />
        ) : (
          <DataTable
            data={dataToRender()}
            columns={columns}
            pagination={true}
            defaultSortField="start"
            defaultSortAsc={true}
            noDataComponent={Str.noRecord}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        )}
      </div>
    </div>
  );
};

export default AvailableModules;
