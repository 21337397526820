import React, { useState, useEffect } from "react";
import { FORM_DATE_FORMAT } from "../../../../../utils/Constants";
import DatePicker from "react-datepicker";
import { getStatement, getDebtCheck } from "../../../../../services/StudentsSettingServices";
import moment from "moment";
import PermissionsGate from "../../../../../utils/permissionGate";

function GenerateStatement({ studentData }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [customerIdNotFound, setCustomerIdNotFound] = useState(false);
  const [debtCheckInfo, setDebtCheckInfo] = useState(null);

  useEffect(() => {
    const currentDate = new Date();
    const calculateddefDate = new Date(currentDate);
    calculateddefDate.setDate(currentDate.getDate() - 90);
    setSelectedDate(calculateddefDate);
    if (!studentData?.netsuite_id) {
      setCustomerIdNotFound(true);
    } else {
      setCustomerIdNotFound(false);
      fetchDebtCheckInfo();
    }
  }, [studentData]);

  const fetchDebtCheckInfo = async () => {
    try {
      const response = await getDebtCheck({ id: studentData.netsuite_id ? studentData.netsuite_id : null });
      setDebtCheckInfo(response.data);
    } catch (error) {
      console.error("Error fetching debt check info:", error);
    }
  };

  const handleDownloadClick = async () => {
    try {
      setIsLoading(true);
      const formattedDate = moment(selectedDate).format("DD/MM/YYYY");
      const response = await getStatement({
        customerId: studentData.netsuite_id?studentData.netsuite_id:null,
        date: formattedDate,
      });
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      // Create a data URL for the PDF
      const pdfUrl = URL.createObjectURL(pdfBlob);
      // Open the PDF in a new tab/window
      window.open(pdfUrl, "_blank");
    } catch (error) {
      console.error("An error occurred during opening the PDF:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Generate Statement of Account</div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <label htmlFor="DateOfBirth">
            From{" "}
            <i
              className="fal fa-info-circle grade-icon"
              title="Please indicate the date from which the balance is required."
            ></i>
          </label>
          <div className="mb-4">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat={FORM_DATE_FORMAT}
              maxDate={new Date()}
              title="Date"
              placeholderText="Select Date"
              className="form-control w-100 cursor-pointer"
              showYearDropdown
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <div className="form-group-blk mb-3">
            <div className="form-group form-group-save-cancel">
            <PermissionsGate scopes={["sagen"]} errorProps={{ disabled: true }}>   
              <button
                className={`btn btn-save btn-success rtimg-icon-btn ${
                  isLoading || customerIdNotFound ? "disabled" : ""
                }`}
                type="button"
                title="Generate Statement"
                onClick={handleDownloadClick}
                disabled={isLoading || customerIdNotFound || (debtCheckInfo && (debtCheckInfo.HandedOver || debtCheckInfo.BadDebt))}
              >
                {isLoading ? "Generating..." : "Generate Statement"}
              </button>
              </PermissionsGate>
              <div className="invalid-feedback d-block">
              {customerIdNotFound && (
                <p className="text-danger mt-2 mb-0">Link to Netsuite not found</p>
              )}
              {(debtCheckInfo && (debtCheckInfo.HandedOver || debtCheckInfo.BadDebt)) && (
                  <p className="text-danger mt-2 mb-0">Statement generation has disabled for this student. Please contact finance for more information.</p>
                )}
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateStatement;