import React, { useContext, useRef, useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import LoginDetails from "../components/profile/blocks/LoginDetails";
import AboutYou from "../components/profile/blocks/AboutYou";
import ProfileAuditTrail from "../components/profile/blocks/ProfileAuditTrail";
import { GlobalIconNames } from "../utils/GlobalIconNames";

const NewProfilePage = () => {
  const [tab1,setTab1] = useState(true);
  const [tab2,setTab2] = useState(false);
  const [tab3,setTab3] = useState(false);

  const setTabs = (value) => {
    if(value === "tab1"){
      setTab1(true)
      setTab2(false)
      setTab3(false)
     
    }else if(value=== "tab2"){
      setTab1(false)
      setTab2(true)
      setTab3(false)
    }
    else if(value=== "tab3"){
      setTab1(false)
      setTab2(false)
      setTab3(true)
    }
  }



  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="profile-wrapper new-right-container">
            <div className="p-3">
              <div className="my-tickets-heading-blk flex-direction">
                <h4 className="text-left-align landing-heading heading_color_dynamic">
                  My <span>Profile</span>
                </h4>
              </div>
              <div className="tabs-wrap">
                <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                  <li className="nav-item" onClick={() => setTabs("tab1") }>
                    <a className="nav-link active" id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                      <i className="fal fa-info-circle"></i>About You
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => setTabs("tab2") }>
                    <a className="nav-link" id="pills-tab2" data-toggle="pill" href="#pills-tab22" role="tab" aria-controls="pills-tab2" aria-selected="false">
                      <i className="fal fa-user"></i>Login Details
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => setTabs("tab3") }>
                    <a className="nav-link" id="pills-tab3" data-toggle="pill" href="#pills-tab33" role="tab" aria-controls="pills-tab3" aria-selected="false">
                      {GlobalIconNames("audittrail").html}
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                   { tab1 && <AboutYou />}
                  </div>
                  <div className="tab-pane fade" id="pills-tab22" role="tabpanel" aria-labelledby="pills-tab2">
                   { tab2 && <LoginDetails />}
                  </div>
                  <div className="tab-pane fade" id="pills-tab33" role="tabpanel" aria-labelledby="pills-tab3">
                   { tab3 && <ProfileAuditTrail />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewProfilePage;
