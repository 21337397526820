import React from "react";
import { useParams } from "react-router-dom";
import NotificationForm from "./NotificationAdd";
import NotificationList from "./NotificationList";

function NotificationTab() {
  const { tab, type, id, subTab, subType } = useParams();
  
  return (
    <div>
      {(subTab == "notification" && subType != "add" && subType!="open" ) && <NotificationList />}
      {((subType == "add") || (subType=="open" && id)) && <NotificationForm />}

      {/* {type && type == "add" && !id && <CustomerAdd />}
  {type && type == "edit" && id && <CustomerEdit customer_id={id} />}*/}
    </div>
  );
}

export default NotificationTab;