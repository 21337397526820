import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { RenderRegistrationStatus } from "../../../../utils/CommonStatusItems";
import RenderPreviousValues from "./RenderPreviousValues";

function ApplicationStatus(props) {

  const getAppStatusVal = (value) => {
    if (value == 'new-status') {
      return "Closed Won"
    } else if (value == 'Final-Status') {
      return "Complete"
    } else if (value == 'In-Active') {
      return "[CL] In-Active"
    } else if (value == 'Rejected') {
      return "[CW] Rejected"
    } else if (value == 'Review') {
      return "[CW] Review"
    } else if (value == 'Preliminary Re-Registration') {
      return "Prelim Re-Reg Review"
    } else if (value == 'Re-Registration') {
      return "Re-Reg Request"
    } else if (value == 'Re-Registration New') {
      return "Re-Reg Request"
    } else if (value == 'Re-Registration Rejected') {
      return "Re-Reg Academic Rejected"
    } else if (value == 'Re-Registration-Final') {
      return "[CW] Re-Reg Complete"
    } else if (value == 'Re-Registration-RCO') {
      return "[CW] Re-Reg RCO"
    } else if (value == 'Re-Registration-Sales-Rejected') {
      return "Re-Registration Sales Rejected"
      // } else if (value == 'Re-Registration Rejected') {
      //   return "Rejected"
    } else if (value == "Re-Registration-Accounts Rejected") {
      return "Re-Reg Accounts Rejected"
    } else if (value == 'Re-Registration-Accounts') {
      return "Re-Reg Accounts"
    } else if (value == 'Re-Registration-Sales') {
      return "Re-Reg Called"
    } else if (value == "Re-Registration-Sales Invoiced") {
      return "Sales-Invoiced"
    } else if (value == 'Re-Registration-Sales Admin') {
      return "Re-Reg Closed Won"
    } else if (value == 'Re-Registration-Sales-Rejected') {
      return "[CW] Re-Reg Rejected"
    } else if (value == 'Re-Registration-Study Kit Pending') {
      return "[CW] Re-Reg Study Kit Pending"
    } else if (value == 'Registration Withdrawn') {
      return "[CL] Registration Withdrawn "
    } else if (value == 'Closed-Ordered' && props.studentData.re_reg_data.status != 'Drop Out') {
      return "[CW] Study Kit Pending"
    } else if (value == 'Closed-Ordered' && props.studentData.re_reg_data.status == 'Drop Out') {
      return "Registration Withdrawn"
    } else if (value == 'Re-Reg Withdrawn') {
      return "[CL] Re-Reg Withdrawn"    
    } else if (value == 'Re-Reg Transferred') {
      return "[Cw] Re-Reg Transferred"
    } else if (value == "Transferred")
    {
      return "[CW] Transferred";
    }
    else if (value == 'Closed-Ordered') {
      return "[CW] Study Kit Pending"
    } else if (value == 'Closed-Ordered2') {
      return "[CW] Study Kit Pending"
    } else return value;
  }

  useEffect(() => {
    let statusValue = props.studentData && props.studentData.re_reg_data && props.studentData.re_reg_data.status && RenderRegistrationStatus(props.studentData.re_reg_data.status).text
    formik.setFieldValue("ApplicationStatusLabel", statusValue)
  }, [props]);

  const formik = useFormikContext();

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">
          Application Status{" "}
          {props.disabled ? <i className="fas fa-cog fa-spin"></i> : null}</div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <label htmlFor="ApplicationStatus">Application Status *</label>
          <div className="form-icon-group mb-4">
            <input className={"form-control"} name="ApplicationStatusLabel" value={formik.values.ApplicationStatusLabel || ""} placeholder={formik.values.ApplicationStatusLabel ? formik.values.ApplicationStatusLabel : "Status"} disabled title="Application Status" />
            {formik.errors.ApplicationStatusLabel && formik.touched.ApplicationStatusLabel ? (
              <span className="exclamation">
                <i className="fal fa-exclamation-circle"></i>
              </span>
            ) : null}
          </div>
          <RenderPreviousValues status={props.studentData?.re_reg_data?.status} currentValue={props.studentData?.re_reg_data?.status} previousValue={props.studentData?.RegStudentsPreviousDetails?.status} type="renderFromFunction" typeSupportingProps={{renderFunction: RenderRegistrationStatus}} />
        </div>
        <div className="col-md-6 col-lg-4">
          <label>&nbsp;</label>
          <div className="form-group-blk mb-3" style={{ paddingTop: "11px" }}>
            <label></label>
            <div className="custom-check custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                id="toBeReceived"
                name="select"
                checked={formik.values.toBeReceived}
                onChange={(e) => {
                  formik.setFieldValue("toBeReceived", e.target.checked)
                  props.clickedCheck("to_be_reviewd", e.target.checked);
                }} />
              <label className="custom-control-label" htmlFor="toBeReceived">
                To Be Reviewed
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default ApplicationStatus