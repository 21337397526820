import React, { useEffect, useState } from "react";
import Select from "react-select";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import { useFormik } from "formik";
import { AddaQuestion, GetQuizTopics } from "../../../../services/SmartAssessmentService";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import $ from 'jquery';
import DisplayQuestion from "../DisplayQuestion";
import { useParams } from "react-router-dom";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { RenderPMCStatus } from "../../../../utils/CommonGroupingItem";
import HtmlParser from "react-html-parser";
import { IMAGE_URL } from "../../../../utils/Constants";
import { TrimText } from "../../../common/TrimText";
import RenderOption from "./RenderOption";
import PopupComponent from "../../../common/PopupComponent";

const questionType = [
    { value: "Multiple Choice Single Answer", label: "Multiple Choice Single Answer" },
    { value: "Multiple Choice Multiple Answer", label: "Multiple Choice Multiple Answer" },
    { value: "Match the Column", label: "Match The Column" },
    { value: "Short Answer", label: "Short Answer" },
    { value: "Long Answer", label: "Long Answer" },
    { value: "Fill in the blank", label: "Fill in the blank" },
    // { value: "Hotspot", label: "Hotspot" },
    { value: "Choice Matrix", label: "Choice Matrix" },
    { value: "Order List", label: "Order List" },
    // { value: "Equation", label: "Equation" },
    { value: "Document Upload", label: "Document Upload" },
];
const nqfOptions = [
    { label: "NQF 1", value: "NQF 1", data: "1" },
    { label: "NQF 2", value: "NQF 2", data: "2" },
    { label: "NQF 3", value: "NQF 3", data: "3" },
    { label: "NQF 4", value: "NQF 4", data: "4" },
    { label: "NQF 5", value: "NQF 5", data: "5" },
    { label: "NQF 6", value: "NQF 6", data: "6" },
    { label: "NQF 7", value: "NQF 7", data: "7" },
    { label: "NQF 8", value: "NQF 8", data: "8" },
    { label: "NQF 9", value: "NQF 9", data: "9" },
    { label: "NQF 10", value: "NQF 10", data: "10" },
];
const statusArr = [
    {
        value: "Draft",
        label: HtmlParser(`<span class="red" title="Draft">Draft</span> `)
    },
    {
        value: "New",
        label: HtmlParser(`<span class="skyblue" title="New">New </span>`)
    },
    {
        value: "Final",
        label: HtmlParser(`<span class="green" title="Final">Final</span>`)
    },
    {
        value: "Approved",
        label: HtmlParser(`<span class="yellowshade" title="Approved">Approved</span>`)
    },
    {
        value: "Inactive",
        label: HtmlParser(`<span class="blue" title="Inactive">Inactive</span>`)
    },
]
const level = [
    { value: "1", label: "Easy" },
    { value: "2", label: "Medium" },
    { value: "3", label: "Hard" },
];
const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        let background = RenderPMCStatus(data.value).bgColor;
        let color = RenderPMCStatus(data.value).colorCode;

        return {
            ...styles,
            backgroundColor: background,
            color: color,
            cursor: isDisabled ? 'not-allowed' : 'default',
        };
    },
};
const multiSelectColorStyles = {
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        '& span': {
            background: 'transparent !important',
            color: '#333 !important',
        }
    }),
};

function QuestionForm({setQuestionType}) {

    const { id } = useParams();
    const history = useHistory();
    const givenPermsisions = useSelector((state) => state.givenPermission);

    const [show, setShow] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [quizTopics, setQuizTopics] = useState([]);
    const [questionEditor, setQuestionEditor] = useState("");
    const [descriptionEditor, setDescriptionEditor] = useState("");
    const [optionEditor, setOptionEditor] = useState({});
    const [equationEditor, setEquationEditor] = useState("");
    const [checkedAns, setCheckedAns] = useState("customCheck0");
    const [matrixData, setMatrixData] = useState([]);
    const [fillOption, setFillOption] = useState([]);
    const [orderState, setOrderState] = useState({});
    const [uploadDoc, setUploadDoc] = useState([]);
    const [uploadDocName, setUploadDocName] = useState("");
    const [previewQuestionData, setPreviewQuestionData] = useState({});
    const [ckstate, setCkState] = useState({})
    // const [fileList, setFileList] = useState(null);
    const [selectedAns, setselectedAns] = useState("customCheck0");

    
    const questionTypeFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            selectedOption: "",
            selectedColumn: "",
            isNeeded: true,
            questionType: "Multiple Choice Single Answer",
        },
        validationSchema: Yup.object({
            questionType: Yup.string().required("Please select Question Type").nullable(),
            selectedOption: Yup.string()
                .when("isNeeded", {
                    is: true,
                    then: Yup.string().required("Please enter A Valid Number").matches(/^[0-9]+$/, "No of Option Must be only digits").test("Is positive?", "No of Option must be between One and Ten", value => parseInt(value) > 0 && parseInt(value) < 11),
                }),
            selectedColumn: Yup.string()
                .when("questionType", {
                    is: "Choice Matrix",
                    then: Yup.string().required("Please enter A Valid Number for Columns").matches(/^[0-9]+$/, "No of Option Must be only digits").test("Is positive?", "No of Option must be between One and Ten", value => parseInt(value) > 0 && parseInt(value) < 11),
                }),
        }),
        onSubmit: (values) => {
            if (values.questionType == "Multiple Choice Single Answer" || values.questionType === "Multiple Choice Multiple Answer" || values.questionType === "Match The Column" || values.questionType === "Match the Column") {
                let arr = Array(parseInt(values.selectedOption)).fill({ q_option: "", q_option_match: "" })
                let final = arr.map((item, index) => {
                    let tempVal = { ...item, id: index }
                    return tempVal
                })
                formik.setFieldValue("matchItemArr", final)
            } else if (values.questionType == "Choice Matrix") {
                setMatrixData(initialMatrixData(values.selectedOption, values.selectedColumn))
            } else if (values.questionType == "Fill in the blank") {
                setFillOption(Array(parseInt(values.selectedOption)).fill([]))
            }
            setShow(false)
        },
    });

    const formik = useFormik({
        // enableReinitialize : true,
        initialValues: {
            question_name: "",
            question_type: "Multiple Choice Single Answer",
            question: questionEditor,
            cid: "1",
            lid: "",
            levelname: "",
            optionEditor: "",
            isSaving: false,
            matchItemArr: [],
            shortAnswer: [],
            nqf_level: "",
            nqf_level_label: "",
            status: "",
            keywords: [],
            score: 1,
            topic: [],
            document: []
        },
        validationSchema: Yup.object({
            question_name: Yup.string().required('Name is required'),
            keywords: Yup.array().min(1, "Atleast one Keyword is required").required("Keyword is required").nullable(),
            topic: Yup.array().min(1, "Atleast one Topic is required").required("Topic is required").nullable(),
            status: Yup.string().required('Status is required'),
            question: Yup.string().test("required", "Question is Required", (value) => {
                const tempQuestionEditor = questionEditor;
                let isInvalid = (!tempQuestionEditor)
                return !isInvalid;
            }),
            score: Yup.string().required('Score is required'),
            optionEditor: Yup.string().test("required", "Option is required", (value) => {
                let isValid = true;
                if (questionTypeFormik.values.questionType === "Multiple Choice Multiple Answer" || questionTypeFormik.values.questionType === "Multiple Choice Single Answer") {
                    isValid = Object.keys(optionEditor).length == (questionTypeFormik.values.selectedOption)
                    Object.keys(optionEditor).map((key, index) => {
                        if (index && isValid) {
                            isValid = optionEditor[key] !== ""
                        }
                    });
                    if (!isValid) {
                        return isValid
                    }
                }
                if (questionTypeFormik.values.questionType === "Multiple Choice Multiple Answer") {
                    isValid = !(!checkedAns.length) // two times ! will return a true or false value instead of thruthy and falsy value
                    return isValid
                } else if (questionTypeFormik.values.questionType === "Multiple Choice Single Answer") {
                    isValid = !(!checkedAns) // two times ! will return a true or false value instead of thruthy and falsy value
                    return isValid
                } else if (questionTypeFormik.values.questionType === "Equation") {
                    return equationEditor !== ""
                } else if (questionTypeFormik.values.questionType === "Order List") {
                    Object.keys(optionEditor).map((key, index) => {
                        if (isValid) {
                            isValid = optionEditor[key] !== ""
                        }
                    });
                    if (!isValid) {
                        return isValid
                    }
                } else if (questionTypeFormik.values.questionType === "Choice Matrix") {
                    isValid = false
                    let tempValueCheck = []
                    matrixData.map(item => {
                        let isTrue = false
                        if (item?.options?.length) {
                            item.options.map(val => {
                                if (!isTrue) {
                                    isTrue = val.isSelected
                                }
                            })
                        }
                        tempValueCheck.push(isTrue);
                    })
                    if (tempValueCheck.includes(false)) {
                        return isValid
                    } else {
                        return true
                    }
                } else if (questionTypeFormik.values.questionType === "Match The Column" || questionTypeFormik.values.questionType === "Match the Column") {
                    formik.values.matchItemArr.map((item) => {
                        if (item.q_option == "" || item.q_option_match == "") {
                            isValid = false
                            return isValid
                        }
                    })
                    return isValid;
                }
                return isValid
            }),
            shortAnswer: Yup.string()
                .when("question_type", {
                    is: "Short Answer",
                    then: Yup.string().required("Possible Answers is required").trim(),
                }),
        }),
        onSubmit: async (values) => {
            if (values.isSaving) {
                if (questionTypeFormik.values.questionType === "Fill in the blank") {
                    let inputCount = questionEditor.split("___").length-1;
                    console.log("inside if >>>> input count", inputCount, fillOption, inputCount>fillOption)
                    if(inputCount > fillOption.length){
                        formik.setErrors({question: "Question can't contain input field more than number of options, Please remove one ore more ___ occurrence."})
                        return;
                    }
                }
                setDisabled(true);
                const editorJSON1 = questionEditor;
                const editorJSON2 = descriptionEditor;
                if (questionTypeFormik.values.questionType === "Multiple Choice Multiple Answer") {
                    let val = [];
                    Object.keys(optionEditor).map((key, index) => {
                        val.push({
                            q_option: optionEditor[key],
                            score: checkedAns.includes(key.toString()) ? `${values.score / checkedAns.length}` : "0",
                        });
                    });
                    values = { ...values, optArr: val, question: editorJSON1, description: editorJSON2 };
                } else if (questionTypeFormik.values.questionType === "Multiple Choice Single Answer") {
                    let val = [];
                    Object.keys(optionEditor).map((key, index) => {
                        val.push({
                            q_option: optionEditor[key],
                            score: (selectedAns.toString() === key.toString() ? values.score : "0"),
                        });
                        // }
                    });
                    values = { ...values, optArr: val, question: editorJSON1, description: editorJSON2 };
                } else if (questionTypeFormik.values.questionType === "Order List") {
                    let val = [];
                    Object.keys(ckstate).map((key, index) => {
                        val.push({
                            q_option: ckstate[key],
                            score: values.score / Object.keys(ckstate).length
                        });
                        // }
                    });
                    values = { ...values, optArr: val, question: editorJSON1, description: editorJSON2 };
                }

                else if (questionTypeFormik.values.questionType === "Match The Column" || questionTypeFormik.values.questionType === "Match the Column") {
                    let val = [];
                    let valuelength = values.matchItemArr.filter((item) => item.q_option != undefined)
                    values.matchItemArr.map((item) => {
                        val.push({ ...item, score: item.q_option !== undefined ? `${values.score / valuelength.length}` : "0" })
                        // val.push({ ...item, score: `${values.score / values.matchItemArr.length}` })
                    })
                    values = { ...values, optArr: val, question: editorJSON1, description: editorJSON2 };
                } else if (questionTypeFormik.values.questionType === "Short Answer") {
                    values = { ...values, shortAnswer: values.shortAnswer.join("~@~"), question: editorJSON1, description: editorJSON2, optArr: [{ q_option: values.shortAnswer.join("~@~"), score: values.score }] };
                } else if (questionTypeFormik.values.questionType === "Equation") {
                    values = { ...values, question: editorJSON1, description: editorJSON2, optArr: [{ q_option: equationEditor, score: values.score }] };
                } else if (questionTypeFormik.values.questionType === "Fill in the blank") {
                    let optionArr = fillOption.map(item => {
                        return {
                            q_option: JSON.stringify(item.map(val => {
                                return val
                            })),
                            score: values.score / fillOption.length
                        }
                    })
                    values = { ...values, question: editorJSON1, description: editorJSON2, optArr: optionArr };
                } else if (questionTypeFormik.values.questionType === "Choice Matrix") {
                    let optionArr = matrixData.map(item => {
                        return {
                            q_option: JSON.stringify(item.options.map(val => {
                                return { isSelected: val.isSelected, score: val.isSelected ? values.score / matrixData.length : 0, titleValue: val.titleValue }
                            })),
                            score: values.score / matrixData.length,
                            titleValue: item.titleValue
                        }
                    })
                    values = { ...values, question: editorJSON1, description: editorJSON2, optArr: optionArr };
                } else {
                    values = { ...values, question: editorJSON1, description: editorJSON2 };
                }
                if (id) {
                    values = { ...values, assessment_id: id };
                }
                
                // await formik.setFieldValue("document", uploadDoc);
                values.document = uploadDoc
                values.cid = Number(values.cid)
                values.lid = Number(values.lid)
                values.keywords = JSON.stringify(values.keywords);
                values.topic = (values.topic.map(item => { return item?.value })).join(",");
                var data = new FormData();
                Object.keys(values).map(key => {
                    if (key == "optArr") {
                        values[key].forEach((item, index) => {
                            for (let k in item) {
                                data.append(`${key}[${index}][${k}]`, item[k]);
                            }
                        });
                    }else if(key === "document"){
                        values.document.map((uploadFile)=>{
                            data.append("document[]", uploadFile)
                        })
                    }else if(key!="matchItemArr"){
                        data.append(key, values[key])
                    }
                })
                // uploadDoc.map((item,index) => {
                //     data.append(index, JSON.stringify(item))
                // })
                AddaQuestion(data)
                    .then((res) => {
                        Swal.fire({ icon: "success", title: "Success", text: "Added Successfully" });
                        setDisabled(false);
                        history.push("/courseAdministration/smartAssessment/questionBank");
                    })
                    .catch((err) => {
                        Swal.fire({ icon: "error", title: "Error", text: err.response.data.message });
                        setDisabled(false);
                    });
            } else {
                const editorJSON1 = questionEditor;
                const editorJSON2 = descriptionEditor;
                if (questionTypeFormik.values.questionType === "Multiple Choice Multiple Answer") {
                    let val = [];
                    Object.keys(optionEditor).map((key, index) => {
                        if (index + 1) {
                            val.push({
                                q_option: HtmlParser(optionEditor[key]),
                                score: checkedAns.includes(key.toString()) ? `${1 / checkedAns.length}` : "0",
                            });
                        }
                    });
                    values = { ...values, options: val, question: editorJSON1, description: editorJSON2 };
                } else if (questionTypeFormik.values.questionType === "Multiple Choice Single Answer") {
                    let val = [];
                    Object.keys(optionEditor).map((key, index) => {
                        // if (index) {
                        val.push({
                            q_option: HtmlParser(optionEditor[key]),
                            score: (checkedAns.toString() === key.toString() ? "1" : "0"),
                        });
                        // }
                    });
                    values = { ...values, options: val, question: editorJSON1, description: editorJSON2 };
                } else if (questionTypeFormik.values.questionType === "Match The Column" || questionTypeFormik.values.questionType === "Match the Column") {
                    let val = [];
                    values.matchItemArr.map((item) => {
                        val.push({ ...item, score: `${1 / values.matchItemArr.length}` })
                    })
                    values = { ...values, options: val, question: editorJSON1, description: editorJSON2 };
                } else if (questionTypeFormik.values.questionType === "Short Answer") {
                    values = { ...values, question: editorJSON1, description: editorJSON2, options: [{ q_option: values.shortAnswer, score: "1" }] };
                }else if (questionTypeFormik.values.questionType === "Choice Matrix") {
                    let optionArr = matrixData.map(item => {
                        return {
                            q_option: JSON.stringify(item.options.map(val => {
                                return { isSelected: val.isSelected, score: val.isSelected ? values.score / matrixData.length : 0, titleValue: val.titleValue }
                            })),
                            score: values.score / matrixData.length,
                            titleValue: item.titleValue
                        }
                    })
                    values = { ...values, question: editorJSON1, description: editorJSON2, options: optionArr };
                }else if (questionTypeFormik.values.questionType === "Order List") {
                    let val = [];
                    Object.keys(ckstate).map((key, index) => {
                        val.push({
                            q_option: ckstate[key],
                            score: values.score / Object.keys(ckstate).length
                        });
                        // }
                    });
                    values = { ...values, options: val, question: editorJSON1, description: editorJSON2 };
                }else {
                    values = { ...values, question: editorJSON1, description: editorJSON2 };
                }
                $("#previewQuestion").modal('show');
                setPreviewQuestionData(values)
            }
        },
    });


    const initialMatrixData = (rowCount, optionCount) => {
        let tempMatrixData = [];
        for (let i = 1; i <= rowCount; i++) {
            const rowTitle = <input type="text"
                className={"form-control"}
                name="item"
                placeholder="Row"
                title="Row"
                value={matrixData[i]?.titleValue}
                onChange={(e) => {
                    handleUpdateRowName(e, i, tempMatrixData)
                }}
            />;
            const options = [];
            for (let j = 1; j <= optionCount; j++) {
                options.push({
                    text: <input type="text"
                        className={"form-control"}
                        name="item"
                        placeholder="Column"
                        title="Column"
                        value={matrixData[i]?.options[j].titleValue}
                        onChange={(e) => {
                            handleUpdateColumnName(e, i, tempMatrixData, j)
                        }}
                    />, isSelected: false
                });
            }
            tempMatrixData.push({ rowTitle, titleValue: "", options });
        }
        return tempMatrixData;
    };

    const handleUpdateRowName = (e, index, matrixData22) => {
        matrixData22[index - 1].titleValue = e.target.value
    };

    const handleUpdateColumnName = (e, index, matrixData22, secondIndex) => {
        matrixData22.map(item => {
            return item.options[secondIndex - 1].titleValue = e.target.value
        })
    };

    const handleKeywords = (event) => {
        var keyword = formik.values.keywords;
        if (event.key === "Enter") {
            if (event.target.value) {
                keyword.push(event.target.value);
                event.target.value = ""
                formik.setFieldValue("keywords", keyword);
            }
        }
    };

    const handleRemoveKeywords = (index) => {
        let keyword = [...formik.values.keywords];
        keyword.splice(index, 1);
        formik.setFieldValue('keywords', keyword)
        // setFillOption([...keyword])
    };


    const removeOptionAnswer = (ind, ele, index) => {
        let deleteoption = fillOption
        deleteoption[ind].splice(index, 1)
        setFillOption([...fillOption])
    };


    const handleRemoveShortAnswer = (index) => {
        let keyword = formik.values.shortAnswer;
        keyword.splice(index, 1)
        formik.setFieldValue("shortAnswer", keyword);
    };

    const handleAddShortAnswer = (event) => {
        let answers = formik.values.shortAnswer;
        if (event.key === "Enter") {
            if (event.target.value) {
                answers.push(event.target.value);
                event.target.value = ""
                formik.setFieldValue("shortAnswer", answers);
            }

        }
    };

    const handleDeleteDoc = (name,docName) => {
        formik.setFieldValue("document", "");
        const filterData = uploadDoc.filter(item => 
            item?.name != name
        )
        setUploadDoc(filterData);
        const filterName = uploadDocName.filter(item => 
            item != docName
        )
        setUploadDocName(filterName)
    };

    const handleOptionSelect = (rowIndex, optionIndex) => {
        const updatedMatrixData = [...matrixData];
        updatedMatrixData[rowIndex].options.forEach((option, index) => {
            if (index === optionIndex) {
                option.isSelected = true;
            } else {
                option.isSelected = false;
            }
        });
        setMatrixData(updatedMatrixData);
    };
    const files = [];
    // const data = new FormData();
    // files.forEach((file, i) => {
    //     data.append(`file-${i}`, file, file.name);
    // });
    useEffect(() => {
        GetQuizTopics().then(res => {
            let tempArr = res?.data?.map(item => {
                return { value: item?.id, label: HtmlParser(`<span style="color:#fff;background:${item?.color};" title=${item?.options}>${item?.options}</span>`) }
            });
            setQuizTopics(tempArr);
        }).catch(error => console.error("GetQuizTopics", error));
    }, []);

    useEffect(() => {
        let response = hasPermission({ scopes: ["cquestionbadd"], permissions: givenPermsisions });
        if (!response) {
            history.push("/noaccess");
        }
    }, []);



    return (
        <div className="card card-profile-info-card course-details-tab-sec">
            {show ? (
                <form>
                    <div className="course-info-sec">
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="form-icon-group mb-4" title="Select Question Type">
                                    <label>Select Question Type *</label>
                                    <Select
                                        className="form-control custom-select-box"
                                        name="questionType"
                                        value={questionType?.filter(val => val.value === questionTypeFormik.values.questionType)}
                                        onChange={(value) => {
                                            if (value) {
                                                questionTypeFormik.setFieldValue("questionType", value.label);
                                                setQuestionType(value.value);
                                                formik.setFieldValue("question_type", value.value);
                                                if (value.value == "Multiple Choice Single Answer") {
                                                    setCheckedAns("customCheck0")
                                                } else {
                                                    setCheckedAns([])
                                                }
                                                if (value.value == "Multiple Choice Single Answer" || value.value === "Multiple Choice Multiple Answer" || value.value === "Match The Column" || value.value === "Match the Column" || value.value === "Order List" || value.value === "Fill in the blank" || value.value === "Choice Matrix") {
                                                    questionTypeFormik.setFieldValue("isNeeded", true)
                                                } else {
                                                    questionTypeFormik.setFieldValue("isNeeded", false)
                                                }
                                            } else {
                                                setQuestionType("");
                                                questionTypeFormik.setFieldValue("questionType", "");
                                                formik.setFieldValue("question_type", "");
                                                questionTypeFormik.setFieldValue("isNeeded", false);
                                            }
                                        }}
                                        options={questionType}
                                        maxMenuHeight={175}
                                        placeholder={questionTypeFormik.values.questionType ? questionTypeFormik.values.questionType : "Select"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                {questionTypeFormik.values.questionType === "Short Answer" || questionTypeFormik.values.questionType === "Long Answer" || questionTypeFormik.values.questionType === "" || questionTypeFormik.values.questionType === "Equation" || questionTypeFormik.values.questionType === "Document Upload" ? "" : (
                                    <div className="form-icon-group mb-4">
                                        {questionTypeFormik.values.questionType === "Choice Matrix" ? 
                                        <label htmlFor="options">No of Row *</label>
                                        :<label htmlFor="options">No of Option *</label>
                                        }
                                        <input
                                            type="number"
                                            className={"form-control arrowshow input " + (questionTypeFormik.errors.selectedOption && questionTypeFormik.touched.selectedOption ? " is-invalid" : "")}
                                            name="options"
                                            id="options"
                                            title={questionTypeFormik.values.questionType === "Choice Matrix" ? "No of Row" : "No of Option"}
                                            value={questionTypeFormik.values.selectedOption}
                                            placeholder="Enter a Valid Number"
                                            onChange={(e) => { questionTypeFormik.setFieldValue("selectedOption", e.target.value) }}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6 col-lg-4">
                                {questionTypeFormik.values.questionType === "Choice Matrix" ? (
                                    <div className="form-icon-group mb-4">
                                        <label htmlFor="options">No of Column *</label>
                                        <input
                                            type="number"
                                            className={"form-control arrowshow input " + (questionTypeFormik.errors.selectedColumn && questionTypeFormik.touched.selectedColumn ? " is-invalid" : "")}
                                            name="options"
                                            id="options"
                                            title="No of Column"
                                            value={questionTypeFormik.values.selectedColumn}
                                            placeholder="Enter a Valid Number"
                                            onChange={(e) => { questionTypeFormik.setFieldValue("selectedColumn", e.target.value) }}
                                        />
                                    </div>
                                ) : ""}
                            </div>
                        </div>
                    </div>
                    <div className="form-group form-group-save-cancel">
                        <button type="button" className="btn btn-primary" onClick={questionTypeFormik.handleSubmit} title="Next">
                            <i className="fal fa-arrow-right"></i>
                            Next
                        </button>
                        {Object.keys(questionTypeFormik.values).map(key => {
                            if (questionTypeFormik.touched[key] && questionTypeFormik.errors[key]) {
                                return (
                                    <div className="invalid-feedback d-block">
                                        {questionTypeFormik.errors[key]}
                                    </div>
                                )
                            }
                        })}
                    </div>
                </form>
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="course-info-sec">
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className="form-icon-group mb-4">
                                    <label htmlFor="question_name">Name *</label>
                                    <input
                                        type="text"
                                        className={"form-control" + (formik.errors.question_name && formik.touched.question_name ? " is-invalid" : "")}
                                        name="question_name"
                                        id="question_name"
                                        title="Name"
                                        value={formik.values.question_name}
                                        placeholder="Name"
                                        onChange={(e) => formik.setFieldValue("question_name", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2">
                                <div className="form-icon-group mb-4" title="NQF Level">
                                    <label>NQF Level</label>
                                    <Select
                                        className={"form-control custom-select-box custom-profile-page" + (formik.errors.nqf_level_label && formik.touched.nqf_level_label ? " is-invalid" : "")}
                                        name="nqf_level_label"
                                        value={nqfOptions?.filter(val => val.value === formik.values.nqf_level_label)}
                                        onChange={(value) => {
                                            if (value) {
                                                formik.setFieldValue("nqf_level_label", value.value);
                                                formik.setFieldValue("nqf_level", value.data);
                                            } else {
                                                formik.setFieldValue("nqf_level_label", "");
                                                formik.setFieldValue("nqf_level", "");
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={nqfOptions}
                                        maxMenuHeight={175}
                                        placeholder={formik.values.nqf_level_label ? formik.values.nqf_level_label : "Select"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2">
                                <div className="form-icon-group mb-4" title="Level">
                                    <label>Level</label>
                                    <Select
                                        className={"form-control custom-select-box custom-profile-page" + (formik.errors.lid && formik.touched.lid ? " is-invalid" : "")}
                                        name="lid"
                                        value={level?.filter(val => val.value === formik.values.lid)}
                                        onChange={(value) => {
                                            if (value) {
                                                formik.setFieldValue("lid", value.value);
                                                formik.setFieldValue("levelname", value.label);
                                            } else {
                                                formik.setFieldValue("lid", "");
                                                formik.setFieldValue("levelname", "");
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={level}
                                        maxMenuHeight={175}
                                        placeholder={formik.values.lid ? formik.values.levelname : "Select"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2">
                                <div className="form-icon-group mb-4" title="Status">
                                    <label>Status *</label>
                                    <Select
                                        styles={colourStyles}
                                        className={"form-control color-drop custom-select-box" + (formik.errors.status && formik.touched.status ? " is-invalid" : "")}
                                        name="status"
                                        value={statusArr?.filter(val => val.value === formik.values.status)}
                                        onChange={(value) => {
                                            if (value) {
                                                formik.setFieldValue("status", value.value)
                                            } else {
                                                formik.setFieldValue("status", "")
                                            }
                                        }}
                                        // menuIsOpen={true}
                                        onBlur={formik.handleBlur}
                                        options={statusArr}
                                        maxMenuHeight={175}
                                        placeholder={formik.values.status ? formik.values.status : "Select"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-icon-group mb-4">
                                    <label htmlFor="score">Score *</label>
                                    <input
                                        type="text"
                                        className={"form-control" + (formik.errors.score && formik.touched.score ? " is-invalid" : "")}
                                        name="score"
                                        id="score"
                                        title="Score"
                                        value={formik.values.score}
                                        placeholder="Score"
                                        onChange={(e) => formik.setFieldValue("score", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 mb-4">
                                <label>Keywords *</label>
                                <div className="form-icon-group">
                                    <div className="form-control custom-select-box">
                                        <input
                                            type="text"
                                            className={"form-control " + (formik.errors.keywords && formik.touched.keywords ? " is-invalid" : "")}
                                            name="keywords"
                                            placeholder="Keywords"
                                            title="Keywords"
                                            onBlur={formik.handleBlur}
                                            onKeyDown={handleKeywords}
                                        />
                                        {formik.errors.keywords && formik.touched.keywords ? (<span className="exclamation"><i className="fal fa-exclamation-circle"></i></span>) : null}
                                    </div>
                                </div>
                                {formik.values.keywords.length ? (
                                    <ul className="tag-list mb-0">
                                        {formik.values.keywords?.map((ele, index) => (
                                            <li key={index}>
                                                {ele}
                                                <span onClick={() => handleRemoveKeywords(index)}>
                                                    <i className="fal fa-times"></i>
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                ) : ""}
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3">
                                    <label htmlFor="Topic">Topic *</label>
                                    <Select
                                        styles={multiSelectColorStyles}
                                        className={"form-control color-drop custom-select-box is-multi" + (formik.errors.topic && formik.touched.topic ? " is-invalid" : "")}
                                        name="topic"
                                        value={formik.values.topic}
                                        onChange={(value) => formik.setFieldValue("topic", value)}
                                        onBlur={formik.handleBlur}
                                        options={quizTopics}
                                        maxMenuHeight={175}
                                        isMulti={true}
                                        placeholder="Select Topic"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-icon-group d-block">
                        <label>Question *
                            {formik.values.question_type == "Fill in the blank" ?
                                <div className="text-primary"><i style={{top:'unset',right:'unset', position:'unset'}} className="fal fa-info-circle"></i> To specify a blank area, add 3 underscores, ex I will be ___ years old  in the year___</div> : ""}
                        </label>
                        <HtmlInputEditor editorState={questionEditor} setEditorState={setQuestionEditor} hideSign={true} isCKEditor={true} from="quizManagement" />
                    </div>
                    <div className="form-icon-group d-block">
                        <label>Description</label>
                        <HtmlInputEditor editorState={descriptionEditor} setEditorState={setDescriptionEditor} hideSign={true} isCKEditor={true} from="quizManagement" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="IndexLogo">
                            Document Upload
                        </label>
                        <div className="form-group atttach-file">
                            <label>
                                <i className="fal fa-upload"></i>
                                <span>Upload</span>
                                <input
                                    type="file"
                                    id="input"
                                    className="form-control  form-control-aatch-file"
                                    name="file"
                                    multiple
                                    accept=".xlsx,.pdf,.prdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    title="Upload Document"
                                    onChange={(e) => {
                                        if (e.target.files.length) {
                                            const fileArray = [...e.target.files]
                                            const uploadArray = [...uploadDoc, ...fileArray];
                                            setUploadDoc([...uploadArray]);                                            
                                            formik.setFieldValue("document", [...uploadArray]);
                                            const FileNames = fileArray.map(item =>(URL.createObjectURL(item)))                                            
                                            setUploadDocName( prev => [ ...prev, ...FileNames]);
                                        }
                                    }}
                                />
                            </label>
                        </div>
                        {uploadDocName && uploadDocName !== "undefined" && uploadDocName.map((item, index) => (

                            <>
                                <div className="document-upload">
                                            <a
                                                title={uploadDoc[index]?.name}
                                                href={
                                                    item.includes("blob")
                                                        ? item
                                                        : `${IMAGE_URL}/${uploadDocName}`.replaceAll(
                                                            "/public/",
                                                            ""
                                                        ).replaceAll("home/myaie/public_html/", "")
                                                }
                                                target="_blank"
                                            >
                                                <i className="fal fa-file"></i>
                                                &nbsp;{
                                                    uploadDoc[index]?.name
                                                        ? TrimText(uploadDoc[index]?.name, 40)
                                                        : ""

                                                }
                                            </a>
                                            <button
                                                className="btn btn-danger rounded-circle btn-dropdown-item"
                                                title="Delete"
                                                type="button"
                                                onClick={() => handleDeleteDoc(uploadDoc[index]?.name,uploadDocName[index])}
                                            >
                                                <i className="fal fa-trash-alt"></i>
                                            </button>
                                    
                                </div>
                            </>
                        ))
                    }
                    </div>
                    <RenderOption
                        selectedQ={questionTypeFormik.values.questionType}
                        orderState={orderState}
                        setCkState={setCkState}
                        setEditorState={setOptionEditor}
                        selectedOption={questionTypeFormik.values.selectedOption}
                        multipleChoiceProps={
                            {
                                checkedAns: checkedAns,
                                setCheckedAns: setCheckedAns,
                                selectedAns: selectedAns,
                                setselectedAns:setselectedAns,
                                optionEditor: optionEditor
                            }
                        }
                        fillInBlankProps={
                            {
                                fillOption: fillOption,
                                setFillOption: setFillOption,
                                removeKeyword: removeOptionAnswer
                            }
                        }
                        matchColumnsProps={
                            {
                                matchItemArr: formik.values.matchItemArr,
                                setMatchItemArr: (value) => formik.setFieldValue("matchItemArr", value),
                                idEditorState3Invalid: formik.errors.optionEditor && formik.touched.optionEditor,
                            }
                        }
                        choiceMatrixProps={
                            {
                                matrixArray: matrixData,
                                onChangeFunction: handleOptionSelect
                            }
                        }
                        shortAnswerProps={
                            {
                                isShortAnswerInvalid: formik.errors.shortAnswer && formik.touched.shortAnswer,
                                handleAddShortAnswer: handleAddShortAnswer,
                                shortAnswerProps: formik.handleBlur,
                                shortAnswer: formik.values.shortAnswer,
                                removeAnswerFunction: handleRemoveShortAnswer
                            }
                        }
                        equationProps={
                            {
                                setEquationEditor: setEquationEditor,
                                equationEditor: equationEditor
                            }
                        }
                    />

                    <div className="form-group form-group-save-cancel">
                        <button type="button" className="btn btn-primary" disabled={disabled} title="Save" onClick={() => {
                            formik.setFieldValue("isSaving", true)
                            formik.handleSubmit()
                        }}>
                            {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                            Save
                        </button>
                        <button type="button" className="btn btn-primary" title="Preview" onClick={() => {
                            formik.setFieldValue("isSaving", false)
                            formik.handleSubmit()
                        }}>
                            <i className="fal fa-file-alt"></i>
                            Preview
                        </button>
                        <button type="button" className="btn btn-danger" onClick={() => history.goBack()}>
                            <i className="fal fa-times"></i>Cancel
                        </button>
                        {Object.keys(formik.values).map((key, ind) => {
                            if (formik.touched[key] && formik.errors[key]) {
                                return (
                                    <div className="invalid-feedback d-block" key={ind}>
                                        {formik.errors[key]}
                                    </div>
                                )
                            }
                        })}
                    </div>
                </form>
            )}
            <PopupComponent
                id="previewQuestion"
                type="previewQuestion"
                header={{
                    iconName: "fa-bars",
                    heading: "View Question"
                }}
                modalBody={<DisplayQuestion question={previewQuestionData} from="addQuestion" />}
            />
        </div>
    );
};

export default QuestionForm