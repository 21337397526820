import React from "react";
import { useParams } from "react-router-dom";
import AppealsList from "./AppealsList";

function AppealsPage() {
    const { type } = useParams();
  return (
    <div>
      {(type === "pending") && <AppealsList />}
      {(type === "approved") && <AppealsList />}
      {(type === "declined") && <AppealsList />}
      {/* {type == "open" && <AppealsForm />} */}
    </div>
  );
}

export default AppealsPage;
