import axiosInstance from "../utils/axios";

export const getTestimonialDropdownlist = async () => {
    return await axiosInstance().get(`/getTestimonialDropDownList`)
}

export const GetTestimonialCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getTestimonialList`, values, {
        cancelToken: cancelToken
    });
};

export const GetTestimonialFilterCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getTestimonialFilters`, {
        cancelToken: cancelToken
    });
};

export const DeleteTestimonial = async (id) => {
    return await axiosInstance().post(`/deleteTestimonial?id=${id}`);
};

export const GetTestimonial = async (data) => {
    return await axiosInstance().post(`/getTestimonialList`, data);
};

export const AddTestimonial = async (data) => {
    return await axiosInstance().post(`/createTestimonial`, data)
}

export const UpdateTestimonial = async (data) => {
    return await axiosInstance().post(`/updateTestimonial`, data)
}

export const GetUpdateTestimonial = async (id) => {
    return await axiosInstance().get(`/getTestimonialDetails?id=${id}`)
}

export const getTestimonialColorList = async (values) => {
    return await axiosInstance().post(`/getAccentColorBaseOnBrandTmpList` , values)
}

