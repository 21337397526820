import { useFormik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import RenderPreviousValues from "./RenderPreviousValues";

function StudentNumber(props) {
  const { id } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [studentData, setStudentData] = useState();
  const [intakeYear, setIntakeYear] = useState([]);
  const history = useHistory()

  useEffect(() => {
    setStudentData(props.studentData);
  }, [props]);

  const formik = useFormikContext()

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Student Number</div>
      </div>
      {/* <form onSubmit={formik.handleSubmit}></form> */}
        {isLoaded ? (
          <SkeletonTicketList />
        ) : (
          <>
            <div className="row">
            <div className="col-md-6 col-lg-4">
            <label htmlFor="StudentNumber">Student Number *</label>
            <div className="form-icon-group mb-4" title="StudentNumber">
              <input type="text" className="form-control" name="StudentNumber" id="StudentNumber" placeholder="Student Number" title="StudentNumber" value={formik.values.StudentNumber} disabled />
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.student_crm_id} previousValue={studentData?.RegStudentsPreviousDetails?.student_crm_id} />
          </div>
          {studentData?.re_reg_data?.live_portal_user_id &&  <div className="col-md-6 col-lg-4">
                <label>&nbsp;</label>
                <div className="form-icon-group mb-4">
                  <Link title="Student View"  to={`/studentAdministration/students/open/${studentData?.re_reg_data?.live_portal_user_id}/general`} className="btn btn-primary pt-1">
                  View Student Profile
                  </Link>
                </div>
              </div>}
             
              {/* {studentData && <div className="col-md-4">
                  <div className="form-icon-group mb-4">
                    <a href={IMAGE_URL + studentData.pdf} target="_blank" className="as-text-blue curser" >
                      <i className="fal fa-file"></i>&nbsp;{studentData && studentData && studentData.pdf ? basename(studentData.pdf) : ""}
                    </a>
                    <a href="javascript:void(0);" className="as-text-red curser" onClick={handleDeletePdf}>
                      <i className="fal fa-trash-alt"></i>
                    </a>
                  </div>
                  <div className="form-icon-group mb-4">
                    <a href={IMAGE_URL + studentData.pdf} className="as-text-blue curser">
                      <i className="fal fa-file"></i>&nbsp;{studentData && studentData && studentData.image ? basename(studentData.image) : ""}
                    </a>
                    <a href="javascript:void(0);" className="as-text-red curser" onClick={handleDeleteImg}>
                      <i className="fal fa-trash-alt"></i>
                    </a>
                  </div>
                </div>} */}
            </div>
          </>
        )}
      <hr />
    </div>
  );
}

export default StudentNumber;
