import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  GetStudentLicenseList,
  SendLicensesStudentNotification,
  UpdateStudentLicense,
} from "../../../../services/TickectService";
import Str from "../../../common/Str";
import Swal from "sweetalert2";
import PermissionsGate from "../../../../utils/permissionGate";
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { FORM_DATE_FORMAT} from "../../../../utils/Constants";
import axios from "axios";

function Licenses() {
  const [licenseData, setLicenseData] = useState([]);
  const [licenseType, setLicenseType] = useState([
    { label: "Adobe Suit", value: "Adobe Suit" },
    { label: "Microsoft O365 Email", value: "Microsoft O365 Email" },
    ,
  ]);

  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [loading, setloading] = useState(true);
  const [refreshRow, setRefreshRow] = useState(false);
  const [microsoftDetail, setMicrosoftDetail] = useState("")
  const { id } = useParams();
  const history = useHistory();
  const [emailLog, setEmailLog] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [saveLoad, setSaveLoad] = useState(false)

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(()=>{
    const params = {};
    SendLicensesStudentNotification(params)
      .then((res) => {
        setEmailLog(res?.data?.data?.what);
      })
      .catch((err) => console.log("err", err));
  },[refresh]);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["slview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getProgramsList = async () => {
      setloading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await GetStudentLicenseList(id, source.token);
        if (res.status === 200) {
          setLicenseData(res.data?.license_list);
          setMicrosoftDetail(res.data?.microsoft_details?.student_status)
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };
  
    getProgramsList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [refreshRow]);

  const onChangeValidUntil = (index, date, field) => {
    let tempUserData = licenseData;
    tempUserData[index][field]=new Date(date).getTime()/1000;
    // console.log("new.", tempUserData, action);
    // tempUserData.map((item) => {
    //   if (item.id == rowId) {
    //     item.valid_until = new Date(date).getTime();
    //     return item;
    //   } else {
    //     return item;
    //   }
    // });

    setLicenseData((tempUserData) => [...tempUserData]);
  };

  const onChangeValidFrom = (index, date,field) => {
    let tempUserData = licenseData;
    tempUserData[index][field]=new Date(date).getTime()/1000;
    setLicenseData((tempUserData) => [...tempUserData]);
  };

  const onChangeStatus = (rowId, action) => {
    let tempUserData = licenseData;
    // console.log("new.", tempUserData, action);
    // tempUserData.map((item) => {
    //   if (item.id == rowId) {
    //     item.action = action == 1 ? 0 : 1;
    //     return item;
    //   } else {
    //     return item;
    //   }
    // });

    // setLicenseData((tempUserData) => [...tempUserData]);
  };

  const handleChangeYear = (e, index,field) => {
    let tempUserData = licenseData;
    tempUserData[index][field]=e.value
    setLicenseData((tempUserData) => [...tempUserData]);
  };

  // const dataToRender = () => {
  //   return licenseData;
  // };

  const onSubmit = () => {
    setSaveLoad(true)
    // const form = new FormData();
    let licenseType = [];
    let validFrom = [];
    let validUntil = [];
    let accountStatus = [];
    licenseData.map((data) => {

      licenseType.push({ id: data.id, value: data.license_type });
      validFrom.push({ id: data.id, value: data.valid_from });
      validUntil.push({ id: data.id, value: data.valid_until });
      accountStatus.push({ id: data.id, value: data.action ? data.action : 0 });
    });

    const data = {
      license_type: licenseType,
      valid_from: validFrom,
      valid_until: validUntil,
      account_status: accountStatus,
      student_id: id,
    };
    // let jsonData = JSON.stringify(data);

    UpdateStudentLicense(data)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Added successfully",
        });
        setRefreshRow(!refreshRow);
        setSaveLoad(false)
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
        setSaveLoad(false)
      });
  };

  const onHandleLicense=()=>{
    let newArr = [...licenseData];
    newArr.push({id: "", license_type: "", valid_from: "", valid_until: "", action :"", detail:"", expire_in: ""  })
    setLicenseData(newArr)
  }

  const handleLicenseOption=(data)=>{
    if(data == "Microsoft O365 Email"){
     return [
        { label: "Adobe Suit", value: "Adobe Suit" },
      ]
    } else if (data == "Adobe Suit") {
      return [
        { label: "Microsoft O365 Email", value: "Microsoft O365 Email" },
      ]
    } else {
      return [
        { label: "Adobe Suit", value: "Adobe Suit" },
        { label: "Microsoft O365 Email", value: "Microsoft O365 Email" },
      ]
    }

  }

  const formikSendEmail = useFormik({
    enableReinitialize: true,
    initialValues: {
      sentGroups:{
        EmailNotification: false,
        PushNotification: false,
      },
    },
    validationSchema: Yup.object({
      sentGroups: Yup.object().shape(
        {
          PushNotification: Yup.bool().when("EmailNotification", {
            is: (EmailNotification) => !EmailNotification,
            then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification")
          }),
          EmailNotification: Yup.bool().when("PushNotification", {
            is: (PushNotification) => !PushNotification,
            then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification")
          })
        },
        [
          ["PushNotification", "EmailNotification"],
          ["EmailNotification", "PushNotification"]
        ]
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      const params = { 
        student_id: id,
        isLicensesPushnotification: values?.sentGroups?.PushNotification ? 1 : 0,
        isLicensesEmail: values?.sentGroups?.EmailNotification ? 1 : 0,
      };
      SendLicensesStudentNotification(params)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Send successfully",
          });
          setRefresh(!refresh);
        })
        .catch((err) => console.log("err", err));
    },
  });

  return (
    <div className="card card-profile-info-card">
      <div className="">
        <div className="card-body-inr card-body-info">
          <div className="my-tickets-info-list Tickets-main-wrap">
            {/* --new4r-- */}
            <div className="edit-icon new-card-header">
              {/* <div className="card-header">Licenses</div> */}
              {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
            </div>
              <div className="custom-main-table custom-table-div select_tp_year01">
                {licenseData.map((row,index) => {
                  return (
                    <div className="highest_qualification_dtls" key={index}>
                      <form>
                        <div className="row">
                          <div className="col-md-6 col-lg-2" title="License Type">
                            <label htmlFor="Type">License Type </label>
                            <div className="form-icon-group mb-4">
                              <Select
                                isDisabled={
                                  row.license_type == "Microsoft O365 Email"
                                    ? true
                                    : false
                                }
                                className={"form-control custom-select-box"}
                                name="Year"
                                value={licenseType.filter((item) => {
                                  return item.value == row.license_type;
                                })}
                                onChange={(e) => handleChangeYear(e, index,"license_type")}
                                options={handleLicenseOption(row.license_type)}
                                maxMenuHeight={175}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-2">
                            <label htmlFor="valid">Valid From </label>
                            <div className="form-group-blk mb-4" title="Valid From">
                              <DatePicker
                                selected={
                                  row.valid_from && row.valid_from != "Invalid date"
                                    ? row.valid_from.toString().length == 10
                                      ? new Date(parseInt(`${row.valid_from}000`))
                                      : new Date(parseInt(row.valid_from))
                                    : // new Date(parseInt(row?.valid_from))
                                    startDate
                                }
                                onChange={(date) => {
                                  onChangeValidFrom(index, date,"valid_from");
                                }}
                                className="form-control cursor-pointer"
                                onChangeRaw={(e) => e.preventDefault()}
                                dateFormat={FORM_DATE_FORMAT}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-2">
                            <label htmlFor="Valid until">Valid Until </label>
                            <div className="form-group-blk mb-4" title="Valid Until">
                              <DatePicker
                                selected={
                                  row.valid_until &&
                                    row.valid_until != "Invalid date"
                                    ? row.valid_until.toString().length == 10
                                      ? new Date(parseInt(`${row.valid_until}000`))
                                      : new Date(parseInt(row.valid_until))
                                    : // new Date(parseInt(row.valid_until))
                                    startDate1
                                }
                                onChange={(date) => {
                                  onChangeValidUntil(index, date,"valid_until");
                                }}
                                className="form-control cursor-pointer"
                                onChangeRaw={(e) => e.preventDefault()}
                                minDate={new Date(parseInt(row.valid_from))}
                                dateFormat={FORM_DATE_FORMAT}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-2">
                            <label htmlFor="details">Details</label>
                            <div className="form-icon-group mb-4">
                              <input
                                type="text"
                                id=""
                                className={"form-control"}
                                name="details"
                                placeholder="Enter details"
                                value={row.detail}
                                // onChange={formik.handleChange}
                                disabled
                                // onBlur={formik.handleBlur}
                                title="Details"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-2">
                            <label htmlFor="expires in">Expires In</label>
                            <div className="form-icon-group mb-4">
                              <input
                                type="text"
                                id=""
                                className={"form-control"}
                                name="details"
                                placeholder="Expires In"
                                value={row.expire_in}
                                // onChange={formik.handleChange}
                                disabled
                                // onBlur={formik.handleBlur}
                                title="Expires In"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-2">
                            <label htmlFor="Title"> </label>
                            <div className="assessment-08">
                              <div className="as-buttons">
                                <button
                                  disabled={false}
                                  // title={row.action ? "OFF" : "ON"}
                                  className={
                                    row.action
                                      ? "btn btn-success rounded-circle"
                                      : "btn btn-danger rounded-circle"
                                  }
                                  onClick={() => {
                                    onChangeStatus(row.id, row.action);
                                  }}
                                >
                                  {row.action ? (
                                    <div title="Activate">
                                      <i className="fal fa-check"></i>
                                    </div>
                                  ) : (
                                    <div title="Deactivate">
                                      <i className="fal fa-times"></i>
                                    </div>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  );
                })}
                 
                    <>
                      {(licenseData.length>0 && licenseData.length<2) && Object.keys(microsoftDetail).length ? 
                        <div className="form-group-save-cancel">
                          <button
                          type="button"
                          className="btn btn-save btn-primary"
                          onClick={()=>onHandleLicense()}
                          title="Add License"
                          >
                          <i className="fal fa-plus"></i>
                          Add License
                        </button> 
                        </div>
                      :
                      (licenseData.length==0 && !loading) ? 
                      <p className="text-center">
                      {Str.noRecord}
                      </p>
                      :
                      <div className="form-group-save-cancel">
                      <PermissionsGate
                          scopes={["sledit"]}
                          errorProps={{ disabled: true }}
                          >
                        <button
                          type="submit"
                          className="btn btn-save btn-primary"
                          onClick={onSubmit}
                          title="Save"
                          >
                          {saveLoad ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                          Save
                        </button>
                      </PermissionsGate>
                      <button
                        type="button"
                        className="btn btn-close btn-danger"
                        data-dismiss="modal"
                        title="Cancel"
                        onClick={()=>{setRefreshRow(!refreshRow)}}
                        >
                          <i className="fal fa-times"></i>
                          Cancel
                      </button>
                      </div>
                      }
                          
                     
                    </>
                  </div>
                  
                {/* send manual notification */}
                <div className="my-tickets-info-list Tickets-main-wrap mt-4">
                  <div className="edit-icon new-card-header">
                    <div className="card-header">Notify Student</div>
                  </div>
                  <form onSubmit={formikSendEmail.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group-blk mb-3">
                          <div className="custom-check custom-control custom-checkbox custom-control-inline mt-2" title="Email Notification">
                            <input
                              type="checkbox"
                              title="Email"
                              className="custom-control-input"
                              id="EmailNotification"
                              name="select"
                              checked={formikSendEmail.values.sentGroups.EmailNotification}
                              onChange={(e) => formikSendEmail.setFieldValue("sentGroups.EmailNotification", e.target.checked)}
                            />
                            <label className="custom-control-label" htmlFor="EmailNotification">
                              Email Notification
                            </label>
                          </div>
                          <div className="custom-check custom-control custom-checkbox custom-control-inline mt-2">
                            <input
                              type="checkbox"
                              title="Push Notification"
                              className="custom-control-input"
                              id="PushNotification"
                              name="select"
                              checked={formikSendEmail.values.sentGroups.PushNotification}
                              onChange={(e) =>
                                formikSendEmail.setFieldValue("sentGroups.PushNotification", e.target.checked)
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="PushNotification"
                            >
                              Push Notification
                            </label>
                          </div>
                          <p>{emailLog}</p>
                        </div>
                        {Object.keys(formikSendEmail.values).map(key => {
                          if (formikSendEmail.touched[key] && formikSendEmail.errors[key]) {
                            if (key == "sentGroups") {
                              return (
                                <div className="invalid-feedback d-block" key={key}>
                                  {formikSendEmail.errors[key].EmailNotification}
                                </div>
                              )

                            }
                            return (
                              <div className="invalid-feedback d-block" key={key}>
                                {formikSendEmail.errors[key]}
                              </div>
                            )
                          }
                        })}
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="form-icon-group">
                          <PermissionsGate errorProps={{ disabled: true }} scopes={["sgiedit"]}>
                            <button
                              className="btn btn-save btn-primary"
                              type="submit"
                              title="Send Manual Notification"
                              disabled={loading}
                            >
                              <i className="fa fa-paper-plane"></i>
                              Send Manual Notification
                            </button>
                          </PermissionsGate>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
          </div>
        </div>
      </div>
  );
}

export default Licenses;
