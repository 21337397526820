import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";

import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";

import Str from "../../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import {
  ExamsFinalMarksImportUpdatedList,
  ExamsMarksFinalUpdate,
  getFilterDetails,
} from "../../../../services/ExternalExamServices";
import FilterComponent from "../../../common/FilterComponent";
import { IMAGE_URL } from "../../../../utils/Constants";
import { InitialRender } from "../../../common/Helper";
import Hover from "../../../common/Hover";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import axios from "axios";

const UpdatedResultList = () => {
  const history=useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [courseFilter, setCourseFilter] = useState([]);
  const [searchCourse, setSearchCourse] = useState({});
  const [FilterData, setFilterData] = useState([]);
  const [userData, setUserData] = useState([]);
  
  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("surename");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [courseList, setCourseList] = useState([]);
  const [course, onChangeCourse] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [debouncedTerm, setDebouncedTerm] = useState("")
  const givenPermsisions = useSelector((state) => state.givenPermission);

  const [loadUpdate, setLoadUpdate] = useState(false);

  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  useEffect(() => {
    let response = hasPermission({ scopes: ["eeurview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])
  
  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  useEffect(() => {
    const cancelTokenSources = [];
    const getListData = async () =>{
      setloading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      
      cancelTokenSources.push(source);
      const exam = {
        exam: id,
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        viaCourse:courseFilter
      };

      try {
        const res = await ExamsFinalMarksImportUpdatedList(exam, source.token);
        if(res.status==200){
          setUserData(res?.data?.data.data);
          setCourseList(res?.data?.filterData)
          setTotalRows(res.data?.data?.total);
          setloading(false);
        }
        
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }

    }
    
    getListData();

    return () => {
      cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
      });
    }
  }, [page, perPage, sortkey, sortOrder, search,id,courseFilter, refresh]);

  useEffect(() => {
    const filterSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await getFilterDetails(filterSource.token);
        if(res.status==200){
          setFilterData(res?.data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    getFilters();
    return () => {
      filterSource.cancel('Component unmounted');    
    };
    
  }, []);

  // TODO Reset Filter

  const resetFilter = () => {
    setStatus([]);
    setSearchStatusCheck({});
    setCourseFilter([]);
    onChangeCourse([])
    setDebouncedTerm("")
    setSearch("");
  };

  // TODO Data

  const columns = useMemo(() => [
    {
      name: "Student",
      selector: "first_name",
      sortField:"first_name",
      sortable: true,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon cat-dark-red "}>
              {row.photo ? <img src={`${IMAGE_URL}/${row.photo.replace("public/","").replaceAll("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.first_name, row.last_name])}
              <span className={`profile-box-2-status ${row.activity_status ? row.activity_status.toLowerCase()=="online" ? "Online" :row.activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
              <Hover firstName={row.first_name} lastName={row.last_name} photo={row.photo} email={row.email} mobile={row.mobile} status={row.student_status} activity_status={row.student_activity_status} showNumber={true} number={row.student_crm_id} right={true} />
            </span>
            {/* <PermissionsGate scopes={["sview"]} RenderError={() => <p>{row.first_name + " " + row.last_name}</p>}> */}
              <Link className="as-text-blue curser " to={`/studentAdministration/students/open/${row.student_profile_id}/general`}>
                <span className="textLimit76" title={row.first_name + " " + row.last_name}>{row.first_name + " " + row.last_name}</span>
              </Link>
            {/* </PermissionsGate> */}
          </div>
        </div>
      ),
    },
    {
      name: "Registration ID",
      selector: "registration",
      sortField:"registration",
      sortable: true,
      cell: (row) => row.registration ? row.registration : "-",
    },
    {
      name: "RSA ID",
      selector: "RSA",
      sortField:"RSA",
      sortable: true,
      cell: (row) => row.RSA ? row.RSA : "-",
    },

    // { name: "Email", selector: "Email", sortable: true, cell: (row) => TrimText(row.Email ? row.Email : "NA", 15) },

    {
      name: "Courses",
      selector: "coursename",
      sortField:"coursename",
      sortable: true,
      cell: (row) =>row.coursename ? <Link title={row.coursename} to={`/courseAdministration/coursesdetails/${row.subject}/detailCourses/show`} className="as-text-blue curser feature-name"> <span className="textLimit100">{row.coursename}</span></Link> :"-"     
    },
    {
      name:"Marks",
      selector:"mark",
      sortField:"student_marks",
      sortable:true,
      cell:(row)=> row.mark ? row.mark :"-"
    },
    {
      name:"DHET Result",
      selector:"dhetresult",
      sortField:"dhetresult",
      sortable:true,
      cell:(row)=> row.dhetresult ? row.dhetresult :"-"
    },
  ]);

  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    let data;
    const header = [
      "Student",
      "Registration ID",
      "RSA Id",
      "Courses",
      "Marks",
      "DHET Result",
    ];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const exam = {
      exam: id,
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      viaCourse:courseFilter,
      exportStatus:"true"
    };

    ExamsFinalMarksImportUpdatedList(exam)
    .then((res) => {
     data=res.data.data;
     data = data?.map((row) => ({
      ...row,
      "Registration ID": row?.registration ? row.registration : "-",
      "RSA Id": row?.RSA ? row?.RSA :"-"   ,
      "Student": row.first_name +" "+ row.last_name.length ,
      Courses: row?.coursename ? row.coursename : "-",
      Marks: row?.mark ? row.mark : "-",
      "DHET Result": row?.dhetresult ? row.dhetresult : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      // console.log(csvString);
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
      Swal.close();
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
      Swal.close();
      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Student"],
          row["Registration ID"],
          row["RSA Id"],
          row["Courses"],
          row["Marks"],
          row["DHET Result"],
         
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);
      Swal.close();
      return false;
    }
    })
    .catch((err) => {
      console.log(err);
    });    
  };

  const handleFinalUpdate = () =>{
    setLoadUpdate(true);
    try{
      let formData = new FormData();
      formData.set("exam", id);
      ExamsMarksFinalUpdate(formData)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Result updated successfully",
          });
          setLoadUpdate(false);
          setRefresh(!refresh)
      })
      .catch((err) =>{ console.log(err);setLoadUpdate(false);});
    }catch(err){
      console.log(err);
    }
  }
  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={e => setDebouncedTerm(e.target.value)} 
                          value={debouncedTerm}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman assesment-dropdown">
                        <div className="dropdown multiselect">
                          <button
                            className={`btn btn-default dropdown-toggle ${
                              course?.length ? "btn-selected" : ""
                            } `}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            title="Course"
                          >
                            <span>
                              Course <i className="fal fa-angle-down"></i>
                            </span>
                          </button>
                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">
                              <div className="dropdown-filter-pagination">
                                {courseList && courseList.length ? (
                                  <FilterComponent
                                    value={course}
                                    onChange={onChangeCourse}
                                    dataResponse={courseList}
                                    backendResponse={setCourseFilter}
                                  />
                                ) : (
                                
                                  ""
                                )}
                              </div>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Updated Result");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Updated Result");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Updated Result");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <DataTable
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={<SkeletonTicketList />}
            data={userData}
            defaultSortField="surename"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            onSort={handleSort}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
          />
    <hr />
    <div className="row align-items-center">
      <div className="col-md-4 col-lg-3 ml-auto text-right">
        <button
          className="btn btn-primary"
          title="Final Update"
          onClick={() => handleFinalUpdate()}
          disabled={loadUpdate}
        >
         {loadUpdate ? <i className="fas fa-cog fa-spin"></i> :  <i className="fa fa-save"></i>}
         Final Update
        </button>        
      </div>
    </div>
      </div>
    </div>
  );
};

export default UpdatedResultList;
