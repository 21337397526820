import axiosInstance from "../utils/axios";


export const getTicketsSummary = async (values) => {
  return await axiosInstance().get(`/getTicketsSummary?uid=${values}`);
};

export const getMyTickets = async (values) => {
  return await axiosInstance().get(`/getMyTickets?id_intake=${values}`);
};


