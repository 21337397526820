import React, { useEffect, useState } from 'react'
import PageComponent from '../components/common/PageComponent'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getCertificateTemplateById } from '../services/CertificateServices';
import { Courses, DetailsPage } from '../components/StudentAdministration/CertificateTemplates/ViewCertificateTemplate';
import AuditTrail from '../components/StudentAdministration/CertificateTemplates/AuditTrail';
import AddCertificateTemplate from '../components/StudentAdministration/CertificateTemplates/AddCertificateTemplate';
import CertificateTemplates from '../components/StudentAdministration/CertificateTemplates/CertificateTemplate';
import DuplicateCertificateTemplate from '../components/StudentAdministration/CertificateTemplates/DuplicateCertificateTemplate';
import Repositioning from '../components/StudentAdministration/CertificateTemplates/Repositioning';

function CertificateTemplate() {

    const { id, tab } = useParams()

    const [certificateName, setCertificateName] = useState("")
    const [details, setDetails] = useState({});

    useEffect(() => {
        if (id) {
            getCertificateTemplateById(id).then(res => {
                setDetails(res.data)
                setCertificateName(res.data.Name)
            }).catch(error => console.error("error :", error));
        }
    }, [id, tab])

    return (
        <PageComponent
            heading={tab === "add" ? ["Add", "Certificate Template"] : tab === "list" ? ["Certificate", "Templates"] : tab === "auditTrail" ? ["Audit Trail", "Certificate Templates"] : tab === "duplicateTemplate" ? ["Duplicate Certificate", certificateName] : tab === "singleAuditTrail" ? ["Audit Trail", certificateName] : ["Certificate Template", certificateName]}
            disableColonShown={tab === "add" || tab === "list"}
            isBackButtonShown
            auditTrailUrl={tab === "details" || tab === "courses" || tab === "repositionElements" ? `/courseAdministration/certificates/singleAuditTrail/${id}` : `/courseAdministration/certificates/auditTrail`}
            isAuditTrailButtonShown={tab !== "auditTrail" && tab !== "singleAuditTrail" && tab !== "duplicateTemplate" && tab !== "add"}
            componentToRender={[
                {
                    condition: tab === "add",
                    component: <AddCertificateTemplate />
                },
                {
                    condition: tab === "list",
                    component: <CertificateTemplates />
                },
                {
                    condition: tab === "details",
                    component: <DetailsPage response={details} />
                },
                {
                    condition: tab === "courses",
                    component: <Courses />
                },
                {
                    condition: tab === "duplicateTemplate",
                    component: <DuplicateCertificateTemplate response={details} />
                },
                {
                    condition: tab === "auditTrail",
                    component: <AuditTrail />
                },
                {
                    condition: tab === "singleAuditTrail",
                    component: <AuditTrail />
                },
                {
                    condition: tab === "repositionElements",
                    component: <Repositioning response={details} />
                },
            ]}
            tabs={
                tab === "details" || tab === "courses" ? [
                    {
                        globalIconName: "details",
                        url: `/courseAdministration/certificates/details/${id}`,
                        condition: tab === "details"
                    },
                    {
                        tabIcon: "fa-chalkboard",
                        tabName: "Linked Courses",
                        url: `/courseAdministration/certificates/courses/${id}`,
                        condition: tab === "courses"
                    }
                ] : []
            }
        />
    )
}

export default CertificateTemplate