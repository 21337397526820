export const convertBase64ToBlob = (base64Image) => {
  const parts = base64Image.split(';base64,');
  const imageType = parts[0].split(':')[1];
  const decodedData = window.atob(parts[1]);
  const uInt8Array = new Uint8Array(decodedData.length);

  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: imageType });
};

export const convertFilePathToBase64 = (filePath, callback) => {
  const reader = new FileReader();

  reader.readAsDataURL(filePath);
  reader.onloadend = () => {
    callback(reader.result);
  };
};

export const convertBase64toText = (base64Text) => {
  const parts = base64Text.split(';base64,');
  const decodedData = window.atob(parts[0]);

  return decodedData;
};

export const pixelToMillimeter = (pixels, dpi = 96) => {
  const inches = pixels / dpi;
  const milimeter = inches * 25.4;
  return milimeter;
};
