import React, { useMemo, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { BBBCommand } from "../../../services/ClassMgtService";
import { GetLoadBalance, GetLoadBalanceCancelToken, UpdateToggle } from "../../../services/ServerService";
import Str from "../../common/Str";
import $ from "jquery";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import axios from "axios";

const ServerSettings = () => {
  const [dataArr, setDataArr] = useState([]);
  const [serverRes, setServerRes] = useState("");
  const [loaderAt, setLoaderAt] = useState(0);
  const [loading,setloading] = useState(true);
  const [search, setSearch] = useState("");
  const [updateData, setUpdatedata] = useState(false)

  // useEffect(() => {
  //   getLoadBalance()
  // }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetLoadBalanceCancelToken({},dropdownSource.token);
        if (res?.status == 200) {
          setDataArr(res.data.res)
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, [updateData]);

  const getLoadBalance = () => {
    GetLoadBalance()
      .then((res) => {
        if (res?.status == 200) {
          setDataArr(res.data.res)
          setloading(false);
        }
      })
      .catch((err) => console.log(err));
  }

  const dataToRender = () => {
    let updatedData = [];
    let allData = dataArr;
    if (search.length) {
      let tempName = allData.filter((item) => {
        let includes =
          item.server_display_name &&
          item.server_display_name.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [...tempName];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if(search.length){
      return updatedData;
    }
    else {
      return dataArr
    }
    
  };

  const columns = useMemo(() => [
    {
      name: "Server Name",
      cell: (row) => row.server_display_name ? <span className="feature-name"><span className="textLimit100">{row.server_display_name }</span></span>: "-",
    },

    {
      name: "Configuration",
      cell: (row) => {
        let randomNum = getRandomNumber();
        return <div className="assessment-08">
          <div className="assessment-08 action-color">
            <button className="btn btn-primary" onClick={() => { serverAction(row.id, "bbb-conf --version", row.id + "-a") }} disabled={loaderAt === row.id + "-a" ? true : false}>
              {loaderAt === row.id + "-a" ? <><i className="fas fa-cog fa-spin"></i>Version</> : "Version"}
            </button>
          </div>
        </div>
      },
    },
    {
      name: "Monitoring",
      cell: (row) => {
        return <div className="assessment-08">
          <div className="assessment-08 monitor-color">
            <button className="btn btn-primary mr-2" onClick={() => { serverAction(row.id, "bbb-conf --status", row.id + "-b") }} disabled={loaderAt === row.id + "-b" ? true : false}>
              {loaderAt === row.id + "-b" ? <><i className="fas fa-cog fa-spin"></i>Status</> : "Status"}
            </button>
            <button className="btn btn-primary mr-2" onClick={() => { serverAction(row.id, "bbb-conf --check", row.id + "-c") }} disabled={loaderAt === row.id + "-c" ? true : false}>
              {loaderAt === row.id + "-c" ? <><i className="fas fa-cog fa-spin"></i>Check</> : "Check"}
            </button>
            <button className="btn btn-primary mr-2" onClick={() => { serverAction(row.id, "bbb-conf --debug", row.id + "-d") }} disabled={loaderAt === row.id + "-d" ? true : false}>
              {loaderAt === row.id + "-d" ? <><i className="fas fa-cog fa-spin"></i>Debug</> : "Debug"}
            </button>
            <button className="btn btn-primary mr-2" onClick={() => { serverAction(row.id, "bbb-conf --watch", row.id + "-e") }} disabled={loaderAt === row.id + "-e" ? true : false}>
              {loaderAt === row.id + "-e" ? <><i className="fas fa-cog fa-spin"></i>Watch</> : "Watch"}
            </button>
            <button className="btn btn-primary mr-2" onClick={() => { serverAction(row.id, "bbb-conf --network", row.id + "-f") }} disabled={loaderAt === row.id + "-f" ? true : false}>
              {loaderAt === row.id + "-f" ? <><i className="fas fa-cog fa-spin"></i>Network</> : "Network"}
            </button>
          </div>
        </div>
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08">
          {/* <div className="assessment-08 action-color">
            <button className="btn btn-primary mr-2" onClick={() => { }}>Restart</button>
            <button className="btn btn-primary mr-2" onClick={() => { }}>Stop</button>
            <button className="btn btn-primary mr-2" onClick={() => { }}>Start</button>
            <button className="btn btn-primary mr-2" onClick={() => { }}>Clean</button>
          </div> */}
          <div className="assessment-08">
            <div className="as-buttons">
              <button disabled={false} title={row.inLoadBalancing ? "OFF" : "ON"} className={row.inLoadBalancing ? "btn btn-success rounded-circle" : "btn btn-danger rounded-circle"} onClick={() => {
                onChangeStatus(row.id, row.inLoadBalancing)
              }}
              >
                {row.inLoadBalancing ? <> <i className="fal fa-check"></i></> : <> <i className="fal fa-times"></i></>}
              </button>
            </div>
          </div>
        </div>
      ),
    },
  ]);

  function serverAction(server_id, command, loaderPosition) {
    setLoaderAt(loaderPosition);
    let fromData = new FormData();
    fromData.append("action", "LB_runCommand");
    fromData.append("command", command);
    fromData.append("server_id", server_id);
    BBBCommand(fromData).then(response => {
      let resMsg = response && response.data && response.data.stdout !== "" ? response.data.stdout : response.data.stderr;
      setLoaderAt(0);
      setServerRes(resMsg);
      $("#roomCampusModal").modal("show");
    }).catch(error => {
      setLoaderAt(0);
      console.log("error >>>> ", error);
    })
  }

  const onChangeStatus = (id, isLoadBalancing) => {

    Swal.fire({
      title: "",
      text: "Are you sure",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        let formData = new FormData();
        formData.append("id", id);
        formData.append("status", isLoadBalancing == 1 ? 0 : 1);
        UpdateToggle(formData).then(data => {
          // console.log("data => ", data);
          // setLoaderAt(0);
          // getLoadBalance();
          setUpdatedata(!updateData)
        }).catch(error => {
          // setLoaderAt(0);
          console.log("error ====> ", error);
        })
      } else {
        // setLoaderAt(0);
      }
    })
  }

  function getRandomNumber() {
    return Math.floor(Math.random() * 10000000);
  }
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  const resetFilter = () => {
    setSearch("");
  };

  return (
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="custom-table-div filter-search-icon card card-table-custom">
          <div className="search-filter-div">
              <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                          <div id="assessment-table-main_filter" className="dataTables_filter">
                            <label>
                              <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                            </label>
                            <div className="filter-eff filter-data-btn">
                              <button className="filter-buttons">
                                <i className="fal fa-filter"></i>
                              </button>
                            </div>
                          </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                    <div className="reset-btn-group">
                      <div className="button-reset dropdown-comman">
                        <button
                          className="btn btn-primary"
                          onClick={() => resetFilter()}
                          title="Reset"
                        >
                          <i className="fal fa-redo"></i>Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {loading ? <SkeletonTicketList />
            : <DataTable
              data={dataToRender()}
              columns={columns}
              noDataComponent={Str.noRecord}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />
          }
        </div>
     

        <div className="topic-add-modal modal fade server-modal" id="roomCampusModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog mmodal-dialog-centered available-assessment-modal" role="document">
            <div className="modal-content">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title"><i className="fal fa-bars"></i> Command Result </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                <div className="topic-add-modal-content text-center font-weight-bold">
                  <pre>{serverRes}</pre>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
  
  );
};

export default ServerSettings;

