const Str = {
  detailDes: "Detailed description and instructions",
  noInstruction: "No instructions available",
  backAssment: "Back to your Assessments",
  Next: "Next",
  sysCheck: "System Check",
  Identification: "Identification",
  launchExam: "Launch Exam",
  assDetail: "Assessment Detail",
  acceptSoftMonitor:
    "I accept that these items will be monitored via software to ensure there are no additional parties, voices and browser windows present during examination.This will only be viewed by a moderator when/where issues were observed by the software.",
  stuIdentify: "Student Identification",
  Test: "Test",
  noRecord: "There are no records to show",
};
export default Str;
