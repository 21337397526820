import React from "react";
import moment from "moment";
import ClosedIcon from "./../../assets/images/closed.png";
import InProgressIcon from "./../../assets/images/In-Progress.png";
import OpenIcon from "./../../assets/images/Open.png";

import PendingAcademy from "./../../assets/images/TicketStatusIcon/Pending-Academy.png";
import PendingInvestigation from "./../../assets/images/TicketStatusIcon/Pending-Investigation.png";
import PendingStudent from "./../../assets/images/TicketStatusIcon/Pending-student.png";
import HoldIcon from "./../../assets/images/TicketStatusIcon/hold.png";
import New from "./../../assets/images/TicketStatusIcon/new.png";
import { TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_TIME_FORMAT } from "../../utils/Constants";

export const originalValues = (s) => {
  try {
    return decodeURIComponent(escape(s));
  } catch (error) {
    return s;
  }
};

export const getCurrentTimeHomePage = (time) => {
  moment.suppressDeprecationWarnings = true;
  // let formattedTime = moment(time).format("dddd, MMMM Do YYYY | h:mm:ss a");
  let formattedTime = moment(time).format(`${TABLE_DATE_FORMAT} | ${TABLE_TIME_FORMAT}:ss`);
  return formattedTime;
};

export const convertArray = (value) => {
  let v = value.replace(new RegExp(",", "g"), "<br/>");
  return v.replace(new RegExp("___", "g"), "  ");
};

export const wishTiming = (time) => {
  let todayHrs = time.getHours();

  if (todayHrs >= 0 && todayHrs <= 12) {
    return "Good Morning";
  } else if (todayHrs > 12 && todayHrs <= 17) {
    return "Good Afternoon";
  } else if (todayHrs > 17 && todayHrs <= 24) {
    return "Good Evening";
  }
};

export const getMenuItem = (item) => {
  let home = ["", "home"];
  let course = ["mycourses", "course"];
  let myexam = ["myexam", "myexamdetails"];
  let chat = ["newchat", "mychats"];
  // let complimentcomments = ["complimentcomments"];
  let reregistration = ["reregistration", "reregistrationSchool"];

  if (home.includes(item)) return "home";
  if (course.includes(item)) return "mycourses";
  if (myexam.includes(item)) return "myexam";
  if (chat.includes(item)) return "mychats";
  if (reregistration.includes(item)) return "reregistration";
};

export const getFormattedTime = (time) => {
  let formattedTime = moment(time).format(TABLE_DATE_TIME_FORMAT);
  return formattedTime;
};

export const dateTimeComparision = (current, update) => {
  var a = new Date(current);
  var b = new Date(update);

  var msDateA = Date.UTC(a.getFullYear(), a.getMonth() + 1, a.getDate());
  var msDateB = Date.UTC(b.getFullYear(), b.getMonth() + 1, b.getDate());

  if (parseFloat(msDateA) < parseFloat(msDateB)) return -1;
  // lt
  else if (parseFloat(msDateA) == parseFloat(msDateB)) return 0;
  // eq
  else if (parseFloat(msDateA) > parseFloat(msDateB)) return 1;
  // gt
  else return null; // error
};

export const getCurrentTime = () => {
  let formattedTime = moment().format(TABLE_DATE_TIME_FORMAT);
  return formattedTime;
};

export const examPdfDateFormate = () => {
  let formattedTime = moment().format("DD-MM-YYYY");
  return formattedTime;
};

export const getFormattedTimeForList = (time) => {
  let formattedTime = moment(time).format(TABLE_DATE_TIME_FORMAT);
  return formattedTime;
};

export const slashDateFormate = () => {
  let formattedTime = moment().format("DD/MM/YYYY");
  return formattedTime;
};

export const getStatusIcon = (status) => {
  if (status == "Closed") return ClosedIcon;
  if (status == "On-Hold") return HoldIcon;
  if (status == "Open") return OpenIcon;
  if (status == "Pending-Academy") return PendingAcademy;
  if (status == "Pending-Investigation") return PendingInvestigation;
  if (status == "Pending-Student") return PendingStudent;
  if (status == "New") return New;
};

export const getStatusDetails = (status) => {
  if (status == "Closed")
    return (
      <p className="as-red">
        <i className="fal fa-times"></i>Closed-Not Submitted
      </p>
    );
  if (status == "On-Hold")
    return (
      <p>
        <img src={InProgressIcon} />
        On - Hold
      </p>
    );
  if (status == "Open")
    return (
      <p>
        <img src={OpenIcon} />
        {status}
      </p>
    );
  if (status == "New")
    return (
      <p>
        <img src={InProgressIcon} />
        {status}
      </p>
    );
  if (status == "Pending-Academy")
    return (
      <p>
        <img src={InProgressIcon} />
        {status}
      </p>
    );
  if (status == "Pending-Student")
    return (
      <p>
        <img src={InProgressIcon} />
        {status}
      </p>
    );
};

export const getFileIcon = (extenstion) => {
  extenstion = extenstion.split(".")[extenstion.split(".").length - 1];
  const images = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "ico"];

  const pdfFile = ["pdf"];

  const docFile = ["doc", "docx"];

  const pptFile = ["ppt", "pptx"];

  const files = [
    "rtf",
    "xls",
    "xlsx",
    "txt",
    "csv",
    "html",
    "xhtml",
    "psd",
    "sql",
    "log",
    "fla",
    "xml",
    "ade",
    "adp",
    "mdb",
    "accdb",
    "odt",
    "ots",
    "ott",
    "odb",
    "odg",
    "otp",
    "otg",
    "odf",
    "ods",
    "odp",
    "css",
    "ai",
    "kmz",
    "dwg",
    "dxf",
    "hpgl",
    "plt",
    "spl",
    "step",
    "stp",
    "iges",
    "igs",
    "sat",
    "cgm",
    "tiff",
    "",
  ];

  const archives = ["zip", "rar", "gz", "tar", "iso", "dmg"];

  if (images.includes(extenstion)) return "fa-image";

  if (files.includes(extenstion)) return "fa-file";

  if (pdfFile.includes(extenstion)) return "fa-file-pdf";
  if (docFile.includes(extenstion)) return "fa-file-pdf";
  if (pptFile.includes(extenstion)) return "fa-file-powerpoint";

  if (archives.includes(extenstion)) return "fa-file-archive";
};

export const InitialRender = (data) => {
  if (data != null && data != undefined && data) {
    // let tempArr = data.split(" ");
    let tempArr = data;
    let initials = "";
    tempArr.map((i) => {
      if (i) {
        initials = initials + i[0];
      }
    });
    initials = initials.length > 2 ? initials.slice(0, 2).toUpperCase() : initials.toUpperCase();
    return initials;
  }
  return "NA";
};


export const InitalRender = (data) => {
  if (data != null && data != undefined && data) {
    let tempArr = data.split(" ")
    let initials = ''
    tempArr.map(i => {
      if (i) {
        initials = initials + i[0]
      }
    })
    initials = initials.length > 2 ? initials.slice(0, 2).toUpperCase() : initials.toUpperCase()
    return initials
  }
  return 'NA'

}


export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const classStatusUi = ({ color, text, icon }) => {
  return (
    <small className={"as-widget-class-status " + color} title={text}>
      {text} </small>
  );
};

export const classStatusWidget = (resource) => {
  let str = resource ? resource.toLowerCase() : resource;
  switch (str) {
    case "past":
      return classStatusUi({ color: "as-past-bg", text: "Past" });
    case "upcoming":
      return classStatusUi({ color: "as-upcoming-bg", text: "Upcoming" });
    case "live":
      return classStatusUi({ color: "as-live-bg", text: "Live" });
    case "ended":
      return classStatusUi({ color: "as-ended-bg", text: "Ended" });
    case "cancelled":
      return classStatusUi({ color: "as-red-bg-status-cancelled", text: "Cancelled" });
    default:
      return classStatusUi({ color: "rolls-tag-small", text: str ? str : "NA" });
  }
};

const RollsUi = ({ color, text, icon }) => {
  return (
    <small className={"as-widget " + color} title={text}>
      {text} </small>
  );
};

export const rollsWidget = (resource) => {
  let str = resource ? resource.toLowerCase() : resource;
  switch (str) {
    case "lecturer":
      return RollsUi({ color: "rolls-tag-small", text: "Subject Lecturer" });
    case "guest lecturer":
    case "guest_lecturer":
      return RollsUi({ color: "rolls-tag-small", text: "Guest Lecturer" });
    case "coach":
      return RollsUi({ color: "rolls-tag-small", text: "Programme Lead" });
    case "support":
      return RollsUi({ color: "rolls-tag-small", text: "Programme Support" });
    case "product_manager":
    case "product manager":
      return RollsUi({ color: "rolls-tag-small", text: "Product Manager" });
    case "student":
      return RollsUi({ color: "student-rolls-tag-small", text: "Student" });
    case "student_support":
    case "student support":
      return RollsUi({ color: "rolls-tag-small", text: "Student Support" });
    case "lead product manager":
    case "lead_product_manager":
      return RollsUi({ color: "rolls-tag-small", text: "Lead Product Manager" });
    default:
      return RollsUi({ color: "", text: str ? str : "" });
  }
};

const classUi = ({ color, text, icon }) => {
  return (
    <small className={"as-widget-class-status " + color} title={text}>
      {text} </small>
  );
};

export const classStatusWidgets = (resource) => {
  let str = resource ? resource.toLowerCase() : resource;
  switch (str) {
    case "past":
      return classUi({ color: "as-past-bg", text: "Past" });
    case "upcoming":
      return classUi({ color: "as-upcoming-bg", text: "Upcoming" });
      case "live":
      return classUi({ color: "as-live-bg", text: "Live" });
    case "ended":
      return classUi({ color: "as-ended-bg", text: "Ended" });
    case "cancelled":
      return classUi({ color: "as-red-bg-status-cancelled", text: "Cancelled" });
      case "launched":
        return classUi({ color: "as-launched-bg", text: "Launched" });
      case "not launched":
        return classUi({ color: "as-not-launched-bg", text: "Not Launched" });
    default:
      return classUi({ color: "", text: str ? str : "" });
  }
};

export function convertToPlain(html){

  // Create a new div element
  var tempDivElement = document.createElement("div");

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element 
  return tempDivElement.textContent || tempDivElement.innerText || "";
}