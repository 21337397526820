import { param } from "jquery";
import axiosInstance from "../utils/axios";

export const UpdateClassSettings = async (values) => {
    return await axiosInstance().post(`/update_class_settings`, values);
};

export const GetClassSettings = async () => {
    return await axiosInstance().get(`/default_class_settings`, {
        params: {},
    });
};

export const GetClassSettingsCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/default_class_settings`, {cancelToken: cancelToken});
};

export const OnlineClassChecker = async (values) => {
    return await axiosInstance().get(`/online_class_checker`, values);
};

export const OnlineClassCheckerCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/online_class_checker`, {cancelToken: cancelToken});
};


export const GetClassAuditTrails = async () => {
    return await axiosInstance().post("/getClassManagementAuditTrail");
}

export const GetClassAuditTrailsCancelToken = async (cancelToken) => {
    return await axiosInstance().post(`/getClassManagementAuditTrail`,{},{cancelToken: cancelToken});
};

export const GetRecordingCeckerList = async () => {
    return await axiosInstance().get(`/getClassRecordingStatus`);
};

export const GetRecordingCeckerListCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getClassRecordingStatus`,{cancelToken: cancelToken});
};

export const ResubmitView = async (data) => {
    return await axiosInstance().post('/resubmitView', data);
}

export const BBBCommand = async (data) => {
    return await axiosInstance().post('/bbbCommands', data);
}

export const UpdateClassInfo = async () => {
    return await axiosInstance().get('/updateClassInformation');
}