import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import { AddAndGetTicketComment, GetAssignmentQuestionSummary, GetSmartAssessmentSubmissionResult, GetSmartAssessmentSubmissionResultCancelToken, Get_assessment_submission } from "../../../../../services/SmartAssessmentService";
import Str from "../../../../common/Str";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import moment from "moment";
import { Link } from "react-router-dom";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../../utils/Constants";
import { InitialRender } from "../../../../common/Helper";
import Hover from "../../../../common/Hover";
import { ColorRender } from "../../../../systemadministration/usermanagement/CheckRole";
import { useFormik } from "formik";
import * as Yup from "yup";
import $ from "jquery"
import Swal from "sweetalert2";
import { TrimText } from "../../../../common/TrimText";
import hasPermission from "../../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../../../utils/permissionGate";
import { handleTableScroll } from "../../../../../utils/commonFunction";
import axios from "axios";

const Submission = () => {
  const history = useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState("");

  const [status, setStatus] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [submissionstatus, setSubmissionStatus] = useState([]);
  const [searchSubmissionStatusCheck, setSearchSubmissionStatusCheck] = useState({});

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [detail, setDetail] = useState([]);
  const [submissionId, setSubmissionId] = useState();
  const [disabled, setDisabled] = useState(false)
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [debouncedTerm, setDebouncedTerm] = useState("")

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    let response = hasPermission({ scopes: ["casubmissionresultview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetSmartAssessmentSubmissionResultCancelToken(id,dropdownSource.token);
        if(res.status == 200){
          setData(res.data.body)
          setDetail(res.data.header)
          setLoading(false)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false)
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: Yup.object({
      comment: Yup.string().required("Comment is required").trim(),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      const data = new FormData();
      data.append("comment", values.comment);
      data.append("assignment_id", id);
      data.append("stdid", submissionId);
      data.append("action_type", "save_comment");
      AddAndGetTicketComment(data)
        .then((response) => {
          $("#notifications").modal("hide");
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Saved comment successfully",
          });
          setDisabled(false)
        })
        .catch((error) => {
          console.log("AddAndGetTicketComment error :", error);
        });
    }
  })

  const secToMin = (value) => {
    var timestamp = value;
    var hours = Math.floor(timestamp / 60 / 60);
    var minutes = Math.floor(timestamp / 60) - hours * 60;
    var seconds = timestamp % 60;
    // hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    var formatted =
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0");
    return formatted;
  }

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      let tempname = allData.filter((item) => {
        let includes = item.fullName && item.fullName.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempname];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (status.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.find(function (post, index) {
          if (post.toString() === item.status ? item.status.toString() : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }
    if (submissionstatus.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = submissionstatus.find(function (post, index) {
          if (post.toString() === item.submission_status ? item.submission_status.toString() : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }
    if (status.length || search.length || submissionstatus.length) {
      return updatedData;
    } else {
      return data;
    }
  };

  const columns = useMemo(() => [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      cell: (row, index) => (
        <div className="ticket-id">
          <span className="overflow-ellipsis2">
            <b>#{row.id}</b>
          </span>
        </div>
      ),
    },
    {
      name: "User",
      selector: "name",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        row.name ? <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(row.role_name)}>
              {row.picture_me ? <img src={`${IMAGE_URL}/${row.picture_me.replaceAll("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.name, row.last_name])}
              <span className={`profile-box-2-status ${row.Status ? row.Status : "Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
              <Hover firstName={row.name.split(" ")[0]} lastName={row.name.split(" ")[1]} photo={row.picture_me} email={""} mobile={""} status={row.status} right={true} role={"Submission"} />
            </span>
            {/* <PermissionsGate scopes={['umedit']} RenderError={() => <p>{row.name}</p>}> */}
            <Link className="as-text-blue curser " to={`/systemAdministration/userManagement/open/${row.UserID}/aboutYou`}>
              {TrimText(row.name)}
            </Link>
            {/* </PermissionsGate> */}
          </div>
        </div> : "-"
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => row.status ? <p><span className={row.status === "Active" ? "as-green-bg as-widget" : "as-red-bg as-widget"}>{row.status}</span></p> : "-"
    },
    {
      name: "Submission Status",
      selector: "submission_status",
      sortable: true,
      cell: (row) => row.submission_status ? <p><span className={"cat cat-" + (row.submission_status === "SUBMITTED" ? "greenhex" : "red")}><i className="fas fa-circle mr-1"></i>{(row.submission_status.toLowerCase()).replace("n", "N").replace("s", "S")}</span></p>
        : "-"
    },
    {
      name: "Date",
      selector: "submission_date",
      sortable: true,
      cell: (row) => (
        <p>{row.submission_date ?
          <div className="dateTime">
            {/* <p className="right-space">{row.submission_date}</p> */}
            <p className="right-space">{moment(row.submission_date).format(TABLE_DATE_FORMAT + ",")}</p>
            <p>{moment(row.submission_date).format(TABLE_TIME_FORMAT)}</p>
          </div>
          : "-"}</p>
      )
    },
    {
      name: "Course",
      selector: "course_name",
      sortable: true,
      cell: (row) => (
        <p>{row.course_name ?
          <Link className="as-text-blue curser " to={`/courseAdministration/coursesdetails/${row.course_id}/dashboard/show`}>
            {row.course_name}
          </Link>
          : "-"}</p>
      )
    },
    {
      name: "Mark",
      selector: "percentage_obtained",
      sortable: true,
      cell: (row) => row.percentage_obtained ? row.percentage_obtained + "%" : "-"
    },
    {
      name: "Student Score",
      selector: "score_obtained",
      sortable: true,
      cell: (row) => row.score_obtained ? row.score_obtained : "-"
    },
    {
      name: "Time To Complete",
      selector: "total_time",
      sortable: true,
      cell: (row) => row.total_time ? (secToMin(row.total_time)) : "-"
    },
    {
      name: "Actions",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <Link className="btn btn-primary rounded-circle" title="Open" to={`/courseAdministration/smartAssessment/assessment/open/submissiondetails/${id}/${row.student_id}`}>
              <i className="fal fa-folder-open"></i>
            </Link>
            <button
              className="btn btn-primary rounded-circle"
              data-toggle="modal"
              data-target="#notifications"
              onClick={() => {
                handleGetComment(row.id);
                setSubmissionId(row.id);
              }}
              title="Comment"
            >
              <i class="fal fa-comment"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const handleGetComment = (submissionID) => {
    const data = new FormData();
    data.append("assignment_id", id);
    data.append("stdid", submissionID);
    data.append("action_type", "get_comment");
    AddAndGetTicketComment(data)
      .then((response) => {
        formik.setFieldValue("comment", response.data.comment ? response.data.comment : "")
      })
      .catch((error) => {
        console.log("AddAndGetTicketComment error:", error);
      });
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  const handleStatusFilter = (e) => {
    const value = e.target.value;
    let arr = status;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setStatus(arr);
    let id = e.target.id;
    setSearchStatusCheck({ ...searchStatusCheck, [id]: !searchStatusCheck[id] });
  };

  const handleSubmissionStatusFilter = (e) => {
    const value = e.target.value;
    let arr = submissionstatus;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setSubmissionStatus(arr);
    let id = e.target.id;
    setSearchSubmissionStatusCheck({ ...searchSubmissionStatusCheck, [id]: !searchSubmissionStatusCheck[id] });
  };

  const resetFilter = () => {
    setSearch("");
    setDebouncedTerm("")
    setStatus([]);
    setSearchStatusCheck({});
    setSubmissionStatus([]);
    setSearchSubmissionStatusCheck({});
  };

  const exportData = async (fileType, fileName) => {
    let data = dataToRender();

    const header = ["ID", "User", "Status", "Submission Status", "Submission Date", "Course", "Mark", "Student Score", "Time To Complete"];

    data = data?.map((row) => ({
      ...row,
      ID: row?.id,
      "User": row?.name ? row.name : "-",
      "Status": row?.status ? row.status : "-",
      "Submission Status": row?.submission_status ? row.submission_status : "-",
      "Submission Date": row?.submission_date ? moment.unix(row.submission_date).format(TABLE_DATE_TIME_FORMAT) : "-",
      "Course": row?.course_name ? row.course_name : "-",
      "Mark": row?.percentage_obtained ? row.percentage_obtained : "-",
      "Student Score": row?.score_obtained ? row?.score_obtained : "-",
      "Time To Complete": row?.total_time ? secToMin(row.total_time) : "-",
    }));


    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });

      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row["ID"], row["User"], row["Status"], row["Submission Status"], row["Submission Date"], row["Course"], row["Mark"], row["Student Score"], row["Time To Complete"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <>
      <div className="card-new card card-profile-info-card mb-30">
        <div className="">
          <div className="card-header">Summary
            {loading && <i className="fas fa-cog fa-spin ml-2"></i>}</div>
          <div className="card-body-inr card-body-info">
            <div className="basic-info-box">
              <div className="row align-items-center">
                <div className="col-md-4 col-lg-3">
                  <div className="basic-info-box-list">
                    <ul className="m-0">
                      <li>
                        <span className="title">Submissions :</span>
                        <span> {detail?.submissions ? detail?.submissions : "-"}</span>
                      </li>
                      <li>
                        <span className="title">Average mark :</span>
                        <span> {detail?.average_marks ? detail?.average_marks : "-"}</span>
                      </li>
                      <li>
                        <span className="title">Average Score :</span>
                        <span> {detail?.average_score ? detail?.average_score : "-"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="basic-info-box-list">
                    <ul className="m-0">
                      <li>
                        <span className="title">Submission rate :</span>
                        <span> {detail?.submissions_rate ? detail?.submissions_rate : "-"}</span>
                      </li>
                      <li>
                        <span className="title">Average time spent :</span>
                        <span> {detail?.average_time_spent ? detail?.average_time_spent : "-"}</span>
                      </li>
                      <li>
                        <span className="title">Appeals :</span>
                        {detail && <span> {detail?.TOTAL_APPEALES || detail?.TOTAL_APPEALES === 0 ? detail?.TOTAL_APPEALES : "-"}</span>}
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-4 col-lg-3 ml-auto text-right">
                  <button className="btn btn-primary" title="Question Summary" onClick={() => { history.push("/courseAdministration/smartAssessment/assessment/open/questionSummary/" + id) }}>
                    <i className="fal fa-plus"></i>Question Summary
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-profile-info-card mb-30">
        <div className={`card-body`}>
          <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="edit-icon new-card-header">
              <div className="card-header">Submissions &amp; Results</div>
            </div>
          </div>
          <div className="custom-table-div filter-search-icon card card-table-custom course-table-filter-sec">
            <div className="filter-search-bar-blk">
              <div className="filter-button-group">
                <div className="filter-eff filter-data-btn">
                  <button className="filter-buttons">
                    <i className="fal fa-filter"></i>
                  </button>
                </div>
                <div className="assessment-table-filter">
                  <div className="btn-type-filter dropdown-comman">
                    <div className={`dropdown multiselect `}>
                      <button className={`btn btn-default dropdown-toggle ${status.length ? "btn-selected" : ""}`} type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" title="Status" >
                        <span>
                          Status <i className="fal fa-angle-down"></i>
                        </span>
                      </button>
                      <form className="dropdown-menu remove-list-style">
                        <ul aria-labelledby="dropdownMenu1">
                          <li className="item-text-green">
                            <input type="checkbox" id="action1" value="Active" onClick={handleStatusFilter} checked={searchStatusCheck.action1} />
                            <label htmlFor="action1">
                              <span className="as-green-bg as-widget">Active</span>
                            </label>
                          </li>
                          <li className="item-text-red">
                            <input type="checkbox" id="action2" value="In Active" onClick={handleStatusFilter} checked={searchStatusCheck.action2} />
                            <label htmlFor="action2">
                              <span className="as-red-bg as-widget">In Active</span>
                            </label>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="assessment-table-filter">
                  <div className="btn-type-filter dropdown-comman">
                    <div className="dropdown multiselect">
                      <button title="Submission Status" className={`btn btn-default dropdown-toggle ${submissionstatus.length ? "btn-selected" : ""}`} type="button" id="dropdownLevel" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span>
                          Submission Status <i className="fal fa-angle-down"></i>
                        </span>
                      </button>
                      <form className="dropdown-menu remove-list-style">
                        <ul aria-labelledby="dropdownLevel">
                          <li className="cat cat-skyblue">
                            <input type="checkbox" id="action3" value="NOT SUBMITTED" onClick={handleSubmissionStatusFilter} checked={searchSubmissionStatusCheck.action5} />
                            <label htmlFor="action3">
                              <span className="cat cat-red">
                                <i className="fas fa-circle mr-1"></i>
                                Not Submitted
                              </span>
                            </label>
                          </li>
                          <li className="cat cat-skyblue">
                            <input type="checkbox" id="action4" value="SUBMITTED" onClick={handleSubmissionStatusFilter} checked={searchSubmissionStatusCheck.action4} />
                            <label htmlFor="action4">
                              <span className="cat cat-greenhex">
                                <i className="fas fa-circle mr-1"></i>
                                Submitted
                              </span>
                            </label>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="button-reset dropdown-comman">
                  <button className="btn btn-primary" title="Reset" onClick={resetFilter}>
                    <i className="fal fa-redo"></i>Reset
                  </button>
                </div>
                <div className="files-export-group">
                  <button type="button" className="btn btn-files" title="Export spreadsheet" onClick={() => { exportData("xlsx", "Submissions") }}>
                    <i className="fal fa-file-excel icon"></i>
                  </button>
                  <button type="button" className="btn btn-files" title="Export CSV" onClick={() => { exportData("csv", "Submissions") }}>
                    <i className="fal fa-file-csv icon"></i>
                  </button>
                  <button type="button" className="btn btn-files" title="Export PDF" onClick={() => { exportData("pdf", "Submissions") }}>
                    <i className="fal fa-file-pdf icon"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                    <div id="assessment-table-main_filter" className="dataTables_filter">
                      <label>
                        <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={debouncedTerm} />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              data={dataToRender()}
              defaultSortField="submission_date"
              defaultSortAsc={false}
              columns={columns}
              pagination={true}
              noDataComponent={Str.noRecord}
              progressPending={loading}
              progressComponent={<SkeletonTicketList />}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />
          </div>
        </div>
        <div className="topic-add-modal modal fade" id="notifications" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">

              <form>
                <div className="modal-body">
                  <div className="topic-add-modal-content text-center font-weight-bold">
                    <i class="fal fa-comment"></i> Add Comment
                  </div>
                  <hr />
                  <div className="topic-add-modal-content">

                    <div className="row">
                      <div className="col-4 font-weight-bold">Comment</div>
                      <div className="col-8">
                        <textarea name="comment" type="text" className={"form-control" + (formik.errors.comment && formik.touched.comment ? " is-invalid" : "")} value={formik.values.comment} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      </div>
                    </div>
                  </div>
                  <div className=" form-group form-group-save-cancel">
                    <PermissionsGate scopes={["cassgradeassedit"]} errorProps={{ disabled: true }}>
                      <button type="button" className="btn btn-primary" onClick={formik.handleSubmit} disabled={disabled} title="Save">
                        {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                        Save
                      </button>
                    </PermissionsGate>
                    <button type="button" className="btn btn-danger" data-dismiss="modal" title="Close">
                      <i className="fal fa-times"></i>
                      Close
                    </button>
                    {formik.touched.comment && formik.errors.comment ? <div className="invalid-feedback d-block">{formik.errors.comment}</div> : null}
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Submission;
