import React, { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Swal from "sweetalert2";
// import { TypeofModule } from"../GroupItems"
import { TrimText } from "../../common/TrimText";
// import { myCompare, myTypeCompare } from "../../../common/MyCompare";
import "jspdf-autotable";
import { getCurrentTimeHomePage } from "../../common/Helper";
import $ from "jquery";
import { AddEditModuleOutcomes, AddUpdatesShortCourseOutcomes, DeleteModuleOutcomes, DeleteShortCourseOutcomes, GetModuleOutcomesList, GetModuleOutcomesListCancelToken, GetShortCourseOutcomeList } from "../../../services/ProgramService";
import { useFormik } from "formik";
import * as Yup from "yup";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import { formatQuestion } from "../../../utils/FormatQuestion";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import PermissionsGate from "../../../utils/permissionGate";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const ShortCourseOutcomes = () => {
    const { id, type } = useParams();
    const history=useHistory();
    const [search, setSearch] = useState("");
    const [outcomes, setOutcomes] = useState();
    const [loading, setLoading] = useState(true);
    const [dummy, setDummy] = useState();
    const [disabled, setDisabled] = useState(false);
    const [isediting, setIsediting] = useState(false);
    const [outcomeId, setOutcomeId] = useState()
    const [shortCourseOutcome, setShortCourseOutcome] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [modalIcon, setModalIcon] = useState(false)   

    useEffect(() => {
        handleTableScroll()
      }, [loading])

    useEffect(() => {
        const cancelTokenSources = [];

    const getOutcomeList = async () => {
      setLoading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const payload={
            shortcourse_id:id
        }
        const res = await GetShortCourseOutcomeList(payload, source.token);
        if (res.status === 200) {
            setOutcomes(res.data.data);
            setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
            console.error(error);
            setLoading(false);
        }
      }
    };
  
    getOutcomeList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
    }, [dummy, type]);


    const resetFilter1 = () => {
        setSearch("");
    };

    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
      let response = hasPermission({
        scopes: ["moview"],
        permissions: givenPermsisions,
      });
      if (!response) {
        history.push("/noaccess");
      }
    }, []);


    const dataToRender = () => {
        let updatedData = [];
        let allData = outcomes;
        if (search.length) {
            let tempId = allData.filter((item) => {
                let includes = item.outcomes
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempdata = [...tempId];
            let unique = [...new Set(tempdata)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (search.length) {
            return updatedData;
        } else {
            return outcomes;
        }
    };

    const outcomeSort = (a, b) => {
        return a && a.toString().toLowerCase() > b && b.toString().toLowerCase()
            ? 1
            : -1;
    };


    const handleSearchFilter1 = (e) => {
        const value = e.target.value;
        setSearch(value);
    };

    const exportData = (fileType, fileName, table) => {
        let data, header;
        data = dataToRender();
        header = ["Outcomes"];
        data = data.map((row) => ({
            ...row,
            // ID: row.id ? row.id : "-",
            Outcomes: row.outcomes ? formatQuestion(row.outcomes).replaceAll("&nbsp;"," ") : "-",
        }));
        // }

        if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            // Create new tag for download file
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = [
                "text/csv",
                anchor.download,
                anchor.href,
            ].join(":");
            anchor.click();

            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                URL.revokeObjectURL(blobURL);
            }, 1000);
        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.forEach((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);

            // Returning false as downloading of file is already taken care of
            return false;
        }
        if (fileType === "pdf") {
            let compatibleData;
            compatibleData = data.map((row) => {
                return [row["Outcomes"]];
            });
            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                    minCellHeight: 10,
                    minCellWidth: 5,
                    halign: "left",
                    // valign: "center",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }
    };


    const columns = useMemo(() => [
        // {
        //   name: "ID",
        //   selector: "id",
        //   sortable: true,
        //   cell: (row) => (
        //     <div className="ticket-id">
        //       <span className="overflow-ellipsis2">
        //         <b>#{row.id}</b>
        //       </span>
        //     </div>
        //   ),
        // },
        {
            name: "Outcomes",
            selector: "Outcomes",
            sortable: true,
            sortFunction: outcomeSort,
            wrap: true,
            cell: (row) => (
                <PermissionsGate
                        scopes={["moedit"]}
                        RenderError={() => <span className="feature-name"> <span className="textLimit100">{formatQuestion(row.outcomes).replaceAll("&nbsp;"," ")}</span></span>}
                    >
                    <span className="as-text-blue curser feature-name"
                        title={formatQuestion(row.outcomes).replaceAll("&nbsp;","")}
                        data-toggle="modal"
                        data-target="#add-outcome"
                        onClick={() => {
                            setIsediting(true);
                            setShowPopup(true)
                            setOutcomeId(row.id)
                            formik.setFieldValue("outcomes", row.outcomes);
                            setShortCourseOutcome(row.outcomes)
                        }}>
                        <span title={formatQuestion(row.outcomes).replaceAll("&nbsp;"," ")} className="textLimit100">{formatQuestion(row.outcomes).replaceAll("&nbsp;"," ")}</span>
                    </span>
                </PermissionsGate>
            ),
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="assessment-08 module-topic">
                    <div className="as-buttons">
                    <PermissionsGate
                            scopes={["moedit"]}
                            RenderError={() => (
                                <button
                                className="btn btn-primary rounded-circle"
                                title="Open"
                                disabled
                                >
                                <i className="fal fa-folder-open"></i>
                                </button>
                            )}
                    >
                        <button
                            title="Open"
                            className="btn btn-primary rounded-circle"
                            data-toggle="modal"
                            data-target="#add-outcome"
                            onClick={() => {
                                setShowPopup(true)
                                setIsediting(true);
                                setOutcomeId(row.id)
                                formik.setFieldValue("outcomes", row.outcomes);
                                setShortCourseOutcome(row.outcomes)
                                setModalIcon(true)
                            }}
                        >
                            <i className="fal fa-folder-open"></i>
                        </button>
                    </PermissionsGate>
                    <PermissionsGate
                            scopes={["modelete"]}
                            RenderError={() => (
                                <button
                                className="btn btn-danger rounded-circle"
                                title="Delete"
                                disabled
                                >
                                <i className="fal fa-trash-alt"></i>
                                </button>
                            )}
                    >
                        <button
                            title="Delete"
                            className="btn btn-danger rounded-circle"
                            onClick={() => handleDelete(row.id)}
                        >
                            <i className="fal fa-trash-alt"></i>
                        </button>
                    </PermissionsGate>
                    </div>
                </div>
            ),
        },
    ]);

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteShortCourseOutcomes({ id: id })
                    .then((res) => {
                        Swal.fire("Deleted!", "Outcome has been deleted.", "success");
                        setDummy(!dummy);
                    })
                    .catch((error) => console.log(error));
            }
        });
    };

    const formik = useFormik({
        initialValues: {
            outcomes: "",
            module_id: id,
            id: ""
        },
        validationSchema: Yup.object({
            outcomes: Yup.string()
                // .max(40, "Outcome should not be greater than 40 Character")
                // .required("Outcome is required")
                .test("required", "Outcome is required", (value) => {
                    if ((formatQuestion(shortCourseOutcome).replaceAll("&nbsp;", " ")).trim() == "" && !shortCourseOutcome.includes("<img")) {
                        return false
                    }
                    if (!shortCourseOutcome) {
                        return false
                    }
                    return true;
                })
                .trim(),
        }),
        onSubmit: (values) => {
            setDisabled(true);
            values = {...values, outcomes:shortCourseOutcome, shortcourse_id:id}
            if (isediting) {
                values.id = outcomeId
            }
            AddUpdatesShortCourseOutcomes({ ...values })
                .then((res) => {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: `${isediting ? "Updated" : "Added"} Successfully`,
                    });
                    setDummy(!dummy);
                    setLoading(false);
                    setDisabled(false);
                    formik.resetForm();
                    setShowPopup(false)
                    $("#add-outcome").modal("hide");
                })
                .catch((err) => console.log(err));
        },
    });



    return (
        <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
            <div className="custom-table-div filter-search-icon card card-table-custom">
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div
                                    id="assessment-table-main_wrapper"
                                    className="dataTables_wrapper no-footer"
                                >
                                    <div
                                        id="assessment-table-main_filter"
                                        className="dataTables_filter"
                                    >
                                        <label>
                                            <input
                                                type="search"
                                                className=""
                                                placeholder="Search"
                                                aria-controls="assessment-table-main"
                                                onChange={handleSearchFilter1}
                                                value={search}
                                            />
                                        </label>
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                            <i className="fal fa-filter"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="filter-button-group">
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => resetFilter1()}
                                            title="Reset"
                                        >
                                            <i className="fal fa-redo"></i>Reset
                                        </button>
                                    </div>
                                    <div className="files-export-group">
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("xlsx", "Outcomes");
                                            }}
                                            title="Export spreadsheet"
                                        >
                                            <i className="fal fa-file-excel icon"></i>
                                        </button>

                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("csv", "Outcomes");
                                            }}
                                            title="Export CSV"
                                        >
                                            <i className="fal fa-file-csv icon"></i>
                                        </button>

                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("pdf", "Outcomes");
                                            }}
                                            title="Export PDF"
                                        >
                                            <i className="fal fa-file-pdf icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-filter-div-right">
                        <div className=" filter-search-bar-blk"> 
                        <div className="add-ticket-blk button-reset dropdown-comman">
                            <PermissionsGate
                                    RenderError={() => (
                                    <button className="btn btn-primary" title="Create New" disabled>
                                        <i className="fal fa-plus"></i>Create New
                                    </button>
                                    )}
                                    scopes={["moadd"]}
                            >
                                <button
                                    className="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#add-outcome"
                                    title="Create New"
                                    onClick={() => {
                                        setShowPopup(true)
                                        setIsediting(false)
                                        setOutcomeId("")
                                        formik.setFieldValue("outcomes", "");
                                        setShortCourseOutcome("")
                                        setModalIcon(false)
                                    }}
                                >
                                    <i className="fal fa-plus"></i>Create New
                                </button>
                            </PermissionsGate>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    data={dataToRender()}
                    columns={columns}
                    pagination={true}
                    progressComponent={<SkeletonTicketList />}
                    progressPending={loading}
                    noDataComponent={Str.noRecord}
                    defaultSortAsc={true}
                    defaultSortField={"outcomes"}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                />
                
            </div>
            {showPopup && <div
                    className="topic-add-modal modal able_modal01 fade show"
                    style={{ display: "block" }}
                    id="add-outcome"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content modal-border-update">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="modal-body p-0">
                                    <div className="modal-header modal-header-custom">
                                        <h5 className="modal-title">
                                        {modalIcon ? <><i className="fal fa-folder-open"></i> Edit</>:<><i className="fal fa-plus"></i> Add</>} Outcome
                                        </h5>
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => {
                                                setShowPopup(false)
                                                $("#add-outcome").modal("hide");
                                                formik.setFieldValue("outcomes", "")
                                            }}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="t p-30px">
                                        <div className="topic-add-modal-content">
                                            <div className="row center-label">
                                                <div className="col-12">
                                                    <label>Outcome *</label>
                                                    <HtmlInputEditor
                                                        editorState={shortCourseOutcome}
                                                        setEditorState={setShortCourseOutcome}
                                                        isCKEditor={true}
                                                        hideSign={true}
                                                        isInvalid={formik.errors.outcomes && formik.touched.outcomes}
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className={
                                                            "form-control" +
                                                            (formik.errors.module_outcomes && formik.touched.module_outcomes
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        placeholder="Enter Outcome"
                                                        title="Enter Outcome"
                                                        name="module_outcomes"
                                                        value={formik.values.module_outcomes}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-save-cancel mt-3">
                                            <button
                                                className="btn btn-primary"
                                                title="Save"
                                                onClick={formik.handleSubmit}
                                                disabled={disabled}
                                            >
                                                {disabled ? (
                                                    <i className="fas fa-cog fa-spin"></i>
                                                ) : (
                                                    <i className="fal fa-save"></i>
                                                )}{" "}
                                                Save
                                            </button>
                                            <button
                                                className="btn btn-close btn-danger"
                                                type="button"
                                                title="Cancel"
                                                onClick={() => {
                                                    setShowPopup(false)
                                                    $("#add-outcome").modal("hide");
                                                    formik.setFieldValue("outcomes", "")
                                                }}
                                            >
                                                <i className="fal fa-times"></i>Cancel
                                            </button>
                                            {formik.touched.outcomes && formik.errors.outcomes ? (
                                                <div className="invalid-feedback d-block">
                                                    {formik.errors.outcomes}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>}
        </div>
    );
};

export default ShortCourseOutcomes;
