import React, { useEffect, useState } from 'react';
import Tablefilter from './Tablefilter';
import { GetAllLecturers, GetCommonContentReReg } from '../../services/ProgrammeServices';
import { RenderLearningMethod, RenderSchool } from '../../utils/CommonGroupingItem';
import { number } from 'yup';
import { GetListOfDashboardCancleToken } from '../../services/DashBoardServices';
import axios from '../../utils/axios';
import { useParams } from 'react-router-dom';

const HomeTopFilters = ({setGlobalReset,setFilterIntake,setFilterSchool,setFilterLearningMethod,setFilterLecturer}) => {

    const { type: urlType, tab } = useParams();    
    const [dropdownVals, setDropdownVals] = useState();
    const [school,setSchool] = useState({ arr: [], checkObj: {} })
    const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
    const [intake, setIntake] = useState({ arr: [], checkObj: {} });
    const [lecturer, setLecturer] = useState({ arr: [], checkObj: [] });
    const [intakeArr,setIntakeArr] = useState([])
    const [lecturerArr,setLecturerArr] = useState([])
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    
    useEffect(() => {
        if(!Array.isArray(lecturer.checkObj) && Object.keys(lecturer.checkObj).length == 0){

            setLecturer({...lecturer,checkObj:[] })
        }
    },[lecturer])
    const resetFilter = () => {
        
        setSchool({ arr: [], checkObj: {} });
        setLearningMethod({ arr: [], checkObj: {} })
        setLecturer({ arr: [], checkObj: [] })
        setIntake({ arr: [], checkObj: {} })
        setGlobalReset(true)
        // document.querySelectorAll('.filter-button-group > .assessment-table-filter input[checked]').forEach((checkbox) => {
        //     console.log("Unchecking buttons");
        //     checkbox.checked = false;
        // });
    }

    useEffect(
        () => {
            
            const getData = () => {
                GetCommonContentReReg()
                .then((res) => {
                    setDropdownVals(res.data)
                    let tempArr = []
                    res.data.intake_year.map(year => {
                        res.data.intake_number.map(number => {
                            tempArr.push({value : `${year.value}/${number.label}`,label : `${year.value}-${number.label}`})
                        })
                    })
                    setIntakeArr(tempArr)
                })
                .catch((err) => console.log("error :", err))
            }
            getData()

            const getLecturerData = () => {
                GetAllLecturers()
                .then(res => {
                    if(res.status == 200){
                        setLecturerArr(res.data)
                    }
                })
                .catch((err) => console.log("error :", err))
            }
            getLecturerData()


            const storedFilters = JSON.parse(localStorage.getItem("globalDashboardFilters"));
        if (storedFilters) {
            setFilterLearningMethod(storedFilters.learningMethod.arr);
            setFilterSchool(storedFilters.school.arr);
            setFilterIntake(storedFilters.intake.arr);
            setFilterLecturer(storedFilters.lecturer.arr);
            setIntake(storedFilters.intake)
            setLecturer(storedFilters.lecturer)
            setSchool(storedFilters.school)
            setLearningMethod(storedFilters.learningMethod)
        }
        }, []
    )

    useEffect(
        () => {
            setFilterLearningMethod(learningMethod.arr)
            

            const filterState = {
                learningMethod,
                intake,
                school,
                lecturer
            };
            if(filterState.learningMethod.arr != [] || filterState.intake.arr != [] || filterState.school.arr != [] || filterState.lecturer.arr != []){
            localStorage.setItem("globalDashboardFilters", JSON.stringify(filterState));
            }

            // getDashboards()
            // console.log(learningMethod,"learningMethod");
        },[learningMethod]
    )

    useEffect(() => {
        setFilterLecturer(lecturer.arr)

            const filterState = {
                learningMethod,
                intake,
                school,
                lecturer
            };
            if(filterState.learningMethod.arr != [] || filterState.intake.arr != [] || filterState.school.arr != [] || filterState.lecturer.arr != []){
            localStorage.setItem("globalDashboardFilters", JSON.stringify(filterState));
            }
    },[lecturer])
    useEffect(() => {
        setFilterSchool(school.arr)
        const filterState = {
            learningMethod,
            intake,
            school,
            lecturer
        };
        if(filterState.learningMethod.arr != [] || filterState.intake.arr != [] || filterState.school.arr != [] || filterState.lecturer.arr != []){
        localStorage.setItem("globalDashboardFilters", JSON.stringify(filterState));
        }
    },[school])

    useEffect(() => {
        setFilterIntake(intake.arr)
        const filterState = {
            learningMethod,
            intake,
            school,
            lecturer
        };
        if(filterState.learningMethod.arr != [] || filterState.intake.arr != [] || filterState.school.arr != [] || filterState.lecturer.arr != []){
        localStorage.setItem("globalDashboardFilters", JSON.stringify(filterState));
        }
    },[intake])

    return (
        <>
            <div className="filter-button-group" style={{ position: "static", marginBottom: "15px",flexWrap:"wrap" }}>
                <Tablefilter
                    filterName="School"
                    optionArr={dropdownVals?.school_type || []}
                    state={school}
                    uniqueId='globalSchool'
                    setState={setSchool}
                    renderLabelFunction={RenderSchool}
                />
                <Tablefilter
                    filterName="Intake"
                    optionArr={intakeArr}
                    uniqueId='globalIntake'
                    state={intake}
                    isOptionReversed={true}
                    setState={setIntake}
                // renderLabelFunction={RenderClassType}
                />
                <Tablefilter
                    filterName="Learning Method"
                    optionArr={dropdownVals?.study_type || []}
                    state={learningMethod}
                    uniqueId='globalLearning'
                    setState={setLearningMethod}
                    renderLabelFunction={RenderLearningMethod}
                />
                <Tablefilter
                    filterName="Employee"
                    optionArr={lecturerArr}
                    state={lecturer}
                    uniqueId='globalLecturer'
                    setState={setLecturer}
                    isSearchFilter={true}
                // renderLabelFunction={RenderClassType}
                />
                <div className="button-reset dropdown-comman" style={{ marginTop: "0" }}>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={resetFilter}
                        title="Reset"
                    >
                        <i className="fal fa-redo"></i>Reset
                    </button>
                </div>
            </div>
        </>
    );
}

export default HomeTopFilters;
