import React, { useEffect, useMemo, useState } from "react";
import Str from "../../../common/Str";
import { CourseDetailsLink } from "../../../common/Links";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import { ClassAttendanceDropdowns, ClassAttendanceSubjectView } from "../../../../services/StudentsSettingServices";
import { TrimText } from "../../../common/TrimText";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import PermissionsGate from "../../../../utils/permissionGate";
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { TABLE_ROWS_PER_PAGE } from "../../../../utils/Constants";
import Tablefilter from "../../../common/Tablefilter";
import { handleTableScroll } from "../../../../utils/commonFunction";
import axios from "axios";

function SubjectView() {
  const { id } = useParams();
  const history = useHistory();
  const [classAttendanceData, setClassAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [subject, setSubject] = useState({ arr: [], checkObj: [] });
  const [subjectArr, setSubjectArr] = useState([]);
  const [searchSubjectCheck, setSearchSubjectCheck] = useState({});
  const [qualification, setQualification] = useState({ arr: [], checkObj: [] });
  const [filterData, setFilterData] = useState({qualification : [], subject : []})

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    let response = hasPermission({ scopes: ["scaview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
    ClassAttendanceDropdowns(id)
      .then((res) => {
        setFilterData({...res.data, qualification : res.data.qualificationType, subject : res.data.subjectyType})
      })
      .catch((err) => {
        console.log("error :", err);
      });
  }, []);
  useEffect(() => {
    const cancelTokenSources = [];

    const getProgramsList = async () => {
      setLoading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await ClassAttendanceSubjectView(id, source.token);
        if (res.status === 200) {
          setClassAttendanceData(res.data.data);
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    getProgramsList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  const columns = useMemo(() => [
    {
      name: "Subject Name",
      selector: "subject",
      sortable: true,
      cell: (row, index) => (
        <PermissionsGate scopes={["cdaview"]} RenderError={() => <p className="as-text-blue curser ">{row.subject ? TrimText(row.subject) : "-"}</p>}>
          <Link title={row.subject} className="as-text-blue curser feature-name" to={CourseDetailsLink(row.intake_id)}>
            <span className="textLimit100">{row.subject ? row.subject : "-"}</span>
          </Link>
        </PermissionsGate>
      ),
    },
    {
      name: "Qualification Name",
      selector: "Qulifaction",
      sortable: true,
      cell: (row) => <Link to={`/courseAdministration/Programmes/programme/open/${row.qualification_id}/details`} title={row.Qulifaction} className="as-text-blue curser feature-name"><span className="textLimit100">{row.Qulifaction ? row.Qulifaction : "-"}</span></Link>,
    },
    {
      name: "Overall Attendance %",
      selector: "OverallAttendance",
      sortable: true,
      cell: (row) => (
        <div className={row.OverallAttendance <= 50 ? "cat-red" : row.OverallAttendance>=51 && row.OverallAttendance<= 74 ? "cat-orange" : "cat-green"} title={row.OverallAttendance}>
          {row.OverallAttendance.toString() ? row.OverallAttendance.toFixed(2) + "%" : "-"}
        </div>
      ),
    },
  ], []);

  const dataToRender = () => {
    let updatedData = [];
    let allData = classAttendanceData;
    if (search.length) {
      let tempId = allData.filter((item) => {
        let includes = item.id.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSubject = allData.filter((item) => {
        let includes = item.subject && item.subject.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempQualification = allData.filter((item) => {
        let includes = item.Qulifaction && item.Qulifaction.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempAttendance = allData.filter((item) => {
        let includes = item.OverallAttendance && item.OverallAttendance.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [...tempId, ...tempQualification, ...tempSubject, ...tempAttendance];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (subject.arr.length) {
      let tempsubject = updatedData;
      let tempResult = tempsubject.filter((item) => {
        const startsWith = subject.arr.find(function (post, index) {
          if (post.toString().toLowerCase() == (item.Subject_id ? item.Subject_id.toString().toLowerCase() : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (qualification.arr.length) {
      let tempQualification = updatedData;
      let tempQualificationResult = tempQualification.filter((item) => {
        const startsWith = qualification.arr.find(function (post, index) {
          if (post.toString().toLowerCase() == (item.qualification_id ? item.qualification_id.toString().toLowerCase() : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempQualificationResult;
    }

    if (search.length || subject.arr.length || qualification.arr.length) {
      return updatedData;
    } else {
      return allData;
    }
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");
    setSubject({ arr: [], checkObj: [] });
    setSearchSubjectCheck({});
    setQualification({ arr: [], checkObj: [] });
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Subject Name", "Qualification Name", "Overall Attendance"];

    data = data?.map((row) => ({
      ...row,
      "Subject Name": row.subject ? row.subject : "-",
      "Qualification Name": row.Qulifaction ? row.Qulifaction : "-",
      "Overall Attendance": row.OverallAttendance.toString() ? row.OverallAttendance.toFixed(2) + "%" : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row["Subject Name"], row["Qualification Name"], row["Overall Attendance"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom program-table">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                  <Tablefilter
                    filterName="Qualification"
                    optionArr={filterData.qualification}
                    state={qualification}
                    setState={setQualification}
                    isSearchFilter
                  />
                    <Tablefilter
                    filterName="Subject"
                    optionArr={filterData.subject}
                    state={subject}
                    setState={setSubject}
                    isSearchFilter
                  />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "class_attendance_list");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "class_attendance_list");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "class_attendance_list");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                {/* <Link to={`/studentAdministration/students/add`}> */}
                <Link className="btn btn-primary" title="Standard View" to={`/studentAdministration/students/open/${id}/classAttendance`}>
                  <i className="fal fa-eye"></i>Standard View
                </Link>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
        {loading ? <SkeletonTicketList /> : <DataTable
          data={dataToRender()}
          defaultSortField="subject"
          defaultSortAsc={true}
          columns={columns}
          pagination={true}
          noDataComponent={Str.noRecord}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />}
      </div>
    </div>
  );
}

export default SubjectView;
