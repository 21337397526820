import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { useHistory, useParams } from 'react-router-dom';
import { AddEditExamPeriod, GetEditExamPeriodData } from '../../../../services/ExternalExamServices';
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { setExamPeriodName } from '../../../../store/actions';
import PermissionsGate from '../../../../utils/permissionGate';
import hasPermission from '../../../../utils/hasMultiplePermission';
import axios from 'axios';

const ExamPeriodForm = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {type,subTab} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [examData, setExamData] = useState({})

    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
        if(subTab){
          let response = hasPermission({ scopes: ["eeepedit"], permissions: givenPermsisions });
          if (!response) {
            history.push("/noaccess")
          }else{
            const source = axios.CancelToken.source();
            const getDetails = async () =>{
              try {
                const res = await GetEditExamPeriodData(subTab, source.token);
                if(res.status==200 && res.data.data){
                  setExamData(res.data.data.exam_period);
                  dispatch(setExamPeriodName(res.data.data.exam_period));
                }else{
                  Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Record not Available",
                  }).then(() => { history.goBack() });
                }
              } catch (error) {
                if (!axios.isCancel(error)) {
                  console.error(error);
                }
              }
            }
            
            getDetails();
            
            return () =>{
              source.cancel('Component unmounted');
            }
          }
        }
    }, [])
    

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: examData.length ? examData : ""
        },
        validationSchema: Yup.object({
            name : Yup.string().trim("Name cannot include leading and trailing spaces").required("Name is required").max(255, "Name should not be greater than 255 Character")
        }),
        onSubmit: (values)=>{
            setIsLoading(true);
            let formData = new FormData();
            formData.append("name", values.name);
            if(type == "open"){
                formData.append("id",subTab)
            }
            AddEditExamPeriod(formData).then((res)=>{
              // console.log("resdatatat",res)
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: subTab ? "Updated successfully" : "Added successfully",
                  });
                  setIsLoading(false);
                  history.push('/studentAdministration/externalExam/examPeriod/table')
            }).catch((err)=>{
                console.log(err)
                setIsLoading(false);
            })
        }
    })
  return (
    <div className="card card-body-inr">
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <label htmlFor="name"> Name *</label>
          <div className="form-icon-group mb-4" title="Name">
            <input
              type="text"
              className={
                "form-control" +
                (formik.errors.name && formik.touched.name
                  ? " is-invalid"
                  : "")
              }
              name="name"
              id="name"
              placeholder="Name"
              title="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
      </div>

      <div className="form-group form-group-save-cancel">
      <PermissionsGate scopes={type=="add" ? ["eeepadd"]: ["eeepedit"]}>
          <button className="btn btn-save btn-success" type="submit" title="Save" disabled={isLoading || !formik.dirty}>
            {isLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save
          </button>
        </PermissionsGate>
        <button
          className="btn btn-close btn-danger"
          type="button"
          title="Cancel"
          onClick={() => formik.resetForm()}
        >
          <i className="fal fa-times"></i>
          Cancel
        </button>

        {Object.keys(formik.values).map(key => {
          if (formik.touched[key] && formik.errors[key]) {
            return (
              <div className="invalid-feedback d-block">
                {formik.errors[key]}
              </div>
            )
          }
        })}
      </div>
    </form>
  </div>
  )
}

export default ExamPeriodForm