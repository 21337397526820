import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { DeleteCertificate, MatricCertificate } from "../../../../../services/StudentsSettingServices";
import { IMAGE_URL } from "../../../../../utils/Constants";
import PermissionsGate from "../../../../../utils/permissionGate";

function DocumentsBlock({ docList, deleteRow, setDeleteRow }) {
  const { id } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [studentData, setStudentData] = useState();
  const [idFile, setIdFile] = useState();
  const [idFileName, setIdFileName] = useState("");
  const [fileList, setFileList] = useState([])
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    console.log("docList", fileList)
    if (docList) {
      setFileList(docList)
    }
  }, [docList]);

  const basename = (path) => {
    return path.split("/").reverse()[0];
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      IDs: studentData ? studentData.Email : "",
    },
    validationSchema: Yup.object({
      // Ids: Yup.string().required("File is required"),
    }),
    onSubmit: (values) => {
      // setDisabled(true);
    },
  });

  const handleDelete = (certId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "warning",
          title: "Deleting Record",
          showConfirmButton: false,
        });
        DeleteCertificate(id, certId, "personal_doc")
          .then((res) => {
            setDeleteRow(!deleteRow);
            Swal.fire("Deleted!", "File Deleted.", "success");
          })
          .catch((err) => {
            if (err.response.status === 422) {
              setDeleteRow(!deleteRow);
              Swal.fire("", `Unable To Delete.`, "warning");
            }
          });

        Swal.fire("Deleted!", "File has been deleted.", "success");
      }
    });
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Documents</div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group-blk mb-3">
              <label>Upload File </label>
              <div className="form-group atttach-file m-0">
                <label className={formik.errors.IDs && formik.touched.IDs && "file-req is-invalid"}>
                  <i className="fal fa-paperclip"></i>
                  <span>Add Attachment</span>
                  <PermissionsGate errorProps={{ disabled: true }} scopes={["sapiedit"]}>
                    <input
                      type="file"
                      className="form-control  form-control-aatch-file"
                      onChange={(e) => {
                        if (e.target.files.length) {
                          formik.setFieldValue("IDs", e.target.files[0]);
                          setIdFile(e.target.files[0]);
                          setIdFileName(e.target.files[0].name);
                          setFileList([...fileList, { url: e.target.files[0].name, student: id, id: e.target.files[0].name, type: "personal_doc" }])
                          const formData = new FormData();
                          formData.append("student", id);
                          formData.append("type", "personal_doc");
                          formData.append("from_admin", 1);
                          formData.append("file_input", e.target.files[0]);
                          MatricCertificate(formData)
                            .then((res) => {
                              Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: "File Added successfully",
                              });
                              setDisabled(false);
                              setDeleteRow(!deleteRow);
                            })
                            .catch((err) => {
                              Swal.fire({
                                icon: "error",
                                title: "Error",
                                text: err?.response?.data?.message,
                              });
                            });
                        }
                      }}
                    />
                  </PermissionsGate>
                </label>
              </div>
              {/* {idFileName && (
                <>
                  <div className="frm-group">
                    <ul className="list-unstyled attached-file-ul m-0">
                      <li>
                        <a href={idFile} target="_blank">
                          <i className="fal fa-file"></i>
                          &nbsp;{idFileName}
                        </a>
                        <a href="javascript:void(0);">
                          <i className="fal fa-trash-alt"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </>
              )} */}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group-blk mb-3">
              {!fileList || !fileList.length ? "" : <label>List Of Documents </label>}
              {fileList && (
                <div className="frm-group">
                  <ul className="list-unstyled attached-file-ul m-0">
                    {fileList.map((item, index) => {
                      if (!index || showMore) {
                        return (
                          <li className="mb-2">
                            <a href={IMAGE_URL + "/" + item.url.replace("/home/myaie/public_html/", "").replace("public/", "")} target="_blank">
                              <i className="fal fa-file"></i>
                              &nbsp;{basename(item.url)}
                            </a>
                            <PermissionsGate errorProps={{ disabled: true }} scopes={["sapiedit"]}>
                              <button className="btn btn-danger" onClick={() => handleDelete(item.id)} title="Delete">
                                <i className="fal fa-trash-alt"></i>
                              </button>
                            </PermissionsGate>
                            {/* <p>(last saved and verified by Administrator Administrator on 12-01-2022 @ 07:59 am)</p> */}
                          </li>
                        );
                      }
                    })}
                  {fileList.length>1 ? <a href="#" className="show_more_btn" onClick={()=>setShowMore(!showMore)}>Show {!showMore ? "More" : "Less"}</a> : ""}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-group form-group-save-cancel">
          {/* <button className="btn btn-save btn-success" type="submit" title="Save" disabled={disabled || !formik.dirty || isLoaded}>
            {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save
          </button>
          <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()}>
            <i className="fal fa-times"></i>
            Cancel
          </button> */}

          {formik.touched.Email && formik.errors.Email ? <div className="invalid-feedback d-block">{formik.errors.Email}</div> : null}
          {formik.touched.Password && formik.errors.Password ? <div className="invalid-feedback d-block">{formik.errors.Password}</div> : null}
          {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword ? <div className="invalid-feedback d-block">{formik.errors.ConfirmPassword}</div> : null}
        </div>
      </form>
    </div>
  );
}

export default DocumentsBlock;
