import React from 'react'
import { useParams } from 'react-router-dom';
import CustomerAdd from './CustomerAdd'
import CustomerEdit from './CustomerEdit'
import CustomersList from './CustomersList';

function CustomerTab() {
  const { tab, type, id } = useParams();

  return (
    <div>
        {type && type == "table" && !id && <CustomersList />}
                    {type && type == "add" && !id && <CustomerAdd />}
                    {type && type == "edit" && id && <CustomerEdit customer_id={id}/> }
    </div>
  )
}

export default CustomerTab