import axiosInstance from "../utils/axios";

export const AddcreateEmail = async (data) => {
    return await axiosInstance().post('/createEmail', data)
}

// export const AddCampusResourse = async (data) => {
//     return await axiosInstance().post('/insertUploadResource', data)
// }

export const updateEmail = async (data) => {
    return await axiosInstance().post(`/updateEmail`, data)
}

export const getEmailsDropdown = async () => {
    return await axiosInstance().get(`/getEmailsListFilters`)
}

export const getEmailsPendingDropdown = async () => {
    return await axiosInstance().get(`/getPendingEmailsListFilters`)
}

export const getEmailsSentDropdown = async () => {
    return await axiosInstance().get(`/getSentEmailsListFilters`)
}

// export const getCampusColorList = async (values) => {
//     return await axiosInstance().post(`/getCampusColorList` , values)
// }

// export const getStaticPagesBrandList = async () => {
//     return await axiosInstance().get(`/getBrandTemplateList`)
// }

// export const getStaticPagesHeaderList = async () => {
//     return await axiosInstance().get(`/getHeaderDropDownListStatic`)
// }

export const getEmailsDropDownList = async () => {
    return await axiosInstance().get(`/getEmailsDropDownList`)
}

// export const getStaticPagesPopupList = async () => {
//     return await axiosInstance().get(`/getPopUpListStatic`)
// }

export const GetEmailsPageListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getEmailsList`, values, {
        cancelToken: cancelToken
    });
};

export const getEmailsList = async (values) => {
    return await axiosInstance().post(`/getEmailsList`, values);
};

export const getEmailProgrammeShortCourseList = async (values , cancelToken) => {
    return await axiosInstance().post(`/getEmailProgrammeShortCourseList`, values, {
        cancelToken: cancelToken
    });
};

export const getEmailProgrammesList = async (values , cancelToken) => {
    return await axiosInstance().post(`/getEmailProgrammesList`, values, {
        cancelToken: cancelToken
    });
};

export const getEmailShortCourseList = async (values , cancelToken) => {
    return await axiosInstance().post(`/getEmailShortCourseList`, values, {
        cancelToken: cancelToken
    });
};

export const getEmailsPendingList = async (values) => {
    return await axiosInstance().post(`/getPendingEmailsList`, values);
};

export const GetEmailsPendingPageListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getPendingEmailsList`, values, {
        cancelToken: cancelToken
    });
};

export const getEmailsSentList = async (values) => {
    return await axiosInstance().post(`/getSentEmailsList`, values);
};

export const GetEmailsSentPageListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getSentEmailsList`, values, {
        cancelToken: cancelToken
    });
};

// export const getEmailsList = async (values) => {
//     return await axiosInstance().post(`/getEmailsList`, values);
// };

export const DeleteEmailsPages = async (id) => {
    return await axiosInstance().post(`/deleteWBSEmail?id=${id}`);
};

// export const DeleteCampusResousePages = async (id) => {
//     return await axiosInstance().get(`/deleteUploadResource?id=${id}`);
// };

// export const GetCampusResourseList = async (data) => {
//     return await axiosInstance().post(`/getCampusResourceList`, data);
// };

export const GetEmailProgramneCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getAllProgrammeAndShortCourseList`, values, {
        cancelToken: cancelToken
    });
};

export const getWBSEmailDetails = async (id) => {
    return await axiosInstance().get(`/getWBSEmailDetails?id=${id}`);
};

export const getEmailProgrammeShortCourseListFilters = async (id) => {
    return await axiosInstance().get(`/getEmailProgrammeShortCourseListFilters?EmailID=${id}`);
};

export const getEmailProgrammesListFilters = async (id , isAssigned) => {
    return await axiosInstance().get(`/getEmailProgrammesListFilters?isAssigned=${isAssigned}&EmailID=${id}`);
};

export const getEmailShortCourseListFilters = async (id , isAssigned) => {
    return await axiosInstance().get(`/getEmailShortCourseListFilters?EmailID=${id}&isAssigned=${isAssigned}`);
};

export const getAllProgrammeAndShortCourseListFilters = async (id, assigned) => {
    return await axiosInstance().get(
        `/getAllProgrammeAndShortCourseListFilters?email_id=${id}&assigned=${assigned}`
    );
};