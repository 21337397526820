import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import { DeleteStudentCard, GenerateStudentCard, GetStudentEditData } from "../../../../../services/StudentsSettingServices";
import { keys } from "underscore";
import { IMAGE_URL } from "../../../../../utils/Constants";
import Swal from "sweetalert2";
import PermissionsGate from "../../../../../utils/permissionGate";

function StudentBlock({ studentData, intakeYear, updateData, setUpdateData, loading, setBrandError, brandValue, brandError }) {
  const { id } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      IntakeYear: studentData && studentData.intakeArr && studentData.intakeArr ? studentData.intakeArr.currentYear : "",
    },
    // validationSchema: Yup.object({
    //   IntakeYear: Yup.string().required("Intake Year is required"),
    // }),
    onSubmit: (values) => {
      setDisabled(true);
      let formData = new FormData();
    },
  });

  const basename = (name) => {
    let tempArr = name.split("/");
    return tempArr.reverse()[0];
  };

  const handleDeletePdf = () => {
    DeleteStudentCard(id, "electronic_student_card")
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Deleted successfully",
        });
        setUpdateData(!updateData);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Not Deleted",
        });
        console.log("err", err);
      });
  };

  const handleDeleteImg = () => {
    Swal.fire({
      title: "Please Wait !",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    DeleteStudentCard(id, "electronic_student_card_image")
      .then((res) => {
        Swal.close()
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Deleted successfully",
        });
        setUpdateData(!updateData);
      })
      .catch((err) => {
        Swal.close()
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Not Deleted",
        });
        console.log("err", err);
      });
  };

  const handleGenerateCard = async() => {
    await setBrandError(brandValue)
    let tempVal = brandError
    if (tempVal) {
    Swal.fire({
      title: "Please Wait !",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    const formData = new FormData();
    formData.append("student_id", id);
    formData.append("isnew", 0);
    formData.append("intake_year", formik.values.IntakeYear);
    GenerateStudentCard(formData)
      .then((res) => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Generated successfully",
        });
      })
      .catch((err) => {
        Swal.close();
        Swal.fire({
          icon: "error",
          title:"Error !" ,
          text:err.response.data.message
        });
        console.log("err", err);
      });
  }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Electronic Student Card</div>
        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      <form onSubmit={formik.handleSubmit}>
        {isLoaded ? (
          <SkeletonTicketList />
        ) : (
          <>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <label htmlFor="StudentNumber">Intake Year</label>
                <div className="form-icon-group mb-4" title="Intake Year">
                  <Select
                    className={"form-control custom-select-box " + (formik.errors.IntakeYear && formik.touched.IntakeYear ? " is-invalid" : "")}
                    name=".IntakeYear"
                    value={intakeYear?.filter(val=>val.value === formik.values.IntakeYear)}
                    onChange={(value) => {
                      if(value){
                        formik.setFieldValue("IntakeYear", value.value);
                      } else {
                        formik.setFieldValue("IntakeYear", "");
                      }                      
                    }}
                    onBlur={formik.handleBlur}
                    options={intakeYear}
                    maxMenuHeight={175}
                    placeholder={formik.values.IntakeYear ? formik.values.IntakeYear : "Select"}
                    isClearable
                  />
                  {formik.errors.StudentNumber && formik.touched.StudentNumber ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              {studentData && studentData.image && (
                <div className="col-md-6 col-lg-4">
                  <div className="form-group-blk mb-3">
                    <div className="frm-group">
                      <ul className="list-unstyled attached-file-ul m-0">
                        {studentData.pdf && (
                          <li>
                            <a href={IMAGE_URL + "/" + studentData.pdf.replace("home/myaie/public_html/", "").replace("public/", "")} target="_blank" className="as-text-blue curser">
                              <i className="fal fa-file"></i>&nbsp;{studentData && studentData && studentData.pdf ? basename(studentData.pdf) : ""}
                            </a>
                            <PermissionsGate errorProps={{ disabled: true }} scopes={["sgiedit"]}>
                              <button className="btn btn-danger" title="Delete" onClick={() => handleDeletePdf()}>
                                <i className="fal fa-trash-alt"></i>
                              </button>
                            </PermissionsGate>
                          </li>
                        )}
                        {studentData.image && (
                          <li>
                            <a href={IMAGE_URL + "/" + studentData.image.replace("home/myaie/public_html/", "").replace("public/", "")} className="as-text-blue curser" target={"_blank"}>
                              <i className="fal fa-file"></i>&nbsp;{studentData && studentData && studentData.image ? basename(studentData.image) : ""}
                            </a>
                            <PermissionsGate errorProps={{ disabled: true }} scopes={["sgiedit"]}>
                              <button className="btn btn-danger" title="Delete" onClick={() => handleDeleteImg()}>
                                <i className="fal fa-trash-alt"></i>
                              </button>
                            </PermissionsGate>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-6 col-lg-4">
                <label>&nbsp;</label>
                <div className="form-icon-group mb-4">
                  <PermissionsGate errorProps={{ disabled: true }} scopes={["sgiedit"]}>
                    <button
                      className="btn btn-primary"
                      type="button"
                      title="Generate Student Card"
                      onClick={() => handleGenerateCard()}
                      disabled={loading}
                    >
                      <i className="fal fa-save"></i>
                      Generate Student Card
                    </button>
                  </PermissionsGate>
                </div>
              </div>
            </div>
          </>
        )}
        
      </form>
    </div>
  );
}

export default StudentBlock;
