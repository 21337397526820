const initialState = {
    keywordData: []
};

const hoverKeywordReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_KEYWORD':
            return action.payload
        default:
            return state;
    }
}

export default hoverKeywordReducer;