import React, { useCallback, useMemo, useState } from "react";
import { checkIfImageExists } from "../../utils/commonFunction";
import { RenderHoverStatus, RenderProgrammeStudentStatus } from "../../utils/CommonStatusItems";
import { IMAGE_URL } from "../../utils/Constants";
import { GlobalIconNames } from "../../utils/GlobalIconNames";
import { ColorRender } from "../systemadministration/usermanagement/CheckRole";


const HoverChat = ({ fullName, firstName, lastName, photo, email, mobile, status, right, role, wholeUi, showNumber, number, activity_status }) => {
  const originalValues = useCallback((s) => {
    try {
      return decodeURIComponent(s);
    } catch (error) {
      
      return s;
    }
  }, []);
  const InitalRender = useMemo(() => data => {
    if (!data) {
      return 'NA';
    }
    const initials = data
      .split(" ")
      .filter(Boolean) // Removes empty strings from array
      .map(word => word[0].toUpperCase())
      .join("")
      .slice(0, 2);
    return initials || 'NA';
  }, []);

  const [copyText1, setCopyText1] = useState(0);
  const [copyText2, setCopyText2] = useState(0);
  const [copyText3, setCopyText3] = useState(0);

  const copyEmailText = (text, num) => {
    if (num === 1) {
      setCopyText1(1);
      navigator.clipboard.writeText(text);
      setTimeout(() => { setCopyText1(0); }, 1000);
    } else if (num === 2) {
      setCopyText2(1);
      navigator.clipboard.writeText(text);
      setTimeout(() => { setCopyText2(0); }, 1000);
    } else if (num === 3) {
      setCopyText3(1);
      navigator.clipboard.writeText(text);
      setTimeout(() => { setCopyText3(0); }, 1000);
    }
  };

  /**
   * 
   * @param {*} img will get the url of image
   * @returns This function will check what is included in url then replace accordingly.
   */
  function getImageUrl(img) {
    let imgUrl = img.replaceAll("/home/myaie/public_html/", "").replace("public/", "");
    if(imgUrl.includes("http://myaie.ac") || imgUrl.includes("https://myaie.ac")) {
      return imgUrl
    }
    return IMAGE_URL+ "/" + imgUrl;
  }

  /**
   * 
   * @returns This function will return the whole ui including that picture and name of user on which hover will call
   */
  function wholeHoverUi() {
    return (<div className="assigned-title-block-new">
      <div className="assigned-title-itm">
        <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(role ? role : "")}>          
          {photo && checkIfImageExists(getImageUrl(photo)) ? (<img src={getImageUrl(photo)} alt="AIE" />) : (InitalRender((originalValues(fullName))))}          
          {justHoverUi()}
        </span>

        <p className="as-text-blue curser " >
          {firstName + " " + lastName}
        </p>

      </div>
    </div>)
  }

  /**
   * 
   * @returns This function will return only the dom elem will be rendered in popup on hover
   */
  function justHoverUi() {
    return (<div className={right ? "assigned-title-info popup-right" : "assigned-title-info"} >
      <article>
        <figure className={ColorRender(role)}>
          {photo ? (
            <img
              src={getImageUrl(photo)}
              alt="AIE"
            />
          ) : (
            InitalRender((originalValues(fullName)))
          )}
          <span className={`profile-box-2-status hover-profile-icon ${activity_status ? activity_status.toLowerCase()=="online" ? "Online" : activity_status.toLowerCase()== "away" ? "Away" : "Offline" : "Offline"}`}>
          <i className="fas fa-circle"></i>
          </span>
        </figure>
        
        <figcaption>
          <h4>{fullName}</h4>

          
            <span className="hover-color-blue d-block mb-1" onClick={() => copyEmailText(email, 1)} >
              {GlobalIconNames("email")}
              {email ? <> {email} {copyText1 ? <i class="fas fa-check-circle cat-greenhex ml-1"></i> : 
                <i className="fal fa-copy hover-color-blue ml-1" onClick={() => copyEmailText(email)} ></i>}</>
              : " NA"}
              
            </span>
            
            <span className="hover-color-blue d-block mb-1" onClick={() => copyEmailText(mobile, 2)} >
                {GlobalIconNames("mobile")}
                {mobile ? <> {mobile} {copyText2 ? <i class="fas fa-check-circle cat-greenhex ml-1"></i> : 
                  <i className="fal fa-copy hover-color-blue ml-1" onClick={() => copyEmailText(mobile)} ></i>}</>
                 : " NA"}
            </span>
            
            
          {showNumber ?
            (
              <span className="hover-color-blue d-block mb-1" onClick={() => copyEmailText(number, 3)} >
               {GlobalIconNames("studentNo")}
                {number ? <> {number} {copyText3 ? 
                  <i class="fas fa-check-circle cat-greenhex ml-1"></i>
                 : 
                  <i className="fal fa-copy hover-color-blue ml-1" onClick={() => copyEmailText(number)} ></i>}
                </> : " NA"}
              </span>
            )
            : null}
            {status && RenderHoverStatus(status).html}
        </figcaption>
      </article>
    </div>)
  }

  return (

    <> {wholeUi === true ? wholeHoverUi() : justHoverUi()} </>
  );
};

export default React.memo(HoverChat);
