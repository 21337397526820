import React, { useEffect, useState } from "react";
import { ColumnChart, PieChart } from "bizcharts";

import { GetClassParticipants, GetOnlineClassGraphData } from "../../../../services/CourseService";

const Attendance = (props) => {
  const [graphData, setGraphData] = useState([])

  const AttendanceBreakdown = [
    { type: '0-20%', value: graphData?.sum_student_0_20 },
    { type: '20-40%', value: graphData?.sum_student_20_40 },
    { type: '40-60%', value: graphData?.sum_student_40_60 },
    { type: '60-80%', value: graphData?.sum_student_60_80 },
    { type: '80-100%', value: graphData?.sum_student_80_100 }
  ]
  const AttendanceStatic = [
    { type: '0%', value: 0 }
  ]



  useEffect(() => {
    getFirstGraphData();
    GetClassParticipants(props.subTab).then((res) => {
      props.setClassTitle(res.data.online_classes_participants.class_title)

      setGraphData(res.data)
    }).catch(err => {
      console.log(err);
    })
  }, []);
  const getFirstGraphData = () => {
    GetOnlineClassGraphData(props.uId)
      .then((response) => {
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  const recordingData = [
    {
      type: "View",
      Students: graphData?.count_view,
    },
    {
      type: "Downloads",
      Students: graphData?.count_download,
    },
  ];
  const attendanceSummery = [
    {
      type: "Present",
      Students: graphData?.sum_student_present,
    },
    {
      type: "Partially Attended ",
      Students: graphData?.sum_student_partialy,
    },
    {
      type: "Absent",
      Students: graphData?.sum_student_absent,
    },
  ];
  const attendance = [
    {
      type: "Virtual",
      Students: graphData?.sum_student_online,
    },

    {
      type: "On Campus",
      Students: graphData?.sum_student_campus,
    },
  ];


  return (
    <>
      <div className="card-new card card-profile-info-card mb-30">
        <div className="">
          <div className="card-header">Graphs</div>
          <div className="card-body-inr card-body-info">
            <div className="graphs-info-box">
              <div className="row">
                <div className="col-md-4">
                  <div className="graphs-info-items">
                    <ColumnChart
                      data={attendanceSummery}
                      title={{
                        visible: true,
                        text: "Attendance Summary",
                      }}
                      xAxis={{
                        label: {
                          visible: true,
                          autoRotate: true,
                        },
                        title: {
                          visible: true,
                          text: "Attendance",
                          style: {
                            fontSize: 14,
                            fill: "black",
                            lineWidth: 4,
                            fontWeight: 600,
                          },
                        },
                      }}
                      yAxis={{
                        label: {
                          visible: true,
                          autoRotate: true,
                        },
                        title: {
                          visible: true,
                          text: "Total Students (In numbers)",
                          style: {
                            fontSize: 14,
                            fill: "black",
                            lineWidth: 4,
                            fontWeight: 600,
                          },
                        },
                      }}
                      autoFit
                      padding="auto"
                      xField="type"
                      yField="Students"
                      meta={{
                        type: {
                          alias: "category",
                        },
                        Students: {
                          alias: "Students",
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="graphs-info-items">
                    {graphData ? <ColumnChart
                      data={recordingData}
                      title={{
                        visible: true,
                        text: "Class Recording",
                      }}
                      xAxis={{
                        label: {
                          visible: true,
                          autoRotate: true,
                        },
                        title: {
                          visible: true,
                          text: "Recordings View/Downloads",
                          style: {
                            fontSize: 14,
                            fill: "black",
                            lineWidth: 4,
                            fontWeight: 600,
                          },
                        },
                      }}
                      yAxis={{
                        label: {
                          visible: true,
                          autoRotate: true,
                        },
                        title: {
                          visible: true,
                          text: "Total Students (In numbers)",
                          style: {
                            fontSize: 14,
                            fill: "black",
                            lineWidth: 4,
                            fontWeight: 600,
                          },
                        },
                      }}
                      autoFit
                      padding="auto"
                      xField="type"
                      yField="Students"
                      meta={{
                        type: {
                          alias: "category",
                        },
                        Students: {
                          alias: "Students",
                        },
                      }}
                    /> : "NA"}

                  </div>
                </div>
                <div className="col-md-4">
                  <div className="graphs-info-items">
                    <ColumnChart
                      data={attendance}
                      title={{
                        visible: true,
                        text: "Attendance Summary",
                      }}
                      xAxis={{
                        label: {
                          visible: true,
                          autoRotate: true,
                        },
                        title: {
                          visible: true,
                          text: "Attendance",
                          style: {
                            fontSize: 14,
                            fill: "black",
                            lineWidth: 4,
                            fontWeight: 600,
                          },
                        },
                      }}
                      yAxis={{
                        label: {
                          visible: true,
                          autoRotate: true,
                        },
                        title: {
                          visible: true,
                          text: "Total Students (In numbers)",
                          style: {
                            fontSize: 14,
                            fill: "black",
                            lineWidth: 4,
                            fontWeight: 600,
                          },
                        },
                      }}
                      autoFit
                      padding="auto"
                      xField="type"
                      yField="Students"
                      meta={{
                        type: {
                          alias: "category",
                        },
                        Students: {
                          alias: "Students",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-4">
                  <div className="graphs-info-items">
                    {/* <label>Pie</label> */}

                    {graphData?.sum_student_0_20 == 0 && graphData?.sum_student_20_40 == 0 && graphData?.sum_student_40_60 == 0 && graphData?.sum_student_60_80 == 0 && graphData?.sum_student_80_100 == 0 ? <PieChart
                      data={AttendanceStatic}
                      height={300}
                      options={{
                          responsive: true,
                          maintainAspectRatio: true,
                      }}
                      title={{
                        visible: true,
                        text: "Attendance & Breakdown",
                      }}
                      offestY={50}
                      radius={1}
                      angleField="value"
                      colorField="type"
                      label={{
                        visible: true,
                        type: "spider",
                        labelHeight: 28,
                        content: (v) => `${v.type}\n${v.value}`,
                      }}
                    /> :
                      <PieChart
                        data={AttendanceBreakdown}
                        height={300}
                        options={{
                            responsive: true,
                            maintainAspectRatio: true,
                        }}
                        title={{
                          visible: true,
                          text: "Attendance & Breakdown",
                        }}
                        offestY={50}
                        radius={1}
                        angleField="value"
                        colorField="type"
                        label={{
                          visible: false,
                          type: "spider",
                          labelHeight: 28,
                          content: (v) => `${v.type}\n${v.value}`,
                        }}
                      />}

                  </div>
                </div>

                <div className="col-md-4">
                  {graphData && graphData?.subjects ? <div className="graphs-info-items">
                    {/* <label>Pie</label> */}
                    <PieChart
                      data={graphData?.subjects}
                      height={300}
                      options={{
                          responsive: true,
                          maintainAspectRatio: true,
                      }}
                      title={{
                        visible: true,
                        text: "Subject",
                      }}
                      offestY={50}
                      radius={1}
                      angleField="y"
                      colorField="label"
                      label={{
                        visible: false,
                        type: "spider",
                        labelHeight: 28,
                        content: (v) => `${v.label}\n${v.y}`,
                      }}
                    />
                  </div> : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Attendance;
