import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { AssignCourses, GetAllDropdownValues, GetAssignedCourseAndProgramme, GetCourseAndPrograms, SaveAndLiveCourse, SpStudentStatusData } from '../../../services/RegistrationService';
import AssignedProgrmasAndCourses from './CoursesAndPrograms/AssignedProgramsAndCourses';
import $ from 'jquery';

function CourseAndProgrammeTab() {

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const studentId = params.get('studentId');
  const [availQualAndCourse, setAvailQualAndCourse] = useState();
  const [assignQualAndCourse, setAssignQualAndCourse] = useState();
  const [dropdownValues, setDropdownValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availableLoading, setAvailableLoading] = useState(false);
  const [portalStatus, setPortalStatus] = useState({})
  const [isSaveAndAddDisabled, setIsSaveAndAddDisabled] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    SpStudentStatusData(studentId).then((res) => {
      setPortalStatus(res.data.data)
    }).catch((err) => {
      console.log(err);
    })
  }, [])


  function getData() {
    setAvailableLoading(true)
    setLoading(true)
    setAvailQualAndCourse([]);
    setAssignQualAndCourse([]);
    GetCourseAndPrograms(studentId).then(res => {
      setAvailQualAndCourse(res && res.data && res.data.qualifications ? res.data.qualifications : []);
      setAvailableLoading(false)
    }).catch(error => {
      setAvailableLoading(false)
      console.log(error);

    })

    GetAssignedCourseAndProgramme(studentId).then(res => {
      setAssignQualAndCourse(res && res.data && res.data.qualifications ? res.data.qualifications : []);
      setLoading(false)
    })
      .catch(error => {
        console.log(error);
        setLoading(false)
      })
    GetAllDropdownValues()
      .then((res) => {
        setDropdownValues(res.data)
      })
      .catch(error => {
        console.log(error);
      })
  }

  function isAlreadyExists(course) {
    // alert(id);
    let found = false;
    assignQualAndCourse.map((elem, ind) => {
      if (elem.id === course.id) {
        found = true;
      }
    })
    return found;
  }

  const onDelete = (course) => {
    assignQualAndCourse.map((elem, ind) => {
      if (elem.id === course.id) {
        assignQualAndCourse.splice(ind, 1);
        setAssignQualAndCourse([...assignQualAndCourse]);
        closeChildModal();
      }
    })
  }

  function assignCourse(course, isAll = false) {
    if (!isAlreadyExists(course)) {
      setAssignQualAndCourse([...assignQualAndCourse, course]);
      closeChildModal();
    } else if (isAll === true) {
      Swal.fire({
        title: "Are you sure?",
        text: "This qualification is already assigned, are you sure to move this course ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, move it!",
      }).then(() => {
        assignQualAndCourse.map((elem, ind) => {
          if (elem.id === course.id) {
            assignQualAndCourse.splice(ind, 1);
            setAssignQualAndCourse([...assignQualAndCourse, course]);
            closeChildModal();
          }
        })
      })
    } else {
      let subjects = { ...course };
      subjects.getSubjects = [];
      let tempArr = [];
      assignQualAndCourse.map((elem, ind) => {
        if (elem.id === course.id) {
          assignQualAndCourse[ind].getSubjects.map((e, i) => {
            subjects.getSubjects = mergeArray(assignQualAndCourse[ind].getSubjects, course.getSubjects);
          })

          tempArr = assignQualAndCourse;
          let selectedObj = tempArr.find(item => item.id == course.id)
          const index = tempArr.indexOf(selectedObj);
          if (index !== -1) {
            tempArr[index] = subjects;
          }
          setAssignQualAndCourse([...tempArr]);
          closeChildModal();
        }
      })
    }
  }

  function mergeArray(arr1, arr2) {
    // let uniqueArr = a => 
    let mergedArr = [...arr1, ...arr2];
    return [...new Set(mergedArr.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));
    // return [...arr1, ...arr2];
  }

  function closeChildModal() {
    $("#viewAvailCourses").modal('hide');
  }

  function assignCourses() {
    setIsSaveDisabled(true)
    let tempArr = [];

    assignQualAndCourse.map((elem, ind) => {
      let obj = { pid: null, children: [] };
      obj.pid = elem.id;
      obj.programs_course_type = elem.programs_course_type;
      if (elem.programs_course_type === "programs") {
        elem.getSubjects.map((e, i) => {
          obj.children.push(e.intake);
        })
      }
      tempArr.push(obj);
    })

    let formData = new FormData();
    formData.append("programs", JSON.stringify(tempArr));
    formData.append("uid", studentId);
    AssignCourses(formData).then(res => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Updated successfully",
      });
      setIsSaveDisabled(false)
      getData();
    }).catch(err => {
      console.log("error ---> ", err);
      setIsSaveDisabled(false)
    })
  }

  const getAvailableData = () => {
    getData();
    setAssignQualAndCourse([...assignQualAndCourse])

  }

  const handleLivePortal = () => {
    setIsSaveAndAddDisabled(true)
    let tempArr = [];

    assignQualAndCourse.map((elem, ind) => {
      let obj = { pid: null, children: [] };
      obj.pid = elem.id;
      obj.programs_course_type = elem.programs_course_type;
      if (elem.programs_course_type === "programs") {
        elem.getSubjects.map((e, i) => {
          obj.children.push(e.intake);
        })
      }
      tempArr.push(obj);
    })

    let formData = new FormData();
    formData.append("programs", JSON.stringify(tempArr));
    formData.append("uid", studentId);
    SaveAndLiveCourse(formData).then(res => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Saved and Updated successfully",
      });
      setIsSaveAndAddDisabled(false)
      getData();
    }).catch(err => {
      console.log("error ---> ", err);
      setIsSaveAndAddDisabled(false)
    })
  }

  function closeCourses() {
    getData();
    setAssignQualAndCourse([...assignQualAndCourse])
    $("#available").modal('hide');
  }

  return (
    <>
      <AssignedProgrmasAndCourses data={assignQualAndCourse} setData={setAssignQualAndCourse} onDelete={onDelete} dropdownValues={dropdownValues} loading={loading} availdata={availQualAndCourse} isAlreadyExists={isAlreadyExists} assignCourse={assignCourse} availloading={availableLoading} assignCourses={assignCourses} closeCourses={closeCourses} />
      <div className="btn-sec t-b-s table-end-btn" style={{ maxWidth: "fit-content" }}>
        {!loading && <div className="form-group form-group-save-cancel">
          <button
            className="btn btn-save btn-success"
            type="button"
            title="Save"
            disabled={isSaveDisabled}
            onClick={() => assignCourses()}
          >
            {isSaveDisabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}Save
          </button>
          {(assignQualAndCourse && assignQualAndCourse.length) ?
            ((portalStatus.status == "Re-Registration-Sales Admin" ||
              portalStatus.status == "Re-Posted" ||
              portalStatus.status == "Re-Reg Re-Posted" ||
              portalStatus.status == "Re-Registration-Study Kit Pending" ||
              portalStatus.status == "Re-Registration-Final" ||
              portalStatus.status == "Review") ||
              portalStatus.accept == 1) &&
            <button
              className="btn btn-save btn-success"
              type="button"
              disabled={isSaveAndAddDisabled}
              title="Save & Add To live Portal"
              onClick={() => handleLivePortal()}>
              {isSaveAndAddDisabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}Save & Add To live Portal
            </button> :
            ""
          }
          <button
            className="btn btn-danger"
            type="button"
            title="Cancel"
            onClick={() => getData()}>
            <i className="fal fa-times"></i>Cancel
          </button>
        </div>}
      </div>
    </>
  )

}

export default CourseAndProgrammeTab