import React, { useState, useMemo, useEffect } from "react";
import Header from '../common/Header';
import SideBar from '../common/SideBar';
import Tablefilter from "../common/Tablefilter";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE } from "../../utils/Constants";
import moment from "moment";
import { Link, useHistory, useParams } from 'react-router-dom';
import AuditTrails from "../courses/AuditTrail";
import {
  getBookedCoursesList,
  getBookedCoursesListCancelToken
} from '../../services/CourseService';
import {
  RenderBookingState,
  RenderBrandListMultiLine,
  RenderCampus,
  RenderITSetup,
  RenderCateringSetup,
  RenderLearningMethod
} from "../../utils/CommonGroupingItem";
import axios from "axios";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import Swal from "sweetalert2";
import PermissionsGate from '../../utils/permissionGate';
import { useSelector } from 'react-redux';
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import DataTable from "react-data-table-component";

const BookedCourses = () => {
  const history = useHistory();
  const { tab } = useParams();
  const [search, setSearch] = useState("");
  const [dateFilter, setdateFilter] = useState({ arr: [], checkObj: {} });
  const [courseDropdown, setCourseDropdown] = useState({ state: [{label: "Closed", value: "Closed"},{label: "Open", value: "Open"},{label: "Delivered", value: "Delivered"},{label: "Cancelled", value: "Cancelled"}], date: [{label: "Upcomming",value: "future"},{label: "Next 30 Days",value: "Next_30"},{label: "Next 7 Days",value: "Next_7"},{label: "Today",value: "today"},{label: "Past",value: "past"}] })
  const [courseId, setCourseId] = useState("");
  const [loading, setLoading] = useState(false)
  const [coursebookingList, setcoursebookingList] = useState([]);
  
  useEffect(() => {
    const cancelTokenSources = [];
    const getBookedCoursesList = async () => {
      setLoading(true);
      
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
      
      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);
      var dateValue = dateFilter?.arr ? dateFilter.arr[0] : "All";
      let data = {
        searchInput: search,
        filterDate: dateValue
      };
      
      try {
        const res = await getBookedCoursesListCancelToken(data, source.token);
        setcoursebookingList(res.data.data);
        if (res.status == 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
    
    getBookedCoursesList();
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [
    search, dateFilter
  ])
  
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateBookedCourses"));
    if (storedFilters) {
      setdateFilter(storedFilters.dateFilter);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      dateFilter
    };
    localStorage.setItem("filterStateBookedCourses", JSON.stringify(filterState));
  }, [dateFilter]);

  useEffect(() => {
    const cancelTokenDropdownSources = [];
    
    const fetchData = async () => {
      cancelTokenDropdownSources.forEach(source => {
          source.cancel('New request made');
      });
      
      const source = axios.CancelToken.source();
      cancelTokenDropdownSources.push(source);
      let data = {
        searchInput: "",
        filterDate: "All"
      };
      try {
        const res = await getBookedCoursesListCancelToken(data, source.token);
      } catch (error) {
          if (!axios.isCancel(error)) {
              console.error(error);
          }
      }            
    };
    
    fetchData();
    
    return () => {
      cancelTokenDropdownSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [tab]);
  const myNameFilter = (a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  };
  const PrelimSort = (a, b) => {
    return a.prelim_total > b.prelim_total ? 1 : -1;
  };
  const FinalSort = (a, b) => {
    return a.final_total > b.final_total ? 1 : -1;
  };
  
  const sortingStart = (a, b) => {
    return moment(a.start).unix() > moment(b.start).unix() ? 1 : -1;
  };
  
  const sortingEnd = (a, b) => {
    return moment(a.end).unix() > moment(b.end).unix() ? 1 : -1;
  };
  const sortingLaptop = (a, b) => {
    return a.laptop_total > b.laptop_total ? 1 : -1;
  };
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  const resetFilter = () => {
    setSearch("");
    setdateFilter({ arr: [""], checkObj: {} });
  };
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      wrap: true,
      sortFunction: myNameFilter,
      cell: (row) => (
        <span className="feature-name">
        <PermissionsGate scopes={["cbview"]} errorProps={{ disabled: true }}>
        <Link
        className="as-text-blue curser textLimit50"
        to={`/courseAdministration/bookedcourses/open/${row.id}`}
        title={row.name}
        >
        {row.name ? row.name : "-"}
        </Link>
        
        
        </PermissionsGate>
        </span>
        ),
      }, 
      {
        name: "Status",
        selector: "Status",
        sortable: false,
        wrap: true,
        cell: (row) => RenderBookingState(row.state_id ? row.state_id : row.state).html,
      },
      {
        name: "Final Total",
        selector: "finaltotal",
        sortable: true,
        sortFunction: FinalSort,
        cell: (row) => (<span className="final-total" style={row.final_total && row.final_total > 0 ? {"backgroundColor": "yellow", "padding": "5px"} : {}}>{row.final_total}</span>),
      },
      {
        name: "Prelim Total",
        selector: "prelimtotal",
        sortable: true,
        sortFunction: PrelimSort,
        cell: (row) => (<span className="prelim-total" style={row.prelim_total && row.prelim_total > 0 ? {"backgroundColor": "#FFCCCB", "padding": "5px"} : {}}>{row.prelim_total}</span>),
      },
      {
        name: "Laptops Required",
        selector: "laptops",
        sortable: true,
        sortFunction: sortingLaptop,
        cell: (row) => (<span className="laptops" style={row.laptop_total && row.laptop_total > 0 ? {"backgroundColor": "lightblue", "padding": "5px"} : {}}>{row.laptop_total}</span>),
      },
      {
        name: "Start",
        selector: "start",
        sortable: true,
        wrap: true,
        sortFunction: sortingStart,
        cell: (row) => (
          <>
            <span className="start">
              {moment(row.start_date, "DD/MM/YYYY").format(TABLE_DATE_FORMAT)}
            </span>
            <br />
            <span>{row.start_time}</span>
          </>
        ),
      },      
      {
        name: "End",
        selector: "end",
        sortable: true,
        wrap: true,
        sortFunction: sortingEnd,
        cell: (row) => (
          <>
            <span className="start">
              {moment(row.end_date, "DD/MM/YYYY").format(TABLE_DATE_FORMAT)}
            </span>
            <br />
            <span>{row.end_time}</span>
          </>
        ),
      },
      {
        name: "Campus",
        selector: "campus",
        sortable: false,
        cell: (row) => RenderCampus(row.campus).html,
      },
      {
        name: "Room",
        selector: "room",
        sortable: false,
        wrap: true,
        cell: (row) => (<span className="room textLimit100">{row.room}</span>),
      },
      {
        name: "Instructor",
        selector: "instructor",
        sortable: false,
        cell: (row) => (
          <>
            {row.instructor_AMI_Id ? (
              <a href={`https://ami.myaie.ac/systemAdministration/userManagement/open/${row.instructor_AMI_Id}/aboutYou`}>
                {row.instructor}
              </a>
            ) : (
              <span className="laptops" style={!row.instructor || row.instructor == "" ? {backgroundColor: "red", padding: "5px 30px"} : {}}>
                {row.instructor}
              </span>
            )}
          </>
        ),
      },      
      {
        name: "Brand",
        selector: "Brand",
        sortable: false,
        wrap: true,
        cell: (row) => (row.brand_color ? RenderBrandListMultiLine(row.subsidiary).html : <span className="brand textLimit50">{row.subsidiary}</span>),
      },
      {
        name: "Catering Setup",
        selector: "catering",
        sortable: false,
        wrap: true,
        cell: (row) => RenderCateringSetup(row.catering_setup),
      },     
      {
        name: "IT Setup",
        selector: "itsetup",
        sortable: false,
        wrap: true,
        cell: (row) => RenderITSetup(row.it_setup),
      },
      {
        name: "Actions",
        selector: "",
        cell: (row) => (
          <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons">
              <PermissionsGate
                scopes={["cbview"]}
                RenderError={() => (
                  <button
                    className="btn btn-primary rounded-circle"
                    title="Open"
                    disabled
                  >
                    <i className="fal fa-folder-open"></i>
                  </button>
                )}
              >
                <Link
                  to={`/courseAdministration/bookedcourses/open/${row.id}`}
                  className="btn btn-primary rounded-circle"
                  title="Open"
                >
                  <i className="fal fa-folder-open"></i>
                </Link>
              </PermissionsGate>
            </div>
          </div>
        ),
      }
      ], []);
      
      const exportData = (fileType, fileName) => {
        let data = coursebookingList;
        let header = [
          'Name',
          'State',
          'Final total',
          'Prelim total',
          'Laptop total',
          'Start date',
          'Start time',
          'End date',
          'End time',
          'Campus',
          'Room',
          'Instructor',
          'Brand',
          'Catering setup',
          'IT setup',
          'Learning method',
        ];
        
        Swal.fire({
          title: "File downloading",
          onOpen: function () {
            Swal.showLoading();
          },
        });
        
        var dateValue = dateFilter?.arr ? dateFilter.arr[0] : "All";
        let data1 = {
          data: {
          searchInput: search,
          filterDate: dateValue
          }
        };
        getBookedCoursesList(data1)
        .then((res) => {
          data = res.data.data;
          data = data?.map((row) => ({
            Name : row.name,
            State : row.state,
            'Final total' : row.final_total,
            'Prelim total' : row.prelim_total,
            'Laptop total' : row.laptop_total,
            'Start date' : row.start_date,
            'Start time' : row.start_time,
            'End date' : row.end_date,
            'End time' : row.end_time,
            Campus : row.campus,
            Room : row.room,
            Instructor : row.instructor,
            Brand : row.subsidiary,
            'Catering setup' : row.catering_setup,
            'IT setup' : row.it_setup,
            'Learning method' : row.learning_method
          }));
          
          if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
              const obj = {};
              header.forEach((col, index) => {
                obj[col] = row[col];
              });
              return obj;
            });
            
            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);
            Swal.close();
            return false;
          }
        })
        .catch((error) => {
          console.log("error >>>>>> ", error);
        });
      };
      return (      
        <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
            <div className="sidebar-right">
              <div className="sub-menu-content-block">
                <div className="sub-menu-right-block">
                  <div className="wrap-box-right">
                    <div className="my-tickets-heading-blk flex-direction mb-2">
                      <div className="d-flex align-items-center ">
                        <div className="name-w-head d-flex align-items-center ">
                          <h4 className="text-left-align landing-heading heading_color_dynamic">
                            Course <span>Bookings</span>
                          </h4>
                        </div>
                        <div className="text-md-right action2-blk">
                          <div className="ticket-view-btn d-flex align-items-center">
                            <div className="add-new-ticket-bx">
                              <button
                              onClick={() => history.goBack()}
                              title="Back"
                              className="btn btn-white-bordered"
                              >
                                <i className="fal fa-angle-left"></i>Back
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tabs-wrap">
                      <ul className="nav nav-pills mb-4" id="tabs"role="tablist">
                        <li className="nav-item" onClick={() => { history.push("/courseAdministration/bookedcourses");}}>
                          <a
                            className={`nav-link cursor-pointer active`}
                            id="pills-tab1"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-tab12"
                            aria-selected="true"
                          >
                            <i className="fal fa-globe-africa"></i>Details
                          </a>
                        </li>                        
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className={"tab-pane fade active show"}
                            id="pills-tab22"
                            role="tabpanel"
                            aria-labelledby="pills-tab2"
                          >
                            {tab === "audit-trails" && <AuditTrails />}
                          </div>
                        </div>
                      </ul>
                    <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab11"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
                      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom days-overdue-cell-width">
                        <div className="search-filter-div">
                          <div className="search-filter-div-left">
                            <div className="system-administration-table table-responsive">
                              <div className="table-responsive-div">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer" >
                                  <div id="assessment-table-main_filter" className="dataTables_filter">
                                    <label>
                                      <input
                                      type="search"
                                      className=""
                                      placeholder="Search"
                                      aria-controls="assessment-table-main"
                                      onChange={handleSearchFilter}
                                      value={search}
                                      />
                                    </label>
                                  <div className="filter-eff filter-data-btn">
                                    <button className="filter-buttons">
                                    <i className="fal fa-filter"></i>                                                 
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="filter-button-group">
                              <div className="filter-scroll">
                                <div className={`filter-scroll-inner  filter-custom-new`}>
                                  <Tablefilter                           
                                  filterName="Date Range"
                                  optionArr={courseDropdown.date}
                                  state={dateFilter}
                                  setState={setdateFilter}
                                  singleSelect="true"
                                  />
                                </div>                                                               
                              </div>
                            <div className="reset-btn-group">
                              <div className="button-reset dropdown-comman">
                                <button
                                className="btn btn-primary"
                                onClick={() => resetFilter()}
                                title="Reset"
                                >
                                <i className="fal fa-redo"></i>Reset
                                </button>
                              </div>        
                              <div className="files-export-group">
                              <button
                              type="button"
                              className="btn btn-files"
                              onClick={() => {
                                exportData("xlsx", "BookingList");
                              }}
                              title="Export spreadsheet"
                              >
                                <i className="fal fa-file-excel icon"></i>
                              </button>               
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="search-filter-div-right">
                        <div className=" filter-search-bar-blk">
                          <div className="add-ticket-blk button-reset dropdown-comman">
                          </div>
                        </div>
                      </div>
                    </div>
                    </div> 
                    <DataTable
                    defaultSortField="start"
                    defaultSortAsc={false}
                    data={coursebookingList}
                    columns={columns}
                    pagination={true}
                    progressComponent={<SkeletonTicketList />}
                    progressPending={loading}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                    />    
                    </div>
                  </div>
                </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>   
        </>
        );
        
      };
      
      export default BookedCourses;
      