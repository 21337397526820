import React, { useEffect, useState } from "react";
import Header from "../../../common/Header";
import SideBar from "../../../common/SideBar";
import { useFormik } from "formik";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import * as Yup from "yup";
import {
  createQuiz,
  getQuizDetails,
  getQuizDetailsCancelToken,
  GetQuizTopics,
  GetQuizTopicsCancelToken,
  GetStudentDropdownList,
  GetStudentDropdownListCancelToken,
} from "../../../../services/SmartAssessmentService";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { getCommonContent } from "../../../../services/ProgramService";
import { RenderPMCStatus } from "../../../../utils/CommonGroupingItem";
import HtmlParser from "react-html-parser";
import { CheckJSONString } from "../../../common/CheckJSONString";
import draftToHtml from "draftjs-to-html";
import axios from "axios";
import PermissionsGate from "../../../../utils/permissionGate";
const AddAssessment = () => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [editorState1, setEditorState1] = React.useState("");
  const [disabled, setDisabled] = useState(false);

  const [userList, setUserList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const [data, setData] = useState();
  const [topicList, setTopicList] = useState([]);
  const [selectKeywords, setSelectedKeywords] = useState([]);
  const [topicArray,setTopicArray] = useState([]);
  const [typeArray,setTypeArray] = useState([]);

  const statusArr = [
    {
      value: "Draft",
      label: HtmlParser(`<span class="red" title="Draft">Draft</span> `)
    },
    {
      value: "New",
      label: HtmlParser(`<span class="skyblue" title="New">New </span>`)
    },
    {
      value: "Final",
      label: HtmlParser(`<span class="green" title="Final">Final</span> `)
    },
    {
      value: "Approved",
      label: HtmlParser(`<span class="yellowshade" title="Approved">Approved</span>`)
    },
    // {
    //   value: "Active",
    //   label: HtmlParser(`<span class="green" title="Final">Active</span>`)
    // },
    {
      value: "Inactive",
      label: HtmlParser(`<span class="blue" title="Inactive">Inactive</span>`)
    },
  ]

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let background = RenderPMCStatus(data.value).bgColor;
      let color = RenderPMCStatus(data.value).colorCode;

      return {
        ...styles,
        backgroundColor: background,
        color: color,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };
  const multiSelectColorStyles = {      
    multiValueLabel: (styles, { data }) => ({
      ...styles,
        '& span':{
          background: 'transparent !important',
          color: '#333 !important',
        }
    }),
  };

  useEffect(() => {
    const studentDropdownList = axios.CancelToken.source();
    const quizDetails = axios.CancelToken.source();
    const commonContent = axios.CancelToken.source();
    const quizTopics = axios.CancelToken.source();
  
    const fetchStudentDropdownList = async () => {
      try {
        const res = await GetStudentDropdownListCancelToken(studentDropdownList.token);
          if(res.status == 200){
            setUserList(res.data.students);
          }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const fetchQuizDetails = async () => {
      try {
        const res = await getQuizDetailsCancelToken({ id: id }, quizDetails.token);
          if(res.status == 200){
            setData(res.data[0]);
          // setEditorState1(res.data[0].description);
          let generalNote= CheckJSONString(res.data[0].description);
              if(generalNote){
                const blocksFromHTML2 = convertFromRaw(JSON.parse(res.data[0].description));
                let dataDescr=EditorState.createWithContent(blocksFromHTML2);
                const note = draftToHtml(convertToRaw(dataDescr.getCurrentContent()));
                setEditorState1(note ? note :"<p><p/>")
              }
              else{
                setEditorState1(res.data[0].description)
              }
            let keywords = (res.data[0].keywords && res.data[0].keywords.length > 0 && Array.isArray(JSON.parse(res.data[0].keywords))) ? JSON.parse(res.data[0].keywords).map(item=>item) : [];
            setSelectedKeywords(keywords);
            let topics =  (res.data[0].topic && ((res.data[0].topic).split(",")).length > 0) ? ((res.data[0].topic).split(",")).map(item => item) : [];
            setTopicArray(topics);
          }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const fetchCommonContent = async () => {
      try {
        const res = await getCommonContent(commonContent.token);
          if(res.status == 200){
            setStatusList(res?.data?.quizStatus);
          }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const fetchQuizTopics = async () => {
      try {
        const res = await GetQuizTopicsCancelToken({},quizTopics.token);
          if(res.status == 200){
            let tempArr = res?.data?.map(item=>{return {value:item?.id, label: HtmlParser(`<span style="color:#fff;background:${item?.color};" title=${item?.options}>${item?.options}</span>`)}});
            setTopicList(tempArr);
          }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    if(id) fetchQuizDetails();
    fetchStudentDropdownList();
    fetchCommonContent();
    fetchQuizTopics();

    return () => {
      studentDropdownList.cancel('Component unmounted');
      quizDetails.cancel('Component unmounted');
      commonContent.cancel('Component unmounted');
      quizTopics.cancel('Component unmounted');
    };
  }, []);

  let selectedTopics = [];

  let selectedTypes = [];
  

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.quiz_name ? data?.quiz_name : "",
      maximum_attempts: data?.maximum_attempts ? data?.maximum_attempts : "",
      pass_percentage: data?.pass_percentage ? data?.pass_percentage : "",
      timeHour: data?.time_limit_hours ? data?.time_limit_hours : 0,
      timeMin: data?.time_limit_minutes ? data?.time_limit_minutes : 0,
      status: data?.status ? data?.status : "",
      duplicateId: "",
      view_answer: data?.show_correct_answer && data?.show_correct_answer == 1 ? true : false,
      description: data?.description,
      keywords: selectKeywords,
      topic: selectedTopics,
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Name is required").trim(),
      maximum_attempts: Yup.string()
        .required("Max Attempts is required")
        .matches("^[0-9]*$", "Maximum Attempts Only accept numbers")
        .test(
          "Is positive?",
          "Maximum Attempts must be between One and Ten",
          (value) => parseInt(value) > 0 && parseInt(value) < 11
        ),
      // timeHour: Yup.string().required("Hours is required"),
      timeMin: Yup.string().when(["timeHour"], {
        is : (timeHour)=>(timeHour=="" || timeHour==undefined || parseInt(timeHour)<1),
        then : Yup.string().test("Is Valid", "Time Limit should be at least 5 min.", (value) => parseInt(value)>=5)
      }),
      status: Yup.string().required("Status is required"),
      pass_percentage: Yup.string()
        .matches("^[0-9]*$", "Minimum Percentage Only accept numbers")
        .required("Minimum Percentage is required"),
      // description: Yup.string().required("Description is required"),
      // .test("required", "Description is required", (value) => {
      //     if ((formatQuestion(editorState1).replaceAll("&nbsp;", " ")).trim() == "" && !editorState1.includes("<img")) {
      //         return false
      //     }
      //     if (!editorState1) {
      //         return false
      //     }
      //     return true;
      // })
      // .trim(),
      // ip_address: Yup.string().test("IP Test", "Please Enter a Valid IP Address", (value) => ipv4.test(value) || ipv6.test(value))
      keywords: Yup.array().min(1, "Atleast one Keyword is required").required("Keyword is required").nullable(),
      topic: Yup.array().min(1, "Atleast one Topic is required").required("Topic is required").nullable(),
    }),
    

    onSubmit: (values) => {
      setDisabled(true);

      // formik.values.open_date_time = formik.values.open_date_time ? moment(formik.values.open_date_time).format("X") : "";
      // formik.values.close_date_time = formik.values.close_date_time ? moment(formik.values.close_date_time).format("X") : "";

      // values = { ...values, description: editorState1}

      // if (location.pathname.includes("duplicateassessment")) {
      //   values.duplicateId = id
      // }
      const selectedTopics = values.topic.map(item=>item?.value).join(",");
      const keywords = values.keywords;

      const body = {
        quiz_name: values.name,
        maximum_attempts: values.maximum_attempts,
        pass_percentage: values.pass_percentage,
        show_correct_answer: values.view_answer == true ? 1 : 0,
        time_limit_hours: Number(values.timeHour),
        time_limit_minutes: Number(values.timeMin),
        status: values.status,
        description: values.description,
        keywords: JSON.stringify(keywords),
        topic: selectedTopics,
        id: id
      };
      createQuiz(body)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Added Successfully",
          });
          history.push("/courseAdministration/assessment/quizzes/open/details/"+res.data.inserted_record);
          setDisabled(false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err?.response?.data?.message,
          });
          setDisabled(false);
        });
    },
  });



  const handlereset = () => {
    formik.resetForm();
    setEditorState1(EditorState.createEmpty());
    history.push("/courseAdministration/assessment/quizzes/list");
  };

  const hourHandle = () => {
    let hourArr = [];
    for (let i = 0; i <= 23; i++) {
      hourArr.push({
        value:
          i.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
        label:
          i.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) + "h",
      });
    }
    // ()=>setHourArray(hourArr)
    return hourArr;
  };

  const MinutesHandle = () => {
    let minutesArray = [];

    for (let i = 0; i <= 59; i++) {
      minutesArray.push({
        value:
          i.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
        label:
          i.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) + "min",
      });
    }
    // ()=>{setMinuteArray(minutesArray)}
    return minutesArray;
  };

  const handleKeyDown1 = (event) => {
    let keywords = selectKeywords;
    if (event.key === "Enter") {
      if (event.target.value) {
        keywords.push(event.target.value);
        event.target.value = ""
        formik.setFieldValue("keywords", keywords);
      }
    }
  };
  const removeKeywords = (index) => {
    let keyword = selectKeywords;
    let keywords = keyword.splice(index, 1);
    formik.setFieldValue("keywords", keyword);
  };

  

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-info-list Tickets-main-wrap">
                  <div className="my-tickets-heading-blk flex-direction">
                    <div class="d-flex align-items-center ">
                      <div class="name-w-head d-flex align-items-center ">
                        <h4 className="text-left-align landing-heading heading_color_dynamic">
                          {location.pathname.includes("duplicateassessment")
                            ? "Duplicate"
                            : "Add"}{" "}
                          <span>Quiz</span>
                        </h4>
                      </div>
                      <div class="text-md-right action2-blk">
                        <div class="ticket-view-btn d-flex align-items-center">
                          <div class="add-new-ticket-bx">
                            <button
                              onClick={() => history.goBack()}
                              title="Back"
                              class="btn btn-white-bordered"
                            >
                              <i class="fal fa-angle-left"></i>Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tabs-wrap">
                    <div className="">
                      <div className="card card-profile-info-card course-details-tab-sec">
                        <div>
                          <form onSubmit={formik.handleSubmit}>
                            <div className="course-info-sec">
                              {/* <div className="edit-icon new-card-header">
                                <div className="card-header">Assessment Details</div>
                              </div> */}
                              <div className="card-body-inr card-body-info">
                                <div className="row">
                                  <div className="col-md-6 col-lg-2">
                                    <label>Name *</label>
                                    <div
                                      className="form-icon-group mb-4"
                                      title="Name"
                                    >
                                      <input
                                        type="text"
                                        className={
                                          "form-control" +
                                          (formik.errors.name &&
                                          formik.touched.name
                                            ? " is-invalid"
                                            : "")
                                        }
                                        name="name"
                                        placeholder="Name"
                                        title="Name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-2">
                                    <label>Maximum Attempts *</label>
                                    <div
                                      className="form-icon-group mb-4"
                                      title="Maximum Attempts"
                                    >
                                      <input
                                        type="number"
                                        className={
                                          "form-control" +
                                          (formik.errors.maximum_attempts &&
                                          formik.touched.maximum_attempts
                                            ? " is-invalid"
                                            : "")
                                        }
                                        name="maximum_attempts"
                                        placeholder="Maximum Attempts"
                                        title="Maximum Attempts"
                                        value={formik.values.maximum_attempts}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-2">
                                    <label>Minimum Percentage * </label>
                                    <div
                                      className="form-icon-group mb-4"
                                      title="Minimum Percentage"
                                    >
                                      <input
                                        type="number"
                                        className={
                                          "form-control" +
                                          (formik.errors.pass_percentage &&
                                          formik.touched.pass_percentage
                                            ? " is-invalid"
                                            : "")
                                        }
                                        name="pass_percentage"
                                        placeholder="Minimum Percentage"
                                        title="Minimum Percentage"
                                        value={formik.values.pass_percentage}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-2">
                                    <div className="form-group-blk mb-3">
                                      <label>Time Limit</label>
                                      <div  className="row form-grp-dates time-select">
                                        <Select
                                          // isDisabled={!isEditable && type === "open"}
                                          isDisabled={
                                            formik.values.isDuration === false
                                          }
                                          className={
                                            "form-control custom-select-box time-box" +
                                            (formik.errors.timeHour &&
                                            formik.touched.timeHour
                                              ? " is-invalid"
                                              : "")
                                          }
                                          name="Hour"
                                          title="Time Limit Hour"
                                          placeholder={
                                            formik.values.timeHour
                                              ? formik.values.timeHour
                                              : "Hours"
                                          }
                                          options={hourHandle()}
                                          onBlur={formik.handleBlur}
                                          onChange={(value) => {
                                            if (value) {
                                              formik.setFieldValue("timeHour", value.value);
                                            } else {
                                              formik.setFieldValue("timeHour", "");
                                            }                                            
                                          }}
                                          value={hourHandle().filter((val) => {
                                            return (
                                              val.value == 
                                              Number(
                                                formik.values.timeHour
                                              ).toLocaleString("en-US", {
                                                minimumIntegerDigits: 2,
                                                useGrouping: false,
                                              })
                                            );
                                          })}
                                          isClearable
                                        />
                                        <Select
                                          // isDisabled={!isEditable && type === "open"}
                                          isDisabled={
                                            formik.values.isDuration === false
                                          }
                                          className={
                                            "form-control custom-select-box time-box" +
                                            (formik.errors.timeMin &&
                                            formik.touched.timeMin
                                              ? " is-invalid"
                                              : "")
                                          }
                                          name="Minute"
                                          title="Time Limit Minute"
                                          placeholder={
                                            formik.values.timeMin
                                              ? formik.values.timeMin
                                              : "Minutes"
                                          }
                                          options={MinutesHandle()}
                                          onBlur={formik.handleBlur}
                                          onChange={(value) => {
                                            if(value){
                                              formik.setFieldValue("timeMin",value.value);
                                            } else {
                                              formik.setFieldValue("timeMin","");
                                            }
                                          }}
                                          value={MinutesHandle().filter(
                                            (val) => {
                                              return (
                                                val.value == 
                                                Number(
                                                  formik.values.timeMin
                                                ).toLocaleString("en-US", {
                                                  minimumIntegerDigits: 2,
                                                  useGrouping: false,
                                                })
                                              );
                                            }
                                          )}
                                          isClearable
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-2">
                                    <label>Status *</label>
                                    <div
                                      className="form-icon-group mb-4"
                                      title="Status"
                                    >
                                      <Select
                                        styles={colourStyles}
                                        className={
                                          "form-control color-drop custom-select-box " +
                                          (formik.errors.status &&
                                          formik.touched.status
                                            ? " is-invalid"
                                            : "")
                                        }
                                        name="status"
                                        value={statusArr.filter(val=>val.value ===  formik.values.status)}
                                        onChange={(selectedOption) => {
                                          if (selectedOption) {
                                            formik.setFieldValue("status", selectedOption.value);
                                          } else {
                                            formik.setFieldValue("status", "");
                                          }
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={statusArr}
                                        maxMenuHeight={175}
                                        isClearable
                                      />

                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-2">
                                    <div className="form-icon-group mt-4">
                                      <div className="custom-check-group ">
                                        <div className="custom-check custom-control custom-checkbox mt-2">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customCheck54"
                                            name="view_answer"
                                            checked={formik.values.view_answer}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="customCheck54"
                                          >
                                            <b>Show Correct Answers</b>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-2 mb-4">
                                    <label>Keywords*</label>
                                    <div className="form-icon-group">
                                      <div className="form-control custom-select-box">
                                        <input
                                          type="text"
                                          className={"form-control " +(formik.errors.keywords && formik.touched.keywords? " is-invalid": "")}
                                          name="keywords"
                                          placeholder="keywords"
                                          title="keywords"
                                          onBlur={formik.handleBlur}
                                          onKeyDown={handleKeyDown1}
                                        />
                                        {formik.errors.keywords && formik.touched.keywords ? (<span className="exclamation"><i className="fal fa-exclamation-circle"></i></span>) : null}
                                      </div>
                                    </div>
                                    {selectKeywords.length > 0 && (
                                      <ul className="tag-list mb-0">
                                        {selectKeywords?.map((ele, index) => (
                                          <li>
                                            {ele}
                                            <span onClick={() => removeKeywords(index)}>
                                              <i className="fal fa-times"></i>
                                            </span>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </div>
                                  <div className="col-md-6 col-lg-2 mb-4">
                                    <div className="form-group-blk" title="Topic">
                                      <label>Topic*</label>
                                      <Select
                                        styles={multiSelectColorStyles}
                                        className={"form-control color-drop custom-select-box is-multi " + (formik.errors.topic && formik.touched.topic ? " is-invalid" : "")}
                                        name="topic"
                                        value={formik.values.topic}
                                        onChange={(value) => formik.setFieldValue("topic",value)}
                                        onBlur={formik.handleBlur}
                                        options={topicList}
                                        maxMenuHeight={175}
                                        isMulti={true}
                                        placeholder="Select Topic"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                  <label>Description</label>
                                    <HtmlInputEditor
                                      editorState={formik.values.description}
                                      setEditorState={(value)=>formik.setFieldValue("description", value)}
                                      hideSign={true}
                                      belowNoMargin={true}
                                      isCKEditor={true}
                                      isInvalid={formik.touched.description && formik.errors.description}
                                      // from = "quizManagement"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group form-group-save-cancel mt-3">
                            <PermissionsGate scopes={["cquizmadd"]}>
                              <button
                                className="btn btn-save btn-success"
                                type="button"
                                title="Save"
                                disabled={disabled}
                                onClick={() => formik.handleSubmit()}
                              >
                                {disabled ? (
                                  <i className="fas fa-cog fa-spin"></i>
                                ) : (
                                  <i className="fal fa-save"></i>
                                )}
                                Save
                              </button>
                              </PermissionsGate>
                              <button
                                className="btn btn-close btn-danger"
                                type="button"
                                title="Cancel"
                                onClick={() => handlereset()}
                              >
                                <i className="fal fa-times"></i>Cancel
                              </button>
                              {Object.keys(formik.values).map((key, ind) => {
                                if (formik.touched[key] && formik.errors[key]) {
                                return (
                                    <div className="invalid-feedback d-block" key={ind}>
                                    {formik.errors[key]}
                                    </div>
                                )}
                              })}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAssessment;
