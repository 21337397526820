export const Color = (data) => {
  switch (data) {
    case "full":
      return "cat-pink";
    case "part":
      return "cat-skyblue";
    case "cores":
      return "cat-yellow";
    case "fulltimecontact":
      return "cat-red";
    case "fulltimeonline":
      return "cat-blue";
    case "parttimeonline":
      return "cat-greenhex";
  }
};

export const Color2 = (data) => {
  switch (data) {
    case "Not started":
      return "cat-greenhex";
    case "In-progress":
      return "cat-skyblue";
    case "Pending-sigh off":
      return "cat-yellow";
    case "Progress":
      return "cat-red";
    case "Finished":
      return "cat-blue";

    case "Closed":
      return "cat-greenhex";
    case "New":
      return "cat-skyblue";
    case "Pending-Investigation":
      return "cat-yellow";
    case "Open":
      return "cat-red";
    case "Pending-Academy":
      return "cat-blue";
    case "Pending-Student":
      return "cat-skyblue";

    case "Active":
      return "cat-greenhex";
    case "Cancelled":
      return "cat-skyblue";
    case "Drop Out":
      return "cat-yellow";
    case "In Active":
      return "cat-red";
    case "On Hold":
      return "cat-blue";

    case "Start & End Date":
      return "cat-greenhex";
    case "Start & Completed Date":
      return "cat-skyblue";
    case "Start & Comitted End Date":
      return "cat-yellow";
    case "Recommended Start & End Date":
      return "cat-red";

    case "administration":
      return "cat-greenhex";
    case "academic":
      return "cat-blue";
    case "student support":
      return "cat-skyblue";
    case "sales":
      return "cat-yellow";
    case "finance":
      return "cat-dark-red";

    default:
      return "cat-dark-red";
  }
};
