import React, { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useParams,useHistory } from "react-router";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Swal from "sweetalert2";
import "jspdf-autotable";
import $ from "jquery";
import { CreateShortCourseTopic, CreateTopic, DeleteShortCourseTopic, DeleteTopic, ListShortCourseTopics, ListTopics, ListTopicsCancelToken } from "../../../services/ProgramService";
import { useFormik } from "formik";
import * as Yup from "yup";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import { formatQuestion } from "../../../utils/FormatQuestion";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../utils/permissionGate";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const ShortCourseTopics = () => {
  const history=useHistory();
  const { id, type} = useParams();
  const [search1, setSearch1] = useState("");
  const [topics, setTopics] = useState();
  const [loadtopics, setLoadTopics] = useState(true);
  const [dummy, setDummy] = useState(false);
  const [disabledTopic, setDisabledTopic] = useState(false);
  const [moduleTopic, setModuleTopic] = useState("")
  const [showTopicPopup, setShowTopicPopup] = useState(false);
  const [modalIcon, setModalIcon] = useState(false)

  useEffect(() => {
    handleTableScroll()
  }, [loadtopics])

  useEffect(() => {
    const cancelTokenSources = [];

    const ListShortCourseTopic = async () => {
      setLoadTopics(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        const payload={
          shortcourse_id : id
        }
        const res = await ListShortCourseTopics(payload, source.token);
        if (res.status === 200) {
          setTopics(res.data.data);
          setLoadTopics(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) { 
          console.error(error);
          setLoadTopics(false);
        }
      }
    };
    
    ListShortCourseTopic()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [dummy, type]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["mtview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])


  const resetFilter1 = () => {
    setSearch1("");
  };


  const dataToRender = () => {
    let updatedData = [];
    let allData = topics;
    if (search1.length) {
      let tempId = allData.filter((item) => {
        let includes = item.topic_name
          .toString()
          .toLowerCase()
          .includes(search1.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempdata = [...tempId];
      let unique = [...new Set(tempdata)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (search1.length) {
      return updatedData;
    } else {
      return topics;
    }
  };

  const topicSort = (a, b) => {
    return a && a.toString().toLowerCase() > b && b.toString().toLowerCase()
      ? 1
      : -1;
  };


  const handleSearchFilter1 = (e) => {
    const value = e.target.value;
    setSearch1(value);
  };

  const exportData = (fileType, fileName) => {
    let data, header;

    data = dataToRender();
    header = ["Topic"];
    data = data.map((row) => ({
      ...row,
      // ID: row.id ? row.id : "-",
      Topic: row.topic_name ? formatQuestion(row.topic_name).replaceAll("&nbsp;"," ") : "-",
    }));
    // }

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      let compatibleData;
      compatibleData = data.map((row) => {
        return [row["Topic"]];
      });
      //   }
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };


  const columns = useMemo(() => [
    {
      name: "Topic",
      selector: "topic_name",
      sortable: true,
      sortFunction: topicSort,
      wrap: true,
      cell: (row) => (
        <PermissionsGate scopes={["mtedit"]} RenderError={() => (
          <span className="feature-name"> <span className="textLimit100">{formatQuestion(row.topic_name).replaceAll("&nbsp;"," ")}</span> </span>
        )}>
        <span className="as-text-blue curser feature-name"
        title={formatQuestion(row.topic_name).replaceAll("&nbsp;"," ")} onClick={async () => {
          $("#add-topic").modal("show")
          await formikX.setFieldValue("name", row.topic_name)
          await formikX.setFieldValue("topicId", row.id)
          setModuleTopic(row.topic_name)
          setModalIcon(true)
          setShowTopicPopup(true)
        }}>
          <span title={formatQuestion(row.topic_name).replaceAll("&nbsp;"," ")} className="textLimit100">{formatQuestion(row.topic_name).replaceAll("&nbsp;"," ")}</span>
        </span>
        </PermissionsGate>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 module-topic">
          <div className="as-buttons">
          <PermissionsGate RenderError={() => (
              <button className="btn btn-primary rounded-circle" title="Open" disabled>
                <i className="fal fa-folder-open"></i>
              </button>
            )} scopes={["mtedit"]}>
            <button
              title="Open"
              className="btn btn-primary rounded-circle"
              onClick={async () => {
                $("#add-topic").modal("show");
                await formikX.setFieldValue("name", row.topic_name)
                await formikX.setFieldValue("topicId", row.id)
                setModuleTopic(row.topic_name)
                setModalIcon(true)
                setShowTopicPopup(true)
              }}
            >
              <i className="fal fa-folder-open"></i>
            </button>
          </PermissionsGate>
          <PermissionsGate scopes={["mtdelete"]} RenderError={() => (
                  <button className="btn btn-danger rounded-circle" title="Delete" disabled>
                    <i className="fal fa-trash-alt"></i>
                  </button>
                )}>
            <button
              title="Delete"
              className="btn btn-danger rounded-circle"
              onClick={() => handleDeleteTopic(row.module_id, row.id)}
            >
              <i className="fal fa-trash-alt"></i>
            </button>
          </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleDeleteTopic = (ID, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
          DeleteShortCourseTopic({id: id })
            .then((res) => {
              Swal.fire("Deleted!", "Topic has been deleted.", "success");
              setDummy(!dummy);
            })
            .catch((error) => console.log(error));
        } 
        // listOfTopics();
    });
  };

  const formikX = useFormik({
    initialValues: { name: "", topicId: false },
    validationSchema: Yup.object({
      name: Yup.string()
      .test("required", "Topic is required", (value) => {
          if ((formatQuestion(moduleTopic).replaceAll("&nbsp;", " ")).trim() == "" && !moduleTopic.includes("<img")) {
              return false
          }
          if (!moduleTopic) {
              return false
          }
          return true;
      })
      .trim(),
    }),
    onSubmit: (values) => {
      setDisabledTopic(true);
        CreateShortCourseTopic({ ...values, shortcourse_id: id, id: values.topicId, name:moduleTopic })
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `${values.topicId ? "Updated" : "Added"} Successfully`,
          });
          setDisabledTopic(false);
          setLoadTopics(false);
          setDummy(!dummy);
          formikX.resetForm();
          $("#add-topic").modal("hide");
          setShowTopicPopup(false)
        })
        .catch((err) => {
          setShowTopicPopup(false)
          console.log(err)
        });
      }
  });



  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter1}
                          value={search1}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                  </div>
                 
                </div>
              </div>
              <div className="filter-button-group">
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={() => resetFilter1()}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Topic", "Topic");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Topic", "Topic");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Topic", "Topic");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div className="search-filter-div-right">
                <div className=" filter-search-bar-blk"> 
                  <div className="add-ticket-blk button-reset dropdown-comman">
                  <PermissionsGate RenderError={() => (
                      <button className="btn btn-primary" title="Create New" disabled>Create New
                      </button>
                    )} scopes={["mtadd"]}>
                    <button
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#add-topic"
                      title="Create New"
                      onClick={() => {
                        formikX.setFieldValue("name", "")
                        formikX.setFieldValue("topicId", false)
                        setModuleTopic("")
                        setShowTopicPopup(true);
                        setModalIcon(false)
                      }}
                    >
                      <i className="fal fa-plus"></i>Create New
                    </button>
                  </PermissionsGate>
                  </div>
                </div>
            </div>
        </div>
        <DataTable
          data={dataToRender()}
          columns={columns}
          pagination={true}
          progressComponent={<SkeletonTicketList />}
          progressPending={loadtopics}
          noDataComponent={Str.noRecord}
          defaultSortAsc={true}
          defaultSortField={"topic_name"}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
      {showTopicPopup && <div
        className="topic-add-modal modal fade show"
        style={{ display: "block" }}
        id="add-topic"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content modal-border-update">
            <form onSubmit={formikX.handleSubmit}>
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                  {modalIcon ? <><i className="fal fa-folder-open"></i> Edit</> : <><i className="fal fa-plus"></i> Add</>} Topic
                  </h5>
                  <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    $("#add-topic").modal("hide");
                    formikX.setFieldValue("name", "")
                    setShowTopicPopup(false)
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="t p-30px">
                  <div className="topic-add-modal-content">
                    <div className="row center-label">
                      <div className="col-12">
                        <label>Topic *</label>
                        <HtmlInputEditor
                          editorState={moduleTopic}
                          setEditorState={setModuleTopic}
                          isCKEditor={true}
                          hideSign={true}
                          isInvalid={formikX.errors.name && formikX.touched.name}
                        />
                        {/* <input
                          type="text"
                          className={
                            "form-control" +
                            (formikX.errors.name && formikX.touched.name
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Enter Topic"
                          title="Enter Topic"
                          name="name"
                          value={formikX.values.name}
                          onChange={formikX.handleChange}
                          onBlur={formikX.handleBlur}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-group-save-cancel mt-3">
                    <button
                      className="btn btn-primary"
                      title="Save"
                      onClick={formikX.handleSubmit}
                      disabled={disabledTopic}
                    >
                      {disabledTopic ? (
                        <i className="fas fa-cog fa-spin"></i>
                      ) : (
                        <i className="fal fa-save"></i>
                      )}{" "}
                      Save
                    </button>
                    <button
                      className="btn btn-close btn-danger"
                      type="button"
                      title="Cancel"
                      onClick={() => {
                        $("#add-topic").modal("hide");
                        formikX.setFieldValue("name", "")
                        setShowTopicPopup(false)
                      }}
                    >
                      <i className="fal fa-times"></i>Cancel
                    </button>
                    {formikX.touched.name && formikX.errors.name ? (
                      <div className="invalid-feedback d-block">
                        {formikX.errors.name}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default ShortCourseTopics;
