import React, {useMemo} from "react";
import { IMAGE_URL} from "../../../utils/Constants";
import { getEventsAuditTrailList, getEventsAuditTrailListFilters } from "../../../services/WebsiteListServer";
import { Link } from "react-router-dom";
import AuditTrailsTable from "../../common/AuditTrailsTable";
import { useParams } from "react-router-dom";
import { RenderStudentResourceType } from "../../../utils/CommonGroupingItem";

function EventAuditTrail({apiParams,apiFunction,apiFilter, exportName}) {
  const { subId, subType, subTab, type , tab , id } = useParams();

  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]  
  return (
    <AuditTrailsTable 
    apiFunction={apiFunction}
    filterArrApi={apiFilter}
    columnsToShow={columnsToShow}
    exportFileName={exportName}
    isResourceNameShown={true}
    apiParams={apiParams}
    actionId={subId || id}
    isShowingResourceTypeFilter
    renderResourceTypeFunction={RenderStudentResourceType}
    />
  );
}

export default EventAuditTrail;
