import React, { useEffect, useState } from "react";
import TreeTheme from "react-sortable-tree-theme-minimal";
import Placeholder from "./PalaceHolder";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";
import Skeleton from "../../../../../../loaders/SkeletonTicketList";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import AddGroup from "./AddGroup";
import { RenderQuizStatus, RenderQuizTopic, wordSpliter } from "../../../../../../utils/CommonStatusItems";
import { RenderQuestionLevel } from "../../../../../../utils/CommonGroupingItem";
import { AssignedQuestionToGroup, GetGroupsAndQuestions, GetGroupsAndQuestionsCanceToken, QuestionDetails, QuestionGroupDelete, assignedQuizToQuestion } from "../../../../../../services/SmartAssessmentService";
import { Link, useParams } from "react-router-dom";
import { TrimText } from "../../../../../common/TrimText";
import ReactTooltip from 'react-tooltip';
import CustomTooltip from "../../../../../common/CustomTooltip";
import QBTable from "./QBTable";
import PermissionsGate from "../../../../../../utils/permissionGate";
import SekletonLearningMaterial from "../../../../../../loaders/SekletonLearningMaterial";
import DisplayQuestion from "../../../DisplayQuestion";
import $ from "jquery"
import axios from "axios";

const TitleWithIcon = ({ type, itemTitle }) => {
    const typeName = {
        "multiple choice single answer" : "fal fa-tasks ",
        "multiple choice multiple answer" : "far fa-check-square",
        "match the column" : "far fa-line-columns",
        "short answer" : "far fa-comment-alt-lines",
        "long answer" : "fal fa-align-left",
        "choice matrix" : "fal fa-table",
        "fill in the blank" : "far fa-keyboard",
        "order list" : "fas fa-sort-numeric-down",
        "document upload" : "fal fa-file-upload",
    };  
    
    return (
      <p className="assembly-list-title" title={itemTitle}>
        {type && <i className={"mr-2 " + typeName[type.toLowerCase()]}></i>} 
        {TrimText(itemTitle)}
      </p>
    );
  };

const QuestionGroup = ({refreshData}) => {
    const {id} = useParams();
    const [questionLoading, setQuestionLoading] = useState(false);
    const [questionData, setQuestionData] = useState({});
    const [renderData, setRenderData] = useState();
    const [loading, setLoading] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const history = useHistory();
    const [childId, setchildId] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [filterData, setFilterData] = useState({ status: [], topics:[] });
    const [errors, setErrors] = useState("");

    const handleChangeData = (newData) => {
        setRenderData(newData);
        setErrors("")
    };

    const handleDragNode = (dragData) => {
        const { isDragging } = dragData;

        if (isDragging === false) {
            setIsDisabled(false);
        }
    };

    const handleSaveData = () => {
        let groupHasData = true;
        let formatSendData = renderData.map((data)=>{
            if(data.type===1){
                if(data.children.length<=0){
                    groupHasData = false;
                }
                return{
                    group_id:data.id,
                    qid:data.children.map((child)=>({
                        qid:child.qid
                    }))
                }
            }else{
                return {
                    qid : data.qid
                }
            }
        });
        if(groupHasData){
            setIsDisabled(true);
            const dataToSend = {
                quiz_id:id,
                quid:formatSendData
            }
            AssignedQuestionToGroup(dataToSend).then((res)=>{
                if(res.status===200){
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: `${res?.data?.message}`,
                      }).then(() => {
                        // getData();
                        setRefresh(!refresh)
                      });
                }
            }).catch((err)=>{
                console.log(err);            
                Swal.fire({
                  icon: "error",
                  title: "error",
                  text: `${err?.response?.data[0]?.message}`,
                });
            }).finally(()=>{
                setIsDisabled(false)
            })
        }else{
            setErrors("All groups should have at least one question assigned to them.")
        }
    };

    const getData = () => {
        const parameter = {
            quiz_id: id
        }
        GetGroupsAndQuestions(parameter).then((res) => {
            if (res.status === 200) {
                const formatData = res.data.map((data)=>{
                    if(data.type===0){
                        return {
                            ...data.questions[0],
                            type:data.type,
                            title:data.questions[0] && data.questions[0].question_name ? <TitleWithIcon itemTitle={data.questions[0].question_name} type={data.questions[0].question_type} /> : "NA",  
                            keywords : data.questions[0] && data.questions[0].keywords ? JSON.parse(data.questions[0].keywords) : []                             
                        }
                    }else{
                        return {
                            id : data.id,
                            group_id : data.group_id,
                            title : <TitleWithIcon itemTitle={data.name} />,
                            quiz_id:data.quiz_id,
                            type:data.type,
                            children : data.questions ? data.questions.map((question)=>{
                                return {                                
                                    ...question,
                                    type:0,
                                    title:<TitleWithIcon itemTitle={question.question_name} type={question.question_type} />,
                                    keywords : question.keywords ? JSON.parse(question.keywords) : []
                                }
                            }) : []
                        }
                    }
                })
                setRenderData(formatData)
                setLoading(false)
            }
        }).catch((err) => {
            console.error(err);
        })
    }

    useEffect(() => {
        const cancelTokenSources = [];
    
        const getData = async () => {
      
          cancelTokenSources.forEach(source => {
            source.cancel('New request made');
          });
      
          const source = axios.CancelToken.source();
          cancelTokenSources.push(source);
      
          const parameter = {
            quiz_id: id
          }
      
          try {
            const res = await GetGroupsAndQuestionsCanceToken(parameter, source.token);
            if (res.status === 200) {
                const formatData = res.data.map((data)=>{
                    if(data.type===0){
                        return {
                            ...data.questions[0],
                            type:data.type,
                            title:data.questions[0] && data.questions[0].question_name ? <TitleWithIcon itemTitle={data.questions[0].question_name} type={data.questions[0].question_type} /> : "NA",  
                            keywords : data.questions[0] && data.questions[0].keywords ? JSON.parse(data.questions[0].keywords) : []                             
                        }
                    }else{
                        return {
                            id : data.id,
                            group_id : data.group_id,
                            title : <TitleWithIcon itemTitle={data.name} />,
                            quiz_id:data.quiz_id,
                            type:data.type,
                            children : data.questions ? data.questions.map((question)=>{
                                return {                                
                                    ...question,
                                    type:0,
                                    title:<TitleWithIcon itemTitle={question.question_name} type={question.question_type} />,
                                    keywords : question.keywords ? JSON.parse(question.keywords) : []
                                }
                            }) : []
                        }
                    }
                })
                setRenderData(formatData)
                setLoading(false)
            }
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.error(error);
              setLoading(false)
            }
          }
        };
      
        getData();
    
        return () => {
          cancelTokenSources.forEach(source => {
            source.cancel('Component unmounted');
          });
        };
      }, [refresh]);

    const handleViewQuestionDetails = (ID) => {
        setQuestionLoading(true)
        setQuestionData({});
        QuestionDetails(ID).then((res) => {
          setQuestionData(res.data[0]);
          setQuestionLoading(false)
        }).catch((errors) => {
          console.error("errorrs", errors)
          setQuestionLoading(false)
        })
    }

    const handleDelete = (ID) => {

        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, remove it!",
        }).then((result) => {
          if (result.isConfirmed) {
            assignedQuizToQuestion({ quizId: id, questionId: ID.toString(), deleteQulification: "true" }).then((res) => {
            //   Swal.fire("Removed!", "Your question has been removed.", "success");
              setRefresh(!refresh)
              refreshData();
            }).catch(err => Swal.fire({ icon: "error", title: "Error", text: err.response.data.message }));
    
          }
        })
    };

    const handleDeleteGroup = (id) =>{
        // console.log(id)
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, remove it!",
          }).then((result) => {
            if (result.isConfirmed) {
                QuestionGroupDelete({ group_id: id}).then((res) => {
                Swal.fire("Removed!", "Your group has been removed.", "success");
                setRefresh(!refresh)
                refreshData();
              }).catch(err => Swal.fire({ icon: "error", title: "Error", text: err.response.data.message }));
      
            }
          })
    }
    return (
        <div className="p-15px">
            <div className="main-block" style={{ height: "auto" }}>
                <div className="tree-group-view-header">
                    <Link                        
                        title="Standard View"
                        to={`/courseAdministration/assessment/quizzes/open/questions/${id}`}
                    >
                        <button className="btn btn-primary mr-2">
                            <i className="fal fa-eye"></i>Standard View
                        </button>
                    </Link>
                    <PermissionsGate scopes={["cquizmquadd"]} errorProps={{ disabled: true }}>
                        <button
                            title="Assign Question"
                            className="btn btn-primary mr-2"
                            data-toggle="modal" data-target="#assignQuestion"
                        >
                            <i className="fal fa-plus"></i> Assign Question
                        </button>
                    </PermissionsGate>
                    <button
                        title="Create Group"
                        data-toggle="modal"
                        data-target="#groupViewForm"
                        className="btn btn-primary"
                    >
                        <i className="fal fa-plus"></i> Create Group
                    </button>
                </div>
                <div className="tree-wrapper">
                    {loading ? (
                        <Skeleton />
                    ) : (
                        <SortableTree
                            theme={TreeTheme}
                            className="my-class"
                            isVirtualized={false}
                            treeData={renderData ? renderData : []}
                            onChange={(treeData) => handleChangeData(treeData)}
                            maxDepth={2}
                            onDragStateChanged={(dragData) => handleDragNode(dragData)}
                            style={{
                                padding: "0px",
                                width: "100%",
                            }}
                            canNodeHaveChildren = {(node)=>(node.type===1)}
                            generateNodeProps={(rowInfo) => {
                                const { node, parentNode } = rowInfo;
                                return {
                                    // canDrag :node.type===0 ? true : false,
                                    canDrop :node.type===0 ? true : false,
                                    class:
                                        node.type === 1
                                            ? "main-category-node"
                                            : node.type === 0
                                                ? "sub-category-node"
                                                : "item-node",
                                    buttons: [
                                        <div className="drag-option-container">                                            
                                            {node.type === 0 && <div className="group-view-ques-info">  
                                                {(node.status || node.level || node.nqf_level) ? 
                                                <>
                                                    <span className="mr-2">
                                                        <i class="fal fa-bars"></i>
                                                    </span>                                             
                                                    <span className="group-view-ques-status">
                                                        {(node.status ? `Status: ${node.status}` : "") +
                                                        (node.question_level ? `, Level: ${node.question_level}` : "") +
                                                        (node.nqf_level ? `, NQF Level: NQF ${node.nqf_level}` : "")}
                                                    </span> 
                                                </>
                                                : null}
                                                {node.keywords && node.keywords.length ? <span className="group-view-hover-icon">
                                                    <small className="notify-count-group">{node.keywords.length}</small>
                                                    <i class="fal fa-tags"></i>
                                                </span> : null}                                                                                                                                                                                                             
                                               {node.topic && node.topic.split(",").length ? <span className="group-view-hover-icon">  
                                                    <small className="notify-count-group">{node.topic.split(",").length<10 ? node.topic.split(",").length : "9+"}</small>                                              
                                                    <i class="fal fa-books"></i>
                                                </span> : null}
                                                <span className="group-action-buttons as-buttons">
                                                    <PermissionsGate scopes={["cquizmquview"]} RenderError={() => (
                                                        <button className="btn btn-primary rounded-circle" title="Open" disabled >
                                                            <i className="fal fa-folder-open"></i>
                                                        </button>
                                                        )
                                                        }>
                                                        <Link className="btn btn-primary rounded-circle" title="Open" to={"/courseAdministration/smartAssessment/questionBank/question/view/" + node?.qid} >
                                                            <i className="fal fa-folder-open"></i>
                                                        </Link>
                                                    </PermissionsGate>                                                    
                                                    <button
                                                        className="btn btn-primary rounded-circle"
                                                        data-toggle="modal"
                                                        data-target="#questionView"
                                                        title="View Question"
                                                        onClick={() => {
                                                            handleViewQuestionDetails(node?.qid)
                                                        }}
                                                        >
                                                        <i className="fal fa-eye"></i>
                                                    </button>
                                                    <PermissionsGate scopes={["cquizmquedit"]} errorProps={{ disabled: true }}>
                                                    <button className="btn btn-danger rounded-circle" title="Remove" onClick={() => handleDelete(node?.qid)} >
                                                        <i className="fal fa-trash-alt"></i>
                                                    </button>
                                                    </PermissionsGate>
                                                </span>                             
                                            </div>  }                                          
                                            {node.type === 1 && <div className="group-view-ques-info">
                                            <button className="btn btn-danger rounded-circle" title="Remove" onClick={() => handleDeleteGroup(node.id)} >
                                                <i className="fal fa-trash-alt"></i>
                                            </button>
                                            </div>}
                                        </div>,
                                    ],
                                    style: { borderRadius: "10px", width: "100%" },
                                };
                            }}
                            placeholderRenderer={Placeholder}
                        />
                    )}
                </div>
                <AddGroup refreshData = {()=>{setRefresh(!refresh)}} />
                <div className="topic-add-modal modal fade" id="assignQuestion" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered available-assessment-modal" role="document">

                    <div className="modal-content">
                        {/* <div className="modal-body"> */}
                        <div class="modal-header modal-header-custom"><h5 class="modal-title"><i class="fal fa-plus"></i> Assign Question</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>

                        <QBTable refresh={()=>{setRefresh(!refresh);refreshData();}} />
                    </div>
                    </div>
                </div>
            </div>
            <div
                className="topic-add-modal modal fade"
                // style={{ display: "block" }}
                id="questionView"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
                >
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                >
                    <div className="modal-content modal-border-update">
                    <div className="modal-body p-0">

                        <div className="modal-header modal-header-custom">
                        <h5 class="modal-title">
                            View Question
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                            // formik.resetForm()
                            $("#questionView").modal("hide");
                            // setEditorState1("");
                            // setShowCkEditor(false);

                            // formik.setFieldValue("type", "");
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>

                        <div className="t p-30px">
                        {/* <div className="topic-add-modal-content text-center font-weight-bold">
                            View Question
                        </div>
                        <hr /> */}
                        {questionLoading ? <SekletonLearningMaterial /> :
                            <DisplayQuestion question={questionData} from="assignedQTable" />}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            {errors && errors!="" ? <div className="invalid-feedback d-block">{errors}</div> : null}
            <div className="form-group form-group-save-cancel mt-3 mb-0">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                        handleSaveData();
                    }}
                    title="Save"
                    disabled={isDisabled}
                >
                    {isDisabled === false ? <i className="fal fa-save"></i> : <i className="fas fa-cog fa-spin"></i>}
                    Save
                </button>
                <button
                    className="btn btn-close btn-danger"
                    type="button"
                    title="Cancel"
                    onClick={() => (setRefresh(!refresh))}
                >
                    <i className="fal fa-times"></i>Cancel
                </button>
            </div>
            
        </div>
    );
};

export default QuestionGroup;
