import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";

import { useSelector } from "react-redux";
import { getCurrentTimeHomePage } from "../../common/Helper";
// import Tabs from "../Tabs";
import { useHistory } from "react-router";
import { GetListOfRole, GetUserDetail, UpdateUserDetail } from "../../../services/UserService";
import { OldToNew } from "./CheckRole";
import { useLocation, useParams } from "react-router-dom";

import user from "../../../assets/images/default-user.png";

import { GetListOfResourcesDetail, GetStudentTabListDetail } from "../../../services/StudentService";
import TabDataDetail from "./tabData";
import CategoryAction from "./CategoryAction";
import ResourceAction from "./ResourcesAction";
import Tabs from "../../studentsupport/Tabs";

let Id = "";
let TabId = "";
let ResourceId = "";
const ResourcesTabList = () => {
  const location = useLocation();
  const history = useHistory();
  const { tabId, catId, id } = useParams() 
  TabId = tabId
  Id = catId
  ResourceId = id
//   if(history.location.state){
//     const { id } = history.location.state;
//     Id = id
//   }

  // const [edit, setEdit] = useState(true);
  const [tablistData, setTablistData] = useState([]);
  // const [roles, setRoles] = useState(["Admin", "Student Support", "Manager", "Developer", "Qualifications Coordinator", "Principle/Deputy Principle", "Lecturer", "Editor", "Sales", "Accounts"]);

  // TODO API



  useEffect(() => {
    if(location.pathname != `student-support/student-hub-library/student-hub/${TabId}/category/${Id}/resource/create`){
        GetListOfResourcesDetail({ resourceId: ResourceId })
      .then((res) => {
        setTablistData(res.data?.hub_resource);
      })
      .catch((err) => console.log(err));
    }
  }, []);

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                <div class="d-flex align-items-center ">
                  <div class="name-w-head d-flex align-items-center ">
                    <h4 class="text-left-align landing-heading heading_color_dynamic">Resource: <span>{tablistData?.title}</span></h4>
                  </div>
                  <div class="text-md-right action2-blk">
                    <div class="ticket-view-btn d-flex align-items-center">
                      <div class="add-new-ticket-bx">
                        <button onClick={()=>history.goBack()} title="Back" class="btn btn-white-bordered">
                          <i class="fal fa-angle-left"></i>Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                  {/* <h4 className="text-left-align landing-heading heading_color_dynamic">
                    Resource <span>{tablistData?.title}</span>
                  </h4> */}
                </div>
                {/*  */}

                <div className="tabs-wrap">
                  {/* <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                        <i className="fal fa-users"></i>Resource Details
                      </a>
                    </li>
                    <li className="nav-item" onClick={() => history.push(`/support/student-hub-library/student-hub/audit-trail/resources/${ResourceId}`) }>
                      <a className="nav-link " id="pills-tab2" data-toggle="pill" href="#pills-tab12" role="tab" aria-controls="pills-tab12" aria-selected="true">
                        <i className="fal fa-history"></i>Audit trail
                      </a>
                    </li>
                  </ul> */}
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      <ResourceAction />
                    </div>
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourcesTabList;
