import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import {
    IMAGE_URL,
    TABLE_DATE_TIME_FORMAT,
    TABLE_ROWS_PER_PAGE,
} from "../../../utils/Constants";
import PermissionsGate from "../../../utils/permissionGate";
import { getIntakeCopyList, getIntakeCopyListCancelToken, revertCopiedIntake } from "../../../services/copyIntakeServices";
import DataTableComponent from "../../common/DataTableComponent";
import { InitialRender } from "../../common/Helper";
import Hover from "../../common/Hover";
import axios from "axios";

const ListCopiedIntake = () => {

    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [studentList, setStudentList] = useState([]);
    const [deleteRow, setDeleteRow] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [tableState, setTableState] = useState({
        page: 1,
        perPage: 10,
        sortKey: "time",
        sortOrder: "desc",
    });

    useEffect(() => {
        const cancelTokenSources = [];

        const getData = async () => {
          setLoading(true);
      
          cancelTokenSources.forEach(source => {
            source.cancel('New request made');
          });
      
          const source = axios.CancelToken.source();
          cancelTokenSources.push(source);
      
          const data = {
            page: tableState.page,
            limit: tableState.perPage,
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            search: search,
          };
      
          try {
            const res = await getIntakeCopyListCancelToken(data, source.token);
            setStudentList(res?.data && res?.data?.list && res?.data?.list?.data ? res?.data?.list?.data : []);
            setTotalRows(res?.data?.list?.total);
            if (res.status === 200) {
              setLoading(false);
            }
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.error(error);
              setLoading(false);
            }
          }
        };
      
        getData();
    
        return () => {
          cancelTokenSources.forEach(source => {
            source.cancel('Component unmounted');
          });
        };
    }, [
        deleteRow,
        tableState,
        // totalRows,
        search,
    ]);

    const columns = useMemo(() => [
        {
            name: "Code",
            selector: "uniqueProcessId",
            sortField: "uniqueProcessId",
            sortable: true,
            cell: (row) => (
                <Link
                    className="as-text-blue curser feature-name"
                    title={row.uniqueProcessId}
                    to={`/courseAdministration/intake-qualification/all-copied-logs/${row?.uniqueProcessId}`}
                >
                    <span className="textLimit100">{row.uniqueProcessId}</span>
                </Link>
            ),
        },
        {
            name: "From Intake",
            selector: "From_intake",
            sortField: "From_intake",
            sortable: true,
            cell: (row) => row.From_year + " - " + row.From_intake,
        },
        {
            name: "To Intake",
            selector: "To_intake",
            sortField: "To_intake",
            sortable: true,
            cell: (row) => row.To_year + " - " + row.To_intake,
        },
        {
            name: "Created By",
            selector: "Firstname",
            sortField: "Firstname",
            sortable: true,
            // cell: (row) => row?.Firstname ? row?.Firstname + " " + row?.Lastname : "-"
            cell: (row) => (
                <div className="assigned-title-block-new">
                    <div className="assigned-title-itm">
                        <span className="assigned-title-blk  name-icon cat-dark-red">
                            {row.picture_me ? (
                                <img
                                    src={`${IMAGE_URL}/${row.picture_me.replaceAll(
                                        "/home/myaie/public_html/",
                                        ""
                                    ).replace("public/", "")}`}
                                    alt="AIE"
                                />
                            ) : (
                                InitialRender([row.Firstname, row.Lastname])
                            )}
                            <span className={`profile-box-2-status ${row.created_by_activity_status ? row.created_by_activity_status.toLowerCase() == "online" ? "Online" : row.created_by_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                                <i className="fas fa-circle"></i>
                            </span>
                            <Hover
                                firstName={row?.Firstname}
                                lastName={row?.Lastname}
                                photo={row?.picture_me}
                                email={row?.Email}
                                mobile={row.Mobile}
                                status={row.created_by_status}
                                activity_status={row?.created_by_activity_status}
                                right={true}
                                // showNumber={true}
                                // number={row.student_crm_id}
                            />
                        </span>
                        <Link
                            className="as-text-blue curser feature-name"
                            to={`/systemAdministration/userManagement/open/${row.user_id}/aboutYou`}
                            title={row.Firstname + " " + row.Lastname}
                        >
                            <span className="textLimit100">{row.Firstname + " " + row.Lastname}</span>
                        </Link>
                    </div>
                </div>
            ),
        },
        {
            name: "Created At",
            selector: "time",
            sortField: "time",
            sortable: true,
            cell: (row) => (
                <>
                    {row.time ? (
                        <div className="">
                            <p className="right-space">
                                {moment(row.time).format(TABLE_DATE_TIME_FORMAT)}
                            </p>
                        </div>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "Actions",
            selector: "",
            cell: (row) => (
                <div className="assessment-08  btn-dropdown-grp">
                    <div className="as-buttons d-flex">
                        <PermissionsGate
                            scopes={["intakeCopyView"]}
                            errorProps={{ disabled: true }}
                        >
                            <Link
                                to={`/courseAdministration/intake-qualification/all-copied-logs/${row?.uniqueProcessId}`}
                                title="View Logs"
                                className="btn btn-primary rounded-circle"
                            >
                                <i className="fal fa-folder-open"></i>
                            </Link>
                        </PermissionsGate>
                        <PermissionsGate
                            scopes={["intakeCopyDelete"]}
                            errorProps={{ disabled: true }}
                        >
                            <button
                                title="Revert Back"
                                className="btn btn-danger rounded-circle"
                                onClick={() => handleDelete(row?.uniqueProcessId)}
                            >
                                <i className="fal fa-trash-alt"></i>
                            </button>
                        </PermissionsGate>
                    </div>
                </div>
            ),
        },
    ]);

    const handleDelete = (ID) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        })
            .then((result) => {
                if (result.isConfirmed) {
                    const detail = {
                        uniqueProcessId: ID
                    }
                    revertCopiedIntake(detail).then((res) => {
                        setDeleteRow(!deleteRow);
                    });
                    Swal.fire("Deleted!", "Copied Intake has been deleted.", "success");
                }
            })
            .catch((error) => {
                console.log("error: ", error);
                setDeleteRow(!deleteRow);
            });
    };

    const exportData = (fileType, fileName) => {
        let data;
        const header = ["Code", "From Intake", "To Intake", "Created By", "Created At"];
        const details = {
            page: tableState.page,
            limit: tableState.perPage,
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            search: search,
            exportStatus: "true",
        };
        getIntakeCopyList(details)
            .then((res) => {
                data = res?.data && res?.data?.list?.length ? res?.data?.list : [];
                data = data?.map((row) => ({
                    ...row,
                    "Code": row.uniqueProcessId ? row.uniqueProcessId : "-",
                    "From Intake": row?.From_intake && row.From_year ? row.From_year + " - " + row.From_intake : "-",
                    "To Intake": row?.To_intake && row?.To_year ? row.To_year + " - " + row.To_intake : "-",
                    "Created By": row.Firstname ? row.Firstname + " " + row.Lastname : "-",
                    "Created At": row.time ? moment(row.time).format(TABLE_DATE_TIME_FORMAT) : "-",
                }));
                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], {
                        type: "text/csv;charset=utf-8,",
                    });

                    const blobURL = window.URL.createObjectURL(blob);

                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    setTimeout(() => {
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.map((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        header,
                    });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);

                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data.map((row) => {
                        return [
                            row["Code"],
                            row["From Intake"],
                            row["To Intake"],
                            row["Created By"],
                            row["Created At"],
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);

                    return false;
                }
            })
            .catch((error) => {
                console.log("error =-=--> ", error);
                setLoading(false);
            });
    };

    return (
        <DataTableComponent
            data={studentList}
            loading={loading}
            state={tableState}
            setState={setTableState}
            setSearch={setSearch}
            totalRows={totalRows}
            columns={columns}
            exportFunction={exportData}
            exportFileName={"List_Copied_Intake"}
            tableButton={[
                <PermissionsGate
                    scopes={["intakeCopyAdd"]}
                    RenderError={() => (
                        <button
                            title="Create New"
                            className="btn btn-primary"
                            disabled
                        >
                            <i className="fal fa-plus"></i>Create New
                        </button>
                    )}
                >
                    <Link
                        to={`/courseAdministration/intake-qualification/open-copied-intake/add`}
                        title="Create New"
                        className="btn btn-primary"
                    >
                        <i className="fal fa-plus"></i>Create New
                    </Link>
                </PermissionsGate>
            ]}
        />
    );
};

export default ListCopiedIntake;
