import React, { useState, useMemo, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { AssignAssembly, DeleteAssignedAssemblies, GetAssignedAssemblies, GetAvailableAssemblies } from "../../../services/CourseService";
import Swal from "sweetalert2";
import moment from "moment";
import PermissionsGate from "../../../utils/permissionGate";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import $ from 'jquery'
import { RenderVersion } from "../../../utils/CommonGroupingItem";
import Tablefilter from "../../common/Tablefilter";
import { handleTableScroll } from "../../../utils/commonFunction";

const Assemblies = (props) => {

  const history = useHistory();
  const {id}=useParams();
  const [searchAssigned, setSearchAssigned] = useState("");
  const [searchAvailable, setSearchAvailable] = useState("");
  const [assignedRec, setAssignedRec] = useState([]);
  const [availableRec, setAvailableRec] = useState([]);
  const [versionAssigned, setVersionAssigned] = useState({ arr: [], checkObj: {} });
  const [isAssignData, setIsAssignData] = useState(false);
  const [isAvailData, setIsAvailData] = useState(false);
  const [selectedVersionCheck, setSelectedVersionCheck] = useState({});
  const [loading, setLoading] = useState(true);
  const [sortkey, setSortKey] = useState("item_date");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [version, setVersion] = useState({ arr: [], checkObj: {} })
  const [assignedFilterData, setAssignedFilterData] = useState({version : []})
  const [availableFilterData, setAvailableFilterData] = useState({version : []})
  const [filterCheck, setFilterCheck] = useState("true");
  const [filterCheck1, setFilterCheck1] = useState("true")

  useEffect(() => {
    handleTableScroll()
  }, [isAvailData, isAssignData])

  useEffect(() => {
    getTableData();
  }, [])

  useEffect(() => {
    getAvailableFunction();


  }, [page, perPage, searchAvailable, sortOrder, sortkey, version, selectedVersionCheck])

  const getAvailableFunction = () => {
    setLoading(true);
    const data = {
      pageNo: page,
      limit: perPage,
      search: searchAvailable ? searchAvailable : "",
      key: sortkey,
      sort: sortOrder,
      version: version.arr.length ? version.arr : [],
      id_subject: props?.userId,
      dynamicFilter : filterCheck1
    }
    GetAvailableAssemblies(data).then(data => {
      setAvailableRec(data && data.data && data.data.assemblies_list ? data.data.assemblies_list.data : []);
      setTotalRows(data?.data?.assemblies_list?.total);
      setIsAssignData(true);
      setLoading(false);
      if(filterCheck1 == "true" && data.data.item_version_filter.length){
        setAvailableFilterData({...data.data, version : data.data.item_version_filter})
        setFilterCheck1("false");
      }
     
    }).catch(error => {
      console.log("error ---> ", error);
      setIsAssignData(true);
      setLoading(false);
    })
  }

  const getTableData = () => {
    let data = filterCheck;
  
    GetAssignedAssemblies(props.userId,data).then(data => {
      setAssignedRec(data && data.data && data.data.assemblies_list ? data.data.assemblies_list : []);
      if(filterCheck == "true"){
        setAssignedFilterData({...data.data,version : data.data.versonTypeFilter});
        setFilterCheck("false")
      }
      setIsAvailData(true);
    }).catch(error => {
      console.log("error ====> ", error);
      setIsAvailData(true);
    })
  }

  const assignedColumns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      wrap: true,
      maxWidth: "250px",
      cell: (row) =>
        row.name ?
          <Link className="as-text-blue curser feature-name" to={{ pathname: "/courses/content/assemblies/edit/" + row.id }}  title={row.name} ><span className="textLimit100">{row.name}</span> </Link>
          // <p>{row.name}</p> 
          : <p>-</p>,
    },
    {
      name: "Creation Date",
      selector: "item_date",
      sortable: true,
      wrap: true,
      maxWidth: "250px",
      cell: (row) =>
        <>
          {row.item_date ?
            <div className="dateTime">
              <p className="right-space">{moment(row.item_date).format(TABLE_DATE_FORMAT).toString() === "Invalid date" ? "-" : moment.utc(row.item_date).format(TABLE_DATE_FORMAT)}</p>
            </div> : "-"}
        </>
    },
    {
      name: "Version",
      selector: "item_version",
      // minWidth: "250px",
      sortable: true,
      cell: (row) =>
        row.item_version ? RenderVersion(row.item_version).html : "-"
    },
    {
      name: "Actions",
      selector: "",
      maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <Link to={{ pathname: "/courses/content/assemblies/edit/" + row.id }} >
              <button className="btn btn-primary rounded-circle" title="Open">
                <i className="fal fa-folder-open"></i>
              </button>
            </Link>
            <PermissionsGate scopes={["casbdelete"]} errorProps={{ disabled: true }}>
              <button className="btn btn-danger rounded-circle btn-dropdown-item" title="Delete" onClick={() => removeAssembly(row.ref_id)}><i className="fal fa-trash-alt"></i></button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearchAssigned(value);
  };

  const assignmentToRender = () => {
    let updatedData = [];
    let allData = assignedRec;
    if (searchAssigned.length) {
      let name = allData.filter((item) => {
        let includes = item.name.toString().toLowerCase().includes(searchAssigned.toLowerCase());
        if (includes) {
          // console.log("name: ", includes);
          return includes;
        } else return null;
      });

      let creationDate = allData.filter((item) => {
        let date = item.item_date && (moment.utc(item.item_date).format(TABLE_DATE_FORMAT).toString() + " " + moment.utc(item.item_date).format(TABLE_TIME_FORMAT).toString());
        let includes = date.toString().toLowerCase().includes(searchAssigned.toLowerCase());
        if (includes) {
          // console.log("name: ", includes);
          return includes;
        } else return null;
      });

      let data = [...name, ...creationDate];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (versionAssigned.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = versionAssigned.arr.find(function (post, index) {
          if (post.toString().toLowerCase() === item.item_version.toString().toLowerCase()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (searchAssigned.length || versionAssigned.arr.length) {
      return updatedData;
    } else {
      return assignedRec;
    }
  };

  const resetFilter = () => {
    setSearchAssigned("");
    setVersionAssigned({ arr: [], checkObj: {} });
  };

  const removeAssembly = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAssignedAssemblies(id).then(data => {
          getTableData();
          Swal.fire("Deleted!", "Your record has been deleted.", "success");
        }).catch(error => {
          console.log("error while deleting >>>>> ", error);
        })
      }
    })
  }

  const availableColumns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      sortField: "name",
      wrap: true,
      maxWidth: "250px",
      cell: (row) =>
        row.name ?
          <Link
            title={row.name}
            className="as-text-blue curser feature-name"
            to={"/courses/content/assemblies/edit/" + row.id} 
            onClick={()=> $('#assignAssemblies').modal("hide")}>
            <span className="textLimit100">
              {row.name}
            </span>
          </Link>
          : <p>-</p>,
    },
    {
      name: "Creation Date",
      selector: "item_date",
      sortable: true,
      wrap: true,
      sortField: "item_date",
      maxWidth: "250px",
      cell: (row) =>
        <>
          {row.item_date ?
            <div className="dateTime">
              <p className="right-space">{moment(row.item_date).format(TABLE_DATE_FORMAT).toString() === "Invalid date" ? "-" : moment.utc(row.item_date).format(TABLE_DATE_FORMAT)}</p>
            </div> : "-"}
        </>
    },
    {
      name: "Version",
      selector: "item_version",
      // minWidth: "250px",
      sortable: true,
      sortField: "item_version",
      cell: (row) =>
        row.item_version ? RenderVersion(row.item_version).html : "-",
    },
    {
      name: "Actions",
      selector: "",
      maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
              <button className="btn btn-sm btn-primary rounded-circle" title="Open" onClick={()=>{
                $('#assignAssemblies').modal("hide");
                history.push(`/courses/content/assemblies/edit/${row.id}`)
              }}>
                <i className="fal fa-folder-open"></i>
              </button>
            <PermissionsGate scopes={["casbadd"]} errorProps={{ disabled: true }}>
              <button className="btn btn-primary rounded-circle btn-dropdown-item" title="Add" onClick={() => assignAssembly(row.id)}><i className="fal fa-plus"></i></button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleAvailableSearchFilter = (e) => {
    const value = e.target.value;
    setSearchAvailable(value);
  };

  const resetAssignedFilter = () => {
    setSearchAvailable("");
    setVersion({ arr: [], checkObj: {} });
    setSelectedVersionCheck({});
  };

  const assignAssembly = (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "confirm",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Add it!",
    }).then((result) => {
      if (result.isConfirmed) {

        AssignAssembly(props.userId, id).then(data => {
          getTableData();
        }).catch(error => {
          console.log("error <><><><>< ", error);
        })

        Swal.fire("Added!", "Your record has been added.", "success");
      }
    })
  }


  const exportData = async (fileType, fileName) => {
    let availableData = [];
    await GetAvailableAssemblies({
      pageNo: page,
      limit: perPage,
      search: searchAvailable ? searchAvailable : "",
      key: sortkey,
      sort: sortOrder,
      version: version.length ? version : [],
      id_subject: props?.userId,
      exportStaus: "true",
    }).then(data => {

      availableData = data.data.assemblies_list;



    }).catch(error => {
      console.log("error ---> ", error);
      availableData = [];
    })

    let data = fileName === "Available_Assemblies" ? availableData : assignmentToRender();
    const header = ["Name", "Creation Date", "Version"];
    data = data?.map((row, index) => ({
      ...row,
      Name: row?.name ? row?.name : "-",
      "Creation Date": moment(row.item_date).format(TABLE_DATE_FORMAT)
      //  + " " + moment(row.item_date).format(TABLE_TIME_FORMAT)
       ,
      Version: row.item_version ? RenderVersion(row.item_version).text : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row, index) => {
        return [row.name, moment(row.item_date).format(TABLE_DATE_FORMAT) + " " + moment(row.item_date).format(TABLE_TIME_FORMAT), row.item_version];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };
  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="custom-table-div filter-search-icon card card-table-custom course-table-filter-sec intake-year-z-i">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                    <div id="assessment-table-main_filter" className="dataTables_filter">
                      <label>
                        <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={searchAssigned} />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="filter-scroll">
                    <div className={`filter-scroll-inner  filter-custom-new`}>
                      <Tablefilter
                        filterName="Version"
                        optionArr={assignedFilterData.version}
                        state={versionAssigned}
                        setState={setVersionAssigned}
                        renderLabelFunction={RenderVersion}
                        uniqueId="assignedAssembly"
                      />
                    </div>
                  </div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button type="button" className="btn btn-files" title="Export spreadsheet" onClick={() => {
                        exportData("xlsx", "Assigned_Assemblies");
                      }} >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button type="button" className="btn btn-files" title="Export CSV" onClick={() => {
                        exportData("csv", "Assigned_Assemblies");
                      }} >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button type="button" className="btn btn-files" title="Export PDF" onClick={() => {
                        exportData("pdf", "Assigned_Assemblies");
                      }} >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-filter-div-right">
              <div className="filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <Link  to={`/courseAdministration/coursesdetails/${id}/assembliesDrag/show`} className="btn btn-primary mr-2" title="Assign Assemblies">
                    <i className="far fa-random"></i>Re-arrange
                  </Link>
                </div>
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <button className="btn btn-primary" title="Assign Assemblies" data-toggle="modal" data-target="#assignAssemblies">
                    <i className="fal fa-plus"></i>Assign Assemblies
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!isAssignData && <SkeletonTicketList />}
          {isAssignData && <DataTable
            data={assignmentToRender()}
            columns={assignedColumns}
            pagination={true}
            // defaultSortField="item_date"
            // defaultSortAsc={true}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />}
        </div>
      </div>

      <div
        className="topic-add-modal modal able_modal01 fade"
        id="assignAssemblies"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div class="modal-header modal-header-custom"><h5 class="modal-title"><i class="fal fa-plus"></i> Assign Assemblies</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
            <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
              <div className="l-o-c-t custom-table-div filter-search-icon card-table-custom days-overdue-cell-width">
                <div className="search-filter-div">
                  <div className="search-filter-div-left">
                    <div className="system-administration-table table-responsive">
                      <div className="table-responsive-div">
                        <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                            <div id="assessment-table-main_filter" className="dataTables_filter">
                              <label>
                                <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleAvailableSearchFilter} value={searchAvailable} />
                              </label>
                              <div className="filter-eff filter-data-btn">
                                <button className="filter-buttons">
                                  <i className="fal fa-filter"></i>
                                </button>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div className="filter-button-group">
                        <div className="filter-scroll">
                          <div className={`filter-scroll-inner  filter-custom-new`}>
                            <Tablefilter
                              filterName="Version"
                              optionArr={availableFilterData.version}
                              state={version}
                              setState={setVersion}
                              renderLabelFunction={RenderVersion}
                              uniqueId="availableAssemblies"
                            />
                          </div>
                        </div>
                        <div className="reset-btn-group">
                          <div className="button-reset dropdown-comman">
                            <button className="btn btn-primary" onClick={resetAssignedFilter} title="Reset" >
                              <i className="fal fa-redo"></i>Reset
                            </button>
                          </div>
                          <div className="files-export-group">
                            <button type="button" className="btn btn-files" title="Export spreadsheet" onClick={() => {
                              exportData("xlsx", "Available_Assemblies");
                            }} >
                              <i className="fal fa-file-excel icon"></i>
                            </button>
                            <button type="button" className="btn btn-files" title="Export CSV" onClick={() => {
                              exportData("csv", "Available_Assemblies");
                            }} >
                              <i className="fal fa-file-csv icon"></i>
                            </button>
                            <button type="button" className="btn btn-files" title="Export PDF" onClick={() => {
                              exportData("pdf", "Available_Assemblies");
                            }} >
                              <i className="fal fa-file-pdf icon"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isAvailData &&
                  <DataTable
                    paginationDefaultPage={page}
                    progressPending={loading}
                    progressComponent={<SkeletonTicketList />}
                    data={availableRec}
                    columns={availableColumns}
                    pagination={true}
                    defaultSortField="item_date"
                    onSort={handleSort}
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    defaultSortAsc={true}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                  />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Assemblies