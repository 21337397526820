import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Swal from "sweetalert2";
import moment from "moment";
import { GetCampusAuditTrails, GetCampusAuditTrailsCancelToken } from "../../../services/CampusMgtService";
import { useLocation } from "react-router-dom";
import { InitialRender } from "../../common/Helper";
import Hover from "../../common/Hover";
import { Link } from "react-router-dom";
import { capitalize, checkIfImageExists, handleTableScroll } from "../../../utils/commonFunction";
import { formatQuestion } from "../../../utils/FormatQuestion";
import generateArrayOfYears from "../../common/generateArrayOfYears";
import { RenderAuditTrailActionType } from "../../../utils/CommonGroupingItem";
import axios from "axios";

const AuditTrail = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [brandData, setBrandData] = useState([]);
  const [actiontype, setActionType] = useState([]);
  const [searchActionTypeCheck, setSearchActionTypeCheck] = useState({});
  const [month, setMonth] = useState([]);
  const [searchMonthCheck, setSearchMonthCheck] = useState({});
  const [year, setYear] = useState([]);
  const [searchYearCheck, setSearchYearCheck] = useState({});
  const [isLoaded, setIsLoaded] = useState(true);
  const searchParam = useLocation().search;
  const classId = new URLSearchParams(searchParam).get("id");
  const className = new URLSearchParams(searchParam).get("name");
  const [resourceType, setResourceType] = useState([]);
  const [searchResourceTypeCheck, setSearchResourceTypeCheck] = useState({});

  useEffect(() => {
    handleTableScroll()
  }, [isLoaded])

  const fixDataFormat = (arr) => {
    let tempArr = [];
    arr.map((item) => {
      let singleItem = {
        id: item.id,
        action_id: item.action_id,
        description: JSON.parse(item.description),
        resource_type: item.resource_type,
      };
      tempArr.push(singleItem);
    });
    setBrandData(tempArr);
  };

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const data = await GetCampusAuditTrailsCancelToken(classId, dropdownSource.token);
        if (data?.status == 200) {
          fixDataFormat(data?.data?.audit_trail);
          setIsLoaded(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsLoaded(false);
        }
      }
    };

    fetchData();

    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const sortingDate = (rowA, rowB) => {
    const date1 = new Date(rowA.description.timestamp);
    const date2 = new Date(rowB.description.timestamp);

    if (date2 > date1) {
      return 1;
    } else if (date2 < date1) {
      return -1;
    } else {
      return 0;
    }
  };

  const sortingName = (rowA, rowB) => {
    const name1 =
      rowA.resource_type == "campus_rooms"
        ? rowA.description.object.definition.New.room_name
          ? rowA.description.object.definition.New.room_name.toString()
          : rowA.description.object.definition.Old.room_name.toString()
        : rowA.description.object.definition.New.campus_name
        ? rowA.description.object.definition.New.campus_name.toString()
        : rowA.description.object.definition.Old.campus_name.toString();
    const name2 =
      rowB.resource_type == "campus_rooms"
        ? rowB.description.object.definition.New.room_name
          ? rowB.description.object.definition.New.room_name.toString()
          : rowB.description.object.definition.Old.room_name.toString()
        : rowB.description.object.definition.New.campus_name
        ? rowB.description.object.definition.New.campus_name.toString()
        : rowB.description.object.definition.Old.campus_name.toString();
    return name1.localeCompare(name2);
  };

  const sortingUser = (rowA, rowB) => {
    const name1 = rowA.description.User.name.toString();
    const name2 = rowB.description.User.name.toString();
    return name1.localeCompare(name2);
  };

  const difference = (obj1, obj2) => {
    let keyFound = [];
    Object.keys(obj1).forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        return keyFound.push(key + ":" + obj1[key]);
      }
    });
    return keyFound;
  };
  const actionSortFunction = (rowA, rowB) => {
    const name1 = rowA.description.verb.Action;
    const name2 = rowB.description.verb.Action;
    return name1.localeCompare(name2);
  };

  const columns = useMemo(() => [
    {
      name: "User",
      selector: "user",
      sortable: false,
      sortFunction: sortingUser,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className="assigned-title-blk name-icon">
              {row.description &&
              row.description.User &&
              row.description.User.URL &&
              checkIfImageExists(
                `${IMAGE_URL}/${row.description.User.URL.replace(
                  "home/myaie/public_html/",
                  ""
                ).replace("public/", "")}`
              ) ? (
                <img
                  src={`${IMAGE_URL}/${row.description.User.URL.replace(
                    "home/myaie/public_html/",
                    ""
                  ).replace("public/", "")}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([
                  row.description.User.name.split(" ")[0],
                  row.description.User.name.split(" ")[1],
                ])
              )}
              <span
                className={`profile-box-2-status ${
                  row.description ? row.description.User.status : "Offline"
                }`}
              >
                <i className="fas fa-circle"></i>
              </span>
              <Hover
                firstName={row.description.User.name.split(" ")[0]}
                lastName={row.description.User.name.split(" ")[1]}
                photo={row.description.User.URL}
                email={row.description.User.mbox}
                mobile={row.description.User?.Mobile}
                right={true}
                role={"default"}
              />
            </span>
            <p>{row.description.User.name}</p>
          </div>
        </div>
      ),
    },
    {
        name: "Action Type",
        sortable: true,
        selector: "action_type",
        sortFunction: actionSortFunction,
        cell: (row) => {
          return RenderAuditTrailActionType(row.description.verb.Action).html;
        },
      },
  
      {
        name: "Resource Type",
        sortable: true,
        selector: "resource_type",
        cell: (row) => (row.resource_type ? <div title={row.resource_type}>{capitalize(row.resource_type)}</div> : "-"),
      },
      {
        name: "Resource Name",
        selector: "resource_name",
        sortable: true,
        sortFunction: sortingName,
        cell: (row) => {
          if (row.description.verb.Action === "Deleted") {
            if (row.resource_type == "campus_rooms") {
              return (
                <span title={row.description.object.definition.Old.room_name} className="feature-name">
                  <span className="textLimit100">
                    {row.description.object.definition.Old.room_name}
                  </span>
                </span>
              );
            } else if (row.resource_type == "campus") {
              return (
                <span title={row.description.object.definition.Old.campus_name} className="feature-name">
                  <span className="textLimit100">
                  {row.description.object.definition.Old.campus_name}
                  </span>
                </span>
              );
            }
          } else {
            if (row.resource_type == "campus_rooms") {
              return (
                <span title={row.description.object.definition.New.room_name} className="feature-name">
                <span className="textLimit100">
                  {row.description.object.definition.New.room_name}
                </span>
              </span>
              );
            }
             else if (row.resource_type == "campus") {
              return (
                    <Link
                      className="as-text-blue curser feature-name"
                      title={row.description.object.definition.New && row.description.object.definition.New.campus_name}
                      to={`/administration/campusManagement/campusDetails?id=${row.description.object.definition.New.id}&name=${row.description.object.definition.New.campus_name}`}
                    >
                      <span className="textLimit100">
                        {row.description.object.definition.New && row.description.object.definition.New.campus_name}
                      </span>
                    </Link>
                    );
            }
          }
        },
      },
    {
      name: "Old Value",
      // sortable: true,
      cell: (row) => {
        const oldObj =
          row &&
          row.description &&
          row.description.object &&
          row.description.object.definition &&
          row.description.object.definition.Old
            ? row.description.object.definition.Old
            : {};
        const entries = Object.entries(oldObj);

        return (
          <div
            className="tooltip-custom h"
            title={`${Object.keys(oldObj)
              .sort()
              .map((entry) => entry.toString() + " : " + oldObj[entry])}`
              .split(",")
              .join("\n")}
          >
            {entries.slice(0, 2).map((entry, index) => {
              return (
                <span className="textLimit100" key={index}>
                  {entry[0]}:
                  {entry[1]
                    ? entry[1].toString().length > 30
                      ? entry[1].toString().slice(0, 30) + "..."
                      : entry[1]
                    : "N/A"}
                </span>
              );
            })}
          </div>
        );
      },
    },
    {
      name: "New Value",
      // sortable: true,
      cell: (row) => {
        const newObj =
          row &&
          row.description &&
          row.description.object &&
          row.description.object.definition &&
          row.description.object.definition.New
            ? row.description.object.definition.New
            : {};
        const entries = Object.entries(newObj);

        return (
          <div
            className="tooltip-custom"
            title={`${entries.map(
              (entry) => entry[0] + ":" + (entry[1] ? entry[1] : "NA")
            )}`
              .split(",")
              .join("\n")}
          >
            {entries.map((entry, index) => {
              return (
                index < 2 && (
                  <span className="textLimit100" key={index}>
                    {entry[0]}:
                    {entry[1]
                      ? entry[1].toString().length > 30
                        ? entry[1].toString().slice(0, 30) + "..."
                        : entry[1]
                      : "N/A"}
                  </span>
                )
              );
            })}
            {entries.length > 2 ? "..." : null}
          </div>
        );
      },
    },
    {
      name: "Date",
      sortable: true,
      selector: "Date",
      sortFunction: sortingDate,
      cell: (row) => {
        return row.description.timestamp ? (
          <>
            <p>{moment(row.description.timestamp).format(TABLE_DATE_FORMAT + ",")}</p>
            <p>{moment(row.description.timestamp).format(TABLE_TIME_FORMAT)}</p>
          </>
        ) : (
          "NA"
        );
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button
              title="Open"
              className="btn btn-primary rounded-circle"
              onClick={() => {
                Swal.fire({
                  html: `<div className="audit-trial-json"><pre>${JSON.stringify(
                    row.description,
                    undefined,
                    1
                  )}</pre></div>`,
                });
              }}
            >
              <i className="fal fa-folder-open"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleTypeFilter = (e, type) => {
    const value = e.target.value;
    if (type == "action") {
      let arr = actiontype;
      if (arr.includes(value)) {
        arr.splice(arr.indexOf(value), 1);
      } else {
        arr.push(value);
      }
      setActionType(arr);
      let id = e.target.id;
      setSearchActionTypeCheck({
        ...searchActionTypeCheck,
        [id]: !searchActionTypeCheck[id],
      });
    } else if (type == "resource") {
      let arr = resourceType;
      if (arr.includes(value)) {
        arr.splice(arr.indexOf(value), 1);
      } else {
        arr.push(value);
      }
      setResourceType(arr);
      let id = e.target.id;
      setSearchResourceTypeCheck({
        ...searchResourceTypeCheck,
        [id]: !searchResourceTypeCheck[id],
      });
    }
  };

  const handleMonthFilter = (e) => {
    const value = e.target.value;
    let arr = month;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setMonth(arr);
    let id = e.target.id;
    setSearchMonthCheck({ ...searchMonthCheck, [id]: !searchMonthCheck[id] });
  };

  const handleYearFilter = (e) => {
    const value = e.target.value;
    let arr = year;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setYear(arr);
    let id = e.target.id;
    setSearchYearCheck({ ...searchYearCheck, [id]: !searchYearCheck[id] });
  };

  const renderMonthUI = () => {
    let arr = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return arr.map((i, index) => {
      return (
        <li key={index}>
          <input
            type="checkbox"
            id={index}
            value={index}
            onClick={handleMonthFilter}
            checked={searchMonthCheck[index]}
          />
          <label htmlFor={index}>
            <span className="cat">{i}</span>
          </label>
        </li>
      );
    });
  };

  const renderYearUI = () => {
    let arr = generateArrayOfYears().splice(0, 3);
    return arr.map((i, index) => {
      return (
        <li key={index}>
          <input
            type="checkbox"
            id={i}
            value={i}
            onClick={handleYearFilter}
            checked={searchYearCheck[i]}
          />
          <label htmlFor={i}>
            <span className="cat">{i}</span>
          </label>
        </li>
      );
    });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = brandData;
    if (search.length) {
      let tempUser = allData.filter((item) => {
        let includes = item?.description?.User?.name
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempName = allData.filter((row) => {
            // let includes = item.description.object.definition.New.brand_name
            let value = row.description.verb.Action === "Deleted" ?
              row.resource_type == "campus_rooms" ?
                formatQuestion(row.description.object.definition.Old.room_name).replaceAll("&nbsp;", "")
                : row.resource_type == "campus" ?
                  formatQuestion(row.description.object.definition.Old.campus_name).replaceAll("&nbsp;", "")
                  :
                  row.description.object.definition.Old.Portal
              :
              row.resource_type == "campus_rooms" ?
                formatQuestion(row.description.object.definition.New.room_name).replaceAll("&nbsp;", "")
                : row.resource_type == "campus" ?
                  formatQuestion(row.description.object.definition.New.campus_name).replaceAll("&nbsp;", "")
                  :
                  row.description.object.definition.New.Portal
            let includes = value
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase());
            if (includes) {
              return includes;
            } else return null;
          });
      let tempDate=allData.filter((row)=>{
        let includes = moment(row.description.timestamp).format(TABLE_DATE_TIME_FORMAT)
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      })

      // let tempName = allData.filter((item) => {
      //   let includes = item?.description?.object?.definition?.New?.brand_name
      //     ? item?.description?.object?.definition?.New?.brand_name
      //         ?.toString()
      //         .toLowerCase()
      //         .includes(search.toLowerCase())
      //     : item?.description?.object?.definition?.Old?.brand_name
      //         ?.toString()
      //         .toLowerCase()
      //         .includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let data = [...tempUser, ...tempName,...tempDate];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (actiontype.length) {
      let tempAction = updatedData;
      let tempResult = tempAction.filter((item) => {
        let tempItem =
          item &&
          item.description &&
          item.description.verb &&
          item.description.verb.Action
            ? item.description.verb.Action.toLowerCase().toString()
            : "";
        const startsWith = actiontype.find((post, index) => {
          if (post.toLowerCase().toString() === tempItem) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (resourceType.length) {
      let tempAction = updatedData;
      let tempResult = tempAction.filter((item) => {
        let tempItem =
          item && item.resource_type
            ? item.resource_type.toLowerCase().toString()
            : "";
        const startsWith = resourceType.find((post, index) => {
          if (post.toLowerCase().toString() === tempItem) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (month.length) {
      let tempMonth = updatedData;
      let tempResult = tempMonth.filter((item) => {
        const startsWith = month.find((post, index) => {
          let monthNumber = new Date(item.description.timestamp).getMonth();
          if (post.toString() === monthNumber.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (year.length) {
      let tempYear = updatedData;
      let tempResult = tempYear.filter((item) => {
        const startsWith = year.find((post, index) => {
          let yearNumber = new Date(item.description.timestamp).getFullYear();
          if (post.toString() === yearNumber.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    // console.log("updatedData ----> ", updatedData);
    return updatedData;
  };

  const resetFilter = () => {
    setSearch("");
    setActionType([]);
    setSearchActionTypeCheck({});
    setMonth([]);
    setSearchMonthCheck({});
    setYear([]);
    setSearchYearCheck({});
    setResourceType([]);
    setSearchResourceTypeCheck({});
  };

  const oldValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.Old);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(
        row.description.object.definition?.Old,
        row.description.object.definition?.New
      );
      return diff.length !== 0 ? `${diff.sort()}` : "NA";
    }
    // else if (row.description.verb.Action === "Profile Picture Edited") {
    //   return  row.description.object.definition?.Old?.picture_me;
    // }
    else {
      return `${entries.map(
        (entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "N/A"}`
      )}`;
    }
  };

  const NewValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.New);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(
        row.description.object.definition?.New,
        row.description.object.definition?.Old
      );
      return diff.length !== 0 ? `${diff.sort()}` : "NA";
    } else if (row.description.verb.Action === "Profile Picture Edited") {
      return row.description.object.definition?.New?.picture_me.toString()
        .length > 30
        ? "picture_me:" + row.description.object.definition?.New?.picture_me
        : row.description.object.definition?.New?.picture_me;
    } else {
      return `${entries.map(
        (entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "N/A"}`
      )}`;
    }
  };

  const modifiedData = () => {
    let data = dataToRender();

    data = data?.map((row) => ({
      ...row,
      // ID: row?.id,
      User: row.description.User.name,
      "Resource Name": row.description.verb.Action === "Deleted" ?
      row.resource_type == "campus_rooms" ?
      row.description.object.definition.Old.room_name
        : row.resource_type == "campus" ?
        row.description.object.definition.Old.campus_name
          :
          ""
      :
      row.resource_type == "campus_rooms" ?
      row.description.object.definition.New && row.description.object.definition.New.room_name
        : row.resource_type == "campus" ?
        row.description.object.definition.New && row.description.object.definition.New.campus_name
          :
          "",
      "Resource Type": row.resource_type ? row.resource_type : "-",
      "Action Type": row.description.verb.Action,
      // "Old Value": Object.keys(row.description.object.definition.Old).length !== 0 ? `${Object.entries(row.description.object.definition.Old).map((entry) => entry[0] + ":" + entry[1])}` : "NA",
      "Old Value":
        Object.keys(row.description.object.definition.Old).length !== 0
          ? oldValue(row)
          : "NA",
      // "New Value": Object.keys(row.description.object.definition.New).length !== 0 ? `${Object.entries(row.description.object.definition.New).map((entry) => entry[0] + ":" + entry[1])}` : "NA",
      "New Value":
        Object.keys(row.description.object.definition.New).length !== 0
          ? NewValue(row)
          : "NA",
      Date: moment(row.description.timestamp).format(TABLE_DATE_TIME_FORMAT),
    }));

    return data;
  };

  const exportData = (fileType, fileName) => {
    let data = modifiedData();

    const header = [
      "User",
      "Action Type",
      "Resource Type",
      "Resource Name",
      "Old Value",
      "New Value",
      "Date",
    ];

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      // console.log(csvString);
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["User"],
          row["Action Type"],
          row["Resource Type"],
          row["Resource Name"],
          row["Old Value"],
          row["New Value"],
          row["Date"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        columnStyles: {
          0: {
            columnWidth: 20,
          },
          1: {
            columnWidth: 24,
          },
          2: {
            columnWidth: 24,
          },
          3: {
            columnWidth: 24,
          },
          4: {
            columnWidth: 40,
          },
          5: {
            columnWidth: 40,
          },
          6: {
            columnWidth: 20,
          },
        },
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  function modifyName(name) {
    name = name.replace("_", " ");
    return name.charAt(0).toUpperCase() + name.substr(1);
  }

  return (
        <div className="my-tickets-info-list Tickets-main-wrap">
          <div className="custom-table-div filter-search-icon card card-table-custom">
            <div className="search-filter-div">
              <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                      <div
                        id="assessment-table-main_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <div
                          id="assessment-table-main_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              className=""
                              placeholder="Search"
                              aria-controls="assessment-table-main"
                              onChange={handleSearchFilter}
                              value={search}
                            />
                          </label>
                          <div className="filter-eff filter-data-btn">
                            <button className="filter-buttons">
                              <i className="fal fa-filter"></i>
                            </button>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                    <div className="filter-scroll">
                    <div className={`filter-scroll-inner  filter-custom-new`}>
                        <div className="assessment-table-filter">
                          <div className="btn-type-filter dropdown-comman">
                            <div className="dropdown multiselect">
                              <button
                                className={`btn btn-default dropdown-toggle ${
                                  actiontype.length ? "btn-selected" : ""
                                }`}
                                type="button"
                                id="dropdownMenu1"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                title="Action Type"
                              >
                                <span>
                                  Action Type <i className="fal fa-angle-down"></i>
                                </span>
                              </button>
                              <form className="dropdown-menu remove-list-style">
                                <ul aria-labelledby="dropdownMenu1">
                                  <li className="cat cat-skyblue">
                                    <input
                                      type="checkbox"
                                      id="action1"
                                      value="Created"
                                      onClick={(e) => {
                                        handleTypeFilter(e, "action");
                                      }}
                                      checked={searchActionTypeCheck.action1}
                                    />
                                    <label htmlFor="action1">
                                      <span className="cat cat-skyblue">
                                        <i className="fas fa-circle mr-1"></i> Created
                                      </span>
                                    </label>
                                  </li>
                                  <li className="cat cat-greenhex">
                                    <input
                                      type="checkbox"
                                      id="action2"
                                      value="Edited"
                                      onClick={(e) => {
                                        handleTypeFilter(e, "action");
                                      }}
                                      checked={searchActionTypeCheck.action2}
                                    />
                                    <label htmlFor="action2">
                                      <span className="cat cat-greenhex">
                                        <i className="fas fa-circle mr-1"></i> Edited
                                      </span>
                                    </label>
                                  </li>
                                  <li className="cat cat-red">
                                    <input
                                      type="checkbox"
                                      id="action3"
                                      value="Deleted"
                                      onClick={(e) => {
                                        handleTypeFilter(e, "action");
                                      }}
                                      checked={searchActionTypeCheck.action3}
                                    />
                                    <label htmlFor="action3">
                                      <span className="cat cat-red">
                                        <i className="fas fa-circle mr-1"></i> Deleted
                                      </span>
                                    </label>
                                  </li>
                                </ul>
                              </form>
                            </div>
                          </div>
                        </div>

                        <div className="assessment-table-filter">
                          <div className="btn-type-filter dropdown-comman">
                            <div className="dropdown multiselect">
                              <button
                                className={`btn btn-default dropdown-toggle ${
                                  month.length ? "btn-selected" : ""
                                }`}
                                type="button"
                                id="dropdownMenu1"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                title="Month"
                              >
                                <span>
                                  Month <i className="fal fa-angle-down"></i>
                                </span>
                              </button>
                              <form className="dropdown-menu remove-list-style">
                                <ul aria-labelledby="dropdownMenu1">
                                  {renderMonthUI()}
                                </ul>
                              </form>
                            </div>
                          </div>
                        </div>

                        <div className="assessment-table-filter">
                          <div className="btn-type-filter dropdown-comman">
                            <div className="dropdown multiselect">
                              <button
                                className={`btn btn-default dropdown-toggle ${
                                  year.length ? "btn-selected" : ""
                                }`}
                                type="button"
                                id="dropdownMenu1"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                title="Year"
                              >
                                <span>
                                  Year <i className="fal fa-angle-down"></i>
                                </span>
                              </button>
                              <form className="dropdown-menu remove-list-style">
                                <ul aria-labelledby="dropdownMenu1">
                                  {renderYearUI()}
                                </ul>
                              </form>
                            </div>
                          </div>
                        </div>
                    </div>
                    </div>
                    <div className="reset-btn-group">
                      <div className="button-reset dropdown-comman">
                        <button
                          className="btn btn-primary"
                          onClick={resetFilter}
                          title="Reset"
                        >
                          <i className="fal fa-redo"></i>Reset
                        </button>
                      </div>

                      <div className="files-export-group">
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("xlsx", "Campus_audit_trail");
                          }}
                          title="Export spreadsheet"
                        >
                          <i className="fal fa-file-excel icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("csv", "Campus_audit_trail");
                          }}
                          title="Export CSV"
                        >
                          <i className="fal fa-file-csv icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("pdf", "Campus_audit_trail");
                          }}
                          title="Export PDF"
                        >
                          <i className="fal fa-file-pdf icon"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoaded ? (
              <SkeletonTicketList />
            ) : (
              <DataTable
                data={dataToRender()}
                defaultSortField="Date"
                defaultSortAsc={true}
                columns={columns}
                pagination={true}
                noDataComponent={Str.noRecord}
                paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              />
            )}
          </div>
        </div>
      );
};

export default AuditTrail;
