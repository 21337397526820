import { Modal } from 'react-bootstrap';
import { FormikProvider, Form, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

export const SetToInProgressDialog = ({
  dialogOpen,
  setDialogOpen,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues: {
      reasonDesc: '',
      sendEmail: true,
    },
    validationSchema: Yup.object().shape({
      reasonDesc: Yup.string().required(
        'Reason for Setting to Back in Progress is required'
      ),
    }),
    onSubmit: (values) => onSubmit(values),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setValues,
    handleBlur,
    resetForm,
  } = formik;
  return (
    <Modal
      className="modal content-assembly fade"
      size="md"
      id="setToInProgressModal"
      role="dialog"
      show={dialogOpen}
      centered
      onHide={() => setDialogOpen(false)}

    >
      <Modal.Header className="modal-header-custom" closeButton>
        <h5 className="modal-title">
          <i className="fal fa-undo"></i> Set to Back In Progress
        </h5>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="px-4 pb-2 pt-3">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} resetForm>
              <div className="row center-label mb-4">
                <div className="col-md-6 col-lg-12">
                  <div className="form-group-blk mb-4">
                    <label htmlFor="item_desc">
                      Reason for Setting to Back in Progress*
                    </label>
                    <input
                      type="text"
                      title="Reason for Setting to Back in Progress"
                      className={
                        'form-control' +
                        (errors.reasonDesc && touched.reasonDesc
                          ? ' is-invalid'
                          : '')
                      }
                      placeholder="Enter Reason for Setting to Back in Progress"
                      name="reasonDesc"
                      value={values.reasonDesc}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="form-group-blk mt-3">
                    <div className="custom-check custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="sentEmail"
                        name="sendEmail"
                        checked={formik.values.sendEmail}
                        onChange={(e) =>
                          formik.setFieldValue('sendEmail', e.target.checked)
                        }
                      />
                      <label className="custom-control-label" htmlFor="email">
                        Include Email to Student
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group form-group-save-cancel mt-3">
                <button className="btn btn-save btn-success"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                  <i className="fas fa-cog fa-spin"></i>
                  ) : 
                    <i className = "fas fa-paper-plane"></i>
                  }
                  Submit
                </button>
                <button type="button" className="btn btn-close btn-danger">
                  <i className="fal fa-times"></i>
                  Cancel
                </button>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </Modal.Body>
    </Modal>
  );
};
