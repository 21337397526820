const initialState = {
    config: new Object(),
  };
  
  const brandVisualReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_BRAND_VISUALS":
        state.config = action.payload;
        return { config: state.config };
      case "CLEAR_BRAND_VISUALS":
        state.config = new Object();
        return { config: state.config };
      default:
        return state;
    }
  };
  
  export default brandVisualReducer;
  