import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";
import { DepartmentRender, ColorRenderDept, StatusRender, ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { getTicketsSummary, getMyTickets } from "../../../services/TicketsService";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import { InitialRender } from "../../common/Helper";
import Hover from "../../common/Hover";
import { checkIfImageExists, handleTableScroll } from "../../../utils/commonFunction";
import { RenderTicketStatus } from "../../../utils/CommonStatusItems";
import Tablefilter from "../../common/Tablefilter";

const Tickets = () => {
  const [search, setSearch] = useState("");
  // const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [searchDepartment, setSearchDepartment] = useState([]);
  const [searchDepartmentCheck, setSearchDepartmentCheck] = useState({});
  const [searchCat, setSearchCat] = useState([]);
  const [searchCatCheck, setSearchCatCheck] = useState({});
  const [ticketSummary, setTicketSummary] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [deleterow, setDeleteRow] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    handleTableScroll()
  }, [isLoaded])

  useEffect(() => {
    getTicketsSummary(id)
      .then((res) => {
        setTicketSummary(res?.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  }, [])

  useEffect(() => {
    getMyTickets(id)
      .then((res) => {
        setTicketList(res?.data?.tickets?.ticketsList);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });

  }, [deleterow]);

  const departments = [
    {
      id: 1,
      title: "Administration",
      helper_department_id: 2,
      status: 1,
      updated_at: "Invalid date",
      categories: [
        {
          id: 2,
          department_id: 1,
          title: "Studykits",
          status: 1,
          updated_at: "2020-07-23 13:07:27",
        },
        {
          id: 3,
          department_id: 1,
          title: "Complaint",
          status: 1,
          updated_at: "2020-07-23 13:07:41",
        },
        {
          id: 4,
          department_id: 1,
          title: "Other",
          status: 1,
          updated_at: "2020-07-23 13:07:56",
        },
      ],
    },
    {
      id: 2,
      title: "Academic",
      helper_department_id: 6,
      status: 1,
      updated_at: "Invalid date",
      categories: [
        {
          id: 5,
          department_id: 2,
          title: "Assessments",
          status: 1,
          updated_at: "2020-07-23 13:08:17",
        },
        {
          id: 6,
          department_id: 2,
          title: "External Exam Query",
          status: 1,
          updated_at: "2020-07-23 13:08:34",
        },
        {
          id: 7,
          department_id: 2,
          title: "Internal Exam Query",
          status: 1,
          updated_at: "2020-07-23 13:08:56",
        },
        {
          id: 8,
          department_id: 2,
          title: "Complaint",
          status: 1,
          updated_at: "2020-07-23 13:09:10",
        },
        {
          id: 9,
          department_id: 2,
          title: "Other",
          status: 1,
          updated_at: "2020-07-23 13:09:26",
        },
        {
          id: 10,
          department_id: 2,
          title: "Academic Query",
          status: 1,
          updated_at: "2020-07-30 14:55:41",
        },
      ],
    },
    {
      id: 6,
      title: "Sales",
      helper_department_id: 4,
      status: 1,
      updated_at: "Invalid date",
      categories: [
        {
          id: 1,
          department_id: 6,
          title: "Accounts",
          status: 1,
          updated_at: "2020-07-23 13:07:08",
        },
        {
          id: 11,
          department_id: 6,
          title: "Other",
          status: 1,
          updated_at: "2020-07-30 14:56:28",
        },
        {
          id: 12,
          department_id: 6,
          title: "Complaint",
          status: 1,
          updated_at: "2020-07-30 14:56:42",
        },
        {
          id: 13,
          department_id: 6,
          title: "New Studies",
          status: 1,
          updated_at: "2020-07-30 14:57:01",
        },
        {
          id: 14,
          department_id: 6,
          title: "Current Studies",
          status: 1,
          updated_at: "2020-07-30 14:57:13",
        },
      ],
    },
    {
      id: 5,
      title: "Student Support",
      helper_department_id: 3,
      status: 1,
      updated_at: "Invalid date",
      categories: [
        {
          id: 15,
          department_id: 5,
          title: "Other",
          status: 1,
          updated_at: "2020-07-30 14:58:10",
        },
        {
          id: 16,
          department_id: 5,
          title: "Complaint",
          status: 1,
          updated_at: "2020-07-30 14:58:30",
        },
        {
          id: 17,
          department_id: 5,
          title: "Student Portal",
          status: 1,
          updated_at: "2020-07-30 14:58:42",
        },
        {
          id: 18,
          department_id: 5,
          title: "Software",
          status: 1,
          updated_at: "2020-07-30 14:58:54",
        },
        {
          id: 19,
          department_id: 5,
          title: "Online/Virtual Classes",
          status: 1,
          updated_at: "2020-07-30 14:59:13",
        },
      ],
    },
    {
      id: 7,
      title: "Finance",
      helper_department_id: 8,
      status: 1,
      updated_at: "Invalid date",
      categories: [
        {
          id: 20,
          department_id: 7,
          title: "Other",
          status: 1,
          updated_at: "2020-07-30 15:00:01",
        },
        {
          id: 21,
          department_id: 7,
          title: "Complaint",
          status: 1,
          updated_at: "2020-07-30 15:00:13",
        },
        {
          id: 22,
          department_id: 7,
          title: "Portal on Hold",
          status: 1,
          updated_at: "2020-07-30 15:02:07",
        },
        {
          id: 23,
          department_id: 7,
          title: "General Account Query",
          status: 1,
          updated_at: "2020-07-30 15:02:25",
        },
        {
          id: 24,
          department_id: 7,
          title: "Statement Request",
          status: 1,
          updated_at: "2020-07-30 15:02:42",
        },
      ],
    },
  ];

  const columns = useMemo(() => [
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
      cell: (row, index) => (
        <Link className="as-text-blue curser feature-name" to={{ pathname: `/editticket/${row.id_ticket}` }} title={row.subject}>
          <span className="textLimit100">{row.subject}</span>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => RenderTicketStatus(row.status).html,
    },
    {
      name: "Department",
      selector: "department",
      wrap: true,
      sortable: true,
      cell: (row) => <DepartmentRender status={row.department} />,
    },
    {
      name: "Days Overdue",
      selector: "dueDays",
      wrap: true,
      sortable: true,
      cell: (row) => (
        row.dueDays ? row.dueDays : "-"
      )
    },

    {
      name: "Created",
      selector: "date_add",
      sortable: true,
      cell: (row) => (
        <>{row.date_add ? <div className="dateTime">
          <p className="right-space">{moment(row.date_add).format(TABLE_DATE_FORMAT)}</p>
          <p>{moment(row.date_add).format(TABLE_TIME_FORMAT)}</p>
        </div>
          : "-"}
        </>
      )
    },
    {
      name: "Modified",
      selector: "last_update",
      sortable: true,
      cell: (row) => (
        <>{row.last_update ? <div className="dateTime">
          <p className="right-space">{moment(row.last_update).format(TABLE_DATE_FORMAT)}</p>
          <p>{moment(row.last_update).format(TABLE_TIME_FORMAT)}</p>
        </div>
          : "-"}
        </>
      )
    },
    {
      name: "Assigned To",
      selector: "assign_to",
      sortable: true,
      cell: (row, index) => (

        <>
          {row.assign_to ? <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(row.profession)}>
                {row.assign_to_photo && checkIfImageExists(`${IMAGE_URL}/${row.assign_to_photo.replace("/home/myaie/public_html/", "").replace("public/")}`) ? <img src={`${IMAGE_URL}/${row.assign_to_photo.replaceAll("/home/myaie/public_html/", "").replace("public/")}`} alt="AIE" /> : InitialRender([row.assign_to.split(" ")[0], row.assign_to.replace(/ +/g, ' ').split(" ")[1]])}
                {/* <span className={`profile-box-2-status ${row.statue ? row.status : "Offline"}`}>
                <i className="fas fa-circle"></i>
              </span> */}
                <span className={`profile-box-2-status ${row.assign_activity_status ? row.assign_activity_status.toLowerCase() == "online" ? "Online" : row.assign_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                  <i className="fas fa-circle"></i>
                </span>
                <Hover firstName={row.assign_to.split(" ")[0]} lastName={row.assign_to.replace(/ +/g, ' ').split(" ")[1]} photo={row.assign_to_photo} email={row.assign_to_email} mobile={row.assign_to_number} right={false} role={row.profession} status={row.assign_status} activity_status={row.assign_activity_status} />
              </span>
              <Link className="as-text-blue curser feature-name" key={index} to={{ pathname: `/systemAdministration/userManagement/open/${row.id_student}/aboutYou` }} >
                <span className="textLimit100">{row.assign_to}</span>
              </Link>
            </div>
          </div> : "-"}
        </>
        // <div className="">
        //   <p>
        //     <span className="overflow-ellipsis2">
        //       {row.assign_to ? row.assign_to : "-"}
        //     </span>
        //   </p>
        // </div>
      ),
    },
    {
      name: "Category",
      selector: "category",
      // wrap: true,
      sortable: true,
      cell: (row) => {
        return (row.category ? row.category : "-")
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <Link to={{ pathname: "/editticket/" + row.id_ticket }}>
              <button title="Open" className="btn btn-primary rounded-circle">
                <i className="fal fa-folder-open"></i>
              </button>
            </Link>
          </div>
        </div>
      ),
    },
  ]);

  const dataToRender = () => {
    let updatedData = [];
    let allData = ticketList;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes = item.id_ticket.toString().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempSearch = allData.filter((item) => {
        let includes = item.subject && item.subject.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempDateAdd = allData.filter((item) => {
        let includes = item.date_add && moment(item.date_add).format(TABLE_DATE_TIME_FORMAT).toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempDateModified = allData.filter((item) => {
        let includes = item.last_update && moment(item.last_update).format(TABLE_DATE_TIME_FORMAT).toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempDepartment = allData.filter((item) => {
        let includes = item.department && item.department.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempAssignTo = allData.filter((item) => {
        let includes = item.assign_to && item.assign_to.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempAssignToNA = allData.filter((item) => {
        let includes = search.toLowerCase() == "na" && item.assign_to == null;
        if (includes) {
          return includes;
        } else return null;
      });
      let tempCategory = allData.filter((item) => {
        let includes = item.category && item.category.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        // ...tempId,
        ...tempSearch,
        ...tempDateAdd,
        ...tempDateModified,
        ...tempDepartment,
        ...tempAssignTo,
        ...tempAssignToNA,
        ...tempCategory
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status !== "") {
      let tempStatus = updatedData;
      let tempSearch = tempStatus.filter((item) => (item.status === status));

      updatedData = tempSearch;
    }
    if (searchDepartment.length) {
      let tempStatus = updatedData;
      let tempSearch = tempStatus.filter((item) => {
        let tempItem = item.department ? item.department.toLowerCase() : "";
        var tempItemNew = tempItem ? tempItem.replace(/-/g, " ") : tempItem;
        const startsWith = searchDepartment.find(function (post, index) {
          let tempPost = post ? post.toLowerCase() : post;
          var tempPostNew = tempPost ? tempPost.replace(/-/g, " ") : tempPost;
          if (tempPostNew == tempItemNew) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (searchCat.length) {
      let tempStatus = updatedData;
      let tempSearch = tempStatus.filter((item) => {
        let tempItem = item.category ? item.category.toLowerCase() : "";
        var tempItemNew = tempItem ? tempItem.replace(/-/g, " ") : tempItem;
        const startsWith = searchCat.find(function (post, index) {
          let tempPost = post ? post.toLowerCase() : post;
          var tempPostNew = tempPost ? tempPost.replace(/-/g, " ") : tempPost;
          if (tempPostNew == tempItemNew) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (status !== "" || search.length || searchDepartment.length || searchCat.length) {
      return updatedData.length > 0 ? updatedData : [];
    } else {
      return ticketList;
    }
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleStatusFilter = (e) => {
    const value = e.target.value;
    let arr = status;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setStatus(arr);
    let id = e.target.id;
    setSearchStatusCheck({ ...searchStatusCheck, [id]: !searchStatusCheck[id] });
  };

  const handleDepartmentFilter = (e) => {
    const value = e.target.value;
    let arr = searchDepartment;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setSearchDepartment(arr);
    let id = e.target.id;
    setSearchDepartmentCheck({ ...searchDepartmentCheck, [id]: !searchDepartmentCheck[id] });
    setSearchCat([]);
    setSearchCatCheck([]);
  };

  const handleCatFilter = (e) => {
    const value = e.target.value;
    // console.log('the value of the filter is ', value)
    let arr = searchCat;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setSearchCat(arr);
    let id = e.target.id;
    setSearchCatCheck({ ...searchCatCheck, [id]: !searchCatCheck[id] });
  };

  const resetFilter = () => {
    setStatus({ arr: [], checkObj: {} });
    setSearchStatusCheck({});
    setSearchDepartment([]);
    setSearchDepartmentCheck({});
    setSearchCat([]);
    setSearchCatCheck({});
    setSearch("");
  };

  function compare(a, b) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }


  const renderDepartmentFilterUI = () => {
    let tempDept = departments;
    return tempDept.sort(compare).map((i) => {
      return (
        <li>
          <input type="checkbox" id={i.title} value={i.title} onClick={handleDepartmentFilter} checked={searchDepartmentCheck[i.title]} />
          <label htmlFor={i.title}>
            <DepartmentRender status={i.title} />
          </label>
        </li>
      );
    });
  };

  const renderCatFilterUI = useMemo(() => {
    let tempDept = departments;
    let tempCat = [];
    let unique = [];
    let arr = searchDepartment;
    if (arr.length == 0) {
      tempDept.map((i) => {
        tempCat = [...tempCat, ...i.categories];
      });
    } else {
      tempDept.map((i) => {
        if (arr.includes(i.title)) {
          tempCat = [...tempCat, ...i.categories];
        }
      });
    }
    unique = [...new Set(tempCat.map((item) => item.title))];
    return unique.sort().map((i) => {
      return (
        <li className={"cat "}>
          <input type="checkbox" id={i} value={i} onClick={handleCatFilter} checked={searchCatCheck[i]} />
          <label for={i}>
            <span className={"cat "}>
              {i}
            </span>
          </label>
        </li>
      );
    });
  });

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Subject", "Status", "Department", "Days Overdue", "Created", "Modified", "Assigned To", "Category"];

    data = data?.map((row) => ({
      ...row,
      Subject: row?.subject,
      Status: RenderTicketStatus(row?.status).text,
      Department: row?.department,
      "Days Overdue": row.dueDays ? row.dueDays : "-",
      Created: row.date_added,
      Modified: row.date_update,
      "Assigned To": row.assign_to,
      Category: row.category,
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row["Subject"], row["Status"], row["Department"], row["Days Overdue"], row["Created"], row["Modified"], row["Assigned To"], row["Category"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const [subTab, setSubTab] = useState("summary")
  const [status, setStatus] = useState("")

  const SubTabsOption = [
    { icon: "fal fa-globe-africa", value: "all", label: "All Tickets" },
    { icon: "fal fa-laptop", value: "Open", label: "Open" },
    { icon: "fal fa-laptop", value: "New", label: "New" },
    { icon: "fal fa-file-check", value: "Closed", label: "Closed" },
    { icon: "fal fa-file-search", value: "Pending-Investigation", label: "Pending-Investigation" },
    { icon: "fal fa-file-alt", value: "Pending-Student", label: "Pending-Student" },
    { icon: "fal fa-file-spreadsheet", value: "Pending-Academy", label: "Pending-Academy" },
  ]

  return (

    <>
      <div className="tabs-wrap">
        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
          <li
            className="nav-item"
            onClick={() => {
              setSubTab(["summary"]);
            }}
          >
            <a
              className={`nav-link cursor-pointer ${subTab === "summary" ? "active" : ""
                }`}
              id="pills-tab32"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab32"
              aria-selected="true"
            >
              <i className="fal fa-books"></i>Summary
            </a>
          </li>
          {SubTabsOption.map((tab) => (
            <li
              className="nav-item"
              onClick={() => {
                setSubTab([tab.label]);
                if (tab.value !== "all") {
                  setStatus(tab.value)
                } else {
                  setStatus("")
                }
              }}
            >
              <a
                className={`nav-link cursor-pointer ${subTab === tab.label ? "active" : ""
                  }`}
                id="pills-tab2"
                data-toggle="pill"
                role="tab"
                aria-controls="pills-tab12"
                aria-selected="true"
              >
                <i className={tab.icon}></i>{tab.label}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {subTab != "summary" ? (
        <div className="my-tickets-info-list Tickets-main-wrap">
          <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
            <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom days-overdue-cell-width">
              <div className="search-filter-div">
                <div className="search-filter-div-left">
                  <div className="system-administration-table table-responsive">
                    <div className="table-responsive-div">
                      <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                        <div id="assessment-table-main_filter" className="dataTables_filter">
                          <label>
                            <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                          </label>
                          <div className="filter-eff filter-data-btn">
                            <button className="filter-buttons">
                              <i className="fal fa-filter"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="filter-button-group">
                      <div className="filter-scroll">
                        <div className={`filter-scroll-inner  filter-custom-new`}>
                          <div className="assessment-table-filter">
                            <div className="btn-type-filter dropdown-comman">
                              <div className={`dropdown multiselect`}>
                                <button
                                  className={`btn btn-default dropdown-toggle ${searchDepartment.length ? "btn-selected" : ""}`}
                                  type="button"
                                  id="dropdownMenu1"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                  title="Department"
                                >
                                  <span>
                                    Department <i className="fal fa-angle-down"></i>
                                  </span>
                                </button>
                                <form className="dropdown-menu remove-list-style">
                                  <ul aria-labelledby="dropdownMenu1">{renderDepartmentFilterUI()}</ul>
                                </form>
                              </div>
                            </div>
                          </div>
                          <div className="assessment-table-filter">
                            <div className="btn-type-filter dropdown-comman">
                              <div className={`dropdown multiselect`}>
                                <button
                                  className={`btn btn-default dropdown-toggle ${searchCat.length ? "btn-selected" : ""}`}
                                  type="button"
                                  id="dropdownMenu1"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                  title="Category"
                                >
                                  <span>
                                    Category <i className="fal fa-angle-down"></i>
                                  </span>
                                </button>
                                <form className="dropdown-menu remove-list-style">
                                  <ul aria-labelledby="dropdownMenu1">{renderCatFilterUI}</ul>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="reset-btn-group">
                        <div className="button-reset dropdown-comman">
                          <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                            <i className="fal fa-redo"></i>Reset
                          </button>
                        </div>

                        <div className="files-export-group">
                          <button
                            type="button"
                            className="btn btn-files"
                            title="Export spreadsheet"
                            onClick={() => {
                              exportData("xlsx", "Tickets");
                            }}
                          >
                            <i className="fal fa-file-excel icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            title="Export CSV"
                            onClick={() => {
                              exportData("csv", "Tickets");
                            }}
                          >
                            <i className="fal fa-file-csv icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            title="Export PDF"
                            onClick={() => {
                              exportData("pdf", "Tickets");
                            }}
                          >
                            <i className="fal fa-file-pdf icon"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isLoaded && <SkeletonTicketList />}
              {isLoaded && <DataTable
                data={dataToRender()}
                columns={columns}
                pagination={true}
                defaultSortField="date_add"
                defaultSortAsc={false}
                onColumnOrderChange={(cols) => console.log(cols)}
                paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              />}
            </div>
          </div>
        </div>
      ) : (
        <div className="card-new card card-profile-info-card">
          <div className="row">
            <div className="col-md-6">
              <div className="card-new card card-profile-info-card">
                <div className="row ticket-detail-container">
                  <div className="col-lg-6 ticket-detail-section">
                    <div className="ticket-detail-item danger-ticket-item">
                      <span className="danger-ticket title">
                        Total Danger Zone Tickets
                      </span>
                      <span className="data danger-ticket">
                        {ticketSummary?.totalDangerTickets !== null
                          ? ticketSummary?.totalDangerTickets
                          : <i className="fas fa-cog fa-spin"></i>}
                      </span>
                    </div>
                    <div className="ticket-detail-item">
                      <span className="title">
                        Total New Tickets
                      </span>
                      <span className="data">
                        {ticketSummary?.totalNewTicket !== null
                          ? ticketSummary?.totalNewTicket
                          : <i className="fas fa-cog fa-spin"></i>}
                      </span>
                    </div>
                    <div className="ticket-detail-item">
                      <span className="title">
                        Total Tickets Closed
                      </span>
                      <span className="data">
                        {ticketSummary?.totalClosedTickets !== null ? ticketSummary?.totalClosedTickets
                          : <i className="fas fa-cog fa-spin"></i>}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-6 ticket-detail-section">
                    <div className="ticket-detail-item">
                      <span className="title">
                        Total Pending - Academy
                      </span>

                      <span className="data">
                        {ticketSummary?.totalPendingAcademy !== null
                          ? ticketSummary?.totalPendingAcademy
                          : <i className="fas fa-cog fa-spin"></i>}
                      </span>
                    </div>
                    <div className="ticket-detail-item">
                      <span className="title">
                        Total Pending - Investigation
                      </span>

                      <span className="data">
                        {ticketSummary?.totalPendingAcademy !== null
                          ? ticketSummary?.totalPendingAcademy
                          : <i className="fas fa-cog fa-spin"></i>}
                      </span>
                    </div>
                    <div className="ticket-detail-item">
                      <span className="title">
                        Total Pending - Student
                      </span>
                      <span className="data">
                        {ticketSummary?.totalPendingStudent !== null
                          ? ticketSummary?.totalPendingStudent
                          : <i className="fas fa-cog fa-spin"></i>}
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4">
                  <div className="ticket-response-section">
                    <h5>New Ticket Response Time</h5>
                    <div class="clock-box"><i class="fal fa-clock as-red"></i></div>
                    <p>Our average Response Time</p>
                    {!ticketSummary?.avgResTimeNewTicket ? (
                      <span class="date-time-box"><i className="fas fa-cog fa-spin"></i></span>
                    ) : (
                      <span className="date-time-box as-red">
                        {`${ticketSummary?.avgResTimeNewTicket?.days
                          .toString()
                          .split(".")[0] + "d"
                          }: ${ticketSummary?.avgResTimeNewTicket?.hours
                            .toString()
                            .split(".")[0] + "h"
                          }: ${ticketSummary?.avgResTimeNewTicket?.minutes
                            .toString()
                            .split(".")[0] + "m"
                          }: ${ticketSummary?.avgResTimeNewTicket?.seconds
                            .toString()
                            .split(".")[0] + "s"
                          } `}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ticket-response-section">
                    <h5>Pending-Academy Response Time</h5>
                    <div class="clock-box"><i class="fal fa-clock as-red"></i></div>
                    <p>Our average Response Time</p>
                    {!ticketSummary?.avgResTimePendingTicket ? (
                      <span class="date-time-box"><i className="fas fa-cog fa-spin"></i></span>
                    ) : (
                      <span className="as-red date-time-box">
                        {`${ticketSummary?.avgResTimePendingTicket?.days
                          .toString()
                          .split(".")[0] + "d"
                          }: ${ticketSummary?.avgResTimePendingTicket?.hours
                            .toString()
                            .split(".")[0] + "h"
                          }: ${ticketSummary?.avgResTimePendingTicket?.minutes
                            .toString()
                            .split(".")[0] + "m"
                          }: ${ticketSummary?.avgResTimePendingTicket?.seconds
                            .toString()
                            .split(".")[0] + "s"
                          } `}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      )}
    </>
  );
};

export default Tickets;
