import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { GetQualificationOptionsInactiveCancelToken } from "../../../../services/RegistrationService";
import { GetMicrosoftAcount, GetNetsuiteCustomerDetails, GetRegistrationData, GetRegistrationDataCancelToken, GetStudentCrmDataDataCancelToken, GetStudentEditData, GetStudentElectricCardCancelToken, GetStudentEmailLogsCancelToken, GetStudentExamRegDataCancelToken, getStudentCrmData, getStudentData, getStudentDataCancelToken, getStudentElectricCard, getStudentEmailLogs, getStudentExamRegData } from "../../../../services/StudentsSettingServices";
import hasPermission from "../../../../utils/hasMultiplePermission";
import CRMData from "./GeneralTabs/CRMData";
import EmailTab from "./GeneralTabs/EmailTab";
import ExamRegistrations from "./GeneralTabs/ExamRegistrations";
import GeneralTab from "./GeneralTabs/GeneralTab";
import NetSuiteDataStudent from "./GeneralTabs/NetSuiteDataStudent";
import RegistrationTab from "./GeneralTabs/RegistrationTab";
import StudentLoginTab from "./GeneralTabs/StudentLoginTab";
import StudentBlock from "./GeneralTabs/StudentTabs";
import PermissionsGate from "../../../../utils/permissionGate";
import axios from "axios";

function General({ studentDetailData }) {
  const { id } = useParams();
  const [sData, setSData] = useState();
  const [intakeYear, setIntakeYear] = useState([]);
  const [loading, setLoading] = useState({ examReg: false, electricCard: false, logs: false, studentData: false, netSuiteData: false })
  const [updateData, setUpdateData] = useState(true);
  const [registrationData, setRegistrationData] = useState([]);
  const [netSuiteData, setNetSuiteData] = useState([]);
  const [brandError, setBrandError] = useState(true)
  const [qualList, setQualList] = useState([]);
  const [registerLoading, setRegisterLoading] = useState({ data: false, qualfications: false })
  const [crmLoading, setCrmLoading] = useState(false)

  const history = useHistory();

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["sgiview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  useEffect(() => {
    const cancelTokenSources = [];

    const getRegistration = async () => {
      setRegisterLoading({ data: true});
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const formData = new FormData();
      formData.append("student_id", id);
      formData.append("tabtype", "general");
  
      try {
        const res = await GetRegistrationDataCancelToken(formData, source.token);
        setRegistrationData(res.data)
        if (res.status === 200) {
          setRegisterLoading(oldVal => ({ ...oldVal, data: false }))
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setRegisterLoading(oldVal => ({ ...oldVal, data: false }))
        }
      }
    };
  
    getRegistration();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData])
  
  useEffect(() => {
    const qualificationSource = [];

    const getQualification = async () => {
      setRegisterLoading({ qualfications: true});
  
      qualificationSource.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      qualificationSource.push(source);

      try {
        const res = await GetQualificationOptionsInactiveCancelToken(false, source.token);
        setQualList(res.data.data.items)
        if (res.status === 200) {
          setRegisterLoading(oldVal => ({ ...oldVal, qualfications: false }))
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setRegisterLoading(oldVal => ({ ...oldVal, qualfications: false }))
        }
      }
    };
  
    getQualification();

    return () => {
      qualificationSource.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData])

  useEffect(() => {
    const studentExamRegSource = [];

    const getStudentExamReg = async () => {
      setLoading({ examReg: true });

      studentExamRegSource.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      studentExamRegSource.push(source);

      try {
        const res = await GetStudentExamRegDataCancelToken(id, source.token);
        setSData(prev => ({ ...prev, exams: res.data }))
        if (res.status === 200) {
          setLoading({ examReg: false })
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading({ examReg: false })
        }
      }
    };
  
    getStudentExamReg();

    return () => {
      studentExamRegSource.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData])

  useEffect(() => {
    const studentCRMSource = [];

    const getStudentCRM = async () => {
      setCrmLoading(true);

      studentCRMSource.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      studentCRMSource.push(source);

      try {
        const res = await GetStudentCrmDataDataCancelToken(id, source.token);
        setSData(prev => ({ ...prev, getCRMData: res.data }))
        if (res.status === 200) {
          setCrmLoading(true);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setCrmLoading(true);
        }
      }
    };
  
    getStudentCRM();

    return () => {
      studentCRMSource.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData])

  useEffect(() => {
    const studentElectricCardSource = [];

    const getStudentElectricCard = async () => {
      setLoading({ electricCard: true });

      studentElectricCardSource.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      studentElectricCardSource.push(source);

      try {
        const res = await GetStudentElectricCardCancelToken(id, source.token);
        setSData(prev => ({ ...prev, electricCard: res.data }))
        const obj = res.data?.intakeArr || {}
        let tempArr = []
        Object.keys(obj).forEach(item => {
          tempArr.push({ value: obj[item], label: obj[item] })
        })
        setIntakeYear(tempArr)
        if (res.status === 200) {
          setLoading(prev => ({...prev, electricCard: false }))
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(prev => ({...prev, electricCard: false }))
        }
      }
    };
  
    getStudentElectricCard();

    return () => {
      studentElectricCardSource.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData])

  useEffect(() => {
    const studentEmailSource = [];

    const getStudentEmail = async () => {
      setLoading({ logs: true });

      studentEmailSource.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      studentEmailSource.push(source);

      try {
        const res = await GetStudentEmailLogsCancelToken(id, source.token);
        setSData(prev => ({ ...prev, getLogSMS: res.data.getLogSMS, getEmailLog: res.data.getEmailLog, getStatusLog: res.data.getStatusLog }))
        if (res.status === 200) {
          setLoading(prev => ({...prev, logs: false }))
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(prev => ({...prev, logs: false }))
        }
      }
    };
  
    getStudentEmail();

    return () => {
      studentEmailSource.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData])

  useEffect(() => {
    const cancelTokenSources = [];

    const getCrmData = async () => {
      setLoading(prev => ({...prev, studentData: true, netSuiteData: true }))
      setCrmLoading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await getStudentDataCancelToken(id, source.token);
        if (res.status === 200) {
          setSData(prev => ({ ...prev, getStudent: res.data?.getStudent, currentBrand: res.data?.currentBrand, brand: res.data?.brand, student_current_status: res.data?.student_current_status }))
          setLoading(prev => ({...prev, studentData: false }))
          GetNetsuiteCustomerDetails(res.data.getStudent.netsuite_id, res.data.getStudent.email, res.data.getStudent.NScontactID, res.data.getStudent.bookingId).then((response) => {
            try{
              let final = response?.data?.data?.map((item) => {
                return { ...item, index: response.data.data.indexOf(item) + 1 }
              })
              setNetSuiteData(final)
            }catch(err){
              console.log(err)
            }
            setLoading(prev => ({...prev, netSuiteData: false }))
          }).catch((err) => {
            console.error("error : ", err)
            setLoading(prev => ({...prev, netSuiteData: false }))
            setCrmLoading(false)
          })
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(prev => ({...prev, studentData: false, netSuiteData: false }))
        }
      }
    };
  
    getCrmData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData]);
  return (
    <>
      <div className="card card-profile-info-card">
        <div className="">
          <div className="card-body-inr card-body-info">
            <GeneralTab studentData={sData} smsLog={sData && sData.getSMSLog && sData.getSMSLog[0]} emailLog={sData && sData.getEmailLog && sData.getEmailLog[0]} statusLog={sData && sData.getStatusLog && sData.getStatusLog[0]} loading={loading.studentData} updateData={updateData} setUpdateData={setUpdateData} brandError={brandError} setBrandError={setBrandError} />
            <hr />
            <PermissionsGate scopes={['sgiedit']}>
            <PermissionsGate scopes={['snedit']}>
            <EmailTab studentData={sData} smsLog={sData && sData.getSMSLog && sData.getSMSLog[0]} emailLog={sData && sData.getEmailLog && sData.getEmailLog[0]} statusLog={sData && sData.getStatusLog && sData.getStatusLog[0]} loading={loading.logs} updateData={updateData} setUpdateData={setUpdateData} brandError={brandError} setBrandError={setBrandError} />
            <hr />
            </PermissionsGate>
            </PermissionsGate>
            <StudentLoginTab studentData={sData} smsLog={sData && sData.getSMSLog && sData.getSMSLog[0]} emailLog={sData && sData.getEmailLog && sData.getEmailLog[0]} statusLog={sData && sData.getStatusLog && sData.getStatusLog[0]} loading={loading.logs} updateData={updateData} setUpdateData={setUpdateData} brandError={brandError} setBrandError={setBrandError} />
            <hr />
            <StudentBlock studentData={sData && sData.electricCard} intakeYear={intakeYear} loading={loading.electricCard} updateData={updateData} setUpdateData={setUpdateData} brandError={brandError} setBrandError={setBrandError} brandValue={sData && sData.currentBrand} />
            <PermissionsGate scopes={['regview']}><hr />
            <NetSuiteDataStudent tempData={netSuiteData} loading={loading.netSuiteData} updateData={updateData} setUpdateData={setUpdateData} />
            <hr />
            {/* <CRMData studentDetailData={studentDetailData} tempData={sData} loading={crmLoading} updateData={updateData} setUpdateData={setUpdateData} />
            <hr /> */}
            <RegistrationTab studentData={registrationData && registrationData} updateData={updateData} setUpdateData={setUpdateData} qualList={qualList} registerLoading={registerLoading} />
            <hr />
            </PermissionsGate>            
            <PermissionsGate scopes={['eerview']}>
            <ExamRegistrations studentData={sData && sData.exams} loading={loading.examReg} updateData={updateData} setUpdateData={setUpdateData} />
            </PermissionsGate>
          </div>
        </div>
      </div>
    </>
  );
}

export default General;
