
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import ListOfTemplate from "../components/courses/CourseGradeSettings/ListOfTemplate";
import AddTemplate from "../components/courses/CourseGradeSettings/AddTemplate";
import StudentGrades from "../components/courses/DetailCoursesBlocks/OnlineClassesBlocks/StudentGrades";
import PageComponent from "../components/common/PageComponent";
import { GradeLetterTemplateDetail } from "../services/GradeSettingService";


const StudentCourseGradeBook = () => {
  const [data, setData] = useState([]);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const stdName = params.get("stdName");
  const courseName = params.get("courseName");
  const { id, tab } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
   if(id){ 
    const data = {
      id: id,
    };
    GradeLetterTemplateDetail(data)
      .then((res) => {
        setData(res?.data?.list_data[0]?.name);
      })
      .catch((err) => {
        console.log(err);
      });}
  }, [id]);

  return (
    <PageComponent
      heading={tab === "grades" ? ["Gradebook:", courseName, stdName] : pathname.includes("addTemplate") ? ["Grading", "Letter", "Templates", id && id != "create" ? `${data}` : "Create New Template"] : ["Grading", "Letter", "Templates"] }
      isBackButtonShown
      disableColonShown
      studentGrades={tab === "grades" ? true : false}
      addTemplate={pathname.includes("addTemplate") ? true : false}
      componentToRender={[
        {
          condition: tab === "grades",
          component: <StudentGrades courseName={courseName} stdName={stdName}/>
        }
      ]}
    />
  );
};

export default StudentCourseGradeBook;
