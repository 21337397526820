import axiosInstance from "../utils/axios";

export const AddPopupPages = async (data) => {
    return await axiosInstance().post('/insertPopupData', data)
}

export const updatePopupPages = async (data) => {
    return await axiosInstance().post(`/updatePopUpData`, data)
}

export const getCampusLocationDropdown = async () => {
    return await axiosInstance().get(`/getCampusLocationDropdown`)
}

export const getCampusColorList = async (values) => {
    return await axiosInstance().post(`/getCampusColorList` , values)
}

export const getCampusPagesStatusList = async () => {
    return await axiosInstance().get(`/getPublishedStatusList`)
}

export const getAssigendPagesAndProgrammeShortcourse = async (id,type) => {
    return await axiosInstance().get(`/getAssigendPagesAndProgrammeShortcourse?id=${id}&type=${type}`)
}

export const GetPopupsPageListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getPopUpList`, values, {
        cancelToken: cancelToken
    });
};

export const getPopupList = async (values) => {
    return await axiosInstance().post(`/getPopUpList`, values);
};

export const getAllProgrameAndShortCourseList = async (values) => {
    return await axiosInstance().post(`/getAllProgrameAndShortCourseList`, values);
};

export const getAllPagesList = async (values) => {
    return await axiosInstance().post(`/getAllPagesList`, values);
};

export const GetPopupsPagesListsFilterCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getStaticFilter`, {
        cancelToken: cancelToken
    });
};

export const DeletePopupsPages = async (id) => {
    return await axiosInstance().post(`/deletePopUpData?PopUpID=${id}`);
};

export const GetPopupsUpdateList = async (id) => {
    return await axiosInstance().get(`/getPopUpData?PopUpID=${id}`);
};

export const getAllPagesFilters = async (id , isAssigned , isfor) => {
    return await axiosInstance().get(`/getAllPagesFilters?id=${id}&type=${isAssigned}&isForAllpages=${isfor}`);
};

export const getAssigendPagesAndProgrammeShortcourseFilter = async (id , isAssigned) => {
    return await axiosInstance().get(`/getAssigendPagesAndProgrammeShortcourseFilter?id=${id}&type=${isAssigned}`);
};