import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { GetReRegistrationStudentListDetail, GetStudentListDetail } from "../../../services/NetSuiteService";

function PortalInformation() {
  const { id,tab } = useParams();
  const [studentData, setStudentData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if(tab==="mergedDataRegistration"){
      
      GetReRegistrationStudentListDetail(id).then((res) => {
        setStudentData(res?.data?.data);
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log("error :", err);
      });
    }
    else{
      GetStudentListDetail(id)
      .then((res) => {
        setStudentData(res?.data?.data);
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log("error :", err);
      });
    }
    
  }, []);
  return (
    <div className="card card-profile-info-card">
      <div className={`card-body `}>
        <div className="card-header">Portal Information</div>
        <div className="card-body-inr card-body-info">
          {isLoading ? (
            <SkeletonTicketList />
          ) : (
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="basic-info-box-list">
                  <ul>
                    <li>
                      <span className="title">Student Number :</span>
                      <span> {studentData && tab==="mergedDataRegistration" ? studentData.id_number ?studentData.id_number:"-" : studentData.number?studentData.number:"-"}</span>
                    </li>
                    <li>
                      <span className="title ">Name :</span>
                      <span> {studentData && studentData.first_name && studentData.last_name ? studentData.first_name + " " + studentData.last_name : "-"}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="basic-info-box-list">
                  <ul>
                    <li>
                      <span className="title">Email :</span>
                      <span> {studentData && studentData.email ? studentData.email : "-"}</span>
                      {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/tickets/show" }}>
                        </Link> */}
                    </li>
                    <li>
                      <span className="title">Contact :</span>
                      <span> {studentData && studentData.mobile ? studentData.mobile : "-"}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="basic-info-box-list">
                  <ul>
                    <li>
                      <span className="title">Address :</span>
                      <span>
                         {studentData &&studentData.ph_street ? studentData.ph_street + ", " + studentData.ph_city + ", " + studentData.ph_country + ", " + studentData.ph_province + ", " + studentData.ph_zip : "-"}
                      </span>
                      {/* <span> {studentData.ph_city ? studentData.ph_city : "-"}</span>, 
                      <span> {studentData.ph_country ? studentData.ph_country : "-"}</span>, 
                      <span> {studentData.ph_province ? studentData.ph_province : "-"}</span>, 
                      <span> {studentData.ph_zip ? studentData.ph_zip : "-"}</span> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PortalInformation;
