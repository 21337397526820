import axiosInstance from "../utils/axios";

export const getIntakeCopyList = async (values) => {
    return await axiosInstance().get(`/getIntakeCopyList`, {params: values});
};

export const getIntakeCopyListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().get(`/getIntakeCopyList`, {params: values, cancelToken: cancelToken});
};

export const getIntakeProcessDetail = async (values) => {
    return await axiosInstance().get(`/getIntakeProcessDetail`, {params: values});
};

export const GetIntakeProcessDetailCancelToken = async (values, cancelToken) => {
    return await axiosInstance().get(`/getIntakeProcessDetail`, {params: values, cancelToken: cancelToken});
};

export const getAllCopiedIntake = async (values) => {
    return await axiosInstance().get(`/getAllCopiedIntake`, {params: values});
};

export const GetAllCopiedIntakeCancelToken = async (values, cancelToken) => {
    return await axiosInstance().get(`/getAllCopiedIntake`, {params: values, cancelToken: cancelToken});
};

export const getAllCopiedQualifications = async (values) => {
    return await axiosInstance().get(`/getAllCopiedQualifications`, {params: values});
};

export const GetAllCopiedQualificationsCancelToken = async (values, cancelToken) => {
    return await axiosInstance().get(`/getAllCopiedQualifications`, {params: values, cancelToken: cancelToken});
};

export const getAllCopiedNotifications = async (values) => {
    return await axiosInstance().get(`/getAllCopiedNotifications`, {params: values});
};

export const GetAllCopiedNotificationsCancelToken = async (values, cancelToken) => {
    return await axiosInstance().get(`/getAllCopiedNotifications`, {params: values, cancelToken: cancelToken});
};

export const getAllCopiedRecordings = async (values) => {
    return await axiosInstance().get(`/getAllCopiedRecordings`, {params: values});
};

export const GetAllCopiedRecordingsCancelToken = async (values, cancelToken) => {
    return await axiosInstance().get(`/getAllCopiedRecordings`, {params: values, cancelToken: cancelToken});
};

export const getAllCopiedAssembly = async (values) => {
    return await axiosInstance().get(`/getAllCopiedAssembly`, {params: values});
};

export const GetAllCopiedAssemblyCancelToken = async (values, cancelToken) => {
    return await axiosInstance().get(`/getAllCopiedAssembly`, {params: values, cancelToken: cancelToken});
};

export const getAllCopiedAssignments = async (values) => {
    return await axiosInstance().get(`/getAllCopiedAssignments`, {params: values});
};

export const GetAllCopiedAssignmentsCancelToken = async (values, cancelToken) => {
    return await axiosInstance().get(`/getAllCopiedAssignments`, {params: values, cancelToken: cancelToken});
};

export const revertCopiedIntake = async (values) => {
    return await axiosInstance().post(`/revertCopiedIntake`, values);
};

export const copyProcess = async (values) => {
    return await axiosInstance().post(`/copyProcess`, values);
};

export const getCopyIntakeAuditTrial = async (values) => {
    return await axiosInstance().post(`/getCopyIntakeAuditTrial`, values);
};

export const GetCopyIntakeAuditTrialCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getCopyIntakeAuditTrial`, values, {
      cancelToken: cancelToken
    });
};

export const getCopyIntakeAuditTrialDropdown = async () => {
    return await axiosInstance().get(`/getCopyIntakeAuditTrialDropdown`);
};

export const GetCopyIntakeAuditTrialDropdownCancelToken = async (values,cancelToken) => {
    return await axiosInstance().get(`/getCopyIntakeAuditTrialDropdown`, { params: values, cancelToken: cancelToken });
};

