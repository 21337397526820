import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";

import { useFormik } from "formik";

import { getCurrentTimeHomePage } from "../../common/Helper";
import Tabs from "../Tabs";
import { useHistory } from "react-router";
import { AddDetail, GetListOfRole } from "../../../services/UserService";
import AddBrandSetting from "./AddBrandSetting";
import AddPortalSetting from "./AddPortalSetting";
import { Link } from "react-router-dom";

// import AboutYou from "./AboutYouNewUser";
// import LoginDetails from "./LoginDetailsNewUser";

const AddNewBrand = () => {
  const history = useHistory();
  const [brandSetting, setBrandSetting] = useState(1);
  const [portalSetting, setPortalSetting] = useState(0);
  const [isPortalEnabled, setIsPortalEnabled] = useState(false);

  // const setState = (type) => {
  //   if (type == "brand") {
  //     setBrandSetting(1);
  //     setPortalSetting(0);
  //   } else if (type == "portal") {
  //     setBrandSetting(0);
  //     setPortalSetting(1);
  //   }
  // }

  const onFinalSubmit = () => {
    setIsPortalEnabled(true);
  };

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className=" wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                <div className="d-flex align-items-center ">
                  <div className="name-w-head d-flex align-items-center ">
                  <h4 className="text-left-align landing-heading heading_color_dynamic">
                    Create <span>Brand</span>
                  </h4>
                  </div>
                  <div className="text-md-right action2-blk">
                    <div className="ticket-view-btn d-flex align-items-center">
                      <div className="add-new-ticket-bx">
                        <button onClick={()=>history.goBack()} title="Back" className="btn btn-white-bordered">
                          <i className="fal fa-angle-left"></i>Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                {/*  */}
                <div className="tabs-wrap">
                  <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li className="nav-item" onClick={() => !isPortalEnabled && history.push("/administration/brandSetting/addNewBrand")}>
                      <div className="nav-link active" id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                        <i className="fal fa-info-circle"></i>Details
                      </div>
                    </li>
                    {isPortalEnabled && <li
                      className="nav-item"
                      onClick={() => isPortalEnabled && history.push("/administration/brandSetting/addPortalSetting")}
                      title={isPortalEnabled ? "lakdjsf" : " Please submit brand Details form to use this tab"}
                    >
                      <div
                        className="nav-link "
                        aria-disabled={false}
                        id="pills-tab1"
                        data-toggle={isPortalEnabled ? "pill" : ""}
                        to={isPortalEnabled ? "/administration/brandSetting/addPortalSetting" : "/administration/brandSetting/addNewBrand"}
                        role="listitem"
                        aria-controls={isPortalEnabled && "pills-tab1"}
                        aria-selected={isPortalEnabled}
                      >
                        <i className="fal fa-books"></i>Portal Settings
                      </div>
                    </li>}
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      {brandSetting && <AddBrandSetting onFinalSubmit={onFinalSubmit} />}
                      {/* <AboutYou /> */}
                    </div>
                    <div className="tab-pane fade" id="pills-tab22" role="tabpanel" aria-labelledby="pills-tab2">
                      {/* <LoginDetails /> */}
                      {portalSetting && <AddPortalSetting />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewBrand;
