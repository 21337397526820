const initialState = {
    topicData: ""
};

const hoverTopicReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TOPIC':
            return action.payload
        default:
            return state;
    }
}

export default hoverTopicReducer;