import React, { useState, useEffect } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import AuditTrail from "../components/studentsupport/ResourceManagement/AuditTrail";
import { Link, useHistory, useParams } from "react-router-dom";
import ResourceTab from "../components/studentsupport/ResourceManagement/ResourceTab";
import LibraryTab from "../components/studentsupport/ResourceManagement/LibraryTab";
import { TrimText } from "../components/common/TrimText";

const ResourceManagement = () => {
    const { sideTab, tab, type, id, editType,resourceType } = useParams();
    const history = useHistory();
    
    const [borrowerName, setBorrowerName] = useState("")
    const [resourceName, setResourceName] = useState("")

    useEffect(()=>{
        
    },[])

    return(
    <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
            <SideBar></SideBar>
            <div className="sidebar-right">
                <div className="sub-menu-content-block">
                    {/* <Tabs /> */}
                    <div className="sub-menu-right-block">
                        <div className="wrap-box-right">
                            <div className="my-tickets-heading-blk flex-direction mb-2">
                                <div className="d-flex align-items-center ">
                                    <div className="name-w-head d-flex align-items-center ">
                                        <h4 className="text-left-align landing-heading heading_color_dynamic">
                                            {(type == "table" && tab == "resource-management") && <>Resource <span>Management</span></>  }
                                            {(type == "auditTrail" && tab == "resource-management" && (!resourceType && !editType)) && <>Resource <span>Management</span></>  }
                                            {(type == "edit" && tab == "resource-management") && <>Resource: <span>{TrimText(resourceName)}</span></> }
                                            {(type == "add" && tab == "resource-management") && <>Add <span>Resource</span></> }
                                            {(type == "table" && tab == "library-borrowers") && <>Library <span>Borrowers</span></> }
                                            {(type == "detail" && tab == "library-borrowers") && <>Borrowers: <span>{borrowerName}</span></> }
                                            {(type == "auditTrail" && tab == "resource-management" && editType == "resource") && <>Resource: <span>{TrimText(resourceName)}</span></> }
                                        </h4>
                                    </div>
                                            <div className="text-md-right action2-blk">
                                                <div className="ticket-view-btn d-flex align-items-center">
                                                    <div className="add-new-ticket-bx">
                                                        <button
                                                            onClick={() => history.goBack()}
                                                            title="Back"
                                                            className="btn btn-white-bordered"
                                                        >
                                                            <i className="fal fa-angle-left"></i>Back
                                                        </button>

                                                        {(type == "detail" && tab == "library-borrowers") && <>
                                                            <Link
                                                                to={`/studentAdministration/students/open/${id}/general`}
                                                                title="Student Profile"
                                                                
                                                            >
                                                                <button className="btn btn-primary ml-2">
                                                                Student Profile
                                                                </button>

                                                            </Link>                                                        
                                                        </> }
                                                    </div>
                                                </div>
                                            </div>
                                </div>
                            </div>

                           <div className="tabs-wrap">
                               {((tab == "resource-management" && type !== "add" && editType && editType != "issue" && editType != "missing" && editType != "found" && editType != "return" && editType != "reserve") || (tab == "resource-management" && type == "auditTrail" && (resourceType && editType))) &&  
                               <ul className="nav nav-pills mb-4" id="tabs" role="tablist">                                    
                                    {(((tab == 'resource-management' && type == 'edit') || (tab == 'resource-management' && type == 'auditTrail')) &&  (tab == 'resource-management' && resourceType != 'digital')) && 
                                    <li
                                        className="nav-item"
                                        onClick={() =>
                                            history.push(
                                                `/support/student-hub-library/resource-management/edit/${id}/dashboard/${resourceType}`
                                            )
                                        }
                                    >
                                        <Link
                                            className={`nav-link ${(tab === "resource-management" && type == "edit" && editType == "dashboard") && "active"}`}
                                            id="pills-tab002"
                                            data-toggle="pill"
                                            to={`/support/student-hub-library/resource-management/edit/${id}/dashboard/${resourceType}`}
                                            role="tab"
                                            aria-controls="pills-tab002"
                                            aria-selected="true"
                                        >
                                            <i className="fal fa-chart-pie-alt"></i>
                                            Dashboard
                                        </Link>
                                    </li>}
                                    {((tab == 'resource-management' && type == 'edit') || (tab == 'resource-management' && type == 'auditTrail') )&& 
                                    <li className="nav-item" onClick={() =>history.push(`/support/student-hub-library/resource-management/edit/${id}/details/${resourceType}`)}>
                                        <Link
                                            className={`nav-link ${(tab === "resource-management" && type == "edit" && editType == "details") && "active"}`}
                                            id="pills-tab002"
                                            data-toggle="pill"
                                            to={`/support/student-hub-library/resource-management/edit/${id}/details/${resourceType}`}
                                            role="tab"
                                            aria-controls="pills-tab002"
                                            aria-selected="true"
                                        >
                                            <i className="fal fa-info-circle"></i>
                                            Details
                                        </Link>
                                    </li>}
                                   
                                    <li
                                        className="nav-item"
                                        onClick={() =>
                                            history.push(`/support/student-hub-library/resource-management/auditTrail/${id}/resource/${resourceType}`)
                                        }
                                    >
                                        <Link
                                            className={`nav-link ${type === "auditTrail" && "active"}`}
                                            id="pills-tab60"
                                            data-toggle="pill"
                                            to={`/support/student-hub-library/resource-management/auditTrail/${id}/resource/${resourceType}`}
                                            role="tab"
                                            aria-controls="pills-tab60"
                                            aria-selected="true"
                                        >
                                            <i className="fal fa-history"></i>Audit Trail
                                        </Link>
                                    </li>
                                </ul>}

                                {(tab=="resource-management" && (!resourceType && !editType) && (type == "table" || type == "auditTrail")) && 
                                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                        <li className="nav-item" onClick={() =>
                                            history.push(`/support/student-hub-library/resource-management/table`)
                                        }>
                                            <Link 
                                            className={"nav-link " + (type=="table" && "active")}
                                            id="pills-tab160"
                                            data-toggle="pill"
                                            to={`/support/student-hub-library/resource-management/table`}
                                            role="tab"
                                            aria-controls="pills-tab160"
                                            aria-selected="true"
                                            >
                                            <i className="fal fa-books"></i>Resource Management
                                            </Link>
                                        </li>
                                        <li className="nav-item" onClick={() =>
                                            history.push(`/support/student-hub-library/resource-management/auditTrail`)
                                        }>
                                            <Link 
                                            className={"nav-link " + (type=="auditTrail" && "active")}
                                            id="pills-tab260"
                                            data-toggle="pill"
                                            to={`/support/student-hub-library/resource-management/auditTrail`}
                                            role="tab"
                                            aria-controls="pills-tab260"
                                            aria-selected="true"
                                            >
                                            <i className="fal fa-history"></i>Audit Trail
                                            </Link>
                                        </li>
                                    </ul>
                                }
                                <div className="tab-content" id="pills-tabContent">
                                    <div
                                        className="tab-pane fade active show"
                                        id="pills-tab11"
                                        role="tabpanel"
                                        aria-labelledby="pills-tab1"
                                    >
                                        {tab === "resource-management" && <ResourceTab setResourceName={setResourceName} />}
                                        {tab === "library-borrowers" && <LibraryTab setBorrowerName={setBorrowerName} />}
                                        {type === "auditTrail" && <AuditTrail setResourceName={setResourceName}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
};
export default ResourceManagement;