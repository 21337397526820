import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";

import { useSelector } from "react-redux";
import { getCurrentTimeHomePage } from "../../common/Helper";
import Tabs from "../Tabs";
import { Link, useHistory, useParams } from "react-router-dom";
import { GetUserDetail } from "../../../services/UserService";

import AboutYou from "./AboutYouEditUser";
import LoginDetails from "./LoginDetailsEditUser";
import UserAuditTrail from "./UserAuditTrail";

const EditUser = () => {
  const history = useHistory();
  const { id, subTab } = useParams();

  const [userData, setUserData] = useState();

  // TODO API

  useEffect(() => {
    GetUserDetail({ UserID: id })
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div class="d-flex align-items-center ">
                    <div class="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        Profile: <span>{userData?.Firstname + " " + userData?.Lastname}</span>
                      </h4>
                    </div>
                    <div class="text-md-right action2-blk">
                      <div class="ticket-view-btn d-flex align-items-center">
                        <div class="add-new-ticket-bx">
                          <button onClick={() => history.goBack()} title="Back" class="btn btn-white-bordered">
                            <i class="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tabs-wrap">
                  <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li className="nav-item" onClick={() => history.push(`/systemAdministration/userManagement/open/${id}/aboutYou`)}>
                      <Link
                        className={`nav-link ${subTab === "aboutYou" && "active"}`}
                        id="pills-tab1"
                        data-toggle="pill"
                        to={`/systemAdministration/userManagement/open/${id}/aboutYou`}
                        role="tab"
                        aria-controls="pills-tab1"
                        aria-selected="true"
                      >
                        <i className="fal fa-info-circle"></i>About You
                      </Link>
                    </li>
                    <li className="nav-item" onClick={() => history.push(`/systemAdministration/userManagement/open/${id}/loginDetails`)}>
                      <Link
                        className={`nav-link ${subTab === "loginDetails" && "active"}`}
                        id="pills-tab2"
                        data-toggle="pill"
                        to={`/systemAdministration/userManagement/open/${id}/loginDetails`}
                        role="tab"
                        aria-controls="pills-tab2"
                        aria-selected="false"
                      >
                        <i className="fal fa-user"></i>Login Details
                      </Link>
                    </li>
                    <li className="nav-item" onClick={() => history.push(`/systemAdministration/userManagement/open/${id}/auditTrail`)}>
                      <Link
                        className={`nav-link ${subTab === "auditTrail" && "active"}`}
                        id="pills-tab3"
                        data-toggle="pill"
                        to={`/systemAdministration/userManagement/open/${id}/auditTrail`}
                        role="tab"
                        aria-controls="pills-tab3"
                        aria-selected="false"
                      >
                        <i className="fal fa-history"></i>Audit Trail
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      {subTab === "aboutYou" && <AboutYou />}
                    </div>
                    <div className="tab-pane fade active show" id="pills-tab22" role="tabpanel" aria-labelledby="pills-tab2">
                      {subTab === "loginDetails" && <LoginDetails />}
                    </div>
                    <div className="tab-pane fade active show" id="pills-tab33" role="tabpanel" aria-labelledby="pills-tab2">
                      {subTab === "auditTrail" && <UserAuditTrail />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
