const initialState={
    exam_name : ""
}

const examNameReducer = (state=initialState, action) => {
    switch(action.type){
        case "SET_EXAM_NAME" :
            state.exam_name = action.payload;
            return {exam_name : state.exam_name}
        default :
            return state;
    }
}
export default examNameReducer;