import React, { useMemo, useState } from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import * as Yup from 'yup';

import DataTable from 'react-data-table-component'
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import Str from '../../../common/Str';
import { getResourceManagement } from '../../../../services/StudentService';
import { useEffect } from 'react';
import Select from 'react-select';
import { Form, FormikProvider, useFormik } from 'formik';
import { IMAGE_URL } from '../../../../utils/Constants';

export default function DocumentLibrary({ show, handleClose, handleSelect,selectedItem }) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);

    const getData = () => {
        const sendData = {
            exportStatus: true,
            viaFormat: ["pdf"]
        }
        getResourceManagement(sendData).then((res) => {
            if (res.status === 200) {
                const formatResp = res.data.result.filter((item) => (item.title !== null && (item.link !== null))).map((item) => {
                    return { label: item.title, value: item.link }
                })
                setData(formatResp);
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        getData()
    }, [])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            item_value: selectedItem.value,
            item_label: selectedItem.label,
            item: selectedItem
        },
        validationSchema: Yup.object().shape({
            item_value: Yup.string().required("Please select an resource.")
        }),
        onSubmit: (values) => {
            handleSelect(values);
        }
    });

    const { values, errors, touched, setValues, handleSubmit } = formik;

    const handleChangeItem = (item) => {
        setValues({
            ...values,
            item: item,
            item_label: item.label,
            item_value: item.value
        })
    }

    return (
        <Modal className='modal zindexadd content-assembly fade' size='md' show={show} centered onHide={handleClose}>
            <Modal.Header className='modal-header-custom' closeButton>
                <h5 class="modal-title"><i class="fal fa-plus"></i> Document Library</h5>
            </Modal.Header>
            <ModalBody className='p-0'>
                <div className="t p-30px">
                    <FormikProvider value={formik}>
                        <Form autocomplete="off" noValidate onSubmit={handleSubmit}>
                            <div className="form-group-blk mb-3">
                                <Select
                                    className={
                                        "form-control custom-select-box" +
                                        (errors.item_value &&
                                            touched.item_value
                                            ? " is-invalid"
                                            : "")
                                    }
                                    name="item_value"
                                    id="upload_item"
                                    title="Select Document"
                                    placeholder={"Select Document"}
                                    options={data}
                                    onChange={(value) => {
                                        handleChangeItem(value)
                                    }}
                                    value={values.item}
                                />
                            </div>
                            <div className="form-group form-group-save-cancel mt-4">
                                <button className="btn btn-save btn-success" type="submit" title="Save">
                                    <i className="fal fa-save"></i>
                                    Save
                                </button>

                                <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => handleClose()}>
                                    <i className="fal fa-times"></i>Cancel
                                </button>
                            </div>
                        </Form>
                    </FormikProvider>
                </div>
                {/* <div className="custom-table-div card-table-custom">
                    <DataTable
                        data={data}
                        columns={columns}
                        pagination={false}
                        progressComponent={<SkeletonTicketList />}
                        progressPending={loading}
                        noDataComponent={Str.noRecord}
                        defaultSortAsc={true}
                        defaultSortField={"name"}
                    />

                </div> */}
            </ModalBody>
        </Modal>
    )
}
