import React, { useState, useMemo, useEffect } from "react";
import { InitialRender } from "../../../common/Helper";
import { Link, useHistory, useParams } from "react-router-dom";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import StatusRender, {
  ColorRender,
} from "../../../systemadministration/usermanagement/CheckRole";
import {
  AddSelectClass,
  GetList,
  GetSelectClass,
  GetSelectClassFilter,
} from "../../../../services/CourseService";
import { GetListOfDashboard, GetVritualClassDropdown } from "../../../../services/DashBoardServices";
import { IMAGE_URL, TABLE_DATE_FORMAT } from "../../../../utils/Constants";
import moment from "moment";
import Swal from "sweetalert2";
import Hover from "../../../common/Hover";
import { RenderLearningMethod } from "../../../../utils/CommonGroupingItem";
import { RenderClassStatus, RenderCourseStatus } from "../../../../utils/CommonStatusItems";
import DataTableComponent from "../../../common/DataTableComponent";


const SelectClass = () => {
  const history = useHistory();
  const { id } = useParams();
  const [saveDisbale, setSaveDisbale] = useState(false);
  
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sortKey, setSortKey] = useState("date");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "date",
    sortOrder: "ASC",
  });
  const [courses, setCourses] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [course, setCourse] = useState({ arr: [], checkObj: [] });
  const [programme, setProgramme] = useState({ arr: [], checkObj: [] });
  const [lecturer, setLecturer] = useState({ arr: [], checkObj: [] });
  
  const [selectClassId, setSelectClassId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterData, setFilterData] = useState({course : [], intake : [], learning : [], status : [], lecturer : [], programme : []})

  useEffect(() =>{
    setLoading(true)
    const data = {
      search: search,
      pageNo: tableState.page,
      limit: tableState.perPage,
      sort: tableState.sortOrder,
      key: tableState.sortKey,
      viaDate:  JSON.stringify(["Upcoming"]),
      viaCourse : course.arr.length > 0 ? JSON.stringify(course.arr) : [],
      intake: intake.arr.length > 0 ? JSON.stringify(intake.arr) : [],
      viaStatus: status.arr.length > 0 ? JSON.stringify(status.arr) : [],      
      viaLecturer: lecturer.arr.length > 0 ? JSON.stringify(lecturer.arr) : [],
      viaStudyType: learningMethod.arr.length > 0 ? JSON.stringify(learningMethod.arr) : [],
      showing:  [-1]
    }
    GetListOfDashboard(data).then((res)=>{
      setCourses(res?.data?.result1);
      setTotalRows(res?.data?.Pagination?.total)
      setLoading(false)
    }).catch((err)=>{console.log(err);setLoading(false)})
  },[
    tableState,
    search,
    course,
    intake,
    learningMethod,
    status,
    lecturer
  ])

  const resetFilter = () =>{
    setCourse({ arr: [], checkObj: {} });
    setIntake({ arr: [], checkObj: {} });
    setStatus({ arr: [], checkObj: {} });
    setLecturer({ arr: [], checkObj: {} });
    setSearch("");
  }
  useEffect(() => {    
    const data = {
      viaDate:  ["Upcoming"],      
    }
    GetVritualClassDropdown(data)
      .then((res) => {
        const resp = res.data;        
        setFilterData({...filterData, course : resp.course, intake : resp.intakes, learning : resp.studyType, status : resp.statusType, lecturer : resp.lectures})
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = useMemo(() => [
    {
      name: "Title",
      sortField: "title",
      selector: "title",
      sortable: true,
      cell: (row) => (
        <span
          className="as-text-blue curser feature-name"
          title={row.class_title + " " + row.class_id}
        >
          <span className="textLimit100">
            {row.class_title}
          </span>
          <br />
          <span className="textLimit100">
            {row.class_id}
          </span>
        </span>
      ),
    },
    {
      name: "Course",
      sortField: "coures",
      selector: "coures",
      sortable: true,
      cell: (row) =>
        row.courseName ? (
          <Link
            title={row.courseName}
            to={`/courseAdministration/coursesdetails/${row.intakeID}/dashboard/show`}
            className="as-text-blue curser feature-name"
          >
            <span className="textLimit100">{row.courseName}</span>
          </Link>
        ) : (
          "-"
        ),
    },    
    {
      name: "Status",
      selector: "status",
      sortable: false,
      cell: (row) =>
        row.status ? RenderClassStatus(row.status).html : "-",
    },
    {
      name: "Intake",
      selector: "Intake",
      sortable: true,
      sortField: "Intake",
      cell: (row) => (
        <p>
          {row.intakeNumber || row.intakeYear ? (
            <>
              {row.intakeYear ? row["intakeYear"] : ""}-{row.intakeNumber ? row.intakeNumber : ""}
            </>
          ) : (
            "-"
          )}
        </p>
      ),
    },
    {
      name: "Lecturer",
      selector: "lecture",
      sortable: true,
      sortField: "lecture",
      cell: (row) => {
        let lecImage = row.lecturer && row.lecturer.picture_me;

        return (
          <>
            {row.lecture_name ? (
              <div className="assigned-title-block-new">
                <div className="assigned-title-itm">
                  <span
                    className={
                      "assigned-title-blk  name-icon cat-dark-red " +
                      ColorRender("lecturer")
                    }
                  >
                    {row?.lecturer?.picture_me ? (
                      <>
                        <img
                          src={`${IMAGE_URL}/${lecImage.replaceAll(
                            "/home/myaie/public_html/",
                            ""
                          )}`}
                          alt="AIE"
                        />
                      </>
                    ) : (
                      InitialRender([
                        row.lecture_name.split(" ")[0],
                        row.lecture_name.replace(/ +/g, " ").split(" ")[1],
                      ])
                    )}
                    <span className={`profile-box-2-status ${row.lecturer.Status ? row.lecturer.Status.toLowerCase() == "online" ? "Online" : row.lecturer.Status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                      <i className="fas fa-circle"></i>
                    </span>
                    <Hover
                      firstName={row.lecture_name.split(" ")[0]}
                      lastName={
                        row.lecture_name.replace(/ +/g, " ").split(" ")[1]
                      }
                      photo={row?.lecturer?.picture_me}
                      email={row?.lecturer?.Email}
                      mobile={row?.lecturer?.Mobile}
                      right={true}
                      role={"lecturer"}
                      status={row.lecturer.is_active}
                      activity_status={row.lecturer.Status}
                    />
                  </span>
                  <p
                    className="as-text-blue curser "
                    onClick={() =>
                      history.push(
                        `/systemAdministration/userManagement/open/${row.lect_id}/aboutYou`
                      )
                    }
                  >
                    {row.lecture_name ? (
                      <span className="textLimit76" title={row.lecture_name}>
                        {row["lecture_name"]}
                      </span>
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
              </div>
            ) : (
              "-"
            )}

          </>
        );
      },
    },
    {
      name: "Learning Method",
      selector: "studyType",
      sortField: "studyType",
      sortable: true,
      cell: (row) => row.studyType ? RenderLearningMethod(row.studyType).html : "-",
    },
    {
      name: "Date & Time",
      selector: "date",
      sortField: "date",
      sortable: true,
      cell: (row) => (
        <span className="feature-name" title={row.class_date + "," + row.class_from + "-" + row.class_to}>
          <span className="textLimit100">{moment(row.class_date).format(TABLE_DATE_FORMAT)}</span>
          <span className="textLimit100">{row.class_from + "-" + row.class_to}</span>
        </span>
      ),
    },
    
  ]);
  const handleChange = (selectedRow) => {
    let arr = [];

    const id = selectedRow.selectedRows.map((item) => {
      arr.push(item.id);
    });
    setSelectClassId(arr);
  };
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };
  
  const handleClassList = () => {
    setSaveDisbale(true);
    const data = {
      intake_id: id,
      class_id: selectClassId,
    };
    AddSelectClass(data)
      .then((res) => {
        setSaveDisbale(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Added successfully",
        });
        history.push(
          `/courseAdministration/coursesdetails/${id}/onlineClasses/show`
        );
      })
      .catch((err) => {
        console.log(err);
        setSaveDisbale(false);
      });
  };

  const exportData = (fileType, fileName) => {
    const header = [
      "Title",
      "Course",
      "Status",
      "Intake",
      "Lecturer",
      "Learning Method",
      "Date & Time",
    ];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });


    const apiData = {
      search: search,
      pageNo: tableState.page,
      limit: tableState.perPage,
      sort: tableState.sortOrder,
      key: tableState.sortKey,
      viaDate:  JSON.stringify(["Upcoming"]),
      viaCourse : course.length > 0 ? JSON.stringify(course.arr) : [],
      intake: intake.arr.length > 0 ? JSON.stringify(intake.arr) : [],
      viaStatus: status.arr.length > 0 ? JSON.stringify(status.arr) : [],      
      viaLecturer: lecturer.arr.length > 0 ? JSON.stringify(lecturer.arr) : [],
      showing:  [-1],
      exportStaus: "true"

    }
    GetListOfDashboard(apiData).then(res => {
      let data = res.data.result1;

      data = data?.map((row) => ({
        ...row,
        Title: row?.class_title,
        Course: row?.courseName ? row?.courseName : "-",        
        Status: row.status ? RenderClassStatus(row.status).text : "-",
        Intake: row.intakeNumber && row.intakeYear ? row.intakeYear + "-" + row.intakeNumber : "-",
        Lecturer: row.lecture_name ? row.lecture_name : "-",
        "Learning Method": row.studyType ? RenderLearningMethod(row.studyType).text : "-",
        "Date & Time": row.class_date && row.class_from && row.class_to
            ? moment(row.class_date).format(TABLE_DATE_FORMAT) + " " + row.class_from + "-" +
            row.class_to
            : "-",
      }));

      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
        anchor.click();

        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();

      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [
            row["Title"],
            row["Course"],
            // row["Programmes"],
            row["Status"],
            row["Intake"],
            row["Lecturer"],
            row["Learning Method"],
            row["Date & Time"],
          ];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
    }).catch((err)=>{console.log(err);Swal.close()})
  };

  return (
    <DataTableComponent
      data={courses}
      loading={loading}
      state={tableState}
      setState={setTableState}
      setSearch={setSearch}
      totalRows={totalRows}
      columns={columns}
      exportFunction={exportData}
      exportFileName={"Select_Class"}
      resetFilter={resetFilter}
      search={search}
      defaultSort={{ defaultSortColumn: "class_date", defaultSortAsc: false }}
      filters={[
        {
          filterName: "Course",
          optionArr: filterData.course,
          state: course,
          setState: setCourse,
          isSearchFilter: true
        },
        {
          filterName: "Intake",
          optionArr: filterData.intake,
          state: intake,
          setState: setIntake,
          isOptionReversed: true,
          isAllIntakeAllowed: true
        },
        {
          filterName: "Learning Method",
          optionArr: filterData.learning,
          state: learningMethod,
          setState: setLearningMethod,
          renderLabelFunction: RenderLearningMethod
        },
        {
          filterName: "Status",
          optionArr: filterData.status,
          state: status,
          setState: setStatus,
          renderLabelFunction: RenderClassStatus
        },
        {
          filterName: "Lecturer",
          optionArr: filterData.lecturer,
          state: lecturer,
          setState: setLecturer,
          isSearchFilter: true
        },
        // {
        //   filterName: "Programme",
        //   optionArr: filterData.programme,
        //   state: programme,
        //   setState: setProgramme,
        //   isSearchFilter: true
        // }
      ]}
      bottomButton={[
        <div className="form-group form-group-save-cancel">
          <button
            className="btn btn-save btn-success"
            type="button"
            disabled={saveDisbale || !selectClassId.length}
            onClick={() => handleClassList()}
            title="Save"
          >
            {saveDisbale ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fal fa-save"></i>
            )}
            Save
          </button>
          <button
            className="btn btn-close btn-danger"
            type="button"
            onClick={() => {
              handleClearRows();
              history.goBack();
            }}
            title="Cancel"
          >
            <i className="fal fa-times"></i>Cancel
          </button>
        </div>
      ]}
      otherTableProps={
        {
          selectableRows: true,
          onSelectedRowsChange: handleChange ,
          clearSelectedRows: toggledClearRows
        }
      }
    />
  );
};

export default SelectClass;
