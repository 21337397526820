import React from 'react'
import { Link } from 'react-router-dom'
import Hover from './Hover'
import { checkIfImageExists } from '../../utils/commonFunction'
import { IMAGE_URL } from '../../utils/Constants'
import { InitialRender } from './Helper'

function UserColumn({ imgUrl = "", firstName = "NA", lastName = "", userActiveStatus = "Offline", email = "", mobile = "", isPopUpRight, role = "default", userId = 0, isUserNameHidden = false, multipleUserStacks, isShowingAllUser = [], setIsShowingAllUser, usersArray = [], rowId, usersArrayData = {} }) {
    // isUserNameHidden :: this key is for hiding user name when there are more then one user in table column

    if (multipleUserStacks) {

        const handleShowingUser = (isShowing, id) => {
            let tempArr = isShowingAllUser;
            if (isShowing) {
                tempArr.splice(tempArr.indexOf(id), 1)
            } else {
                tempArr.push(id)
            }
            setIsShowingAllUser([...tempArr])
        }

        return isShowingAllUser.includes(rowId) ? <div>
            <div className="assigned-profile-tit">
                {usersArray.length ? usersArray.map((data, index) => {
                    return <UserColumn
                        firstName={data[usersArrayData.firstName]}
                        lastName={data[usersArrayData.lastName]}
                        imgUrl={data[usersArrayData.imgUrl]}
                        email={data[usersArrayData.email]}
                        mobile={data[usersArrayData.mobile]}
                        userId={data[usersArrayData.userId]}
                        userActiveStatus={data[usersArrayData.userActiveStatus]}
                        role={data[usersArrayData.role] || "default"}
                        isPopUpRight={data[usersArrayData.right]}
                        key={index}
                        isUserNameHidden={!(isShowingAllUser.includes(rowId) || usersArray.length == 1)}
                    />
                }) : "-"}</div>
            {usersArray.length && usersArray.length > 1 ? <div
                className="table-expand-btn"
                onClick={() => handleShowingUser(true, rowId)}>
                See Less
            </div> : ""}
        </div> : <div className="assigned-profile-overlay-out">
            <div className="assigned-profile-overlay">
                {usersArray.length ? usersArray.map((data, index) => {
                    return index < 3 ? <UserColumn
                        firstName={data[usersArrayData.firstName]}
                        lastName={data[usersArrayData.lastName]}
                        imgUrl={data[usersArrayData.imgUrl]}
                        email={data[usersArrayData.email]}
                        mobile={data[usersArrayData.mobile]}
                        userId={data[usersArrayData.userId]}
                        userActiveStatus={data[usersArrayData.userActiveStatus]}
                        role={data[usersArrayData.role] || "default"}
                        isPopUpRight={data[usersArrayData.right]}
                        key={index}
                        isUserNameHidden={!(isShowingAllUser.includes(rowId) || usersArray.length == 1)}
                    /> : ""
                }) : "-"}</div>
            {usersArray.length && usersArray.length > 1 ? <div
                className="table-expand-btn"
                onClick={() => handleShowingUser(false, rowId)}>
                See More
            </div> : ""}
        </div>
    }

    return (
        <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
                <span className="assigned-title-blk name-icon">
                    {imgUrl &&
                        checkIfImageExists(
                            `${IMAGE_URL}/${imgUrl.replace(
                                "home/myaie/public_html/",
                                ""
                            ).replace("public/", "")}`
                        ) ? (
                        <img
                            src={`${IMAGE_URL}/${imgUrl.replaceAll(
                                "/home/myaie/public_html/",
                                ""
                            ).replace("public/", "")}`}
                            alt="AIE"
                        />
                    ) : (
                        InitialRender(firstName && lastName ? [
                            firstName,
                            lastName,
                        ] : null)
                    )}
                    <span
                        className={`profile-box-2-status ${userActiveStatus
                            ? userActiveStatus
                            : "Offline"
                            }`}
                    >
                        <i className="fas fa-circle"></i>
                    </span>
                    <Hover
                        firstName={firstName}
                        lastName={lastName}
                        photo={imgUrl}
                        email={email}
                        mobile={mobile}
                        right={isPopUpRight}
                        activity_status={userActiveStatus}
                        role={role}
                        wholeUi={false}
                    />
                </span>
                {isUserNameHidden ? "" : <Link
                    className='as-text-blue curser'
                    to={userId ? `/systemAdministration/userManagement/open/${userId}/aboutYou` : "/systemAdministration/userManagement/table"}
                >
                    {firstName} {lastName && lastName !== "NA" ? lastName : ""}
                </Link>}
            </div>
        </div>
    )
}

export default UserColumn