/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoginService } from "../services/LoginService";
import { setUserSession, setUserSettings } from "../utils/Auth";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import { useDispatch } from "react-redux";
import { setUser, setWidget, setTab, setImage } from "../store/actions/index";
import { MainContext } from "../contexts/MainContext";

const LoginForm = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { setState } = useContext(MainContext);
  let history = useHistory();
  const formik = useFormik({
    initialValues: {
      Email: "",
      Password: "",
      keepMe: false,
    },
    validationSchema: Yup.object({
      Email: Yup.string().email().required("Email is required"),
      Password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      LoginService(values)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
            timer: 3000,
            onOpen: function () {
              Swal.showLoading();
            },
          }).then(function (result) {
            if (result.dismiss === "timer") {
              let token = res.data.token;
              let user = res.data.user;
              localStorage.setItem("widgetStatus", 1);
              dispatch(setUser(user));
              if (res.data.picture_me !== null) {
                dispatch(setImage(res.data.picture_me));
                setState(res.data.picture_me);
              } else {
                dispatch(setImage(""));
              }
              dispatch(setWidget({ status: 1 }));
              setUserSession(token, user);
              document.cookie = `api_token=${token}; Domain=.myaie.ac;`
              document.cookie = `phone_user=${JSON.stringify(user)}; Domain=.myaie.ac;`
              setUserSettings(user.settings);
              dispatch(setTab()); //side bar tab value set default to 1

              setLoading(false);
              if (user.status == "On Hold" || user.status == "In Active") {
                history.push("/status");
              } else {
                history.push("/home", { widgetLoad: true });
              }
            }
          });
        })
        .catch(function (err) {
          Swal.fire({
            icon: "error",
            title: "Login Failed",
            text: err.response.data.message,
          });
          setLoading(false);
          return false;
        });
    },
  });

  return (
    <div className="card-body-inr">
      <div className="portal-login-info">
        {/* <h1>COMMUNICATOR</h1> */}
        {/* <h3>Sign In</h3> */}
        <p>Sign in with your registered <br /> admin account
        </p>
      </div>
      <div className="portal-login-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="">
                <i className="fal fa-user"></i>
              </span>
            </div>
            <input
              type="text"
              className={"form-control" + (formik.errors.Email && formik.touched.Email ? " is-invalid" : "")}
              placeholder="Email"
              name="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.Email}
            />
          </div>

          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="">
                <i className="fal fa-lock"></i>
              </span>
            </div>
            <input
              type="password"
              className={"form-control" + (formik.errors.Password && formik.touched.Password ? " is-invalid" : "")}
              placeholder="Password"
              name="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.Password}
            />
          </div>

          <div className="custom-control custom-checkbox text-left">
            <input type="checkbox" className="custom-control-input" id="customCheck1" name="keepMe" onChange={formik.handleChange} onBlur={formik.handleBlur} />
            <label className="custom-control-label" htmlFor="customCheck1">
              Keep me signed in
            </label>
          </div>

          <button className="btn btn-white-bordered btn-submit-info btn-primary" type="submit" disabled={loading} title="Sign In">
            {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-sign-in-alt"></i>} Sign In
          </button>

          {formik.touched.Email && formik.errors.Email ? <div className="invalid-feedback d-block">{formik.errors.Email}</div> : null}
          {formik.touched.Password && formik.errors.Password ? <div className="invalid-feedback d-block">{formik.errors.Password}</div> : null}
        </form>
        <small>
          By signing in, you agree that you have read the{" "}
          <a href="https://aie.ac/pgs-189-student-privacy-policy.html" target="_blank">
            Privacy Policy
          </a>
          .
        </small>
      </div>
    </div>
  );
};
export default LoginForm;