import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import CentreTab from "../components/StudentAdministration/ExternalExam/ExamCenters/CenterTab";
import DetailsTab from "../components/StudentAdministration/ExternalExam/Details/DetailsTab";
import ExamSettingTab from "../components/StudentAdministration/ExternalExam/ExamSetting/ExamSettingTab";
import { GlobalIconNames } from "../utils/GlobalIconNames";
import { useSelector } from "react-redux";
import ExamPeriodTab from "../components/StudentAdministration/ExternalExam/ExamPeriod/ExamPeriodTab";
import axios from "axios";
import { GeteditExamData } from "../services/ExternalExamServices";

const ExternalExamReg = () => {
  const history = useHistory();
  const { tab, type, id, subTab, subId} = useParams();
  const examCenterName = useSelector((state)=>state.examCenterReducer.exam_center_name);
  const examPeriod = useSelector((state)=>state.examPeriodReducer.exam_period_name);

  const [examName, setExamName] = useState("")


  useEffect(() => {
    if (subId || id) {
      const source = axios.CancelToken.source();
      const getDetails = async () =>{
        try {
          const res = await GeteditExamData(subId ?? id, source.token);
          if(res.status==200){
            setExamName(res.data?.data?.period)
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
        }
      }
      getDetails();      
      return () =>{
        source.cancel('Component unmounted');
      }
    }
  }, [subId, id]);

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        {tab=="details" ? ((type=="table" || type =="audit-trail") ? "Exams" : (type=="add" ? "Add " : "Exam: ")) : tab=="examCentres" ? (type=="table" || type =="audit-trail") ? "Exam " : type=="add" ? "Add " : "Exam Centre: " 
                        : (tab=="examSettings" && (type=="add" || type=="audit-trail")) ? "Exam ":tab=="examPeriod" ? (type=="table" || type=="audit-trail") ? "Exam " : type=="add" ? "Add " : "Exam Period: " : ""}
                        
                        {tab=="details" ? type=="add" ? <span>Exam</span> : (type=="open" ? <span>{examName}</span> : "") : tab=="examCentres" ? (type=="table" || type == "audit-trail") ? <span>Centres</span> : type=="add" ? 
                        <span>Exam Centre</span> : <span>{examCenterName}</span> : tab=="examSettings" && (type=="add" || type=="audit-trail") ? <span>Settings</span> : tab=="examPeriod" ? (type=="table" || type=="audit-trail") ?
                         <span>Period</span> : type=="add" ? <span>Exam Period</span> : <span>{examPeriod}</span> :""} 
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <button
                        onClick={() => {history.goBack()}}
                        title="Back"
                        className="btn btn-white-bordered"
                      >
                        <i className="fal fa-angle-left"></i>Back
                      </button>                                                  
                    </div>
                  </div>
                </div>

                <div className="tabs-wrap">
                  {(tab !== "details" && (type!="edit" && type!="add")) ||
                  (tab == "details" && (type == "table" || type == "audit-trail")) || tab=="examSettings" ? (
                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                      {(tab == "details" && (type == "table" || type == "audit-trail")) && <>
                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(
                            "/studentAdministration/externalExam/details/table"
                          )
                        }
                      >
                        <Link
                          className={`nav-link ${
                            (tab === "details" && type=="table") ? "active" : ""
                          }`}
                          id="pills-tab1"
                          data-toggle="pill"
                          to={
                            "/studentAdministration/externalExam/details/table"
                          }
                          href="#pills-tab11"
                          role="tab"
                          aria-controls="pills-tab11"
                          aria-selected="true"
                        >
                          {GlobalIconNames("exam").html}
                        </Link>
                      </li>
                      <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          `/studentAdministration/externalExam/details/audit-trail` 
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${
                          (tab=== "details" && type === "audit-trail") ? "active" : ""
                        }`}
                        id="pills-tab6"
                        data-toggle="pill"
                        to={
                        `/studentAdministration/externalExam/details/audit-trail` 
                        }
                        href="#pills-tab16"
                        role="tab"
                        aria-controls="pills-tab16"
                        aria-selected="true"
                      >
                        {GlobalIconNames("audit-trail").html}
                      </Link>
                      </li>
                    </>}
                      {(tab == "examSettings" && (type == "add" || type == "audit-trail")) &&  <>
                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(
                            "/studentAdministration/externalExam/examSettings/add"
                          )
                        }
                      >
                        <Link
                          className={`nav-link ${
                            (tab === "examSettings" && type==="add") ? "active" : ""
                          }`}
                          id="pills-tab2"
                          data-toggle="pill1"
                          to={
                            "/studentAdministration/externalExam/examSettings/add"
                          }
                          href="#pills-tab12"
                          role="tab"
                          aria-controls="pills-tab12"
                          aria-selected="false"
                        >
                          {GlobalIconNames("examsetting").html}
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(
                            "/studentAdministration/externalExam/examSettings/audit-trail"
                          )
                        }
                      >
                        <Link
                          className={`nav-link ${
                            (tab === "examSettings" && type==="audit-trail") ? "active" : ""
                          }`}
                          id="pills-tab2"
                          data-toggle="pill1"
                          to={
                            "/studentAdministration/externalExam/examSettings/audit-trail"
                          }
                          href="#pills-tab12"
                          role="tab"
                          aria-controls="pills-tab12"
                          aria-selected="false"
                        >
                          {GlobalIconNames("audit-trail").html}
                        </Link>
                      </li>
                      </>}
                      {(tab == "examCentres" && (type == "table" || type== "audit-trail")) && <>
                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(
                            "/studentAdministration/externalExam/examCentres/table"
                          )
                        }
                      >
                        <Link
                          className={`nav-link ${
                            (tab === "examCentres" && type === 'table') ? "active" : ""
                          }`}
                          id="pills-tab3"
                          data-toggle="pill2"
                          to={
                            "/studentAdministration/externalExam/examCentres/table"
                          }
                          href="#pills-tab13"
                          role="tab"
                          aria-controls="pills-tab13"
                          aria-selected="false"
                        >
                          {GlobalIconNames("examcentre").html}
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(
                            "/studentAdministration/externalExam/examCentres/audit-trail"
                          )
                        }
                      >
                        <Link
                          className={`nav-link ${
                            (tab === "examCentres" && type === "audit-trail") ? "active" : ""
                          }`}
                          id="pills-tab3"
                          data-toggle="pill2"
                          to={
                            "/studentAdministration/externalExam/examCentres/audit-trail"
                          }
                          href="#pills-tab13"
                          role="tab"
                          aria-controls="pills-tab13"
                          aria-selected="false"
                        >
                          {GlobalIconNames("audit-trail").html}
                        </Link>
                      </li>
                      </>}                      
                      
                      {(tab == "examPeriod" && type == "open") && <>
                      <li
                        className="nav-item"
                        onClick={() => history.push(`/studentAdministration/externalExam/examPeriod/open/${subTab}`)}
                      >
                        <Link
                          className={`nav-link ${
                            (tab === "examPeriod" && id !== "audit-trail") ? "active" : ""
                          }`}
                          id="pills-tab5"
                          data-toggle="pill"
                          to={`/studentAdministration/externalExam/examPeriod/open/${subTab}`}
                          href="#pills-tab15"
                          role="tab"
                          aria-controls="pills-tab15"
                          aria-selected="true"
                        >
                          {GlobalIconNames("examperiod").html}
                        </Link>
                      </li>
                      <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          `/studentAdministration/externalExam/examPeriod/open/${subTab}/audit-trail` 
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${
                          (type === "audit-trail" || id=="audit-trail") ? "active" : ""
                        }`}
                        id="pills-tab6"
                        data-toggle="pill"
                        to={
                        `/studentAdministration/externalExam/examPeriod/audit-trail` 
                        }
                        href="#pills-tab16"
                        role="tab"
                        aria-controls="pills-tab16"
                        aria-selected="true"
                      >
                        {GlobalIconNames("audit-trail").html}
                      </Link>
                    </li>
                    </>}
                      {(tab == "examPeriod" && ((type == "table" || type=="audit-trail"))) && <>
                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(
                            `/studentAdministration/externalExam/examPeriod/table`
                          )
                        }
                      >
                        <Link
                          className={`nav-link ${
                            type === "table" ? "active" : ""
                          }`}
                          id="pills-tab6"
                          data-toggle="pill"
                          to={
                            `/studentAdministration/externalExam/examPeriod/table`
                          }
                          href="#pills-tab16"
                          role="tab"
                          aria-controls="pills-tab16"
                          aria-selected="true"
                        >
                          {GlobalIconNames("examperiod").html}
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(
                            `/studentAdministration/externalExam/examPeriod/audit-trail` 
                          )
                        }
                      >
                        <Link
                          className={`nav-link ${
                            type === "audit-trail" ? "active" : ""
                          }`}
                          id="pills-tab6"
                          data-toggle="pill"
                          to={
                          `/studentAdministration/externalExam/examPeriod/audit-trail` 
                          }
                          href="#pills-tab16"
                          role="tab"
                          aria-controls="pills-tab16"
                          aria-selected="true"
                        >
                          {GlobalIconNames("audit-trail").html}
                        </Link>
                      </li>
                      </>}
                    </ul>
                  ) : (
                    ""
                  )}
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab11"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      {tab === "details" && <DetailsTab />}
                      {tab === "examSettings" && <ExamSettingTab />}
                      {tab === "examCentres" && <CentreTab />}
                      {tab === "examPeriod" && <ExamPeriodTab/>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalExamReg;
