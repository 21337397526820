import React, { useEffect, useMemo, useState } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import { GetProgrammeStudent } from "../../../../services/ProgrammeServices";
import { RenderLearningMethod } from "../../../../utils/CommonGroupingItem";
import { RenderProgrammeStudentStatus } from "../../../../utils/CommonStatusItems";
import { TABLE_ROWS_PER_PAGE } from "../../../../utils/Constants";
import Str from "../../../common/Str";
import hasPermission from "../../../../utils/hasMultiplePermission";
import PermissionsGate from "../../../../utils/permissionGate";
import { handleTableScroll } from "../../../../utils/commonFunction";

function PercentageView() {
  const { id,subTab } = useParams();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [campus, setCampus] = useState({ arr: [], checkObj: {} });
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
  const [userData, setUserData] = useState([]);
  const [loading, setloading] = useState(true);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    let response = hasPermission({ scopes: ["psview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [givenPermsisions, history])

  useEffect(() => {
    GetProgrammeStudent(id)
      .then((res) => {
        setUserData(res?.data.programs);
        setloading(false);
        // setFilterData({...res.data, status : res.data.studentStatusFilter, campus : res.data.campusStatusFilter, learning : res.data.deliveryMethodFilter})
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");
    setStatus({ arr: [], checkObj: {} });
    setCampus({ arr: [], checkObj: {} });
    setLearningMethod({ arr: [], checkObj: {} });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = userData;
    if (search.length) {
      let tempId = allData.filter((item) => {
        let includes = item.student_crm_id.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempNumber = allData.filter((item) => {
        let includes = item.student_num.toString().toLowerCase().includes(search.toLowerCase().toString());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempfirst_name = allData.filter((item) => {
        let includes = item.first_name && (item.first_name + " " + item.last_name).toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let templast_name = allData.filter((item) => {
        let includes = item.last_name && item.last_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempEmail = allData.filter((item) => {
        let includes = item.email && item.email.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempCampus = allData.filter((item) => {
        let includes = item.campus && item.campus.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempLearningMethod = allData.filter((item) => {
        let includes = item.type && item.type.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempProgramme = allData.filter((item) => {
        return item.Programme 
      });
      let tempStatus = allData.filter((item) => {
        let status;
        if (item.status) {
          status = "active";
        } else {
          status = "inactive";
        }
        let includes = status && status.toLowerCase().includes(search.toLowerCase());

        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        ...tempId,
        ...tempfirst_name,
        ...templast_name,
        ...tempEmail,
        ...tempStatus,
        ...tempNumber,
        ...tempCampus,
        ...tempLearningMethod,
        ...tempProgramme
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.arr.find(function (post, index) {
          // console.log("postsss", post, item.status)
          if (post.toString() === item.status.toString() ? item.status.toString() : "") return item;
          return false;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }
    if (campus.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = campus.arr.find(function (post, index) {
          if (post.toString() === item.campus.toString() ? item.campus.toString() : "") return item;
          return false
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }
    if (learningMethod.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = learningMethod.arr.find(function (post, index) {
          if (post.toString() === item.type.toString() ? item.type.toString() : "") return item;
          else return false
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (status.arr.length || learningMethod.arr.length || campus.arr.length || search.length) {
      return updatedData;
    } else {
      return userData;
    }
  };

  const columns = useMemo(() => [
    {
        name: "Course",
        selector: "type",
        sortField: "type",
        sortable: true,
        cell: (row) => row.type ? <span className="feature-name"><span className="textLimit100">{"course"}</span></span> : "-"
    },
    {
      name: "course marks",
      selector: "student_crm_id",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          <span className="overflow-ellipsis2" title={row.student_crm_id}>{"grade"}</span>
        </div>
      ),
    },
    {
        name: "final marks",
        selector: "status",
        sortable: true,
        cell: (row) => (row.status ? "-" : "-"),
      },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate scopes={["sview"]} RenderError={() => <button className="btn btn-primary rounded-circle" title="Open" disabled>
              <i className="fal fa-folder-open"></i>
            </button>}>
              <Link className="btn btn-primary rounded-circle" title="Open" to={`/studentAdministration/students/open/${row.id}/general`}>
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Email", "ID Number", "Student Number", "Status","Campus", "Learning Method", "Programme"];
    let file = data?.map(row=>row.course)[0];
    data = data?.map((row) => ({
      ...row,
      // ID: row.id? row.id : "-",
      Name: row?.first_name + " " + row.last_name,
      Email: row.email ? row.email : "-",
      Status: row?.status ? RenderProgrammeStudentStatus(row.status).text :  "-",
      "ID Number": row.number && row.number !== null ? row.number : "-",
      "Student Number": row.student_crm_id ? row.student_crm_id : "-",
      "Campus": row.campus ? row.campus.charAt(0).toUpperCase() + row.campus.slice(1).toLowerCase() : "-",
      "Learning Method": row.type ? RenderLearningMethod(row.type).text :"-",
      "Programme": row.course ? row.course : "-"
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = file;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
          return
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, file + ".xlsx");

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Name, row.Email, row["ID Number"], row["Student Number"], row.Status,row["Campus"], row["Learning Method"], row['course']];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(file+ ".pdf");

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {console.log("checkdatahere")}
              <div className="filter-button-group">
                {/* <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Status"
                      optionArr={filterData.status}
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={RenderProgrammeStudentStatus}
                    />
                    <Tablefilter
                      filterName={"Campus"}
                      optionArr={filterData.campus}
                      state={campus}
                      setState={setCampus}
                      renderLabelFunction={RenderCampus}
                    />
                    <Tablefilter
                      filterName={"Learning Method"}
                      optionArr={filterData.learning}
                      state={learningMethod}
                      setState={setLearningMethod}
                      renderLabelFunction={RenderLearningMethod}
                    />
                  </div>
                </div> */}
                <div className="button-reset dropdown-comman">
                  <button className="btn btn-primary" title="Reset" onClick={resetFilter}>
                    <i className="fal fa-redo"></i>Reset
                  </button>
                </div>
                <div className="files-export-group">
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("xlsx", "Programmes_Students");
                    }}
                    title="Export spreadsheet"
                  >
                    <i className="fal fa-file-excel icon"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("csv", "Programmes_Students");
                    }}
                    title="Export CSV"
                  >
                    <i className="fal fa-file-csv icon"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("pdf", "Programmes_Students");
                    }}
                    title="Export PDF"
                  >
                    <i className="fal fa-file-pdf icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                {/* <Link to={`/studentAdministration/students/add`}> */}
                <Link className="btn btn-primary" title="Standard View" to={`/courseAdministration/Programmes/programme/open/${id}/grade/table/${subTab}/course`}>
                  <i className="fal fa-eye"></i>Standard View
                </Link>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
        {loading ? <SkeletonTicketList />
          : <DataTable
            data={dataToRender()}
            defaultSortField="first_name"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />}

      </div>
    </div>
  );
}

export default PercentageView;
