import axiosInstance from "../utils/axios";

export const AddStaticPages = async (data) => {
    console.log(data);
    return await axiosInstance().post('/createStaticPage', data)
}

export const updateStaticPages = async (data) => {
    return await axiosInstance().post(`/updateStaticPage`, data)
}

// export const WebsiteBranch = async () => {
//     return await axiosInstance().get(`/getBranchWebDropDownList`)
// }

export const getStaticPagesCategory = async () => {
    return await axiosInstance().get(`/getCatgoryListStatic`)
}

export const getStaticPagesBrandList = async () => {
    return await axiosInstance().get(`/getBrandTemplateList`)
}

export const getStaticPagesHeaderList = async () => {
    return await axiosInstance().get(`/getHeaderDropDownListStatic`)
}

export const getStaticPagesStatusList = async () => {
    return await axiosInstance().get(`/getPublishedStatusList`)
}

export const getStaticPagesPopupList = async () => {
    return await axiosInstance().get(`/getPopUpListStatic`)
}

export const GetStaticPageListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getStaticPagesList`, values, {
        cancelToken: cancelToken
    });
};

export const GetStaticPageList = async (values) => {
    return await axiosInstance().post(`/getStaticPagesList`, values);
};

export const GetStaticPagesListsFilterCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getStaticFilter`, {
        cancelToken: cancelToken
    });
};

export const DeleteStaticPages = async (PageID) => {
    return await axiosInstance().post(`/deleteStaticPage?PageID=${PageID}`);
};

// export const GetWebsiteList = async (data) => {
//     return await axiosInstance().post(`/getWebsiteList`, data);
// };

export const GetStaticUpdateList = async (id) => {
    return await axiosInstance().get(`/getStaticPage?PageID=${id}`);
};