import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { RenderPopupType } from '../../../../utils/CommonGroupingItem';
import DataTableComponentFrontPagination from '../../../common/DataTableComponentFrontPagination';
import axios from 'axios';
import { RenderCourseAssignedlistStatus } from '../../../../utils/CommonStatusItems';
import CategoryAvailableProgrames from './CategoryAvailableProgrames'
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import CategoryAvailablePages from './CategoryAvailablePages';
import { getAllPagesFilters, getAllPagesList, getAllProgrameAndShortCourseList, getAssigendPagesAndProgrammeShortcourseFilter } from '../../../../services/PopupsPagesServer';

function CategoryAssignedProgrammes({ id, assignedRec, setAssignedRec, assignloading, setAssignLoading , type }) {
  const [availableLoading, setAvailableLoading] = useState(false);
  const [courseLoading, setCourseLoading] = useState(false);
  const [addedRec, setAddedRec] = useState([]);
  const [availableRec, setAvailableRec] = useState([]);
  const [availableCourseRec, setAvailableCourseRec] = useState([]);
  const [searchAssigned, setSearchAssigned] = useState("");
  const [assingIntake, setAssingIntake] = useState({ arr: [], checkObj: {} })
  const [assingStudyType, setAssingStudyType] = useState({ arr: [], checkObj: {} })
  const [filterData, setFilterData] = useState({ intake: [], learning: [] })
  const [AvailablefilterData, setAvailableFilterData] = useState({ intake: [], learning: [] })
  const [AvailableCoursefilterData, setAvailableCourseFilterData] = useState({ intake: [], learning: [] })

  const uniquekey = assignedRec?.map((id) => id.ref_id)

  useEffect(() => {
    const getProgramsList = async () => {
      setAssignLoading(true);

      try {
        let res = await getAssigendPagesAndProgrammeShortcourseFilter(id , 1);
        if (res.status === 200) {
          setFilterData({ ...res.data, intake: res?.data?.statusFilters, learning: res?.data?.typeFilters})
          setAssignLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error:", error)
          setAssignLoading(false)
        }
      }

    }

    const getsingleProgramFilter = () => {
      getAllPagesFilters(id, 1 , 0)
        .then((res) => {
          setAvailableFilterData({ intake: res?.data?.statusFilters, learning: res?.data?.typeFilters })
        })
    }

    const getsingleShortFilter = () => {
      getAllPagesFilters(id, 1 , 1)
        .then((res) => {
          setAvailableCourseFilterData({ intake: res?.data?.statusFilters, learning: res?.data?.typeFilters })
        })
    }

    getProgramsList()
    getsingleProgramFilter()
    getsingleShortFilter()
  }, [])

  useEffect(() => {
    const getProgramsList = async () => {
      setAvailableLoading(true);
      const data = {
        id: Number(id),
        type: 1
      };

      try {
        let res = await getAllProgrameAndShortCourseList(data);
        if (res.status === 200) {
          setAvailableRec(res?.data?.data);
          setAvailableLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error:", error)
          setAvailableLoading(false)
        }
      }

    }
    getProgramsList()
  }, [])

  useEffect(() => {
    const getProgramsList = async () => {
      setCourseLoading(true);
      const data = {
        id: Number(id),
        type: 1
      };
      try {
        let res = await getAllPagesList(data);

        if (res.status === 200) {
          setAvailableCourseRec(res?.data?.data);
          setCourseLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error:", error)
          setCourseLoading(false)
        }
      }

    }

    getProgramsList()
  }, [])

  const handleAdd = (rec, type) => {
    if (type === 'add') {
      if (rec.flag == 3 || rec.flag == 4 || rec.flag == 5 || rec.flag == 6 || rec.flag == 7) {
        
            availableCourseRec.map((elem, ind) => {
              if (elem.id == rec.id) {
                availableCourseRec.splice(ind, 1);
                setAssignedRec([...assignedRec, rec]);
                if (addedRec.length) {
                  addedRec.map((e, i) => {
                    if (e == rec.id) {
                      addedRec.splice(i, 1);
                      setAddedRec([...addedRec]);
                    } else {
                      setAddedRec([...addedRec, rec.id]);
                    }
                  });
                } else {
                  setAddedRec([...addedRec, rec.id]);
                }
          }
        });
      } else if (rec.flag == 2 || rec.flag == 1) {
        
            availableRec.map((elem, ind) => {
              if (elem.id == rec.id) {
                availableRec.splice(ind, 1);
                setAssignedRec([...assignedRec, rec]);
                if (addedRec.length) {
                  addedRec.map((e, i) => {
                    if (e == rec.id) {
                      addedRec.splice(i, 1);
                      setAddedRec([...addedRec]);
                    } else {
                      setAddedRec([...addedRec, rec.id]);
                    }
                  });
                } else {
                  setAddedRec([...addedRec, rec.id]);
                }
          }
        });
      }
    } else if (type === "Delete") {
      if (rec.flag == 3 || rec.flag == 4 || rec.flag == 5 || rec.flag == 6 || rec.flag == 7) {
        
            assignedRec.map((elem, ind) => {
              if (elem.ref_id == rec.ref_id) {
                assignedRec.splice(ind, 1);
                setAvailableCourseRec([...availableCourseRec, rec]);
                if (addedRec.length) {
                  addedRec.map((e, i) => {
                    if (e == rec.id) {
                      addedRec.splice(i, 1);
                      setAddedRec([...addedRec]);
                    } else {
                      setAddedRec([...addedRec, rec.id]);
                    }
                  });
                } else {
                  setAddedRec([...addedRec, rec.id]);
                }
          }
        });
      } else if (rec.flag == 2 || rec.flag == 1) {
        
            assignedRec.map((elem, ind) => {
              if (elem.ref_id == rec.ref_id) {
                assignedRec.splice(ind, 1);
                setAvailableRec([...availableRec, rec]);
                if (addedRec.length) {
                  addedRec.map((e, i) => {
                    if (e == rec.id) {
                      addedRec.splice(i, 1);
                      setAddedRec([...addedRec]);
                    } else {
                      setAddedRec([...addedRec, rec.id]);
                    }
                  });
                } else {
                  setAddedRec([...addedRec, rec.id]);
                }
          }
        });
      }
    }
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "title",
      sortField: "title",
      sortable: true,
      cell: (row) => (
        <Link
          className="as-text-blue curser feature-name"
          to={`${row.flag == 4 ? `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}` : row.flag == 2 ? `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}` : row.flag == 1 ? `/program-management-center/programmes/programme/open/${row.id}` : row.flag == 3 ? `/website_management/pages/staticpages/table/update/${row.id}` : row.flag == 5 ? `/website_management/pages/courselandingpages/table/open/${row.id}` : row.flag == 7 && `/website_management/pages/blogandnews/table/update/${row.id}`}`}
          title={row.title}
        >
          <span className="textLimit100">{row.title}</span>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) => (row.status !== undefined || null ? RenderCourseAssignedlistStatus(row.status).html : "-"),
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      cell: (row) => row?.type ? RenderPopupType(row.type, "popup")?.html : "-"
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              className="btn btn-primary h-auto rounded-circle"
              to={`${row.flag == 4 ? `/website_management/pages/categorylandingpages/table/update/${row.id}` : row.flag == 2 ? `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}` : row.flag == 1 ? `/program-management-center/programmes/programme/open/${row.id}` : row.flag == 3 ? `/website_management/pages/staticpages/table/update/${row.id}` : row.flag == 5 ? `/website_management/pages/courselandingpages/table/update/${row.id}` : null}`}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>

            <button
              type="button"
              className="btn btn-danger rounded-circle"
              onClick={() => handleAdd(row, "Delete")}
              title='Delete'
            >
              <i className="fal fa-minus"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const dataToRender = () => {
    let updatedData = [];
    let allData = assignedRec;

    if (searchAssigned.length) {
      let name = allData?.filter((item) => {
        let includes = item?.title?.toString()
          .toLowerCase()
          .includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...name];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (assingStudyType.arr.length) {
      let tempAssignStudy = updatedData;
      let tempIntakeHash = tempAssignStudy.filter((item) => {
        const startsMarking = assingStudyType.arr.find((it) => it == item.type)
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });
      updatedData = tempIntakeHash;
    } else if (assingIntake.arr.length) {
      let tempIntake = updatedData;
      let tempIntakeHash = tempIntake.filter((item) => {
        const startsMarking = assingIntake.arr.find((it) => it == item.status)
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });

      updatedData = tempIntakeHash;
    }

    if (
      searchAssigned.length ||
      assingStudyType.arr.length ||
      assingIntake.arr.length
    ) {
      return updatedData;
    } else {
      return assignedRec;
    }
  };

  const exportData = () => {
    let data = dataToRender();
    const header = [
      "Name",
      "Status",
      "Type",
    ];
    data = data.map((row) => ({
      "Name": row.title ? row.title : "-",
      "Status": row.status,
      "Type": row?.type,
    }));
    const pdfData = data.map((row) => {
      return [
        row["Name"],
        row["Status"],
        row["Type"],
      ];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };

  return (
    <>
      <DataTableComponentFrontPagination
        data={dataToRender()}
        columns={columns}
        loading={assignloading}
        search={searchAssigned}
        setSearch={setSearchAssigned}
        exportData={exportData}
        exportFileName={"Assigned_Programmes_and_sortCourses"}
        progressComponent={<SkeletonTicketList />}
        defaultSort={{ defaultSortColumn: "name", defaultSortAsc: false }}
        custumcss="border-0 p-0"
        keyField={uniquekey[1]}
        filters={[
          {
            filterName: "Status",
            optionArr: filterData.intake,
            state: assingIntake,
            setState: setAssingIntake,
            uniqueId: "assignstatus",
            isOptionReversed: true,
            renderLabelFunction: RenderCourseAssignedlistStatus,
          },
          {
            filterName: "Type",
            optionArr: filterData.learning,
            state: assingStudyType,
            setState: setAssingStudyType,
            uniqueId: "assignType",
            renderLabelFunction: RenderPopupType,
          }
        ]}
        tableButton={
          [
            <div className='d-flex'>
              <button className="btn btn-primary mr-1" data-toggle="modal" data-target="#availableProgramme" title="Assign Programme" type="button">
                <i className="fal fa-laptop"></i>Assign Programme
              </button>

              <button className="btn btn-primary" data-toggle="modal" data-target="#availableShortCourse" title="Assign Pages" type="button">
                <i className="fal fa-file"></i>Assign Pages
              </button>
            </div>
          ]
        }
      />

      <div
        className="topic-add-modal modal able_modal01 fade"
        id="availableProgramme"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <CategoryAvailableProgrames
            AvailablefilterData={AvailablefilterData}
            availableRec={availableRec}
            assignedRec={assignedRec}
            setAssignedRec={setAssignedRec}
            availableLoading={availableLoading}
            handleAdd={handleAdd}
          />
        </div>
      </div>

      <div
        className="topic-add-modal modal able_modal01 fade"
        id="availableShortCourse"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <CategoryAvailablePages
            AvailablefilterData={AvailableCoursefilterData}
            availableRec={availableCourseRec}
            assignedRec={assignedRec}
            setAssignedRec={setAssignedRec}
            availableLoading={courseLoading}
            handleAdd={handleAdd}
          />
        </div>
      </div>
    </>
  )
}

export default CategoryAssignedProgrammes