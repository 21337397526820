import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import "jspdf-autotable";
import JsPDF from "jspdf";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import Swal from 'sweetalert2';
import Str from '../../../common/Str';
import DataTable from 'react-data-table-component';
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import { ResourceType } from '../../../systemadministration/usermanagement/CheckRole';
import { DeleteAssemblyResource, GetAssignedAssembliesToResource, GetListOfItemsResources } from '../../../../services/CourseService';
import { TABLE_ROWS_PER_PAGE } from '../../../../utils/Constants';
import { handleTableScroll } from '../../../../utils/commonFunction';

const ListOfResources = ({ setHeadingname }) => {

    const { tab, type, id, resourcePoolId, subTabId } = useParams();
    const history = useHistory();
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [resources, setResources] = useState([]);
    const [deleteRow, setDeleteRow] = useState(false);
    const [resourceDetail, setResourceDetail] = useState("");
    const [linkedAssemblies, setLinkedAssemblies] = useState([]);
    const [selectedAddSubCatData, setSelectedAddSubCatData] = useState("");

    useEffect(() => {
        handleTableScroll()
      }, [loading])

    useEffect(() => {
        if (type !== "add") {
            getData();
        } else {
            setLoading(false);
            setResourceDetail("");
        }
    }, [deleteRow]);

    const getData = () => {
        // GetAssemblyResourceDetail({ item_id: id }).then((res) => {
        //     setResourceDetail(res?.data?.resouce_detail);
        //     setHeadingname(res?.data?.resouce_detail?.item_name);
        // });
        GetListOfItemsResources(id).then((res) => {
            setResources(res?.data?.assemblies_item_list?.data);
            setLoading(false);
        });
        GetAssignedAssembliesToResource(id).then((res) => {
            setLinkedAssemblies(res?.data?.list);
            setLoading(false);
        });
    };

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "item_name",
            sortable: true,
            // wrap: true,
            cell: (row) => <Link to={{ pathname: `/content/resource-pool/open/${id}/sub-category/open/${row.item_id}`, state: { subCatDataDetail: row } }} className="as-text-blue curser " title={row.item_name}>
                {row.item_name}
            </Link>,
        },
        {
            name: "Type",
            selector: "item_type",
            // sortable: true,
            // wrap: true,
            cell: (row) => (
                <span title={ResourceType(row?.item_type.toString())}>
                    {ResourceType(row?.item_type.toString())}
                </span>
            ),
        },
        {
            name: "Action",
            selector: "",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons">
                        <Link className={row?.item_type !== 3 ? "mr-1" : ""} to={{ pathname: `/content/resource-pool/open/${id}/sub-category/open/${row.item_id}`, state: { subCatDataDetail: row } }} >
                            <button className="btn btn-primary rounded-circle" title="Open">
                                {/* <button className="btn btn-primary rounded-circle" title="Open" onClick={() => history.push(`/content/resource-pool/open/${id}/sub-category/open/${row.item_id}`, { subCatDataDetail: row })}> */}
                                <i className="fal fa-folder-open"></i>
                            </button>
                        </Link>
                        {row?.item_type == 3 ? (
                            <div className="dropdown btn-dropdown-item">
                                <button
                                    className="btn btn-primary rounded-circle dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    title="More"
                                >
                                    <i className="fal fa-ellipsis-h-alt"></i>
                                </button>

                                <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    <button
                                        className="btn btn-primary rounded-circle"
                                        title="Add"
                                        data-toggle="modal"
                                        data-target="#addSubCatModal"
                                        onClick={() =>
                                            setSelectedAddSubCatData(row)
                                        }
                                    >
                                        <i className="fal fa-plus"></i>
                                    </button>
                                    <button
                                        className="btn btn-danger rounded-circle"
                                        title="Delete"
                                        onClick={() => handleDelete(row.item_id)}
                                    >
                                        <i className="fal fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <button
                                className="btn btn-danger rounded-circle"
                                title="Delete"
                                onClick={() => handleDelete(row.item_id)}
                            >
                                <i className="fal fa-trash-alt"></i>
                            </button>
                        )}
                    </div>
                </div>
            ),
        },
    ]);

    const dataToRender = () => {
        let updatedData = [];
        // console.log("resources from dataToRender", resources);
        let allData = resources;
        if (search?.length) {
            // let tempId = allData.filter((item) => {
            //     let includes = item.item_id.toString().includes(search.toLowerCase());
            //     if (includes) {
            //         return includes;
            //     } else return null;
            // });
            let tempTabName = allData.filter((item) => {
                let includes =
                    item.item_name &&
                    item.item_name.toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempType = allData.filter((item) => {
                let includes =
                    item.item_type &&
                    ResourceType(item?.item_type.toString()).toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });

            let data = [
                // ...tempId,
                ...tempTabName,
                ...tempType,
                // ...tempVisible,
                // ...tempIcon,
                // ...tempLinkedBrand,
            ];
            let unique = [...new Set(data)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (search.length) {
            return updatedData;
        } else {
            return allData;
        }
    };

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setSearch(value);
    };

    const exportData = (fileType, fileName) => {
        let data = dataToRender();
        const header = ["Name", "Type"];
        data = data?.map((row) => ({
            ...row,
            Name: row?.item_name || "-",
            Type: ResourceType(row?.item_type.toString()) || "-",
        }));
        // console.log(typeof data);
        // console.log(typeof result);

        if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            // Create new tag for download file
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = [
                "text/csv",
                anchor.download,
                anchor.href,
            ].join(":");
            anchor.click();

            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                URL.revokeObjectURL(blobURL);
            }, 1000);
        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.map((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);

            // Returning false as downloading of file is already taken care of
            return false;
        }
        if (fileType === "pdf") {
            const compatibleData = data.map((row) => {
                return [row.Name, row.Type];
            });
            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                    minCellHeight: 10,
                    minCellWidth: 5,
                    halign: "left",
                    // valign: "center",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteAssemblyResource({ item_id: id }).then((res) =>
                    setDeleteRow(!deleteRow)
                );
                Swal.fire("Deleted!", "Your resource has been deleted.", "success");
            }
        });
    };

    const resetFilter = () => {
        setSearch("");
    };

    return (

        <div className="custom-table-div filter-search-icon card card-table-custom custom-table-expand-blk ass-bel-stuff">
            <div className="new-datatable-blk">
                <div className="filter-search-bar-blk top-0">
                    <div className="filter-button-group">
                        <div className="filter-eff filter-data-btn">
                            <button className="filter-buttons">
                                <i className="fal fa-filter"></i>
                            </button>
                        </div>
                        <div className="button-reset dropdown-comman">
                            <button className="btn btn-primary" title="Reset" onClick={resetFilter}>
                                <i className="fal fa-redo"></i>Reset
                            </button>
                        </div>

                        <div className="files-export-group">
                            <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Resources List"); }} title="Export spreadsheet" >
                                <i className="fal fa-file-excel icon"></i>
                            </button>

                            <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Resources List"); }} title="Export CSV" >
                                <i className="fal fa-file-csv icon"></i>
                            </button>

                            <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Resources List"); }} title="Export PDF" >
                                <i className="fal fa-file-pdf icon"></i>
                            </button>
                        </div>
                    </div>
                    <div className="add-ticket-blk button-reset dropdown-comman">
                        <button className="btn btn-primary" title="New Resource" onClick={() => history.push("/content/resource-pool/create/" + id + "/sub-category/add/")} >
                            <i className="fal fa-plus"></i>New Resource
                        </button>
                    </div>
                </div>
                <div className="system-administration-table table-responsive">
                    <div className="table-responsive-div">
                        <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                <div id="assessment-table-main_filter" className="dataTables_filter">
                                    <label>
                                        <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" value={search} onChange={handleSearchFilter} />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading ? (<SkeletonTicketList />) : (<DataTable
                data={dataToRender()}
                defaultSortField="item_name"
                defaultSortAsc={true}
                noDataComponent={Str.noRecord}
                columns={columns}
                pagination={true}
                paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />)}


            {/* <div className="card-header mt-2">Linked Assemblies</div>
                <div className="custom-table-div filter-search-icon card card-table-custom new-datatable-blk ">
                    <div className="filter-search-bar-blk">
                        <div className="filter-button-group">
                            <div className="filter-eff filter-data-btn">
                                <button className="filter-buttons">
                                    <i className="fal fa-filter"></i>
                                </button>
                            </div>

                            <div className="button-reset dropdown-comman">
                                <button className="btn btn-primary" onClick={resetFilter2} title="Reset" >
                                    <i className="fal fa-redo"></i>Reset
                                </button>
                            </div>

                            <div className="files-export-group">
                                <button
                                    type="button"
                                    className="btn btn-files"
                                    onClick={() => {
                                        exportData2("xlsx", "Linked Assemblies");
                                    }}
                                    title="Export spreadsheet"
                                >
                                    <i className="fal fa-file-excel icon"></i>
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-files"
                                    onClick={() => {
                                        exportData2("csv", "Linked Assemblies");
                                    }}
                                    title="Export CSV"
                                >
                                    <i className="fal fa-file-csv icon"></i>
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-files"
                                    onClick={() => {
                                        exportData2("pdf", "Linked Assemblies");
                                    }}
                                    title="Export PDF"
                                >
                                    <i className="fal fa-file-pdf icon"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="system-administration-table table-responsive">
                        <div className="table-responsive-div">
                            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                    <div id="assessment-table-main_filter" className="dataTables_filter">
                                        <label>
                                            <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" value={search2} onChange={handleSearchFilter2} />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {!loading ? (
                        <DataTable data={dataToRender2()} defaultSortField="updated_at" defaultSortAsc={true} noDataComponent={Str.noRecord} columns={columns2} pagination={true} />
                    ) : (
                        <SkeletonTicketList />
                    )}
                </div> */}
        </div>

    )

}

export default ListOfResources;