import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import RegisterNewStudent from "../registration/RegisterNewStudent";
import ListArticulations from "./ListArticulations";
import AuditTrails from "./AuditTrails";
// import StudentList from "./StudentList";
import { GlobalIconNames } from "../../../utils/GlobalIconNames";
import OpenArticulationRegistration from "./OpenArticulationRegistration";
import AssignedProgrammes from "./AssignedProgrammes";
import Articulations from "./Articulations";
import StudentList from "./StudentList";
import PermissionsGate from "../../../utils/permissionGate";

const ProgrammeArticulation = (props) => {
  const { tab, page, id } = useParams();
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const description = params.get("description")
    ? params.get("description")
    : "";

  useEffect(() => {
    if (page === "open-articulation-registration" && tab !== "add") {
      changeTabName("Articulation Registration: " + description);
    } else if (
      (page === "open-articulation-registration" && tab === "add") ||
      page === "list-student-status"
    ) {
      changeTabName("Articulation Registration");
    }
  }, [page]);

  function changeTabName(name = "open-articulation-registration") {
    props.changeName(name);
  }

  return (
    <>
      {page === "create" ? (
        <RegisterNewStudent />
      ) : page === "open-articulation-registration" && tab !== "add" ? (
        <>
          <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
            <PermissionsGate scopes={["cparview"]}>
              <li
                className="nav-item"
                onClick={() => {
                  history.push(
                    `/courseAdministration/articulation/open-articulation-registration/open/${id}?description=${description}`
                  );
                  changeTabName("Articulation Registration: " + description);
                }}
              >
                <a
                  className={"nav-link" + (tab === "open" ? " active" : "")}
                  id="pills-tab15"
                  data-toggle="pill"
                  href="#pills-tab15"
                  role="tab"
                  aria-controls="pills-tab15"
                  aria-selected="true"
                >
                  {GlobalIconNames("details").html}
                </a>
              </li>
            </PermissionsGate>
            <PermissionsGate scopes={["cparaapview"]}>
              <li
                className="nav-item"
                onClick={() => {
                  history.push(
                    `/courseAdministration/articulation/open-articulation-registration/assignedProgramme/${id}?description=${description}`
                  );
                }}
              >
                <a
                  className={
                    "nav-link" + (tab === "assignedProgramme" ? " active" : "")
                  }
                  id="pills-tab16"
                  data-toggle="pill"
                  href="#pills-tab16"
                  role="tab"
                  aria-controls="pills-tab16"
                  aria-selected="true"
                >
                  {GlobalIconNames("assignedProgrammes").html}
                </a>
              </li>
            </PermissionsGate>
            <PermissionsGate scopes={["cparaaview"]}>
              <li
                className="nav-item"
                onClick={() => {
                  history.push(
                    `/courseAdministration/articulation/open-articulation-registration/articulations/${id}?description=${description}`
                  );
                }}
              >
                <a
                  className={
                    "nav-link" + (tab === "articulations" ? " active" : "")
                  }
                  id="pills-tab67"
                  data-toggle="pill"
                  href="#pills-tab67"
                  role="tab"
                  aria-controls="pills-tab67"
                  aria-selected="true"
                >
                  {GlobalIconNames("articulations").html}
                </a>
              </li>
            </PermissionsGate>
            <PermissionsGate scopes={["cparasview"]}>
              <li
                className="nav-item"
                onClick={() => {
                  history.push(
                    `/courseAdministration/articulation/open-articulation-registration/studentList/${id}?description=${description}`
                  );
                }}
              >
                <a
                  className={
                    "nav-link" + (tab === "studentList" ? " active" : "")
                  }
                  id="pills-tab26"
                  data-toggle="pill"
                  href="#pills-tab26"
                  role="tab"
                  aria-controls="pills-tab26"
                  aria-selected="true"
                >
                  {GlobalIconNames("Student List", "fa-users", "Student List").html}
                </a>
              </li>
            </PermissionsGate>
            <PermissionsGate scopes={["cparview"]}>
              <li
                className="nav-item"
                onClick={() => {
                  history.push(
                    `/courseAdministration/articulation/open-articulation-registration/audittrails/${id}?description=${description}`
                  );
                }}
              >
                <a
                  className={
                    "nav-link" + (tab === "audittrails" ? " active" : "")
                  }
                  id="pills-tab17"
                  data-toggle="pill"
                  href="#pills-tab17"
                  role="tab"
                  aria-controls="pills-tab17"
                  aria-selected="true"
                >
                  {GlobalIconNames("auditTrail").html}
                </a>
              </li>
            </PermissionsGate>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className={"tab-pane fade active show"}
              id="pills-tab14"
              role="tabpanel"
              aria-labelledby="pills-tab14"
            >
              {tab === "open" && <OpenArticulationRegistration />}
              {tab === "assignedProgramme" && <AssignedProgrammes />}
              {tab === "articulations" && <Articulations />}
              {tab === "studentList" && <StudentList />}
              {tab === "audittrails" && <AuditTrails />}
            </div>
          </div>
        </>
      ) : page === "open-articulation-registration" && tab === "add" ? (
        <OpenArticulationRegistration />
      ) : (
        <>
          <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
            <li
              className="nav-item"
              onClick={() => {
                history.push(
                  page === "open-articulation-registration"
                    ? `/courseAdministration/articulation/open-articulation-registration/open/${id}`
                    : `/courseAdministration/articulation/list-articulations`
                );
                changeTabName("Articulation Registrations");
              }}
            >
              <a
                className={
                  "nav-link" +
                  (page === "list-articulations" ||
                    page === "list-student-status" ||
                    page === "open-articulation-registration"
                    ? " active"
                    : "")
                }
                id="pills-tab15"
                data-toggle="pill"
                href="#pills-tab15"
                role="tab"
                aria-controls="pills-tab15"
                aria-selected="true"
              >
                {page === "open-articulation-registration"
                  ? GlobalIconNames("Open Articulation Registration", "fa-bars", "Open Articulation Registration").html
                  : GlobalIconNames("List Articulations", "fa-bars", "List Articulations").html}
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                history.push(
                  `/courseAdministration/articulation/audittrails?description=${description}`
                );
              }}
            >
              <a
                className={
                  "nav-link" + (page === "audittrails" ? " active" : "")
                }
                id="pills-tab16"
                data-toggle="pill"
                href="#pills-tab16"
                role="tab"
                aria-controls="pills-tab16"
                aria-selected="true"
              >
                {GlobalIconNames("auditTrail").html}
              </a>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className={"tab-pane fade active show"}
              id="pills-tab14"
              role="tabpanel"
              aria-labelledby="pills-tab14"
            >
              {page === "open-articulation-registration" && <OpenArticulationRegistration />}
              {page === "list-articulations" && <ListArticulations />}
              {page === "list-student-status" && <ListArticulations />}
              {page === "audittrails" && <AuditTrails />}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ProgrammeArticulation;
