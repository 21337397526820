import { useFormikContext } from "formik";
import React from "react";
import { checkCheckboxRejectDetails } from "../../../../services/RegistrationService";
import Swal from "sweetalert2";

function StudyKits() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const studentId = params.get('studentId');

  const formik = useFormikContext()

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Study Kits</div>
      </div>
      {/* {formik.values.laptop !== "no_laptop" ? <div className="row"> */}
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <label>&nbsp;</label>
          <div className="form-group-blk mb-3" style={{ paddingTop: "11px" }}>
            <label></label>
            <div className="custom-check custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                id="laptop_ordered"
                name="select"
                checked={formik.values.laptop_ordered}
                onChange={e => {
                  let data = {
                    action: "laptop_ordered",
                    id: studentId,
                    order: e.target.checked ? 1 : 0,
                  }
                  checkCheckboxRejectDetails(data).then(res => {
                    Swal.fire("Updated", res.data.message, "success")
                  }).catch(err => {
                    Swal.fire("Not Updated", err.response.data.message, "error")
                    console.error("error: ", err)
                  })
                  formik.setFieldValue("laptop_ordered", e.target.checked)
                }}
              />
              <label className="custom-control-label" htmlFor="laptop_ordered">Laptop Ordered</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudyKits;
