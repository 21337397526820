import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import { GetCampusMgtList } from "../../../services/CampusMgtService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { formatQuestion } from "../../../utils/FormatQuestion";
import { TrimText } from "../../common/TrimText";
import Swal from "sweetalert2";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const CampusList = () => {
  const [dataArr, setDataArr] = useState([]);
  const [search, setSearch] = useState("");
  const [loading,setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("campus_name");
  const [sortOrder, setSortOrder] = useState("DESC");

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    const cancelTokenSources = [];

    const handleCampusList= async ()=>{      
      setLoading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();

      cancelTokenSources.push(source);
      const cData={
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        name:search,
        exportStatus: "false",
      }
      try{
        const res = await GetCampusMgtList(cData, source.token);
        if(res.status==200){
          setDataArr(res && res.data && res.data.data_list ? res.data.data_list.data : []);
          setTotalRows(res.data.data_list?.total); 
          setLoading(false);       
        }
      }catch(error){
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    }
    handleCampusList();
    
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    }
  }, [page,perPage,sortkey,sortOrder,search]);

  

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };


  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "campus_name",
      sortField:"campus_name",
      sortable: true,
      cell: (row) => (
        row.campus_name ? <Link className="feature-name" to={`/administration/campusManagement/campusDetails?id=${row.id}&name=${row.campus_name}`}><p className="as-text-blue curser textLimit100" title={row.campus_name}>{row.campus_name}</p></Link> : '-'
      ),
    },
    {
      name: "Address",
      selector: "campus_address",
      sortField:"campus_address",
      sortable: true,
      cell: (row) => (
        <span className="feature-name" title={formatQuestion(row.campus_address).replaceAll("&nbsp;","")}>
          <span className="textLimit100">{formatQuestion(row.campus_address).replaceAll("&nbsp;","")}</span>
        </span>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <Link to={`/administration/campusManagement/campusDetails?id=${row.id}&name=${row.campus_name}`}>
              <button className="btn btn-primary rounded-circle" title="Open">
                <i className="fal fa-folder-open"></i>
              </button>
            </Link>
          </div>
        </div>
      ),
    }
  ]);

  const exportData = (fileType, fileName) => {
    let data;
    const header = ["Name", "Address"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    const cData={
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      name:search,
      exportStatus: "true",
    }
    GetCampusMgtList(cData).then(data => {
      data=data.data.data_list;
      data = data?.map((row, ind) => ({
        ...row,
        "Name": row.campus_name ? row.campus_name : "-",
        "Address": row.campus_address ? formatQuestion(row.campus_address).replaceAll("&nbsp;","") : "-",
      }));
      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        // console.log(csvString);
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
  
        const blobURL = window.URL.createObjectURL(blob);
  
        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
        anchor.click();
  
        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });
  
        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        // Returning false as downloading of file is already taken care of
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [row["Name"], row["Address"]];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
      
    }).catch(error => {
      console.log("error -----> ", error);
    })

 

  
  };

  const resetFilter = () => {
    setSearch("");
  };


const customSort = (rows, selector, direction) => {
  return rows.sort((rowA, rowB) => {
   // use the selector function to resolve your field names by passing the sort comparitors
   const aField = rowA[selector].toString().toLowerCase()
   const bField = rowB[selector].toString().toLowerCase()
 
   let comparison = 0;
 
   if (aField > bField) {
    comparison = 1;
   } else if (aField < bField) {
    comparison = -1;
   }
 
   return direction === 'desc' ? comparison * -1 : comparison;
  });
 };

  return (
        <div className="my-tickets-info-list Tickets-main-wrap">
          <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
            <div className="search-filter-div">
              <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                    <div className="table-responsive-div">
                      <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                          <div id="assessment-table-main_filter" className="dataTables_filter">
                            <label>
                              <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                            </label>
                            <div className="filter-eff filter-data-btn">
                              <button className="filter-buttons">
                                <i className="fal fa-filter"></i>
                              </button>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="filter-button-group">
                      <div className="reset-btn-group">
                        <div className="button-reset dropdown-comman">
                          <button className="btn btn-primary" onClick={resetFilter} title="Reset" >
                            <i className="fal fa-redo"></i>Reset
                          </button>
                        </div>

                        <div className="files-export-group">
                          <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Campus_List"); }} title="Export spreadsheet" >
                            <i className="fal fa-file-excel icon"></i>
                          </button>
                          <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Campus_List"); }} title="Export CSV" >
                            <i className="fal fa-file-csv icon"></i>
                          </button>
                          <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Campus_List"); }} title="Export PDF" >
                            <i className="fal fa-file-pdf icon"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
      
              <div className="search-filter-div-right">
                <div className=" filter-search-bar-blk">
                  <div className="add-ticket-blk button-reset dropdown-comman d-flex">
                      <Link className="ml-auto" to='/administration/campusManagement/addCampus'>
                        <button className="btn btn-primary" title="Create New">
                          <i className="fal fa-plus"></i>Create New
                        </button>
                      </Link>
                  </div>
                </div>
              </div>
            </div>
              <DataTable
                paginationDefaultPage={page}
                data={dataArr}
                columns={columns}
                defaultSortAsc={false}
                defaultSortField="campus_name"
                paginationServer
                pagination
                paginationTotalRows={totalRows}
                progressPending={loading}
                progressComponent={<SkeletonTicketList />}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                highlightOnHover={false}
                onSort={handleSort}
                sortFunction={customSort}
                // paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              />
          </div>
        </div>
  );
};

export default CampusList;

