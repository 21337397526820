import React from "react";
import { useParams } from "react-router-dom";
import GradeCourseList from "./GradeCourseList";
import GradeList from "./GradeList";
import PercentageView from "./PercentageView";
import GradeMixGradeList from "./GradeMixGradeList";

function GradeTab({ programmeName, gradingStandard }) {
  const { subtype,subId, subTab, openTabs} = useParams();
  return (
    <div>
      {(gradingStandard == 2 && subtype === "grade" && subId === "table" && !subTab ) && <GradeMixGradeList programmeName={programmeName}/>}
      {(gradingStandard != undefined && gradingStandard != 2 && subtype === "grade" && subId === "table" && !subTab ) && <GradeList programmeName={programmeName}/>}
      {(subtype === "grade" && subTab && openTabs==="course") && <GradeCourseList/>}
      {(subtype === "grade" && subTab && openTabs==="percentage") && <PercentageView/>}
      {/* {type && type == "edit" && id && <EmailEdit setName={setName} emailId={id} />}
      {type && type == "add" && <EmailEdit />} */}
    </div>
  );
}

export default GradeTab;