import axiosInstance from "../utils/axios";

export const GetBannerList = async (cancelToken) => {
    return await axiosInstance().get(`/getBannerSettingList`, {cancelToken});
};

export const DeleteBannerList = async (value) => {
    return await axiosInstance().post(`/deleteBannerSetting`, value)
}

export const GetListOfBannersettingList = async () => {
    return await axiosInstance().get(`/getBrandSettingList`, {});
};

export const GetListOfBannersettingListCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getBrandSettingList`, {cancelToken: cancelToken});
};

export const AddBannerSetting = async (values) => {
    return await axiosInstance().post(`/addBannerSetting`, values)
}

export const getBannerSettingDetail = async (values) => {
    return await axiosInstance().get(`/getBannerSettingDetail?id=${values.id}`);
};

export const getBannerSettingDetailCancelToken = async (values, cancelToken) => {
    return await axiosInstance().get(`/getBannerSettingDetail?id=${values.id}`, {cancelToken: cancelToken});
};

export const EditBannersetting = async (values) => {
    return await axiosInstance().post(`/editBannerSetting`, values)
}

export const GetBannerSettingAuditTrail = async (values) => {
    // console.log("routevalue", values)
    return await axiosInstance().get(`/getBannerSettingAuditTrail?action_id=${values.action_id}`)
}

export const getEmailAuditTrail = async (values, tab) => {
    // console.log("routevalue", values)
    return await axiosInstance().get(`/getEmailAuditTrail?action_id=${values.action_id}&resource_type=${tab}`)
}