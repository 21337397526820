
import React, { useState, useEffect, useMemo } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { GetAllCopiedNotificationsCancelToken, getAllCopiedNotifications } from "../../../services/copyIntakeServices";
import DataTableComponent from "../../common/DataTableComponent";
import axios from "axios";

const AllCopiedNotifications = ({ id, setShowAllCopiedNotifications }) => {
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [studentList, setStudentList] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [tableState, setTableState] = useState({
        page: 1,
        perPage: 10,
        sortKey: "name",
        sortOrder: "desc",
    });

    useEffect(() => {
        const cancelTokenSources = [];
        const getData = async () => {
            setLoading(true);

            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
              });
          
              const source = axios.CancelToken.source();
              cancelTokenSources.push(source);

              const data = {
                uniqueProcessId: id,
                page: tableState.page,
                limit: tableState.perPage,
                key: tableState.sortKey,
                sort: tableState.sortOrder,
                search: search,
            };

            try {
                const res = await GetAllCopiedNotificationsCancelToken(data, source.token);
                setShowAllCopiedNotifications(res?.data && res?.data?.list && res?.data?.list?.data.length || search ? true : false)
                setStudentList(
                    res?.data && res?.data?.list && res?.data?.list?.data ? res?.data?.list?.data : []
                );
                setTotalRows(res?.data?.list?.total);
                if (res.status === 200) {
                  setLoading(false);
                }
              } catch (error) {
                if (!axios.isCancel(error)) {
                  console.error(error);
                  setLoading(false);
                }
              }
        };
        getData();

        return () => {
            cancelTokenSources.forEach(source => {
              source.cancel('Component unmounted');
            });
        };
    }, [
        tableState,
        // totalRows,
        search,
    ]);
    
    const columns = useMemo(() => [
        // {
        //     name: "Id",
        //     selector: "id",
        //     sortField: "id",
        //     sortable: true,
        //     cell: (row) => row?.id,
        // },
        {
            name: "Notifications",
            selector: "name",
            sortField: "name",
            sortable: true,
            cell: (row) => (
                <span
                    className="as-text-blue curser feature-name"
                    title={row.name}
                //   to={`/courseAdministration/articulation/open-articulation-registration/open/${row.id}?description=${row.description}`}
                >
                    <span className="textLimit100">{row?.name}</span>
                </span>
            ),
        },
    ]);

    const exportData = (fileType, fileName) => {
        let data;
        const header = ["Notifications"];
        const details = {
            uniqueProcessId: id,
            page: tableState.page,
            limit: tableState.perPage,
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            search: search,
            exportStatus: "true",
        };
        GetAllCopiedNotificationsCancelToken(details)
            .then((res) => {
                data = res?.data && res?.data?.list?.length ? res?.data?.list : [];
                data = data?.map((row) => ({
                    ...row,
                    // "Id": row.id ? row.id : "-",
                    "Notifications": row?.name ? row?.name : "-",
                }));
                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], {
                        type: "text/csv;charset=utf-8,",
                    });

                    const blobURL = window.URL.createObjectURL(blob);

                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    setTimeout(() => {
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.map((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        header,
                    });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);

                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data.map((row) => {
                        return [
                            // row["Id"],
                            row["Notifications"],
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);

                    return false;
                }
            })
            .catch((error) => {
                console.log("error =-=--> ", error);
                setLoading(false);
            });
    };

    return (
        <DataTableComponent
            data={studentList}
            loading={loading}
            state={tableState}
            setState={setTableState}
            setSearch={setSearch}
            totalRows={totalRows}
            columns={columns}
            exportFunction={exportData}
            exportFileName={"All_Copied_Notifications"}
        />
    );
};

export default AllCopiedNotifications;
