import { useEffect, useState } from "react"

const useDebounce = (input, delay) =>{
    const [debounceValue, setDebounceValue] = useState(input);

    useEffect(()=>{
        const callbackHandler = setTimeout(()=>{
            setDebounceValue(input);
        },delay)

        return () =>{
            clearTimeout(callbackHandler);
        }

    },[input, delay])


    return debounceValue;

}

export default useDebounce;