import { useFormik } from "formik";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { DeleteCertificate, MatricCertificate } from "../../../../../services/StudentsSettingServices";
import { IMAGE_URL } from "../../../../../utils/Constants";
import PermissionsGate from "../../../../../utils/permissionGate";
import { TrimText } from "../../../../common/TrimText";

function ConfirmationLetterBlock({ studentData, loading, setUpdateRow, updateRow}) {
  const { id } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [idFile, setIdFile] = useState();
  const [idFileName, setIdFileName] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      IDs: studentData ? studentData : "",
    },
    validationSchema: Yup.object({
      Ids: Yup.string().required("File is required"),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      //   TODO API
    },
  });

  const handleDelete = (certId,tbname) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        DeleteCertificate(id, certId, "confirmation_letter",tbname)
          .then((res) => {
            Swal.fire("Deleted!", "File Deleted.", "success");
            setUpdateRow(!updateRow);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              setUpdateRow(!updateRow);
              Swal.fire("", `Unable To Delete.`, "warning");
            }
          });

        // Swal.fire("Deleted!", "File has been deleted.", "success");
      }
    });
  };

  const handleFileChange = (e) => {
    setDisabled(true);
    if (e.target.files.length) {
      formik.setFieldValue("IDs", [...formik.values.IDs, { url: e.target.files[0].name, student: id, id: e.target.files[0].name, type: "personal_doc" }]);
      setIdFile(e.target.files[0]);
      setIdFileName(e.target.files[0].name);
      const formData = new FormData();
      formData.append("student", id);
      formData.append("type", "confirmation_letter");
      formData.append("from_admin", 1);
      formData.append("file_input", e.target.files[0]);
      MatricCertificate(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "File Added successfully",
          });
          setDisabled(false);
          setUpdateRow(!updateRow);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err?.response?.data?.message,
          });
          setUpdateRow(!updateRow);
        });
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Confirmation Letters</div>
        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group-blk mb-3">
              <label>Upload File *</label>
              <div className="form-group atttach-file m-0">
                <label className={formik.errors.IDs && formik.touched.IDs && "file-req is-invalid"}>
                  <i className="fal fa-paperclip"></i>
                  <span>Add Attachment</span>
                  <PermissionsGate errorProps={{ disabled: true }} scopes={["spiedit"]}>
                    <input
                      type="file"
                      className="form-control  form-control-aatch-file"
                      disabled={disabled}
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                  </PermissionsGate>
                </label>
              </div>
            </div>
          </div>
          {studentData && studentData.length ? (
            <div className="col-md-4">
              <div className="form-group-blk mb-3">
                <div className="files-list-item frm-group">
                  <label>List Of Documents </label>
                  <ul className="list-unstyled attached-file-ul m-0">
                    {studentData.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <li>
                            <a href={IMAGE_URL + "/" + item.url.replace("/home/myaie/public_html/", "").replace("public/", "")} target="_blank" title={item.url.split("/").reverse()[0]}>
                              <i className="fal fa-file"></i>
                              &nbsp;{TrimText(item.url.split("/").reverse()[0])}&nbsp;
                            </a>
                            <PermissionsGate errorProps={{ disabled: true }} scopes={["spidelete"]}>
                              <button className="btn btn-danger" onClick={() => handleDelete(item.id,item.tbname)} type={"button"}>
                                <i className="fal fa-trash-alt"></i>
                              </button>
                            </PermissionsGate>
                          </li>
                          <p>{item.logMsg}</p>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* <div className="form-group form-group-save-cancel">
          <button className="btn btn-save btn-success" type="submit" title="Save" disabled={disabled || !formik.dirty || isLoaded}>
            {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save
          </button>
          <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()}>
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {formik.touched.Email && formik.errors.Email ? <div className="invalid-feedback d-block">{formik.errors.Email}</div> : null}
          {formik.touched.Password && formik.errors.Password ? <div className="invalid-feedback d-block">{formik.errors.Password}</div> : null}
          {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword ? <div className="invalid-feedback d-block">{formik.errors.ConfirmPassword}</div> : null}
        </div> */}
      </form>
    </div>
  );
}

export default ConfirmationLetterBlock;
