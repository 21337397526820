import React,{useState, useEffect} from "react";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import Tabs from "../Tabs";
import { getCurrentTimeHomePage } from "../../common/Helper";
import DataTable from "react-data-table-component";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { AddAssembliesResources, GetAssemblyResourceDetail, EditAssemblyResourceDetail, GetListOfItemsResources } from "../../../services/CourseService";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { GetListOfResources, DeleteAssemblyResource } from "../../../services/CourseService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import {ResourceType, ResourceTypeIcon} from '../../systemadministration/usermanagement/CheckRole'

const SubCategoryDetails = () => {



  const { tab, subType, id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const selectedTab = location.pathname;

  const [resourceDetail, setResourceDetail] = useState("")
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBlock, setSelectedBlock] = useState("");
  const [deleteRow, setDeleteRow] = useState(false);
  const [search, setSearch] = useState("");


  useEffect(() => {
    // GetListOfAssembliesFilters().then((res) => {
    //   let x = {value: "",label: ""};
    //   x = res.data.item_version.map((e) => ({...x, value: `${e.title}`, label :`${e.title}` }))
    //   setVersionList(res?.data?.item_version);
    // })
    //   .catch((err) => console.log(err)); 

    subType !== "add"
      ? GetAssemblyResourceDetail({item_id: id}).then((res) => {
        setResourceDetail(res?.data?.resouce_detail);
        })
      : setResourceDetail("");
  }, []);


  useEffect(() => {
    if(subType !== "add"){
      GetListOfItemsResources(id).then((res) => {
        setResources(res?.data?.assemblies_item_list?.data);
        setLoading(false);
      })
    }
    
  }, [deleteRow]);


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      item_name: resourceDetail?.item_name,
      item_desc: resourceDetail?.item_desc,
    },
    validationSchema: Yup.object({
      item_name: Yup.string().required("Name is required"),
      item_desc: Yup.string().required("Description is required"),
    }),
    onSubmit: (values) => {
      values.parent_id = 0;
      values.item_type = 1;
       if(subType == "add" || subType == "duplicate"){
      AddAssembliesResources(values)
      .then((res) => {
        // setAssemblyID(res?.data?.inserted_record);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message}`,
        });
        // history.push("/content/resource-pool")
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
    } else{
      values.item_id = id
      EditAssemblyResourceDetail(values)
      .then((res) => {
        // setAssemblyID(res?.data?.inserted_record);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message}`,
        });
        // history.push("/content/resource-pool")
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
    }
    },
  });

   

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };



  const handleDragEnd = (e) => {

    if (!e.destination) return;

    // let tempData = Array.from(users);
    // let [source_data] = tempData.splice(e.source.index, 1);
    // tempData.splice(e.destination.index, 0, source_data);

    const sourceIndex = e.source.index;
    const destIndex = e.destination.index;

    if (e.type === "outer") {
      let tempData = reorder(resources, sourceIndex, destIndex);
      setResources(tempData);
    }

    if (e.type === "inner") {
      const element = e.draggableId.split("+");
      let newarray = resources[element[0]].sub_items;
      let tempdata = reorder(newarray, sourceIndex, destIndex);

      const newUsers = JSON.parse(JSON.stringify(resources));
      newUsers[element[0]].sub_items = tempdata;

      setResources(newUsers);

      // change the index of dummy array of user
      // [a[3], a[4]] = [a[4], a[3]]
      // [a[e.source.index],a[e.destination.index]] = [a[e.destination.index],a[e.source.index]]
    }
  };


  const handleBlock = (i) => {
    if(selectedBlock == "lowerBlock-"+i) {
      setSelectedBlock("");
    } else {
      setSelectedBlock("lowerBlock-"+i);
    }
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAssemblyResource({ item_id: id }).then((res) => setDeleteRow(!deleteRow));
        Swal.fire("Deleted!", "Your resource has been deleted.", "success");
      }
    });
  };

  // console.log("ResourceType(resource?.item_type)", ResourceType(resource?.item_type))
  return (
    <>

                <div className="card card-profile-info-card mb-30">
                  <div className="card-body">
                    <div className="new-card-header">
                      <div className="card-header">Resource Details</div>
                    </div>

                    <div className="card-body-inr card-body-info"></div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group-blk mb-3">
                            <label>Name</label>
                            <input
                              type="text"
                              className={"form-control" + (formik.errors.item_name && formik.touched.item_name ? " is-invalid" : "")}
                              placeholder="Enter Name"
                              name="item_name"
                              value={formik.values.item_name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group-blk mb-3">
                            <label>Description</label>

                            <input
                              type="text"
                              className={"form-control" + (formik.errors.item_desc && formik.touched.item_desc ? " is-invalid" : "")}
                              placeholder="Enter Description"
                              name="item_desc"
                              value={formik.values.item_desc}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group form-group-save-cancel mt-4">
                          <button className="btn btn-save btn-success" type="submit" title="Save">
                            <i className="fal fa-save"></i>Save
                          </button>

                          <button className="btn btn-close btn-danger" onClick={()=>{history.goBack()}} type="button" title="Cancel">
                            <i className="fal fa-times"></i>Cancel
                          </button>
                        </div>
                    </form>
                    
                  </div>
                </div>
    </>
    
  );
};

export default SubCategoryDetails;
