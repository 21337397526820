import React, { useState, useMemo, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { GetListOfRole, GetListOfRoleCancelToken } from "../../../services/UserService";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const Roles = ({ openRoles, openRoleName }) => {
    const { roleName } = useParams();

    const [search, setSearch] = useState("");
    const [roles, setRoles] = useState("");
    const history = useHistory();

    const [loading, setloading] = useState(true);

    useEffect(() => {
        handleTableScroll()
      }, [loading])


      useEffect(() => {
        const dropdownSource = axios.CancelToken.source();
      
        const getRoles = async () => {
          try {
            const data = await GetListOfRoleCancelToken(dropdownSource.token);
            setRoles(data.data ? data.data : []);
            if(data.status == 200){
                setloading(false);
            }
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.error(error);
            }
          }
        };
      
        getRoles();
      
        return () => {
          dropdownSource.cancel('Component unmounted');
        };
      }, []);

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setSearch(value);
    };

    const dataToRender = () => {
        let updatedData = [];
        let allData = roles;
        if (search.length) {
            let role_name = allData.filter((item) => {
                let includes = item.role_name.toString().toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    // console.log("role_name: ", includes);
                    return includes;
                } else return null;
            });
            let role_count = allData.filter((item) => {
                let includes = item.count.toString().toLowerCase().includes(search.toString().toLowerCase());
                if (includes) {
                    // console.log("role_name: ", includes);
                    return includes;
                } else return null;
            });
            let data = [...role_name, ...role_count];
            let unique = [...new Set(data)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (search.length) {
            return updatedData;
        } else {
            return roles;
        }
    };

    const openRolePermissions = useCallback(
        (id, name) => {
            openRoles(id);
            openRoleName(name);
        },
        [openRoles, openRoleName]
    );

    const columns = useMemo(() => [
        // {
        //     name: "Sr.No",
        //     selector: "index",
        //     sortable: false,
        //     maxWidth: "100px",
        //     // justifyContent: "left",
        //     cell: (row, index) => (
        //         <b>
        //             <span>#{++index}</span>
        //         </b>
        //     ),
        // },
        {
            name: "Role",
            selector: "role_name",
            sortable: true,
            wrap: true,
            maxWidth: "250px",
            cell: (row) =>
                row.role_name ? <Link className="as-text-blue curser" to={`/systemAdministration/permissionManagement/open/new/${row.role_name}/${row.role_id}`}>{row.role_name}</Link> : (
                    <p className="cat">
                        <i className="fas fa-circle mr-1"></i> N/A
                    </p>
                ),
        },
        {
            name: "#Users",
            selector: "count",
            // minWidth: "250px",
            sortable: true,
            cell: (row) =>
                row.count ? <Link className="as-text-blue curser" to={`/systemAdministration/permissionManagement/open/userInRole/${row.role_name}/${row.role_id}`}>{row.count}</Link> : (<p className="cat"><i className="fas fa-circle mr-1"></i> N/A</p>),
        },
        {
            name: "Action",
            selector: "",
            // maxWidth: "120px",
            className: "permission-tabe-last-cell",
            cell: (row) => (
                <div className="assessment-08">
                    <div className="as-buttons" title="Open">
                        <Link className="btn btn-sm btn-primary rounded-circle" to={`/systemAdministration/permissionManagement/open/php/${row.role_name}/${row.role_id}`}>
                            <i className="fal fa-folder-open"></i>
                        </Link>
                    </div>
                </div>
            ),
        },
    ]);

    const userManagement = (name) => {
        let obj = {};
        obj[name] = true;
        history.push("/systemAdministration/userManagement/table", { role_name: obj, name: name });
    }

    const resetFilter = () => {
        // setStatus([]);
        // setSearchStatusCheck({});
        // setRole([]);
        // setSearchRoleCheck({});
        // setCompany([]);
        // setSearchCompanyCheck({});
        // setLocation([]);
        // setSearchLocationCheck({});
        setSearch("");
    };

    /**
     * Function to export the table data in pdf, csv, xlsx format
     *
     * @param {*} fileType
     * @param {*} fileName
     * @returns
     */
    const exportData = (fileType, fileName) => {
        let data = dataToRender();
        const header = ["No", "Role", "Users"];

        data = data?.map((row, index) => ({
            ...row,
            No: ++index,
            Role: row.role_name,
            Users: row.role_id,
        }));

        // console.log("data ----> ", data);

        if (fileType === "csv") {
            // console.log("dataaaaaa ---------> ", data);
            // return
            const csvString = Papa.unparse({ fields: header, data });
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            // Create new tag for download file
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
            anchor.click();

            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                URL.revokeObjectURL(blobURL);
            }, 1000);
        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.map((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);

            // Returning false as downloading of file is already taken care of
            return false;
        }
        if (fileType === "pdf") {
            const compatibleData = data.map((row) => {
                return [row.No, row.Role, row.Users];
            });
            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                    minCellHeight: 10,
                    minCellWidth: 5,
                    halign: "left",
                    // valign: "center",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }
    };

    return (
        <div className="filter-search-icon card card-table-custom roles-table">
            <div className="search-filter-div">
                <div className="search-filter-div-left">
                    <div className="system-administration-table table-responsive">
                        <div className="table-responsive-div">
                            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                    <div id="assessment-table-main_filter" className="dataTables_filter">
                                        <label>
                                            <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                                        </label>
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                                <i className="fal fa-filter"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="filter-button-group">
                            <div className="reset-btn-group">
                                <div className="button-reset dropdown-comman">
                                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                                        <i className="fal fa-redo"></i>Reset
                                    </button>
                                </div>

                                <div className="files-export-group">
                                    <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Roles"); }} title="Export spreadsheet" >
                                        <i className="fal fa-file-excel icon"></i>
                                    </button>
                                    <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Roles"); }} title="Export CSV" >
                                        <i className="fal fa-file-csv icon"></i>
                                    </button>
                                    <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Roles"); }} title="Export PDF" >
                                        <i className="fal fa-file-pdf icon"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {roles.length == 0 && <SkeletonTicketList />} */}
            {loading ? <SkeletonTicketList />
                : <DataTable
                    data={dataToRender()}
                    columns={columns}
                    pagination={true}
                    defaultSortField="role_name"
                    defaultSortAsc={true}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                />
            }
        </div>
    );
};

export default Roles;
