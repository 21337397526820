import React, { useState, useMemo, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import 'jspdf-autotable';
import * as Yup from 'yup';
import Select from 'react-select';
import { IMAGE_URL, TABLE_ROWS_PER_PAGE } from '../../../../utils/Constants';
import Str from '../../../common/Str';
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import {
  DeleteStudentCertificate,
  GetCertificateLinkedList,
  SaveStudentSingleCertificate,
  StudentCertificatNotification,
  GetCertificateDetails,
  GetCertificateDetailCancelToken,
} from '../../../../services/TickectService';
import { useHistory, useParams } from 'react-router-dom';
import $ from 'jquery';
import { myCompare } from '../../../common/MyCompare';
import PermissionsGate from '../../../../utils/permissionGate';
import { useSelector } from 'react-redux';
import hasPermission from '../../../../utils/hasMultiplePermission';
import Tablefilter from '../../../common/Tablefilter';
import { RenderCertificateType } from '../../../../utils/CommonGroupingItem';
import PopupComponent from '../../../common/PopupComponent';
import 'jspdf-autotable';
import JsPDF from 'jspdf';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
import { handleTableScroll } from '../../../../utils/commonFunction';
import axios from 'axios';

function Certificates() {
  const [data, setData] = useState({});
  const [studentData, setStudentData] = useState([]);
  const certificateTypeOptions = [
    { label: "Programme", value: "qualification", alt:"qualification" },
    { label: "Course", value: "course", alt: "subject" },
    { label: "Not Applicable", value: "not_applicable", alt:"not_applicable" },
  ]
  const [certificateType, setCertificateType] = useState([]);
  const [certificateLinked, setCertificateLinked] = useState([]);
  const [loading, setloading] = useState(true);
  const [selectedType, setSelectedType] = useState('');
  const [refreshRow, setRefreshRow] = useState(false);
  const [sortkey, setSortKey] = useState('certificate_name');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [type, setType] = useState({ arr: [], checkObj: {} });

  const { id } = useParams();

  const history = useHistory();

  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [emailLog, setEmailLog] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [saveLoad, setSaveLoad] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    let response = hasPermission({
      scopes: ['scview'],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push('/noaccess');
    }
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getProgramsList = async () => {
      setloading(true);

      cancelTokenSources.forEach((source) => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetCertificateDetailCancelToken(id, source.token);
        if (res.status === 200) {
          setStudentData(res.data?.certificate);
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };

    getProgramsList();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel('Component unmounted');
      });
    };
  }, [refreshRow]);

  const sortName = (rowA, rowB) => {
    const name1 = rowA.certificate_name
      ? rowA.certificate_name.toString().toLowerCase().trim()
      : '';
    const name2 = rowB.certificate_name
      ? rowB.certificate_name.toString().toLowerCase().trim()
      : '';
    return name1.localeCompare(name2);
  };

  const sortCert = (rowA, rowB) => {
    const name1 = rowA.certificate
      ? rowA.certificate.toString().toLowerCase().trim()
      : '';
    const name2 = rowB.certificate
      ? rowB.certificate.toString().toLowerCase().trim()
      : '';
    return name1.localeCompare(name2);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data.id || 0,
      Name: data.certificate_name || '',
      File: data.certificate ? data.certificate : '',
      File_URL: data.certificate ? data.certificate : '',
      Type: data.certificate_type || '',
      LinkedCertificate: data.certificate_linked || '',
      NonLinkedCertificate: data.certificate_not_linked || '',
    },
    validationSchema: Yup.object({
      Name: Yup.string()
        .required('Certificate Name is required')
        .max(255, "Certificate name can't be more than 255 characters."),
      File: Yup.mixed().required('File is required'),
      Type: Yup.string().required('Type is required').trim(),
    }),
    onSubmit: (values, { resetForm }) => {
      setPostLoading(true);
      let formData = new FormData();
      formData.append('uid', id);
      formData.append('id', values.id);
      formData.append('certificate_name', values.Name);
      formData.append('certificate_type', values.Type);
      formData.append('certificate_linked', values.LinkedCertificate);
      formData.append('certificate_not_linked', values.NonLinkedCertificate);
      formData.append('file', values.File);
      SaveStudentSingleCertificate(formData)
        .then((res) => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Added successfully',
          });
          setRefreshRow(!refreshRow);
          resetForm();
          setData({});
          $('#addForm').modal('hide');
          $('#editModal').modal('hide');
          setPostLoading(false);
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `${err?.response?.data?.data}`,
          });
          setRefreshRow(!refreshRow);
          resetForm();
          setData({});
          setPostLoading(false);
        });
      // setDisabled(true);
      //   let formData = new FormData();
    },
  });

  const handleDelete = (id, certificate, studentId, courseId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteStudentCertificate(id, certificate, studentId, courseId)
          .then((data) => {
            setRefreshRow((prevVal) => !prevVal);
            Swal.fire('Deleted!', 'Your record has been deleted.', 'success');
          })
          .catch((error) => {
            setRefreshRow((prevVal) => !prevVal);
            console.log('error while deleting >>>>> ', error);
            Swal.fire('Not Deleted!', error.response?.data?.message ?? 'Record is not deleted.', 'error');
          });
      }
    });
  };

  const GetCertificateLinked = (type) => {
    GetCertificateLinkedList(
      id,
      type.value == 'course' ? 'subject' : type.value
    ).then((res) => {
      setCertificateLinked(res?.data?.certificate_linked);
    });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = studentData;
    if (search.length) {
      let tempName = allData.filter((item) => {
        const includes =
          item.certificate_name &&
          item.certificate_name
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else {
          return null;
        }
      });
      let tempQual = allData.filter((item) => {
        const includes =
          item.qualification_name &&
          item.qualification_name
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else {
          return null;
        }
      });
      let tempType = allData.filter((item) => {
        const includes = item.certificate
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else {
          return null;
        }
      });

      let tempData = [...tempName, ...tempType, ...tempQual];
      let unique = [...new Set(tempData)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (type.arr.length) {
      let tempData = updatedData;
      let tempResult = tempData.filter((item) => {
        const startsWith = type.arr.find((post) => {
          console.log(post);
          if (post.toString() === 'course') {
            if (
              item.certificate_type.toString() === 'course' ||
              item.certificate_type.toString() == 'subject'
            ) {
              return item;
            }
          } else {
            if (post.toString() === item.certificate_type.toString()) {
              return item;
            }
          }
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    return updatedData;
  };

  const handledoc = (e) => {
    // setSelectedDocument(e.target.files[0]);
    formik.setFieldValue('File', e.target.files[0]);
  };

  const formikSendEmail = useFormik({
    enableReinitialize: true,
    initialValues: {
      EmailNotification: false,
    },
    validationSchema: Yup.object({
      EmailNotification: Yup.boolean().oneOf(
        [true],
        'Please Check Email Notification'
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      const params = { student_id: id };
      StudentCertificatNotification(params)
        .then((res) => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Send successfully',
          });
          setRefresh(!refresh);
        })
        .catch((err) => console.log('err', err));
    },
  });

  const columns = useMemo(
    () => [
      {
        name: 'Certificate Name',
        selector: 'certificate_name',
        sortable: true,
        sortFunction: sortName,
        cell: (row) =>
          row?.certificate_name ? (
            <span
              className="overflow-ellipsis"
              onClick={() => {
                handleEdit(row);
              }}
              title={row?.certificate_name}
            >
              <p className="textLimit100  as-text-blue curser">
                {row?.certificate_name}
              </p>
            </span>
          ) : (
            '-'
          ),
      },
      {
        name: 'Related To',
        selector: 'certificate_type',
        sortable: false,
        cell: (row) =>
          row?.certificate_type
            ? RenderCertificateType(row?.certificate_type).html
            : '-',
      },
      {
        name: 'Course/Programme',
        selector: 'qualification_name',
        sortable: false,
        cell: (row) =>
          row?.qualification_name ? (
            <Link
              className="as-text-blue curser"
              target="_blank"
              title={row.qualification_name}
              to={{
                pathname:
                  row.certificate_type === 'qualification'
                    ? `/courseAdministration/Programmes/programme/open/${row.certificate_linked}/details`
                    : row.certificate_type === 'course' ||
                      row.certificate_type === 'subject'
                    ? `/courseAdministration/coursesdetails/${row.certificate_linked}/dashboard/show`
                    : '',
              }}
            >
              {row?.qualification_name}
            </Link>
          ) : (
            '-'
          ),
      },
      {
        name: 'Attachment',
        selector: 'certificate',
        sortable: true,
        sortFunction: sortCert,
        cell: (row) =>
          row.certificate ? (
            <a
              title={row.certificate?.substring(
                row.certificate?.lastIndexOf('/') + 1
              )}
              className="as-text-blue curser"
              download
              href={
                IMAGE_URL +
                '/' +
                row.certificate
                  .replace('/home/myaie/public_html/', '')
                  .replace('public/', '')
              }
              target="_blank"
            >
              {row.certificate?.substring(
                row.certificate?.lastIndexOf('/') + 1
              )}
            </a>
          ) : (
            '-'
          ),
      },
      {
        name: 'Actions',
        maxWidth: '50px',
        cell: (row) => (
          <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons d-flex">
              <PermissionsGate
                scopes={['scedit']}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  onClick={() => {
                    handleEdit(row);
                  }}
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              </PermissionsGate>
              <PermissionsGate
                scopes={['scdelete']}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-danger rounded-circle"
                  title="Delete"
                  onClick={() =>
                    handleDelete(
                      row.id,
                      row.certificate,
                      row.student,
                      Number(row.certificate_linked)
                    )
                  }
                >
                  <i className="fal fa-trash-alt"></i>
                </button>
              </PermissionsGate>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  const handleEdit = (data) => {
    GetCertificateDetails(data.id)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.certificate);
          setCertificateType(res.data.certificate_type)
          GetCertificateLinkedList(
            id,
            res.data.certificate.certificate_type == 'course'
              ? 'subject'
              : res.data.certificate.certificate_type
          ).then((res) => {
            setCertificateLinked(res?.data?.certificate_linked);
          });
          $('#editModal').modal(
            { backdrop: 'static', keyboard: false },
            'show'
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilter = (e, typeName = '') => {
    const value = e.target.value;
    if (typeName === 'search') {
      setSearch(value);
    }
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === 'asc' ? 'ASC' : 'DESC');
  };

  const resetFilter = () => {
    setSearch('');
    setType({ arr: [], checkObj: {} });
  };

  const exportData = async (fileType, fileName) => {
    let data = dataToRender();

    const header = [
      'Certificate Name',
      'Related To',
      'Course/Programme',
      'Attachment',
    ];

    Swal.fire({
      title: 'File downloading',
      onOpen: function () {
        Swal.showLoading();
      },
    });
    data = data.map((row) => ({
      'Certificate Name': row?.certificate_name ? row?.certificate_name : '-',
      'Related To': row?.certificate_type
        ? RenderCertificateType(row?.certificate_type).text
        : '-',
      'Course/Programme': row?.qualification_name
        ? row?.qualification_name
        : '-',
      Attachment: row?.certificate
        ? row?.certificate?.substring(row?.certificate?.lastIndexOf('/') + 1)
        : '-',
    }));
    if (fileType === 'csv') {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8,' });

      const blobURL = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        'text/csv',
        anchor.download,
        anchor.href,
      ].join(':');
      anchor.click();

      setTimeout(() => {
        URL.revokeObjectURL(blobURL);
      }, 1000);
      Swal.close();
    } else if (fileType === 'xlsx') {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, 'React Table Data');
      XLSX.writeFile(wb, `${fileName}.xlsx`);
      Swal.close();
      return false;
    }
    if (fileType === 'pdf') {
      const compatibleData = data.map((row) => {
        return [
          row['Certificate Name'],
          row['Related To'],
          row['Course/Programme'],
          row['Attachment'],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: 'left',
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);
      Swal.close();
      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="card mb-4">
        <div className="edit-icon new-card-header">
          <div className="card-header">Notify By</div>
        </div>
        <form onSubmit={formikSendEmail.handleSubmit}>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="form-group-blk mb-3">
                <div
                  className="custom-check custom-control custom-checkbox"
                  title="Email Notification"
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="EmailNotification"
                    name="EmailNotification"
                    checked={formikSendEmail.values.EmailNotification}
                    onChange={(e) =>
                      formikSendEmail.setFieldValue(
                        'EmailNotification',
                        e.target.checked
                      )
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="EmailNotification"
                  >
                    Email Notification
                  </label>
                </div>
                <p>{emailLog}</p>
              </div>
              {formikSendEmail.touched.EmailNotification &&
              formikSendEmail.errors.EmailNotification ? (
                <div className="invalid-feedback d-block">
                  {formikSendEmail.errors.EmailNotification}
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-icon-group">
                <PermissionsGate
                  errorProps={{ disabled: true }}
                  scopes={['sgiedit']}
                >
                  <button
                    className="btn btn-save btn-primary"
                    type="submit"
                    title="Send Manual Notification"
                    disabled={loading}
                  >
                    <i className="fa fa-paper-plane"></i>
                    Send Manual Notification
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="card custom-table-div filter-search-icon card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={(e) => handleFilter(e, 'search')}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Related To"
                      optionArr={certificateType}
                      state={type}
                      setState={setType}
                      renderLabelFunction={RenderCertificateType}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData('xlsx', 'Student Certificate List');
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData('csv', 'Student Certificate List');
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData('pdf', 'Student Certificate List');
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman ml-auto">
                <PermissionsGate
                  scopes={['scadd']}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#addForm"
                    title="Add Certificate"
                  >
                    <i className="fal fa-plus"></i>Add Certificate
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          data={dataToRender()}
          defaultSortField={sortkey}
          defaultSortAsc={true}
          columns={columns}
          pagination
          noDataComponent={Str.noRecord}
          progressPending={loading}
          paginationDefaultPage={page}
          progressComponent={<SkeletonTicketList />}
          onSort={handleSort}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
      <div
        className="topic-add-modal modal fade"
        data-backdrop="static"
        id="addForm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fal fa-file-alt"></i> Add Certificate
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      formik.resetForm();
                      setData({});
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="t p-30px">
                  <div className="topic-add-modal-content">
                    <div className="row center-label">
                      <div className="col-lg-12">
                        <div className="form-group-blk mb-3">
                          <label>Add Certificate *</label>
                          <div className="form-group atttach-file m-0">
                            <label
                              className={
                                formik.errors.File &&
                                formik.touched.File &&
                                'file-req is-invalid'
                              }
                            >
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                type="file"
                                className="form-control  form-control-aatch-file"
                                onChange={handledoc}
                                accept={[
                                  'image/jpg',
                                  'image/jpeg',
                                  'image/png',
                                  'application/pdf',
                                ]}
                              />
                            </label>
                          </div>
                          {formik.values.File && (
                            <>
                              <div className="frm-group">
                                <ul className="list-unstyled attached-file-ul m-0">
                                  <li>
                                    <a
                                      href={IMAGE_URL + formik.values.File}
                                      target="_blank"
                                    >
                                      <i className="fal fa-file"></i>
                                      &nbsp;{formik.values.File.name}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group-blk mb-3">
                          <label>Certificate Name *</label>
                          <input
                            className={
                              'form-control ' +
                              (formik.errors.Name && formik.touched.Name
                                ? ' is-invalid'
                                : '')
                            }
                            name="Name"
                            value={formik.values.Name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder={'Enter Certificate Name'}
                            title="Certificate Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group-blk mb-3" title="Related to">
                          <label>Related to *</label>
                          <Select
                            className={
                              'form-control custom-select-box ' +
                              (formik.errors.Type && formik.touched.Type
                                ? ' is-invalid'
                                : '')
                            }
                            name="Type"
                            value={certificateTypeOptions.filter((value) => {
                              return value.value == formik.values.Type;
                            })}
                            onChange={(value) => {
                              formik.setFieldValue('Type', value.value);
                              // setSelectedType(value.label);
                              GetCertificateLinked(value);
                              formik.setFieldValue('LinkedCertificate', '');
                            }}
                            onBlur={formik.handleBlur}
                            options={certificateTypeOptions.sort(myCompare)}
                            maxMenuHeight={175}
                            placeholder={
                              selectedType ? selectedType : 'Related to'
                            }
                            title="Related to"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div
                          className="form-group-blk mb-3"
                          title="Select Linked Certificate"
                        >
                          <label>Select Linked Certificate</label>
                          <Select
                            className={
                              'form-control custom-select-box ' +
                              (formik.errors.LinkedCertificate &&
                              formik.touched.LinkedCertificate
                                ? ' is-invalid'
                                : '')
                            }
                            title="Select Linked Certificate"
                            name="LinkedCertificate"
                            value={certificateLinked.filter((option) => {
                              return (
                                option.value == formik.values.LinkedCertificate
                              );
                            })}
                            onChange={(value) => {
                              if (value) {
                                formik.setFieldValue(
                                  'LinkedCertificate',
                                  value.value
                                );
                              } else {
                                formik.setFieldValue('LinkedCertificate', '');
                              }
                            }}
                            onBlur={formik.handleBlur}
                            options={certificateLinked.sort(myCompare)}
                            maxMenuHeight={175}
                            placeholder={
                              formik.values.LinkedCertificate
                                ? formik.values.LinkedCertificate
                                : 'Select Linked Certificate'
                            }
                            isClearable
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div
                          className="form-group-blk mb-3"
                          title="Non Linked Certificate"
                        >
                          <label>Non Linked Certificate</label>
                          <input
                            type="text"
                            className={
                              'form-control form-control-aatch-file ' +
                              (formik.errors.NonLinkedCertificate &&
                              formik.touched.NonLinkedCertificate
                                ? ' is-invalid'
                                : '')
                            }
                            placeholder="Enter Non Linked Certificate"
                            value={formik.values.NonLinkedCertificate}
                            onChange={formik.handleChange}
                            name="NonLinkedCertificate"
                            title="Non Linked Certificate"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-group-save-cancel mt-4">
                    <PermissionsGate
                      scopes={['scadd']}
                      errorProps={{ disabled: true }}
                    >
                      <button
                        type="submit"
                        className="btn btn-save btn-success"
                        title="Post"
                      >
                        {postLoading ? (
                          <i className="fas fa-cog fa-spin"></i>
                        ) : (
                          <i className="fal fa-save"></i>
                        )}
                        Post
                      </button>
                    </PermissionsGate>
                    <button
                      type="reset"
                      title="Close"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={() => {
                        formik.resetForm();
                        setData({});
                      }}
                    >
                      <i className="fal fa-times"></i>
                      Close
                    </button>
                    {Object.keys(formik.values).map((key, ind) => {
                      if (formik.touched[key] && formik.errors[key]) {
                        return (
                          <div className="invalid-feedback d-block" key={ind}>
                            {formik.errors[key]}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <PopupComponent
        id="editModal"
        type="form"
        closeFunction={() => {
          formik.resetForm();
          setData({});
        }}
        header={{ iconName: 'fa-edit', heading: 'Edit Certificate' }}
        inputs={[
          <>
            <div className="col-lg-12">
              <div className="form-group-blk mb-3">
                <label>Add Certificate *</label>
                <div className="form-group atttach-file m-0">
                  <label
                    className={
                      formik.errors.File &&
                      formik.touched.File &&
                      'file-req is-invalid'
                    }
                  >
                    <i className="fal fa-paperclip"></i>
                    <span>Add Attachment</span>
                    <input
                      type="file"
                      className="form-control  form-control-aatch-file"
                      onChange={handledoc}
                      accept={[
                        'image/jpg',
                        'image/jpeg',
                        'image/png',
                        'application/pdf',
                      ]}
                    />
                  </label>
                </div>
                {formik.values.File && (
                  <>
                    <div className="frm-group">
                      <ul className="list-unstyled attached-file-ul m-0">
                        <li>
                          <a
                            href={
                              formik.values.File.name
                                ? IMAGE_URL + formik.values.File
                                : IMAGE_URL +
                                  '/' +
                                  formik.values.File.replace(
                                    '/home/myaie/public_html/',
                                    ''
                                  ).replace('public/', '')
                            }
                            target="_blank"
                          >
                            <i className="fal fa-file"></i>
                            &nbsp;
                            {formik.values.File.name ||
                              formik.values.File.substring(
                                formik.values.File?.lastIndexOf('/') + 1
                              )}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group-blk mb-3">
                <label>Certificate Name *</label>
                <input
                  className={
                    'form-control ' +
                    (formik.errors.Name && formik.touched.Name
                      ? ' is-invalid'
                      : '')
                  }
                  name="Name"
                  value={formik.values.Name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={'Enter Certificate Name'}
                  title="Certificate Name"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group-blk mb-3" title="Related to">
                <label>Related to *</label>
                <Select
                  className={
                    'form-control custom-select-box ' +
                    (formik.errors.Type && formik.touched.Type
                      ? ' is-invalid'
                      : '')
                  }
                  name="Type"
                  value={certificateType.filter((value) => {
                    return value.value == formik.values.Type;
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue('Type', value.value);
                      GetCertificateLinked(value);
                      formik.setFieldValue('LinkedCertificate', '');
                    } else {
                      formik.setFieldValue('Type', '');
                      setCertificateLinked([]);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={certificateType.sort(myCompare)}
                  maxMenuHeight={175}
                  placeholder={selectedType ? selectedType : 'Related to'}
                  title="Related to"
                  isClearable
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className="form-group-blk mb-3"
                title="Select Linked Certificate"
              >
                <label>Select Linked Certificate</label>
                <Select
                  className={
                    'form-control custom-select-box ' +
                    (formik.errors.LinkedCertificate &&
                    formik.touched.LinkedCertificate
                      ? ' is-invalid'
                      : '')
                  }
                  title="Select Linked Certificate"
                  name="LinkedCertificate"
                  value={certificateLinked.filter((option) => {
                    return option.value == formik.values.LinkedCertificate;
                  })}
                  onChange={(value) => {
                    if (value && value.value) {
                      formik.setFieldValue('LinkedCertificate', value.value);
                    } else {
                      formik.setFieldValue('LinkedCertificate', '');
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={certificateLinked.sort(myCompare)}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.LinkedCertificate
                      ? formik.values.LinkedCertificate
                      : 'Select Linked Certificate'
                  }
                  isClearable
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className="form-group-blk mb-3"
                title="Non Linked Certificate"
              >
                <label>Non Linked Certificate</label>
                <input
                  type="text"
                  className={
                    'form-control form-control-aatch-file ' +
                    (formik.errors.NonLinkedCertificate &&
                    formik.touched.NonLinkedCertificate
                      ? ' is-invalid'
                      : '')
                  }
                  placeholder="Enter Non Linked Certificate"
                  value={formik.values.NonLinkedCertificate}
                  onChange={formik.handleChange}
                  name="NonLinkedCertificate"
                  title="Non Linked Certificate"
                />
              </div>
            </div>
          </>,
        ]}
        bottomButton={[
          <PermissionsGate scopes={['scedit']} errorProps={{ disabled: true }}>
            <button
              type="submit"
              className="btn btn-save btn-success"
              title="Post"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {postLoading ? (
                <i className="fas fa-cog fa-spin"></i>
              ) : (
                <i className="fal fa-save"></i>
              )}
              Post
            </button>
          </PermissionsGate>,
          <button
            type="reset"
            title="Close"
            className="btn btn-danger"
            data-dismiss="modal"
            onClick={() => {
              formik.resetForm();
              setData({});
            }}
          >
            <i className="fal fa-times"></i>
            Close
          </button>,
          Object.keys(formik.values).map((key, ind) => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block" key={ind}>
                  {formik.errors[key]}
                </div>
              );
            }
          }),
        ]}
        otherProps={{ secondPopupCss: 'zindexadd' }}
      />
    </div>
  );
}

export default Certificates;
