import React, { useEffect, useMemo, useState } from 'react';
import { TABLE_ROWS_PER_PAGE } from '../../../../utils/Constants';
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import Str from '../../../common/Str';
import { DeleteExamPeriod, GetExamPeriodList } from '../../../../services/ExternalExamServices';
import DataTable from "react-data-table-component";
import PermissionsGate from '../../../../utils/permissionGate';
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import { useHistory, Link } from 'react-router-dom';
import Swal from "sweetalert2";
import hasPermission from '../../../../utils/hasMultiplePermission';
import { useSelector } from 'react-redux';
import axios from 'axios';

const ExamPeriodTable = () => {
    const [search, setSearch] = useState("");
    const [examPeriod, setExamPeriod] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const history = useHistory();
    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
      let response = hasPermission({ scopes: ["eeepview"], permissions: givenPermsisions });
      if (!response) {
        history.push("/noaccess")
      }
    }, [])

    useEffect(()=>{
      const source = axios.CancelToken.source();        
      const examPeriodData = async () =>{
        setLoading(true);        
        try {
          const res = await GetExamPeriodList(source.token);
          if(res.status==200){
            let period = res.data.data;
            setExamPeriod(period);
            setLoading(false)
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
            setLoading(false);
          }
        }        
      }
    
      examPeriodData();
      return () => {
        source.cancel('Component unmounted');    
      };
    },[refresh])

    
    const sortingName = (rowA, rowB) => {
      const name1 = rowA.exam_period.toString();
      const name2 = rowB.exam_period.toString();
      return name1.localeCompare(name2);
  };

    const columns = useMemo(() => [
        {
          name: "Exam Period",
          selector: "exam_period",
          sortable: true,
          wrap: true,
          sortFunction: sortingName,
          cell: (row) =>
            row.exam_period ? (
              <PermissionsGate scopes={["eeepedit"]} RenderError={() => (
                <p>{row?.exam_period.trim() ? <span className="feature-exam_period"> <span className="textLimit100">{row.exam_period}</span> </span> : "-"}</p>
              )}>
              <Link
                title={row.exam_period}
                to={`/studentAdministration/externalExam/examPeriod/open/${row.id}`}
                className="as-text-blue curser feature-exam_period"
              >
                <span title={row.exam_period} className="textLimit100">{row.exam_period}</span>
              </Link>
              </PermissionsGate>
            ) : (
              "-"
            ),
        },
      
        {
          name: "Actions",
          cell: (row) => (
            <div className="assessment-08">
              <div className="as-buttons">
              <PermissionsGate scopes={["eeepedit"]} RenderError={()=>(
                <button
                disabled
                title="Open"
                className="btn btn-primary rounded-circle"                
              >
                <i className="fal fa-folder-open"></i>
              </button>
              )}>
                <Link
                  title="Open"
                  className="btn btn-primary rounded-circle"
                  to={`/studentAdministration/externalExam/examPeriod/open/${row.id}`}
                >
                  <i className="fal fa-folder-open"></i>
                </Link>
              </PermissionsGate>
              <PermissionsGate scopes={["eeepdelete"]} errorProps={{disabled:true}}>
                <button
                  title="Delete"
                  className="btn btn-danger rounded-circle"
                  onClick={() => handleDelete(row.id)}
                >
                  <i className="fal fa-trash-alt"></i>
                </button>
              </PermissionsGate>
              </div>
            </div>
          ),
        },
    ]);
    const handleDelete=(id)=>{
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
          }).then((result) => {
            if (result.isConfirmed) {
                DeleteExamPeriod({id : id})
                .then((res) => {
                  if(res.status==200){
                    Swal.fire(
                      "Deleted!",
                      "Exam Period has been deleted.",
                      "success"
                    );
                    setRefresh(!refresh);
                  }
                  
                })
                .catch((error) => console.log(error));
            }
          });
    }

    const dataToRender = () => {
        let updatedData = [];
        let allData = examPeriod;
        if (search.length) {
          let tempId = allData.filter((item) => {
            let includes = item.exam_period
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase());
            if (includes) {
              return includes;
            } else return null;
          });
          let tempdata = [...tempId];
          let unique = [...new Set(tempdata)];
    
          updatedData = unique;
        } else {
          updatedData = allData;
        }
    
        if (search.length) {
          return updatedData;
        } else {
          return examPeriod;
        }
    };
    const exportData = (fileType, fileName) => {
        let data, header;
        data = dataToRender();
        header = ["Exam Period"];
        data = data.map((row) => ({
          ...row,
          "Exam Period": row.exam_period ? row.exam_period : "-",
        }));
        // }
    
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
    
          const blobURL = window.URL.createObjectURL(blob);
    
          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();
    
          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });
    
          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
    
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          let compatibleData;
          compatibleData = data.map((row) => {
            return [row["Exam Period"]];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
    
          return false;
        }
    };    
    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setSearch(value);
    };
    const resetFilter = () => {
        setSearch("");
    };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom right-align-action">
    <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <div
                        id="assessment-table-main_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={handleSearchFilter}
                            value={search}
                          />
                        </label>
                        <div className="filter-eff filter-data-btn">
                            <button className="filter-buttons">
                              <i className="fal fa-filter"></i>
                            </button>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="reset-btn-group">
                      <div className="button-reset dropdown-comman">
                        <button
                          className="btn btn-primary"
                          onClick={() => resetFilter()}
                          title="Reset"
                        >
                          <i className="fal fa-redo"></i>Reset
                        </button>
                      </div>
                      <div className="files-export-group">
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData(
                              "xlsx",
                              "Export period",
                            );
                          }}
                          title="Export spreadsheet"
                        >
                          <i className="fal fa-file-excel icon"></i>
                        </button>

                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData(
                              "csv",
                              "Export period",
                            );
                          }}
                          title="Export CSV"
                        >
                          <i className="fal fa-file-csv icon"></i>
                        </button>

                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData(
                              "pdf",
                              "Export period",
                            );
                          }}
                          title="Export PDF"
                        >
                          <i className="fal fa-file-pdf icon"></i>
                        </button>
                      </div>
                  </div>
                </div>
            </div>
          </div>

          
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                    <PermissionsGate scopes={["eeepadd"]} RenderError={()=>(
                      <button
                      className="btn btn-primary"
                      title="Create New"
                      disabled
                    >
                      <i className="fal fa-plus"></i>Create New
                    </button>
                    )}>
                  <Link
                    className="btn btn-primary"
                    title="Create New"
                    to = {`/studentAdministration/externalExam/examPeriod/add`}
                  >
                    <i className="fal fa-plus"></i>Create New
                  </Link>
                  </PermissionsGate>
                  
              </div>
            </div>
          </div>
        </div>

      <DataTable
        data={dataToRender()}
        columns={columns}
        pagination={true}
        progressComponent={<SkeletonTicketList />}
        progressPending={loading}
        noDataComponent={Str.noRecord}
        defaultSortAsc={true}
        defaultSortField={"exam_period"}
        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
      />
    </div>
  </div>
  )
}

export default ExamPeriodTable