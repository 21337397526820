import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { UpdateStudentPersonalInfo } from "../../../../../services/StudentsSettingServices";
import { EMAIL_REGEX, MOBILE_REGEX } from "../../../../../utils/Constants";
import PermissionsGate from "../../../../../utils/permissionGate";

function ContactDetailsBlock({ studentData, loading }) {
  const { id } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Email: studentData ? studentData.email : "",
      Mobile: studentData && studentData.mobile !== null ? studentData.mobile : "",
      Landline: studentData && studentData.phone !== null ? studentData.phone : "",
      AlternateContact: studentData && studentData.a_phone !== null ? studentData.a_phone : "",
    },
    validationSchema: Yup.object({
      Email: Yup.string().required("Email is required").matches(EMAIL_REGEX, "Please Enter a Valid Email").trim(),
      Mobile: Yup.string().matches(MOBILE_REGEX, "Please Enter Valid Mobile Number"),
      Landline: Yup.string().matches(MOBILE_REGEX, "Please Enter Valid Landline Number"),
      AlternateContact: Yup.string().matches(MOBILE_REGEX, "Please Enter Valid Alternate Contact Number"),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      let formData = new FormData();
      formData.append("section_type", "contact_info");
      formData.append("student_id", id);
      formData.append("email", values.Email);
      formData.append("mobile", values.Mobile);
      formData.append("landline", values.Landline);
      formData.append("a_phone", values.AlternateContact);
      UpdateStudentPersonalInfo(formData)
        .then((res) => {
          setDisabled(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
        })
        .catch((err) => {
          setDisabled(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.message,
          });
        });
    },
  });

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Contact Details</div>
        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Email">Email *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="Email"
                className={"form-control" + (formik.errors.Email && formik.touched.Email ? " is-invalid" : "")}
                name="Email"
                placeholder="Enter Email"
                value={formik.values.Email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Email"
              />
              {formik.errors.Email && formik.touched.Email ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Mobile">Mobile </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="Mobile"
                className={"form-control" + (formik.errors.Mobile && formik.touched.Mobile ? " is-invalid" : "")}
                name="Mobile"
                placeholder="Enter Mobile Number"
                value={formik.values.Mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Mobile"
              />
              {formik.errors.Mobile && formik.touched.Mobile ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Landline">Landline </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="Landline"
                className={"form-control" + (formik.errors.Landline && formik.touched.Landline ? " is-invalid" : "")}
                name="Landline"
                placeholder="Enter Landline Number"
                value={formik.values.Landline}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Landline"
              />
              {formik.errors.Landline && formik.touched.Landline ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="AlternateContact">Alternate Mobile Number </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="AlternateContact"
                className={"form-control" + (formik.errors.AlternateContact && formik.touched.AlternateContact ? " is-invalid" : "")}
                name="AlternateContact"
                placeholder="Enter Alternate Mobile Number"
                value={formik.values.AlternateContact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="AlternateContact"
              />
              {formik.errors.AlternateContact && formik.touched.AlternateContact ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group form-group-save-cancel">
          <PermissionsGate errorProps={{ disabled: true }} scopes={["spiedit"]}>
            <button className="btn btn-save btn-success" type="submit" title="Save" disabled={disabled || !formik.dirty || isLoaded}>
              {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
              Save
            </button>
          </PermissionsGate>
          <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => formik.resetForm()}>
            <i className="fal fa-times"></i>
            Cancel
          </button>
          {Object.keys(formik.values).map(key => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block">
                  {formik.errors[key]}
                </div>
              )
            }
          })}
          {/* {formik.touched.Email && formik.errors.Email ? <div className="invalid-feedback d-block">{formik.errors.Email}</div> : null}
          {formik.touched.Mobile && formik.errors.Mobile ? <div className="invalid-feedback d-block">{formik.errors.Mobile}</div> : null}
          {formik.touched.Landline && formik.errors.Landline ? <div className="invalid-feedback d-block">{formik.errors.Landline}</div> : null}
          {formik.touched.AlternateContact && formik.errors.AlternateContact ? <div className="invalid-feedback d-block">{formik.errors.AlternateContact}</div> : null} */}
        </div>
      </form>
    </div>
  );
}

export default ContactDetailsBlock;
