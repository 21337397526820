import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import AuditTrail from "../../StudentAdministration/Students/AuditTrail";
import PopupsPagesOpt from "./PopupsPagesOpt";
import { GetPopupsUpdateList } from "../../../services/PopupsPagesServer";
// This will be shown in merge-all branch

function CreatePopupsPages() {
    const history = useHistory();
    const [updateData, setUpdateData] = useState({});
    const { tab, type, id } = useParams();

    useEffect(() => {
        GetPopupsUpdateList(id)
            .then(res => setUpdateData(res?.data?.Data))
    }, [id])

    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div className={"sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")}>
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                {/* Student <span>List</span> */}
                                                Popup: <span>{type === "open" ? updateData.PopUpName : "Create New"}</span>
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {(type === "auditTrail" || type === "open") && (
                                        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                            <li className="nav-item" onClick={() => history.push(`/website_management/pages/popupspages/table/open/${id}`)}>
                                                <Link
                                                    className={`nav-link ${type === "open" && "active"}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/popupspages/table/open/${id}`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                >
                                                    <i className="fa fa-info-circle"></i>
                                                    Popup Details
                                                </Link>
                                            </li>
                                            <li className="nav-item" onClick={() => history.push(`/website_management/pages/popupspages/table/auditTrail/${id}`)}>
                                                <Link
                                                    className={`nav-link ${type === "auditTrail" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/popupspages/table/auditTrail/${id}`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                >
                                                    <i className="fal fa-history"></i>Audit trail
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {type == "create" && <PopupsPagesOpt />}
                                            {type == "open" && <PopupsPagesOpt id={id} />}
                                            {type == "auditTrail" && <AuditTrail />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreatePopupsPages;
