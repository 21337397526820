import React from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import Tabs from "../components/systemadministration/Tabs";

import SoftPhone from "react-softphone";
import { WebSocketInterface } from "jssip";


const Phone = () => {
  const config = {
    domain: "enterprisedemo.bitcovoice.com", // sip-server@your-domain.io
    uri: "sip:500@enterprisedemo.bitcovoice.com", // sip:sip-user@your-domain.io
    password: "bI6zZ8aR6gX8mV1v", //  PASSWORD ,
    ws_servers: "wss://500@enterprisedemo.bitcovoice.com", //ws server
    sockets: new WebSocketInterface("wss://enterprisedemo.bitcovoice.com:7443/ws"),
    display_name: "500", //jssip Display Name
    debug: true, // Turn debug messages on
  };
  const setConnectOnStartToLocalStorage = (newValue) => {
    // Handle save the auto connect value to local storage
    return true;
  };
  const setNotifications = (newValue) => {
    // Handle save the Show notifications of an incoming call to local storage
    return true;
  };
  const setCallVolume = (newValue) => {
    // Handle save the call Volume value to local storage
    return true;
  };
  const setRingVolume = (newValue) => {
    // Handle save the Ring Volume value to local storage
    return true;
  };
  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <SoftPhone
                callVolume={33} //Set Default callVolume
                ringVolume={44} //Set Default ringVolume
                connectOnStart={false} //Auto connect to sip
                notifications={false} //Show Browser Notification of an incoming call
                config={config} //Voip config
                setConnectOnStartToLocalStorage={setConnectOnStartToLocalStorage} // Callback function
                setNotifications={setNotifications} // Callback function
                setCallVolume={setCallVolume} // Callback function
                setRingVolume={setRingVolume} // Callback function
                timelocale={"UTC+3"} //Set time local for call history
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Phone;
