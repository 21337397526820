import React, { useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import AuditTrail from "../components/studentsupport/Banners/AuditTrail";
import Listofbanners from "../components/studentsupport/Banners/Listofbanners";
import Tabs from "../components/studentsupport/Tabs";
import { useHistory, useParams,useLocation } from "react-router-dom";
import BannerForm from "../components/studentsupport/Banners/BannerForm";


const BannerPages = () => {
    const { type, id, name } = useParams();
    const history = useHistory();
    const [bannerName, setBannerName] = useState("");


    return (
        <>
            <Header></Header>
            <div className="content-wrapper d-flex flex-wrap new-standard-box">
                <SideBar></SideBar>
                <div className="sidebar-right">
                    <div className="sub-menu-content-block">
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-info-list Tickets-main-wrap">

                                    <div className="my-tickets-heading-blk flex-direction mb-2">
                                        <div class="d-flex align-items-center ">
                                            <div class="name-w-head d-flex align-items-center ">
                                                <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                    {((type==="table" || type ==="auditTrail") && !id) ? "Banners" : type==="create" ? "Add ": "Banner: "}
                                                    {type==="create" ? <span>Banner</span> : ((type==="table" || type ==="auditTrail") && !id) ? "" : <span>{bannerName}</span>}
                                                </h4>
                                            </div>
                                            <div class="text-md-right action2-blk">
                                                <div class="ticket-view-btn d-flex align-items-center">
                                                    <div class="add-new-ticket-bx">
                                                        <button onClick={() => history.goBack()} title="Back" class="btn btn-white-bordered">
                                                            <i class="fal fa-angle-left"></i>Back
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {<div className="tabs-wrap">
                                        {type!=="create" && 
                                        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                            <li className="nav-item" onClick={() => history.push(type === "open" || (type === "auditTrail" && id) ? type === "create" ? `/support/studentBanners/create` : `/support/studentBanners/open/${id}` : `/support/studentBanners/table`)}>
                                                <a className={`nav-link ${type !== "auditTrail" && "active"}`} id="pills-tab1" data-toggle="pill1" href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true"
                                                 >
                                                    {type === "open" || (type === "auditTrail" && id) || type === "create" ? <i class="fal fa-info-circle"></i> : <i className="fal fa-pennant"></i>}
                                                    {type === "open" || (type === "auditTrail" && id) || type === "create" ? "Details" : "Banners List"}
                                                </a> 
                                                

                                            </li>
                                            { type !== "create" && <li className="nav-item" onClick={() => history.push((type === "open" || id) ? `/support/studentBanners/auditTrail/${id}` :`/support/studentBanners/auditTrail`)}>
                                                <a className={`nav-link ${type == "auditTrail" && "active"}`} id="pills-tab2" data-toggle="pill" href="#pills-tab12" role="tab" aria-controls="pills-tab12" aria-selected="true">
                                                    <i className="fal fa-history"></i>Audit trail
                                                </a>
                                            </li>}
                                        </ul> }
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                                {type === "table" && <Listofbanners />}
                                           
                                                {type === "create" && <BannerForm setBannerName={setBannerName} />}

                                                {type === "open" && id && <BannerForm setBannerName={setBannerName} />}

                                                {type === "auditTrail" && <AuditTrail />}
                                            </div>
                                        </div>

                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BannerPages;