import axiosInstance from "../utils/axios";

export const GetListOfBrandSetting = async () => {
  return await axiosInstance().get(`/getBrandSettingList`);
};

export const GetListOfBrandSettingCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getBrandSettingList`, {
    cancelToken: cancelToken
  });
};

export const AddBrandSettingAPI = async (values) => {
  return await axiosInstance().post(`/addBrandSetting`, values);
};

export const EditBrandSettingAPI = async (values) => {
  return await axiosInstance().post(`/editBrandSetting`, values);
};

export const GetBrandSettingDetail = async (id) => {
  return await axiosInstance().get(`/getBrandSettingDetail?id=${id}`);
};

export const GetBrandSettingDetailCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getBrandSettingDetail?id=${id}`,{ cancelToken: cancelToken});
};

export const GetBrandSettingAuditTrail = async () => {
  return await axiosInstance().post(`/getBrandSettingAuditTrail`);
};

export const GetBrandSettingAuditTrailDetails = async (data) => {
  return await axiosInstance().post(`/getBrandSettingAuditTrail`, data);
};

export const DeleteBrandSetting = async (values) => {
  return await axiosInstance().post(`/deleteBrandSetting`, values);
};

export const GetBrandSettingCommonContent = async () => {
  return await axiosInstance().get(`/getBrandSettingCommonContent`);
};

export const GetBrandSettingCommonContentCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getBrandSettingCommonContent`,{ cancelToken: cancelToken});
};

export const GetNetsuiteERPBrandNameList = async () => {
  return await axiosInstance().get(`/getNetsuiteERPBrandNameList`);
};

export const GetNetsuiteERPBrandNameListCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getNetsuiteERPBrandNameList`,{ cancelToken: cancelToken});
};