import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { getCurrentTimeHomePage } from "../../../common/Helper";
import AssessmentList from "./AssessmentList";
import AuditTrails from "./AuditTrails";

import DetailAssessment from "./DetailAssessment";
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission";

const Tabs = () => {
  const history = useHistory();
  const { tabName, tab, subtab, id } = useParams();


  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["cquizmview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])

  return (
    <>
      {/* <div className="my-tickets-info-list Tickets-main-wrap">
        {tabName === "assessment" && tab === "open" ? (
          <DetailAssessment />
        ) : (
          <>
            <div className="my-tickets-heading-blk flex-direction">
              <h4 className="text-left-align landing-heading heading_color_dynamic">
              Assessments
              </h4>
            </div>
            <div className="tabs-wrap">
              <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                <li className="nav-item" onClick={() => history.push("/courseAdministration/smartAssessment/assessment/list")}>
                  <a className={`nav-link cursor-pointer ${tabName == "assessment" && tab !== "audittrail" ? "active" : ""}`}>
                    <i className="fal fa-file-alt"></i>
                    Assessment
                  </a>
                </li>
                <li className="nav-item" onClick={() => history.push("/courseAdministration/smartAssessment/assessment/audittrail/student")}>
                  <a className={`nav-link cursor-pointer ${tabName == "assessment" && tab === "audittrail" ? "active" : ""}`}>
                    <i className="fal fa-history"></i>Audit trail
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade active show">
                  {tabName === "assessment" && tab === "list" && <AssessmentList />}
                  {tabName === "assessment" && tab === "audittrail" && <AuditTrails />}
                </div>
              </div>
            </div>
          </>
        )}
      </div> */}

{/* changes for quizzes in assessments  */}
<div className="my-tickets-info-list Tickets-main-wrap">
        {tabName === "quizzes" && tab === "open" ? (
          <DetailAssessment />
        ) : (
          <>
            <div className="my-tickets-heading-blk flex-direction">
              <div className="d-flex align-items-center ">
                <div className="name-w-head d-flex align-items-center ">
                  <h4 className="text-left-align landing-heading heading_color_dynamic">
                  Quiz<span> Management</span>
                  </h4>
                </div>
                <div className="text-md-right action2-blk">
                <div className="ticket-view-btn d-flex align-items-center">
                  <div className="add-new-ticket-bx">
                    <button
                      onClick={() => history.goBack()}
                      title="Back"
                      className="btn btn-white-bordered"
                    >
                      <i className="fal fa-angle-left"></i>Back
                    </button>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className="tabs-wrap">
              <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                <li className="nav-item" onClick={() => history.push("/courseAdministration/assessment/quizzes/list")}>
                  <a className={`nav-link cursor-pointer ${tabName == "quizzes" && tab !== "audittrail" ? "active" : ""}`}>
                    <i className="fal fa-file-alt"></i>
                    Quiz Management
                  </a>
                </li>
                <li className="nav-item" onClick={() => history.push("/courseAdministration/assessment/quizzes/audittrail")}>
                  <a className={`nav-link cursor-pointer ${tabName == "quizzes" && tab === "audittrail" ? "active" : ""}`}>
                    <i className="fal fa-history"></i>Audit trail
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade active show">
                  {tabName === "quizzes" && tab === "list" && <AssessmentList />}
                  {tabName === "quizzes" && tab === "audittrail" && <AuditTrails />}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Tabs;
