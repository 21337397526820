import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AddAssembliesResources } from '../../../../services/CourseService';
import Swal from 'sweetalert2';
import $ from "jquery";


export default function AddCategory({ assembly_id, refreshList }) {
    const [isDisabled, setIsDisabled] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            item_name: "",
            item_desc: "",
            parent_id: 0,
            item_type: 1,
            assembly_id: assembly_id
        },
        validationSchema: Yup.object({
            item_name: Yup.string().required("Name is required").max(255, "Name can't be more than 255 characters"),
            item_desc: Yup.string().required("Description is required"),
        }),
        onSubmit: (values, { resetForm }) => {
            setIsDisabled(true);
            AddAssembliesResources(values).then((res) => {
                setIsDisabled(false)
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: `${res?.data?.message}`,
                }).then(() => {
                    handleClose();
                    refreshList();
                });


            }).catch((err) => {
                setIsDisabled(false);
                Swal.fire({
                    icon: "error",
                    title: "error",
                    text: `${err?.response?.data?.message}`,
                });
            });
        }
    });

    const handleClose = () => {
        $("#categoryForm").modal("hide");
        formik.resetForm();

    }

    return (
        <div
            className="modal content-assembly fade"
            id="categoryForm"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
            >
                <div className="modal-content">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-body p-0">
                            <div className="modal-header modal-header-custom">
                                <h5 class="modal-title"><i class="fal fa-plus"></i> Add Category</h5>
                                <button type="button" onClick={handleClose} class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                            </div>
                            <div className="t p-30px">
                                <div className="row center-label mb-3">
                                    <div className="col-md-6 col-lg-12">
                                        <div className="form-group-blk mb-3">
                                            <label htmlFor="item_name">Name *</label>
                                            <input
                                                name="item_name"
                                                type="text"
                                                className={"form-control " + (formik.errors.item_name && formik.touched.item_name ? " is-invalid" : "")}
                                                placeholder='Enter Name'
                                                title='Name'
                                                id="name"
                                                value={formik.values.item_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-12">
                                        <div className="form-group-blk mb-3">
                                            <label htmlFor="item_name">Description *</label>
                                            <input
                                                name="item_desc"
                                                id="item_desc"
                                                type="text"
                                                placeholder="Enter Description"
                                                title="Description"
                                                className={"form-control " + (formik.errors.item_desc && formik.touched.item_desc ? " is-invalid" : "")}
                                                value={formik.values.item_desc}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group form-group-save-cancel mt-3">
                                    <button className="btn btn-primary" type="submit" title="Save" disabled={isDisabled}>
                                        {isDisabled === false ? <i className="fal fa-save"></i> : <i className="fas fa-cog fa-spin"></i>}
                                        Save
                                    </button>
                                    <button type="button" className="btn btn-close btn-danger" title="Close" onClick={handleClose} >
                                        <i className="fal fa-times"></i>
                                        Close
                                    </button>
                                    {formik.touched.item_name && formik.errors.item_name ? <div className="invalid-feedback d-block">{formik.errors.item_name}</div> : null}
                                    {formik.touched.item_desc && formik.errors.item_desc ? <div className="invalid-feedback d-block">{formik.errors.item_desc}</div> : null}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
