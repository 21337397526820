import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import PermissionsGate from "../../utils/permissionGate";
import { useLocation, useParams } from "react-router-dom";

const Tabs = () => {
  const { assemblyId } = useParams();

  const handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };
  const { pathname } = useLocation();
  const location = useLocation();
  const currentPath = pathname.split("/");

  return (
    <>
      <div className="sub-menu-left-block">
        <button className="close-btn-sidebar" onClick={() => handleSideBar()}>
          <i className="close-bar close-bar-1"></i>
          <i className="close-bar close-bar-2"></i>
          <i className="close-bar close-bar-3"></i>
        </button>
        <div className="sub-menu accordion" id="accordionExample">
          <div className="card">
            <PermissionsGate scopes={["capview"]}>
              <div className={"card-header" + (location.pathname.includes("/Programmes") ? " active" : "")} id="headingOne">
                <Link to="/courseAdministration/Programmes/programme/show" className="btn btn-link btn-block no-sub">
                  Programmes
                </Link>
              </div>
            </PermissionsGate>
          </div>
          <div className="card">
            <PermissionsGate scopes={["cview"]}>
              <div
                className={"card-header" + (currentPath.includes("courseAdministration") && (currentPath.includes("coursesdetails") || currentPath.includes("courses")) ? " active" : "")}
                id="headingTwo"
              >
                <Link to="/courseAdministration/courses/list" className="btn btn-link btn-block no-sub">
                  Courses
                </Link>
              </div>
              <div className="card">
              <PermissionsGate scopes={["caview"]}>
                <div className={"card-header " + (location.pathname === "/content" || location.pathname === "/contentassembliesdetails" || location.pathname === "/content/resourcedetails" || location.pathname === "/contentassembliesdetails/create" || location.pathname === "/contentassembliesdetails/edit" || location.pathname === "/contentassembliesdetails/edit/" + assemblyId || location.pathname.includes("/content") ? "active" : "")} id="headingOne">
                  <Link to="/courses/content/assemblies/table" className="btn btn-link btn-block no-sub">
                    Content Assemblies
                  </Link>
                </div>
              </PermissionsGate>
              </div>
            </PermissionsGate>
            <div className={"card-header" + (currentPath.includes("courseAdministration") && currentPath.includes("smartAssessment") ? " active" : "")} id="headingOne">
              <a className="btn btn-link btn-block collapsed " data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                Smart Assessments
              </a>
            </div>
            <PermissionsGate scopes={["cquestionbview"]}>
            <div
              className={"collapse card-header" + (currentPath.includes("smartAssessment") ? " show" : "") + (currentPath.includes("questionBank") || currentPath.includes("addquestion") ? " active" : "")}
              id="collapseExample"
            >
              <Link className="btn btn-link btn-block no-sub sub-sub-tab" to={{ pathname: "/courseAdministration/smartAssessment/questionBank" }}>
                <span className="ml-2">
                  Question Bank
                </span>
              </Link>
            </div>
            </PermissionsGate>
            <PermissionsGate scopes={["castview"]}>
            <div
              className={"collapse card-header" + (currentPath.includes("smartAssessment") ? " show" : "") + (currentPath.includes("assessment") ? " active" : "")}
              id="collapseExample"
              >
              <Link className="btn btn-link btn-block mt-0 no-sub sub-sub-tab" to={{ pathname: "/courseAdministration/smartAssessment/assessment/list" }}>
                <span className="ml-2">
                  Assessments
                </span>
              </Link>
            </div>
              </PermissionsGate>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Tabs);
