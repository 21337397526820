import axiosInstance from "../utils/axios";

export const GetAppealsList = async () => {
    return await axiosInstance().get("/getAppealList");
};

export const GetAppealsCommonComponent = async () => {
  return await axiosInstance().get("/getAppealCommonContent");
};

export const ViewAppeal = async (stdId, assId) => {
  return await axiosInstance().get(`/viewAppeal?student_id=${stdId}&assessment_id=${assId}`);
};

export const ApproveOrDeclineAppeal = async (data) => {
  return await axiosInstance().post("/approveOrDeclineAppeal", data);
};

export const GetStudentAppealAuditTrail = async (data) => {
  return await axiosInstance().post("/getStudentAppealAuditTrail", data);
};

export const getAppealListData = async (data) => {
  return await axiosInstance().post("/getAppealListData", data);
};

export const GetAppealListDataFilters = async () => {
  return await axiosInstance().get("/getAppealListDataFilters");
};
