import Swal from "sweetalert2";
import { StudentStatmentResult } from "../services/StudentsSettingServices";
import Papa from "papaparse";

export const exportFile = (fileType, fileName, Id, fileDestination, intake_id) => {
  let data = [];
  var header = ["Name", "Surname", "Student Number", "Subject", "Assignment Name", "Assignment Code", "Assignment Mark", "Assignment Status"];
  Swal.fire({
    title: "File downloading",
    onOpen: function () {
      Swal.showLoading();
    },
  });
  const studentData = {
    student_id: Id,
    intake: intake_id && intake_id ? intake_id : ""
  };
  StudentStatmentResult(studentData)
    .then((res) => {
      data = res.data.studentData;
      if (fileDestination == "courseStudent") {
        data = data.filter((row, i) => {
          return row?.intake_status == 1
        })
      }
      data = data?.map((row) => {
        return {
          ...row,
          Name: row?.first_name ? row?.first_name : "-",
          Surname: row.last_name ? row.last_name : "-",
          "Student Number": row?.number ? row.number : "-",
          Subject: row?.SpAssignmentDetails[0].SpIntakeDetails[0] && row?.SpAssignmentDetails[0].SpIntakeDetails[0].getCourseName.name && row?.SpAssignmentDetails[0].SpIntakeDetails[0].courseDate ? row?.SpAssignmentDetails[0].SpIntakeDetails[0].getCourseName.name + " " + row?.SpAssignmentDetails[0].SpIntakeDetails[0].courseDate : "-",
          "Assignment Name": row?.SpAssignmentDetails[0].name ? row?.SpAssignmentDetails[0].name
            : "-",
          "Assignment Code": row?.SpAssignmentDetails[0].code ? row?.SpAssignmentDetails[0].code : "-",
          "Assignment Mark": row.mark ? row?.mark : "-",
          "Assignment Status": row?.status ? row?.status : "-"
        }
      }
      );

      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        const blob = new Blob([csvString], {
          type: "text/csv;charset=utf-8,",
        });

        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();

        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      }
    })
    .catch((err) => {
      console.log(err);
    });
}