import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Attendance from "./Attendance";
import Summary from "./Summary"

const ClassReportTabs = () => {
  const { id, subId, openTabs, subType,subTab } = useParams();
  const history = useHistory();

  return (
    <div className="tabs-wrap">
      <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
        <li className="nav-item" onClick={() => history.push("/courseAdministration/coursesdetails/" + id + "/onlineClasses/classReport/" + subTab + '/summary')}>
          <a className={`nav-link cursor-pointer ${openTabs === "summary" ? "active" : ""}`} id="pills-tab1" data-toggle="pill" role="tab" aria-controls="pills-tab11" aria-selected="true">
            <i className="fal fa-file-alt"></i>
            Summary
          </a>
        </li>
        <li className="nav-item" onClick={() => history.push("/courseAdministration/coursesdetails/" + id + "/onlineClasses/classReport/" + subTab + '/attendance')}>
          <a
            className={`nav-link cursor-pointer ${openTabs === "attendance" ? "active" : ""}`}
            id="pills-tab2"
            data-toggle="pill"
            role="tab"
            aria-controls="pills-tab12"
            aria-selected="true"
          >
            <i className="fal fa-books"></i>Attendance
          </a>
        </li>
      </ul>

      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
          {openTabs == "summary" && <Summary uId={id} subTab={subTab}/>}
        </div>
        <div className="tab-pane fade active show" id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
          {openTabs == "attendance" ? <Attendance uId={id}/> : ""}
        </div>
      </div>
    </div>
  );
};

export default ClassReportTabs;
