import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import PageComponent from '../components/common/PageComponent'
import Attendance from '../components/courses/DetailCoursesBlocks/OnlineClassesBlocks/Attendance';
import Summary from '../components/courses/DetailCoursesBlocks/OnlineClassesBlocks/Summary';

function ClassReport() {

    const { tab, intake, cid } = useParams();
    const [classTitle, setClassTitle] = useState("");

    return (
        <PageComponent
            heading={tab === "summary" ? ["Attendance Summary", classTitle] : ["Class Attendance", classTitle]}
            componentToRender={[
                {
                    condition: tab === "summary",
                    component: <Summary uId={intake} subTab={cid} setClassTitle={setClassTitle} />
                },
                {
                    condition: tab === "attendenceReport",
                    component: <Attendance uId={intake} setClassTitle={setClassTitle} />
                },
            ]}
            tabs={
                [
                    {
                        tabName: "Summary",
                        tabIcon: "fa-file-alt",
                        url: `/classReport/summary/${intake}/${cid}`,
                        condition: tab === "summary"
                    },
                    {
                        tabName: "Attendence Report",
                        tabIcon: "fa-books",
                        url: `/classReport/attendenceReport/${intake}/${cid}`,
                        condition: tab === "attendenceReport"
                    },
                ]
            }
        />
    )
}

export default ClassReport