import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { InitialRender } from "../../common/Helper";
import { IMAGE_URL } from "../../../utils/Constants";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import {
    checkRole,
    ColorRender,
} from "../../systemadministration/usermanagement/CheckRole";
import Hover from "../../common/Hover";
import hasPermission from "../../../utils/hasMultiplePermission";
import DataTableComponent from "../../common/DataTableComponent";
import { GetListOfUser, GetListOfUserCancelToken } from "../../../services/UserService";
import { RenderUserStatus } from "../../../utils/CommonStatusItems";
import { RenderUserCompany, RenderUserLocation } from "../../../utils/CommonGroupingItem";
import axios from "axios";

function UserAssigned() {

    const history = useHistory();
    const { roleName, id } = useParams();
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState({ arr: [], checkObj: {} });
    const [company, setCompany] = useState({ arr: [], checkObj: {} });
    const [location, setLocation] = useState({ arr: [], checkObj: {} })
    const [reportTo, setReportTo] = useState({ arr: [], checkObj: [] })
    const [allUserList, setAllUserList] = useState([]);
    const givenPermsisions = useSelector((state) => state.givenPermission);
    const user = useSelector((state) => state.user);
    const [userData, setUserData] = useState([]);
    const [loading, setloading] = useState(true);
    const [totalRows, setTotalRows] = useState(0)
    const [tableState, setTableState] = useState({
        page: 1,
        perPage: 10,
        sortKey: "name",
        sortOrder: "asc",
    })

    useEffect(() => {
        const cancelTokenSources = [];
    
        const getListOfuser = async () => {
          setloading(true)
      
          cancelTokenSources.forEach(source => {
            source.cancel('New request made');
          });
      
          const source = axios.CancelToken.source();
          cancelTokenSources.push(source);
      
          const apiData = {
            page: tableState.page,
            limit: tableState.perPage,
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            search: search,
            viaStatus: status.arr,
            viaRole: [roleName],
            viaCompany: company.arr,
            viaLocation: location.arr,
            viaReportTo: reportTo.arr,
            exportStatus: "false"
        };
      
          try {
            const res = await GetListOfUserCancelToken(apiData, source.token);
            setUserData(res?.data?.data);
            setTotalRows(res.data?.total)
            if (res.status == 200) {
              setloading(false);
            }
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.error(error);
              setloading(false);
            }
          }
        }
    
       getListOfuser();
    
       return () => {
        cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
        });
      };
      }, [status, tableState, search, company, location, reportTo]);

    useEffect(() => {
        let response = hasPermission({
            scopes: ["sview"],
            permissions: givenPermsisions,
        });
        if (!response) {
            history.push("/noaccess");
        }
    }, []);

    useEffect(() => {
        const cancelTokenSources = [];
        const getData = async () => {
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);

            try {
                // this api call is for user list in report to filter, this need all user in list
                const res = await GetListOfUserCancelToken({}, source.token)
                if (res.status == 200) {
                    setAllUserList(res?.data.map(item => ({ value: item.UserID, label: item.Firstname + " " + item.Lastname })))
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    setloading(false);
                }
            }
        }

        getData();

        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, []);

    const resetFilter = () => {
        setStatus({ arr: [], checkObj: {} });
        setCompany({ arr: [], checkObj: {} });
        setLocation({ arr: [], checkObj: {} });
        setReportTo({ arr: [], checkObj: [] });
        setSearch("")
    };

    const handleEdit = (row, ID) => {
        const result = checkRole(user.role_name, row.role_name);
        if (result === "enabled") {
            history.push(`/systemAdministration/userManagement/open/${ID}/aboutYou`);
        } else {
            Swal.fire({
                icon: "error",
                title: "Not authorised",
                text: "You dont have the permission to Edit ",
            }).then((result) => { });
        }
    };

    const columns = useMemo(() => [
        {
            name: "User",
            selector: "Firstname",
            sortField:"name",
            sortable: true,
            cell: (row) => (
                <div className="assigned-title-block-new">
                <div className="assigned-title-itm">
                    <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(row.role_name)}>
                    {row.picture_me ? <img src={`${IMAGE_URL}/${row.picture_me.replace("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.Firstname, row.Lastname])}
                    <span className={`profile-box-2-status ${row.user_activity_status ? row.user_activity_status.toLowerCase()=="online" ? "Online" :row.user_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                        <i className="fas fa-circle"></i>
                    </span>
                    <Hover firstName={row.Firstname} lastName={row.Lastname} photo={row.picture_me} email={row.Email} mobile={row.Mobile} status={row.user_status} activity_status={row.user_activity_status} right={true} role={row.role_name} />
                    </span>
                    <PermissionsGate scopes={['umedit']} RenderError={() => <p>{row.Firstname}</p>}>
                    <Link className="as-text-blue curser " to={`/systemAdministration/userManagement/open/${row.UserID}/aboutYou`}>
                        {row.Firstname + " " + row.Lastname}
                    </Link>
                    </PermissionsGate>
                </div>
                </div>
            ),
        },

        { name: "Email", selector: "Email", sortField: "email", sortable: true, cell: (row) => (row.Email ? <span title={row.Email}>{row.Email}</span> : "-") },
        // {
        //     name: "Role",
        //     selector: "role_name",
        //     sortField: "role",
        //     sortable: true,
        // },
        {
            name: "Status",
            selector: "is_active",
            sortField: "status",
            sortable: true,
            cell: (row) => RenderUserStatus(row.is_active).html
        },
        {
            name: "Location",
            selector: "Location",
            sortField: "location",
            sortable: true,
            cell: (row) => row.Location ? RenderUserLocation(row.Location).html : "-",
        },
        {
            name: "Company",
            selector: "Company",
            sortField: "company",
            sortable: true,
            cell: (row) =>
                row.Company ? RenderUserCompany(row.Company).html : "-",
        },
       {
            name: "Reports To",
            selector: "report_user_Firstname",
            sortField:"reportTo",
            sortable: true,
            cell: (row) =>
                row.report_user_Firstname ? (
                <div className="reports-profile-view assigned-title-block-new">
                    <div className="assigned-title-itm">
                    <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(row.role_name)}>
                        {row.report_user_picture ? (
                        <img src={`${IMAGE_URL}/${row.report_user_picture.replace("/home/myaie/public_html/", "")}`} alt="AIE" />
                        ) : (
                        InitialRender([row.report_user_Firstname, row.report_user_Lastname])
                        )}
                        <span className={`profile-box-2-status ${row.report_user_activity_status ? row.report_user_activity_status.toLowerCase()=="online" ? "Online" :row.report_user_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                        <i className="fas fa-circle"></i>
                    </span>
                        <Hover firstName={row.report_user_Firstname} lastName={row.report_user_Lastname} photo={row.report_user_picture} email={row.report_user_Email} mobile={row.report_user_Mobile} status={row.report_user_status} activity_status={row.report_user_activity_status} right={true} role={row.role_name} />
                    </span>
                    <PermissionsGate scopes={['umedit']} RenderError={() => <p>{row.report_user_Firstname}</p>}>
                        <p className="as-text-blue curser " onClick={() => handleEdit(row, row.Report_to)}>
                        {row.report_user_Firstname}
                        </p>
                    </PermissionsGate>
                    </div>
                </div>
            ) : (
            "-"
            ),
        },

        {
            name: "Action",
            selector: "",
            cell: (row) => (
                <div className="assessment-08">
                    <div className="as-buttons">
                        <PermissionsGate scopes={['umedit']}>
                            <Link title="Open" className="btn btn-primary rounded-circle" to={`/systemAdministration/userManagement/open/${row.UserID}/aboutYou`}
                            >
                                <i className="fal fa-folder-open"></i>
                            </Link>
                        </PermissionsGate>
                    </div>
                </div>
            ),
        },
    ]);

    const exportData = (fileType, fileName) => {
        const header = ["ID", "User", "Email", "Role", "Status", "Company", "Location", "Reports To"];
        const apiData = {
            page: tableState.page,
            limit: tableState.perPage,
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            search: search,
            viaStatus: status.arr,
            viaRole: [roleName],
            viaCompany: company.arr,
            viaLocation: location.arr,
            viaReportTo: reportTo.arr,
            exportStatus: "true",
        };
        GetListOfUser(apiData)
            .then((res) => {
                let data = res.data

                data = data?.map((row) => ({
                    ...row,
                    // ID: row?.UserID,
                    // Role: row?.role_name,
                    Status: row?.is_active ? "Active" : "Inactive",
                    User: row?.Firstname + " " + row.Lastname,
                    Company: row.Company ? row.Company : "-",
                    Location: row.Location ? row.Location : "-",
                    Company: row.Company ? row.Company : "-",
                    Email: row.Email ? row.Email : "-",
                    "Reports To": row.report_user_Firstname ? row.report_user_Firstname : "-",
                }));

                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

                    const blobURL = window.URL.createObjectURL(blob);

                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
                    anchor.click();

                    setTimeout(() => {
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.map((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        header,
                    });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);

                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data.map((row) => {
                        return [row.User, row.Email, row.Status, row.Company, row.Location, row["Reports To"]];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);

                    return false;
                }
            })
    };

    return (
        <DataTableComponent
            data={userData}
            loading={loading}
            state={tableState}
            setState={setTableState}
            setSearch={setSearch}
            totalRows={totalRows}
            columns={columns}
            exportFunction={exportData}
            exportFileName={"User_Assigned"}
            resetFilter={resetFilter}
            filters={[
                {
                    filterName: "Status",
                    optionArr: [
                        { value: "1", label: "Active" },
                        { value: "0", label: "In Active" },
                    ],
                    state: status,
                    setState: setStatus,
                    renderLabelFunction: RenderUserStatus,
                },
                {
                    filterName: "Location",
                    optionArr: [
                        { value: "Midrand", label: "Midrand" },
                        { value: "Johannesburg", label: "Johannesburg" },
                        { value: "Cape Town", label: "Cape Town" },
                        { value: "Hatfield", label: "Hatfield" },
                        { value: "Remote/Online", label: "Remote/Online" },
                    ],
                    state: location,
                    setState: setLocation,
                    renderLabelFunction: RenderUserLocation,
                },
                {
                    filterName: "Company",
                    optionArr: [
                        { value: "Academic Institute of Excellence (AIE)", label: "Academic Institute of Excellence (AIE)" },
                        { value: "Greenside Design Center (GDC)", label: "Greenside Design Center (GDC)" },
                        { value: "Learnfast", label: "Learnfast" },
                        { value: "Cadco", label: "Cadco" },
                    ],
                    state: company,
                    setState: setCompany,
                    renderLabelFunction: RenderUserCompany,
                },
                {
                    filterName: "Report To",
                    optionArr: allUserList,
                    state: reportTo,
                    setState: setReportTo,
                    isSearchFilter:true
                }
            ]}
            tableButton={[]}
            />
    );
}
export default UserAssigned;
