import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  GetCommonContent,
  ResponsibleAccount,
  StudentUpdateAccountData,
} from "../../../../../services/StudentsSettingServices";
import Swal from "sweetalert2";
import moment from "moment";
import { myCompare } from "../../../../common/MyCompare";
import PermissionsGate from "../../../../../utils/permissionGate";
import { TABLE_DATE_FORMAT, FORM_DATE_FORMAT, DATE_FORMAT } from "../../../../../utils/Constants";
function BasicDetailsBlock({
  deleteRow,
  loading,
  setDeleteRow,
  studentData,
  raceArr,
  genderArr,
}) {
  const { id } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [raceOption, setRaceOption] = useState([]);
  const [genderArrs, setGenderArr] = useState([]);
  const [homeLan, setHomeLan] = useState([]);
  const [resDisable, setResDisable] = useState(false);

  const fixArr = (arr) => {
    let tempArr = [];
    Object.keys(arr).map((item) => {
      tempArr.push({
        value: item,
        label: arr[item],
      });
    });
    return tempArr;
  };
  useEffect(() => {
    GetCommonContent().then((res) => {
      setHomeLan(res.data.language);
    });
    raceArr && setRaceOption(fixArr(raceArr));
    genderArr && setGenderArr(genderArr);
  }, [raceArr]);

  const tempStatusArr = [];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Responsibility: studentData ? studentData.payer_same : "",
      Title: studentData ? studentData.title : "",
      FirstName: studentData ? studentData.first_name : "",
      SecondName: studentData ? studentData.second_name : "",
      LastName: studentData ? studentData.last_name : "",
      IDNumber: studentData ? studentData.number : 0,
      DOB: studentData && studentData.bday_date ?  new Date(moment.unix(studentData.bday_date).format(DATE_FORMAT) ) : "",
      Race: studentData ? studentData.race : "",
      Gender: studentData ? studentData.gender : "",
      HomeLan: studentData ? studentData.language : "",
    },
    validationSchema: Yup.object({
      // Responsibility: Yup.boolean().required("Responsibility is required"),
      // Title: Yup.string().required("Title is required").trim(),
      FirstName: Yup.string().required("First Name is required").trim().nullable(),
      // SecondName: Yup.string().required("Second Name is required").trim(),
      LastName: Yup.string().required("Last Name is required").trim().nullable(),
      // IDNumber: Yup.number().required("Please Enter a valid ID Number").min(13, "Please Enter 13 Digin Number").max(14, "Please Enter 13 Digit Number"),
      IDNumber: Yup.string()
        // .required()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(13, "Must be exactly 13 digits")
        .max(13, "Must be exactly 13 digits")
        .nullable(),
      // DOB: Yup.string().required("Date Of Birth is required").trim(),
      // Race: Yup.string().required("Race is required").trim(),
      // Gender: Yup.string().required("Gender is required").trim(),
      // HomeLan: Yup.string().required("Home Language is required").trim(),
    }),
    onSubmit: (values) => {
      // if (values.Responsibility) {
        setDisabled(true);
        let formData = new FormData();
        formData.append("section", "about_section");
        formData.append("student_id", id);
        formData.append("payer_same", values.Responsibility ? 1 : 0);
        formData.append("first_name", values.FirstName ? values.FirstName : "");
        formData.append("second_name", values.SecondName ? values.SecondName : "");
        formData.append("last_name", values.LastName ? values.LastName : "");
        formData.append("title", values.Title ? values.Title : "");
        formData.append("number", values.IDNumber ? values.IDNumber : "");
        formData.append("gender", values.Gender ? values.Gender : "");
        formData.append("bday_date", moment(values.DOB).unix() ? moment(values.DOB).unix() : "");
        formData.append("race", values.Race ? values.Race : "");
        formData.append("language", values.HomeLan ? values.HomeLan : "");
        StudentUpdateAccountData(formData)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: res.data.message,
            });
            setDisabled(false);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err?.response?.data?.message,
            });
            console.log("error :", err);
            setDisabled(false);
          });
      // } else {
        // formik.setErrors({ Responsibility: "Responsibility is required" });
      // }
    },
  });

  const handleResCheck = (e) => {
    setResDisable(true)
    let checked = e.target.checked
    let formData = new FormData();
    formData.append("uid", id)
    formData.append("type", checked ? "make_same" : "make_different")
    ResponsibleAccount(formData)
    .then(res=> {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Updated successfully",
      });
      setResDisable(false)
    }).catch(err => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: err?.response?.data?.message,
      })
      setResDisable(false)
    })
  }

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Basic Details</div>
        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label></label>
              <div className="custom-check custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="Responsibility"
                  name="select"
                  checked={formik.values.Responsibility}
                  disabled={resDisable}
                  onChange={(e) => {
                    handleResCheck(e);
                    formik.setFieldValue("Responsibility", e.target.checked)
                  }
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="Responsibility"
                >
                  Student is responsible for own account
                </label>
                {/* <p>Admin has last ticked</p>
                      <p>10/05/22 10:00AM</p> */}
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <label htmlFor="Title">Title </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="Title"
                className={
                  "form-control" +
                  (formik.errors.Title && formik.touched.Title
                    ? " is-invalid"
                    : "")
                }
                name="Title"
                placeholder="Enter Title"
                value={formik.values.Title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Title"
              />
              {formik.errors.Title && formik.touched.Title ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="FirstName">First Name *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="FirstName"
                className={
                  "form-control" +
                  (formik.errors.FirstName && formik.touched.FirstName
                    ? " is-invalid"
                    : "")
                }
                name="FirstName"
                placeholder="Enter First Name"
                value={formik.values.FirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="First Name"
              />
              {formik.errors.FirstName && formik.touched.FirstName ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="SecondName">Second Name </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="SecondName"
                className={
                  "form-control" +
                  (formik.errors.SecondName && formik.touched.SecondName
                    ? " is-invalid"
                    : "")
                }
                name="SecondName"
                placeholder="Enter Second Name"
                value={formik.values.SecondName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Second Name"
              />
              {formik.errors.SecondName && formik.touched.SecondName ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="LastName">Last Name *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="LastName"
                className={
                  "form-control" +
                  (formik.errors.LastName && formik.touched.LastName
                    ? " is-invalid"
                    : "")
                }
                name="LastName"
                placeholder="Enter Last Name"
                value={formik.values.LastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Last Name"
              />
              {formik.errors.LastName && formik.touched.LastName ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <label htmlFor="IDNumber">ID Number </label>
            <div className="form-icon-group mb-4">
              <input
                type="number"
                id="IDNumber"
                className={
                  "form-control" +
                  (formik.errors.IDNumber && formik.touched.IDNumber
                    ? " is-invalid"
                    : "")
                }
                name="IDNumber"
                placeholder="Enter ID Number"
                value={formik.values.IDNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="ID Number"
              />
              {formik.errors.IDNumber && formik.touched.IDNumber ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4" title="Gender">
            <label htmlFor="Gender">Gender </label>
            <div className="form-icon-group mb-4">
              <Select
                className={
                  "form-control custom-select-box " +
                  (formik.errors.Gender && formik.touched.Gender
                    ? " is-invalid"
                    : "")
                }
                name="Gender"
                value={genderArrs.filter((item) => {
                  return item.value == formik.values.Gender;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("Gender", value.value);
                  } else {
                    formik.setFieldValue("Gender", "");
                  }                  
                }}
                onBlur={formik.handleBlur}
                options={genderArrs.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={
                  formik.values.Gender ? formik.values.Gender : "Select"
                }
                isClearable
              />
              {formik.errors.Gender && formik.touched.Gender ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="DateOfBirth">Date Of Birth </label>
            <div className="form-group-blk mb-4">
              <DatePicker
                selected={formik.values.DOB}
                onChange={(date) => {
                  formik.setFieldValue("DOB", date);
                }}
                // showTimeSelect
                //   filterTime={filterPassedTime}
                dateFormat={FORM_DATE_FORMAT}
                // className="form-control"
                className={
                  "form-control w-100 cursor-pointer" +
                  (formik.errors.DOB && formik.touched.DOB ? " is-invalid" : "")
                }
                maxDate={new Date()}
                title="Date Of Birth"
                onBlur={formik.handleBlur}
                placeholderText="Select Date Of Birth"
                showYearDropdown
              />
              {formik.errors.DOB && formik.touched.DOB ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4" title="Race">
            <label htmlFor="Race">Race </label>
            <div className="form-icon-group mb-4">
              <Select
                className={
                  "form-control custom-select-box " +
                  (formik.errors.Race && formik.touched.Race
                    ? " is-invalid"
                    : "")
                }
                name="Race"
                value={raceOption.filter((item) => {
                  return item.value == formik.values.Race;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("Race", value.value);
                  } else {
                    formik.setFieldValue("Race", "");
                  }                  
                }}
                onBlur={formik.handleBlur}
                options={raceOption.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={formik.values.Race ? formik.values.Race : "Select"}
                isClearable
              />
              {formik.errors.Race && formik.touched.Race ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4" title="Home Language">
            <label htmlFor="HomeLan">Home Language </label>
            <div className="form-icon-group mb-4">
              <Select
                className={
                  "form-control custom-select-box " +
                  (formik.errors.HomeLan && formik.touched.HomeLan
                    ? " is-invalid"
                    : "")
                }
                name="HomeLan"
                value={homeLan.filter((item) => {
                  return item.value.toString().toLowerCase() == formik.values.HomeLan.toString().toLowerCase();
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("HomeLan", value.value);
                  } else {
                    formik.setFieldValue("HomeLan", "");
                  }                  
                }}
                onBlur={formik.handleBlur}
                options={homeLan.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={
                  formik.values.HomeLan ? formik.values.HomeLan : "Select"
                }
                isClearable
              />
              {formik.errors.HomeLan && formik.touched.HomeLan ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group form-group-save-cancel">
          <PermissionsGate errorProps={{ disabled: true }} scopes={["sapiedit"]}>
            <button
              className="btn btn-save btn-primary"
              type="submit"
              title="Save"
              disabled={disabled || !formik.dirty || isLoaded}
            >
              {disabled ? (
                <i className="fas fa-cog fa-spin"></i>
              ) : (
                <i className="fal fa-save"></i>
              )}
              Save
            </button>
          </PermissionsGate>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => formik.resetForm()}
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {formik.touched.Responsibility && formik.errors.Responsibility ? (
            <div className="invalid-feedback d-block">
              {formik.errors.Responsibility}
            </div>
          ) : null}
          {formik.touched.Title && formik.errors.Title ? (
            <div className="invalid-feedback d-block">
              {formik.errors.Title}
            </div>
          ) : null}
          {formik.touched.FirstName && formik.errors.FirstName ? (
            <div className="invalid-feedback d-block">
              {formik.errors.FirstName}
            </div>
          ) : null}
          {formik.touched.SecondName && formik.errors.SecondName ? (
            <div className="invalid-feedback d-block">
              {formik.errors.SecondName}
            </div>
          ) : null}
          {formik.touched.LastName && formik.errors.LastName ? (
            <div className="invalid-feedback d-block">
              {formik.errors.LastName}
            </div>
          ) : null}
          {formik.touched.IDNumber && formik.errors.IDNumber ? (
            <div className="invalid-feedback d-block">
              {formik.errors.IDNumber}
            </div>
          ) : null}
          {formik.touched.DOB && formik.errors.DOB ? (
            <div className="invalid-feedback d-block">{formik.errors.DOB}</div>
          ) : null}
          {formik.touched.Race && formik.errors.Race ? (
            <div className="invalid-feedback d-block">{formik.errors.Race}</div>
          ) : null}
          {formik.touched.Gender && formik.errors.Gender ? (
            <div className="invalid-feedback d-block">
              {formik.errors.Gender}
            </div>
          ) : null}
          {formik.touched.HomeLan && formik.errors.HomeLan ? (
            <div className="invalid-feedback d-block">
              {formik.errors.HomeLan}
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
}

export default BasicDetailsBlock;
