import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { ColorRender} from "../../../../systemadministration/usermanagement/CheckRole";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import Str from "../../../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../../../utils/permissionGate";
import { DeleteExamRegistration, ExamsRegistrationsList, ExamsRegistrationsListFilter } from "../../../../../services/ExternalExamServices";
import moment from "moment";
import Tablefilter from "../../../../common/Tablefilter";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE } from "../../../../../utils/Constants";
import {RenderStudentType } from "../../../../../utils/CommonGroupingItem";
import { RenderExamIdMisMatch, RenderExtexamStudentStatus, RenderProgrammeStudentStatus } from "../../../../../utils/CommonStatusItems";
import Hover from "../../../../common/Hover";
import { InitialRender } from "../../../../common/Helper";
import hasPermission from "../../../../../utils/hasMultiplePermission";
import axios from "axios";
import { checkIfImageExists } from "../../../../../utils/commonFunction";

const RegistrationList = () => {
  const { id } = useParams();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [studentTypeFilter, setStudentTypeFIlter] = useState([]);
  const [studentStatusFilter, setStudentStatusFilter] = useState([]);
  const [userData, setUserData] = useState([]);
  const [valueStatus, onChangeStatus] = useState([]);
  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("submited");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [examsCenterFilterArr, setExamsCenterFilterArr] = useState([]);
  const [studentTypeFilterarr, setStudentTypeFilterArr] = useState([]);
  const [statusFilterArr, setStatusFilterArr] = useState([]);
  const [examIdFilterArr, setExamIdFilterArr] = useState([]);
  const [stuStatusData, setStuStatusData] = useState([]);
  const [examsCenterFilterMethod, setExamsCenterFilterMethod] = useState({ arr: [], checkObj:  [] });
  const [studentTypeFilterMethod, setStudentTypeFilterMethod] = useState({ arr: [], checkObj: {} });
  const [statusFilterMethod, setStatusFilterMethod] = useState({ arr: [], checkObj: {} });
  const [studentStatusFilterMethod, setStudentStatusFilterMethod] = useState({ arr: [], checkObj: {} });
  const [examIdFilterMethod, setExamIdFilterMethod] = useState({ arr: [], checkObj: {} });
  const [refresh, setRefresh] = useState(false);
  const [debouncedTerm, setDebouncedTerm] = useState("")
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["eerview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])
  
  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  useEffect(() => {
    const cancelTokenSources = [];
    const handleRegistrationData= async ()=>{
      setloading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      
      cancelTokenSources.push(source);

      const exam = {
        exam: id,
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        viaStudentType: studentTypeFilterMethod.arr,
        viaCenter: examsCenterFilterMethod.arr,
        viaStudentStatus: studentStatusFilterMethod.arr,
        viaStatus: statusFilterMethod.arr,
        viaMisMatch : examIdFilterMethod.arr
      };

      try {
        const res = await ExamsRegistrationsList(exam, source.token);
        if(res.status==200){
          setUserData(res?.data?.data?.data);
          setTotalRows(res.data?.data?.total);          
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }    
    }
   
    handleRegistrationData();

    return () => {
      cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
      });
    }

  }, [
    id,
    page,
    perPage,
    sortkey,
    sortOrder,
    search,
    studentTypeFilter,
    studentStatusFilter,
    studentStatusFilter,
    valueStatus,
    studentStatusFilterMethod,
    statusFilterMethod,
    studentTypeFilterMethod,
    examsCenterFilterMethod,
    examIdFilterMethod,
    refresh
  ]);
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateExamRegistrations"));
    if (storedFilters) {
      setStudentTypeFilterMethod(storedFilters.studentTypeFilterMethod);
      setStudentStatusFilterMethod(storedFilters.studentStatusFilterMethod);
      setStatusFilterMethod(storedFilters.statusFilterMethod);
      setExamsCenterFilterMethod(storedFilters.examsCenterFilterMethod);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      studentTypeFilterMethod,
      studentStatusFilterMethod,
      statusFilterMethod,
      examsCenterFilterMethod
    };
    localStorage.setItem("filterStateExamRegistrations", JSON.stringify(filterState));
  }, [  studentTypeFilterMethod, studentStatusFilterMethod, statusFilterMethod, examsCenterFilterMethod]);
  useEffect(()=>{
    const filterSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await ExamsRegistrationsListFilter({exam:id}, filterSource.token);
        if(res.status==200){
          setStatusFilterArr(res.data.examStatusFilter);
          setExamsCenterFilterArr(res.data.examsCenterFilter);
          setStudentTypeFilterArr(res.data.checkStudentTypeFilter);
          setStuStatusData(res.data.statusFilter);          
          setExamIdFilterArr(res.data.mismatchFilters);          
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    }
    
    getFilters();

    return () => {
      filterSource.cancel('Component unmounted');    
    };

  },[])

  

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const sortingName = (rowA, rowB) => {
    const name1 = (rowA.first_name).trim().toLowerCase();
    const name2 = (rowB.first_name).trim().toLowerCase();
    return name1.localeCompare(name2);
  };

  const columns = useMemo(() => [
    {
      name: "Student",
      selector: "first_name",
      sortField: "first_name",
      sortable: true,
      // minWidth: "190px",
      sortFunction: sortingName,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span
              className={
                "assigned-title-blk  name-icon cat-dark-red " +
                 ColorRender("Student")
              }
            >
              {row.photo && checkIfImageExists(
                    `${IMAGE_URL}/${row.photo.replaceAll(
                      "/home/myaie/public_html/",
                      ""
                    ).replaceAll("public/","")}`
                  ) ? (
                <img
                  src={`${IMAGE_URL}/${row.photo.replaceAll(
                    "/home/myaie/public_html/",
                    ""
                  ).replace("public/","")}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([row.first_name.trim(),row.last_name.trim()])
              )}
              <span className={`profile-box-2-status ${row.student_activity_status ? row.student_activity_status.toLowerCase()=="online" ? "Online" :row.student_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
              <Hover
                firstName={row.first_name}
                lastName={row.last_name}
                photo={row.photo}
                email={row.email}
                mobile={row.mobile}
                status={row.sp_status}
                role={"Student"}
                activity_status={row.activity_status}
                right={true}
                showNumber={true} 
                number={row.student_crm_id}
              />
            </span>
            <PermissionsGate scopes={["eeredit"]} RenderError={() => (
                <p>{row.first_name.trim() ? <span title={row.first_name+" "+row.last_name} className="feature-exam_period"> <span className="textLimit100">{row.first_name+" "+row.last_name}</span> </span> : "-"}</p>
              )}>
            <Link
              className="as-text-blue curser feature-name"
              to={`/studentAdministration/externalExam/details/open/registration/${row.id}/details/${row.exam}`}
              title={row.first_name+" "+row.last_name}
            >
              <span className="textLimit100">{row.first_name+" "+row.last_name}</span>
            </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
    {
      name: "ID or Passport",
      selector: "rsa_identity",
      sortable: true,
      sortField: "rsa_identity",
      cell: (row) => (row.rsa_identity ? <span title={row.rsa_identity}>{row.rsa_identity}</span> : "-"),
    },
    {
      name: "Exam ID number",
      selector: "exam_id_number",
      sortField: "number",
      sortable: true,
      cell: (row) => (row.exam_id_number ? <span title={row.exam_id_number }>{row.exam_id_number }</span> : "-"),
    },
   
    {
      name: "Second Name",
      selector: "second_name",
      sortable: true,
      sortField: "second_name",
      cell: (row) => (row.second_name ? <span title={row.second_name}>{row.second_name}</span> : "-"),
    },
    {
      name: "Third Name",
      selector: "third_name",
      sortable: true,
      sortField: "third_name",
      cell: (row) => (row.third_name ? <span title={row.third_name}>{row.third_name}</span> : "-"),
    },
    // {
    //   name: "Surname",
    //   selector: "last_name",
    //   sortable: true,
    //   sortField: "last_name",
    //   cell: (row) => (row.last_name ? row.last_name : "-"),
    // },
    {
      name: "Student Type",
      selector: "student_type",
      sortable: true,
      sortField: "student_type",
      // cell: (row) =>
      //   row.student_type ? (
      //     RegistrationColor(row.student_type)
      //   ) : (
      //     <p className="cat">
      //       <i className="fas fa-circle mr-1"></i> -
      //     </p>
      //   ),
        cell: (row) => row.student_type  ? <span title={RenderStudentType(row.student_type).html}>{RenderStudentType(row.student_type).html}</span> : "-",
    },
    {
      name: "Centre Alias",
      selector: "centre_alias",
      sortable: true,
      sortField: "center",
      cell: (row) => (row.centre_alias ? <span title={row.centre_alias}>{row.centre_alias}</span> : "-"),
    },
    {
      name: "Student Status",
      selector: "sp_status",
      sortable: true,
      sortField: "sp_status",
      // cell: (row) =>
      //   row.sp_status ? (
      //     RegistrationStudentStatusColor(row.sp_status)
      //   ) : (
      //     <p className="cat">
      //       <i className="fas fa-circle mr-1"></i> -
      //     </p>
      //   ),
        cell: (row) => row.sp_status  ? <span title={RenderProgrammeStudentStatus(row.sp_status).html}>{RenderProgrammeStudentStatus(row.sp_status).html}</span> : "-",
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      sortField: "status",
      // cell: (row) =>
      //   row.status ? (
      //     RegistrationStatusColor(row.status)
      //   ) : (
      //     <p className="cat">
      //       <i className="fas fa-circle mr-1"></i> -
      //     </p>
      //   ),
        cell: (row) => row.status  ? <span title={RenderExtexamStudentStatus(row.status).html}>{RenderExtexamStudentStatus(row.status).html}</span> : "-",
    },
    {
      name: "Date Submitted",
      selector: "submited",
      sortable: true,
      sortField: "adust_submited",
      cell: (row) =>
        row.submited ? <span title={moment.unix(row.submited).format(TABLE_DATE_FORMAT)}>{moment.unix(row.submited).format(TABLE_DATE_FORMAT)}</span> : "-",
    },
    {
      name: "INV No.",
      selector: "invoice_no",
      sortable: true,
      sortField: "invoice_no",
      cell: (row) => (row.invoice_no ? <span title={row.invoice_no}>{`INV${row.invoice_no}`}</span> : "-"),
    },
    {
      name: "Exam ID Mismatch",
      selector: "id_mismatch",
      sortable: true,
      sortField: "id_mismatch",
      cell: (row) => (row.id_mismatch ? <span title={row.id_mismatch}>{RenderExamIdMisMatch(row?.id_mismatch).html}</span> : "-"),
    },
    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <PermissionsGate scopes={["eeredit"]} RenderError={() => (
              <button title="Open" className="btn btn-primary rounded-circle mr-1" disabled>
                <i className="fal fa-folder-open"></i>
              </button>
            )}>
              <Link
                title="Open"
                className="btn btn-primary rounded-circle mr-1"
                to={`/studentAdministration/externalExam/details/open/registration/${row.id}/details/${row.exam}`}
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            <div className="dropdown btn-dropdown-item">
              <button
                className="btn btn-primary rounded-circle dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title="More"
              >
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <Link className="btn btn-primary rounded-circle"
                  to={`/studentAdministration/students/open/${row.student}/general`}
                  title="Open Student Profile"
                >
                  <i className="fal fa-user"></i>
                </Link>
                <PermissionsGate scopes={["eerdelete"]} errorProps={{ disabled: true }}>
                  <button
                    title="Delete"
                    className="btn btn-danger rounded-circle"
                    onClick={() => handleDelete(row.id)}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ]);

  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // TODO Reset Filter
  const resetFilter = () => {
    setStudentStatusFilter([]);
    setStudentTypeFIlter([]);
    onChangeStatus([]);
    setSearch("");
    setStatusFilterMethod({ arr: [], checkObj: {} })
    setStudentStatusFilterMethod({ arr: [], checkObj: {} })
    setExamsCenterFilterMethod({ arr: [], checkObj: [] })
    setStudentTypeFilterMethod({ arr: [], checkObj: {} })
    setExamIdFilterMethod({ arr: [], checkObj: {} })
    setDebouncedTerm("")
  };

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteExamRegistration({ registration: ID }).then((res)=>{
          if(res.status==200){
            Swal.fire("Deleted!", "Your record has been deleted.", "success");
            setRefresh(!refresh)
          }
        });
        
      }
    });
  };

  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = [
      "Student",
      "Id or Passport",
      "Exam ID number",
      "Second Name",
      "Third Name",
      // "Surname",
      "Student Type",
      "Centre Alias",
      "Student Status",
      "Status",
      "Date Submitted",
      "INV No.",
      "Exam ID Mismatch"
    ];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const exam = {
      exam: id,
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      viaStudentType: studentTypeFilterMethod.arr,
      viaCenter: examsCenterFilterMethod.arr,
      viaStudentStatus: studentStatusFilterMethod.arr,
      viaStatus: statusFilterMethod.arr,
      viaMisMatch : examIdFilterMethod.arr,
      exportStatus: "true",
    };
    ExamsRegistrationsList(exam)
      .then((res) => {
        data = res.data.data;
        data = data?.map((row) => ({
          ...row,
          "First Name": row.first_name ? row.first_name : "-",
          "Id or Passport": row?.rsa_identity ? row?.rsa_identity : "-",
          "Exam ID number": row?.exam_id_number ? row?.exam_id_number : "-",
          Student:
            row.first_name && row.last_name
              ? `${row.first_name} ${row.last_name}`
              : "-",
          "Second Name": row.second_name ? row.second_name : "-",
          "Third Name": row.third_name ? row.third_name : "-",
          // Surname: row.last_name ? row.last_name : "-",
          "Student Type": row.student_type ? RenderStudentType(row.student_type).text : "-",
          "Centre Alias": row.centre_alias ? row?.centre_alias : "-",
          "Student Status": row.sp_status ? RenderProgrammeStudentStatus(row.sp_status).text : "-",
          Status: row.status ? row.status : "-",
          "Date Submitted": row.submited
            ? moment.unix(row.submited).format(`D MMM 'Y`)
            : "-",
          "INV No.": row.invoice_no ? row.invoice_no : "-",
          "Exam ID Mismatch": row.id_mismatch ? RenderExamIdMisMatch(row?.id_mismatch).text : "-",
        }));
        // console.log(typeof data);
        // console.log(typeof result);

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          console.log(csvString);
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
            Swal.close();
          }, 1000);
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Student"],
              row["Id or Passport"],
              row["Exam ID number"],
              row["Second Name"],
              row["Third Name"],
              // row["Surname"],
              row["Student Type"],
              row["Centre Alias"],
              row["Student Status"],
              row["Status"],
              row["Date Submitted"],
              row["INV No."],
              row["Exam ID Mismatch"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();

          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap filter-scrolable">
      <div className="custom-table-div filter-search-icon card card-table-custom appeals-tables">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={e => setDebouncedTerm(e.target.value)} value={debouncedTerm} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Student Type"
                      optionArr={studentTypeFilterarr}
                      state={studentTypeFilterMethod}
                      setState={setStudentTypeFilterMethod}
                      renderLabelFunction={RenderStudentType}
                    />
                    <Tablefilter
                      filterName={"Student Status"}
                      optionArr={stuStatusData}
                      state={studentStatusFilterMethod}
                      setState={setStudentStatusFilterMethod}
                      renderLabelFunction={RenderProgrammeStudentStatus}
                    />
                    <Tablefilter
                      filterName="Status"
                      optionArr={statusFilterArr}
                      state={statusFilterMethod}
                      setState={setStatusFilterMethod}
                      renderLabelFunction={RenderExtexamStudentStatus}
                    />
                    <Tablefilter
                      filterName="Centre Alias"
                      optionArr={examsCenterFilterArr}
                      state={examsCenterFilterMethod}
                      setState={setExamsCenterFilterMethod}
                      isSearchFilter
                    />
                    <Tablefilter
                      filterName="Exam ID Mismatch"
                      optionArr={examIdFilterArr}
                      state={examIdFilterMethod}
                      setState={setExamIdFilterMethod}
                      renderLabelFunction={RenderExamIdMisMatch}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Registration");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Registration");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Registration");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
              
              <PermissionsGate scopes={["eeradd"]} RenderError={()=>(
                <button className="btn btn-primary" title="Create New" disabled>
                  <i className="fal fa-plus"></i> Create New
                </button>
                )}>
                <Link className="btn btn-primary" title="Create New" to={`/studentAdministration/externalExam/details/open/registration/${id}/add_details`}>
                  <i className="fal fa-plus"></i> Create New
                </Link>
              </PermissionsGate>
              
              </div>
            </div>

          </div>

        </div>

        <DataTable
          data={userData}
          defaultSortField="submited"
          defaultSortAsc={false}
          columns={columns}
          pagination={true}
          noDataComponent={Str.noRecord}
          onSort={handleSort}
          paginationServer
          sortServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
    </div>
  );
};

export default RegistrationList;
