import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../utils/Constants";
import Str from "../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import Swal from "sweetalert2";
import moment from "moment";
// import SideBar from "../../common/SideBar";
// import Tabs from "../Tabs";
// import { getCurrentTimeHomePage } from "../../common/Helper";
// import { Link } from "react-router-dom";
import { GetBannerSettingAuditTrail, getEmailAuditTrail } from "../../services/BannerService";
import { capitalize, handleTableScroll } from "../../utils/commonFunction";
import generateArrayOfYears from "../common/generateArrayOfYears";
import { InitialRender } from "../common/Helper";
import Hover from "../common/Hover";
import { RenderAuditTrailActionType } from "../../utils/CommonGroupingItem";



const AuditTrail = () => {
  const { id, type, tab } = useParams()
  const history = useHistory();
  const [search, setSearch] = useState("");

  const [brandData, setBrandData] = useState([]);

  const [actiontype, setActionType] = useState([]);
  const [searchActionTypeCheck, setSearchActionTypeCheck] = useState({});

  const [month, setMonth] = useState([]);
  const [searchMonthCheck, setSearchMonthCheck] = useState({});

  const [year, setYear] = useState([]);
  const [searchYearCheck, setSearchYearCheck] = useState({});

  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    handleTableScroll()
  }, [isLoaded])

  const fixDataFormat = (arr) => {
    let tempArr = [];
    arr.map((item) => {
      let singleItem = {
        id: item?.id,
        action_id: item?.action_id,
        resource_type: item?.resource_type,
        description: JSON.parse(item?.description),
      };
      tempArr.push(singleItem);
    });
    setBrandData(tempArr);
  };

  useEffect(() => {
    // let Tab = tab == "bulk" ? "bulk" : tab === "notifications-list" ? "generated" : tab === "pending-notifications" ? "pending" : tab === "stopped-notifications" ? "stopped" : "emails_template"
    let Tab = tab == "bulk" ? "bulk" : tab === "notifications-list" ? "generated" : tab === "pending-notifications" ? "pending" : tab === "stopped-notifications" ? "stopped" : "emails_template"

    // getEmailAuditTrail(id ? { action_id: id } : { action_id: "" }, Tab)
    getEmailAuditTrail({action_id: id ? id : ""}, Tab)
      .then((res) => {
        fixDataFormat(res?.data?.getAudit);
        setIsLoaded(false);
      })
      .catch((err) => console.log(err));
  }, [tab]);

  const sortingDate = (rowA, rowB) => {
    const date1 = new Date(rowA.description.timestamp);
    const date2 = new Date(rowB.description.timestamp);

    if (date2 > date1) {
      // console.log(`${date2} is greater than ${date1}`)
      return 1;
    } else if (date2 < date1) {
      // console.log(`${date1} is greater than ${date2}`)
      return -1;
    } else {
      // console.log(`Both dates are equal`)
      return 0;
    }
  };

  const sortingName = (rowA, rowB) => {
    const name1 = rowA?.description?.object?.definition?.New?.subject ? rowA?.description?.object?.definition?.New?.subject?.toString().toLowerCase().trim() : rowA?.description?.object?.definition?.Old?.subject?.toString().toLowerCase().trim();
    const name2 = rowB?.description?.object?.definition?.New?.subject ? rowB?.description?.object?.definition?.New?.subject?.toString().toLowerCase().trim() : rowB?.description?.object?.definition?.Old?.subject?.toString().toLowerCase().trim();
    // return name1?.localeCompare(name2);
    if (name2 > name1) {
      return 1;
    } else if(name2 < name1){
      return -1;
    } else {
      return 0;
    }
  };

  const sortingUser = (rowA, rowB) => {
    const name1 = rowA?.description?.User?.name?.toString();
    const name2 = rowB?.description?.User?.name?.toString();
    return name1.localeCompare(name2);
  };

  const difference = (obj1, obj2) => {
    let keyFound = [];
    Object.keys(obj1).forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        return keyFound.push(key + ":" + obj1[key]);
      }
    });
    return keyFound;
  };

  // image url
  const Testing_Img_URL = "https://aisuperadmin.devtechnosys.tech/backend/";

  const columns = useMemo(() => [
    {
      name: "User",
      selector: "user",
      sortable: true,
      sortFunction: sortingUser,
      cell: (row) => (
        // <div className="assigned-title-block-new">
        //   <div className="assigned-title-itm">
        //     <span className="assigned-title-blk name-icon">
        //       <img src={`${Testing_Img_URL}${row?.description?.User?.URL?.replace("/home/myaie/public_html/", "/")}`} alt="AIE" />
        //       <span className={`profile-box-2-status ${row?.description?.User?.status ? row?.description?.User?.status : "Offline"}`}>
        //         <i className="fas fa-circle"></i>
        //       </span>
        //       <div className="assigned-title-info popup-right">
        //         <article>
        //           <figure>
        //             <img src={`${Testing_Img_URL}${row?.description?.User?.URL?.replace("/home/myaie/public_html/", "/")}`} alt="AIE" />
        //           </figure>
        //           <figcaption>
        //             <h4>{row?.description?.User?.name}</h4>
        //             <a href="javascript:void(0);" onClick={() => navigator.clipboard.writeText(row?.description?.User?.mbox)}>
        //               <i className="fal fa-envelope"></i> {row?.description?.User?.mbox ? row?.description?.User?.mbox : "NA"}
        //             </a>
        //             <a href="javascript:void(0);" onClick={() => navigator.clipboard.writeText(row.description.User?.Mobile)}>
        //               <i className="fal fa-phone-alt"></i> {row?.description?.User?.Mobile ? row?.description?.User?.Mobile : "NA"}
        //             </a>
        //           </figcaption>
        //         </article>
        //       </div>
        //     </span>
        //     <p>{row?.description?.User?.name}</p>
        //   </div>
        // </div>
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className="assigned-title-blk name-icon">
              {row?.description?.User?.URL ? <img src={`${!row?.description?.User?.URL?.includes("https://myaie.ac") ? IMAGE_URL + "/" : ""}${row?.description?.User?.URL?.replaceAll("/home/myaie/public_html/", "").replaceAll("/home/myaie/public_html/", "").replace("public/", "").replace("adminapi.myaie.ac17278/", "").replace("devadminapi.myaie.ac1788/", "")}`} alt="AIE" /> : InitialRender([row?.description?.User?.name, row?.description?.User?.name])}

              <span className={`profile-box-2-status ${row?.description?.User?.status ? row?.description?.User?.status : "Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
              <Hover firstName={row?.description?.User?.name.split(" ")[0]} lastName={row?.description?.User?.name?.split(" ")[1]} photo={`${row?.description?.User?.URL ? row?.description?.User?.URL?.replace("https://myaie.ac", "").replaceAll("/home/myaie/public_html/", "").replaceAll("/home/myaie/public_html/", "").replace("public/", "").replace("adminapi.myaie.ac17278/", "").replace("devadminapi.myaie.ac1788/", ""):""}`} email={row?.description?.User?.mbox} mobile={row?.description?.User?.Mobile} status={row?.description?.User?.status} role={""} right={true} />
            </span>
            <p><b>{row?.description?.User?.name}</b></p>
          </div>
        </div>
      ),
    },
    {
      name: "Action Type",
      // sortable: true,
      cell: (row) => {
        return RenderAuditTrailActionType(row?.description?.verb?.Action).html;
        // <p>
        //   <label htmlFor="action3">
        //     <span className={row.description.verb.Action === "Branch Deleted" ? "cat cat-red" : row.description.verb.Action === "Branch Created" ? "cat cat-blue" : "cat cat-green"}>
        //       <i className="fas fa-circle mr-1"></i> {row.description.verb.Action}
        //     </span>
        //   </label>
        // </p>
      },
    },

    {
      name: "Resource Type",
      // sortable: true,
      cell: (row) => (row?.resource_type ? <div title={row?.resource_type}>{capitalize(row?.resource_type)}</div> : "-"),
    },
    {
      name: "Resource Name",
      selector: "Name",
      sortable: true,
      sortFunction: sortingName,
      cell: (row) => {
        if (row?.description?.verb?.Action === "Deleted") {
          return <div title={row?.description?.object?.definition?.Old?.subject}>{row?.description?.object?.definition?.Old?.subject}</div>;
        } else {
          return (
            <div className="assigned-title-block-new">
              <div className="assigned-title-itm">
                <p className="as-text-blue curser textLimit100" title={row?.description?.object?.definition?.New?.subject} onClick={() => history.push(tab=="notifications-template" ? `/notification/email/list/edit/${row?.description?.object?.id}`:`/notification/bulknotification/${tab}/table`)}>
                  {row?.description?.object?.definition?.New?.subject}
                </p>
              </div>
            </div>
          );
        }
      },
    },

    {
      name: "Old Value",
      // sortable: true,
      cell: (row) => {
        const entries = Object.entries(row?.description?.object?.definition?.Old);

        if (row?.description?.verb?.Action === "Created") {
          return "-";
        } else if (row?.description?.verb?.Action === "Edited") {
          const diff = difference(row?.description?.object?.definition?.Old, row?.description?.object?.definition?.New);
          return diff.length !== 0
            ? <div className="tooltip-custom" title={`${diff.sort().map(entry => entry.toString())}`.split(',').join("\n")}>
              {/* <span className="tooltip-custom-box">{diff.sort().map(entry => <p>{entry.toString()}</p>)}</span> */}
              {diff.sort().map((entry, index) => {
                return (index < 2 && <p key={index}>
                  {entry.toString().length > 30 ? entry.toString().slice(0, 30) + "..." : entry}
                </p>)
              })}
              {diff.length > 2 ? "..." : null}
            </div>
            : "NA";
        } else {
          const diff = difference(row?.description?.object?.definition?.New, row?.description?.object?.definition?.Old);
          return (<div className="tooltip-custom h" title={`${Object.keys(row?.description?.object?.definition?.Old).sort().map(entry => entry.toString() + " : " + row?.description?.object?.definition?.Old[entry])}`.split(',').join("\n")}>
            {entries.slice(0, 2).map((entry, index) => {
              return (
                // <p>
                //   {diff.sort().map((entry) => (
                //     <p>{entry.toString()}</p>
                //   ))}
                // </p>
                <p key={index}>
                  {entry[0]}:{entry[1] ? (JSON.stringify(entry[1]).length > 30 ? JSON.stringify(entry[1]).slice(0, 30) + "..." : JSON.stringify(entry[1])) : "N/A"}
                </p>
              );
            })}
          </div>)
        }
      },
    },
    {
      name: "New Value",
      // sortable: true,
      cell: (row) => {
        const entries = Object.entries(row?.description?.object?.definition?.New);
        if (row?.description?.verb?.Action === "Brand Edited") {
          const diff = difference(row?.description?.object?.definition?.New, row?.description?.object?.definition?.Old);
          return diff.length !== 0
            ? <div className="tooltip-custom" title={`${diff.sort().map(entry => entry.toString())}`.split(',').join("\n")}>
              {/* <span className="tooltip-custom-box">{diff.sort().map(entry => <p>{entry.toString()}</p>)}</span> */}
              {diff.sort().map((entry, index) => {
                return (index < 2 && <p key={index}>
                  {entry.toString().length > 30 ? entry.toString().slice(0, 30) + "..." : entry}
                </p>)
              })}
              {diff.length > 2 ? "..." : null}
            </div>
            : "-";
        } else {
          return (
            <div className="tooltip-custom" title={`${entries.map(entry => entry[0] + ":" + (entry[1] ? JSON.stringify(entry[1]) : "NA"))}`.split(',').join("\n")}>
              {/* <span className="tooltip-custom-box">{entries.map(entry => <p>{entry[0]}:{entry[1] ? entry[1] : "NA"}</p>)}</span> */}
              {entries.map((entry, index) => {
                // console.log(entry)
                return (index < 2) && (<p key={index} >
                  {entry[0]}:{entry[1] ? (JSON.stringify(entry[1]).length > 30 ? JSON.stringify(entry[1]).slice(0, 30) + "..." : JSON.stringify(entry[1])) : "N/A"}
                </p>)
              })}
              {entries.length > 2 ? "..." : null}
            </div>
          );
        }
      },
    },
    {
      name: "Date",
      sortable: true,
      selector: "Date",
      sortFunction: sortingDate,
      cell: (row) => {
        let today = new Date().toLocaleDateString();
        return row?.description?.timestamp ? (
          <>
            <p>{moment(row?.description?.timestamp).format(TABLE_DATE_FORMAT + ",")}</p>
            <p>{moment(row?.description?.timestamp).format(TABLE_TIME_FORMAT)}</p>
          </>
        ) : (
          "-"
        );
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button
              title="Open"
              className="btn btn-primary rounded-circle"
              onClick={() => {
                Swal.fire({ html: `<div className="audit-trial-json"><pre>${JSON.stringify(row?.description, undefined, 1)}</pre></div>` });
              }}
            >
              <i className="fal fa-folder-open"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleActionTypeFilter = (e) => {
    const value = e.target.value;
    let arr = actiontype;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setActionType(arr);
    let id = e.target.id;
    setSearchActionTypeCheck({ ...searchActionTypeCheck, [id]: !searchActionTypeCheck[id] });
  };

  const handleMonthFilter = (e) => {
    const value = e.target.value;
    let arr = month;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setMonth(arr);
    let id = e.target.id;
    setSearchMonthCheck({ ...searchMonthCheck, [id]: !searchMonthCheck[id] });
  };

  const handleYearFilter = (e) => {
    const value = e.target.value;
    let arr = year;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setYear(arr);
    let id = e.target.id;
    setSearchYearCheck({ ...searchYearCheck, [id]: !searchYearCheck[id] });
  };

  const renderMonthUI = () => {
    let arr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return arr.map((i, index) => {
      return (
        <li key={index}>
          <input type="checkbox" id={index} value={index} onClick={handleMonthFilter} checked={searchMonthCheck[index]} />
          <label htmlFor={index}>
            <span className="cat">{i}</span>
          </label>
        </li>
      );
    });
  };

  const renderYearUI = () => {
    // let arr = ["2020", "2021", "2022"];
    let arr = generateArrayOfYears().splice(0, 3);
    return arr.map((i, index) => {
      return (
        <li key={index}>
          <input type="checkbox" id={i} value={i} onClick={handleYearFilter} checked={searchYearCheck[i]} />
          <label htmlFor={i}>
            <span className="cat">{i}</span>
          </label>
        </li>
      );
    });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = brandData;
    if (search.length) {
      let tempUser = allData.filter((item) => {
        let includes = item?.description?.User?.name?.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempName = allData.filter((item) => {
        let includes = item?.description?.object?.definition?.New?.banner_heading
          ? item?.description?.object?.definition?.New?.banner_heading?.toLowerCase().includes(search.toLowerCase())
          : item?.description?.object?.definition?.Old?.banner_heading?.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempResourceName = allData.filter((item) => {
        let includes = item?.description?.object?.definition?.New?.subject
          ? item?.description?.object?.definition?.New?.subject?.toLowerCase().includes(search.toLowerCase())
          : item?.description?.object?.definition?.New?.subject?.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      })
      let data = [...tempUser, ...tempName, ...tempResourceName];
      let unique = [...new Set(data)];
      updatedData = unique;
      // console.log("updateddata", updatedData)
    } else {
      updatedData = allData;
    }

    if (actiontype.length) {
      let tempAction = updatedData;
      let tempResult = tempAction.filter((item) => {
        const startsWith = actiontype.find((post, index) => {
          if (post.toString() === item?.description?.verb?.Action?.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (month.length) {
      let tempMonth = updatedData;
      let tempResult = tempMonth.filter((item) => {
        const startsWith = month.find((post, index) => {
          let monthNumber = new Date(item?.description?.timestamp).getMonth();
          if (post.toString() === monthNumber.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (year.length) {
      let tempYear = updatedData;
      let tempResult = tempYear.filter((item) => {
        const startsWith = year.find((post, index) => {
          let yearNumber = new Date(item?.description?.timestamp).getFullYear();
          if (post.toString() === yearNumber.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    return updatedData;
  };

  const resetFilter = () => {
    setSearch("");
    setActionType([]);
    setSearchActionTypeCheck({});
    setMonth([]);
    setSearchMonthCheck({});
    setYear([]);
    setSearchYearCheck({});
  };

  const oldValue = (row) => {
    const entries = Object.entries(row?.description?.object?.definition?.Old);
    if (row?.description?.verb?.Action === "Edited") {
      const diff = difference(row?.description?.object?.definition?.Old, row?.description?.object?.definition?.New);
      return diff.length !== 0 ? `${diff.sort()}` : "NA";
    }
    // else if (row.description.verb.Action === "Profile Picture Edited") {
    //   return  row.description.object.definition?.Old?.picture_me;
    // }
    else {
      return `${entries.map((entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "N/A"}`)}`;
    }
  };

  const NewValue = (row) => {
    const entries = Object.entries(row?.description?.object?.definition?.New);
    if (row?.description?.verb?.Action === "Edited") {
      const diff = difference(row?.description?.object?.definition?.New, row?.description?.object?.definition?.Old);
      return diff.length !== 0 ? `${diff.sort()}` : "NA";
    } else if (row?.description?.verb?.Action === "Profile Picture Edited") {
      return row?.description?.object?.definition?.New?.picture_me?.toString().length > 30
        ? "picture_me:" + row?.description?.object?.definition?.New?.picture_me
        : row?.description?.object?.definition?.New?.picture_me;
    } else {
      return `${entries.map((entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "N/A"}`)}`;
    }
  };

  const modifiedData = () => {
    let data = dataToRender();

    data = data?.map((row) => ({
      ...row,
      // ID: row?.id,
      User: row?.description?.User?.name ? row?.description?.User?.name : '-',
      "Resource Name": row?.description?.object?.definition?.New?.subject ? row?.description?.object?.definition?.New?.subject : row?.description?.object?.definition?.Old?.subject,
      "Resource Type": row?.resource_type ? row?.resource_type : "-",
      "Action Type": row?.description?.verb?.Action ? row?.description?.verb?.Action : '-',
      // "Old Value": Object.keys(row.description.object.definition.Old).length !== 0 ? `${Object.entries(row.description.object.definition.Old).map((entry) => entry[0] + ":" + entry[1])}` : "NA",
      "Old Value": Object.keys(row?.description?.object?.definition?.Old).length !== 0 ? oldValue(row) : "-",
      // "New Value": Object.keys(row.description.object.definition.New).length !== 0 ? `${Object.entries(row.description.object.definition.New).map((entry) => entry[0] + ":" + entry[1])}` : "NA",
      "New Value": Object.keys(row?.description?.object?.definition?.New).length !== 0 ? NewValue(row) : "-",
      Date: row?.description?.timestamp ? moment(row?.description?.timestamp).format(TABLE_DATE_TIME_FORMAT + ",") : "-"
    }));

    return data;
  };

  const exportData = (fileType, fileName) => {
    let data = modifiedData();

    const header = ["User", "Action Type", "Resource Type", "Resource Name", "Old Value", "New Value", "Date"];

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      // console.log(csvString);
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["User"],
          row["Action Type"],
          row["Resource Type"],
          row["Resource Name"],
          row["Old Value"],
          row["New Value"],
          row["Date"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        columnStyles: {
          0: {
            columnWidth: 20,
          },
          1: {
            columnWidth: 24,
          },
          2: {
            columnWidth: 24,
          },
          3: {
            columnWidth: 24,
          },
          4: {
            columnWidth: 40,
          },
          5: {
            columnWidth: 40,
          },
          6: {
            columnWidth: 20,
          },
        },
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="my-tickets-info-list Tickets-main-wrap">
          <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
            <div className="search-filter-div">
              <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                      <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                        <div id="assessment-table-main_filter" className="dataTables_filter">
                          <label>
                            <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                          </label>
                          <div className="filter-eff filter-data-btn">
                            <button className="filter-buttons">
                              <i className="fal fa-filter"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                    <div
                      className="filter-scroll"
                    >
                      <div
                        className="filter-scroll-inner filter-custom-new"
                      >
                        <div className="assessment-table-filter">
                          <div className="btn-type-filter dropdown-comman">
                            <div className="dropdown multiselect">
                              <button
                                className={`btn btn-default dropdown-toggle ${actiontype.length ? "btn-selected" : ""}`}
                                type="button"
                                id="dropdownMenu1"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span>
                                  Action Type <i className="fal fa-angle-down"></i>
                                </span>
                              </button>
                              <form className="dropdown-menu remove-list-style">
                                <ul aria-labelledby="dropdownMenu1">
                                  <li className="cat cat-skyblue">
                                    <input type="checkbox" id="action1" value="Created" onClick={handleActionTypeFilter} checked={searchActionTypeCheck.action1} />
                                    <label htmlFor="action1">
                                      <span className="cat cat-skyblue">
                                        <i className="fas fa-circle mr-1"></i> Created
                                      </span>
                                    </label>
                                  </li>
                                   <li className="cat cat-greenhex">
                                    <input type="checkbox" id="action2" value="Edited" onClick={handleActionTypeFilter} checked={searchActionTypeCheck.action2} />
                                    <label htmlFor="action2">
                                      <span className="cat cat-greenhex">
                                        <i className="fas fa-circle mr-1"></i> Edited
                                      </span>
                                    </label>
                                  </li>
                                  {/* <li className="cat cat-red">
                                    <input type="checkbox" id="action3" value="Deleted" onClick={handleActionTypeFilter} checked={searchActionTypeCheck.action3} />
                                    <label htmlFor="action3">
                                      <span className="cat cat-red">
                                        <i className="fas fa-circle mr-1"></i> Deleted
                                      </span>
                                    </label>
                                  </li> */}
                                </ul>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="assessment-table-filter">
                          <div className="btn-type-filter dropdown-comman">
                            <div className="dropdown multiselect">
                              <button
                                className={`btn btn-default dropdown-toggle ${month.length ? "btn-selected" : ""}`}
                                type="button"
                                id="dropdownMenu1"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span>
                                  Month <i className="fal fa-angle-down"></i>
                                </span>
                              </button>
                              <form className="dropdown-menu remove-list-style">
                                <ul aria-labelledby="dropdownMenu1">{renderMonthUI()}</ul>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="assessment-table-filter">
                          <div className="btn-type-filter dropdown-comman">
                            <div className="dropdown multiselect">
                              <button
                                className={`btn btn-default dropdown-toggle ${year.length ? "btn-selected" : ""}`}
                                type="button"
                                id="dropdownMenu1"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span>
                                  Year <i className="fal fa-angle-down"></i>
                                </span>
                              </button>
                              <form className="dropdown-menu remove-list-style">
                                <ul aria-labelledby="dropdownMenu1">{renderYearUI()}</ul>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="reset-btn-group">
                      <div className="button-reset dropdown-comman">
                        <button className="btn btn-primary" onClick={resetFilter}>
                          <i className="fal fa-redo"></i>Reset
                        </button>
                      </div>
                      <div className="files-export-group">
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("xlsx", tab=="notifications-template" ? "Notifications-Template-Audit-Trail" : "Bulk-Notifications-Audit-Trail");
                          }}
                          title="Export spreadsheet"
                        >
                          <i className="fal fa-file-excel icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("csv", tab=="notifications-template" ? "Notifications-Template-Audit-Trail" : "Bulk-Notifications-Audit-Trail");
                          }}
                          title="Export CSV"
                        >
                          <i className="fal fa-file-csv icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          onClick={() => {
                            exportData("pdf", tab=="notifications-template" ? "Notifications-Template-Audit-Trail" : "Bulk-Notifications-Audit-Trail");
                          }}
                          title="Export PDF"
                        >
                          <i className="fal fa-file-pdf icon"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isLoaded ? <SkeletonTicketList />
              : <DataTable
                data={dataToRender()}
                defaultSortField="Date"
                defaultSortAsc={true}
                columns={columns}
                pagination={true}
                noDataComponent={Str.noRecord}
                paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuditTrail;
