import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import { StatusRender } from "../../../../systemadministration/usermanagement/CheckRole";
import Str from "../../../../common/Str";
import { TABLE_ROWS_PER_PAGE } from "../../../../../utils/Constants";
import { handleTableScroll } from "../../../../../utils/commonFunction";

function ExamRegistrations({ studentData,loading }) {
  const { id } = useParams();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);

  const [deleterow, setDeleteRow] = useState(false);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    // console.log("studentData", studentData);
    if (studentData) {
    }
  }, [studentData]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = studentData;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes = item.id.toString().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempPeriod = allData.filter((item) => {
        let includes = item.period.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempIdPassword = allData.filter((item) => {
        let includes = item.exam.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempExamId = allData.filter((item) => {
        let includes = item.exam_id_number.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudentType = allData.filter((item) => {
        let includes = item.studentType.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempCenterAlias = allData.filter((item) => {
        let includes = item.centre_alias.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        // ...tempId,
        ...tempPeriod,
        ...tempIdPassword,
        ...tempExamId,
        ...tempStudentType,
        ...tempCenterAlias
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (status.length || search.length) {
      return updatedData;
    } else {
      return studentData;
    }
  };

  const columns = useMemo(() => [
    {
      name: "Exam Period",
      selector: "period",
      sortable: true,
      cell: (row) => (
        <Link
        to={`/studentAdministration/externalExam/details/open/registration/${row.id}/details/${row.exam}`}
        title={row.period} className="as-text-blue curser feature-name">
          <span className="textLimit100">{row.period ? row.period : "-"}</span>
        </Link>
      ),
    },
    { name: "ID or Passport", selector: "rsa_identity", sortable: true, cell: (row) => row.rsa_identity ? <span title={row.rsa_identity}>{row.rsa_identity}</span> : "-"},
    { name: "Exam ID Number", selector: "exam_id_number", sortable: true, cell: (row) => row.exam_id_number ? <span title={row.exam_id_number}>{row.exam_id_number}</span> : "-" },
    { name: "Student Type", selector: "studentType", sortable: true, cell: (row) => row.studentType ? <span title={row.studentType}>{row.studentType}</span> : "-" },
    { name: "Centre Alias", selector: "centre_alias", sortable: true, cell: (row) => row.centre_alias ? <span title={row.centre_alias}>{row.centre_alias}</span> : "-" },
    { name: "Status", selector: "status", sortable: true, cell: (row) => row.status ? <span title={row?.status}><StatusRender status={row?.status} /></span> : "-" },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            {/* <PermissionsGate scopes={["umedit"]}> */}
            <Link
            // href={`https://www.myaie.ac/cadcocms/exams_registrations_list_edit.php?exam=${row.exam}&registration=${row.id}`}
            to={`/studentAdministration/externalExam/details/open/registration/${row.id}/details/${row.exam}`}
            // href={`https://www.myaie.ac/cadcocms/exams_list_edit.php?uid=${id}`}
             className="btn btn-primary rounded-circle" title="Open">
              <i className="fal fa-folder-open"></i>
            </Link>
            {/* </PermissionsGate> */}
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Exam Period", "ID or Passport", "Exam ID Number", "Student Type", "Center Alias", "Status"];

    data = data?.map((row) => ({
      ...row,
      "Exam Period": row.period ? row.period : "-",
      "ID or Passport": row?.rsa_identity ? row?.rsa_identity : "-",
      "Exam ID Number": row.exam_id_number ? row.exam_id_number : "-",
      "Student Type": row?.studentType ? row.studentType : "-",
      "Center Alias": row.centre_alias ? row.centre_alias : "-",
      Status: row.status ? row.status : "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row["Exam Period"], row["ID or Passport"], row["Exam ID Number"], row["Student Type"], row["Center Alias"], row.Status];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };
  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Exam Registrations</div>
        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      <div className="custom-table-div filter-search-icon card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                      <div id="assessment-table-main_filter" className="dataTables_filter">
                        <label>
                          <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-button-group student-zindex">
                      <div className="filter-scroll">
                          <div className={`filter-scroll-inner filter-custom-new`}>

                          </div>
                      </div>
                      <div className="reset-btn-group">
                        <div className="button-reset dropdown-comman">
                          <button className="btn btn-primary" onClick={() => setSearch("")} title="Reset">
                            <i className="fal fa-redo"></i>Reset
                          </button>
                        </div>
                        <div className="files-export-group">
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("xlsx", "ExamRegistration");
                            }}
                            title="Export spreadsheet"
                          >
                            <i className="fal fa-file-excel icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("csv", "ExamRegistration");
                            }}
                            title="Export CSV"
                          >
                            <i className="fal fa-file-csv icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("pdf", "ExamRegistration");
                            }}
                            title="Export PDF"
                          >
                            <i className="fal fa-file-pdf icon"></i>
                          </button>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
              </div>
          </div>
      </div>

        {loading ? <SkeletonTicketList /> : <DataTable
          data={dataToRender()}
          defaultSortField="period"
          defaultSortAsc={true}
          columns={columns}
          pagination={true}
          noDataComponent={Str.noRecord}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />}
      </div>
    </div>
  );
}

export default ExamRegistrations;
