import React from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import Softphone from "../components/Softphone";
import { useLocation } from "react-router-dom";

const Courses = () => {
  const { pathname } = useLocation();
  const currentPath = pathname.split("/");
  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>

        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            <div className="sub-menu-right-block">
                
              <div className="wrap-box-right">
                {currentPath.includes("courseAdministration") &&
                  currentPath.includes("coursesDeon") && (
                    <div className="my-tickets-info-list Tickets-main-wrap">
                      <div className="my-tickets-heading-blk flex-direction mb-2">
                        <h4 className="text-left-align landing-heading heading_color_dynamic">
                            Courses
                        </h4>
                      </div>
                      <Softphone />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;
