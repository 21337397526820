import React from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  user: state.user,
})

class StudentStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        student: {},
      };
      if(this.props.user.status !='On Hold' && this.props.user.status !='In Active'){
      window.location.href='/home';
      }
  }
  
  render() {
    const  user  = this.props.user;
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="home-list-wrap">
            <div className="wlcome-user-info">
              <div className="row align-items-center">
                  <div className="col-md-6">
                  <h4 className="my-md-2 my-0">
                  Dear <span>{user.first_name} {user.second_name} {user.third_name} {user.last_name}</span>
                  </h4>
                   
                </div>
                
                </div>
                <div className="statusBody">
                     <br/><br/>
                      Your student account status has been set to, {user.status}. You will have limited access to your account. Please submit a ticket or contact student support for further information. 
                      <br/><br/><br/>
                      Kind regards, 
                      <br/><br/>
                      AIE
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, null)(StudentStatus);
