import { useFormik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import RenderPreviousValues from "./RenderPreviousValues";

function Accounts(props) {
  const { id } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [studentData, setStudentData] = useState();
  const [intakeYear, setIntakeYear] = useState([]);

  useEffect(() => {
    setStudentData(props.studentData);
  }, [props]);

  const formik = useFormikContext();

  // const formik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     upToDate: false,
  //     inCredit: false,
  //     creditAmount: ""
  //   },
  //   validationSchema: Yup.object({
  //   //   StudentNumber: Yup.string().required("Application Status is required"),
  //   }),
  //   onSubmit: (values) => {
  //     setDisabled(true);
  //     let formData = new FormData();
  //   },
  // });

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Accounts *</div>
      </div>
        {isLoaded ? (
          <SkeletonTicketList />
        ) : (
          <>
            <div className="row">
            <div className="col-md-6 col-lg-4">
                <label>&nbsp;</label>
                <div className="form-group-blk mb-3">
                  <label></label>
                  <div className="custom-check custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" className="custom-control-input" id="upToDate" name="select" checked={formik.values.upToDate} onChange={(e) => {
                      formik.setFieldValue("upToDate", e.target.checked)
                      props.setAccountCredit(oldValue => ({...oldValue, upToDate:e.target.checked}))
                      }} />
                    <label className="custom-control-label" htmlFor="upToDate">
                      Account is up to date
                    </label>
                  </div>
                </div>
              </div>
            <div className="col-md-6 col-lg-4">
                <label>&nbsp;</label>
                <div className="form-group-blk mb-3">
                  <label></label>
                  <div className="custom-check custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" className="custom-control-input" id="inCredit" name="select" checked={formik.values.inCredit} onChange={(e) => {
                      formik.setFieldValue("inCredit", e.target.checked)
                      props.setAccountCredit(oldValue => ({...oldValue, inCredit:e.target.checked}))
                    }} />
                    <label className="custom-control-label" htmlFor="inCredit">
                      Account is in credit
                    </label>
                  </div>
                </div>
              </div>
            <div className="col-md-6 col-lg-4">
            <label htmlFor="creditAmount">Credit Amount *</label>
            <div className="form-icon-group mb-4" title="creditAmount">
              <input type="number" 
              disabled={!formik.values.inCredit}
              className={"form-control" + (formik.errors.creditAmount && formik.touched.creditAmount ? " is-invalid" : "")}
              name="creditAmount" 
              id="creditAmount" 
              placeholder="Credit Amount" 
              title="creditAmount" 
              value={formik.values.creditAmount} 
              onChange={(e)=>formik.setFieldValue("creditAmount", e.target.value)}  
              />
            </div>
            <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.creditAmount} previousValue={studentData?.RegStudentsPreviousDetails?.creditAmount} />
          </div>
             
              {/* {studentData && <div className="col-md-4">
                  <div className="form-icon-group mb-4">
                    <a href={IMAGE_URL + studentData.pdf} target="_blank" className="as-text-blue curser" >
                      <i className="fal fa-file"></i>&nbsp;{studentData && studentData && studentData.pdf ? basename(studentData.pdf) : ""}
                    </a>
                    <a href="javascript:void(0);" className="as-text-red curser" onClick={handleDeletePdf}>
                      <i className="fal fa-trash-alt"></i>
                    </a>
                  </div>
                  <div className="form-icon-group mb-4">
                    <a href={IMAGE_URL + studentData.pdf} className="as-text-blue curser">
                      <i className="fal fa-file"></i>&nbsp;{studentData && studentData && studentData.image ? basename(studentData.image) : ""}
                    </a>
                    <a href="javascript:void(0);" className="as-text-red curser" onClick={handleDeleteImg}>
                      <i className="fal fa-trash-alt"></i>
                    </a>
                  </div>
                </div>} */}
            </div>
          </>
        )}
      {/* <hr /> */}
    </div>
  );
}

export default Accounts;
