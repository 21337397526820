import React from "react";
import { getCurrentTimeHomePage, wishTiming } from "./Helper";
import{originalValues} from "./Helper";
export default function HomeWishes() {
  const user = JSON.parse(localStorage.getItem("user")); 
  //const [realTime, setRealTime] = useState(new Date().toLocaleString());
  return (
    <>
      <div className="wlcome-user-info">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h4 className="my-md-2 my-0">
              {wishTiming(new Date())} <span>{user!=null && originalValues(user.Firstname+" "+user.Lastname)}</span>
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}
