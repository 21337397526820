import React, { useEffect, useState } from "react";
import { useFormik, FieldArray, FormikProvider, Form } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { FormField } from "../../../common/FormFields";
import { getHeaderDropDownList } from "../../../../services/LayoutHeaderServices";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  addEventLandingPage,
  addUpcomingEventPage,
  getUpcomingEventDetails,
  getUpcomingEventListDropDown,
  updateEventLandingPage,
  updateUpcomingEventPage,
} from "../../../../services/EventService";
import {
  FORM_DATE_FORMAT,
  FORM_TIME_FORMAT,
} from "../../../../utils/Constants";
import moment from "moment";
import PermissionsGate from "../../../../utils/permissionGate";
import { getBrandTemplateCampus } from "../../../../services/BrandTemplateServices";

const EventDetails = ({ setHeaderName }) => {
  const { subId, subTab } = useParams();
  const history = useHistory();
  const [eventData, setEventData] = useState({});
  const [brandArr, setBrandArr] = useState([]);
  const [schoolArr, setSchoolArr] = useState([]);
  const [eventCampusArr,setEventCampusArr] = useState([]);
  const [relatedSchoolArr, setRelatedSchoolArr] = useState([]);
  const [onCampus,setOnCampus] = useState(false);
  const [virtual,setVirtual] = useState(false);
  const [statusArr,setStatusArr] = useState([{value : 0,label : 'Unpublished'},{value : 1,label : 'Published'}]);
  const [popupArr, setPopupArr] = useState([]);
  const [collapsedTracks, setCollapsedTracks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getUpcomingEventListDropDown();
        setSchoolArr(res.data.schoolList || []);
        setBrandArr(res.data.brandTemplateList || []);
        setRelatedSchoolArr(res.data.relatedSchool || []);
        setPopupArr(res.data.getPopupList || []);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (subId) {
      const fetchEventData = async () => {
        try {
          const res = await getUpcomingEventDetails(subId);
          const event = res.data.Data;
          event.brandTemplete = event.brandTemplete?.map((el) => el.BrandID);
          event.schools = event.schools?.map((el) => el.school_id);
          event.eventTrack = event.eventTrack?.map((track) => ({
            ...track,
            collapsed: false,
          }));
          if (event.EventTime) {
            const eventTimeParts = event.EventTime.split(":");
            if (eventTimeParts.length === 3) {
              const date = new Date();
              date.setHours(
                eventTimeParts[0],
                eventTimeParts[1],
                eventTimeParts[2]
              );
              event.EventTime = date;
            }
          }
          if (event.EndEventTime) {
            const endEventTimeParts = event.EndEventTime.split(":");
            if (endEventTimeParts.length === 3) {
              const date = new Date();
              date.setHours(
                endEventTimeParts[0],
                endEventTimeParts[1],
                endEventTimeParts[2]
              );
              event.EndEventTime = date;
            }
          }
          setEventData(event);
          setHeaderName(res.data?.Data?.EventTitle);
          localStorage.setItem("headerName", res.data?.Data?.EventTitle);
        } catch (error) {
          console.error(error);
        }
      };
      fetchEventData();
    }
  }, [subId]);

  const initialValues = {
    EventTitle: eventData.EventTitle || "",
    EventDescription: eventData.EventDescription || "",
    Brand: eventData.brandTemplete || [],
    schools: eventData.schools || [],
    ShowTitleInBody: eventData.ShowTitleInBody || 1,
    BodyContent: eventData.BodyContent || "",
    upcomingBodyContent: eventData.upcomingBodyContent || "",
    SliderID: eventData.SliderID || "",
    PopupID: eventData.PopupID || "",
    event_type : eventData.event_type || "",
    FormDestinationEmail: eventData.FormDestinationEmail || "",
    conversion_code: eventData.conversion_code || "",
    MetaTitle: eventData.MetaTitle || "",
    MetaDescription: eventData.MetaDescription || "",
    MetaKeywords: eventData.MetaKeywords || "",
    isAddCustomCss: eventData.isAddCustomCss || 0,
    customCss: eventData.customCss || "",
    eventDate: eventData.EventDate ? new Date(eventData.EventDate) : null,
    EventTime: eventData.EventTime || null,
    EndEventTime: eventData.EndEventTime || null,
    campus_id : eventData.campus_id || "",
    IsPublished : eventData.IsPublished || "",
    tracks: eventData.eventTrack || [
      { TrackName: "", School: "", TrackSchedule: "", collapsed: false },
    ],
  };

  const validationSchema = Yup.object().shape({
    EventTitle: Yup.string()
      .required("Page Title is required")
      .trim()
      .nullable(),
    Brand: Yup.array().min(1, "At least one template is required"),
    BodyContent: Yup.string().required("Page Content is required"),
    eventDate: Yup.date().nullable().required("Event Date is required"),
    EventTime: Yup.date().nullable().required("Event Start Time is required"),
    EndEventTime: Yup.date().nullable().required("Event End Time is required"),
    eventDate: Yup.date().nullable().required("Event Date is required"),
    IsPublished :Yup.string().required("Status is required"),
    EventTime: Yup.date().nullable().required("Event Start Time is required"),
    EndEventTime: Yup.date()
      .nullable()
      .required("Event End Time is required")
      .test(
        "is-greater",
        "End time must be after start time",
        function (value) {
          const { EventTime } = this.parent;
          return value && EventTime && value > EventTime;
        }
      ),
    tracks: Yup.array()
      .of(
        Yup.object({
          TrackName: Yup.string().required("Track Name is required"),
          School: Yup.string().required("Related School is required"),
          TrackSchedule: Yup.string().required("Scheduler is required"),
        })
      )
      .min(1, "At least one event track is required"),
  });

  useEffect(() => {
    getBrandTemplateCampus()
    .then((res) => {
      setEventCampusArr(res.data?.campusLocationFilters)
    }).catch((err) => {
      console.log("error:",err);
    })
  },[])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        values.tracks = values.tracks.map((track, index) => ({
          ...track,
          collapsed: collapsedTracks[index] || false,
        }));
        values.eventDate = moment(values?.eventDate).format("YYYY-MM-DD");
        values.EventTime = moment(values?.EventTime).format("HH:mm:ss");
        values.EndEventTime = moment(values?.EndEventTime).format("HH:mm:ss");
        if (subTab === "create") {
          await addUpcomingEventPage(values);
          Swal.fire(
            "Success!",
            "Upcoming event created successfully!",
            "success"
          );
          history.push("/websiteManagement/events/upcoming_events/table");
        } else if (subTab === "open") {
          await updateUpcomingEventPage({ ...values, EventId: subId });
          Swal.fire(
            "Success!",
            "Upcoming event updated successfully!",
            "success"
          );
          history.push("/websiteManagement/events/upcoming_events/table");
        }
      } catch (error) {
        Swal.fire(
          "Error!",
          `Failed to ${subTab === "create" ? "create" : "update"
          } upcoming event`,
          "error"
        );
        console.log(error);
      }
      setSubmitting(false);
    },
  });

  const handleEditorChange = (key, value) => {
    formik.setFieldValue(key, value);
  };

  const toggleCollapse = (index) => {
    formik.setFieldValue(
      `tracks[${index}].collapsed`,
      !formik.values.tracks[index].collapsed
    );
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const handleSearchDateTime = (e) => {
    let searchedValue = e.target.value;
    let tempArr = document.getElementsByClassName(
      "react-datepicker__time-list-item"
    );
    let list = Array.from(tempArr);
    if (!list.length) return;
    list.forEach((item) => {
      if (!item.innerHTML.includes(searchedValue)) {
        item.classList.add("d-none");
      } else {
        item.classList.remove("d-none");
      }
    });
  };
  return (
    <div className="card card-body-inr">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="edit-icon new-card-header">
                <h4 className="card-header">Event Information</h4>
              </div>
            </div>
            <FormField
              field={{ name: "EventTitle", label: "Event Title" }}
              formik={formik}
              col_lg={4}
              col_md={4}
            />
            <FormField
              field={{
                name: "EventDescription",
                label: "Event Description",
                required: false,
              }}
              formik={formik}
              col_lg={4}
              col_md={4}
            />
            <div className="col-md-4 col-lg-4">
              <div className="form-group-blk mb-3">
                <label htmlFor="CloseDate">Event Date* </label>
                <div className="datepicker-container">
                  <DatePicker
                    selected={formik.values.eventDate}
                    onChange={(date) => formik.setFieldValue("eventDate", date)}
                    minDate={new Date()}
                    filterTime={filterPassedTime}
                    dateFormat={FORM_DATE_FORMAT}
                    showYearDropdown
                    scrollableYearDropdown
                    className={`form-control cursor-pointer ${formik.errors.eventDate && formik.touched.eventDate
                        ? "is-invalid"
                        : ""
                      }`}
                    title="Start Date"
                    onBlur={formik.handleBlur}
                    placeholderText="Select Date"
                    timeIntervals={15}
                    onChangeRaw={(e) => e.preventDefault()}
                  />
                  <i className="fal fa-calendar-alt"></i>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="form-group-blk mb-3">
                <label htmlFor="CloseDate">Event Start Time* </label>
                <div className="m-auto time-select datepicker-container">
                  <DatePicker
                    className={`form-control cursor-pointer ${formik.errors.EventTime && formik.touched.EventTime
                        ? "is-invalid"
                        : ""
                      }`}
                    selected={formik.values.EventTime}
                    onChange={(date) => formik.setFieldValue("EventTime", date)}
                    showTimeSelect
                    showTimeSelectOnly
                    filterTime={filterPassedTime}
                    timeIntervals={5}
                    timeCaption="Time"
                    dateFormat={FORM_TIME_FORMAT}
                    timeFormat={FORM_TIME_FORMAT}
                    onChangeRaw={(e) => handleSearchDateTime(e)}
                    title="Event Start Time"
                    onBlur={formik.handleBlur}
                    placeholderText="Select From Time"
                  />
                  <i className="fal fa-clock"></i>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="form-group-blk mb-3">
                <label htmlFor="CloseDate">Event End Time* </label>
                <div className="m-auto time-select datepicker-container">
                  <DatePicker
                    className={`form-control cursor-pointer ${formik.errors.EndEventTime && formik.touched.EndEventTime
                        ? "is-invalid"
                        : ""
                      }`}
                    selected={formik.values.EndEventTime}
                    onChange={(date) =>
                      formik.setFieldValue("EndEventTime", date)
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    filterTime={filterPassedTime}
                    timeIntervals={5}
                    timeCaption="Time"
                    dateFormat={FORM_TIME_FORMAT}
                    timeFormat={FORM_TIME_FORMAT}
                    onChangeRaw={(e) => handleSearchDateTime(e)}
                    title="Event End Time"
                    onBlur={formik.handleBlur}
                    placeholderText="Select From Time"
                  />
                  <i className="fal fa-clock"></i>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <label htmlFor="Brand">
                Assigned Brand Templates *{" "}
                <i className="fal fa-info-circle"></i>
              </label>
              <div className="form-icon-group mb-4">
                <Select
                  className={`form-control custom-select-box ${formik.touched.Brand && formik.errors.Brand && "is-invalid"
                    }`}
                  name="Brand"
                  value={brandArr.filter((val) =>
                    formik.values.Brand.includes(val.value)
                  )}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "Brand",
                      value ? value.map((v) => v.value) : []
                    )
                  }
                  isClearable
                  onBlur={formik.handleBlur}
                  options={brandArr}
                  maxMenuHeight={175}
                  placeholder="Select"
                  isMulti
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <label htmlFor="eventCampus">
                Event Campus *{" "}
                <i className="fal fa-info-circle"></i>
              </label>
              <div className="form-icon-group mb-4">
                <Select
                  className={`form-control custom-select-box ${formik.touched.campus_id && formik.errors.campus_id && "is-invalid"
                    }`}
                  name="campus_id"
                  value={eventCampusArr.length ? eventCampusArr?.filter((val) =>
                    formik.values.campus_id == val.value
                  ) : ""}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "campus_id",
                      value.value || ""
                    )
                  }
                  isClearable
                  onBlur={formik.handleBlur}
                  options={eventCampusArr}
                  maxMenuHeight={175}
                  placeholder="Select"
                  // isMulti
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <label htmlFor="eventCampus">
                Event Type {" "}
                <i className="fal fa-info-circle"></i>
              </label>
              <div className="form-icon-group mb-4">
                <Select
                  className={`form-control custom-select-box ${formik.touched.event_type && formik.errors.event_type && "is-invalid"
                    }`}
                  name="event_type"
                  value={([
                    {
                      value : 0,
                      label : "On Campus",
                    },
                    {
                      value : 1,
                      label : "Virtual",
                    },
                    {
                      value : 2,
                      label : "Virtual & On Campus",
                    }
                  ]).filter((value) => formik.values.event_type == value.value)}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "event_type",
                      value.value || "",
                    )
                  }
                  isClearable
                  onBlur={formik.handleBlur}
                  options={[
                    {
                      value : 0,
                      label : "On Campus",
                    },
                    {
                      value : 1,
                      label : "Virtual",
                    },
                    {
                      value : 2,
                      label : "Virtual & On Campus",
                    }
                  ]}
                  maxMenuHeight={175}
                  placeholder="Select"
                  // isMulti
                />
              </div>
            </div>

<FormField
            field={{
              name: "IsPublished",
              label: "Status",
              info: true,
              required: true,
            }}
            formik={formik}
            selectOptions={statusArr}
          />


            <div className="col-md-12 col-lg-12">
              <label htmlFor="schools">
                Assigned Schools <i className="fal fa-info-circle"></i>
              </label>
              <div className="form-icon-group mb-4">
                <Select
                  className={`form-control custom-select-box ${formik.touched.schools &&
                    formik.errors.schools &&
                    "is-invalid"
                    }`}
                  name="schools"
                  value={schoolArr.filter((val) =>
                    formik.values.schools.includes(val.value)
                  )}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "schools",
                      value ? value.map((v) => v.value) : []
                    )
                  }
                  isClearable
                  onBlur={formik.handleBlur}
                  options={schoolArr}
                  maxMenuHeight={175}
                  placeholder="Select"
                  isMulti
                />
              </div>
            </div>

            <div className="col-md-6 col-lg-6 d-flex align-items-center mb-4">
              <label className="mb-0">
                Enable Event Schedule <i className="fal fa-info-circle"></i>
              </label>
              <div className="toggle-switch ml-2">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.ShowTitleInBody === 1}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "ShowTitleInBody",
                        e.target.checked ? 1 : 0
                      )
                    }
                  />
                  <span className="slider slider-round"></span>
                </label>
              </div>
            </div>

            <div className="col-md-12">
              <label htmlFor={"disclaimer_tc"}>Event Content *</label>
              <div className="form-icon-group mb-6 laptop-pricing">
                <HtmlInputEditor
                  editorState={formik.values.BodyContent}
                  setEditorState={(value) =>
                    handleEditorChange("BodyContent", value)
                  }
                  isInValid={formik.errors.BodyContent}
                  isCKEditor={true}
                  hideSign={true}
                />
              </div>
            </div>

            <div className="col-md-12">
              <label htmlFor={"disclaimer_tc"}>Upcoming Event Content *</label>
              <div className="form-icon-group mb-6 laptop-pricing">
                <HtmlInputEditor
                  editorState={formik.values.upcomingBodyContent}
                  setEditorState={(value) =>
                    handleEditorChange("upcomingBodyContent", value)
                  }
                  isInValid={formik.errors.upcomingBodyContent}
                  isCKEditor={true}
                  hideSign={true}
                />
              </div>
            </div>

            {/* Event Tracks Section */}
            {formik.values.ShowTitleInBody === 1 && <>
              <div className="col-md-12">
                <div className="edit-icon new-card-header">
                  <h4 className="card-header">Event Tracks</h4>
                </div>
              </div>
              <FieldArray name="tracks">
                {({ push, remove }) => (
                  <>
                    {formik.values?.tracks?.map((track, index) => (
                      <div key={index} className="col-md-12 mb-4">
                        <div className="row align-items-center">
                          <div className="col-md-10">
                            <div className="edit-icon new-card-header">
                              <h4 className="card-header">{`${track.collapsed ? "Minimized " : ""
                                }Event Track ${index + 1}`}</h4>
                            </div>
                          </div>
                          <div className="col-md-2 text-right">
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() => toggleCollapse(index)}
                            >
                              {track.collapsed ? (
                                <FontAwesomeIcon
                                  color="var(--topbar-color)"
                                  icon={faChevronDown}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  color="var(--topbar-color)"
                                  icon={faChevronUp}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                        {!track.collapsed && (
                          <>
                            <div className="row align-items-center">
                              <FormField
                                field={{
                                  name: `tracks[${index}].TrackName`,
                                  label: `Event Track Name`,
                                  required: true,
                                  value:
                                    formik.values?.tracks?.[index]?.TrackName,
                                  isInvalid:
                                    formik.touched?.tracks?.[index]?.TrackName &&
                                    formik.errors?.tracks?.[index]?.TrackName,
                                }}
                                formik={formik}
                                col_md={12}
                                col_lg={6}
                              />
                              <FormField
                                field={{
                                  name: `tracks[${index}].School`,
                                  label: `Related School`,
                                  value: formik.values?.tracks?.[index]?.School,
                                  required: false,
                                  info: true,
                                  isInvalid:
                                    formik.touched?.tracks?.[index]?.School &&
                                    formik.errors?.tracks?.[index]?.School,
                                }}
                                formik={formik}
                                selectOptions={relatedSchoolArr}
                                col_md={12}
                                col_lg={6}
                              />
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <label htmlFor={`tracks[${index}].TrackSchedule`}>
                                  Event Track Scheduler *
                                </label>
                                <div className="form-icon-group mb-4 laptop-pricing">
                                  <HtmlInputEditor
                                    editorState={
                                      formik.values?.tracks?.[index]
                                        ?.TrackSchedule
                                    }
                                    setEditorState={(value) => {
                                      handleEditorChange(
                                        `tracks[${index}].TrackSchedule`,
                                        value
                                      );
                                    }}
                                    isInValid={
                                      formik.errors.tracks?.[index]?.TrackSchedule
                                    }
                                    isCKEditor={true}
                                    hideSign={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="row">
                          <div className="col-md-12 text-left">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => remove(index)}
                            >
                              <i className="fal fa-trash-alt"></i> Remove Event
                              Track
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          push({
                            TrackName: "",
                            School: "",
                            TrackSchedule: "",
                            collapsed: false,
                          })
                        }
                      >
                        Add Event Track
                      </button>
                    </div>
                  </>
                )}
              </FieldArray>
            </>}

            <div className="col-md-12">
              <hr
                className="border border-2"
                style={{ borderColor: "#E6EBF1" }}
              />
            </div>

            <div className="col-md-12">
              <div className="edit-icon new-card-header">
                <h4 className="card-header">Page Settings</h4>
              </div>
            </div>
            <FormField
              field={{ name: "PopupID", label: "Popup", required: false }}
              formik={formik}
              selectOptions={popupArr}
              col_md={6}
              col_lg={6}
            />
            <FormField
              field={{
                name: "FormDestinationEmail",
                label: "Form Email",
                required: false,
              }}
              formik={formik}
              col_md={6}
              col_lg={6}
            />

            <div className="col-md-12">
              <div className="edit-icon new-card-header">
                <h4 className="card-header">
                  <FontAwesomeIcon icon={faGear} color="var(--topbar-color)" />{" "}
                  Page Settings
                </h4>
              </div>
            </div>

            <FormField
              field={{
                name: "conversion_code",
                label: "Conversion Code",
                required: false,
              }}
              formik={formik}
              col_md={6}
              col_lg={6}
            />
            <FormField
              field={{
                name: "MetaTitle",
                label: "Meta Title",
                required: false,
              }}
              formik={formik}
              col_md={6}
              col_lg={6}
            />
            <FormField
              field={{
                name: "MetaDescription",
                label: "Meta Description",
                required: false,
              }}
              formik={formik}
              col_md={6}
              col_lg={6}
            />
            <FormField
              field={{
                name: "MetaKeywords",
                label: "Meta Keywords",
                required: false,
              }}
              formik={formik}
              col_md={6}
              col_lg={6}
            />

            {/* <div className="col-md-12">
              <div className="edit-icon new-card-header">
                <h4 className="card-header">
                  <FontAwesomeIcon icon={faGear} color="var(--topbar-color)" />{" "}
                  Style Settings
                </h4>
              </div>
            </div> */}
            {/* <div className="col-md-6 col-lg-3 d-flex align-items-center mb-3">
              <label className="mb-0">
                Add Custom CSS<i className="fal fa-info-circle"></i>
              </label>
              <div className="toggle-switch ml-2">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.isAddCustomCss === 1}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "isAddCustomCss",
                        e.target.checked ? 1 : 0
                      )
                    }
                  />
                  <span className="slider slider-round"></span>
                </label>
              </div>
            </div> */}
            
            {/* {formik.values.isAddCustomCss ? (
              <div className="col-md-12">
                <div className="form-group-blk mb-3">
                  <label>Custom CSS*</label>
                  <textarea
                      className={"form-control " + (formik.errors.customCss && formik.touched.customCss ? " is-invalid" : "")}
                      rows="5"
                      placeholder="customCss"
                      value={formik.values.customCss}
                      name="customCss"
                      id="notes"
                      title="Custom css"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></textarea>
                </div>
              </div>
            ) : 
            null
            } */}
          </div>
          <div className="form-group form-group-save-cancel mt-4">
            <PermissionsGate scopes={[subTab == "create" ? 'wbseventupcomingadd' : 'wbseventupcomingedit']}>

            <button type="submit" className="btn btn-primary">
              <i className="fal fa-save"></i>Save
            </button>
            </PermissionsGate>
          </div>
          {formik.errors && (
            <div className="col-md-12 mt-3">
              {Object.keys(formik.touched).map((key) => {
                if (key === "tracks") {
                  return formik.errors.tracks?.map((trackError, index) =>
                    trackError
                      ? Object.keys(trackError).map((trackKey) => (
                        <div
                          className="invalid-feedback d-block"
                          key={`${index}-${trackKey}`}
                        >
                          Track {index + 1}: {trackError[trackKey]}
                        </div>
                      ))
                      : null
                  );
                } else {
                  return (
                    formik.errors[key] && (
                      <div className="invalid-feedback d-block" key={key}>
                        {formik.errors[key]}
                      </div>
                    )
                  );
                }
              })}
            </div>
          )}
        </Form>
      </FormikProvider>
    </div>
  );
};

export default EventDetails;
