import React, {useState, useEffect} from "react";
import { useFormikContext } from "formik";
// import { EditorState, convertToRaw, AtomicBlockUtils } from "draft-js";
import HtmlInputEditor from "../../../common/HtmlInputEditor";

const Reject = (props) => {
  const [studentData, setStudentData] = useState();
  // console.log(props.rejectEditorState);
  // console.log(props);
  // const HandleSpan = (props) => {
  //   return (
  //     <span style={styles.handle} data-offset-key={props.offsetKey}>
  //       {props.children}
  //     </span>
  //   );
  // };

  // function handleStrategy(contentBlock, callback, contentState) {
  //   findWithRegex(HANDLE_REGEX, contentBlock, callback);
  // }

  // function findWithRegex(regex, contentBlock, callback) {
  //   const text = contentBlock.getText();
  //   let matchArr, start;
  //   while ((matchArr = regex.exec(text)) !== null) {
  //     start = matchArr.index;
  //     callback(start, start + matchArr[0].length);
  //   }
  // }


  // const compositeDecorator = new CompositeDecorator([
  //   { strategy: handleStrategy, component: HandleSpan },
  // ]);

 


  const styles = {
    editor: {
      border: "1px solid #ddd",
      cursor: "text",
      fontSize: 16,
      minHeight: "40px",
      padding: 10,
      background: "#000",
    },
    handle: {
      color: "rgba(98, 177, 254, 1.0)",
      direction: "ltr",
      unicodeBidi: "bidi-override",
    },
  };

  const INLINE_STYLES = [
    { label: <i>I</i>, style: "ITALIC" },
    { label: <b>B</b>, style: "BOLD" },
    { label: <u>U</u>, style: "UNDERLINE" },
  ];



  const HANDLE_REGEX = /\@[\w]+/g;

  

  useEffect(() => {
    setStudentData(props.studentData);  
    
    // const contentBlock = convertFromRaw(tryParseJSONObject(props.studentData?.re_reg_data?.reject_status_message));
    // console.log("contentBlock,",contentBlock);
    // setEditorState1(EditorState.createWithContent(contentBlock));
    // setEditorState1(props.studentData?.re_reg_data?.reject_status_message)


    // setEditorState1(props.studentData?.re_reg_data?.subject_notes)
    // let check = tryParseJSONObject(props.studentData?.re_reg_data?.subject_notes);
    // if (check) {
    //     const q_optionBlock = convertFromRaw(JSON.parse(props.studentData?.re_reg_data?.subject_notes));
    //     setEditorState1(EditorState.createWithContent(q_optionBlock)); 
    // } else {    
    // const q_optionBlock = convertFromHTML(props.studentData?.re_reg_data?.subject_notes ? props.studentData?.re_reg_data?.subject_notes : "");
    // const q_option = ContentState.createFromBlockArray(q_optionBlock?.contentBlocks, q_optionBlock?.entityMap);
    // setEditorState1(EditorState.createWithContent(q_option)); 
    // }


  }, [props]);

  const formik = useFormikContext();

  const onsetData = (data) => {
    // setEditorState1(data)
    props.setRejectEditorState(data)
    // console.log("data", data)
    // let msg = JSON.stringify(convertToRaw(editorState1.getCurrentContent()));
    formik.setFieldValue("rejectMsg", data)
  }

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Reject Details</div>
      </div>
        <>
          <div className="row">
            <div className="col">
              <div className="form-icon-group d-block">
                <label>Rejected Application Message</label>
                {/* <HtmlInputEditor editorState={editorState1} setEditorState={onsetData}/> */}
                {!props.disabled ? <HtmlInputEditor editorState={props.rejectEditorState} setEditorState={onsetData} isCKEditor={true} hideSign={true} /> : ""}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group-blk mb-3">
                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" className="custom-control-input" id="CRMReject" name="select" checked={formik.values.CRMReject} onChange={(e) => {formik.setFieldValue("CRMReject", e.target.checked);
                  props.clickedCheck("check_crm",e.target.checked);
                }} />
                  <label className="custom-control-label" htmlFor="CRMReject">
                  Netsuite Account Updated 
                  </label>
                </div>
              </div>
              <div className="form-group-blk mb-3">
                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" className="custom-control-input" id="XEROReject" name="select" checked={formik.values.XEROReject} onChange={(e) => {formik.setFieldValue("XEROReject", e.target.checked);props.clickedCheck("check_xero",e.target.checked);}} />
                  <label className="custom-control-label" htmlFor="XEROReject">
                  Netsuite Opportunity Updated
                  </label>
                </div>
              </div>
              <div className="form-group-blk mb-3">
                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" className="custom-control-input" id="PORTALReject" name="select" checked={formik.values.PORTALReject} onChange={(e) => {formik.setFieldValue("PORTALReject", e.target.checked); props.clickedCheck("check_portal",e.target.checked);}} />
                  <label className="custom-control-label" htmlFor="PORTALReject">
                  AMI Course and Programme updated
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            {formik.values.crm_checked_date ? <div className="form-group-blk mb-3"><div className="custom-check custom-control custom-checkbox custom-control-inline"><label>Updated by {formik.values.crm_checked_and_updated && formik.values.crm_checked_and_updated.Firstname} {formik.values.crm_checked_and_updated && formik.values.crm_checked_and_updated.Lastname} on {formik.values.crm_checked_date}</label></div></div> : <label>&nbsp;</label>}
              {formik.values.xero_checked_date ? <div className="form-group-blk mb-3"><div className="custom-check custom-control custom-checkbox custom-control-inline"><label>Updated by {formik.values.xero_checked_and_updated && formik.values.xero_checked_and_updated.Firstname} {formik.values.xero_checked_and_updated && formik.values.xero_checked_and_updated.Lastname} on {formik.values.xero_checked_date}</label></div></div> : <label>&nbsp;</label>}
              {formik.values.portal_checked_date ? <div className="form-group-blk mb-3"><div className="custom-check custom-control custom-checkbox custom-control-inline"><label>Updated by {formik.values.portal_checked_and_updated && formik.values.portal_checked_and_updated.Firstname} {formik.values.portal_checked_and_updated && formik.values.portal_checked_and_updated.Lastname} on {formik.values.portal_checked_date}</label></div></div> : <label>&nbsp;</label>}
            </div>
          </div>
        </>
        {/* <div className="col-md-4">
              <p class="mt-4">Updated by <b>{studentData && studentData.student_prelim_update && studentData.student_prelim_update[0] && studentData.student_prelim_update[0].Firstname + " " + studentData.student_prelim_update[0].Lastname}</b> on <b>{studentData && studentData.re_reg_data && moment(studentData?.re_reg_data?.qc_updated_rco_date).format(`DD-MM-YY`) + " " + moment(studentData?.re_reg_data?.qc_updated_rco_date).format(TABLE_TIME_FORMAT) }</b></p>
            </div> */}
      {/* <hr /> */}
    </div>
  );
};

export default Reject;
