import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import {
  AddModule,
  GetListofModule,
  GetProgrammeStudyPathList,
  RemoveModuleFromProgramme,
  UpdateModule,
  createProgrammeStudyPath,
  getProgrammeStudyPathDetails,
  getProgrammeStudyPathModuleList,
  updateProgrammeStudyPath,
} from "../../../services/ProgramService";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import HtmlParser from "react-html-parser";
import TableStudyPath from "./TableStudyPath";
import axios from "axios";
import PermissionsGate from "../../../utils/permissionGate";
const statusArr = [
  {
    value: "Draft",
    label: HtmlParser(`<span class="red">Draft  </span> `),
  },
  {
    value: "New",
    label: HtmlParser(`<span class="skyblue"> New </span>`),
  },
  {
    value: "Final",
    label: HtmlParser(` <span class="green">Final</span>`),
  },
  {
    value: "Approved",
    label: HtmlParser(`<span class="yellowshade">Approved</span>`),
  },
  {
    value: "Inactive",
    label: HtmlParser(`<span class="blue">Inactive</span>`),
  },
];

const CreateStudyPath = (props) => {
  const {
    detail,
    description,
    setDescription,
    prescribedTextBooks,
    setPrescribedTextBooks,
    isEditorStateReady,
  } = props;
  const history = useHistory();
  const { id, type, subId } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [moduleList, setModuleList] = useState([]);
  const [modalDuration, setModalDuration] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  const [studyPathData1, setStudyPathData1] = useState([]);
  const [studyPathData, setStudyPathData] = useState([]);

  const [foundationalData1, setFoundationalData1] = useState([]);
  const [foundationalData, setFoundationalData] = useState([]);

  const [electiveData1, setElectiveData1] = useState([]);
  const [electiveData, setElectiveData] = useState([]);

  const [pageData, setPageData] = useState({});

  const [totalRows1, setTotalRows1] = useState(0);
  const [totalRows2, setTotalRows2] = useState(0);
  const [totalRows3, setTotalRows3] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("name");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [triggers, setTriggers] = useState(false)

  const [loading, setLoading] = useState(false);
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "name",
    sortOrder: "DESC",
  });
  const [search1, setSearch1] = useState("");
  const [search2, setSearch2] = useState("");
  const [search3, setSearch3] = useState("");

  const givenPermsisions = useSelector((state) => state.givenPermission);
  useEffect(() => {
    GetListofModule()
      .then((res) => {
        let moduleData = res?.data.map((mod) => ({
          value: mod.Id,
          label: mod.Portal,
        }));
        moduleData.push({ value: 0, label: "None" });
        setModuleList(moduleData);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["pmcmedit"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    let duration = [];
    for (let i = 1; i <= 52; i++) {
      let obj = { value: i, label: i };
      duration.push(obj);
    }
    setModalDuration(duration);
  }, []);

  const fetchStudyPathData = async (moduleType, cancelTokenSource, search) => {
    const apiData = {
      page: tableState.page,
      limit: tableState.perPage,
      key: tableState.sortKey,
      sort: tableState.sortOrder,
      search: search,
      exportStatus: type == "create" ? "false" : "true",
      programme_id:id,
      isAssigned: 1,
      module_type: moduleType,
      id: subId || 0,
    };

    try {
      const response = await getProgrammeStudyPathModuleList(apiData, {
        cancelToken: cancelTokenSource.token,
      });
      return response.data;
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(
          `Error fetching data for module type ${moduleType}:`,
          error
        );
      }
      return [];
    }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    setLoading(true);

    const fetchAllData = async () => {
      try {
        const [coreModules, foundationalModules, electiveModules] =
          await Promise.all([
            fetchStudyPathData(1, cancelTokenSource, search1),
            fetchStudyPathData(2, cancelTokenSource, search2),
            fetchStudyPathData(3, cancelTokenSource, search3),
          ]);
        setStudyPathData(type == "create" ? coreModules?.modules?.data : coreModules?.modules);
        setTotalRows1(type == "create" ? coreModules?.modules?.data : coreModules?.modules);
        setFoundationalData(type == "create" ? foundationalModules?.modules?.data : foundationalModules?.modules);
          setTotalRows2(type == "create" ? foundationalModules?.modules?.data : foundationalModules?.modules);
        setElectiveData(type == "create" ? electiveModules?.modules?.data : electiveModules?.modules);
          setTotalRows3(type == "create" ? electiveModules?.modules?.data : electiveModules?.modules);
          formik.setFieldValue("core_modules",type == "create" ? coreModules?.modules?.data?.map((item) => item.Id) || [] : coreModules?.modules.map((item) => item.Id) || [])
          formik.setFieldValue("foundational_modules",type == "create" ? foundationalModules?.modules?.data?.map((item) => item.Id) || [] : foundationalModules?.modules.map((item) => item.Id) || [])
          formik.setFieldValue("elective_modules",type == "create" ? electiveModules?.modules?.data?.map((item) => item.Id) || [] : electiveModules?.modules.map((item) => item.Id) || [])

      } finally {
        setLoading(false);
      }
    };

    fetchAllData();

    return () => {
      cancelTokenSource.cancel("Component unmounted");
    };
  }, [search1, search2,search1,sortkey,sortOrder,updateData,type]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const res = await getProgrammeStudyPathDetails(subId);
        if (!res.data.data && subId) {
          Swal.fire({
            icon: "Info",
            title: "Info",
            text: "No record found with this id",
          })
          history.push(`/program-management-center/programmes/study_path/table/${id}`)
        }
        setPageData(res.data?.data);
      } catch (error) {
          console.error(error);
          setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchAllData();
  }, [updateData]);
  const formik = useFormik({
    enableReinitialize: type === "open" || type === "duplicate" ? true : false,
    // enableReinitialize: true,
    initialValues: {
      programme_id: id,
      name: pageData?.name ? pageData.name : "",
      core_credits: pageData?.core_credits ? pageData.core_credits : "",
      foundational_credits: pageData?.foundational_credits
        ? pageData.foundational_credits
        : "",
      elective_credits: pageData?.elective_credits
        ? pageData.elective_credits
        : "",
      core_modules: studyPathData?.map(item => item.Id) || [],
      foundational_modules:  foundationalData?.map(item => item.Id) || [],
      elective_modules:  electiveData?.map(item => item.Id) || [],
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .max(255, "Name should not be greater than 255 Character")
        .trim(),
      core_credits: Yup.string()
        .required("Core credits is required")
        .max(255, "Core credits should not be greater than 255 Character")
        .trim(),
      foundational_credits: Yup.string()
        .required("Foundational credits is required")
        .max(
          255,
          "Foundational credits should not be greater than 255 Character"
        )
        .trim(),
      elective_credits: Yup.string()
        .required("Elective credits is required")
        .max(255, "Elective credits should not be greater than 255 Character")
        .trim(),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      if (type === "open") {
        updateProgrammeStudyPath({ ...values, id: subId })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Updated Successfully",
            });
            setDisabled(false);
            history.push(
              `/program-management-center/programmes/study_path/table/${id}`
            );
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err.response.data,
            });
            setDisabled(false);
          });
      } else {
        createProgrammeStudyPath(values)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added Successfully",
            });
            history.push(
              `/program-management-center/programmes/study_path/table/${id}`
            );
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err.response.data.message,
            });
          });
      }
    },
  });
  const handleSort = (column, sortDirection) => {
    setSortKey(column.selector);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  return (
    <div className="card card-profile-info-card">
      <div className="">
        <div className="card-body-inr card-body-info">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="new-card-header">
                  <div className="card-header">Details</div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="form-group-blk mb-3">
                  <label>Phase Name *</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.name && formik.touched.name
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Enter phase name"
                    name="name"
                    id="name"
                    title="Phase Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <TableStudyPath
                handleSort={handleSort}
                triggers={triggers}
                setTriggers={setTriggers}
                tableData={studyPathData}
                setTableData={setStudyPathData}
                tableData1={studyPathData1}
                setTableData1={setStudyPathData1}
                search={search1}
                setSearch={setSearch1}
                formik={formik}
                loading={loading}
                modelID={"addModules1"}
                label={"Core Modules"}
                name={"core_credits"}
                module_type={1}
                totalRows={totalRows1}
                ArrayFields={"core_modules"}
              />
              <TableStudyPath
                handleSort={handleSort}
                triggers={triggers}
                setTriggers={setTriggers}
                tableData={foundationalData}
                setTableData={setFoundationalData}
                tableData1={foundationalData1}
                setTableData1={setFoundationalData1}
                search={search2}
                setSearch={setSearch2}
                formik={formik}
                loading={loading}
                modelID={"addModules2"}
                label={"Foundational Modules"}
                name={"foundational_credits"}
                module_type={2}
                totalRows={totalRows2}
                ArrayFields={"foundational_modules"}

              />

              <TableStudyPath
                handleSort={handleSort}
                triggers={triggers}
                setTriggers={setTriggers}
                tableData={electiveData}
                setTableData={setElectiveData}
                tableData1={electiveData1}
                setTableData1={setElectiveData1}
                search={search3}
                setSearch={setSearch3}
                formik={formik}
                loading={loading}
                modelID={"addModules3"}
                label={"Elective Modules"}
                name={"elective_credits"}
                module_type={3}
                totalRows={totalRows3}
                ArrayFields={"elective_modules"}

              />
            </div>
            <div className="form-group form-group-save-cancel mt-4">
              
            <PermissionsGate scopes={[type == "open" ? 'pmcstudypathedit' : "pmcstudypathadd"]} RenderError={() => {
              return (<button
              className="btn btn-save btn-success"
              title="Save"
              disabled
              >
                <i className="fal fa-save"></i> Save
                </button>)
            }}>

              <button
                className="btn btn-save btn-success"
                type="submit"
                title="Save"
                disabled={disabled || (type == "open" && !formik.dirty)}
                >
                {disabled ? (
                  <i className="fas fa-cog fa-spin"></i>
                ) : (
                  <i className="fal fa-save"></i>
                )}
                Save
              </button>
                </PermissionsGate>
              <button
                className="btn btn-close btn-danger"
                type="button"
                title="Cancel"
                on onClick={() => {
                  if(id) {
                    formik.resetForm();
                    setUpdateData(!updateData)
                  } else {
                    history.goBack();
                  }
                }}
              >
                <i className="fal fa-times"></i>Cancel
              </button>
            </div>

            {Object.keys(formik.values).map((key) => {
              if (formik.touched[key] && formik.errors[key]) {
                return (
                  <div className="invalid-feedback d-block">
                    {formik.errors[key]}
                  </div>
                );
              }
            })}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateStudyPath;
