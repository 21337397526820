import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { useParams, Link, useHistory } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { GetAllDropdownValues, GetQualificationList, AddOrUpdateReregistration, reRegistrationDetails, GetAllDropdownValuesCancelToken } from "../../../services/RegistrationService";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { TrimText } from "../../common/TrimText";
import Tablefilter from "../../common/Tablefilter";
import { RenderLearningMethod, RenderSchool } from "../../../utils/CommonGroupingItem";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import { assignedArticulationToQualification, getArticulationQualificationList, unassignedArticulationToQualification } from "../../../services/programArticulationService";
import $ from "jquery";
import PermissionsGate from "../../../utils/permissionGate";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import PopupComponent from "../../common/PopupComponent";
import Select from "react-select";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

let tempData;
let ids = [];

const Articulations = () => {
    const { page, tab, id } = useParams();
    const history = useHistory()

    const [search, setSearch] = useState("");
    const [search1, setSearch1] = useState("");

    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(true);

    const [studentList, setStudentList] = useState([]);
    const [assignedStudentList, setAssignedStudentList] = useState([]);

    const [assignIntake, setAssignIntake] = useState({ arr: [], checkObj: {} })
    const [availableIntake, setAvailableIntake] = useState({ arr: [], checkObj: {} })
    const [assignSchool, setAssignSchool] = useState({ arr: [], checkObj: {} })
    const [availableSchool, setAvailableSchool] = useState({ arr: [], checkObj: {} })
    const [assignLearningMethod, setAssignLearningMethod] = useState({ arr: [], checkObj: {} })
    const [availableLearningMethod, setAvailableLearningMethod] = useState({ arr: [], checkObj: {} })
    const [intakeArr, setIntakeArr] = useState([])
    const [schoolArr, setSchoolArr] = useState([])
    const [learningMethodArr, setLearningMethodArr] = useState([])
    const [programmeReRegDetails, setProgrammeReRegDetails] = useState({});
    const [isGearIconShown, setIsGearIconShown] = useState(false);
    const givenPermsisions = useSelector((state) => state.givenPermission);

    const [isDisabled, setIsDisabled] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        handleTableScroll()
      }, [loading])

    const formik = useFormik({
        initialValues: {
        intake: [],
        school: [],
        learning_method: [],          
        },
        onSubmit: (values) => {
            if((values.intake && values.intake.length>0) || (values.school && values.school.length>0) || (values.learning_method && values.learning_method.length>0)){
                let assignTemp = [...assignedStudentList];
                let availTemp = [...studentList];
                let selectedRows = availTemp;
                if(values.intake.length){
                    let tempIntake = selectedRows;
                    let tempSearch = tempIntake.filter((item)=>{
                        const startsIntakeYear = values.intake.find((post)=>(post.value === ((item.year && item.number) ? item.year + "/" + item.number : "")));
                        if(startsIntakeYear){
                            return startsIntakeYear;
                        }else{
                            return null;
                        }
                    });
                    selectedRows = tempSearch;
                }

                if(values.school.length){
                    let tempSchool = selectedRows;
                    let tempSearch = tempSchool.filter((item)=>{
                        const startsWith = values.school.find((name)=>(name.value.toLowerCase() === (item.school_type ? item.school_type.toLowerCase() : "")));
                        if(startsWith){
                            return startsWith;
                        }else{
                            return null;
                        }
                    })

                    selectedRows = tempSearch;
                }

                if(values.learning_method.length){
                    let tempMethod = selectedRows;
                    let tempSearch = tempMethod.filter((item)=>{
                        const status = values.learning_method.find((post)=>(item.type && post.value.toString().toLowerCase() === item.type.toString().toLowerCase() ? item.type.toString().toLowerCase() : ""));
                        if(status){
                            return status;                        
                        }else{
                            return null;
                        }
                    })

                    selectedRows = tempSearch;
                }
                let selectedIds = selectedRows.map((row)=>row.id);
                assignTemp.push(...selectedRows);
                availTemp = availTemp.filter(data => !selectedIds.includes(data.id));

                setAssignedStudentList([...assignTemp]);
                setStudentList([...availTemp]);

                $("#bulkaddmodal").modal('hide');
                formik.resetForm();
            }else{
                formik.setFieldError("intake", "Please select at least one filter value.")
                formik.setFieldError("school", " ")
                formik.setFieldError("learning_method", " ")
                
            }            
        },
    })

    useEffect(() => {
        let response = hasPermission({ scopes: ["cparaaview"], permissions: givenPermsisions, });
        if (!response) {
            history.push("/noaccess");
        }
    }, [])


    useEffect(() => {
        const dropdownSource = axios.CancelToken.source();
        const getDropDowns = async () =>{
            try {
                const res = await GetAllDropdownValuesCancelToken(dropdownSource.token);
                if(res.status==200){
                    let tempIntakeArr = [];
                    res.data.intake_year && res.data.intake_year.length && res.data.intake_number && res.data.intake_number.length && res.data.intake_year.map(year => {
                        res.data.intake_number.map(number => {
                            tempIntakeArr.push({ label: `${year.label}-${number.label}`, value: `${year.value}/${number.value}` })
                        })
                    })
                    setIntakeArr(tempIntakeArr);
                    setSchoolArr(res.data.school_type);
                    setLearningMethodArr(res.data.study_type);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        }
        getDropDowns();
        return () => {
        dropdownSource.cancel('Component unmounted');    
        }; 
    }, []);

    useEffect(()=>{
        const cancelTokenSources = [];

        const getAssignedQualificationList = async () => {
            setLoading(true);
            if (page == "open-articulation-registration" && tab == "articulations") {
                cancelTokenSources.forEach(source => {
                    source.cancel('New request made');
                });
                const assignedSource = axios.CancelToken.source();
                cancelTokenSources.push(assignedSource);
                const params = {
                    assigned: "assigned",
                    articulation_id: id,
                    articulation: 1
                }
                try {
                    const res = await getArticulationQualificationList(params, assignedSource.token);
                    if(res.status==200){
                        setAssignedStudentList(res?.data.qualication);
                        setProgrammeReRegDetails(res.data.res)
                        setLoading(false);
                    }
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        setLoading(false);
                    }
                }                
            }
        }

        getAssignedQualificationList();
        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        }
    },[refresh])


    useEffect(()=>{
        const qualificationTokenSources = [];
        const getAvailableQualificationList = async () => {
            setLoading1(true);
            if (page == "open-articulation-registration" && tab == "articulations") {
                qualificationTokenSources.forEach(source => {
                    source.cancel('New request made');
                });
                const unassignedSource = axios.CancelToken.source();
                qualificationTokenSources.push(unassignedSource);
                const params = {
                    assigned: "",
                    articulation_id: id,
                    articulation: 1
                }
                try {
                    const res = await getArticulationQualificationList(params, unassignedSource.token);
                    if(res.status==200){
                        setStudentList(res?.data?.qualication ? res?.data?.qualication : []);
                        setLoading1(false);
                    }
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        setLoading1(false);
                    }
                }                
            }
        }
        getAvailableQualificationList();
        return () => {
            qualificationTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        }
    },[refresh])

    const dataToRender = () => {
        let updatedData = [];
        let allData = studentList
        if (search1.length) {
            let tempFirstname = allData.filter((item) => {
                let includes = item.course && item.course.toLowerCase().includes(search1.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempYear = allData.filter((item) => {
                let includes = item.year && item.year.toLowerCase().includes(search1.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempSchoolType = allData.filter((item) => {
                let includes = item.school_type && item.school_type.toLowerCase().includes(search1.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempType = allData.filter((item) => {
                let includes = item.type && item.type.toLowerCase().includes(search1.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempStartDate = allData.filter((item) => {
                let includes = item.start && moment.unix(item.start).format(TABLE_DATE_FORMAT).toLowerCase().includes(search1.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempStartEnd = allData.filter((item) => {
                let includes = item.end && moment.unix(item.end).format(TABLE_DATE_FORMAT).toLowerCase().includes(search1.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let data = [
                // ...tempId,
                ...tempFirstname,
                ...tempYear,
                ...tempSchoolType,
                ...tempType,
                ...tempStartDate,
                ...tempStartEnd
            ];
            let unique = [...new Set(data)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (availableSchool.arr.length) {
            let tempRole = updatedData;
            let tempSearch = tempRole.filter((item) => {
                const startsWith = availableSchool.arr.find(function (user, index) {
                    if (user.toLowerCase() === (item.school_type ? item.school_type.toLowerCase() : "")) return item;
                });
                if (startsWith) {
                    return startsWith;
                } else return null;
            });
            updatedData = tempSearch;
        }

        if (availableIntake.arr.length) {
            let tempStatus = updatedData;
            let tempIntakeYear = tempStatus.filter((item) => {
                const startsIntakeYear = availableIntake.arr.find(function (post, index) {
                    if (post === (item.year && item.number ? item.year + "/" + item.number : "")) return item;
                });
                if (startsIntakeYear) {
                    return startsIntakeYear;
                } else return null;
            });

            updatedData = tempIntakeYear;
        }

        if (availableLearningMethod.arr.length) {
            let tempStatus = updatedData;
            let tempIntakeYear = tempStatus.filter((item) => {
                const startsIntakeYear = availableLearningMethod.arr.find(function (post, index) {
                    if (item.type && post.toString().toLowerCase() === item.type.toString().toLowerCase() ? item.type.toString().toLowerCase() : "") return item;
                });
                if (startsIntakeYear) {
                    return startsIntakeYear;
                } else return null;
            });

            updatedData = tempIntakeYear;
        }

        return updatedData;
    };

    const dataToRender1 = () => {
        let updatedData = [];
        let allData = assignedStudentList;
        if (search.length) {
            // let tempId = allData.filter((item) => {
            //     let includes = item.id.toString().toLowerCase().includes(search.toLowerCase());
            //     if (includes) {
            //         return includes;
            //     } else return null;
            // });
            let tempFirstname = allData.filter((item) => {
                let includes = item.course && item.course.toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempYear = allData.filter((item) => {
                let includes = item.year && item.year.toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempSchoolType = allData.filter((item) => {
                let includes = item.school_type && item.school_type.toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempType = allData.filter((item) => {
                let includes = item.type && item.type.toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempStartDate = allData.filter((item) => {
                let includes = item.start && moment.unix(item.start).format(TABLE_DATE_FORMAT).toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempStartEnd = allData.filter((item) => {
                let includes = item.end && moment.unix(item.end).format(TABLE_DATE_FORMAT).toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let data = [
                // ...tempId,
                ...tempFirstname,
                ...tempYear,
                ...tempSchoolType,
                ...tempType,
                ...tempStartDate,
                ...tempStartEnd
            ];
            let unique = [...new Set(data)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (assignSchool.arr.length) {
            let tempRole = updatedData;
            let tempSearch = tempRole.filter((item) => {
                const startsWith = assignSchool.arr.find(function (user, index) {
                    if (user.toLowerCase() === (item.school_type ? item.school_type.toLowerCase() : "")) return item;
                });
                if (startsWith) {
                    return startsWith;
                } else return null;
            });
            updatedData = tempSearch;
        }

        if (assignIntake.arr.length) {
            let tempStatus = updatedData;
            let tempIntakeYear = tempStatus.filter((item) => {
                const startsIntakeYear = assignIntake.arr.find(function (post, index) {
                    if (post === (item.year && item.number ? item.year + "/" + item.number : "")) return item;
                });
                if (startsIntakeYear) {
                    return startsIntakeYear;
                } else return null;
            });

            updatedData = tempIntakeYear;
        }

        if (assignLearningMethod.arr.length) {
            let tempStatus = updatedData;
            let tempIntakeYear = tempStatus.filter((item) => {
                const startsIntakeYear = assignLearningMethod.arr.find(function (post, index) {
                    if (item.type && post.toString().toLowerCase() === item.type.toString().toLowerCase() ? item.type.toString().toLowerCase() : "") return item;
                });
                if (startsIntakeYear) {
                    return startsIntakeYear;
                } else return null;
            });

            updatedData = tempIntakeYear;
        }

        return updatedData;
    };

    const addRemoveRow = (row, type) => {
        let assigntemp = [...assignedStudentList];
        let availtemp = [...studentList];

        if (type === "add") {
            assigntemp.push(row);
            availtemp = availtemp.filter(data => data.id !== row.id);
        } else if (type === "delete") {
            assigntemp = assigntemp.filter(data => data.id !== row.id)
            availtemp.push(row);
            const params = {
                programme_id: row?.id,
                articulation_id: id,
                articulation: 1
            }
            // unassignedArticulationToQualification(params)
            // .then((res) => {
            //     console.log(res?.data)
            // }).catch((err)=>console.log(err))
        }

        setAssignedStudentList([...assigntemp]);
        setStudentList([...availtemp]);

    }

    const sortingName = (rowA, rowB) => {
        const name1 = rowA.course.toString().toLowerCase();
        const name2 = rowB.course.toString().toLowerCase();
        return name1.localeCompare(name2);
    };

    const columns1 = useMemo(() => [
        {
            name: "Name",
            selector: "course",
            sortable: true,
            sortFunction: sortingName,
            cell: (row) => (
                <Link to={`/courseAdministration/Programmes/programme/open/${row.id}/details`} title={row.course} className="as-text-blue curser feature-name">
                    <span className="textLimit100">{row.course}</span>
                </Link>
            ),
        },
        {
            name: "Intake",
            selector: "year",
            sortable: true,
            cell: (row) => row.year + "-" + row.number,
        },
        {
            name: "School",
            selector: "school_type",
            sortable: true,
            cell: (row) => row.school_type ? RenderSchool(row.school_type).html : "-"
        },
        {
            name: "Learning Method",
            selector: "type",
            sortable: true,
            cell: (row) => row.type ? RenderLearningMethod(row.type).html : "-"
        },
        {
            name: "Start",
            selector: "start",
            sortable: true,
            cell: (row) => (
                <>
                    {row.start ? (
                        <div className="dateTime">
                            {/* <p className="right-space">{new Date(row.start)}</p> */}
                            <p className="right-space">{row.start.toString().length == 10 ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : moment(parseInt(`${row.start}`)).format(TABLE_DATE_FORMAT)}</p>
                            {/* <p className="right-space">{row.start.toString().length == 10 ? moment.unix(row.start).format(TABLE_TIME_FORMAT) : moment(parseInt(`${row.start}`)).format(TABLE_TIME_FORMAT)}</p> */}
                        </div>
                    ) : ("-")}
                </>
            ),
        },
        {
            name: "End",
            selector: "end",
            sortable: true,
            cell: (row) => (
                <>
                    {row.end ? (
                        <div className="dateTime">
                            <p className="right-space">{row.end.toString().length == 10 ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : moment(parseInt(`${row.end}`)).format(TABLE_DATE_FORMAT)}</p>
                            {/* <p>{row.end.toString().length == 10 ? moment.unix(row.end).format(TABLE_TIME_FORMAT) : moment(parseInt(`${row.end}`)).format(TABLE_TIME_FORMAT)}</p> */}
                        </div>
                    ) : ("-")}
                </>
            ),
        },
        {
            name: "Actions",
            selector: "",
            cell: (row) => (
                <div className="row m-0">
                    <div className="assessment-08 btn-dropdown-grp">
                        <div className="as-buttons">
                            <Link to={`/courseAdministration/Programmes/programme/open/${row.id}/details`} title="Open" className="btn btn-primary rounded-circle">
                                <i className="fal fa-folder-open"></i>
                            </Link>
                            {/* <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => checkCheckbox(row?.id, row?.checked)}> */}
                            <PermissionsGate scopes={["cparaadelete"]} errorProps={{ disabled: true }}>
                                <button
                                    className="btn btn-danger rounded-circle"
                                    type="button"
                                    title="Delete"
                                    onClick={() => {
                                        Swal
                                            .fire({ title: "Are you sure?", text: "You won't be able to revert this!", icon: "warning", showCancelButton: true, confirmButtonColor: "#3085d6", cancelButtonColor: "#d33", confirmButtonText: "Yes, delete it!" })
                                            .then((result) => {
                                                if (result.isConfirmed) { addRemoveRow(row, "delete") }
                                            })
                                    }}>
                                    <i className="fal fa-trash-alt"></i>
                                </button>
                            </PermissionsGate>
                        </div>
                    </div>
                </div>
            ),
        },
    ]);

    const columnsAvailable = useMemo(() => [
        {
            name: "Name",
            selector: "course",
            sortable: true,
            sortFunction: sortingName,
            cell: (row) => (
                <Link to={`/courseAdministration/Programmes/programme/open/${row.id}/details`} title={row.course} className="as-text-blue curser feature-name" onClick={() => $("#availableprogrammeinRereg").modal("hide")}>
                    <span className="textLimit100">{row.course}</span>
                </Link>
            ),
        },
        {
            name: "Intake",
            selector: "year",
            sortable: true,
            cell: (row) => row.year + "-" + row.number,
        },
        {
            name: "School",
            selector: "school_type",
            sortable: true,
            cell: (row) => row.school_type ? RenderSchool(row.school_type).html : "-"
        },
        {
            name: "Learning Method",
            selector: "type",
            sortable: true,
            cell: (row) => row.type ? RenderLearningMethod(row.type).html : "-"
        },
        {
            name: "Start",
            selector: "start",
            sortable: true,
            cell: (row) => (
                <>
                    {row.start ? (
                        <div className="dateTime">
                            <p className="right-space">{row.start.toString().length == 10 ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : moment(parseInt(`${row.start}`)).format(TABLE_DATE_FORMAT)}</p>
                            {/* <p>{row.start.toString().length == 10 ? moment.unix(row.start).format(TABLE_TIME_FORMAT) : moment(parseInt(`${row.start}`)).format(TABLE_TIME_FORMAT)}</p> */}
                        </div>
                    ) : ("-")}
                </>
            ),
        },
        {
            name: "End",
            selector: "end",
            sortable: true,
            cell: (row) => (
                <>
                    {row.end ? (
                        <div className="dateTime">
                            <p className="right-space">{row.end.toString().length == 10 ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : moment(parseInt(`${row.end}`)).format(TABLE_DATE_FORMAT)}</p>
                            {/* <p>{row.end.toString().length == 10 ? moment.unix(row.end).format(TABLE_TIME_FORMAT) : moment(parseInt(`${row.end}`)).format(TABLE_TIME_FORMAT)}</p> */}
                        </div>
                    ) : ("-")}
                </>
            ),
        },
        {
            name: "Actions",
            selector: "",
            cell: (row) => (
                <div className="row m-0">
                    <div className="assessment-08 btn-dropdown-grp">
                        <div className="as-buttons">
                            <Link to={`/courseAdministration/Programmes/programme/open/${row.id}/details`} className="btn btn-primary rounded-circle" title="Open">
                                <i className="fal fa-folder-open"></i>
                            </Link>
                            <button
                                className="btn btn-primary rounded-circle"
                                title="Add"
                                type="button"
                                onClick={() => {
                                    Swal
                                        .fire({ title: "Are you sure?", icon: "question", showCancelButton: true, confirmButtonColor: "#3085d6", cancelButtonColor: "#d33", confirmButtonText: "Yes, Add it!" })
                                        .then((result) => {
                                            if (result.isConfirmed) { addRemoveRow(row, "add") }
                                        })
                                }}>
                                <i className="fal fa-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
    ]);

    function resetFilter() {
        setSearch("");
        setAssignIntake({ arr: [], checkObj: {} });
        setAssignSchool({ arr: [], checkObj: {} });
        setAssignLearningMethod({ arr: [], checkObj: {} });

    }

    function resetFilter1() {
        setSearch1("");
        setAvailableIntake({ arr: [], checkObj: {} })
        setAvailableSchool({ arr: [], checkObj: {} })
        setAvailableLearningMethod({ arr: [], checkObj: {} })
    }

    const handleFilters = (e, type = "") => {
        const value = e.target.value;
        setSearch(value);
    };

    const handleFilters1 = (e, type = "") => {
        const value = e.target.value;
        setSearch1(value);
    };

    const exportData = (fileType, fileName) => {
        let data = dataToRender1();
        const header = ["Name", "Intake", "School", "Learning Method", "Start", "End"];

        data = data?.map((row) => ({
            ...row,
            Name: row.course,
            Intake: row.year ? row.year + "-" + row.number : "-",
            School: row.school_type ? RenderSchool(row.school_type).text : "-",
            "Learning Method": row.type ? RenderLearningMethod(row.type).text : "-",
            "Start": row.start ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : "-",
            "End": row.end ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : "-",
        }));

        if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
            anchor.click();
            setTimeout(() => {
                URL.revokeObjectURL(blobURL);
            }, 1000);
        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.map((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);

            return false;
        }
        if (fileType === "pdf") {
            const compatibleData = data.map((row) => {
                return [
                    row.course,
                    row.year + " " + row.number,
                    RenderSchool(row.school_type).text,
                    row?.type ? RenderLearningMethod(row?.type).text : "-",
                    moment.unix(row.start).format(TABLE_DATE_FORMAT) + " " + moment.unix(row.start).format(TABLE_TIME_FORMAT),
                    moment.unix(row.end).format(TABLE_DATE_FORMAT) + " " + moment.unix(row.end).format(TABLE_TIME_FORMAT),
                ];
            });
            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                    minCellHeight: 10,
                    minCellWidth: 5,
                    halign: "left",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }
    };

    const exportData1 = (fileType, fileName) => {
        let data = dataToRender();
        const header = ["Name", "Intake", "School", "Learning Method", "Start", "End"];

        data = data?.map((row) => ({
            ...row,

            Name: row.course,
            Intake: row.year ? row.year + " " + row.number : "-",
            School: row.school_type ? RenderSchool(row.school_type).text : "-",
            "Learning Method": row.type ? RenderLearningMethod(row.type).text : "-",
            "Start": row.start ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : "-",
            "End": row.end ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : "-",
        }));

        if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            // Create new tag for download file
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
            anchor.click();

            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                URL.revokeObjectURL(blobURL);
            }, 1000);
        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.map((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);

            // Returning false as downloading of file is already taken care of
            return false;
        }
        if (fileType === "pdf") {
            const compatibleData = data.map((row) => {
                // return [row.ID, row.User, row.Email, row.role_name, row.Status, row.Company, row.Location, row["Reports To"]];
                // return [row["ID"], row["Full Name"], row["Date"], row["Status"], row["Sales Person"], row["Qualification"], row["School"], row["Intake"], row["Delivery"], row["Campus"], row["Laptop"], row["#Tracking No"], row["Application Status"], row["Is Re-Registration"]]

                return [

                    row.course,
                    row.year + " " + row.number,
                    RenderSchool(row.school_type).text,
                    row?.type ? RenderLearningMethod(row?.type).text : "-",
                    moment.unix(row.start).format(TABLE_DATE_FORMAT) + " " + moment.unix(row.start).format(TABLE_TIME_FORMAT),
                    moment.unix(row.end).format(TABLE_DATE_FORMAT) + " " + moment.unix(row.end).format(TABLE_TIME_FORMAT),
                ];
            });
            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                    minCellHeight: 10,
                    minCellWidth: 5,
                    halign: "left",
                    // valign: "center",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }
    };

    const assignCourses = () => {
        setIsGearIconShown(true)
        ids = [];
        tempData = assignedStudentList;
        tempData.map((data) => {
            ids.push(data.id);
        });
        let arrJoin = ids.join(",");
        const formData = new FormData();
        formData.append("programme_id", arrJoin);
        formData.append("articulation", 1)
        // formData.append("reg_status", programmeReRegDetails.reg_status);
        // formData.append("academy_type", programmeReRegDetails.academy_type);
        // formData.append("start_date", programmeReRegDetails.start_date);
        // formData.append("end_date", programmeReRegDetails.end_date)
        // formData.append("early_bird_end_date", programmeReRegDetails.early_bird_end_date)
        // formData.append("intake_year", programmeReRegDetails.intake_year);
        // formData.append("intake_number", programmeReRegDetails.intake_number);
        // formData.append("description", programmeReRegDetails.description);
        id && formData.append("articulation_id", id);
        assignedArticulationToQualification(formData).then(res => {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Successfully Assigned",

            });
            setIsGearIconShown(false)
        })
            .catch(err => {
                setIsGearIconShown(false)
                console.error("error :", err)
            })
    }
    return (
        <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="custom-table-div filter-search-icon card card-table-custom">
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                    <div id="assessment-table-main_filter" className="dataTables_filter">
                                        <label>
                                            <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={(e) => handleFilters(e, "searchAssigned")} value={search} />
                                        </label>
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                                <i className="fal fa-filter"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-button-group">
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner  filter-custom-new`}>
                                        <Tablefilter
                                            filterName={"Intake"}
                                            optionArr={intakeArr}
                                            state={assignIntake}
                                            setState={setAssignIntake}
                                            uniqueId={"assignIntake"}
                                            isOptionReversed={true}
                                        />
                                        <Tablefilter
                                            filterName={"School"}
                                            optionArr={schoolArr}
                                            state={assignSchool}
                                            setState={setAssignSchool}
                                            renderLabelFunction={RenderSchool}
                                            uniqueId={"assignSchool"}
                                        />
                                        <Tablefilter
                                            filterName={"Learning Method"}
                                            optionArr={learningMethodArr}
                                            state={assignLearningMethod}
                                            setState={setAssignLearningMethod}
                                            renderLabelFunction={RenderLearningMethod}
                                            uniqueId={"assignLearningMethod"}
                                        />
                                    </div>
                                </div>
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button className="btn btn-primary" title="Reset" onClick={resetFilter}>
                                            <i className="fal fa-redo"></i>Reset
                                        </button>
                                    </div>
                                    <div className="files-export-group">
                                        <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Assigned Articulation") }} title="Export spreadsheet">
                                            <i className="fal fa-file-excel icon"></i>
                                        </button>
                                        <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Assigned Articulation") }} title="Export CSV">
                                            <i className="fal fa-file-csv icon"></i>
                                        </button>
                                        <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Assigned Articulation") }} title="Export PDF">
                                            <i className="fal fa-file-pdf icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-filter-div-right">
                        <div className=" filter-search-bar-blk">                            
                            <PermissionsGate scopes={["cparaaadd"]} errorProps={{ disabled: true }}>
                                <button
                                    className="btn btn-primary mr-2"
                                    data-toggle="modal"
                                    data-target="#availableprogrammeinRereg"
                                    title="Available Articulation"
                                >
                                    <i className="fal fa-plus"></i>Available Articulation
                                </button>
                            </PermissionsGate>
                            <PermissionsGate scopes={["cparaaadd"]} errorProps={{ disabled: true }}>
                                <button
                                    className="btn btn-primary mr-2"
                                    data-toggle="modal"
                                    data-target="#bulkaddmodal"
                                    title="Bulk Add Articulation"
                                >
                                    <i className="fal fa-plus"></i>Bulk Add Articulation
                                </button>
                            </PermissionsGate>
                        </div>
                    </div>
                </div>


                <DataTable
                    data={dataToRender1()}
                    defaultSortField="start"
                    defaultSortAsc={true}
                    columns={columns1}
                    pagination={true}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                    progressPending={loading}
                    progressComponent={<SkeletonTicketList />}
                />
            </div>
            <div className="btn-sec t-b-s table-end-btn" style={{ maxWidth: 'fit-content' }}>
                <div className="form-group form-group-save-cancel">
                    <PermissionsGate
                        scopes={["cparedit"]}
                        errorProps={{ disabled: true }}
                    >
                        <button
                            className="btn btn-save btn-success"
                            type="button"
                            title="Save"
                            onClick={() => assignCourses()}
                        >
                            {isGearIconShown ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}Save
                        </button>
                    </PermissionsGate>
                    <button
                        className="btn btn-danger"
                        type="button"
                        title="Cancel"
                        onClick={() => { setRefresh(!refresh) }}
                    >
                        <i className="fal fa-times"></i>Cancel
                    </button>
                </div>
            </div>
            <div
                className="topic-add-modal modal fade"
                id="availableprogrammeinRereg"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered available-assessment-modal"
                    role="document"
                >
                    <div className="modal-content modal-border-update">
                        <div className="modal-body p-0">
                            <div className="modal-header modal-header-custom">
                                <h5 className="modal-title">
                                    Available Articulation
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    data-toggle="modal"
                                    data-target="#availableprogrammeinRereg"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="t p-30px">
                                <div className="my-tickets-info-list Tickets-main-wrap">
                                    <div className="custom-table-div filter-search-icon card-table-custom">
                                        <div className="search-filter-div">
                                            <div className="search-filter-div-left">

                                                <div className="system-administration-table table-responsive">
                                                    <div className="table-responsive-div">
                                                        <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                                            <div id="assessment-table-main_filter" className="dataTables_filter">
                                                                <label>
                                                                    <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={(e) => handleFilters1(e, "search")} value={search1} />
                                                                </label>
                                                                <div className="filter-eff filter-data-btn">
                                                                    <button className="filter-buttons">
                                                                        <i className="fal fa-filter"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="filter-button-group">
                                                        <div className="filter-scroll">
                                                            <div className={`filter-scroll-inner  filter-custom-new`}>
                                                                <Tablefilter
                                                                    filterName={"Intake"}
                                                                    optionArr={intakeArr}
                                                                    state={availableIntake}
                                                                    setState={setAvailableIntake}
                                                                    uniqueId={"availableIntake"}
                                                                    isOptionReversed={true}
                                                                />
                                                                <Tablefilter
                                                                    filterName={"School"}
                                                                    optionArr={schoolArr}
                                                                    state={availableSchool}
                                                                    setState={setAvailableSchool}
                                                                    renderLabelFunction={RenderSchool}
                                                                    uniqueId={"availableSchool"}
                                                                />
                                                                <Tablefilter
                                                                    filterName={"Learning Method"}
                                                                    optionArr={learningMethodArr}
                                                                    state={availableLearningMethod}
                                                                    setState={setAvailableLearningMethod}
                                                                    renderLabelFunction={RenderLearningMethod}
                                                                    uniqueId={"availableLearningMethod"}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="reset-btn-group">
                                                            <div className="button-reset dropdown-comman">
                                                                <button className="btn btn-primary" title="Reset" onClick={resetFilter1}>
                                                                    <i className="fal fa-redo"></i>Reset
                                                                </button>
                                                            </div>
                                                            <div className="files-export-group">
                                                                <button type="button" className="btn btn-files" onClick={() => { exportData1("xlsx", "Available Articulation") }} title="Export spreadsheet">
                                                                    <i className="fal fa-file-excel icon"></i>
                                                                </button>
                                                                <button type="button" className="btn btn-files" onClick={() => { exportData1("csv", "Available Articulation") }} title="Export CSV">
                                                                    <i className="fal fa-file-csv icon"></i>
                                                                </button>
                                                                <button type="button" className="btn btn-files" onClick={() => { exportData1("pdf", "Available Articulation") }} title="Export PDF">
                                                                    <i className="fal fa-file-pdf icon"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <DataTable
                                            data={dataToRender()}
                                            defaultSortField="start"
                                            defaultSortAsc={false}
                                            columns={columnsAvailable}
                                            pagination={true}
                                            progressPending={loading1}
                                            progressComponent={<SkeletonTicketList />}
                                            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PopupComponent 
                id="bulkaddmodal"
                type="form"
                header={{iconName : "fa-bars", heading:"Add Bulk Articulation"}}
                inputs={[
                    <>
                    <div className="row m-1 form-icon-group">
                        <div className="col-md-12 col-lg-6 mb-2">
                            <label htmlFor="Topic">Intake</label>
                            <Select
                                className={"form-control custom-select-box " + 
                                (formik.errors.intake && formik.touched.intake ? " is-invalid" : "")}
                                name="intake"
                                title="Intake"
                                value={formik.values.intake}
                                onChange={(value) => formik.setFieldValue("intake", value)}
                                onBlur={formik.handleBlur}
                                options={intakeArr}
                                maxMenuHeight={175}
                                isMulti={true}
                                placeholder="Select Intake"
                            />
                        </div>
                        <div className="col-md-12 col-lg-6 mb-2">
                            <label htmlFor="School">School</label>
                            <Select
                                className={"form-control custom-select-box " + 
                                (formik.errors.school && formik.touched.school ? " is-invalid" : "")}
                                name="school"
                                title="School"
                                value={formik.values.school}
                                onChange={(value) => formik.setFieldValue("school", value)}
                                onBlur={formik.handleBlur}
                                options={schoolArr}
                                maxMenuHeight={175}
                                isMulti={true}
                                placeholder="Select School"
                            />
                        </div>
                        <div className="col-md-12 col-lg-6 mb-2">
                            <label htmlFor="Topic">Learning Method</label>
                            <Select
                                className={"form-control custom-select-box " + 
                                (formik.errors.learning_method && formik.touched.learning_method ? " is-invalid" : "")}
                                name="intake"
                                title="Intake"
                                value={formik.values.learning_method}
                                onChange={(value) => formik.setFieldValue("learning_method", value)}
                                onBlur={formik.handleBlur}
                                options={learningMethodArr}
                                maxMenuHeight={175}
                                isMulti={true}
                                placeholder="Select Learning Method"
                            />
                        </div>
                    </div>
                    </>
                ]}
                bottomButton={[
                    <button className="btn btn-primary" type="submit" title="Save" onClick={formik.handleSubmit} disabled={isDisabled || !formik.dirty}>
                        {isDisabled === false ? <i className="fal fa-save"></i> : <i className="fas fa-cog fa-spin"></i>}
                        Save
                    </button>,
                    <button type="button" className="btn btn-close btn-danger" title="Close" data-dismiss="modal" onClick={()=>{formik.resetForm()}}>
                        <i className="fal fa-times"></i>
                        Cancel
                    </button>,
                    Object.keys(formik.values).map((key, ind) => {
                        if (formik.touched[key] && formik.errors[key]) {
                        return (
                            <div className="invalid-feedback d-block" key={ind}>
                            {formik.errors[key]}
                            </div>
                        )
                        }
                    })
                ]}
                otherProps={{secondPopupCss: "zindexadd"}}
            />
        </div>
    );
};

export default Articulations;
