import React from "react";
import { TrimText } from "../../common/TrimText";

export const DeliveryMethods = (data) => {
  switch (data) {
    case "PTOL":
      return GroupColor({ color: "cat-skyblue", text: "PTOL" });
    case "FTC":
      return GroupColor({ color: "cat-red", text: "FTC" });
    case "FTOL":
      return GroupColor({ color: "cat-greenhex", text: "FTOL" });
    case "FTC_CPT":
      return GroupColor({ color: "cat-yellowshade", text: "FTC_CPT" });
  }
};

export const DMColor = (role_name) => {
  switch (role_name) {
    case "PTOL":
      return "cat-skyblue";

    case "FTC":
      return "cat-red";

    case "FTOL":
      return "cat-greenhex";

    case "FTC_CPT":
      return "cat-yellowshade";
  }
};

const GroupColor = ({ color, text }) => {
  return (
    <p className={"cat " + color} title={text}>
      <i className="fas fa-circle mr-1"></i> {TrimText(text, 10)}
    </p>
  );
};
const SchoolGroupColor = ({ color, text }) => {
  return (
    <p className={"cat " + color} title={text}>
      <i className="fas fa-circle mr-1"></i> {TrimText(text, 30)}
    </p>
  );
};
const StatusGroupColor = ({ color, text }) => {
  return (
    <p className={`as-${color}-bg as-widget`}>{text}</p>
  );
};
const GroupTypeColor = ({ color, text }) => {
  return (
    <span className={"cat " + color} title={text}>
      <i className="fas fa-circle mr-1"></i> {TrimText(text, 15)}
    </span>
  );
};

export const SchoolColor = (role_name) => {
  switch (role_name) {
    case "School of Draughting & Technical Design":
      return "cat-skyblue";

    case "School of Engineering & Science":
      return "cat-red";

    case "School of Business, Entrepreneurship & Finance":
      return "cat-greenhex";

    case "School of Architecture & The Built Environment":
      return "cat-yellowshade";

    case "Greenside Design Center":
      return "cat-blue";
  }
};

export const School = (data) => {
  switch (data ? data.trim() : data) {
    case "School of Draughting & Technical Design":
      return SchoolGroupColor({ color: "cat-skyblue", text: "School of Draughting & Technical Design" });
    case "School of Engineering & Science":
      return SchoolGroupColor({ color: "cat-red", text: "School of Engineering & Science" });
    case "School of Business, Entrepreneurship & Finance":
      return SchoolGroupColor({ color: "cat-greenhex", text: "School of Business, Entrepreneurship & Finance" });
    case "School of Architecture & The Built Environment":
      return SchoolGroupColor({ color: "cat-yellowshade", text: "School of Architecture & The Built Environment" });
    case "Greenside Design Center":
      return SchoolGroupColor({ color: "cat-blue", text: "Greenside Design Center" });
    default :
    return SchoolGroupColor({ color: "cat-black", text: "NA" });
  }
};

export const pmcStatus = (data) => {
  switch (data.trim()) {
    case "New":
      return StatusGroupColor({ color: "skyblue", text: "New" });
    case "Draft":
      return StatusGroupColor({ color: "red", text: "Draft" });
    case "Final":
      return StatusGroupColor({ color: "green", text: "Final" });
    case "Approved":
      return StatusGroupColor({ color: "yellowshade", text: "Approved" });
    case "Inactive":
      return StatusGroupColor({ color: "blue", text: "Inactive" });
    default :
    return StatusGroupColor({ color: "black", text: "NA" });
  }
};


export const NQFColor = (data) => {
  switch (data) {
    case "1": return "cat-skyblue";

    case "2": return "cat-red";

    case "3": return "cat-greenhex";
    
    case "4": return "cat-blue";

    case "5": return "cat-yellow";

    case "6": return "cat-skyblue";

    case "7": return "cat-red";

    case "8": return "cat-greenhex";
    
    case "9": return "cat-blue";

    case "10": return "cat-yellow";
  }
};

export const NQFItems = (data) => {
  switch (data) {
    case "1": return GroupColor({ color: "cat-skyblue", text: "1" });

    case "2": return GroupColor({ color: "cat-red", text: "2" });

    case "3": return GroupColor({ color: "cat-greenhex", text: "3" });

    case "4": return GroupColor({ color: "cat-blue", text: "4" });

    case "5": return GroupColor({ color: "cat-yellow", text: "5" });
    
    case "6": return GroupColor({ color: "cat-skyblue", text: "6" });
    
    case "7": return GroupColor({ color: "cat-red", text: "7" });
    
    case "8": return GroupColor({ color: "cat-greenhex", text: "8" });
    
    case "9": return GroupColor({ color: "cat-blue", text: "9" });
    
    case "10": return GroupColor({ color: "cat-yellow", text: "10" });
    
    default :
    return GroupColor({ color: "cat-black", text: "NA" });
  }
};


export const TypeofModule = (data) => {
  let col = data.toLowerCase();
  switch (col) {
    case "scheduled":
      return GroupTypeColor({ color: "cat-skyblue", text: "Scheduled" });
    case "mentored":
      return GroupTypeColor({ color: "cat-red", text: "Mentored" });
    case "information":
      return GroupTypeColor({ color: "cat-greenhex", text: "Information" });
    case "orientation":
      return GroupTypeColor({ color: "cat-yellow", text: "Orientation" });
    case "shortcourse":
      return GroupTypeColor({ color: "cat-blue", text: "Short Course" });
    default:
      return GroupTypeColor({ color: "cat-black", text: "NA" });
  }
};
