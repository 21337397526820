import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from '../components/common/Header';
import SideBar from '../components/common/SideBar';
import AuditTrailTable from '../components/systemadministration/TicketSetting/AuditTrailTable';
import CategoryForm from '../components/systemadministration/TicketSetting/CategoryForm';
import CategoryTable from '../components/systemadministration/TicketSetting/CategoryTable';
import TicketForm from '../components/systemadministration/TicketSetting/DepartmentForm';
import TicketTable from '../components/systemadministration/TicketSetting/DepartmentTable';
import { GetTicketCategoriesSettingDetail, GetTicketCategoriesSettingDetailCancelToken, GetTicketDepartmentSettingDetail, GetTicketDepartmentSettingDetailCancelToken } from '../services/TicketsSettingServices';
import axios from 'axios';

function TicketSetting() {
  const history = useHistory();
  const { type, id, tab } = useParams();
  
  const [departmentDetails, setDepartmentDetails] = useState();
  const [categoriesDetails, setCategoriesDetails] = useState();

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    const dropdownCategoriesSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetTicketDepartmentSettingDetailCancelToken(id, dropdownSource.token);
        setDepartmentDetails(res.data.support_department.title)
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const fetchCatData = async () => {
      try {
        const response = await GetTicketCategoriesSettingDetailCancelToken(id, dropdownCategoriesSource.token);
        setCategoriesDetails(response.data.list_data.title)
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    if ((tab == "department" && type == "open") || (type == "auditTrail" && tab == "department" && id)) {
      fetchData();
    } else if ((tab == "categories" && type == "open") || (type == "auditTrail" && tab == "categories" && id)) {
      fetchCatData();
    }

    return () => {
      dropdownSource.cancel('Component unmounted');
      dropdownCategoriesSource.cancel('Component unmounted');
    };
}, [tab, type]);

  // useEffect(() => {
  //   if ((tab == "department" && type == "open") || (type == "auditTrail" && tab == "department" && id)) {
  //     GetTicketDepartmentSettingDetail(id)
  //       .then((res) => setDepartmentDetails(res.data.support_department.title))
  //       .catch((err) => console.log("error : ", err))
  //   } else if ((tab == "categories" && type == "open") || (type == "auditTrail" && tab == "categories" && id)) {
  //     GetTicketCategoriesSettingDetail(id)
  //       .then((res) => setCategoriesDetails(res.data.list_data.title))
  //       .catch((err) => console.log("error : ", err))
  //   }
  // }, [tab, type])


  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        {(type == "table" || (type==="auditTrail" && !id)) ? (tab === "categories" ? "Categories" : "Departments")
                          : tab == "categories" && type != "table" ? type === "add" ? "Add " : "Category:"
                            : tab == "department" && type === "add" ? "Add " : "Department:"}

                        {(type == "open" && tab == "department") || (tab=="department" && type == "auditTrail" && id) ? <span> {departmentDetails}</span>
                          : (type == "open" && tab == "categories") || (tab=="categories" && type == "auditTrail" && id) ? <span> {categoriesDetails}</span>
                            : tab === "categories" && type === "add" ? <span>Category</span>
                              : tab === "department" && type === "add" ? <span>Department</span>
                                : <span> </span>}
                        {/* {tab == "department" ? "Department" : tab == "categories" ? "Category" : "Ticket"} {type == "open" && tab == "department" ? <span>{departmentDetails}</span> : type == "open" && tab == "categories" ? <span>{categoriesDetails}</span> : <span>Settings</span>} */}
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                            <i className="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  {type !== "add" &&
                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                      {(tab == "categories") ? ""
                        :
                        <li className="nav-item"
                          onClick={() => history.push((type === "auditTrail" && id) || type == "open" ?
                            `/administration/ticketSettings/department/open/${id}`
                            : "/administration/ticketSettings/department/table")}
                        >
                          <Link
                            className={`nav-link ${(tab == "department" && (type==="table" || type==="open")) && "active"}`}
                            id="pills-tab1"
                            data-toggle="pill"
                            to={((type === "auditTrail" && id) || type == "open" ?
                              `/administration/ticketSettings/department/open/${id}` :
                              "/administration/ticketSettings/department/table")}
                            role="tab"
                            aria-controls="pills-tab11"
                            aria-selected="true"
                          >
                            {(tab === "department" && type === "open") || (type === "auditTrail" && id) ? <i className="fal fa-info-circle"></i> : <i className="fal fa-users"></i>}
                            {(tab === "department" && type === "open") || (type === "auditTrail" && id) ? "Details" : "Departments"}

                          </Link>
                        </li>
                      }

                      {(tab == "department") ? "" :
                        <li className="nav-item"
                          onClick={() => history.push(type == "open" || (type == "auditTrail" && id) ?
                            `/administration/ticketSettings/categories/open/${id}` :
                            "/administration/ticketSettings/categories/table")}
                        >
                          <Link
                            className={`nav-link ${(tab == "categories" && (type == "table" || type == "open")) && "active"}`}
                            id="pills-tab1"
                            data-toggle="pill"
                            to={type == "open" || (type == "auditTrail" && id) ?
                              `administration/ticketSettings/categories/open/${id}`
                              : "/administration/ticketSettings/categories/table"}
                            role="tab"
                            aria-controls="pills-tab11"
                            aria-selected="true"
                          >
                            {(tab === "categories" && type === "open") || (type == "auditTrail" && id) ? <i className="fal fa-info-circle"></i> : <i className="fas fa-bars"></i>}
                            {(tab === "categories" && type === "open") || (type == "auditTrail" && id) ? "Details" : "Categories"}
                          </Link>
                        </li>}

                      <li
                        className="nav-item"
                        onClick={() => history.push(((tab == "categories" && type=="open") || (type == "auditTrail"  && tab == "categories") && id) ?
                          `/administration/ticketSettings/categories/auditTrail/${id}`
                          : ((tab == "department" && type == "open") || (type == "auditTrail" && tab == "department") && id) ?
                            `/administration/ticketSettings/department/auditTrail/${id}`
                            : `/administration/ticketSettings/${tab}/auditTrail`)}
                      >
                        <Link
                          className={`nav-link ${type == "auditTrail" && "active"}`}
                          id="pills-tab1"
                          data-toggle="pill"
                          to={""}
                          role="tab"
                          aria-controls="pills-tab11"
                          aria-selected="true"
                        >
                          <i className="fal fa-history"></i>
                          Audit Trail
                        </Link>
                      </li>

                    </ul>
                  }
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">

                      {tab == "department" && type == "table" && <TicketTable />}
                      {tab == "department" && type == "open" && <TicketForm />}
                      {tab == "department" && type == "add" && <TicketForm />}
                      {tab == "categories" && type == "table" && <CategoryTable />}
                      {tab == "categories" && type == "open" && <CategoryForm />}
                      {tab == "categories" && type == "add" && <CategoryForm />}
                      {(tab == "department" || tab == "categories") && type == "auditTrail" && <AuditTrailTable />}
                      {/* {tab == "auditTrail" && type == "categoryTable" && <AuditTrailTable />}
                      {tab == "auditTrail" && type == "departmentTable" && <AuditTrailTable />} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketSetting