import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { DeleteUser, GetListOfRole, GetListOfRoleCancelToken, GetListOfUser, GetListOfUserCancelToken, ListUserFilters, ListUserFiltersCancelToken } from "../../../services/UserService";
import { checkRole, ColorRender } from "./CheckRole";
import { InitialRender } from "../../common/Helper";
import { IMAGE_URL } from "../../../utils/Constants";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import Hover from "../../common/Hover";
import { RenderBrands, RenderUserLocation, RenderUserRole } from "../../../utils/CommonGroupingItem";
import { RenderUserStatus } from "../../../utils/CommonStatusItems";
import DataTableComponent from "../../common/DataTableComponent";
import axios from "axios";

const ListOfUsers = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [role, setRole] = useState({ arr: [], checkObj: {} });
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [company, setCompany] = useState({ arr: [], checkObj: {} });
  const [location, setLocation] = useState({ arr: [], checkObj: {} });
  const [reportTo, setReportTo] = useState({ arr: [], checkObj: [] });
  const [deleterow, setDeleteRow] = useState(false);
  const [userData, setUserData] = useState([]);
  const [roleList, setRoleList] = useState();
  const user = useSelector((state) => state.user);
  const [loading, setloading] = useState(false);
  const [totalRows, setTotalRows] = useState(0)
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "name",
    sortOrder: "asc",
  });
  const [filterData, setFilterData] = useState({role : [], status : [], location : [], company : [], report : []})
  

  useEffect(() => {
    const cancelTokenSources = [];

    const getListOfuser = async () => {
      setloading(true)
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);
  
      const apiData = {
        page: tableState.page,
        limit: tableState.perPage,
        key: tableState.sortKey,
        sort: tableState.sortOrder,
        search: search,
        viaStatus: status.arr,
        viaRole: role.arr,
        viaCompany: company.arr,
        viaLocation: location.arr,
        viaReportTo: reportTo.arr,
        exportStatus: "false"
      };
  
      try {
        const res = await GetListOfUserCancelToken(apiData, source.token);
        setUserData(res.data.data);
        setTotalRows(res.data.total)
        if (res.status == 200) {
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    }

   getListOfuser();

   return () => {
    cancelTokenSources.forEach(source => {
      source.cancel('Component unmounted');
    });
  };
  }, [deleterow, role, status, company, location, reportTo, search, tableState]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await ListUserFiltersCancelToken(dropdownSource.token);
        setFilterData({...res.data, role : res.data.userRoleFilters, status : res.data.userStatusFilters, location : res.data.locationFilters, report : res.data.reportToFilters, company: res.data.CompanyFilters})
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const roleSource = axios.CancelToken.source();

    const fetchRoleData = async () => {
      try {
        const res = await GetListOfRoleCancelToken(roleSource.token);
        setRoleList(res.data.map(item => ({ value: item.role_name, label: item.role_name })))
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchRoleData();
  
    return () => {
      roleSource.cancel('Component unmounted');
    };
  }, [])

  const resetFilter = () => {
    setStatus({ arr: [], checkObj: {} });
    setRole({ arr: [], checkObj: {} });
    setCompany({ arr: [], checkObj: {} });
    setLocation({ arr: [], checkObj: {} });
    setReportTo({ arr: [], checkObj: [] });
    setSearch("");
  };

  const columns = useMemo(() => [
    {
      name: "User",
      selector: "Firstname",
      sortField:"name",
      sortable: true,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(row.role_name)}>
              {row.picture_me ? <img src={`${IMAGE_URL}/${row.picture_me.replace("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.Firstname, row.Lastname])}
              <span className={`profile-box-2-status ${row.user_activity_status ? row.user_activity_status.toLowerCase()=="online" ? "Online" :row.user_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
              <Hover firstName={row.Firstname} lastName={row.Lastname} photo={row.picture_me} email={row.Email} mobile={row.Mobile} status={row.user_status} activity_status={row.user_activity_status} right={true} role={row.role_name} />
            </span>
            <PermissionsGate scopes={['umedit']} RenderError={() => <p>{row.Firstname}</p>}>
              <Link className="as-text-blue curser feature-name" to={`/systemAdministration/userManagement/open/${row.UserID}/aboutYou`}>
                <span className="textLimit100">{row.Firstname + " " + row.Lastname}</span>
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
    { name: "Email", selector: "Email", sortField: "email", sortable: true, cell: (row) => (row.Email ? <span title={row.Email}>{row.Email}</span> : "-") },

    {
      name: "Role",
      selector: "role_name",
      sortField:"role",
      sortable: true,
      cell: (row) => row.role_name ? RenderUserRole(row.role_name).html : "-",
    },
    {
      name: "Status",
      selector: "is_active",
      sortField:"status",
      sortable: true,
      cell: (row) => RenderUserStatus(row.is_active).html
    },
    {
      name: "Location",
      selector: "Location",
      sortField:"location",
      sortable: true,
      cell: (row) => row.Location ? RenderUserLocation(row.Location).html : "-"
    },
    {
      name: "Company",
      selector: "Company",
      sortField:"company",
      sortable: true,
      cell: (row) => row.Company ? <span className="feature-name"><span className="textLimit100">{RenderBrands(row.Company, row.topbar_color).html} </span></span>: "-"
    },
    {
      name: "Reports To",
      selector: "report_user_Firstname",
      sortField:"reportTo",
      sortable: true,
      cell: (row) =>
        row.report_user_Firstname ? (
          <div className="reports-profile-view assigned-title-block-new">
            <div className="assigned-title-itm">
              <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(row.role_name)}>
                {row.report_user_picture ? (
                  <img src={`${IMAGE_URL}/${row.report_user_picture.replace("/home/myaie/public_html/", "")}`} alt="AIE" />
                ) : (
                  InitialRender([row.report_user_Firstname, row.report_user_Lastname])
                )}
                <span className={`profile-box-2-status ${row.report_user_activity_status ? row.report_user_activity_status.toLowerCase()=="online" ? "Online" :row.report_user_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                <i className="fas fa-circle"></i>
              </span>
                <Hover firstName={row.report_user_Firstname} lastName={row.report_user_Lastname} photo={row.report_user_picture} email={row.report_user_Email} mobile={row.report_user_Mobile} status={row.report_user_status} activity_status={row.report_user_activity_status} right={true} role={row.role_name} />
              </span>
              <PermissionsGate scopes={['umedit']} RenderError={() => <p>{row.report_user_Firstname}</p>}>
                <p className="as-text-blue curser " onClick={() => handleEdit(row, row.Report_to)}>
                  {row.report_user_Firstname}
                </p>
              </PermissionsGate>
            </div>
          </div>
        ) : (
          "-"
        ),
    },

    {
      name: "Action",
      selector: "",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate scopes={['umedit']}>
              <Link title="Open" className="btn btn-primary rounded-circle" to={`/systemAdministration/userManagement/open/${row.UserID}/aboutYou`}
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            {user?.role_name === "Admin" && (
              <PermissionsGate scopes={['umdelete']}>
                <button title="Delete" className="btn btn-danger rounded-circle" onClick={() => handleDelete(row.UserID)}>
                  <i className="fal fa-trash-alt"></i>
                </button>
              </PermissionsGate>
            )}
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteUser({ UserID: ID }).then((res) => setDeleteRow(!deleterow));

        Swal.fire("Deleted!", "Your user has been deleted.", "success");
      }
    });
  };

  const handleEdit = (row, ID) => {
    const result = checkRole(user.role_name, row.role_name);
    if (result === "enabled") {
      history.push(`/systemAdministration/userManagement/open/${ID}/aboutYou`);
    } else {
      Swal.fire({
        icon: "error",
        title: "Not authorised",
        text: "You dont have the permission to Edit ",
      }).then((result) => { });
    }
  };

  const exportData = (fileType, fileName) => {
    const header = ["User", "Email", "Role", "Status", "Company", "Location", "Reports To"];
    const apiData = {
      page: tableState.page,
      limit: tableState.perPage,
      key: tableState.sortKey,
      sort: tableState.sortOrder,
      search: search,
      viaStatus: status.arr,
      viaRole: role.arr,
      viaCompany: company.arr,
      viaLocation: location.arr,
      viaReportTo: reportTo.arr,
      exportStatus: "true"
    };
    GetListOfUser(apiData).then(res => {
      let data = res.data;

      data = data?.map((row) => ({
        ...row,
        // ID: row?.UserID,
        Role: row?.role_name,
        Status: row?.is_active ? "Active" : "Inactive",
        User: row?.Firstname + " " + row.Lastname,
        Company: row.Company ? row.Company : "-",
        Location: row.Location ? row.Location : "-",
        Company: row.Company ? RenderBrands(row.Company, row.topbar_color).text : "-",
        Email: row.Email ? row.Email : "-",
        "Reports To": row.report_user_Firstname ? row.report_user_Firstname : "-",
      }));

      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
        anchor.click();

        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);

        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [row.User, row.Email, row.role_name, row.Status, row.Company, row.Location, row["Reports To"]];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);

        return false;
      }
    })
  };

  return (
    <DataTableComponent
        data={userData}
        loading={loading}
        state={tableState}
        setState={setTableState}
        setSearch={setSearch}
        totalRows={totalRows}
        columns={columns}
        exportFunction={exportData}
        exportFileName={"User_List"}
        resetFilter={resetFilter}
        filters={[
          {
            filterName: "Role",
            optionArr: filterData.role,
            state: role,
            setState: setRole,
            renderLabelFunction: RenderUserRole,
          },
          {
            filterName: "Status",
            optionArr: filterData.status,
            state: status,
            setState: setStatus,
            renderLabelFunction: RenderUserStatus,
          },
          {
            filterName: "Location",
            optionArr: filterData.location,
            state: location,
            setState: setLocation,
            renderLabelFunction: RenderUserLocation,
          },
          {
            filterName: "Company",
            optionArr: filterData.company,
            state: company,
            setState: setCompany,
            renderLabelFunction: RenderBrands,
            uniqueId: "brands"
          },
          {
            filterName: "Report To",
            optionArr: filterData.report,
            state: reportTo,
            setState: setReportTo,
            isSearchFilter: true
          }
        ]}
        tableButton={[
          <Link to={`/systemAdministration/userManagement/new`}>
            <PermissionsGate errorProps={{ disabled: true }} scopes={["umadd"]}>
              <button className="btn btn-primary" title="New User">
                <i className="fal fa-plus"></i>New User
              </button>
            </PermissionsGate>
          </Link>
        ]}
      />
  );
};

export default ListOfUsers;
