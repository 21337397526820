import React, { useState, useMemo, useEffect } from "react";
import Header from '../common/Header';
import SideBar from '../common/SideBar';
import Tablefilter from "../common/Tablefilter";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE } from "../../utils/Constants";
import moment from "moment";
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from "react-select";
import { useFormik } from 'formik';
import {
  getBookedCoursesList,
  getBookedCoursesListCancelToken,
  updateBookedCourseDetails,
  getNetsuiteRooms
} from '../../services/CourseService';
import {
  RenderBookingState,
  RenderBrandList,
  RenderBookingActiveStatus,
  RenderBrandListMultiLine,
  RenderCampus,
  RenderDelegateType,
  RenderDelegateLaptopType,
  RenderCreditBearing
} from "../../utils/CommonGroupingItem";
import axios from "axios";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import Swal from "sweetalert2";
import PermissionsGate from '../../utils/permissionGate';
import { useSelector } from 'react-redux';
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import DataTable from "react-data-table-component";

const OpenCourseBookings = () => {
  const history = useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [courseDropdown, setCourseDropdown] = useState({ state: [{label: "Closed", value: "Closed"},{label: "Open", value: "Open"},{label: "Delivered", value: "Delivered"},{label: "Cancelled", value: "Cancelled"}], date: [{label: "Upcomming",value: "future"},{label: "Next 30 Days",value: "Next_30"},{label: "Next 7 Days",value: "Next_7"},{label: "Today",value: "today"},{label: "Past",value: "past"}] })
  const [courseId, setCourseId] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseStatus, setCourseStatus] = useState("");
  const [courseStatus_Id, setCourseStatus_Id] = useState("");
  const [final_total, setFinal_total] = useState("");
  const [prelim_total, setPrelim_total] = useState("");
  const [laptop_total, setLaptop_total] = useState("");
  const [startDate, setStartDate] = useState("");
  const [roomOptions, setRoomOptions] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [campus, setCampus] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [room, setRoom] = useState("");
  const [campus_id, setCampus_id] = useState("");
  const [instructor, setInstructor] = useState("");
  const [brand, setbrand] = useState("");
  const [cateringSetup, setCateringSetup] = useState("");
  const [caterId, setCaterId] = useState("");
  const [roomId, setRoomId] = useState("");
  const [ITSetup, setITSetup] = useState("");
  const [it_setup_id, setITId] = useState("");
  const [loading, setLoading] = useState(false)
  const [coursebookingList, setcoursebookingList] = useState([]);
  const [courseBooking, setcourseBooking] = useState({});
  let cateringOptions = [
    { label: 'Not Required', value: '3' },
    { label: 'Pending', value: '1' },
    { label: 'Catering Complete', value: '2' }
  ]
  let itOptions = [
    { label: 'Not Required', value: '3' },
    { label: 'Pending', value: '1' },
    { label: 'Setup Complete', value: '2' }
  ]

  useEffect(() => {
    
    const cancelTokenSources = [];
    const getBookedCoursesList = async () => {
      setLoading(true);
      setCourseId(id);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
      
      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);
      let data = {
        courseId: id
      };
      
      try {
        const res = await getBookedCoursesListCancelToken(data, source.token);
        setcourseBooking(res?.data?.data[0]);
        setCourseName(res?.data?.data[0]?.name ? res?.data?.data[0]?.name : "");
        setCourseStatus(res?.data?.data[0]?.state);
        setCourseStatus_Id(res?.data.data[0].state_id);
        setFinal_total(res?.data?.data[0]?.final_total);
        setRoomId(res?.data?.data[0]?.room_id);
        setPrelim_total(res?.data?.data[0]?.prelim_total);
        setLaptop_total(res?.data?.data[0]?.laptop_total);
        setCaterId(res?.data?.data[0]?.catering_setup_id);
        setCampus_id(res?.data?.data[0]?.campus_id);
        setITId(res?.data?.data[0]?.it_setup_id);
        if(res?.data?.data[0]?.start_date){
          setStartDate(moment(res?.data?.data[0]?.start_date, "DD/MM/YYYY").format(TABLE_DATE_FORMAT) + " " + res?.data?.data[0]?.start_time)
        }
        if(res?.data?.data[0]?.end_date){
          setEndDate(moment(res?.data?.data[0]?.end_date, "DD/MM/YYYY").format(TABLE_DATE_FORMAT) + " " + res?.data?.data[0]?.end_time)
        }
        setCampus(res?.data?.data[0]?.campus)
        setRoom(res?.data?.data[0]?.room)
        setInstructor(res?.data?.data[0]?.instructor)
        setbrand(res?.data?.data[0]?.subsidiary)
        setCateringSetup(res?.data?.data[0]?.catering_setup)
        setITSetup(res?.data?.data[0]?.it_setup)
        setcoursebookingList(res?.data?.data[0].bookings);
        if (res?.status == 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }      
    };
    
    getBookedCoursesList();
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [ id])
  
  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const res2 = await getNetsuiteRooms();
        setRoomOptions(res2?.data?.data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    }
    
    fetchRooms();
  }, [ id])
  useEffect(() => {
    const cancelTokenDropdownSources = [];
    
    const fetchData = async () => {
      cancelTokenDropdownSources.forEach(source => {
        source.cancel('New request made');
      });
      
      const source = axios.CancelToken.source();
      cancelTokenDropdownSources.push(source);
      let data = {
        courseId: id
      };
      try {
        const res = await getBookedCoursesListCancelToken(data, source.token);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }            
    };
    
    fetchData();
    
    return () => {
      cancelTokenDropdownSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);
  const myNameFilter = (a, b) => {
    return a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1;
  };
  const statusSortFunction = (a, b) => {
    return a.status.toLowerCase() > b.status.toLowerCase() ? 1 : -1;
  };
  const delegateTypeSortFunction= (a, b) => {
    return a.delegate_type.toLowerCase() > b.delegate_type.toLowerCase() ? 1 : -1;
  };

  const laptopTypeSortFunction= (a, b) => {
    return a.laptop_requirements.toLowerCase() > b.laptop_requirements.toLowerCase() ? 1 : -1;
  };
  const creditBearingTypeSortFunction= (a, b) => {
    return a.credit_bearing > b.credit_bearing ? 1 : -1;
  };
  const   brandSortFunction = (a, b) => {
    return a.subsidiary.toLowerCase() > b.subsidiary.toLowerCase() ? 1 : -1;
  }
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  const resetFilter = () => {
    setSearch("");
  };
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      wrap: true,
      sortFunction: myNameFilter,
      cell: (row) => (
        <PermissionsGate scopes={["regview"]} errorProps={{ disabled: true }}>
        <Link
        className="as-text-blue curser textLimit50"
        to={`/studentadministration/registration/open/registration?studentId=${row.ami_reg_id}&studentName=${row.first_name}%20${row.last_name}`}
        title={row.name}
        >
        {row.first_name} {row.last_name}
        </Link>
        
        
        </PermissionsGate>
        )
      }, 
      {
        name: "Status",
        selector: "Status",
        sortable: true,
        wrap: true,
        sortFunction: statusSortFunction,
        cell: (row) => RenderBookingActiveStatus(row.status),
      },
      {
        name: "Phone",
        selector: "phone",
        sortable: false,
        cell: (row) => (<span className="phone">{row.phone}</span>),
      },
      {
        name: "Email",
        selector: "email",
        sortable: false,
        wrap: true,
        cell: (row) => (<span className="email textLimit100">{row.email}</span>),
      },  
      {
        name: "Delegate Type",
        selector: "delegate_type",
        sortable: true,
        wrap: true,
        sortFunction: delegateTypeSortFunction,
        cell: (row) => RenderDelegateType(row.delegate_type),
      },  
      {
        name: "Campus",
        selector: "campus",
        sortable: true,
        wrap: true,
        cell: (row) => RenderCampus(row.campus).html,
      },
      {
        name: "Dietary Requirements",
        selector: "dietary_requirements",
        sortable: true,
        wrap: true,
        cell: (row) => (<span className="dietary_requirements">{row.dietary_requirements}</span>),
      }, 
      {
        name: "Laptop Requirements",
        selector: "aptop_requirements",
        sortable: true,
        wrap: true,
        sortFunction: laptopTypeSortFunction,
        cell: (row) => RenderDelegateLaptopType(row.laptop_requirements),
      },    
      {
        name: "Credit Bearing",
        selector: "cb",
        sortable: true,
        sortFunction: creditBearingTypeSortFunction,
        wrap: true,
        cell: (row) => RenderCreditBearing(row.credit_bearing ? "Yes" : "No"),
      },   
      {
        name: "Brand",
        selector: "Brand",
        sortable: true,
        wrap: true,
        sortFunction: brandSortFunction,
        cell: (row) => (row.brand_color ? RenderBrandListMultiLine(row.subsidiary).html : <span className="brand textLimit50">{row.subsidiary}</span>),
      },
      {
        name: "Actions",
        selector: "",
        cell: (row) => (
          <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
          <PermissionsGate
          scopes={["regview"]}
          RenderError={() => (
            <button
            className="btn btn-primary rounded-circle"
            title="Open"
            disabled
            >
            <i className="fal fa-folder-open"></i>
            </button>
            )}
            >
            <Link
            to={`/studentadministration/registration/open/registration?studentId=${row.ami_reg_id}&studentName=${row.first_name}%20${row.last_name}`}
            className="btn btn-primary rounded-circle"
            title="Open"
            >
            <i className="fal fa-folder-open"></i>
            </Link>
            </PermissionsGate>
            </div>
            </div>
            ),
          }
        ], []);
        
        const exportData = (fileType, fileName) => {
          let data = coursebookingList;
          let header = [
            "Name",
            "Status",
            "Phone",
            "Email",
            "Delegate Type",
            "Campus",
            "Dietary Requirements",
            "Laptop Requirements",
            "Credit Bearing",
            "Brand"
          ];
          
          Swal.fire({
            title: "File downloading",
            onOpen: function () {
              Swal.showLoading();
            },
          });
          
          let data1 = {
            data: {
            courseId: id
            }
          };
          getBookedCoursesList(data1)
          .then((res) => {
            data = res?.data?.data[0].bookings;
            data = data?.map((row) => ({
              "Name" : row.first_name + " " + row.last_name,
              "Status" : row.status,
              "Phone" : row.phone,
              "Email" : row.email,
              "Delegate Type" : row.delegate_type,
              "Campus" : row.campus,
              "Dietary Requirements" : row.dietary_requirements,
              "Laptop Requirements" : row.laptop_requirements,
              "Credit Bearing" : row.credit_bearing,
              "Brand" : row.subsidiary,
            }));
            
            if (fileType === "xlsx") {
              const compatibleData = data.map((row) => {
                const obj = {};
                header.forEach((col, index) => {
                  obj[col] = row[col];
                });
                return obj;
              });
              
              let wb = XLSX.utils.book_new();
              let ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
              XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
              XLSX.writeFile(wb, `${fileName}.xlsx`);
              Swal.close();
              return false;
            }
          })
          .catch((error) => {
            console.log("error >>>>>> ", error);
          });          
        };
        const formik = useFormik({
          enableReinitialize: true,
          initialValues: {
            room : room ? room : "",
            catering : cateringSetup ? cateringSetup : "",
            cateringId: caterId ? caterId : "",
            ITSetup : ITSetup ? ITSetup : "",
            it_setup_id: it_setup_id ? it_setup_id : "",
            roomId: roomId ? roomId : "",
            courseId : courseId  ? courseId : "",
          },          
          onSubmit: (values) => {
            setDisabled(true);
            setLoading(true);
           
            
            let SubmitData = {
              room: values.roomId,
              catering: values.cateringId,
              it: values.it_setup_id,
              courseId: values.courseId,
            }
            
            updateBookedCourseDetails(SubmitData)
              .then((data) => {
                setLoading(false);
                setDisabled(false);
                Swal.fire("Saved!", "Course has been updated in NetSuite.", "success");
                history.push(`/courseAdministration/bookedcourses/open/${id}`);
              })
              .catch((error) => {
                setLoading(false);
                console.log("error ---->", error);
              });
          },          
        });
        const handlereset = () => {
          formik.resetForm();
        };
        return (      
          <>
          <Header></Header>
          <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
          <div className="sub-menu-content-block">
          <div className="sub-menu-right-block">
          <div className="wrap-box-right">
          <div className="my-tickets-heading-blk flex-direction mb-2">
          <div className="d-flex align-items-center ">
          <div className="name-w-head d-flex align-items-center ">
          <h4 className="text-left-align landing-heading heading_color_dynamic">
          Course <span>Bookings</span>: {courseName}
          </h4>
          </div>
          <div className="text-md-right action2-blk">
          <div className="ticket-view-btn d-flex align-items-center">
          <div className="add-new-ticket-bx">
          <button
          onClick={() => history.goBack()}
          title="Back"
          className="btn btn-white-bordered"
          >
          <i className="fal fa-angle-left"></i>Back
          </button>
          </div>
          </div>
          </div>
          </div>
          </div>
          <div className="tabs-wrap">
          <ul className="nav nav-pills mb-4" id="tabs"role="tablist">
          <li className="nav-item" onClick={() => { history.push("/courseAdministration/bookedcourses");}}>
          <a
          className={`nav-link cursor-pointer active`}
          id="pills-tab1"
          data-toggle="pill"
          role="tab"
          aria-controls="pills-tab12"
          aria-selected="true"
          >
          <i className="fal fa-globe-africa"></i>Details
          </a>
          </li> 
          </ul>                       
          <div className="tab-content" id="pills-tabContent">
          <div
          className="tab-pane fade active show"
          id="pills-tab11"
          role="tabpanel"
          aria-labelledby="pills-tab1"
          >
          <div className="my-tickets-info-list Tickets-main-wrap">
          <div className="card mb-4">
            <div className="edit-icon new-card-header">
              <div className="card-header">Course Details</div>
              {loading ? <i className="fas fa-cog fa-spin"></i> : ""}
            </div>
            <form onSubmit={formik.handleSubmit}>
            <div className="card-body-inr card-body-info">
            <div className="row">
                <div className="col-md-3 col-lg-4">
                    <label><strong>Name: </strong></label>
                    <br/>
                    <label>{courseName}</label>              
                </div>
                <div className="col-md-3 col-lg-4">
                    <label><strong>Status: </strong></label>
                    <br/>
                    <label>{RenderBookingState(courseStatus_Id).html}</label>              
                </div>
                <div className="col-md-3 col-lg-4">
                    <label><strong>Campus: </strong></label>
                    <br/>
                    <label>{RenderCampus(campus).html}</label>              
                </div>
                {/* <div className="col-md-3 col-lg-4">
                    <label><strong>Brand: </strong></label>
                    <br/>
                    <label>{RenderBrandList(brand).html}</label>              
                </div> */}
                <div className="col-md-3 col-lg-4">
                    <label><strong>Final Total: </strong></label>
                    <br/>
                    <label style={final_total && final_total > 0 ? {"backgroundColor": "yellow", "padding": "5px"} : {}}>{final_total}</label>              
                </div>
                <div className="col-md-3 col-lg-4">
                    <label><strong>Prelim Total: </strong></label>
                    <br/>
                    <label style={prelim_total && prelim_total > 0 ? {"backgroundColor": "#FFCCCB", "padding": "5px"} : {}}>{prelim_total}</label>              
                </div>
                <div className="col-md-3 col-lg-4">
                    <label><strong>Laptop Total: </strong></label>
                    <br/>
                    <label style={laptop_total && laptop_total > 0 ? {"backgroundColor": "lightblue", "padding": "5px"} : {}}>{laptop_total}</label>              
                </div>
                <div className="col-md-3 col-lg-4">
                    <label><strong>Instructor: </strong></label>
                    <br/>
                    <label style={!instructor || instructor == "" ? {backgroundColor: "red", padding: "5px 30px"} : {}}>{instructor}</label>              
                </div> 
                <div className="col-md-3 col-lg-4">
                    <label><strong>Start Date: </strong></label>
                    <br/>
                    <label>{startDate}</label>              
                </div>
                <div className="col-md-3 col-lg-4">
                    <label><strong>End Date: </strong></label>
                    <br/>
                    <label>{endDate}</label>              
                </div>                
                <div className="col-md-3 col-lg-4">
                <div className="form-icon-group mb-8 d-block" title="Room Setup">
                    <label><strong>Room</strong></label>
                    <Select
                      isClearable
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.room &&
                        formik.touched.room
                          ? " is-invalid"
                          : "")
                      }
                      name="room"
                      // value={formik.values.correspondence || ""}
                      value={ roomOptions?.filter(function (
                        option
                      ) {
                        return (
                          option.value ==
                          formik.values.roomId
                        );
                      })}
                      onChange={(value) => {
                        if(value){
                          formik.setFieldValue("room",value.key);
                          formik.setFieldValue("roomId",value.value);
                        } else {
                          formik.setFieldValue("room","");
                          formik.setFieldValue("roomId","");
                        }
                        
                      }}
                      onBlur={formik.handleBlur}
                      options= {roomOptions?.filter(function (
                        option
                      ) {
                        return (
                          option.campus ==
                          campus_id
                        );
                      })}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.room
                          ? formik.values.room
                          : "Room"
                      }
                    />
                    {formik.errors.room &&
                    formik.touched.room ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                  </div>                              
                <div className="col-md-3 col-lg-4">
                <div className="form-icon-group mb-8 d-block" title="Catering Setup">
                    <label><strong>Catering Setup </strong></label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.catering &&
                        formik.touched.catering
                          ? " is-invalid"
                          : "")
                      }
                      name="catering"
                      // value={formik.values.correspondence || ""}
                      value={cateringOptions.filter(function (
                        option
                      ) {
                        return (
                          option.value ==
                          formik.values.cateringId
                        );
                      })}
                      onChange={(value) => {
                        if(value){
                          formik.setFieldValue("catering",value.key);
                          formik.setFieldValue("cateringId",value.value);
                        } else {
                          formik.setFieldValue("catering",{cateringSetup});
                          formik.setFieldValue("cateringId",{caterId});
                        }
                        
                      }}
                      onBlur={formik.handleBlur}
                      options= {cateringOptions}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.catering
                          ? formik.values.catering
                          : "Catering Setup"
                      }
                    />
                    {formik.errors.catering &&
                    formik.touched.catering ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                  </div>
                  <div className="col-md-3 col-lg-4">
                    <div className="form-icon-group mb-8 d-block" title="IT Setup">
                    <label><strong>IT Setup </strong></label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.ITSetup &&
                        formik.touched.ITSetup
                          ? " is-invalid"
                          : "")
                      }
                      name="ITSetup"
                      // value={formik.values.correspondence || ""}
                      value={itOptions.filter(function (
                        option
                      ) {
                        return (
                          option.value ==
                          formik.values.it_setup_id
                        );
                      })}
                      onChange={(value) => {
                        if(value){
                          formik.setFieldValue("ITSetup",value.key);
                          formik.setFieldValue("it_setup_id",value.value);
                        } else {
                          formik.setFieldValue("ITSetup",{ITSetup});
                          formik.setFieldValue("it_setup_id",{it_setup_id});
                        }
                        
                      }}
                      onBlur={formik.handleBlur}
                      options= {itOptions}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.ITSetup
                          ? formik.values.ITSetup
                          : "IT Setup"
                      }
                    />
                    {formik.errors.ITSetup &&
                    formik.touched.ITSetup ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>      
                  </div>   
                  <div className="col-md-3 col-lg-4">
                  <div className="form-group form-group-save-cancel mt-4">
                  <PermissionsGate scopes={["cbedit"]} errorProps={{disabled:true}}>
                  <button
                    className="btn btn-save btn-success"
                    type="Submit"
                    title="Save"
                    disabled={disabled}
                  >
                    {disabled ? (
                      <i className="fas fa-cog fa-spin"></i>
                    ) : (
                      <i className="fal fa-save"></i>
                    )}
                    Save
                  </button>
                  </PermissionsGate>
                  <button
                    className="btn btn-close btn-danger"
                    type="button"
                    title="Cancel"
                    onClick={() => handlereset()}
                  >
                    <i className="fal fa-times"></i>Cancel
                  </button>
                  {Object.keys(formik.values).map((key, ind) => {
                    if (formik.touched[key] && formik.errors[key]) {
                    return (
                        <div className="invalid-feedback d-block" key={ind}>
                        {formik.errors[key]}
                        </div>
                    )}
                  })}                
                </div>    
                </div>   
                </div>                               
                </div>
            </form>
            <br/>
            <br/>
          <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom days-overdue-cell-width">
          <div className="edit-icon new-card-header">
              <div className="card-header">Course Bookings</div>
          </div>
          <div className="search-filter-div">
          <div className="search-filter-div-left">
          <div className="system-administration-table table-responsive">
          <div className="table-responsive-div">          
          <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer" >          
          <div id="assessment-table-main_filter" className="dataTables_filter">          
          {/* <label>
          <input
          type="search"
          className=""
          placeholder="Search"
          aria-controls="assessment-table-main"
          onChange={handleSearchFilter}
          value={search}
          />
          </label> */}
          {/* <div className="filter-eff filter-data-btn">
          <button className="filter-buttons">
          <i className="fal fa-filter"></i>                                                 
          </button>
          </div> */}
          </div>
          </div>
          </div>
          <div className="filter-button-group">
          <div className="filter-scroll">
          <div className={`filter-scroll-inner  filter-custom-new`}>
          {/* <Tablefilter                           
          filterName="Date Range"
          optionArr={courseDropdown.date}
          state={dateFilter}
          setState={setdateFilter}
          singleSelect="true"
        /> */}
        </div>                                                               
        </div>
        <div className="reset-btn-group">
        <div className="button-reset dropdown-comman">
        <button
        className="btn btn-primary"
        onClick={() => resetFilter()}
        title="Reset"
        >
        <i className="fal fa-redo"></i>Reset
        </button>
        </div>        
        <div className="files-export-group">
        <button
        type="button"
        className="btn btn-files"
        onClick={() => {
          exportData("xlsx", "BookingList");
        }}
        title="Export spreadsheet"
        >
        <i className="fal fa-file-excel icon"></i>
        </button>               
        </div>
        </div>
        </div>
        </div>
        <div className="search-filter-div-right">
        <div className=" filter-search-bar-blk">
        <div className="add-ticket-blk button-reset dropdown-comman">
        </div>
        </div>
        </div>
        </div>
        </div> 
        <DataTable
        defaultSortField="SAQA_Name"
        data={coursebookingList}
        columns={columns}
        pagination={true}
        progressComponent={<SkeletonTicketList />}
        progressPending={loading}
        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />    
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </>
        );
        
      };
      
      export default OpenCourseBookings;
      