import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import {
  DeleteProgramme,
  getCommonContent,
  GetListofModule,
  GetListofModuleCancelToken,
  ListModulesFilters,
  ListModulesFiltersCancelToken,
} from "../../../services/ProgramService";
import Str from "../../common/Str";
import Swal from "sweetalert2";
import { TypeofModule } from "../program/GroupItems";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { TrimText } from "../../common/TrimText";
import { Link } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import Tablefilter from "../../common/Tablefilter";
import { RenderModuleType } from "../../../utils/CommonGroupingItem";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import axios from "axios"
import $ from "jquery";
import { handleTableScroll } from "../../../utils/commonFunction";

const Modules = () => {
  const history = useHistory();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [dummy, setDummy] = useState(false);
  const [duration, setDuration] = useState({ arr: [], checkObj: {} });
  const [moduleType, setModuleType] = useState({ arr: [], checkObj: {} });
  const [filterData, setFilterData] = useState({type : []});
  const [cancelTokenSources, setCancelTokenSources] = useState([]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    let response = hasPermission({
      scopes: ["pmcmview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  // const getListOfModule = async () => {
  //   setLoading(true)

  //   cancelTokenSources.forEach(source => {
  //     source.cancel('New request made');
  //   });

  //   const source = axios.CancelToken.source();
  //   setCancelTokenSources([...cancelTokenSources, source]);

  //   try {
  //     const res = await GetListofModuleCancelToken(source.token);
  //     setData(res.data);
  //     if (res.status == 200) {
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     if (!axios.isCancel(error)) {
  //       console.error(error);
  //       setLoading(false);
  //     }
  //   }
  // }

  useEffect(() => {
    const cancelTokenSources = [];

    const getListOfModule = async () => {
      setLoading(true)
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);
  
      try {
        const res = await GetListofModuleCancelToken(source.token);
        setData(res.data);
        if (res.status == 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    }

    getListOfModule()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [dummy]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await ListModulesFiltersCancelToken(dropdownSource.token);
        setFilterData({...res.data, type : res.data.ModuleTypeFilter})
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const portalSort = (a, b) => {
    return a.Portal.toLowerCase() > b.Portal.toLowerCase() ? 1 : -1;
  };
  const MarketingSort = (a, b) => {
    if(a.Marketing_Name !== null && b.Marketing_Name !== null) {
      return a.Marketing_Name.toLowerCase() > b.Marketing_Name.toLowerCase()
      ? 1
      : -1;
    }
  };
  const codeSort = (a, b) => {
    return a.Code.toLowerCase() > b.Code.toLowerCase() ? 1 : -1;
  };
  const columns = useMemo(() => [
    {
      name: "Portal Names",
      selector: "Portal",
      sortable: true,
      sortFunction: portalSort,
      cell: (row) => (
        <PermissionsGate
          scopes={["pmcmedit"]}
          RenderError={() => (
            <span className="feature-name">
              {" "}
              <span className="textLimit100">{row.Portal}</span>
            </span>
          )}
        >
          <Link
            to={`/program-management-center/modules/module/open/${row.Id}`}
            className="as-text-blue curser feature-name"
            title={row.Portal}
          >
            {row?.Portal.trim() ? <span className="textLimit100">{row?.Portal}</span> : "-"}
          </Link>
        </PermissionsGate>
      ),
    },
    {
      name: "Code",
      selector: "Code",
      sortable: true,
      sortFunction: codeSort,
      cell: (row) => (
        <div className="ticket-id">
          {row.Code ? (
            <span title={row.Code} className="overflow-ellipsis2">
              {TrimText(row.Code, 10)}
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
    },

    {
      name: "Marketing Name",
      selector: "Marketing_Name",
      sortable: true,
      sortFunction: MarketingSort,
      cell: (row) =>
        row.Marketing_Name ? (
          <span title={row.Marketing_Name} className="overflow-ellipsis2">
            {row.Marketing_Name.trim() ? TrimText(row.Marketing_Name, 20) : "-"}
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Duration (Weeks)",
      selector: "Duration",
      sortable: true,
      cell: (row) =>
        row.Duration && row.Duration.toString() ? (
          <div title={row.Duration}>{row.Duration}</div>
        ) : (
          "-"
        ),
    },
    {
      name: "#Topics",
      selector: "topics",
      sortable: true,
      cell: (row) =>
        row.topics && row.topics.toString() ? (
          <div title={row.topics}>{row.topics}</div>
        ) : (
          "-"
        ),
    },
    {
      name: "Type",
      selector: "Module_Type",
      sortable: true,
      cell: (row) => (row.Module_Type ? RenderModuleType(row.Module_Type).html : "-"),
    },
    {
      name: "#Programmes",
      selector: "Programmes",
      sortable: true,
      cell: (row) =>
        row.Programmes.toString() ? (
          <div title={row.Programmes}>{row.Programmes}</div>
        ) : (
          "-"
        ),
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate
              RenderError={() => (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
              scopes={["pmcmedit"]}
            >
              <Link
                to={`/program-management-center/modules/module/open/${row.Id}`}
                className="btn btn-primary rounded-circle"
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            <div className="dropdown btn-dropdown-item">
              <button
                title="More"
                className="btn btn-primary rounded-circle dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <PermissionsGate
                  scopes={["pmcmadd"]}
                  RenderError={() => (
                    <button
                      className="btn btn-primary rounded-circle"
                      title="Duplicate"
                      disabled
                    >
                      <i className="fal fa-copy"></i>
                    </button>
                  )}
                >
                  <button
                    className="btn btn-primary rounded-circle"
                    title="Duplicate"
                    onClick={() =>
                      history.push(
                        "/program-management-center/modules/module/duplicate/" +
                        row.Id
                      )
                    }
                  >
                    <i className="fal fa-copy"></i>
                  </button>
                </PermissionsGate>

                <PermissionsGate
                  scopes={["pmcmdelete"]}
                  RenderError={() => (
                    <button
                      className="btn btn-danger rounded-circle"
                      title="Delete"
                      disabled
                    >
                      <i className="fal fa-trash-alt"></i>
                    </button>
                  )}
                >
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Delete"
                    onClick={() => handleDelete(row.Id)}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteProgramme({ id: ID })
          .then((res) => {
            Swal.fire("Deleted!", "Your module has been deleted.", "success");
            setDummy(!dummy);
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      let temp = allData.filter((item) => {
        let includes =
          item.Portal &&
          item.Portal.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempCode = allData.filter((item) => {
        let includes =
          item.Code &&
          item.Code.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempMarketing_Name = allData.filter((item) => {
        let includes =
          item.Marketing_Name &&
          item.Marketing_Name.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempDuration = allData.filter((item) => {
        let includes =
          item.Duration &&
          item.Duration.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let temptopics = allData.filter((item) => {
        let includes =
          item.topics &&
          item.topics.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempModule_Type = allData.filter((item) => {
        let includes =
          item.Module_Type &&
          item.Module_Type.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempProgrammes = allData.filter((item) => {
        let includes =
          item.Programmes &&
          item.Programmes.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempdata = [
        ...tempCode,
        ...tempMarketing_Name,
        ...tempDuration,
        ...temptopics,
        ...tempModule_Type,
        ...tempProgrammes,
        ...temp,
      ];
      let unique = [...new Set(tempdata)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (search.length) {
      return updatedData;
    }
    if (duration.arr.length) {
      let tempData = updatedData;
      let tempSearch = tempData.filter((item) => {
        const startsWith = duration.arr.find((weeks) => {
          if (weeks === "1-4") {
            if (Number(item.Duration) > 1 && Number(item.Duration) <= 4)
              return item;
          }
          if (weeks === "5-8") {
            if (Number(item.Duration) > 4 && Number(item.Duration) <= 8)
              return item;
          }
          if (weeks === "9-12") {
            if (Number(item.Duration) > 8 && Number(item.Duration) <= 12)
              return item;
          }
          if (weeks === "> 12") {
            if (Number(item.Duration) > 12) return item;
          }
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (moduleType.arr.length) {
      let tempStudyType = updatedData;
      let tempSearch = tempStudyType.filter((item) => {
        const startsWith = moduleType.arr.find(function (post, index) {
          if (
            post.toLowerCase() ==
            (item.Module_Type ? item.Module_Type.toLowerCase() : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }
    if (search.length || duration.arr.length || moduleType.arr.length) {
      return updatedData;
    } else {
      return data;
    }
  };
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");
    setDuration({ arr: [], checkObj: {} });
    setModuleType({ arr: [], checkObj: {} });
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = [
      "Portal Name",
      "Code",
      "Marketing Name",
      "Duration",
      "Topics",
      "Type",
      "Programmes",
    ];

    data = data?.map((row) => ({
      ...row,
      "Portal Name": row?.Portal ? row.Portal : "-",
      Code: row.Code ? row?.Code : "-",
      "Marketing Name": row?.Marketing_Name ? row?.Marketing_Name : "-",
      Duration: row?.Duration ? row.Duration : "-",
      Type: row.Module_Type ? RenderModuleType(row.Module_Type).text : "-",
      Topics: row.topics ? row.topics : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Portal Name"],
          row["Code"],
          row["Marketing Name"],
          row.Duration,
          row.Topics,
          row["Type"],
          row["Programmes"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={search}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Duration"
                      optionArr={
                        [
                          { value: "1-4", label: "1-4" },
                          { value: "4-8", label: "4-8" },
                          { value: "8-12", label: "8-12" },
                          { value: "> 12", label: "> 12" }
                        ]
                      }
                      state={duration}
                      setState={setDuration}
                      stopOptionSorting={true}
                    />
                    <Tablefilter
                      filterName="Type"
                      optionArr={filterData.type}
                      state={moduleType}
                      setState={setModuleType}
                      renderLabelFunction={RenderModuleType}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={() => resetFilter()}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Modules");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Modules");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Modules");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate
                  RenderError={() => (
                    <button
                      className="btn btn-primary"
                      title="Create New"
                      disabled
                    >
                      <i className="fal fa-plus"></i>Create New
                    </button>
                  )}
                  scopes={["pmcmadd"]}
                >
                  <button
                    className="btn btn-primary"
                    title="Create New"
                    onClick={() =>
                      history.push(
                        "/program-management-center/modules/module/add"
                      )
                    }
                  >
                    <i className="fal fa-plus"></i>Create New
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <SkeletonTicketList />
        ) : (
          <DataTable
            keyField="Id"
            data={dataToRender()}
            defaultSortField="Portal"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        )}
      </div>
    </div>
  );
};

export default Modules;
