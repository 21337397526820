import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Header from '../components/common/Header'
import SideBar from '../components/common/SideBar'
import AppealsPage from '../components/studentsupport/Appeals/AppealsPage'
import AuditTrailTabs from '../components/studentsupport/Appeals/AuditTrailTabs'
import { GlobalIconNames } from '../utils/GlobalIconNames'

function Appeals() {
  const { tab, type } = useParams();
  const history = useHistory();
  return (
    <div>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        Appeals <span>Management</span>
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                            <i className="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li className="nav-item" onClick={() => history.push("/supportAdministration/appeals/appeal/pending")}>
                      <Link className={`nav-link cursor-pointer ${tab === "appeal" & type === "pending" ? "active" : ""}`} id="pills-tab1" data-toggle="pill" to={"/supportAdministration/appeals/appeal/pending"} href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true">
                        <i className="fa fa-exclamation"></i>Pending
                      </Link>
                    </li>
                    <li className="nav-item" onClick={() => history.push("/supportAdministration/appeals/appeal/approved")}>
                      <Link className={`nav-link cursor-pointer ${tab === "appeal" & type === "approved" ? "active" : ""}`} id="pills-tab1" data-toggle="pill" to={"/supportAdministration/appeals/appeal/approved"} href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true">
                        <i className="fa fa-thumbs-up"></i> Approved
                      </Link>
                    </li>
                    <li className="nav-item" onClick={() => history.push("/supportAdministration/appeals/appeal/declined")}>
                      <Link className={`nav-link cursor-pointer ${tab === "appeal" & type === "declined" ? "active" : ""}`} id="pills-tab1" data-toggle="pill" to={"/supportAdministration/appeals/appeal/declined"} href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true">
                        <i className="fa fa-thumbs-down"></i> Declined
                      </Link>
                    </li>
                    <li className="nav-item" onClick={() => history.push("/supportAdministration/appeals/auditTrail/user")}>
                      <Link className={`nav-link cursor-pointer ${tab === "auditTrail" ? "active" : ""}`} id="pills-tab2" data-toggle="pill" to={"/supportAdministration/appeals/auditTrail/table"} href="#pills-tab12" role="tab" aria-controls="pills-tab12" aria-selected="true">
                        <i className="fal fa-history"></i>
                        Audit Trail
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">

                      {tab == "appeal" && <AppealsPage />}
                      {tab == "auditTrail" && <AuditTrailTabs />}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Appeals