import React from "react";
import { useHistory, useParams } from "react-router";
import QuizAuditTrail from "./blocks/quizAuditTrail";
import EmpAudit from "./EmpAudit";
import StudentAudit from "./StudentAudit";

const AuditTrails = () => {
    const history = useHistory();
    const { tabName, tab , subtab } = useParams();
  return (
    // <div className="tabs-wrap">
    //   <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
    //     <li className="nav-item" onClick={() => history.push("/courseAdministration/smartAssessment/assessment/audittrail/student")}>
    //       <a className={`nav-link ${(tabName == "assessment" && tab =="audittrail" && subtab === "student") ? "active" : ""}`} id="pills-tab101" data-toggle="pill" href="#pills-tab21" role="tab" aria-controls="pills-tab21" aria-selected="true">
    //         <i className="fal fa-history"></i> Student Audit Trail
    //       </a>
    //     </li>
    //     <li className="nav-item" onClick={() => history.push("/courseAdministration/smartAssessment/assessment/audittrail/employee")}>
    //       <a className={`nav-link ${tabName == "assessment" && subtab === "employee"  ? "active" : ""}`} id="pills-tab201" data-toggle="pill" href="#pills-tab22" role="tab" aria-controls="pills-tab22" aria-selected="true">
    //       <i className="fal fa-history"></i> Employee Audit Trail
    //       </a>
          
    //     </li>
    //   </ul>
    //   <div className="tab-content" id="pills-tabContent">
    //         <div className={"tab-pane fade active show"} id="pills-tab21" role="tabpanel" aria-labelledby="pills-tab101">
    //           { tabName === "assessment" && tab === "audittrail" && subtab === "student"  && <StudentAudit />} 
    //         </div>
    //         <div className={"tab-pane fade active show"} id="pills-tab22" role="tabpanel" aria-labelledby="pills-tab201">
    //           { tabName === "assessment" && tab === "audittrail" && subtab === "employee" && <EmpAudit />}
    //         </div>
    //       </div>
    // </div>
    // <StudentAudit />
    <QuizAuditTrail />
  );
};

export default AuditTrails;
