import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {Color,} from "../../systemadministration/usermanagement/CheckRole";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import { emailList } from "../../../services/EmailServices";
import hasPermission from "../../../utils/hasMultiplePermission";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";
const ListTable = () => {
  const location = useLocation();
  const loc = useLocation();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [role, setRole] = useState(
    loc && loc.state && loc.state.name ? [loc.state.name] : []
  );
  const [searchRoleCheck, setSearchRoleCheck] = useState(
    loc && loc.state && loc.state.role_name ? loc.state.role_name : {}
  );
  const [company, setCompany] = useState([]);
  const [searchCompanyCheck, setSearchCompanyCheck] = useState({});
  const [searchLocationCheck, setSearchLocationCheck] = useState({});

  const [report, setReport] = useState([]);
  const [searchReportCheck, setSearchReportCheck] = useState({});

  const [deleterow, setDeleteRow] = useState(false);

  const [userData, setUserData] = useState([]);
  const [roleList, setRoleList] = useState();
  const [loading, setloading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("type");
  const [sortOrder, setSortOrder] = useState("ASC");

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  // TODO Api Call

  useEffect(() => {

    let response = hasPermission({ scopes: ["bnntview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])

  useEffect(() => {
    setPage(1)

  }, [location]);

  useEffect(() => {
    const cancelTokenSources = [];
    const getList = async () =>{
      setloading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();      
      cancelTokenSources.push(source);
      const data = {
        page: page,
        limit: perPage,
        viaSearch: search ? search : "",
        exportStatus: false,
        key: sortkey,
        sort: sortOrder,
      };

      try {
        const res = await emailList(data, source.token);
        if(res.status==200){
          setUserData(res?.data?.data_list?.data);
          setTotalRows(res?.data?.data_list?.total);
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }

    }

    getList();
    return () => {
      cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
      });
    };
  }, [deleterow, search, page, perPage, sortkey, sortOrder,]);

  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column?.selector);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  // TODO Filtering

  const resetFilter = () => {
    setStatus([]);
    setSearchStatusCheck({});
    setRole([]);
    setSearchRoleCheck({});
    setCompany([]);
    setSearchCompanyCheck({});
    // setLocation([]);
    setSearchLocationCheck({});
    setReport([]);
    setSearchReportCheck({});
    setSearch("");
  };
  // TODO Data
  const sortingSubject = (rowA, rowB) => {
    const name1 = rowA.subject.toString().toLowerCase().trim();
    const name2 = rowB.subject.toString().toLowerCase().trim();
    return name1.localeCompare(name2);
  };
  const columns = useMemo(() => [
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
      sortFunction : sortingSubject,
      // cell: (row) => (row.subject ? TrimText(row.subject) : "NA"),
      cell: (row) => (row?.subject ? <Link
        className="as-text-blue curser textLimit100"
        to={`/notification/email/list/edit/${row.id}?name=${row?.subject}`}
        title={row?.subject}
        // onClick={() => handleEdit(row, row.id)}
      >{row?.subject}</Link> : "-"),
    },

    {
      name: "Type",
      selector: "type",
      sortable: true,
      cell: (row) => (row?.type ? <p title={row?.type}>{row?.type}</p> : "-"),
    },

    {
      name: checkPermissions(["bnntedit"]) ? "Action" : "",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate scopes={["bnntedit"]}>
              {/* <button
                title="Open"
                className="btn btn-primary rounded-circle"
                onClick={() => handleEdit(row, row?.id)}
              >
                <i className="fal fa-folder-open"></i>
              </button> */}
              <Link
                to={`/notification/email/list/edit/${row?.id}?name=${row?.subject}`}
                className="btn btn-primary rounded-circle"
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);


  function checkPermissions(arr = []) {
    return hasPermission({ scopes: arr, permissions: givenPermsisions });
  }

  // const handleDelete = (ID) => {
  //   // console.log(ID);

  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       DeleteUser({ UserID: ID }).then((res) => setDeleteRow(!deleterow));

  //       Swal.fire("Deleted!", "Your user has been deleted.", "success");
  //     }
  //   });
  // };

  const handleEdit = (row, ID) => {
    // const result = checkRole(user.role_name, row.role_name);
    // console.log(user.role_name, row.role_name, result);
    // if (result === "enabled") {
    //   history.push(`/notification/email/list/edit/${ID}`);
    // } else {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Not authorised",
    //     text: "You dont have the permission to Edit ",
    //   }).then((result) => {});
    // }
    history.push(`/notification/email/list/edit/${ID}?name=${row?.subject}`);
  };

  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    // let data = dataToRender();
    let data;
    const header = ["Subject", "Type",];

    // data = data?.map((row) => ({
    //   ...row,
    //   ID: row?.id,
    //   Type: row?.type ? row?.type : "NA",
    //   Subject: row?.subject ? row?.subject : "NA",
    // }));

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });


    emailList({
      page: page,
      limit: perPage,
      viaSearch: search ? search : "",
      exportStatus: "true"
    })
      .then((res) => {
        data = res?.data?.data_list.map((row) => ({
          Subject: row?.subject ? row?.subject : "-",
          Type: row?.type ? row?.type : '-',
          // Status: row.status ? row.status : "-",
          // To: row.to ? row.to : "-",
          // "Created Date": `${moment(row.created_date).format(TABLE_DATE_FORMAT)} ${moment(row.created_date).format(TABLE_TIME_FORMAT)} - ${moment(row.created_date).format(TABLE_TIME_FORMAT)}`,
        }))

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });

          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

          const blobURL = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Subject"],
              row["Type"],
              // row["To"],
              // row["Created Date"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      });
  };

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
     // use the selector function to resolve your field names by passing the sort comparitors
     const aField = rowA[selector]?.toString()?.toLowerCase()
     const bField = rowB[selector]?.toString()?.toLowerCase()
   
     let comparison = 0;
   
     if (aField > bField) {
      comparison = 1;
     } else if (aField < bField) {
      comparison = -1;
     }
   
     return direction === 'desc' ? comparison * -1 : comparison;
    });
   };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Notification-Templates");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Notification-Templates");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Notification-Templates");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate scopes={["bnntadd"]}>
                  <button className="btn btn-primary" title="Create New" onClick={() => history.push(`/notification/email/list/add`)}>
                    <i className="fal fa-plus"></i>Create New
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
          <DataTable
            data={userData}
            defaultSortField="type"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={<SkeletonTicketList />}
            onSort={handleSort}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            //sortFunction={customSort}
          />
      </div>
    </div>
  );
};

export default ListTable;
