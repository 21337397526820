import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { NetSuiteCustomerService, NetSuiteCustomerServiceCancelToken } from "../../../services/NetSuiteService";
import { EMAIL_REGEX, NAME_REGEX } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Select from "react-select";
import axios from "axios";
const CustomerEdit = (props) => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [customerDetail, setCustomerDetail] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [brandList, setBrandList] = useState([
    // { value: 1, label: "AIE" },
    // { value: 2, label: "GDC" },
    { value: 1, label: "AIE Group" },
    { value: 2, label: "AIE Group : AIE" },
    { value: 3, label: "AIE Group : AIE : GDC" },
    { value: 4, label: "AIE Group : AIE Prop" },
    { value: 5, label: "AIE Group : X Group" },
  ]);
  const [subsidiaries, setSubsidiaries] = useState();

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    let formData = new FormData();
    formData.append("method", "GET");
    formData.append("customer_id", props.customer_id);

    const fetchData = async () => {
      try {
        const res = await NetSuiteCustomerServiceCancelToken(formData, dropdownSource.token);
        if (res?.status == 200) {
          let x = {};
        x = res?.data?.data;
        setSubsidiaries(x?.subsidiary?.id);
        setCustomerDetail(x);
        setIsLoaded(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsLoaded(false);
        }
      }
    };
    if (props.customer_id){
    fetchData();
    }
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: customerDetail ? customerDetail.firstName : "",
      lastName: customerDetail ? customerDetail.lastName : "",
      companyName: customerDetail ? customerDetail.companyName : "",
      email: customerDetail ? customerDetail.email : "",
      brand: subsidiaries ? brandList[brandList?.findIndex(option=>option?.value == subsidiaries)]?.label : "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().trim("The first name cannot include leading and trailing spaces").required("First Name is required"),
      lastName: Yup.string().trim("The last name cannot include leading and trailing spaces").required("Last Name is required"),
      companyName: Yup.string().trim("The last name cannot include leading and trailing spaces").required("Company Name is required"),
      email: Yup.string().email("Invalid email address").required("Email is required").matches(EMAIL_REGEX, "Please Enter a Valid Email"),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      let formData = new FormData();
      formData.append("method", "PATCH");
      formData.append("customer_id", props.customer_id);
      formData.append("firstName", values?.firstName);
      formData.append("lastName", values?.lastName);
      formData.append("companyName", values?.companyName);
      formData.append("email", values?.email);
      formData.append("subsidiary", subsidiaries);

      NetSuiteCustomerService(formData).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Customer Updated Successfuly",
        })
          .then(async (result) => {
            history.push(`/administration/netsuite/customers`);
          })
          .catch(function (err) {
            history.push(`/administration/netsuite/customers`);
          });
      });
    },
  });

  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        {isLoaded ? (
          <SkeletonTicketList />
        ) : (
          <>
            <div class="row">
              <div className="col-md-6 col-lg-4">
                <label htmlFor="BrandName">Company Name *</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="companyName"
                    className={"form-control" + (formik.errors.companyName && formik.touched.companyName ? " is-invalid" : "")}
                    name="companyName"
                    placeholder="Enter Company Name"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="companyName"
                  />
                  {formik.errors.companyName && formik.touched.companyName ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <label htmlFor="BrandName">First Name *</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="text"
                    className={"form-control" + (formik.errors.firstName && formik.touched.firstName ? " is-invalid" : "")}
                    name="firstName"
                    placeholder="Enter First Name"
                    title="Enter First Name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.firstName && formik.touched.firstName ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <label htmlFor="BrandName">Last Name *</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="text"
                    className={"form-control" + (formik.errors.lastName && formik.touched.lastName ? " is-invalid" : "")}
                    name="lastName"
                    placeholder="Enter Last Name"
                    title="Enter Last Name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.lastName && formik.touched.lastName ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <label htmlFor="BrandName">Email *</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="email"
                    className={"form-control" + (formik.errors.email && formik.touched.email ? " is-invalid" : "")}
                    name="email"
                    placeholder="Enter email address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="Enter email address"
                  />
                  {formik.errors.email && formik.touched.email ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group-blk mb-3">
                  <label>Brand </label>
                  <Select
                    className={"form-control custom-select-box" + (formik.errors.brand && formik.touched.brand ? " is-invalid" : "")}
                    name="brand"
                    id="brand"
                    title="Brand"
                    placeholder={formik.values.brand ? formik.values.brand : "Select Brand"}
                    options={brandList}
                    onBlur={formik.handleBlur}
                    onChange={(value) => {
                      if(value){
                        formik.setFieldValue("brand", value.label);
                        setSubsidiaries(value.value);
                      } else {
                        formik.setFieldValue("brand", "");
                        setSubsidiaries("");
                      }
                      // setGreensideClassTypeSelected(value.value)
                    }}
                    value={brandList.filter((val)=>{
                      return val.label === formik.values.brand
                    })}
                    isClearable
                  />
                  {formik.errors.brand && formik.touched.brand ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-group form-group-save-cancel">
              <button className="btn btn-save btn-success" type="submit" title="Update" disabled={disabled}>
                {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Save
              </button>
              <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()}>
                <i className="fal fa-times"></i>
                Cancel
              </button>

              {formik.touched.firstName && formik.errors.firstName ? <div className="invalid-feedback d-block">{formik.errors.firstName}</div> : null}
              {formik.touched.lastName && formik.errors.lastName ? <div className="invalid-feedback d-block">{formik.errors.lastName}</div> : null}
              {formik.touched.companyName && formik.errors.companyName ? <div className="invalid-feedback d-block">{formik.errors.companyName}</div> : null}
              {formik.touched.email && formik.errors.email ? <div className="invalid-feedback d-block">{formik.errors.email}</div> : null}
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default CustomerEdit;
