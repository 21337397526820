import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../../store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { LoginDetailService } from "../../../services/ProfileService";
import { GetUserDetail, GetUserDetailCancelToken, SoftPhoneUserDetailsUpdate, UpdateUserLoginDetail } from "../../../services/UserService";
import Swal from "sweetalert2";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
const LoginDetails = () => {
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user);
  const { id, tab } = useParams();
  const [loginData, setLoginData] = useState({})


  /** webphone script */
  useMemo(() => {

    let techdemoJquery = document.createElement("script");
    techdemoJquery.async = true;
    techdemoJquery.src = "/js/techdemo_example/jquery-1.9.1.min.js";
    document.head.appendChild(techdemoJquery);


    let webphoneApi = document.createElement("script");
    webphoneApi.async = true;
    webphoneApi.src = "/webphone_api.js";
    document.head.appendChild(webphoneApi);

    let picoModal = document.createElement("script");
    picoModal.async = true;
    picoModal.src = "/js/techdemo_example/picoModal.js";
    document.head.appendChild(picoModal);

    let stringres = document.createElement("script");
    stringres.async = true;
    stringres.src = "/js/techdemo_example/stringres.js";
    document.head.appendChild(stringres);

    let techdemo_example = document.createElement("script");
    techdemo_example.async = true;
    techdemo_example.src = "/js/techdemo_example/techdemo_example.js";
    document.head.appendChild(techdemo_example);

  }, []);

  useEffect(() => {    
    if (id || pathname == "/profile") {
      const detailSource = axios.CancelToken.source();
      const getDetails = async () =>{
        try {
          const res = await GetUserDetail({ UserID: pathname == "/profile" ? user?.UserID : id }, detailSource.token);
          if(res.status=200 && res.data){
            setLoginData(res.data);
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
        }
      }
      getDetails();

      return () => {
        detailSource.cancel('Component unmounted');
      }; 
    }
  }, []);

  /** webphone registration form validation*/
  const formikWebphone = useFormik({
    enableReinitialize: true,
    initialValues: {
      server : loginData?.softphone_server_address,
      uname : loginData?.softphone_user_name,
      pass : loginData?.softphone_password
    },
    validationSchema: Yup.object({
      server: Yup.string().required("Server address is required"),
      uname: Yup.string().required("Username is required"),
      pass: Yup.string().required("Password is required"),
    }),

    onSubmit: (values) => {      
      const sendData = {
        softphone_server_address:values.server,
        softphone_user_name:values.uname,
        softphone_password:values.pass,
        cms_user_id:pathname == "/profile" ? user?.UserID : id
      }
      SoftPhoneUserDetailsUpdate(sendData).then((res)=>{
        if(res.status===200){
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
        }
      }).catch((err)=>{
        console.log(err)
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err?.response?.data?.message,
        });
      });
      // window.webphone_api.setparameter('serveraddress', values.serveraddress);
      // window.webphone_api.setparameter('username', values.username);
      // window.webphone_api.setparameter('password', values.password);
      // document.getElementById('events').innerHTML = 'EVENT, Initializing...'; 
      // window.Start();
    }
  });
 
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Email: loginData.Email,
      Password: "",
      Password_confirmation: "",
    },
    validationSchema: Yup.object({
      Email: Yup.string().email("Invalid email address").required("Email is required"),
      Password: Yup.string()
        .required("Password is required")
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"),
      Password_confirmation: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("Password"), null], "Passwords must match"),
    }),
    onSubmit: (values, { resetForm }) => {
      // console.log("values", values);
      const body = {
        ...values,
        UserID: loginData.UserID,
      };
      UpdateUserLoginDetail(body)
        .then((res) => {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {});
          resetForm();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error?.response?.data?.message,
          }).then((result) => {});
        });
    },
  });

  return (
    <>
      <div className="card card-profile-info-card">
        <div className="card-header pt-0">Login Details</div>
        <div className={`card-body `}>
          <div className="card-body-inr card-body-info">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <div className="form-group">
                    <label>Email *</label>
                    <input
                      type="email"
                      className={"form-control" + (formik.errors.Email && formik.touched.Email ? " is-invalid" : "")}
                      name="Email"
                      disabled
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.Email && formik.touched.Email ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <div className="form-group">
                    <label>Password *</label>
                    <input
                      type="password"
                      placeholder="Password"
                      className={"form-control" + (formik.errors.Password && formik.touched.Password ? " is-invalid" : "")}
                      name="Password"
                      value={formik.values.Password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.Password && formik.touched.Password ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 ">
                  <div className="form-group">
                    <label>Confirm Password *</label>
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      className={"form-control" + (formik.errors.Password_confirmation && formik.touched.Password_confirmation ? " is-invalid" : "")}
                      name="Password_confirmation"
                      value={formik.values.Password_confirmation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.Password_confirmation && formik.touched.Password_confirmation ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 col-lg-4 mb-4">
                  <div className="Password-criteria mt-3">
                    <h5>Password Criteria!</h5>
                    <ul className="text-danger">
                      <li>Minimum 8 characters.</li>
                      <li>At least 1 uppercase letter.</li>
                      <li>At least 1 lowercase letter.</li>
                      <li>At least 1 number.</li>
                      <li>At least 1 special character.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="form-group form-group-save-cancel mb-4">
                <button className="btn btn-save btn-success" type="submit" title="Save">
                  <i className="fal fa-save"></i>
                  Save
                </button>
                <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => formik.resetForm()}>
                  <i className="fal fa-times"></i>
                  Cancel
                </button>
              </div>
              {formik.touched.Email && formik.errors.Email ? <div className="invalid-feedback d-block">{formik.errors.Email}</div> : null}
              {formik.touched.Password && formik.errors.Password ? <div className="invalid-feedback d-block">{formik.errors.Password}</div> : null}
              {formik.touched.Password_confirmation && formik.errors.Password_confirmation ? <div className="invalid-feedback d-block">{formik.errors.Password_confirmation}</div> : null}
            </form>
            <form onSubmit={formikWebphone.handleSubmit} autoComplete="off">
              <div className="row mt-3">
                <div className="col-md-6 col-lg-4 mb-4">
                  <div className="form-group">
                    <label>Server Address *</label>
                    <input
                      placeholder="Server Address"
                      className={"form-control" + (formikWebphone.errors.server && formikWebphone.touched.server ? " is-invalid" : "")}
                      id="server"
                      name="server"
                      value={formikWebphone.values.server}
                      onChange={formikWebphone.handleChange}
                      onBlur={formikWebphone.handleBlur}
                    />
                    {formikWebphone.errors.server && formikWebphone.touched.server ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-4">
                  <div className="form-group">
                    <label>Username *</label>
                    <input                      
                      placeholder="Username"
                      className={"form-control" + (formikWebphone.errors.uname && formikWebphone.touched.uname ? " is-invalid" : "")}
                      id="uname"
                      name="uname"
                      value={formikWebphone.values.uname}
                      onChange={formikWebphone.handleChange}
                      onBlur={formikWebphone.handleBlur}
                    />
                    {formikWebphone.errors.uname && formikWebphone.touched.uname ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-4">
                  <div className="form-group">
                    <label>Password *</label>
                    <input
                      // type="password"
                      placeholder="Password"
                      className={"form-control" + (formikWebphone.errors.pass && formikWebphone.touched.pass ? " is-invalid" : "")}
                      id="pass"
                      name="pass"
                      value={formikWebphone.values.pass}
                      onChange={formikWebphone.handleChange}
                      onBlur={formikWebphone.handleBlur}
                    />
                    {formikWebphone.errors.pass && formikWebphone.touched.pass ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div id="status"></div>
              <div className="form-group form-group-save">
                <button className="btn btn-save btn-success" type="submit" title="Register">
                  <i className="fal fa-save"></i>
                  Save
                </button>
              </div>
              {formikWebphone.touched.server && formikWebphone.errors.server ? <div className="invalid-feedback d-block">{formikWebphone.errors.server}</div> : null}
              {formikWebphone.touched.uname && formikWebphone.errors.uname ? <div className="invalid-feedback d-block">{formikWebphone.errors.uname}</div> : null}
              {formikWebphone.touched.pass && formikWebphone.errors.pass ? <div className="invalid-feedback d-block">{formikWebphone.errors.pass}</div> : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginDetails;
