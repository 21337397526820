export const increment = () => {
  return {
    type: "INCREMENT",
  };
};

export const decrement = () => {
  return {
    type: "DECREMENT",
  };
};

export const setUser = (payload) => {
  return {
    type: "SET_USER",
    payload,
  };
};

export const setPermission = (payload) => {
  return {
    type: "SET_PERMISSION",
    payload,
  };
};

export const setGivenPermission = (payload) => {
  return {
    type: "SET_GIVEN_PERMISSION",
    payload,
  };
};

export const setRegistarationDropDownValues = (payload) => {
  return {
    type: "SET_VALUES",
    payload,
  }
}

export const setImage = (payload) => {
  return {
    type: "SET_IMAGE",
    payload,
  };
};

export const setUserStatus = (payload) => {
  return {
    type: "SET_USER_STATUS",
    payload,
  };
};

export const unsetUser = () => {
  return {
    type: "UNSET_USER",
  };
};

export const setPayer = (payload) => {
  return {
    type: "SET_PAYER",
    payload,
  };
};

export const setFile = (payload) => {
  return {
    type: "SET_FILE",
    payload,
  };
};

export const setSubject = (payload) => {
  return {
    type: "SET_SUBJECT",
    payload,
  };
};

export const setTab = (payload) => {
  return {
    type: "SET_TAB",
    payload,
  };
};

export const unsetTab = (payload) => {
  return {
    type: "UNSET_TAB",
    payload,
  };
};

export const selectTab = (payload) => {
  return {
    type: "SELECTED_TAB",
    payload,
  };
};

export const setChatGroups = (payload) => {
  return {
    type: "SET_MY_GROUPS",
    payload,
  };
};

export const unsetChatGroups = (payload) => {
  return {
    type: "UNSET_MY_GROUPS",
    payload,
  };
};

export const setSelectedGroupsRedux = (payload) => {
  return {
    type: "SET_SELECTED_GROUPS",
    payload,
  };
};

export const unSetSelectedGroupsRedux = (payload) => {
  return {
    type: "UNSET_SELECTED_GROUPS",
    payload,
  };
};

export const setQuiz = (payload) => {
  return {
    type: "SET_QUIZ",
    payload,
  };
};

export const setQuizComplete = (payload) => {
  return {
    type: "SET_QUIZ_COMPLETE",
    payload,
  };
};

export const clearQuiz = () => {
  return {
    type: "CLEAR_QUIZ",
  };
};

export const setNotificationCount = (payload) => {
  return {
    type: "SET_NOTIFICATION_COUNT",
    payload,
  };
};

export const setSelectedNotification = (payload) => {
  return {
    type: "SET_SELECTED_NOTIFICATION",
    payload,
  };
};

export const setUnreadNotificationCount = (payload) => {
  return {
    type: "SET_UNREAD_NOTIFICATION_COUNT",
    payload,
  };
};

export const unsetUnreadNotificationCount = () => {
  return {
    type: "UNSET_UNREAD_NOTIFICATION_COUNT",
  };
};

export const setWidget = (payload) => {
  return {
    type: "SET_WIDGET",
    payload,
  };
};

export const unsetWidget = () => {
  return {
    type: "UNSET_WIDGET",
  };
};

export const modalOpen = (payload) => {
  return {
    type: "MODAL_OPEN",
    payload,
  };
};
export const modalClose = () => {
  return {
    type: "MODAL_CLOSE",
  };
};

export const setBrand = (payload) => {
  return {
    type: "SET_BRAND",
    payload,
  };
};

export const clearBrand = () => {
  return {
    type: "CLEAR_BRAND",
  };
};

export const setHoverUserData = (data) => {
  return {
    type: "SET",
    payload: data
  };
};

export const setSideBarLinks = (data) => {
  return { type: "SET", payload: data };
}

export const setBrandVisuals = (data) => {
  return { type: "SET_BRAND_VISUALS", payload: data };
}

export const clearBrandVisuals = () => {
  return { type: "CLEAR_BRAND_VISUALS" };
}

export const setAssessmentName = (data) => {
  return { type: "SET_ASSESSMENT_NAME", payload: data };
}

export const setExamCenter = (data) => {
  return {type : "SET_CENTER_NAME", payload : data}
}

export const setExamName = (data) => {
  return {type : "SET_EXAM_NAME", payload : data}
}

export const setExamPeriodName = (data) => {
  return {type : "SET_EXAM_PERIOD_NAME", payload : data}
}
export const setQuizId = (data) => {
  return { type: "SET_QUIZ_ID", payload: data };
}

export const setHoverTopicData = (data) => {
  return {
    type: "SET_TOPIC",
    payload: data
  };
};
export const setHoverKeywordData = (data) => {
  return {
    type: "SET_KEYWORD",
    payload: data
  };
};