import { param } from "jquery";
import axiosInstance from "../utils/axios";

export const AdjsutServerTime = async (values) => {
    return await axiosInstance().post(`/adjustServerTime`, values);
};

export const GetServerTime = async () => {
    return await axiosInstance().get(`/getServerTime`, {
        params: {},
    });
};

export const GetLoadBalance = async () => {
    return await axiosInstance().get(`/load_balancing`, {
        params: {},
    });
};

export const GetLoadBalanceCancelToken = async ({},cancelToken) => {
    return await axiosInstance().get(`/load_balancing`, {params: {},cancelToken:cancelToken});
};

export const GetServerAuditTrails = async () => {
    return await axiosInstance().get("/getServerManagementAuditTrials");
}

export const GetServerAuditTrailsCancelToken = async (cancelToken) => {
    return await axiosInstance().get("/getServerManagementAuditTrials",{cancelToken: cancelToken});
}

export const UpdateToggle = async (params) => {
    return await axiosInstance().post("/updateLoadBalancingToggle", params);
}