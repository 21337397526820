const hasPermission = ({ permissions, scopes }) => {
    const permissionsCheck = [];
    Object.keys(permissions).map(function (key, index) {
        permissionsCheck.push(permissions[key]);
    });
    const scopesMap = {};
    scopes.forEach((scope) => {scopesMap[scope] = true;});
    return permissionsCheck.some((permission) => scopesMap[permission]);
};

export default hasPermission;