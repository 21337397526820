import React from "react";
import { useParams } from "react-router-dom";
import AddTab from "./AddDetailsTab/AddTab";
import DetailsAuditTrail from "./AddDetailsTab/DetailsAuditTrail";

import DetailsList from "./DetailsList";

function DetailsTab() {
  const { tab, type, id, subTab, subType } = useParams();  
  return (
    <div>
      {type && type == "table" && !id && <DetailsList />}
      {type && type == "add" && !id && <AddTab />}
      {type && type == "open" && <AddTab />}
      {type && type == "audit-trail" && <DetailsAuditTrail/>}

      {/* {type && type == "add" && !id && <CustomerAdd />}
  {type && type == "edit" && id && <CustomerEdit customer_id={id} />}*/}
    </div>
  );
}

export default DetailsTab;
