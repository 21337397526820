import React, { useState, useMemo, useEffect } from "react";
import { InitialRender } from "../common/Helper";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import * as Yup from "yup";
import { useFormik } from "formik";
import JsPDF from "jspdf";
import "jspdf-autotable";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import { IMAGE_URL, TABLE_ROWS_PER_PAGE, TABLE_DATE_FORMAT, TABLE_TIME_FORMAT } from "../../utils/Constants";
import moment from "moment";
import Swal from "sweetalert2";
import PermissionsGate from "../../utils/permissionGate";
import {
  GetListOfDashboard,
  DeleteOnlineClass,
  GetLuanchButtonData,
  LaunchVirtualClass,
  JoinClassAsAdmin,
  classLaunch,
  SendQuickNotification,
  UpdateClassRecordStatus,
  getAve,
  GetLearningDashboardURL,
  GetListOfDashboardCancleToken,
  getClassFiles,
  GetVritualClassDropdownCancelToken,
} from "../../services/DashBoardServices";
import {
  ColorRender,
} from "../systemadministration/usermanagement/CheckRole";
import Hover from "../common/Hover";
import $ from "jquery";
import HtmlInputEditor from "../common/HtmlInputEditor";
import FilterComponent from "../common/FilterComponent";
import Tablefilter from "../common/Tablefilter";
import { RenderClassStatus, RenderClassType } from "../../utils/CommonStatusItems";
import { formatQuestion } from "../../utils/FormatQuestion";
import ClassRecordingModal from "../courses/DetailCoursesBlocks/OnlineClassesBlocks/ClassRecordingModal";
import ClassRecordingForm from "../courses/DetailCoursesBlocks/OnlineClassesBlocks/ClassRecordingForm";
import OnlineClassType from "../common/OnlineClassType";
import CopyToClipboard from "../CopyToClipboard";
import { RenderLearningMethod } from "../../utils/CommonGroupingItem";
import ClassSeriesPopup from "../courses/DetailCoursesBlocks/OnlineClassesBlocks/ClassSeriesPopup";
import UserColumn from "../common/UserColumn";
import PopupComponent from "../common/PopupComponent";
import { handleTableScroll } from "../../utils/commonFunction";

import axios from "axios";
const UpcomingOnlineClasses = ({filterIntake, Course,globalReset, Lecturer, filterSchool,filterLearningMethod,filterLecturer}) => {
  const [onlineClass, setOnlineClass] = useState({
    course_id: "",
    class_id: "",
  });
  const history = useHistory();
  const location = useLocation();
  const { type: urlType, tab } = useParams();
  const [classTitle, setClassTitle] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [modalStudentList, setModalStudentList] = useState([]);
  const [modalStudentId, setModalStudentId] = useState([]);
  const [modalSearch, setModalSearch] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [value, onChange] = useState([]);
  const [valueLecturer, onChangeLecturer] = useState([]);
  const [valueIntake, onChangeIntake] = useState([]);
  const [showing, setShowing] = useState([10]);
  const [searchShowing, setSearchShowing] = useState({ action41: true });
  const [duration, setDuration] = useState();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [recordingLink, setRecordingLink] = useState("Empty Link");
  const [studyType, setStudyType] = useState([]);
  const [searchStudyTypeCheck, setSearchStudyTypeCheck] = useState({});
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [searchIntakeCheck, setSearchIntakeCheck] = useState({});
  const [intakeNum, setIntakeNum] = useState([]);
  const [searchIntakeNumCheck, setSearchIntakeNumCheck] = useState({});
  const [dashboardList, setDashBoardList] = useState([]);
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });

  const [virtualDropdown, setVirtualDropdown] = useState({ course: [], intake: [], lecturer: [], status: [], learning: [] });
  const [lecturer, setLecturer] = useState([]);
  const [searchLecturerCheck, setSearchLecturerCheck] = useState({});
  const [course, setCourse] = useState([]);
  const [courseIds, setCourseIds] = useState([]);
  const [lectureIds, setLectureIds] = useState([]);
  const [searchCourseCheck, setSearchCourseCheck] = useState({});
  const [programme, setProgramme] = useState([]);
  const [programmeIds, setProgrammeIds] = useState([]);
  const [searchProgrammeCheck, setSearchProgrammeCheck] = useState({});
  const [date, setDate] = useState([
    urlType.charAt(0).toUpperCase() + urlType.slice(1)
  ]);
  const [searchDateCheck, setSearchDateCheck] = useState({});
  const [type, setType] = useState({ arr: [], checkObj: {} });
  const [searchTypeCheck, setSearchTypeCheck] = useState({});

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortkey, setSortKey] = useState("date");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [analysisBtn, setAnalysisBtn] = useState({ disable: true, link: "" });

  const [classId, setClassId] = useState(null);
  const [recurringDetails, setRecurringDetails] = useState({});
  const [dropDownLoading, setDropDownLoading] = useState(false)

  const [launchDetail, setLaunchDetail] = useState("");
  const [loader, setLoader] = useState([]);
  const [portalId, setPortalId] = useState("");
  const [showCkEditor, setShowCkEditor] = useState(false);
  const [isDropdownFetched, setIsDropdownFetched] = useState(false)
  const notificationInitialState = {
    class_id: "",
    subject: "",
    message: "",
    attachment: {},
    class_name: "",
  };
  const [notificationFields, setNotificationFields] = useState(
    notificationInitialState
  );
  const [editorState, setEditorState] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [statusData, setStatusData] = useState("");
  const [countStatusData, setCountStatusData] = useState([]);
  const [popUpState, setPopUpState] = useState({ cid: 0, intake: 0, act: "" })
  const [showMore, setShowMore] = useState(false);
  const [launchLoading, setLaunchLoading] = useState(false);
  const [isShowingAllUser, setIsShowingAllUser] = useState([]);
  const [refreshList, setRefreshList] = useState();
  const [debounceTerm, setDebounceTerm] = useState("");
  const [storedGlobal,setStoredGlobal] = useState({
    "learningMethod": {
        "arr": [],
        "checkObj": {}
    },
    "intake": {
        "arr": [],
        "checkObj": {}
    },
    "school": {
        "arr": [],
        "checkObj": {}
    },
    "lecturer": {
        "arr": [],
        "checkObj": []
    }
})
  // console.log(filterIntake,"kkkkk"); 
  // return 
  const classTypeOptions = [
    { label: "Campus", value: "on_campus" },
    { label: "Hybrid", value: "hybrid" },
    { label: "Virtual", value: "bbb_class" }
  ]

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debounceTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debounceTerm])

  useEffect(() => {
    setPage(1)

  }, [location]);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateUpcomingClasses"));
    if (storedFilters) {
      setIntake(storedFilters.intake);
      setType(storedFilters.type);
      setStatus(storedFilters.status);
      setLearningMethod(storedFilters.learningMethod);
      setLectureIds(storedFilters.lectureIds); 
      onChangeLecturer(storedFilters.valueLecturer)   
    }
  }, []);
  useEffect(() => {
    const filterState = {
      intake,
      type,
      status,
      learningMethod,
      lectureIds,
      valueLecturer
    };
    localStorage.setItem("filterStateUpcomingClasses", JSON.stringify(filterState));
  }, [ intake, type, status, learningMethod, lectureIds, valueLecturer]);

  useEffect(() => {


    return () => {
      resetFilter()
    }
  }, [date])

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();

    const fetchData = async () => {
      let data = {
        viaDate: date.length > 0 ? date : []
      }
      try {
        const res = await GetVritualClassDropdownCancelToken(data, dropdownSource.token);
        setVirtualDropdown({ course: [], intake: [], lecturer: [], status: [], learning: [] })
        let lecturerList = res.data.lectures;
        let localStorageValues = JSON.parse(localStorage.getItem("globalDashboardFilters"))

        
          let arrayMade = [];
          for(let d of localStorageValues.lecturer.arr){
            for(let data of lecturerList){
              if(d == data.value){
                arrayMade.push(data)
              }
            }
          }
          if(arrayMade.length){

            onChangeLecturer(arrayMade)
          }
        
        if (localStorageValues.lecturer.arr.length) {
          lecturerList = lecturerList.filter(element => localStorageValues.lecturer.arr.includes(element.value))
        }
        setVirtualDropdown({ ...res.data, course: res.data.course, intake: res.data.intakes, lecturer: lecturerList, status: res.data.statusType, learning: res.data.studyType })
        setDropDownLoading(false)
        setIsDropdownFetched(true)
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setDropDownLoading(false)
        }
      }
    };

    fetchData();

    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, [date, filterLecturer]);

  useEffect(() => {
    getAve()
      .then((res) => {
        setDuration(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    $(".drag-modal").draggable({
      handle: ".drag-handler"
    });
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getDashboard = async () => {


      setLoading(true);

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      const data = {
        pageNo: page,
        limit: perPage,
        search: search ? search : "",
        viaCourse: courseIds.length > 0 ? JSON.stringify(courseIds) : [],
        viaDate: date.length > 0 ? JSON.stringify(date) : [],
        intake: intake.arr && intake.arr.length > 0 ? JSON.stringify(intake.arr) : [],
        globalIntake : filterIntake?.newValue?.length > 0 ? JSON.stringify(filterIntake?.newValue) : [],
        globalSchool : filterSchool?.newValue?.length > 0 ? JSON.stringify(filterSchool?.newValue) : [],
        globalLearningMethod : filterLearningMethod?.newValue?.length > 0 ? JSON.stringify(filterLearningMethod?.newValue) : [],
        globalLecturer : filterLecturer?.newValue?.length > 0 ? JSON.stringify(filterLecturer?.newValue) : [],
        viaStatus: status.arr.length > 0 ? JSON.stringify(status.arr) : [],
        viaQualification:
          programmeIds.length > 0 ? JSON.stringify(programmeIds) : [],
        viaLecturer: lectureIds.length > 0 ? JSON.stringify(lectureIds) : [],
        key: sortkey,
        sort: sortOrder,
        viaClassType: type.arr.length ? JSON.stringify(type.arr) : [],
        showing: showing,
        viaStudyType: learningMethod.arr.length ? JSON.stringify(learningMethod.arr) : []
      };
      if(isDropdownFetched){

        try {
          let res = await GetListOfDashboardCancleToken(data, source.token);
          if (res.status == 200) {
          setDashBoardList(res.data?.result1);
          setTotalRows(res.data?.Pagination?.total);
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          setLoading(false);
          console.log(error);
        }
      }
    }
    };

    getDashboard();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [
    lecturer,
    course,
    searchCourseCheck,
    programme,
    searchProgrammeCheck,
    searchLecturerCheck,
    date,
    searchDateCheck,
    type,
    searchTypeCheck,
    intake,
    isDropdownFetched,
    searchIntakeCheck,
    intakeNum,
    searchIntakeNumCheck,
    filterIntake,
    filterLearningMethod,
    filterLecturer,
    filterSchool,
    search,
    page,
    perPage,
    status,
    searchStatusCheck,
    sortkey,
    sortOrder,
    lectureIds,
    courseIds,
    showing,
    learningMethod,
    refreshList
  ]);

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebounceTerm(value);
  };

  const handleModalSearchFilter = (e) => {
    const value = e.target.value;
    setModalSearch(value);
  };

  const handleShowingFilter = (e) => {
    const value = e.target.value;
    if (showing.includes(value)) {
      setShowing([]);
    } else {
      setShowing([value]);
    }

    let id = e.target.id;
    if (id == "action41") {
      setSearchShowing({ action41: !searchShowing.action41 });
    } else {
      setSearchShowing({
        [id]: !searchShowing[id],
        action41: false,
      });
    }
  };
  useEffect(
    () => {
      setStoredGlobal(JSON.parse(localStorage.getItem("globalDashboardFilters")))
    },[filterIntake,filterLearningMethod,filterLecturer,filterSchool]
  )
  useEffect(
    () => {
       if(storedGlobal?.intake?.arr.length != 0 && storedGlobal?.intake?.checkObj != undefined && storedGlobal?.intake.checkObj != null){
        var newObj = Object.keys(storedGlobal.intake?.checkObj).map(d => d.replace('globalIntakeintake','intake').concat(` ${storedGlobal.intake.checkObj[d]}`)).map(d => d.split(" "))
        var jjj = newObj.map(d => d[0])
        var iii = newObj.map(d => d[1])
        var newestObj = jjj.map((d,i) => ` ${d} : ${iii[i]}`)
        var finalObj = {}
        var key;
        var value;
        newestObj.forEach(item => {
          var parts = item.split(":")
          key = parts[0]
          value = parts[1]
          key = key.trim()
          value = value.trim()
          if(value == "true"){value = true}
          if(value == "false"){value = false}
          finalObj[key] = value
        })
        let arrayMade = [];
        for(let d of storedGlobal.intake.arr){
          for(let data of virtualDropdown.intake){
            if(d == data.value){
              arrayMade.push(d)
            }
          }
        }
        setIntake({...intake,arr : arrayMade,checkObj:finalObj})
      }
       else if(globalReset){
        var newObj = intake.checkObj
        for (let key in newObj) {
          newObj[key] = false;
        }
        setIntake({arr:[],checkObj: newObj})
      }
       
    },[storedGlobal,filterIntake,globalReset,virtualDropdown]
  )
  useEffect(
    () => {
       if(storedGlobal?.learningMethod?.arr.length != 0 && storedGlobal?.learningMethod?.checkObj != undefined && storedGlobal?.learningMethod.checkObj != null){
        var newObj = Object.keys(storedGlobal.learningMethod?.checkObj).map(d => d.replace('globalLearninglearning','learning').concat(` ${storedGlobal.learningMethod.checkObj[d]}`)).map(d => d.split(" "))
        var jjj = newObj.map(d => d[0])
        var iii = newObj.map(d => d[1])
        var newestObj = jjj.map((d,i) => ` ${d} : ${iii[i]}`)
        var finalObj = {}
        var key;
        var value;
        newestObj.forEach(item => {
          var parts = item.split(":")
          key = parts[0]
          value = parts[1]
          key = key.trim()
          value = value.trim()
          if(value == "true"){value = true}
          if(value == "false"){value = false}
          finalObj[key] = value
        })
        let arrayMade = [];
        for(let d of storedGlobal.learningMethod.arr){
          for(let data of virtualDropdown.learning){
            if(d == data.value){
              arrayMade.push(d)
            }
          }
        }
        setLearningMethod({...learningMethod,arr : arrayMade,checkObj:finalObj})
      }
       else if(globalReset){
        var newObj = learningMethod.checkObj
        for (let key in newObj) {
          newObj[key] = false;
        }
        setLearningMethod({arr:[],checkObj: newObj})
      }
       
    },[storedGlobal,filterLearningMethod,globalReset,virtualDropdown]
  )
  useEffect(
    () => {
       if(storedGlobal?.lecturer?.arr.length != 0){
        
      }
       else if(globalReset){
        // var newArr = valueLecturer
        // for (let key in newArr) {
        //   newObj[key] = false;
        // }
        onChangeLecturer([])
      }
       
    },[storedGlobal,filterLecturer,globalReset,virtualDropdown]
  )

  const resetModalFilter = () => {

    setModalSearch("")

  }

  const resetFilter = () => {
    setSearch("");
    setStatus({ arr: [], checkObj: {} });
    setSearchStatusCheck({});
    setStudyType([]);
    setSearchStudyTypeCheck({});
    setIntake({ arr: [], checkObj: {} });
    setSearchIntakeCheck({});
    setIntakeNum([]);
    setSearchIntakeNumCheck({});
    setCourse([]);
    setSearchCourseCheck({});
    setType({ arr: [], checkObj: {} })
    setSearchTypeCheck({});
    setSearchDateCheck({});
    setLecturer([]);
    setSearchLecturerCheck({});
    setProgramme([]);
    setProgrammeIds([]);
    setSearchProgrammeCheck({});
    setCourseIds([]);
    setLectureIds([]);
    setShowing([10]);
    setSearchShowing({});
    onChange([]);
    onChangeLecturer([]);
    onChangeIntake([]);
    setLearningMethod({ arr: [], checkObj: {} })
    setDebounceTerm("")
  };

  function launchButton(row) {
    setLaunchLoading(true)
    setPortalId(row.id);
    setStatusData(row.class_type);
    setLaunchDetail({});
    let formData = new FormData();
    formData.append("vclassID", row.id);
    formData.append("class_type", row.class_type);
    GetLuanchButtonData(formData)
      .then((data) => {
        const launchBtnData = data && data.data ? data.data : {};
        setLaunchDetail({ ...row, ...launchBtnData });
        const sendData = {
          server_id: row.server_id,
          bbb_class_id: row.big_blue_button_id
        }
        GetLearningDashboardURL(sendData).then((res) => {
          if (res.status === 200) {
            // console.log(res.data.URL);
            if (res.data.URL) {
              setAnalysisBtn({ disable: false, link: res.data.URL })
            }
          }
        }).catch((err) => { console.log(err) })
        setLaunchLoading(false)
      })
      .catch((error) => {
        console.log("error >>>>>>> ", error);
        setLaunchLoading(false)
      });
  }

  function closeLaunchPopup() {
    setAnalysisBtn({ disable: true, link: "" });
    $("#launch").modal("hide");
  }

  function launchVClass(id, loaderAt, isStart = true) {
    Swal.fire({
      title: "Loading",
      onOpen: function () {
        Swal.showLoading()
      }
    })
    if (launchDetail.class_type === "hybrid" && isStart === true) {
      setLoader([...loader, 1, 2]);
    } else if (launchDetail.class_type === "hybrid" && isStart === false) {
      setLoader([...loader, 3, 4]);
    } else {
      setLoader([loaderAt]);
    }
    let formData = new FormData();
    formData.append("portalID", id);
    // return;
    LaunchVirtualClass(formData, isStart)
      .then((data) => {
        setLoader([]);
        Swal.close()
        if (data.data?.message) {
          Swal.fire("Class Ended!", data.data?.message, "success");
          closeLaunchPopup();
          setRefreshList(!refreshList)
        }
        if (data.data?.data?.url) {
          window.open(data.data?.data?.url.toString(), "_blank");
          //  Swal.fire("Lunched!", data.data.data.message, "success");
          closeLaunchPopup();
          setRefreshList(!refreshList)
        } else {
          Swal.fire(isStart ? "Launched!" : "Ended!", data.data?.data?.message, "success").then(() => {
            closeLaunchPopup();
            setRefreshList(!refreshList)
          });
        }
      })
      .catch((error) => {
        setLoader([]);
        console.log(">>>>> ", error);
        Swal.close()
      });
  }

  function joinAsAdmin(joinData) {
    if (launchDetail.status !== "Live" && launchDetail.subStatus !== "Launched")
      return;
    const formData = new FormData();
    formData.append("class_id", launchDetail.id);
    formData.append(
      "learner_name",
      launchDetail.lerner ? launchDetail.lerner : ""
    );
    JoinClassAsAdmin(formData)
      .then((res) => {
        console.log("joinadminclass", res.data.joinMeetingURL);
        if (joinData == "copy") {
          Swal.fire({
            title: "Copied",
          }).then(() => {
            closeLaunchPopup();
            copyGuestUrl(res.data.joinMeetingURL);
          })

        }
        else {
          window.open(res.data.joinMeetingURL.toString(), "_blank");
          closeLaunchPopup();
        }
      })
      .catch((err) => {
        console.log("error while joining class as admin ---> ", err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data[0].message}`,
        });
      });
  }

  function cancelQuickNotification() {
    setNotificationFields({ ...notificationInitialState });
    setEditorState("");
    formik.resetForm();
    setShowCkEditor(false)
    setSelectedFile("");
  }

  const onChangeStatus = (id, recordingChecked) => {
    UpdateClassRecordStatus(id, recordingChecked == 1 ? false : true)
      .then((response) => {
        setRefreshList(recordingChecked == 1 ? false : true)
        // getDashboard();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const modalColumns = useMemo(() => [
    {
      name: "First Name",
      selector: "first_name",
      sortable: true,
      cell: (row) => (row.first_name ? row.first_name : "-"),
    },
    {
      name: "Last Name",
      selector: "last_name",
      sortable: true,
      cell: (row) => (row.last_name ? row.last_name : "-"),
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      cell: (row) => (row.email ? row.email : "-"),
    },
    {
      name: "Status",
      selector: "",
      sortable: true,
      cell: (row) => (
        <span
          className={
            modalStudentId.includes(row.student_id.toString())
              ? "cat cat-green"
              : "cat cat-red"
          }
        >
          {modalStudentId.includes(row.student_id.toString())
            ? "Online"
            : "Offline"}
        </span>
      ),
    },
  ], []);

  const handleShowingUser = (isShowing, id) => {
    let tempArr = isShowingAllUser;
    if (isShowing) {
      tempArr.splice(tempArr.indexOf(id), 1)
    } else {
      tempArr.push(id)
    }
    setIsShowingAllUser([...tempArr])
  }

  const columns = useMemo(() => [
    {
      name: "Title",
      selector: "class_title",
      sortField: "title",
      sortable: true,
      cell: (row) => (
        <>
          <span
            className="feature-name fist-name-box"
            title={row.class_title}
          >
            {/* {row.status == "Past" ?
              <Link
                to={{
                  pathname:
                    "/courseAdministration/coursesdetails/" +
                    row.intakeID +
                    "/onlineClasses/classReport/" +
                    row.class_id +
                    "/summary",
                }}
                target="_blank"
                className="as-text-blue curser"
              >
                <span className="textLimit100">{row.class_title ? row.class_title : "-"}</span>
              </Link> :
            } */}
            <span
              data-toggle="modal"
              data-target="#launch"
              className="as-text-blue curser">
              <span onClick={() => {
                launchButton(row);
                // setClassReportMeetingId(row.meeting_id);
                setOnlineClass({
                  class_id: row.class_id,
                  course_id: row.courseID,
                });
              }} className="textLimit100">{row.class_title ? row.class_title : "-"} </span>
            </span>
            {/* <span className="textLimit100">
              {row.class_id}
              {row.bbbname ? (" - " + row.bbbname) : ""}
            </span> */}
            {row.isRecurring == 1 && row.isRecurringDifferent == 1 ? (
              <div className="icon-w-text as-text-blue">
                <span className="icon-w">
                  <i className="fal fa-refresh not-refresh"></i>
                </span>
              </div>
            ) : row.isRecurring == 1 && (
              <div className="icon-w-text as-text-blue">
                <span className="icon-w">
                  <i className="fal fa-refresh"></i>
                </span>
              </div>
            )}
          </span>


        </>
      ),
    },
    {
      name: "Date",
      selector: "class_date",
      sortField: "date",
      sortable: true,
      cell: (row) => {
        return (
          <span className="feature-name">
            <p className="right-space">
              {moment(row.class_date, "YYYY-MM-DD").format(TABLE_DATE_FORMAT)}
            </p>
            <span className="textLimit100">
              {moment(row.class_from, "h:mm A").format(TABLE_TIME_FORMAT)} -{" "}
              {moment(row.class_to, "h:mm A").format(TABLE_TIME_FORMAT)}
            </span>
          </span>
        );
      },
    },
    {
      name: "Course",
      selector: "courseName",
      sortField: "coures",
      sortable: true,
      cell: (row) => (
        <Link
          title={row.courseName}
          className="as-text-blue curser feature-name"
          to={`/courseAdministration/coursesdetails/${row.intakeID}/dashboard/show`}
        >
          <span className="textLimit100">{row.courseName ? row.courseName : "-"}</span>
        </Link>
      ),
    },
    {
      name: "Intake",
      selector: "intakeYear",
      sortable: true,
      sortField: "Intake",
      cell: (row) => (
        <p>
          {row.intakeNumber || row.intakeYear ? (
            <>
              {" "}
              {row.intakeYear ? row.intakeYear : ""}-{row.intakeNumber ? row["intakeNumber"] : ""}
            </>
          ) : (
            "-"
          )}
        </p>
      ),
    },

    {
      name: "Delivery",
      selector: "class_type",
      sortField: "type",
      sortable: true,
      cell: (row) => <OnlineClassType row={row} />
    },

    {
      name: "Status",
      selector: "status",
      sortable: false,
      cell: (row) =>
        row.status ? (
          <div>
            {RenderClassStatus(row.status).html}
            {row.status === "Live" && row.subStatus === "Launched" ? <>
              {row.isVirtual ? <span className="p-1"><i className="fal fa-house-signal"></i></span> : null}
              {row.isCampus ? <span className="p-1"><i className="fal fa-university"></i></span> : null}
            </> : null}
            {row.subStatus && (
              RenderClassStatus(row.subStatus).html
            )}
            {row.status == "Live" && row.subStatus == "Launched" ? (
              <>
                <p className="textLimit100">{row.nested_started_at ? " at " + moment.unix(row.nested_started_at).format(TABLE_TIME_FORMAT) : ""}
                  <br />
                  {row.nestedStarted_by ? " by " + row.nestedStarted_by : ""}
                </p>
              </>
            ) : (
              ""
            )}

            {(row.status == "Past" || row.status == "Live") &&
              row.subStatus == "Ended" &&
              (row.ended_by == row.lect_id || row.ended_by >= 0 ? (
                <p className="textLimit100">
                  {row.nested_ended_at ? ` at ${moment.unix(row.nested_ended_at).format(TABLE_TIME_FORMAT)}` : ""}
                  <br />
                  <span className="one-line-truncate" title={row.nestedEnded_by
                    ? ` by ${row.nestedEnded_by}`
                    : ` by ${row.lecture_name}`}>{row.nestedEnded_by
                      ? ` by ${row.nestedEnded_by}`
                      : ` by ${row.lecture_name}`}</span>
                </p>
              ) : (
                ""
              ))}
          </div>
        ) : (
          "NA"
        ),
    },

    {
      name: "Lecturer",
      selector: "lecture_name",
      sortable: true,
      sortField: "lecture",
      cell: (row) => {
        let lecImage = row.lecturer && row.lecturer.picture_me;

        return (
          <>
            {row.lecture_name ? (
              <div className="assigned-title-block-new">
                <div className="assigned-title-itm">
                  <span
                    className={
                      "assigned-title-blk  name-icon cat-dark-red " +
                      ColorRender("lecturer")
                    }
                  >
                    {row.lecturer?.picture_me ? (
                      <>
                        <img
                          src={`${IMAGE_URL}/${lecImage.replaceAll(
                            "/home/myaie/public_html/",
                            ""
                          )}`}
                          alt="AIE"
                        />
                      </>
                    ) : (
                      InitialRender([
                        row.lecture_name.split(" ")[0],
                        row.lecture_name.replace(/ +/g, " ").split(" ")[1],
                      ])
                    )}
                    <span className={`profile-box-2-status ${row.lecturer.Status ? row.lecturer.Status.toLowerCase() == "online" ? "Online" : row.lecturer.Status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                      <i className="fas fa-circle"></i>
                    </span>
                    <Hover
                      firstName={row.lecture_name.split(" ")[0]}
                      lastName={
                        row.lecture_name.replace(/ +/g, " ").split(" ")[1]
                      }
                      photo={row.lecturer?.picture_me}
                      email={row.lecturer?.Email}
                      mobile={row.lecturer?.Mobile}
                      right={true}
                      role={"lecturer"}
                      status={row.lecturer?.is_active}
                      activity_status={row.lecturer?.Status}
                    />
                  </span>
                  <p
                    className="as-text-blue curser "
                    onClick={() =>
                      history.push(
                        `/systemAdministration/userManagement/open/${row.lect_id}/aboutYou`
                      )
                    }
                  >
                    {row.lecture_name ? (
                      <span className="textLimit76" title={row.lecture_name}>
                        {row["lecture_name"]}
                      </span>
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
              </div>
            ) : (
              "-"
            )}

          </>
        );
      },
    },
    {
      name: "Guest Lecturer",
      cell: (row) => {
        return isShowingAllUser.includes(row.id) ? <div>
          <div className="assigned-profile-tit">
            {row.multiple_lecture.length ? row.multiple_lecture.map((data, index) => <UserColumn
              firstName={data?.get_lecture_details?.Firstname}
              lastName={data?.get_lecture_details?.Lastname}
              imgUrl={data?.get_lecture_details?.picture_me}
              email={data?.get_lecture_details?.Email}
              mobile={data?.get_lecture_details?.Mobile}
              userId={data?.lecturer_id}
              userActiveStatus={data?.get_lecture_details?.Status}
              role="lecturer"
              key={index}
              isUserNameHidden={!(isShowingAllUser.includes(data?.class_id) || row.multiple_lecture.length == 1)}
              isPopUpRight={true}
            />) : "-"}</div>
          {row.multiple_lecture.length && row.multiple_lecture.length > 1 ? <div
            className="table-expand-btn"
            onClick={() => handleShowingUser(true, row.id)}>
            See Less
          </div> : ""}
        </div> : <div className="assigned-profile-overlay-out">
          <div className="assigned-profile-overlay">
            {row.multiple_lecture.length ? row.multiple_lecture.map((data, index) => {
              return index < 3 ? <UserColumn
                firstName={data?.get_lecture_details?.Firstname}
                lastName={data?.get_lecture_details?.Lastname}
                imgUrl={data?.get_lecture_details?.picture_me}
                email={data?.get_lecture_details?.Email}
                mobile={data?.get_lecture_details?.Mobile}
                userId={data?.lecturer_id}
                userActiveStatus={data?.get_lecture_details?.Status}
                role="lecturer"
                key={index}
                isUserNameHidden={!(isShowingAllUser.includes(data?.class_id) || row.multiple_lecture.length == 1)}
                isPopUpRight={true}
              /> : ""
            }) : "-"}</div>
          {row.multiple_lecture.length && row.multiple_lecture.length > 1 ? <div
            className="table-expand-btn"
            onClick={() => handleShowingUser(false, row.id)}>
            See More
          </div> : ""}
        </div>
      },
    },
    {
      name: "Recording",
      selector: "",
      sortable: false,
      cell: (row) => {
        return row.class_type == "on_campus" ? (
          ""
        ) : (
          <div className="assessment-08">
            {/* <div className="mb-2 text-centre">
              <PermissionsGate
                scopes={["cedit"]}
                errorProps={{ disabled: true }}
              >
                {row.class_type == "on_campus" ? <></> : row.subStatus ==
                  "Ended" ? (
                  <> {"Class Recorded"}
                    <button
                      disabled
                      title={
                        row.isRecorded && row.isRecordingAvailable ? "Yes" : "No"
                      }
                      className={"btn rounded-circle btn-status-1 ml-1 " +
                        (row.isRecorded == 1
                          ? "btn-success btn-yes"
                          : "btn-danger online-recording btn-no")
                      }
                    >
                      {row.isRecorded == 1 ? (
                        <>
                          <i className="fal fa-check"></i> Yes
                        </>
                      ) : (
                        <>
                          <i className="fal fa-times"></i> No
                        </>
                      )}
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </PermissionsGate>
            </div> */}
            {row.status == "Live" && row.subStatus == "Launched" ?
              <> Recording Started
                <button
                  disabled={
                    row.status == "Live" && row.subStatus == "Launched"
                      ? false
                      : true
                  }
                  title={row.isRecordingChecked ? "Yes" : "No"}
                  className={"btn rounded-circle btn-status-1 ml-1 " +
                    (row.isRecordingChecked == 1
                      ? "btn-success btn-yes "
                      : "btn-danger btn-no ")
                  }
                  onClick={() => {
                    onChangeStatus(
                      row.big_blue_button_id,
                      row.isRecordingChecked
                    );
                  }}
                >
                  {row.isRecordingChecked == 1 ? (
                    <>
                      {" "}
                      <i className="fal fa-check"></i> Yes{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <i className="fal fa-times"></i> No
                    </>
                  )}
                </button>
              </>
              : (row.isRecorded == 1 && row.viewRecording.link) ? (
                <>
                  <a
                    href={row.viewRecording.link}
                    target="_blank"
                    className="dashboard-view-button"
                    title="View Recording"
                  >
                    {" "}
                    <i className="fal fa-eye"></i> <span className="as-text-blue curser "> View </span>
                  </a>
                  &nbsp;
                  <CopyToClipboard data={`
                      <strong>Class name: </strong> ${row.class_title}
                      <br />
                      <strong> Class date and time: </strong>
                      ${moment(row.class_date, "YYYY-MM-DD").format(TABLE_DATE_FORMAT)} ${moment(row.class_from, "h:mm A").format(TABLE_TIME_FORMAT)} - ${moment(row.class_to, "h:mm A").format(TABLE_TIME_FORMAT)}
                      <br />
                      <strong> Lecturer: </strong> ${row.lecture_name} <br />
                      <br />
                      <a href="${row.viewRecording.link}" target="_blank" className="dashboard-view-button" title="View Recording"> View </a>
                      <br />
                      <a href="${row.recording.link}" target="_blank" className="dashboard-view-button" title="Download Recording"> Download </a>`}
                  />
                </>
              ) : (
                <>
                  {row.recording.status === "Processing" ?
                    <>
                      <i className="fal fa-eye"></i>
                      <span className="pl-1">Processing</span>
                    </> : <><i className="fal fa-times" />&nbsp;Not Recorded</>}
                </>
              )}
            <br />
            {(row.isRecorded === 1 && row.recording.link) ? (
              <>
                <a
                  // disabled={row.isRecorded == 0}
                  href={row.recording.link}
                  target="_blank"
                  className="dashboard-view-button"
                  title="Download Recording"
                >
                  <i className="fal fa-download"></i> <span className="as-text-blue curser "> Download </span>
                </a>{" "}
                &nbsp;
              </>
            ) : (
              <>
                {row.recording.status === "Processing" ?
                  <>
                    <i className="fal fa-download"></i>
                    <span className="pl-1">Processing</span>
                  </> : <></>}
              </>
            )}
          </div>
        );
      },
    },
    date &&
    date != "Upcoming" && {
      name: "Insights",
      selector: "",
      minWidth: "350px",
      sortable: false,
      cell: (row) => {
        let downloadrate =
          (row.arrAttendance?.getattend / row.arrAttendance?.total) * 100;

        return (
          <div className="popup-item poup-item-box">
            <div className="icon-area-main">
              <div className="icon-area">
                <div
                // className="icon-area-row "
                >
                  <div className="icon-w-text">
                    <span className="icon-w">
                      <i
                        className="fal fa-users"
                        title="Student attendance"
                      ></i>
                    </span>
                    {row.arrAttendance ? (
                      <>
                        {" "}
                        {row.arrAttendance?.getattend} /{" "}
                        {row.arrAttendance?.total} (
                        {downloadrate ? downloadrate.toFixed(2) : 0}%)
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="icon-w-text">
                    <span className="icon-w">
                      <i
                        className="fal fa-university"
                        title="Campus attendance"
                      ></i>
                    </span>
                    {row.arrAttendanceCampus ? (
                      <>
                        {" "}
                        {row.arrAttendanceCampus?.getattend} /{" "}
                        {row.arrAttendanceCampus?.total} (
                        {row.arrAttendanceCampus?.attendpercentage > 0
                          ? row.arrAttendanceCampus?.attendpercentage.toFixed(
                            2
                          )
                          : 0}
                        %)
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="icon-w-text">
                    <span className="icon-w">
                      <i
                        className="fal fa-house-signal"
                        title="Remote attendance"
                      ></i>
                    </span>
                    {row.arrAttendanceOnline ? (
                      <>
                        {" "}
                        {row.arrAttendanceOnline?.getattend} /{" "}
                        {row.arrAttendanceOnline?.total} (
                        {row.arrAttendanceOnline?.attendpercentage > 0
                          ? row.arrAttendanceOnline?.attendpercentage.toFixed(
                            2
                          )
                          : 0}
                        %)
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="icon-w-text">
                    {row.ratingArr.avg_rating > 0
                      ? startShow(row.ratingArr.avg_rating)
                      : ""}
                    {row.ratingArr.avg_rating && row.ratingArr.avg_rating > 0
                      ? `${row.ratingArr.total_reviewed_user}/${row.ratingArr.total_user}`
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            {/* {row.status !== "Past" ?  */}
            <PermissionsGate
              scopes={["cocview"]}
              errorProps={{ disabled: true }}
            >
              <button
                className="btn btn-primary rounded-circle"
                title="Launch"
                data-toggle="modal"
                data-target="#launch"
                onClick={() => {
                  launchButton(row);
                  // setClassReportMeetingId(row.meeting_id);
                  setOnlineClass({
                    class_id: row.class_id,
                    course_id: row.courseID,
                  });
                }}
              >
                <i className="fal fa-rocket"></i>
              </button>
            </PermissionsGate>

            <div className="dropdown btn-dropdown-item">
              <button
                className="btn btn-primary rounded-circle dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {/* DO NOT DELETE BELOW COMMENTED BLOCK */}
                {/* <PermissionsGate
                  scopes={["cocadd"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-primary rounded-circle"
                    title="Quick Notification"
                    data-toggle="modal"
                    onClick={() => {
                      setShowCkEditor(true);
                      setSelectedFile("");
                      setEditorState("")

                      $("#notifications").modal("show");
                      // setNotificationFields([...notificationFields, { class_id: row.class_id, class_name: row.class_title }])
                      setNotificationFields((prevState) => {
                        return {
                          ...prevState,
                          class_id: row.id,
                          class_name: row.class_title,
                        };
                      });
                    }}
                  >
                    <i className="fal fa-bell"></i>
                  </button>
                </PermissionsGate> */}
                {row.status == "Past" && <PermissionsGate
                  scopes={["cocview"]}
                  errorProps={{ disabled: true }}
                >
                  {/* <Link
                    target="_blank"
                    to={`/classReport/summary/${row.intakeID}/${row.class_id}`}
                  // href={"/courseAdministration/coursesdetails/" + row.intakeID + "/onlineClasses/classReport/" + row.class_id + "/summary"}
                  > */}
                  <button
                    className="btn btn-primary rounded-circle"
                    title="Class Report"
                    onClick={() => history.push(`/classReport/summary/${row.intakeID}/${row.class_id}`)}
                  >
                    {/* onClick={() => { redirectToExternalLink("https://www.myaie.ac/cadcocms/participant_nonParticipant_student_list.php?class_id=" + row.c_id) }} */}
                    <i className="fal fa-users"></i>
                  </button>
                  {/* </Link> */}
                </PermissionsGate>}



                {row.status !== "Live" && ((row.launch_disable != 1 && row.status !== "Past") && (
                  (row.isRecurring == "0" || (row.isRecurring == 1 && row.isRecurringDifferent == 1)) ?
                    <PermissionsGate
                      scopes={["cocedit"]}
                      RenderError={() =>
                        <button
                          disabled
                          className="btn btn-primary rounded-circle"
                          title="Open"
                        >
                          <i className="fal fa-folder-open"></i>
                        </button>}
                    >
                      <Link
                        to={{ pathname: "/courseAdministration/coursesdetails/" + row.intakeID + "/onlineClasses/" + row.id + "/open/" + row.isRecurring }}
                        className="btn btn-primary rounded-circle"
                        title="Open"
                      >
                        <i className="fal fa-folder-open"></i>
                      </Link>
                    </PermissionsGate> :
                    <PermissionsGate
                      scopes={["cocedit"]}
                      errorProps={{ disabled: true }}
                    >
                      <button
                        className="btn btn-primary rounded-circle"
                        title="Open"
                        data-toggle="modal"
                        data-target="#editModal"
                        onClick={() => {
                          setRecurringDetails({ recurring: row.isRecurring, id: row.id, intakeId: row.intakeID });
                          setClassId(row.id)
                        }}
                      >
                        <i className="fal fa-folder-open"></i>
                      </button>
                    </PermissionsGate>
                ))}
                {row.status != "Past" &&
                  <PermissionsGate
                    scopes={["cocview"]}
                    errorProps={{ disabled: true }}
                  >
                    {/* <Link
                      target="_blank"
                      to={`/classReport/summary/${row.intakeID}/${row.class_id}`}
                    // href={"/courseAdministration/coursesdetails/" + row.intakeID + "/onlineClasses/classReport/" + row.class_id + "/summary"}
                    > */}
                    <button
                      className="btn btn-primary rounded-circle"
                      title="Class Report"
                      onClick={() => history.push(`/classReport/summary/${row.intakeID}/${row.class_id}`)}
                    >
                      {/* onClick={() => { redirectToExternalLink("https://www.myaie.ac/cadcocms/participant_nonParticipant_student_list.php?class_id=" + row.c_id) }} */}
                      <i className="fal fa-users"></i>
                    </button>
                    {/* </Link> */}
                  </PermissionsGate>}

                {/* <PermissionsGate
                  scopes={["cocview"]}
                  errorProps={{ disabled: true }}
                >
                  <Link
                    to={{
                      pathname:
                        ROOT_URL_AWS +
                        "playback/playback.html?meetingId=" +
                        row.big_blue_button_id,
                    }}
                    target="_blank"
                  >
                    <button
                      className="btn btn-primary rounded-circle"
                      title="View Recording"
                    >
                      <i className="fal fa-eye"></i>
                    </button>
                  </Link>
                </PermissionsGate>
                <PermissionsGate
                  scopes={["cocview"]}
                  RenderError={() => (
                    <button
                      disabled
                      className="btn btn-primary rounded-circle"
                      title="Download Recording"
                    >
                      <i className="fal fa-arrow-down"></i>
                    </button>
                  )}
                >
                  <a
                    href={
                      ROOT_URL_AWS +
                      "presentation_video/" +
                      row.big_blue_button_id +
                      "/video.mp4"
                    }
                    target="_blank"
                    download
                    className="btn btn-primary rounded-circle"
                    title="Download Recording"
                  >
                    <button
                      className="btn btn-primary rounded-circle"
                      title="Download Recording"
                    >
                      <i className="fal fa-arrow-down"></i>
                    </button>
                  </a>
                </PermissionsGate> */}
                <PermissionsGate
                  scopes={["cocadd"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-primary rounded-circle"
                    title="Class Recording"
                    data-toggle="modal"
                    data-target="#classrecording"
                    onClick={() => {
                      setClassId(row.id);
                    }}
                  >
                    <i className="fal fa-video"></i>
                  </button>
                </PermissionsGate>
                <PermissionsGate
                  scopes={["cocdelete"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Unlink class"
                    onClick={() => handleDelete(row, "unlink")}
                  >
                    <i className="fal fa-unlink"></i>
                  </button>
                </PermissionsGate>
                <PermissionsGate
                  scopes={["cocdelete"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Cancel Class"
                    onClick={() => handleDelete(row, "cancel")}
                  >
                    <i className="fal fa-times"></i>
                  </button>
                </PermissionsGate>
                <PermissionsGate
                  scopes={["cocdelete"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Delete"
                    onClick={() => handleDelete(row, "remove")}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ], [isShowingAllUser]);

  function startShow(rating) {
    // console.log("rating", rating);

    // //let arr = rating.split("");
    var arrCount = [];
    for (var i = 0; i < rating; i++) {
      arrCount.push(i);
    }
    let tempValues = arrCount.map((val) => {
      return (
        <span className="icon-w">
          <i
            className="fa fa-star"
            style={{ color: "#FFD700" }}
            title="Rating"
          ></i>
        </span>
      );
    });
    return tempValues;
    // console.log("arrCount", arrCount);
    //return arrCount;


  }

  const handleDelete = (row, type = "") => {
    let msgType = "";
    let msg = "";
    let btnText = "";
    if (type == "remove") {
      btnText = "Yes, delete it!"
      msgType = "Deleted!";
      msg = "Class deleted successfully.";
    } else if (type == "cancel") {
      btnText = "Yes, cancel it!"
      msgType = "Canceled!";
      msg = "Class cancelled successfully.";
    } else if (type == "unlink") {
      btnText = "Yes, unlink it!"
      msgType = "Unlinked!";
      msg = "Class unlinked successfully.";
    }
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: btnText,
    }).then((result) => {
      if (result.isConfirmed) {
        // API Call
        // key : cid(class id)
        // act ="remove" (string)
        // intake:1514
        setPopUpState({ cid: row.id, intake: row.intakeID, act: type })
        console.log("oionimk", type, row)
        if (type == "remove" && row.isRecurring == 1 && row.isRecurringDifferent != 1) {
          $("#deleteClass").modal("show");
          return
        } else if (type == "cancel" && row.isRecurring == 1 && row.isRecurringDifferent != 1) {
          $("#cancelClass").modal("show");
          return
        } else if (type == "unlink" && row.isRecurring == 1 && row.isRecurringDifferent != 1) {
          $("#unlinkClass").modal("show");
          return
        }
        let formData = new FormData();
        formData.append("cid", row.id);
        formData.append("intake", row.intakeID);
        formData.append("act", type);

        DeleteOnlineClass(formData)
          .then((data) => {
            setRefreshList(!refreshList)

            Swal.fire(msgType, msg, "success");
          })
          .catch((error) => {
            console.log("error ===> ", error);
          });
      }
    });
  };

  const downloadRate = (count, total) => {
    let donwrate = `${count}/${total}`;
    return donwrate;
  };

  const dataToModal = () => {

    let updatedData = [];
    let allData = modalStudentList;
    if (modalSearch.length) {

      let tempFirstname = allData.filter((item) => {
        let includes =
          item.first_name &&
          item.first_name.toLowerCase().includes(modalSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempLastname = allData.filter((item) => {
        let includes =
          item.last_name &&
          item.last_name.toLowerCase().includes(modalSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempEmail = allData.filter((item) => {
        let includes =
          item.email &&
          item.email.toString().toLowerCase().includes(modalSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [

        ...tempFirstname,
        ...tempLastname,
        ...tempEmail,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }





    return updatedData;
  };

  const exportData = (fileType, fileName) => {
    let data;
    let header = [
      "Title",
      "Date",
      "Course",
      "Intake",
      "Delivery",
      "Status",
      "Lecturer",
      "Recording",
      "Student attendance",
      "Campus Attendance",
      "Remote attendance",
      "View rate",
      "Download rate",
    ];
    let arr1 = [
      "Student attendance",
      "Campus Attendance",
      "Remote attendance",
      "View rate",
      "Download rate",
    ];

    if (date.includes("Upcoming")) {
      let newHeader = header.filter((val) => {
        return !arr1.includes(val)
      })
      header = newHeader;

    }


    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    GetListOfDashboard({
      pageNo: page,
      limit: perPage,
      search: search ? search : "",
      viaCourse: courseIds.length > 0 ? JSON.stringify(courseIds) : [],
      viaDate: date.length > 0 ? JSON.stringify(date) : [],
      intake: intake.arr && intake.arr.length > 0 ? JSON.stringify(intake.arr) : [],
      viaStatus: status.arr.length > 0 ? JSON.stringify(status.arr) : [],
      globalIntake : filterIntake?.newValue?.length > 0 ? JSON.stringify(filterIntake?.newValue) : [],
        globalLearningMethod : filterLearningMethod?.newValue?.length > 0 ? JSON.stringify(filterLearningMethod?.newValue) : [],
        globalLecturer : filterLecturer?.newValue?.length > 0 ? JSON.stringify(filterLecturer?.newValue) : [],
        globalSchool: filterSchool?.newValue?.length > 0 ? JSON.stringify(filterSchool?.newValue) : [],
      viaQualification:
        programmeIds.length > 0 ? JSON.stringify(programmeIds) : [],
      viaLecturer: lectureIds.length > 0 ? JSON.stringify(lectureIds) : [],
      key: sortkey,
      sort: sortOrder,
      viaClassType: type.arr.length ? JSON.stringify(type.arr) : [],
      showing: showing,
      viaStudyType: learningMethod.arr ? JSON.stringify(learningMethod.arr) : [],
      exportStaus: "true",
    }).then((res) => {
      data = res.data.result1;
      data = data.map((row) => ({
        Title: row.class_title ? row.class_title : "-",
        Date: `${moment(row.class_date).format(TABLE_DATE_FORMAT)} ${moment(
          row.class_start_dt
        ).format(TABLE_TIME_FORMAT)} - ${moment(row.class_end_dt).format(TABLE_TIME_FORMAT)}`,
        Course: row.courseName ? row.courseName : "-",

        Intake: `${row.intakeYear ? row.intakeYear : ""}-${row.intakeNumber ? row["intakeNumber"] : "-"
          }`,
        Delivery:
          row.class_type === "bbb_class" ||
            row.class_type == "braincert_class" ||
            row.class_type == "joinme_class"
            ? "Virtual"
            : row.class_type === "on-campus"
              ? "On Campus"
              : "Hybrid",
        Status: row.status ? RenderClassStatus(row.status).text : "-",
        Lecturer: row.lecture_name
          ? `${row.lecture_name}${row.multiple_lecture?.length ? "," : ""}${row.multiple_lecture?.map(
            (data) =>
            (data.get_lecture_details.Firstname +
              " " +
              data.get_lecture_details.Lastname + "")
          )}`
          : "-",
        Recording: row.downloadapilink == 1 ? "Yes" : "No",
        "Student attendance": `${row.arrAttendance
          ? `${row.arrAttendance?.getattend} / ${row.arrAttendance?.total
          } (${downloadRate(
            row.arrAttendance?.getattend,
            row.arrAttendance?.total
          )})`
          : "-"
          }`,
        "Campus Attendance": `${row.arrAttendanceCampus
          ? `${row.arrAttendanceCampus?.getattend} / ${row.arrAttendanceCampus?.total
          } (${row.arrAttendanceCampus?.getattend
            ? row.arrAttendanceCampus?.total
            : 0
          }%)`
          : "-"
          }`,
        "Remote attendance": `${row.arrAttendanceOnline
          ? `${row.arrAttendanceOnline?.getattend} / ${row.arrAttendanceOnline?.total
          } (${row.arrAttendanceOnline?.getattend
            ? row.arrAttendanceOnline?.total
            : 0
          }%)`
          : "-"
          }`,
        "View rate": `${row.arrViewRate?.count_view} / ${row.arrViewRate?.total}`,
        "Download rate": `${downloadRate(
          row.arrDownloadRate?.count_Download,
          row.arrDownloadRate?.total
        )}`,
      }));

      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });

        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();

        setTimeout(() => {
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.forEach((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [
            row["Title"],
            row["Date"],
            row["Course"],

            row["Intake"],
            row["Delivery"],
            row["Status"],
            row["Lecturer"],
            row["Recording"],
            row["Student attendance"],
            row["Campus Attendance"],
            row["Remote attendance"],
            row["View rate"],
            row["Download rate"],
          ];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,

          columnStyles: {
            0: {
              columnWidth: 10,
            },
            1: {
              columnWidth: 20,
            },
            2: {
              columnWidth: 15,
            },
            3: {
              columnWidth: 15,
            },
            4: {
              columnWidth: 10,
            },
            5: {
              columnWidth: 10,
            },
            6: {
              columnWidth: 20,
            },
            7: {
              columnWidth: 10,
            },
            8: {
              columnWidth: 10,
            },
            9: {
              columnWidth: 15,
            },
            10: {
              columnWidth: 15,
            },
            11: {
              columnWidth: 15,
            },
            12: {
              columnWidth: 10,
            },
            13: {
              columnWidth: 10,
            },
          },
          styles: {
            minCellHeight: 10,
            minCellWidth: 10,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      class_name: notificationFields.class_name,
      subject: "",
      attachment: "",
      content: "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Subject is required"),
      attachment: Yup.string().required("Attachment is required").trim(),

      content: Yup.string()
        .test("required", "Content is required", (value) => {
          if ((formatQuestion(editorState).replaceAll("&nbsp;", " ")).trim() == "" && !editorState.includes("<img")) {
            return false
          }
          if (!editorState) {
            return false
          }
          return true;
        })
        .trim()
    }),
    onSubmit: (values, { resetForm }) => {
      setDisabled(true);

      let formData = new FormData();
      formData.append("subject", values.subject);
      formData.append("message", editorState);
      formData.append("attachment", values.attachment);
      formData.append("class_id", notificationFields.class_id);
      formData.append("isEmail", 1);
      formData.append("isPushnotificationActive", 1);

      SendQuickNotification(formData)
        .then((data) => {
          if (data.status == 200) {
            setDisabled(false);
            setNotificationFields({
              class_id: "",
              subject: "",
              message: "",
              attachment: {},
              class_name: "",
            });
            $("#notifications").modal("hide");
            formik.resetForm();
            setEditorState("");
            setShowCkEditor(false);
            setSelectedFile("");
            Swal.fire("Notification!", "Successfully notified.", "success");
            setRefreshList(!refreshList)
          }
        })
        .catch((error) => {
          console.log("error ---> ", error);
        });
    },
  });

  async function closeModal() {
    // setSelectedCourses([]);
    $("#classrecording").modal("hide");
  }

  const copyGuestUrl = (url) => {
    navigator.clipboard.writeText(url);
  };

  const launchCampusClass = (action) => {
    Swal.fire({
      title: "Loading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    let launchData = new FormData();
    launchData.append("portal_id", portalId);
    launchData.append("action", action);
    classLaunch(launchData)
      .then((res) => {
        Swal.close()
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated successfully",
        });
        $("#launch").modal("hide");

        setRefreshList(!refreshList)
      })
      .catch((err) => {
        console.log("launch error", err.response.data.message);
        Swal.close()
        Swal.fire({
          text: err.response.data.message,
          icon: "warning",
        });
      });
  };


  return (
    <>
      <div
        id="online_classes_list"
        className={`custom-table-div filter-search-icon card-table-custom new-table-box`}
      >
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={debounceTerm}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    {/*showing*/}
                    {date != "Today" && (
                      <div className="assessment-table-filter">
                        <div className="btn-type-filter dropdown-comman">
                          <div className="dropdown multiselect">
                            <button
                              className={`btn btn-default dropdown-toggle ${showing.length ? "btn-selected" : ""
                                }`}
                              type="button"
                              id="dropdownMenu5"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              title="Showing"
                            >
                              <span>
                                Showing <i className="fal fa-angle-down"></i>
                              </span>
                            </button>
                            <form className="dropdown-menu remove-list-style">
                              <ul aria-labelledby="dropdownMenu5">
                                <li className="">
                                  <input
                                    type="checkbox"
                                    id="action41"
                                    value="10"
                                    onClick={handleShowingFilter}
                                    checked={searchShowing["action41"]}
                                  />
                                  <label htmlFor="action41">
                                    <span className="cat ">10 days</span>
                                  </label>
                                </li>
                                <li className="">
                                  <input
                                    type="checkbox"
                                    id="action42"
                                    value="20"
                                    onClick={handleShowingFilter}
                                    checked={searchShowing["action42"]}
                                  />
                                  <label htmlFor="action42">
                                    <span className="cat ">20 days</span>
                                  </label>
                                </li>
                                <li className="">
                                  <input
                                    type="checkbox"
                                    id="action43"
                                    value="30"
                                    onClick={handleShowingFilter}
                                    checked={searchShowing["action43"]}
                                  />
                                  <label htmlFor="action43">
                                    <span className="cat ">30 days</span>
                                  </label>
                                </li>
                                <li className="">
                                  <input
                                    type="checkbox"
                                    id="action44"
                                    value="-1"
                                    onClick={handleShowingFilter}
                                    checked={searchShowing["action44"]}
                                  />
                                  <label htmlFor="action44">
                                    <span className="cat ">All</span>
                                  </label>
                                </li>
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* showing end*/}
                    {/* Course filter */}
                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman assesment-dropdown">
                        <div className="dropdown multiselect">
                          <button
                            className={`btn btn-default dropdown-toggle ${value?.length ? "btn-selected" : ""
                              } `}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            title="Course"
                          >
                            <span>Course <i className="fal fa-angle-down"></i></span>
                          </button>
                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">
                              <div className="dropdown-filter-pagination">
                                {dropDownLoading ? <i className="fas fa-cog fa-spin"></i> : virtualDropdown.course && virtualDropdown.course.length ?
                                  <FilterComponent
                                    value={value}
                                    onChange={onChange}
                                    dataResponse={virtualDropdown.course}
                                    backendResponse={setCourseIds}
                                  />
                                  : <i className="fas fa-cog fa-spin"></i>
                                }
                              </div>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* End of Course filter */}
                    {/* Intake filter */}
                    {/* <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman assesment-dropdown">
                        <div className="dropdown multiselect">
                          <button
                            className={`btn btn-default dropdown-toggle ${valueIntake?.length ? "btn-selected" : ""
                              } `}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            title="Intake"
                          >
                            <span>Intake <i className="fal fa-angle-down"></i></span>
                          </button>
                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">
                              <div className="dropdown-filter-pagination">
                              {dropDownLoading ? (
                                (<i className="fas fa-cog fa-spin"></i>)
                                ):(virtualDropdown && virtualDropdown.intake && virtualDropdown.intake.length? (
                                  <FilterComponent
                                    value={valueIntake}
                                    onChange={onChangeIntake}
                                    dataResponse={virtualDropdown.intake}
                                    backendResponse={setIntake}
                                  />
                                ) : (<i className="fas fa-cog fa-spin"></i>)
                                )}
                              </div>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div> */}
                    {/* End of Intake filter */}

                    <Tablefilter
                      filterName={"Intake"}
                      optionArr={storedGlobal.intake?.arr.length ? virtualDropdown.intake.filter(element => storedGlobal.intake.arr.includes(element.value)) : virtualDropdown.intake }
                      state={intake}
                      setState={setIntake}
                      isOptionReversed={true}
                      isIntake={true}
                    />
                    <Tablefilter
                      filterName="Type"
                      optionArr={classTypeOptions}
                      state={type}
                      setState={setType}
                      renderLabelFunction={RenderClassType}
                    />
                    <Tablefilter
                      filterName="Status"
                      optionArr={virtualDropdown.status}
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={RenderClassStatus}
                    />
                    <Tablefilter
                      filterName="Learning Method"
                      optionArr={storedGlobal.learningMethod?.arr.length ? virtualDropdown.learning.filter(element => storedGlobal.learningMethod.arr.includes(element.value)) : virtualDropdown.learning }
                      state={learningMethod}
                      setState={setLearningMethod}
                      renderLabelFunction={RenderLearningMethod}
                    />
                    {/* Lecturer filter */}
                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman assesment-dropdown">
                        <div className="dropdown multiselect">
                          <button
                            className={`btn btn-default dropdown-toggle ${valueLecturer?.length ? "btn-selected" : ""
                              } `}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            title="Lecturer"
                          >
                            <span>
                              Lecturer <i className="fal fa-angle-down"></i>
                            </span>
                          </button>
                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">
                              <div className="dropdown-filter-pagination">
                                {virtualDropdown.lecturer && virtualDropdown.lecturer.length ? (
                                  <FilterComponent
                                    value={valueLecturer}
                                    onChange={onChangeLecturer}
                                    dataResponse={virtualDropdown.lecturer}
                                    backendResponse={setLectureIds}
                                  />
                                ) : ""}
                              </div>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Type filter END */}
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>

                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Online Virtual Class");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Online Virtual Class");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Online Virtual Class");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <DataTable
          paginationDefaultPage={page}
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          data={dashboardList}
          columns={columns}
          defaultSortAsc={true}
          defaultSortField="class_date"
          onSort={handleSort}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          // forcePage={page !== 0 ? page - 1 : 0}
          // pageCount={page || 1}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>

      {/* added by yash notification popup to send and listing start */}

      {showCkEditor && <div
        className="topic-add-modal modal fade zindexadd show"
        style={{ display: "block" }}
        id="notifications"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body p-0">

                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fal fa-bell"></i> Quick Notifications
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      formik.resetForm()
                      $("#notifications").modal("hide");
                      setEditorState("");
                      setShowCkEditor(false);
                      setSelectedFile("");
                      formik.setFieldValue("attachment", "")
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                </div>
                <div className="t p-30px">
                  <div className="topic-add-modal-content">
                    <div className="row center-label">
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Class Name</label>
                          <input
                            type="text"
                            className={"form-control form-control-aatch-file"}
                            disabled
                            title="Class Name"
                            value={formik.values.class_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Subject *</label>
                          <input
                            type="text"
                            title="Subject"
                            name="subject"
                            className={
                              "form-control form-control-aatch-file" +
                              (formik.errors.subject && formik.touched.subject
                                ? " is-invalid"
                                : "")
                            }
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}

                          />

                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Content *</label>
                          <HtmlInputEditor
                            editorState={editorState}
                            setEditorState={setEditorState}
                            hideSign={true}
                            isCKEditor={true}
                            isInvalid={
                              formik.touched.content &&
                              formik.errors.content
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Attachment *</label>
                          <div className="form-group atttach-file m-0 d-flex align-items-center">
                            <label
                              className={
                                formik.errors.attachment &&
                                formik.touched.attachment &&
                                "file-req is-invalid"
                              }
                            >
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                type="file"
                                className="form-control  form-control-aatch-file"
                                onChange={(e) => {
                                  if (e.target.files.length) {
                                    formik.setFieldValue(
                                      "attachment",
                                      e.target.files[0]
                                    );
                                    setSelectedFile(URL.createObjectURL(e.target.files[0]));
                                  }
                                }}
                                placeholder="File"
                              />
                            </label>
                          </div>
                          {selectedFile ? (
                            <Link
                              className="mt-1 d-flex"
                              to={{ pathname: selectedFile }}
                              target="_blank"
                            >
                              {selectedFile}
                            </Link>
                          ) : null}
                        </div>

                      </div>

                    </div>
                  </div>
                  <div className="form-group form-group-save-cancel mt-3">
                    <PermissionsGate
                      scopes={["cedit"]}
                      errorProps={{ disabled: true }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={disabled}
                      // onClick={() => {
                      //   sendNotification();
                      // }}
                      >
                        {disabled ? (
                          <i className="fas fa-cog fa-spin"></i>
                        ) : (
                          <i className="fal fa-check"></i>
                        )}
                        Send
                      </button>
                    </PermissionsGate>
                    <button
                      type="button"
                      // type="submit"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={() => {
                        cancelQuickNotification();
                      }}
                    >
                      <i className="fal fa-times"></i>
                      Close
                    </button>
                    {Object.keys(formik.values).map(key => {
                      if (formik.touched[key] && formik.errors[key]) {
                        return (
                          <div className="invalid-feedback d-block" key={key}>
                            {formik.errors[key]}
                          </div>
                        )
                      }
                    })}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>}

      <div
        className="topic-add-modal modal fade"
        id="classrecording"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-video"></i> Class Recording
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  closeModal();
                }}
              >
                <i className="fal fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <ClassRecordingModal classId={classId} />
              <hr />
              <div className="">
                <ClassRecordingForm classId={classId} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <PopupComponent
        type="customModalBody"
        id="launch"
        header={{ iconName: "fa-tachometer-alt-average", heading: "Class Launcher" }}
        modalBody={!launchLoading ? <div className="modal-body class-launch-modal">
          <div className="d-flex">
            <h4 className="landing-heading">{launchDetail.class_title}</h4>
            <div className="as-widget no-text-transformation ml-auto" title="Hybrid">
              {launchDetail.class_type ? RenderClassType(launchDetail.class_type).html : ""}
            </div>
          </div>
          <div className="time-date-bar d-flex align-items-center mt-2">
            <div className="time-date-bar-in">
              {launchDetail.class_date ? <><i className="fal fa-calendar-alt" title="Date and Time"></i>
                {` ${moment(launchDetail.class_date).format(
                  `dddd, ${TABLE_DATE_FORMAT}`
                )} ${launchDetail.class_from} - ${launchDetail.class_to
                  }`}</>
                : ""}
            </div>
            <div className="ml-3">
              {launchDetail.status ? RenderClassStatus(launchDetail.status).html : ""}
              {launchDetail.subStatus ? RenderClassStatus(launchDetail.subStatus).html : ""}
            </div>
          </div>
          <div className="room-name my-2">
            {launchDetail.multiple_room?.length ? launchDetail.multiple_room.map((item, index) => {
              return <span className="mr-2" key={index}><i className="fal fa-university" title="Campus"></i> {item.campus_room?.room_no}, {item.campus_room?.room_description}</span>
            }) : ""}
          </div>
          <div className="room-name my-2 d-flex">
            {launchDetail.arrAttendance ?
              <span className="mr-2">
                <i className="fal fa-users" title="Student Attendance"></i>{" "}
                {launchDetail.arrAttendance?.getattend} /{" "}{launchDetail.arrAttendance?.total} ({((launchDetail.arrAttendance?.getattend / launchDetail.arrAttendance?.total) * 100) ? ((launchDetail.arrAttendance?.getattend / launchDetail.arrAttendance?.total) * 100).toFixed(2) : 0}%)
              </span>
              : ""
            }
            {launchDetail.arrAttendanceCampus ? <span className="mr-2">
              <i className="fal fa-university" title="Campus Attendance"></i>{" "}
              {launchDetail.arrAttendanceCampus?.getattend} /{" "} {launchDetail.arrAttendanceCampus?.total} (
              {launchDetail.arrAttendanceCampus?.attendpercentage > 0
                ? launchDetail.arrAttendanceCampus?.attendpercentage.toFixed(2)
                : 0}
              %)
            </span> : ""}
            {launchDetail.arrAttendanceOnline ? <span className="mr-2">
              <i className="fal fa-house-signal" title="Remote Attendance"></i>{" "}
              {launchDetail.arrAttendanceOnline?.getattend} /{" "} {launchDetail.arrAttendanceOnline?.total} (
              {launchDetail.arrAttendanceOnline?.attendpercentage > 0 ?
                launchDetail.arrAttendanceOnline?.attendpercentage.toFixed(2)
                : 0}
              %)
            </span> : ""}
            {launchDetail.subStatus === "Ended" ? <div className="icon-w-text mr-2">
              <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
              <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
              <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
              <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
              <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
            </div> : ""}
          </div>
          <div className="mt-3 d-flex align-items-center flex-wrap">
            {(launchDetail.launch_show == 1 || launchDetail.on_campus_show == 1) && !((launchDetail.launch_disable == 1 || (launchDetail.status === "Past" || launchDetail.status === "Cancelled")) && ((launchDetail.on_campus_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled"))) ? <div
              className={
                "box-style-new2 " +
                ((launchDetail.launch_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled")
                  ? ""
                  : " bg-sidebar")
              }
            >
              {!loader.includes(1) && (
                <button
                  className="btn btn-success mr-2 mt-2"
                  disabled={(launchDetail.launch_disable == 1 || (launchDetail.status === "Past" || launchDetail.status === "Cancelled")) && ((launchDetail.on_campus_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled"))}
                  onClick={() => {
                    if (launchDetail && (launchDetail.launch_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled"))) {
                      launchVClass(launchDetail.id, 1)
                      return
                    }
                    if (launchDetail && (launchDetail.on_campus_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled"))) {
                      launchDetail.class_type == "hybrid"
                        ? launchVClass(launchDetail.id, 1)
                        : launchCampusClass("start")
                    }
                  }}
                >
                  <i className="fal fa-rocket"></i> Launch Class
                </button>
              )}
            </div> : ""}
            {(launchDetail.end_meeting_show == 1 || launchDetail.end_campus_show == 1) && !(launchDetail.end_meeting_disable == 1 && launchDetail.end_campus_disable == 1) ? (
              <div
                className={
                  "box-style-new2 " +
                  (launchDetail.end_meeting_disable == 1
                    ? ""
                    : " bg-sidebar")
                }
              >
                {!loader.includes(3) && (
                  <button
                    // className={
                    //   launchDetail.end_meeting_disable == 0 &&
                    //   "dashboard_hover"
                    // }
                    className="btn btn-danger mr-2 mt-2"
                    disabled={launchDetail.end_meeting_disable == 1 && launchDetail.end_campus_disable == 1}
                    onClick={() => {
                      if (launchDetail &&
                        launchDetail.end_meeting_disable == 0) {
                        launchVClass(launchDetail.id, 3, false)
                        return
                      }
                      if (launchDetail && launchDetail.end_campus_disable == 0) {
                        launchDetail.status == "hybrid" ? launchVClass(launchDetail.id, 3, false) : launchCampusClass("end")
                      }
                    }}
                  >
                    <i className="fal fa-window-close"></i> End Class
                  </button>
                )}
              </div>
            ) : ""}
            {launchDetail.status === "Past" && launchDetail.subStatus === "Ended" ? <>
              <button
                className="btn btn-success btn-sidebar mr-2 mt-2"
                title="Class Analytics"
                disabled={analysisBtn.disable}
                onClick={() => window.open(analysisBtn.link, "_blank")}
              >
                <i className="fal fa-analytics"></i> Class Analytics
              </button>
              <button
                className="btn btn-primary btn-sidebar mr-2 mt-2"
                title="Class Attendance"
                onClick={() => window.open(`/classReport/summary/${onlineClass.course_id}/${onlineClass.class_id}`, "_blank")}
              >
                <i className="fal fa-users"></i> Class Attendance
              </button>
              {launchDetail.viewRecording?.status === "YES" ? <a
                className="btn btn-primary btn-sidebar mr-2 pt-1 mt-2"
                title="Watch Video"
                href={launchDetail.viewRecording?.link}
                target="_blank"
                download
              >
                <i className="fal fa-play"></i> Watch Video
              </a> : <button
                className="btn btn-primary btn-sidebar mr-2 pt-1 mt-2"
                title="Watch Video"
                disabled
              >
                <i className="fal fa-play"></i> Watch Video
              </button>}
              <button
                className="btn btn-primary btn-sidebar mr-2 mt-2"
                title="Class Files"
                onClick={() => {
                  Swal.fire({
                    title: "Loading",
                    onOpen: function () {
                      Swal.showLoading();
                    },
                  });
                  getClassFiles({ "big_blue_button_id": launchDetail.big_blue_button_id }).then(res => {
                    let arr = res.data.data.Contents || [];
                    let Name = res.data.data.Name;
                    if (arr.length) {
                      arr.forEach(item => {
                        const aTag = document.createElement("a");
                        aTag.target = "_blank"
                        aTag.href = `https://${Name}/${item.Key}`;
                        aTag.setAttribute("download", item.Key);
                        document.body.appendChild(aTag);
                        aTag.click();
                      })
                      Swal.close();
                    } else {
                      Swal.close();
                      Swal.fire("No Files", "There are no Files Uploaded in this class", "success");
                    }
                  }).catch(err => {
                    Swal.close();
                    console.error("error: ", err)
                    Swal.fire("Error!", "Something Went Wrong", "error");
                  })
                }}
              ><i className="fal fa-file"></i> Class Files</button>
            </> : <>
              <button
                className="btn btn-primary mr-2 mt-2"
                title="System Check"
                onClick={() => window.open("https://test.8x8.vc/", "_blank")}
                disabled={launchDetail.launch_show == 0}
              >
                <i className="fal fa-check-double"></i> System Check
              </button>
              <button
                className="btn btn-primary btn-orange mr-2 mt-2"
                title="Speed Test"
                onClick={() => window.open("https://speedtest.mybroadband.co.za/", "_blank")}
              >
                <i className="fal fa-tachometer-slowest"></i> Speed Test
              </button>
            </>}
            {launchDetail.subStatus === "Launched" ? <div className={"box-style-css box-style-new3 active d-flex mb-0 align-items-center " + (launchDetail.status === "Past" ? "" : "mt-2")}>
              <h4 className="mb-0 mr-1">
                <i className="fal fa-user-check"></i> Join as Guest&nbsp;
                <i className="fal fa-link"
                  disabled={launchDetail.launch_show == 0}
                  role={launchDetail.launch_show != 0 && "button"}
                  onClick={() => joinAsAdmin("copy")}
                >
                </i>
              </h4>
              <div className="input-box-style-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  disabled={launchDetail.launch_show == 0}
                  value={launchDetail.lerner ?? ""}
                  onChange={(e) => setLaunchDetail({ ...launchDetail, lerner: e.target.value ?? "" })}
                />
                <button
                  disabled={launchDetail.launch_show == 0}
                  className="btn btn-submit"
                  onClick={() => joinAsAdmin("guest")}
                >
                  <i className="fal fa-chevron-right"></i>
                </button>
              </div>
            </div> : ""}
          </div>
          <div className="launcher-info-bar d-flex py-3 mt-3">
            {launchDetail.courseName ? <div className="launcher-info-bar-in mr-3">
              <p className="mb-1"><b>Courses</b></p>
              <Link to={`/courseAdministration/coursesdetails/${launchDetail.intakeID}/detailCourses/show`} className="as-text-blue curser">{launchDetail.courseName}</Link>
            </div> : ""}
            {launchDetail.lecture_name ? <div className="launcher-info-bar-in mr-3">
              <p className="mb-1"><b>Lecturer</b></p>
              <UserColumn
                firstName={launchDetail.lecturer.Firstname}
                lastName={launchDetail.lecturer.Lastname}
                imgUrl={launchDetail.lecturer.picture_me}
                email={launchDetail.lecturer.Email}
                mobile={launchDetail.lecturer.Mobile}
                userId={launchDetail.lecturer.UserID}
                userActiveStatus={launchDetail.lecturer.Status}
                isPopUpRight={true}
              />
            </div> : ""}
            {launchDetail.multiple_lecture?.length ? <div className="launcher-info-bar-in mr-3">
              <p className="mb-1"><b>Guest Lecturers</b></p>
              {showMore ? <div>
                <div className="assigned-profile-tit">{launchDetail.multiple_lecture.length ? launchDetail.multiple_lecture.map((data, index) => <UserColumn
                  firstName={data.get_lecture_details.Firstname}
                  lastName={data.get_lecture_details.Lastname}
                  imgUrl={data.get_lecture_details.picture_me}
                  email={data.get_lecture_details.Email}
                  mobile={data.get_lecture_details.Mobile}
                  userId={data.get_lecture_details.UserID}
                  userActiveStatus={data.get_lecture_details.Status}
                  isUserNameHidden={launchDetail.multiple_lecture.length === 1 ? false : !showMore}
                  isPopUpRight={true}
                  key={index}
                />) : "-"}</div>
                {launchDetail.multiple_lecture.length && launchDetail.multiple_lecture.length > 1 ? <div
                  className="table-expand-btn"
                  onClick={() => setShowMore(!showMore)}>
                  See Less
                </div> : ""}
              </div> : <div className="assigned-profile-overlay-out">
                <div className="assigned-profile-overlay">{launchDetail.multiple_lecture.length ? launchDetail.multiple_lecture.map((data, index) => <UserColumn
                  firstName={data.get_lecture_details.Firstname}
                  lastName={data.get_lecture_details.Lastname}
                  imgUrl={data.get_lecture_details.picture_me}
                  email={data.get_lecture_details.Email}
                  mobile={data.get_lecture_details.Mobile}
                  userId={data.get_lecture_details.UserID}
                  userActiveStatus={data.get_lecture_details.Status}
                  isUserNameHidden={launchDetail.multiple_lecture.length === 1 ? false : !showMore}
                  isPopUpRight={true}
                  key={index}
                />) : "-"}</div>
                {launchDetail.multiple_lecture.length && launchDetail.multiple_lecture.length > 1 ? <div
                  className="table-expand-btn"
                  onClick={() => setShowMore(!showMore)}>
                  See More
                </div> : ""}
              </div>}
            </div> : ""}
          </div>
          {launchDetail.class_description ? <div className={`${launchDetail.big_blue_button_id ? "launcher-desc" : ""} py-3`}>
            {launchDetail.class_description}
          </div> : ""}
          {launchDetail.big_blue_button_id ? <div className="pt-3 ">
            <b>BBB: {(launchDetail?.big_blue_button_id + (launchDetail?.bbbname && ` - ${launchDetail?.bbbname}`))} <CopyToClipboard data={(launchDetail?.big_blue_button_id + (launchDetail?.bbbname && ` - ${launchDetail?.bbbname}`))} /></b>
          </div> : ""}
        </div> : <span className="p-30px"><SkeletonTicketList /></span>}
      />

      <div
        className="topic-add-modal modal fade"
        id="Todaystatus"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-bars"></i> {classTitle ? classTitle : ""}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  closeModal();
                }}
              >
                <i className="fal fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="my-tickets-info-list Tickets-main-wrap">
                <div className="custom-table-div filter-search-icon card card-table-custom">
                  <div className="search-filter-div">
                    <div className="search-filter-div-left">
                      <div className="system-administration-table table-responsive">
                        <div className="table-responsive-div">
                          <div
                            id="assessment-table-main_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                            <div
                              id="assessment-table-main_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              <div
                                id="assessment-table-main_filter"
                                className="dataTables_filter"
                              >
                                <label>
                                  <input
                                    type="search"
                                    className=""
                                    placeholder=""
                                    aria-controls="assessment-table-main"
                                    onChange={handleModalSearchFilter}
                                    value={modalSearch}
                                  />
                                </label>
                                <div className="filter-eff filter-data-btn">
                                  <button className="filter-buttons">
                                    <i className="fal fa-filter"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="filter-button-group">
                          <div className="reset-btn-group">
                            <div className="button-reset dropdown-comman">
                              <button
                                className="btn btn-primary"
                                onClick={resetModalFilter}
                                title="Reset"
                              >
                                <i className="fal fa-redo"></i>Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {modalLoading ? (
                    <SkeletonTicketList />
                  ) : (
                    <DataTable
                      data={dataToModal()}
                      defaultSortField="first_name"
                      defaultSortAsc={false}
                      columns={modalColumns}
                      pagination={true}
                      paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClassSeriesPopup
        popId="editModal"
        heading="Edit Class"
        message="This is one class in a series. What do you want to open?"
        // url={`/courseAdministration/coursesdetails/${id}/onlineClasses/${classId}/open/recurring/`}
        onSubmit={(val) => {
          history.push(`/courseAdministration/coursesdetails/${recurringDetails.intakeId}/onlineClasses/${recurringDetails.id}/open/${recurringDetails.recurring}/${val}`)
        }}
      />
      <ClassSeriesPopup
        popId="unlinkClass"
        heading="Unlink Class"
        message="This is one class in a series. What do you want to unlink?"
        onSubmit={(val) => {
          // rmData = new FormData();
          // formData.append("cid", no);
          // formData.append("intake", props.userId);
          // formData.append("act", type);

          DeleteOnlineClass({ ...popUpState, isRecurring: val })
            .then((data) => {
              setRefreshList(!refreshList)
              Swal.fire("Unlinked!", "Class unlinked successfully.", "success");
            })
            .catch((error) => {
              console.log("error ===> ", error);
            });
        }}
      />
      <ClassSeriesPopup
        popId="cancelClass"
        heading="Cancel Class"
        message="This is one class in a series. What do you want to cancel?"
        onSubmit={(val) => {
          // rmData = new FormData();
          // formData.append("cid", no);
          // formData.append("intake", props.userId);
          // formData.append("act", type);

          DeleteOnlineClass({ ...popUpState, isRecurring: val })
            .then((data) => {
              setRefreshList(!refreshList)
              Swal.fire("cancelled!", "Class cancelled successfully.", "success");
            })
            .catch((error) => {
              console.log("error ===> ", error);
            });
        }}
      />
      <ClassSeriesPopup
        popId="deleteClass"
        heading="Delete Class"
        message="This is one class in a series. What do you want to delete?"
        onSubmit={(val) => {
          // rmData = new FormData();
          // formData.append("cid", no);
          // formData.append("intake", props.userId);
          // formData.append("act", type);

          DeleteOnlineClass({ ...popUpState, isRecurring: val })
            .then((data) => {
              setRefreshList(!refreshList)
              Swal.fire("Deleted!", "Class deleted successfully.", "success");
            })
            .catch((error) => {
              console.log("error ===> ", error);
            });
        }}
      />
    </>
  );
};

export default UpcomingOnlineClasses;
