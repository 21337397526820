import moment from "moment";
import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import { DeleteAssessment, GetAssessmentListCancelToken } from "../../../../services/CourseService";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import { InitialRender } from "../../../common/Helper";
import { ColorRender } from "../../../systemadministration/usermanagement/CheckRole";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import PermissionsGate from "../../../../utils/permissionGate";
import Hover from "../../../common/Hover";
import { checkIfImageExists, handleTableScroll } from "../../../../utils/commonFunction";
import Tablefilter from "../../../common/Tablefilter";
import { RenderAssessmentStatus } from "../../../../utils/CommonStatusItems";
import { RenderAssessmentType } from "../../../../utils/CommonGroupingItem";
import Swal from "sweetalert2";
import axios from "axios";
import UserColumn from "../../../common/UserColumn";
import $ from 'jquery';

const AssignedAssessments = (props) => {

  const { tab, id } = useParams();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [markingStatus, setMarkingStatus] = useState({ arr: [], checkObj: {} });
  const [type, setType] = useState({ arr: [], checkObj: {} });
  const [assessmentType, setAssessmentType] = useState({ arr: [], checkObj: {} });
  const [assessmentList, setAssessmentList] = useState([]);
  const [isListLoaded, setIsListLoaded] = useState(false);
  const [filterData, setFilterData] = useState({ intake: [], type: [], status: [], mStatus: [], assessmentType:[] });
  const [filterCheck, setFilterCheck] = useState("true");
  const [deleteAssessment, setDeleteAssessment] = useState(false);
  const [isShowingAllUser, setIsShowingAllUser] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {        
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    handleTableScroll()
  }, [isListLoaded])
  useEffect(() => {
    const cancelTokenSources = [];

    const getAssessmentsList = async () => {
      setIsListLoaded(false);
      props.setLoad(true)

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        let data = filterCheck;
        let res = await GetAssessmentListCancelToken(id, data, source.token)
        if (res.status === 200) {
          setAssessmentList(res.data && res.data.result.length ? res.data.result : []);
          setIsListLoaded(true);
          props.setLoad(false)
          if (filterCheck == "true") {
            setFilterData({ ...res.data, intake: res.data.yearsAndIntaksFilter, type: res.data.assessmentTypeFilter, status: res.data.statusFilter, mStatus: res.data.MarkingStatusFilter, assessmentType:res.data.typeFilter });
            setFilterCheck("false")
          }
        }
      } catch (error) {
        setIsListLoaded(true);
        console.log("error <<<<<< : ", error);
        props.setLoad(false)
      }
    }
    getAssessmentsList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [deleteAssessment])

  const dataToRender = () => {
    let updatedData = [];
    let allData = assessmentList;
    if (search.length) {
      let tempStudent = allData.filter((item) => {
        let includes = item.name && item.name.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempCode = allData.filter((item) => {
        let includes = item?.code?.toString().toLowerCase().includes(search.toString().toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        ...tempStudent,
        // ...tempId,
        ...tempCode
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.arr.find(function (post, index) {
          if (post.toString().toLowerCase() === item.status.toString().toLowerCase() ? item.status.toString().toLowerCase() : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (type.arr.length) {
      let tempType = updatedData;
      let tempResult = tempType.filter((item) => {
        const startsWith = type.arr.find(function (post, index) {
          if (post.toString().toLowerCase() === item.type.toString().toLowerCase()) {
            return item;
          }
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (markingStatus.arr.length) {
      let tempStatus = updatedData;
      let tempMarkingStatus = tempStatus.filter((item) => {
        const startsMarking = markingStatus.arr.find(function (post, index) {
          if (post.toString().toLowerCase() === item.markingStatus.toString().toLowerCase() ? item.markingStatus.toString().toLowerCase() : "") return item;
        });
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });

      updatedData = tempMarkingStatus;
    }
    if (intake.arr.length) {
      let tempStatus = updatedData;
      let tempIntakeHash = tempStatus.filter((item) => {
        const startsMarking = intake.arr.find(function (post, index) {
          if (post == (item.number && item.year ? item.year + "/" + item.number : "")) return item;
        });
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });

      updatedData = tempIntakeHash;
    }
    if (assessmentType.arr.length) {
      let tempStatus = updatedData;
      let tempAssType = tempStatus.filter((item) => {
        const startsWith = assessmentType.arr.find(function (post, index) {
          if (post.toString().toLowerCase() === item.assignment_type.toString().toLowerCase()) {
            return item;
          }
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempAssType;
    }

    return updatedData;
  };

  const sortingDate = (rowA, rowB) => {
    const date1 = moment.unix(rowA.newMarkDate_mili);
    const date2 = moment.unix(rowB.newMarkDate_mili);

    if (date2 > date1) {
      // console.log(`${date2} is greater than ${date1}`)
      return 1;
    } else if (date2 < date1) {
      // console.log(`${date1} is greater than ${date2}`)
      return -1;
    } else {
      // console.log(`Both dates are equal`)
      return 0;
    }
  };

  const sortingName = (rowA, rowB) => {
    const name1 = rowA.name ? rowA.name.toString() : "";
    const name2 = rowB.name ? rowB.name.toString() : "";
    return name1.localeCompare(name2);
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      sortFunction: sortingName,
      cell: (row) => {
        return (
          <span
            title={row.name}
            className="overflow-ellipsis2 ">
            {row.name ?
              row.disable_assessment=="1" ? 
              <span className="feature-name">
                <span className="textLimit100">{row.name}</span>
              </span>
              :
              // <PermissionsGate scopes={["castedit"]} RenderError={() => <span>{row.name ? row.name : "-"}</span>}>
                <Link className="as-text-blue curser feature-name"
                  to={row.Assmenttype == "no" ? `/courseAdministration/coursesdetails/${id}/${tab}/open/assigned/Details/${row.id}` : `/courseAdministration/assessment/quizzes/open/details/${row.smart_assignment_id}`}
                >
                  <span className="textLimit100">{row.name}</span>
                </Link>
              // </PermissionsGate>
               : "-"}
          </span>
        )
      }
    },
    {
      name: "Assessment Code",
      selector: "code",
      sortable: true,
      cell: (row) => (
        <p>{row.code && row.code != '' ? row.code : '-'}</p>
      )
    },
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      cell: (row) => (
        <p>{row.year && row.number ? row.year + "-" + row.number : "-"}</p>
      )
    },
    {
      name: "Open Date",
      selector: "open_date_time",
      sortable: true,
      cell: (row) => (
        row.open_date_time ?
          <>
            <p className="right-space">{moment.unix(row.open_date_time).format(TABLE_DATE_FORMAT)}</p>
            <p>{moment.unix(row.open_date_time).format(TABLE_TIME_FORMAT)}</p>
          </>
          : "-"
      )
    },
    {
      name: "Due Date",
      selector: "close_date_time_mili",
      sortable: true,
      cell: (row) => (
        row.close_date_time_mili ?
          <>
            <p className="right-space">{moment.unix(row.close_date_time_mili).format(TABLE_DATE_FORMAT)}</p>
            <p>{moment.unix(row.close_date_time_mili).format(TABLE_TIME_FORMAT)}</p>
          </>
          : "-"
      )
    },
    {
      name: "Assessment Type",
      selector: "assignment_type",
      sortable: true,
      cell: (row) => (
        row.assignment_type ? RenderAssessmentType(row?.assignment_type).html : "-"
      )
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      cell: (row) => (
        row.type ? RenderAssessmentType(row.type).html : "-"
      )
    },
    {
      name: "Marker",
      selector: "markerName",
      sortable: true,
      cell: (row) => <UserColumn
        multipleUserStacks
        isShowingAllUser={isShowingAllUser}
        setIsShowingAllUser={setIsShowingAllUser}
        usersArray={row.markerList}
        rowId={row.id}
        usersArrayData={{
          firstName: "Firstname",
          lastName: "Lastname",
          imgUrl: "picture_me",
          email: "Email",
          mobile: "Mobile",
          userId: "UserID",
          userActiveStatus: "Status",
          right: true
          // role: "lecturer"
        }}
      />
      // cell: (row) => row.markerList && row.markerList.length ? row.markerList.map(item => {
      //       return (
      //       <div className="assigned-title-block-new mb-1">
      //         <div className="assigned-title-itm">
      //           <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(row.role_name)}>
      //             {item.picture_me && checkIfImageExists(`${IMAGE_URL}/${item.picture_me.replaceAll("/home/myaie/public_html/", "")}`) ? <img src={`${IMAGE_URL}/${item.picture_me.replaceAll("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([item.Firstname, item.Lastname])}
      //             <span className={`profile-box-2-status ${item.is_active.toString() ? item.is_active ? "Online" : "Offline" : "Offline"}`}>
      //               <i className="fas fa-circle"></i>
      //             </span>
      //             <Hover firstName={item.Firstname} lastName={item.Lastname} photo={item.picture_me} email={item.Email} mobile={item.Mobile} status={item.Status} activity_status={item.is_active.toString()} right={false} role={"marker"} />
      //           </span>
      //           <Link className="as-text-blue curser feature-name" title={item.Firstname + " " + item.Lastname}  to={`/systemAdministration/userManagement/open/${item.UserID}/aboutYou`}><span className="textLimit100">{item.Firstname + item.Lastname ? item.Firstname + " " + item.Lastname : "-"}</span></Link>
      //         </div>
      //       </div>
      //       )
      //     }) : "-"
    },
    {
      name: "Moderator",
      selector: "moderatorName",
      sortable: true,
      cell: (row) => (
        row.moderatorName ? (
          <>
            <div className="assigned-title-block-new">
              <div className="assigned-title-itm">
                <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(row.role_name)}>
                  {row.moderatorProfile && checkIfImageExists(`${IMAGE_URL}/${row.moderatorProfile.replaceAll("/home/myaie/public_html/", "")}`) ? <img src={`${IMAGE_URL}/${row.moderatorProfile.replaceAll("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.moderatorName.split(" ")[0], row.moderatorName.split(" ")[1]])}
                  <span className={`profile-box-2-status ${row.moderator_activity_status ? row.moderator_activity_status.toLowerCase() == "online" ? "Online" : row.moderator_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                    <i className="fas fa-circle"></i>
                  </span>
                  <Hover firstName={row.moderatorName.split(" ")[0]} lastName={row.moderatorName.split(" ")[1]} photo={row.moderatorProfile} email={row.moderatorEmail} mobile={row.moderatorContact} status={row.moderator_status} activity_status={row.moderator_activity_status} right={false} role={"marker"} />
                </span>
                <Link className="as-text-blue curser feature-name" title={row.moderatorName} to={`/systemAdministration/userManagement/open/${row.moderator_id}/aboutYou`}><span className="textLimit100">{row.moderatorName}</span></Link>
              </div>
            </div>
          </>
        ) : "-"
      )
    },
    {
      name: "Marking Due Date",
      selector: "newMarkDate_mili",
      sortable: true,
      sortFunction: sortingDate,
      cell: (row) => (
        row.newMarkDate_mili && row.newMarkDate_mili != "NaN" && parseInt(row.newMarkDate_mili) ?
          <>
            <p className="right-space">{moment.unix(parseInt(row.newMarkDate_mili)).format(TABLE_DATE_FORMAT)}</p>
            <p>{moment.unix(parseInt(row.newMarkDate_mili)).format(TABLE_TIME_FORMAT)}</p>
          </>
          : "-"
      )
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => row.status ? RenderAssessmentStatus(row.status).html : "-",
    },
    {
      name: "Submissions",
      selector: "submission",
      sortable: true,
      cell: (row) => (
        <p>{row.submission ? row.submission : "-"}</p>
      )
    },
    {
      name: "Marking Status",
      selector: "markingStatus",
      sortable: true,
      cell: (row) => (
        (row.markingStatus && row.newMarkDate_mili && row.newMarkDate_mili != "NaN") ? RenderAssessmentStatus(row.markingStatus).html : "-"
      ),
    },
    {
      name: "Marking Overdue Days",
      selector: "markingOverDueDays",
      sortable: true,
      cell: (row) => (
        <p>{row.markingStatus == "Marked" ? "NA" : row.markingOverDueDays ? row.markingOverDueDays : "-"}</p>
      )
    },
    {
      name: "Actions",
      cell: (row) => (
        row.type=="Supplementary Exam" ? 
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            {/* <PermissionsGate scopes={["castedit"]} errorProps={{ disabled: true }}>               */}
              <button className="btn btn-primary rounded-circle mr-1" title="Open" disabled={row.disable_assessment=="1"} onClick={()=>{history.push(row.Assmenttype == "no" ? `/courseAdministration/coursesdetails/${id}/${tab}/open/assigned/Details/${row.id}` : `/courseAdministration/assessment/quizzes/open/details/${row.smart_assignment_id}`)}}>
                <i className="fal fa-folder-open"></i>
              </button>            
            {/* </PermissionsGate> */}
            <PermissionsGate scopes={["castdelete"]} errorProps={{ disabled: true }}>
              <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => handleDelete(row.id)}>
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>            
          </div>
        </div>
        :
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            {/* <PermissionsGate scopes={["castedit"]} errorProps={{ disabled: true }}>               */}
              <button className="btn btn-primary rounded-circle mr-1" title="Open" disabled={row.disable_assessment=="1"} onClick={()=>{history.push(row.Assmenttype == "no" ? `/courseAdministration/coursesdetails/${id}/${tab}/open/assigned/Details/${row.id}` : `/courseAdministration/assessment/quizzes/open/details/${row.smart_assignment_id}`)}}>
                <i className="fal fa-folder-open"></i>
              </button>            
            {/* </PermissionsGate> */}
            <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" title="More" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {/* <PermissionsGate scopes={["castadd"]} errorProps={{ disabled: true }}> */}
                  <button className="btn btn-primary rounded-circle" title="Duplicate" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/${tab}/duplicate/assigned/Details/${row.id}`)}>
                    <i className="fal fa-copy"></i>
                  </button>
                {/* </PermissionsGate> */}
                <PermissionsGate scopes={["castdelete"]} errorProps={{ disabled: true }}>
                  <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => handleDelete(row.id)}>
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ], []);

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAssessment({ assessment_id: ID }).then((res) =>
          setDeleteAssessment(!deleteAssessment)
        );
        Swal.fire("Deleted!", "Your assessment has been deleted.", "success");
      }
    });
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Assessment Code", "Intake", "Open Date", "Due Date", "Assessment Type", "Type", "Marker", "Moderator", "Marking Due Date", "Status", "Submissions", "Marking Status", "Marking Over Due Days"];
    data = data?.map((row) => ({
      ...row,
      Name: row?.name ? row.name : "-",
      "Assessment Code": row?.code ? row.code : "-",
      Intake: row.year && row.number ? row.year + "-" + row.number : "-",
      "Due Date": row.due ? moment.unix(row.due).format(TABLE_DATE_TIME_FORMAT) : "-",
      "Open Date": row.open_date_time ? moment.unix(row.open_date_time).format(TABLE_DATE_TIME_FORMAT) : "-",
      "Assessment Type": row.assignment_type ? RenderAssessmentType(row.assignment_type).text : "-",
      Type: row.type ? row.type : "-",
      Marker: row?.markerName ? row?.markerName : "-",
      Moderator: row?.moderatorName ? row.moderatorName : "-",
      "Marking Due Date": (row?.newMarkDate_mili && row.newMarkDate_mili != "NaN") ? moment.unix(row.newMarkDate_mili).format(TABLE_DATE_TIME_FORMAT) : "-",
      Status: row?.status ? row?.status.toUpperCase() : "-",
      Submissions: row?.submission ? row?.submission : "-",
      "Marking Status": (row?.markingStatus && row.newMarkDate_mili && row.newMarkDate_mili != "NaN") ? row?.markingStatus : "-",
      "Marking Over Due Days": row?.markingOverDueDays ? row?.markingOverDueDays : "-"
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Name, row["Assessment Code"], row.Intake, row["Open Date"], row["Due Date"], row["Assessment Type"], row["Type"], row.Marker, row.Moderator, row["Marking Due Date"], row.Status, row.Submissions, row["Marking Status"], row["Marking Over Due Days"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const resetFilter = () => {
    setSearch("");
    setStatus({ arr: [], checkObj: {} });
    setIntake({ arr: [], checkObj: {} });
    setType({ arr: [], checkObj: {} });
    setMarkingStatus({ arr: [], checkObj: {} });
    setAssessmentType({ arr: [], checkObj: {} });
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">

      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                    <div id="assessment-table-main_filter" className="dataTables_filter">
                      <label>
                        <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner`}>
                    <Tablefilter
                      filterName={"Intake"}
                      optionArr={filterData.intake}
                      state={intake}
                      setState={setIntake}
                      isOptionReversed={true}
                    />
                    <Tablefilter
                      filterName={"Type"}
                      optionArr={filterData.type}
                      state={type}
                      setState={setType}
                      renderLabelFunction={RenderAssessmentType}
                    />
                    <Tablefilter
                      filterName={"Assessment Type"}
                      optionArr={filterData.assessmentType}
                      state={assessmentType}
                      setState={setAssessmentType}
                      renderLabelFunction={RenderAssessmentType}
                    />
                    <Tablefilter
                      filterName={"Status"}
                      optionArr={filterData.status}
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={RenderAssessmentStatus}
                    />
                    <Tablefilter
                      filterName={"Marking Status"}
                      optionArr={filterData.mStatus}
                      state={markingStatus}
                      setState={setMarkingStatus}
                      renderLabelFunction={RenderAssessmentStatus}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button type="button" className="btn btn-files" title="Export spreadsheet" onClick={() => {
                      exportData("xlsx", "Assigned-Assessment");
                    }}>
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button type="button" className="btn btn-files" title="Export CSV" onClick={() => {
                      exportData("csv", "Assigned-Assessment");
                    }}>
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button type="button" className="btn btn-files" title="Export PDF" onClick={() => {
                      exportData("pdf", "Assigned-Assessment");
                    }}>
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className="filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate scopes={["castadd"]} errorProps={{ disabled: true }} >
                  <button className="btn btn-primary" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/${tab}/add`)} title="Create New" >
                    <i className="fal fa-plus"></i>Create New
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        {!isListLoaded && <SkeletonTicketList />}
        {isListLoaded && <DataTable
          data={dataToRender()}
          columns={columns}
          pagination={true}
          onChangeRowsPerPage={()=>{setUpdate(!update)}}
          defaultSortField="close_date_time_mili"
          defaultSortAsc={false}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />}

      </div>
    </div>
  );
};

export default AssignedAssessments;
