import React from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import AuditTrail from "../components/systemadministration/usermanagement/AuditTrail";
import ListOfBrands from "../components/systemadministration/BrandManagement/ListOfBrands";
import { Link, useHistory } from "react-router-dom";
import { GlobalIconNames } from "../utils/GlobalIconNames";

const BrandManagement = () => {
  
  const history = useHistory();

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                    <div className="d-flex align-items-center ">
                      <div className="name-w-head d-flex align-items-center ">
                        <h4 className="text-left-align landing-heading heading_color_dynamic">
                          Brand <span>Management</span>
                        </h4>
                      </div>
                      <div className="text-md-right action2-blk">
                        <div className="ticket-view-btn d-flex align-items-center">
                          <div className="add-new-ticket-bx">
                            <button
                              onClick={() => history.goBack()}
                              title="Back"
                              className="btn btn-white-bordered"
                            >
                              <i className="fal fa-angle-left"></i>Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="tabs-wrap">
                  <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li className="nav-item" onClick={() => history.push("/administration/brandSetting/list")}>
                      <Link className="nav-link active" id="pills-tab1" data-toggle="pill" to={"/administration/brandSetting/list"} href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true">
                        {GlobalIconNames("brand").html}
                        {/* <i className="fal fa-users"></i>
                        Brands */}
                      </Link>
                    </li>
                    <li className="nav-item" onClick={() => history.push("/administration/brandSetting/auditTrail") } >
                      <Link className="nav-link " id="pills-tab2" data-toggle="pill" to={"/administration/brandSetting/auditTrail"} href="#pills-tab12" role="tab" aria-controls="pills-tab12" aria-selected="true">
                      {GlobalIconNames("audittrail").html}
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                    <ListOfBrands />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandManagement;
