import React, { useEffect, useState } from "react";
import { setUserSession } from "../utils/Auth";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { getMyAllExamCountService } from "../services/ExamService";
import { useSelector, useDispatch } from "react-redux";
import { setUser, setWidget, setTab } from "../store/actions/index";

import { viewStudent } from "../services/GeneralService.js";

const LoginFromAdmin = ({ email, password, params }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  useEffect(() => {
    //console.log('props',params);
    viewStudent({ email: params.params.email, password: params.params.password })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          if (result.dismiss === "timer") {
            let token = res.data.token;
            let user = res.data.user;
            localStorage.setItem("widgetStatus", 1);
            dispatch(setUser(user));
            dispatch(setWidget({ status: 1 }));
            setUserSession(token, user);
            dispatch(setTab()); //side bar tab value set default to 1
            getMyAllExamCountService()
              .then((res) => {
                localStorage.setItem("examReg", res.data.registration.examList > 0 ? true : false);
                localStorage.setItem("reReg", res.data.reregistration.open + res.data.reregistration.close > 0 ? true : false);
                setLoading(false);
                history.push("/home", { widgetLoad: true });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      })
      .catch(function (err) {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: err.response.data.message,
        });
        setLoading(false);
        return false;
      });
  }, []);

  // const formik = useFormik({
  //   initialValues: {
  //     email: "",
  //     password: "",
  //     keepMe: false,
  //   },
  //   validationSchema: Yup.object({
  //     email: Yup.string()
  //       .email("Invalid email address")
  //       .required("Email Required"),
  //     password: Yup.string().required("Password Required"),
  //   }),
  //   onSubmit: (values) => {

  //   },
  // });

  return "";
};
export default LoginFromAdmin;
