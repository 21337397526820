import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import hasPermission from '../../../utils/hasMultiplePermission';
import AuditTrailsTable from '../../common/AuditTrailsTable';
import { GetCertificateAuditFilters, GetCertificateAuditTrails } from '../../../services/CertificateServices';
import { RenderCertificateResourceType } from '../../../utils/CommonGroupingItem';

function AuditTrail() {
    const history = useHistory();
    const { id, tab } = useParams();
    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
      let response = hasPermission({ scopes: ["intakeCopyView"], permissions: givenPermsisions });
      if (!response) {
        history.push("/noaccess");
      }
    }, [])
  
    const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]
    // TODO add both api's in this table to make it working
    return (
      <AuditTrailsTable
        apiFunction={GetCertificateAuditTrails}
        apiParams={id ? { action_id: id } : {}}
        actionId={id}
        columnsToShow={columnsToShow}
        filterArrApi={GetCertificateAuditFilters}
        exportFileName={"Certificate_template_audittrail"}
        isShowingResourceTypeFilter
        renderResourceTypeFunction={RenderCertificateResourceType}
        isResourceNameShown={true}
      />
    )
  }

export default AuditTrail